import { theme } from "antd";
import { type } from "os";
import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  XAxisProps,
  YAxisProps,
  AreaChart,
  Area,
} from "recharts";
import { Margin } from "recharts/types/util/types";
import { CustomShadowedContent } from "specifics/shadowed_content";

const CustomTooltip = <T extends Record<string, any>>({
  active,
  payload,
  label,
  getTooltipLabel,
  getTooltipBody,
}: {
  active?: boolean;
  payload?: any[];
  label?: string | number;
  getTooltipLabel?: (payload: T) => string | number;
  getTooltipBody?: (payload: T) => string | number;
}) => {
  const { token } = theme.useToken();
  if (active && payload && payload.length) {
    return (
      <CustomShadowedContent style={{ padding: 18, width: "100%" }}>
        <div style={{ fontSize: 14, color: token.colorTextSecondary }}>
          {getTooltipLabel ? getTooltipLabel(payload[0]) : label}
        </div>
        <div
          style={{ fontSize: 22, color: token.colorPrimary, fontWeight: 700 }}
        >
          {getTooltipBody ? getTooltipBody(payload[0]) : payload[0].value}
        </div>
      </CustomShadowedContent>
    );
  }

  return null;
};

export const CustomAreaChat = <T extends Record<string, any>>({
  data,
  xAxisLabelKey,
  dataKey,
  height = 338,
  width = 302,
  tooltipProps,
  xaxisProps,
  yaxisProps,
  getTooltipLabel,
  getTooltipBody,
  fill = (payload, hover) => (hover ? "#1D2645" : "#EAEAEA"),
  margin,
}: {
  data: T[];
  xAxisLabelKey: keyof T;
  dataKey: keyof T;
  width?: number;
  height?: number;
  tooltipProps?: TooltipProps<any, any>;
  xaxisProps?: XAxisProps;
  yaxisProps?: YAxisProps;
  getTooltipLabel?: (key: { payload: T }) => string | number;
  getTooltipBody?: (key: { payload: T }) => string | number;
  fill?: string | ((payload?: T, hover?: boolean) => string);
  margin?: Margin;
}): JSX.Element => {
  const { token } = theme.useToken();

  return (
    <AreaChart
      className="bar-chart-pading-disabled"
      data={data}
      width={width}
      height={height}
      margin={margin}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={token.colorPrimary} stopOpacity={0.8} />
          <stop offset="95%" stopColor={token.colorPrimary} stopOpacity={0.1} />
        </linearGradient>
      </defs>
      <YAxis {...yaxisProps} />
      <XAxis
        tickMargin={10}
        dataKey={xAxisLabelKey as string}
        axisLine={false}
        tickLine={false}
        {...xaxisProps}
      />
      <Tooltip
        contentStyle={{ borderRadius: 12 }}
        itemStyle={{ color: token.colorTextSecondary }}
        content={
          <CustomTooltip
            getTooltipLabel={getTooltipLabel}
            getTooltipBody={getTooltipBody}
          />
        }
        {...tooltipProps}
      />
      <Area
        type="monotone"
        dataKey={dataKey as string}
        stroke={token.colorPrimary}
        fillOpacity={1}
        fill="url(#colorUv)"
      />
    </AreaChart>
  );
};
