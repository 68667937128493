import { Tag, TagProps } from "antd";
import { CSSProperties, MouseEventHandler } from "react";

export const CustomTag = (
  props: TagProps & {
    key?: string | null;
    onClick?: MouseEventHandler<HTMLSpanElement>;
  }
) => {
  return <Tag {...props}>{props.children}</Tag>;
};

export const ManualIconButtonTag = (
  props: {
    onClick?: () => void;
  } & TagProps
) => {
  return (
    <CustomTag
      style={{
        boxShadow: "0 0.5mm 1mm rgba(0, 0, 0, 0.3)",
        ...props.style,
      }}
      {...props}
    />
  );
};
