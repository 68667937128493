import { Button, Flex, notification, Skeleton, Space, theme } from "antd";
import {
  useFetchDeliveryCompanyApi,
  usePostDeliveryCompanyApi,
  useEditDeliveryCompanyApi,
} from "api/delivery_company";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  DeliveryCompany,
  DeliveryCompanyType,
  DeliveryCompanyTypes,
} from "entities/delivery_company";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import {
  CustomInputField,
  CustomInputNumberField,
  CustomSelectField,
  CustomSelectRadioField,
  CustomTextAreaField,
  CustomTimeRangeField,
  KanaValidation,
  LabelView,
  StringValidation,
  ZipCodeValidation,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import {
  Form,
  FormAttrType,
  useEffectSkipFirst,
  useForm,
  useQuery,
} from "utils/hooks";
import { DeleteOutlined } from "@ant-design/icons";

import { CustomContentFooter } from "specifics/footer";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { filterKanaName, hankaku2Zenkaku } from "utils/util";
import dayjs from "dayjs";
import { DescriptionBlock } from "specifics/description";
import { GoogleMapComponent } from "utils/google_map_component";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { useFetchAllDeliveryPartnerCompaniesApi } from "api/delivery_partner_company";
import useMedia from "use-media";
import {
  TotalTime,
  AcceptanceTime,
  WaitingTime,
  HandlingTerm,
  DeliveryTerm,
  HANDLING_TASK,
  NEED_TASK,
  ReceiptHandOverTime,
} from "entities/contract";
import { Point, PointType } from "entities/point";
import { ManualMarkers } from "entities/manual";
import { useFetchAllRoutesApi } from "api/route";
import { Route } from "entities/route";

const FormView = ({
  form,
  validationResultForm,
  deliveryPartnerCompanies,
  deliveryPointCompanies,
  routes,
  originalValue,
}: {
  form: Form<DeliveryCompany>;
  validationResultForm: Form<any>;
  deliveryPartnerCompanies: DeliveryPartnerCompany[];
  routes: Route[];
  deliveryPointCompanies?: DeliveryCompany[];
  originalValue?: DeliveryCompany;
}) => {
  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "type", true);
      StringValidation(f, form, "name", true);
      KanaValidation(f, form, "kana", false);
      ZipCodeValidation(f, form, "zipcode", false);
      StringValidation(f, form, "address", true);
    });
  }, [JSON.stringify(form.object)]);

  const pointTypeForm = useForm<Point>({});
  const deliveryCompanyPoints = useRef<DeliveryCompany[]>([
    form.object,
    ...(deliveryPointCompanies || []),
  ]);
  const selectedDeliveryCompanyPointIndex = useMemo(() => {
    return form.object.deliveryCompanies?.findIndex(
      (company) =>
        company.point?.latitude === pointTypeForm.object.latitude &&
        company.point?.longitude === pointTypeForm.object.longitude
    );
  }, [
    JSON.stringify(form.object.deliveryCompanies),
    pointTypeForm.object.latitude,
  ]);

  useEffectSkipFirst(() => {
    if (
      // 選択中のpointTypeFormと座標が一致する行先、地点がなければ
      !deliveryCompanyPoints.current?.find(
        (company) =>
          JSON.stringify([
            company.point?.latitude,
            company.point?.longitude,
          ]) ===
          JSON.stringify([
            pointTypeForm.object.latitude,
            pointTypeForm.object.longitude,
          ])
      )
    ) {
      deliveryCompanyPoints.current = [
        ...deliveryCompanyPoints.current,
        pointTypeForm.object.pointType !== PointType.削除
          ? {
            point: pointTypeForm.object,
            type: form.object.type,
            availableHours: `09:00~18:00`,
            name: form.object.name,
            kana: form.object.kana,
            deliveryPartnerCompanies: form.object.deliveryPartnerCompanies,
            zipcode: form.object.zipcode,
            address: form.object.address,
            urls: [{ name: "", url: "" }]
          }
          : {},
      ].slice();
      form.updateObject(
        "deliveryCompanies",
        // deliveryCompaniesに元の地点は含めない
        deliveryCompanyPoints.current.filter(
          (company) => company.id !== form.object.id
        )
      );
    }
  }, [pointTypeForm.object.latitude]);

  return (
    <Space style={{ width: "100%" }} size={16} direction="vertical">
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        label="行先の種別"
        attr="type"
        style={{ width: 223 }}
        required
        selectItems={Object.keys(DeliveryCompanyTypes)
          .map((key) => Number(key) as DeliveryCompanyType)
          .map((key) => ({
            label: DeliveryCompanyTypes[key],
            value: key,
          }))}
      />
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="名称"
        attr="name"
        required
      />
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="ふりがな（全角ひらがな、スペースなし）"
        attr="kana"
      />
      <Space direction="vertical">
        <CustomInputField
          form={form}
          validationResultForm={validationResultForm}
          label="郵便番号"
          onChange={(e) => {
            const value = hankaku2Zenkaku(e.target.value);
            if (
              e.target.value.length === 0 ||
              value.match(/^\d{1,3}-?\d{0,4}$/)
            ) {
              form.updateObject(
                "zipcode",
                value.length > 3 && value[3] !== "-" && !value.includes("-")
                  ? value.slice(0, 3) + "-" + value.slice(3)
                  : value.slice(0, 8)
              );
            } else {
              console.log("invalid");
            }
          }}
          attr="zipcode"
          style={{ width: 223 }}
        />
        {/* <CustomButton
          style={{ height: 32, width: 223 }}
          onClick={async () => {
            globalState.setLoading(true);
            const res = await findAddressByZip(form.object.zipcode ?? "");
            globalState.setLoading(false);
            if (res.status === 200) {
              if (res.data.prefecture && res.data.city && res.data.street) {
                form.updateObject(
                  "address",
                  `${res.data.prefecture}${res.data.city}${res.data.street}`
                );
              } else {
                notification.error({
                  message: `Error`,
                  description: "郵便番号が見つかりませんでした。",
                });
              }
            } else {
              notification.error({
                message: `Error (Status Code is ${res.status})`,
                description: res.message,
              });
            }
          }}
          type="primary"
        >
          郵便番号から住所を検索
        </CustomButton> */}
      </Space>
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        mode="multiple"
        label="担当運送会社"
        attr={"deliveryPartnerCompanies"}
        fieldProps={{
          value: form.object.deliveryPartnerCompanies?.map(
            (company) => company.id
          ),
          filterOption: filterKanaName(deliveryPartnerCompanies),
        }}
        style={{ width: "100%" }}
        selectItems={deliveryPartnerCompanies.map((deliveryPartnerCompany) => ({
          label: deliveryPartnerCompany.name,
          value: deliveryPartnerCompany.id,
        }))}
        onChange={(ids: string[]) => {
          const selectedDeliveryPartnerCompanies = ids.map((id) =>
            deliveryPartnerCompanies.find((company) => company.id === id)
          );
          form.update((f) => {
            f.deliveryPartnerCompanies =
              selectedDeliveryPartnerCompanies as DeliveryPartnerCompany[];
            if (f.deliveryCompanies?.length) {
              f.deliveryCompanies = f.deliveryCompanies?.map((d) => ({
                ...d,
                deliveryPartnerCompanies:
                  selectedDeliveryPartnerCompanies as DeliveryPartnerCompany[],
              }));
            }
          });
        }}
      />
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="住所"
        attr="address"
        required
      />
      {!!form.object.id && (
        <div>
          <LabelView label="座標を指定" />
          <>
            <GoogleMapComponent
              isDeliveryCompany
              pointTypeForm={pointTypeForm}
              mapCenter={{
                lat: form.object.point?.latitude ?? 0,
                lng: form.object.point?.longitude ?? 0,
              }}
              markerOptions={[
                ...(deliveryCompanyPoints.current?.map((company) => ({
                  data: company.point,
                  position: {
                    lat: company.point?.latitude ?? 0,
                    lng: company.point?.longitude ?? 0,
                  },
                  icon: ManualMarkers.find(
                    (manualMaker) =>
                      manualMaker.pointType === company.point?.pointType
                  )?.icon,
                  draggable: true,
                  visible: true,
                })) ?? []),
              ]}
              onDbclick={(e: google.maps.MapMouseEvent) => {
                pointTypeForm.set({
                  latitude: e.latLng?.lat(),
                  longitude: e.latLng?.lng(),
                });
              }}
              onClickMarker={(
                e: google.maps.MapMouseEvent,
                marker: google.maps.Marker
              ) => {
                const targetPoint =
                  e.latLng?.lat() === form.object.point?.latitude &&
                    e.latLng?.lng() === form.object.point?.longitude
                    ? form.object.point
                    : deliveryCompanyPoints.current?.find(
                      (company) =>
                        JSON.stringify([
                          company.point?.latitude,
                          company.point?.longitude,
                        ]) ===
                        JSON.stringify([e.latLng?.lat(), e.latLng?.lng()])
                    )?.point;
                pointTypeForm.set({ ...targetPoint });
                return targetPoint;
              }}
              onDbclickMarker={(e) => {
                deliveryCompanyPoints.current = [
                  ...(deliveryCompanyPoints.current?.filter(
                    (company) =>
                      JSON.stringify([
                        company.point?.latitude,
                        company.point?.longitude,
                      ]) !== JSON.stringify([e.latLng?.lat(), e.latLng?.lng()])
                  ) ?? []),
                ].slice();
                form.updateObject(
                  "deliveryCompanies",
                  // deliveryCompaniesに元の地点は含めない
                  deliveryCompanyPoints.current.filter(
                    (company) => company.id !== form.object.id
                  )
                );
              }}
              onDragstartMarker={(e) => {
                const targetPoint = deliveryCompanyPoints.current?.find(
                  (company) =>
                    JSON.stringify([
                      company.point?.latitude,
                      company.point?.longitude,
                    ]) === JSON.stringify([e.latLng?.lat(), e.latLng?.lng()])
                )?.point;
                pointTypeForm.set({ ...targetPoint });
                return targetPoint;
              }}
              onDragendMarker={(e, data: Point) => {
                if (e.latLng) {
                  const targetCompany = deliveryCompanyPoints.current?.find(
                    (company) =>
                      JSON.stringify([
                        company.point?.latitude,
                        company.point?.longitude,
                      ]) === JSON.stringify([data.latitude, data.longitude])
                  );
                  const point = {
                    ...targetCompany?.point,
                    latitude: e.latLng.lat(),
                    longitude: e.latLng.lng(),
                  };
                  deliveryCompanyPoints.current = [
                    ...(deliveryCompanyPoints.current?.filter(
                      (company) =>
                        JSON.stringify([
                          company.point?.latitude,
                          company.point?.longitude,
                        ]) !== JSON.stringify([data.latitude, data.longitude])
                    ) || []),
                    {
                      ...targetCompany,
                      point: point,
                    },
                  ].slice();
                  if (data.id === form.object.point?.id) {
                    form.updateObject("point", point);
                  } else {
                    form.updateObject(
                      "deliveryCompanies",
                      // deliveryCompaniesに元の地点は含めない
                      deliveryCompanyPoints.current.filter(
                        (company) => company.id !== form.object.id
                      )
                    );
                  }

                  pointTypeForm.set(point);
                }
              }}
            />
            <CustomButton
              onClick={() => {
                form.update((f) => {
                  f.point = {
                    ...f.point,
                    latitude: originalValue?.point?.latitude,
                    longitude: originalValue?.point?.longitude,
                  };
                });
                deliveryCompanyPoints.current =
                  deliveryCompanyPoints.current.map((item) =>
                    // 元地点の更新を行う
                    item.id === originalValue?.id
                      ? {
                        ...item,
                        point: {
                          ...item.point,
                          latitude: originalValue?.point?.latitude,
                          longitude: originalValue?.point?.longitude,
                        },
                      }
                      : item
                  );
              }}
              disabled={
                form.object.point?.latitude ===
                originalValue?.point?.latitude &&
                form.object.point?.longitude === originalValue?.point?.longitude
              }
              style={{ width: "100%", marginTop: 4 }}
            >
              もとに戻す
            </CustomButton>
          </>
        </div>
      )}
      <FormItemView
        form={form}
        validationResultForm={validationResultForm}
        routes={routes}
        preAttr={
          typeof selectedDeliveryCompanyPointIndex === "number" &&
            selectedDeliveryCompanyPointIndex >= 0
            ? ["deliveryCompanies", selectedDeliveryCompanyPointIndex]
            : undefined
        }
      />
    </Space>
  );
};

const FormItemView = ({
  form,
  validationResultForm,
  routes,
  preAttr,
}: {
  form: Form<DeliveryCompany>;
  validationResultForm: Form<any>;
  routes: Route[];
  preAttr?: (string | number)[];
}) => {
  const isMobile = useMedia("(max-width: 519px)");
  const formAttr = (
    attr: FormAttrType<DeliveryCompany>
  ): FormAttrType<DeliveryCompany> | (string | number)[] => {
    if (Array.isArray(attr)) {
      return preAttr ? [...preAttr, ...attr] : attr;
    } else {
      return preAttr ? [...preAttr, attr] : attr;
    }
  };

  useEffect(() => {
    const availableHours = form.getValue(formAttr("availableHours")) as string;
    form.updateObject(
      formAttr("availableHours"),
      availableHours
        ?.split(",")
        .filter((availableHour) => availableHour !== "null")
        .join(",")
    );
  }, [form.getValue(formAttr("availableHours"))]);

  return (
    <Space style={{ width: "100%" }} size={16} direction="vertical">
      <Space>
        {(form.object.deliveryCompanies || []).length > 0 && (
          <CustomInputField
            form={form}
            validationResultForm={validationResultForm}
            label="地点の名称"
            attr={preAttr ? [...formAttr("point"), "memo"] : ["point", "memo"]}
          />
        )}
        <CustomSelectField
          form={form}
          validationResultForm={validationResultForm}
          label="ルートコード"
          mode="multiple"
          selectItems={routes.map(route => ({
            label: route.code,
            value: route.id
          }))}
          onChange={(e) => {
            form.updateObject(
              formAttr("routes"),
              routes.filter(route => e.includes(route.id))
            )
          }}
          fieldProps={{
            value: (form.getValue(formAttr("routes")) as Route[])?.map(route => route.id)
          }}
          attr={formAttr("routes")}
        />
      </Space>
      <DescriptionBlock
        valueStyle={{ display: "flex", gap: 8, flexDirection: "column" }}
        label={<LabelView label="受入可能時間" />}
      >
        {(form.getValue(formAttr("availableHours")) as string)
          ?.split(",")
          .map((time, index) => {
            const format = "HH:mm";
            const [from, to] = time.split("~");
            return (
              <Space key={index}>
                <CustomTimeRangeField
                  fieldProps={{
                    format,
                    // @ts-ignore
                    defaultValue: [dayjs(from, format), dayjs(to, format)], // ToDo: Fix Bug
                    style: { width: 223 },
                    // @ts-ignore
                    value: [dayjs(from, format), dayjs(to, format)], // ToDo: Fix Bug
                    minuteStep: 5,
                    allowClear: false,
                    order: false,
                  }}
                  form={form}
                  validationResultForm={validationResultForm}
                  attr={formAttr("availableHours")}
                  onChange={(e) => {
                    const value =
                      e[0].format("HH:mm") + "~" + e[1].format("HH:mm");
                    const currentList =
                      (
                        form.getValue(formAttr("availableHours")) as string
                      )?.split(",") ?? [];
                    if (e[0].format("HH:mm") === e[1].format("HH:mm")) {
                      notification.error({
                        message:
                          "開始時間と終了時間は異なる時刻を入力してください。",
                      });
                    } else {
                      currentList[index] = value;
                    }
                    form.updateObject(
                      formAttr("availableHours"),
                      currentList.join(",")
                    );
                  }}
                />
                {((form.getValue(formAttr("availableHours")) as string)?.split(
                  ","
                ).length ?? 0) > 1 && (
                    <Button
                      shape="circle"
                      icon={<DeleteOutlined style={{ color: "#B91C1C" }} />}
                      type="dashed"
                      onClick={() => {
                        const currentList =
                          (
                            form.getValue(formAttr("availableHours")) as string
                          ).split(",") ?? [];
                        form.updateObject(
                          formAttr("availableHours"),
                          currentList.filter((_, i) => index !== i).join(",")
                        );
                      }}
                    />
                  )}
              </Space>
            );
          })}
        <CustomButton
          type="primary"
          onClick={() =>
            form.updateObject(
              formAttr("availableHours"),
              (form.getValue(formAttr("availableHours"))
                ? form.object.availableHours + ","
                : "") + "09:00~18:00"
            )
          }
          style={{ width: 223, height: 32 }}
        >
          受入可能時間を追加
        </CustomButton>
      </DescriptionBlock>
      <CustomInputNumberField
        form={form}
        validationResultForm={validationResultForm}
        label="受入可能車両"
        attr={formAttr("maxWeight")}
        style={{ width: 123 }}
        fieldProps={{ addonAfter: "t", min: 0, precision: 0 }}
      />

      {/* 契約条件 */}
      {/* 時間 */}
      <CustomSelectRadioField
        form={form}
        isManual
        validationResultForm={validationResultForm}
        label="入門〜出門までの所要時間"
        attr={formAttr("totalTime")}
        style={{ width: "100%" }}
        selectItems={Object.entries(TotalTime)
          .filter(([label, value]) => typeof value === "number")
          .map(([label, value]) => ({
            label,
            value,
          }))}
        column={isMobile ? 1 : 2}
      />
      <CustomSelectRadioField
        form={form}
        isManual
        validationResultForm={validationResultForm}
        label="受入の所要時間"
        attr={formAttr("acceptanceTime")}
        style={{ width: "100%" }}
        selectItems={Object.entries(AcceptanceTime)
          .filter(([label, value]) => typeof value === "number")
          .map(([label, value]) => ({
            label,
            value,
          }))}
        column={isMobile ? 1 : 2}
      />
      <CustomSelectRadioField
        form={form}
        isManual
        validationResultForm={validationResultForm}
        label="バース待ち時間"
        attr={formAttr("waitingTimeForBerth")}
        style={{ width: "100%" }}
        selectItems={Object.entries(WaitingTime)
          .filter(([label, value]) => typeof value === "number")
          .map(([label, value]) => ({
            label,
            value,
          }))}
        column={process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1}
      />
      <CustomSelectRadioField
        form={form}
        isManual
        validationResultForm={validationResultForm}
        label="荷役待ち時間"
        attr={formAttr("waitingTimeForHandling")}
        style={{ width: "100%" }}
        selectItems={Object.entries(WaitingTime)
          .filter(([label, value]) => typeof value === "number")
          .map(([label, value]) => ({
            label,
            value,
          }))}
        column={process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1}
      />
      <CustomSelectRadioField
        form={form}
        isManual
        validationResultForm={validationResultForm}
        label="納品書/受領書の受け渡しの待ち時間"
        attr={formAttr("waitingTimeForReceipt")}
        style={{ width: "100%" }}
        selectItems={Object.entries(ReceiptHandOverTime)
          .filter(([label, value]) => typeof value === "number")
          .map(([label, value]) => ({
            label,
            value,
          }))}
        column={process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1}
      />
      {/* 条件 */}
      <CustomSelectRadioField
        form={form}
        isManual
        validationResultForm={validationResultForm}
        label="荷役"
        attr={formAttr("handlingTerm")}
        style={{ width: "100%" }}
        selectItems={Object.entries(HandlingTerm)
          .filter(([label, value]) => typeof value === "number")
          .map(([label, value]) => ({
            label,
            value,
          }))}
        column={isMobile ? 1 : 2}
      />
      <CustomSelectRadioField
        form={form}
        isManual
        validationResultForm={validationResultForm}
        label="荷渡し条件"
        attr={formAttr("deliveryTerm")}
        style={{ width: "100%" }}
        selectItems={Object.entries(DeliveryTerm)
          .filter(([label, value]) => typeof value === "number")
          .map(([label, value]) => ({
            label,
            value,
          }))}
        onChange={(e) => {
          if (preAttr) {
            form.updateObject(formAttr("deliveryTerm"), e.target.value);
            form.updateObject(formAttr("deliveryTermOther"), null);
          } else {
            form.update((f) => {
              f.deliveryTerm = e.target.value;
              f.deliveryTermOther = null;
            });
          }
        }}
        column={isMobile ? 1 : 2}
      />
      <CustomInputField
        label="荷渡し条件(自由入力欄)"
        form={form}
        validationResultForm={validationResultForm}
        fieldProps={{
          disabled:
            form.getValue(formAttr("deliveryTerm")) !== DeliveryTerm.その他,
        }}
        attr={formAttr("deliveryTermOther")}
        style={{ width: "100%" }}
      />
      {/* 作業 */}
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        label="荷役作業(その他を選択したら自由入力可能)"
        mode="multiple"
        attr={formAttr("handlingTask")}
        style={{ width: "100%" }}
        onChange={(e) =>
          form.updateObject(
            formAttr("handlingTask"),
            e.sort((a: string, b: string) => (a < b ? 1 : -1)).join(",")
          )
        }
        fieldProps={{
          value: (form.getValue(formAttr("handlingTask")) as string)?.split(
            ","
          ),
          children: <></>,
        }}
        selectItems={HANDLING_TASK.map((item) => ({
          label: item,
          value: item,
        }))}
      />
      <CustomInputField
        label="荷役作業(自由入力欄)"
        form={form}
        validationResultForm={validationResultForm}
        fieldProps={{
          disabled: !(
            form.getValue(formAttr("handlingTask")) as string
          )?.includes("その他"),
        }}
        attr={formAttr("handlingTaskOther")}
        style={{ width: "100%" }}
      />
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        label="附帯業務(その他を選択したら自由入力可能)"
        mode="multiple"
        attr={formAttr("needTask")}
        style={{ width: "100%" }}
        onChange={(e) =>
          form.updateObject(
            formAttr("needTask"),
            e.sort((a: string, b: string) => (a < b ? 1 : -1)).join(",")
          )
        }
        fieldProps={{
          value: (form.getValue(formAttr("needTask")) as string)?.split(","),
          children: <></>,
        }}
        selectItems={NEED_TASK.map((item) => ({
          label: item,
          value: item,
        }))}
      />
      <CustomInputField
        label="附帯業務(自由入力欄)"
        form={form}
        validationResultForm={validationResultForm}
        fieldProps={{
          disabled: !(form.getValue(formAttr("needTask")) as string)?.includes(
            "その他"
          ),
        }}
        attr={formAttr("needTaskOther")}
        style={{ width: "100%" }}
      />

      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="担当者氏名"
        attr={formAttr("repName")}
        style={{ width: 180 }}
      />
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="担当者電話番号"
        onChange={(e) => {
          const value = hankaku2Zenkaku(e.target.value);
          if (value.match(/^\d{0,13}$/)) {
            form.updateObject(formAttr("repTel"), value.slice(0, 13));
          } else {
            console.log("invalid");
          }
        }}
        attr={formAttr("repTel")}
        style={{ width: 180 }}
      />
      <CustomTextAreaField
        form={form}
        validationResultForm={validationResultForm}
        label="備考"
        attr={formAttr("memo")}
      />
      <DescriptionBlock
        label="URL"
        valueStyle={{ display: "flex", gap: 8, flexDirection: "column" }}
      >
        {((form.getValue(formAttr("urls"))) as [] || [{}]).map((url, index) =>
          <Flex key={index}>
            <CustomInputField
              form={form}
              validationResultForm={validationResultForm}
              placeholder="名称"
              attr={formAttr(["urls", index, "name"])}
            />
            <CustomInputField
              form={form}
              validationResultForm={validationResultForm}
              placeholder="URL"
              attr={formAttr(["urls", index, "url"])}
              onlyHankaku
            />
            {((form.getValue(formAttr("urls")) as [])?.length || 0) > 1 && (
              <Button
                shape="circle"
                icon={<DeleteOutlined style={{ color: "#B91C1C" }} />}
                type="dashed"
                onClick={() => {
                  const urls = form.getValue(formAttr("urls")) as { name: string; url: string }[];
                  urls.splice(index, 1); // 指定されたindexの要素を削除
                  form.updateObject(formAttr("urls"), urls);
                }}
              />
            )}
          </Flex>
        )}
        <CustomButton
          type="primary"
          style={{ width: 223, height: 32 }}
          onClick={() =>
            form.updateObject(formAttr("urls"), [
              ...(form.getValue(formAttr("urls")) as [] ?? []),
              {}
            ])
          }
        >
          URLを追加
        </CustomButton>
      </DescriptionBlock>
    </Space>
  );
};

const _deliveryCompaniesCreatePage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const query = useQuery<{ isTemporary?: boolean }>();
  const globalState = useContext(GlobalStateContext);
  const deliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const routesApi = useFetchAllRoutesApi();
  const form = useForm<DeliveryCompany>({
    availableHours: `09:00~18:00`,
    urls: [{ name: "", url: "" }]
  });
  const postApi = usePostDeliveryCompanyApi();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    deliveryPartnerCompaniesApi.execute();
    routesApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(deliveryPartnerCompaniesApi.loading);
  }, [deliveryPartnerCompaniesApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      if (query.isTemporary) {
        window.close();
      } else {
        props.history.goBack();
      }
    }
  }, [postApi.loading]);
  const validationResultForm = useForm<Record<string, string | undefined>>({});
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="行先登録"
      childrenStyle={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        overflowY: "scroll",
      }}
      handleLogoClick={() =>
        props.history.push("/operations/delivery-companies")
      }
    >
      <CustomShadowedContent style={{ padding: 0, overflow: "visible" }}>
        <div style={{ padding: 24 }}>
          {/* {FormView(form, validationResultForm, deliveryPartnerCompaniesApi.response.data ?? [])} */}
          <FormView
            form={form}
            validationResultForm={validationResultForm}
            deliveryPartnerCompanies={
              deliveryPartnerCompaniesApi.response.data || []
            }
            routes={routesApi.response.data || []}
          />
        </div>
        <CustomContentFooter>
          <CustomButton
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) => validationResultForm.object[key] === undefined
                )
              ) {
                postApi.execute(form);
              }
            }}
            type="primary"
          >
            登録
          </CustomButton>
          <CustomButton
            onClick={() => props.history.push("/operations/delivery-companies")}
            confirm
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

const _deliveryCompaniesEditPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const deliveryCompanyApi = useFetchDeliveryCompanyApi();
  const deliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const routesApi = useFetchAllRoutesApi();
  const params = useParams<{ id: string }>();
  const form = useForm<DeliveryCompany>({});
  const editApi = useEditDeliveryCompanyApi();
  const validationResultForm = useForm<Record<string, string | undefined>>({});
  const { token } = theme.useToken();
  useEffect(() => {
    deliveryCompanyApi.execute(params.id);
    deliveryPartnerCompaniesApi.execute();
    routesApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(
      deliveryCompanyApi.loading || deliveryPartnerCompaniesApi.loading
    );
    if (deliveryCompanyApi.isSuccess()) {
      form.set({
        ...deliveryCompanyApi.response.data,
        deliveryCompanies:
          deliveryCompanyApi.response.data.deliveryCompanies?.map(
            (company) => ({
              ...company,
            })
          ),
        urls: (deliveryCompanyApi.response.data.urls || [])[0]?.name ?
          deliveryCompanyApi.response.data.urls : // urlが空の時は値を整形
          [{ name: "", url: "" }]
      });
    }
  }, [deliveryCompanyApi.loading, deliveryPartnerCompaniesApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.history.push(`/operations/delivery-companies/${params.id}`);
    }
  }, [editApi.loading]);

  const deliveryCompany = () => deliveryCompanyApi.response.data || [];
  const deliveryPartnerCompanies = () =>
    deliveryPartnerCompaniesApi.response.data || [];
  const deliveryPointCompanies = () =>
    deliveryCompany().deliveryCompanies || [];
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="行先編集"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() =>
        props.history.push("/operations/delivery-companies")
      }
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <Skeleton loading={deliveryCompanyApi.loading}>
          <div style={{ padding: 24 }}>
            {form.object.id && deliveryPartnerCompanies().length > 0 && (
              <FormView
                form={form}
                validationResultForm={validationResultForm}
                deliveryPartnerCompanies={deliveryPartnerCompanies()}
                routes={routesApi.response.data || []}
                deliveryPointCompanies={deliveryPointCompanies()}
                originalValue={deliveryCompany()}
              />
            )}
          </div>
        </Skeleton>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) => validationResultForm.object[key] === undefined
                )
              ) {
                editApi.execute(form);
              }
            }}
          >
            保存
          </CustomButton>
          <CustomButton
            onClick={() => props.history.push("/operations/delivery-companies")}
            confirm
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const DeliveryCompaniesEditPage = withRouter(_deliveryCompaniesEditPage);

export const DeliveryCompaniesCreatePage = withRouter(
  _deliveryCompaniesCreatePage
);
