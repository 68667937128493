import { PlusOutlined } from '@ant-design/icons';

interface AddRotationButtonProps {
    onClick: () => () => void;
}

const AddRotationButton: React.FC<AddRotationButtonProps> = ({ onClick }) => (
    <div onClick={onClick()} style={{ background: '#E5E7EB', width: 55, height: 363, display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
        <div style={{ color: '#9CA3AF', height: 'fit-content', textAlign: 'center' }}>
            <PlusOutlined style={{ fontSize: 23 }} />
            <p style={{ marginTop: 10, fontSize: 14 }}>回転を<br />増やす</p>
        </div>
    </div>
);

export default AddRotationButton;