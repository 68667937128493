import React, { ReactNode, useContext, useEffect, useState } from "react";
import {
  AnalysisBaseSearchFormDatetimeFormat,
  AnalysisSearchContext,
} from "contexts/analysis_search_context";
import dayjs from "dayjs";

export type ConfirmOption = {
  confirmWord?: string;
  backdropStatic?: boolean;
  onCancel?: () => void;
  hideCancel?: boolean;
};

export type Dimension = {
  width: number;
  height: number;
};

type GlobalStateContainerProps = {
  children: ReactNode;
};

const AnalysisSearchContainer: React.FC<GlobalStateContainerProps> = (
  props: GlobalStateContainerProps
) => {
  const [datetime, setDatetime] = useState<string>(
    dayjs().endOf("month").format()
  );
  console.log(datetime);

  return (
    <AnalysisSearchContext.Provider
      value={{
        datetime,
        setDatetime,
      }}
    >
      <div>{props.children}</div>
    </AnalysisSearchContext.Provider>
  );
};

export default AnalysisSearchContainer;
