import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useIndexApi,
  usePostApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import {
  AssignedPartnerCompanyDataForm,
  DeliveryPartnerCompany,
  DeliveryPartnerCompanyForm,
  DeliveryPartnerCompanySearchForm,
} from "entities/delivery_partner_company";
import { ID, PagingResponse } from "entities";
import { DraftResponse } from "./draft";

type AllDeliveryPartnerCompaniesResponse = BaseResponse<DeliveryPartnerCompany[]>;

export function useFetchAllDeliveryPartnerCompaniesApi(): ApiSet<AllDeliveryPartnerCompaniesResponse> & {
  execute: () => void;
} {
  const api = useIndexApi<AllDeliveryPartnerCompaniesResponse>(new HttpClient(), {
    initialResponse: { data: [] },
  });

  const execute = (): void => {
    const apiPath = `delivery_partner_company/all`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export type DeliveryPartnerCompaniesResponse = PagingResponse<DeliveryPartnerCompany>;

export function useFetchDeliveryPartnerCompaniesApi(
  searchForm?: Form<DeliveryPartnerCompanySearchForm>
): IndexApiSet<DeliveryPartnerCompaniesResponse> & {
  execute: (query?: string) => void;
} {
  const apiPath = "delivery_partner_company";
  const api = useIndexApi<DeliveryPartnerCompaniesResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type DeliveryPartnerCompanyResponse = BaseResponse & {
  data: DeliveryPartnerCompany;
};

export function useFetchDeliveryPartnerCompanyApi(): ApiSet<DeliveryPartnerCompanyResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<DeliveryPartnerCompanyResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `delivery_partner_company/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostDeliveryPartnerCompanyApi(): ApiSet<DeliveryPartnerCompanyResponse> & {
  execute: (form: Form<DeliveryPartnerCompanyForm>) => void;
} {
  const apiSet = usePostApi<DeliveryPartnerCompanyResponse, DeliveryPartnerCompanyForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<DeliveryPartnerCompanyForm>) => {
    const apiPath = `delivery_partner_company`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditDeliveryPartnerCompanyApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<DeliveryPartnerCompanyForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, DeliveryPartnerCompanyForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<DeliveryPartnerCompanyForm>) => {
    const apiPath = `delivery_partner_company/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteDeliveryPartnerCompanyApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `delivery_partner_company/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}


export type DeliveryPartnerCompanyDataResponse = DraftResponse

export function useFetchDeliveryPartnerCompanyDataApi(): ApiSet<DeliveryPartnerCompanyDataResponse> & {
  execute: () => void;
} {
  const api = useShowApi<DeliveryPartnerCompanyDataResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (): void => {
    const apiPath = `delivery_partner_company/data`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function useEditDeliveryPartnerCompanyDataApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<AssignedPartnerCompanyDataForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, AssignedPartnerCompanyDataForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<AssignedPartnerCompanyDataForm>) => {
    const apiPath = `delivery_partner_company/data`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}