import { Delivery, calculateTotalLoadWeight } from "entities/delivery";
import { useContext, useEffect, useMemo, useState } from "react";
import { GoogleMapComponent } from "utils/google_map_component";
import { Form, useEffectSkipFirst } from "utils/hooks";
import _ from "lodash";
import { Divider, Skeleton, Space, theme } from "antd";
import { GlobalStateContext } from "contexts/global_state_context";
import { CustomTag } from "specifics/tag";
import { Vehicle } from "entities/vehicle";
import { DeliveryCompany } from "entities/delivery_company";
import { HistoryProps } from "routes/app";
import { CookieManager } from "utils/cookie_manager";
import dayjs from "dayjs";
import { DeliveryTerm } from "entities/contract";

export const DeliveriesMaps = (
  props: {
    form: Form<Delivery[]>; // FormのDelivery[] (可変)
    vehicles: Vehicle[];
    selectedDate: Date;
  } & HistoryProps
) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();

  const [vehicleIds, setVehicleIds] = useState<
    (string | undefined)[] | undefined
  >(undefined);

  useEffect(() => {
    if (!vehicleIds) {
      setVehicleIds(CookieManager.getMapActiveVehicleKeys(props.selectedDate));
    } else {
      CookieManager.setMapActiveVehicleKeys(props.selectedDate, vehicleIds);
    }
  }, [vehicleIds]);

  const deliveriesOwn = props.form.object?.filter(
    // 自社のdelivery一覧
    (d: Delivery) => d.charterType === 0
  );

  const nVehicles = useMemo(() => {
    const list = _.uniq(
      props.form.object
        .filter((delivery) => delivery.charterType === 0)
        .map((delivery) => delivery.vehicleId)
    );

    return list.length ?? 1;
  }, [JSON.stringify(props.form.object)]);

  const colors = _.uniq(
    props.form.object
      .filter((delivery) => delivery.vehicleId)
      .map((delivery) => delivery.vehicleId)
  ).map((id, index) => ({
    id,
    color: `hsl(${index * (360 / nVehicles)}, 80%, 60%)`,
  }));

  const markerOptions: (google.maps.MarkerOptions & { data?: any })[] =
    useMemo(() => {
      return props.form.object
        ?.filter((delivery) => delivery.charterType === 0)
        .filter((delivery) => {
          // vehicleIdsで絞り込みをかける。
          return (
            vehicleIds?.includes(delivery.vehicleId) ||
            (!delivery.vehicleId && vehicleIds?.some((id) => !id)) // 未割当 : deliveryにはvehicleIdが紐づいておらず、vehicleIdsにnull | undefinedが含まれているときは、「未割当」にチェックが入っている。
          );
        })
        ?.map(
          (delivery, index) =>
            delivery.deliveryDetails
              ?.filter(
                (detail) =>
                  detail.deliveryCompany?.point?.latitude &&
                  detail.deliveryCompany?.point?.longitude
              )
              .map((detail) => {
                // 色はHue（色相）を変化させる。
                // Saturation（彩度）とLightness（輝度）は固定。
                const color = delivery.vehicleId
                  ? colors.find((color) => color.id === delivery.vehicleId)
                      ?.color
                  : "#9a9a9a";
                return {
                  position: {
                    lat: detail.deliveryCompany?.point?.latitude,
                    lng: detail.deliveryCompany?.point?.longitude,
                  },
                  icon: {
                    fillColor: color, //塗り潰し色
                    fillOpacity: 1, //塗り潰し透過率
                    path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
                    scale: 1.6,
                    strokeColor: color, //枠の色
                    anchor: { x: 12, y: 24 },
                  },
                  data: detail.deliveryCompany,
                  visible:true
                };
              }) as (google.maps.MarkerOptions & { data?: any })[]
        )
        .reduce((acc, val) => acc.concat(val), []);
    }, [JSON.stringify(props.form.object), vehicleIds?.length]);

  const N = markerOptions.length ?? 1;

  const centerOfGravity = useMemo(() => {
    if (markerOptions.length > 0) {
      return markerOptions
        .map((marker) => ({
          lat: Number(marker.position?.lat),
          lng: Number(marker.position?.lng),
        }))
        .reduce(
          (acc, cur) => {
            return {
              lat: acc.lat + cur.lat / N,
              lng: acc.lng + cur.lng / N,
            };
          },
          { lat: 0, lng: 0 }
        );
    }
  }, [markerOptions.length]);

  return (
    <Skeleton loading={globalState.loading || !vehicleIds}>
      <Space wrap style={{ marginBottom: 15 }}>
        {props.form.object
          .filter((deliver) => deliver.charterType === 0)
          .map((deliver) => deliver.vehicleId)
          .some((id) => !id) && (
          <CustomTag
            style={{
              padding: "0px 8px",
              background: token.colorWhite,
              boxShadow: "1px 2px 3px 0px #1d264533",
            }}
          >
            <Space style={{ height: 28, alignItems: "center" }}>
              <input
                onChange={(e) => {
                  if (vehicleIds?.some((id) => !id)) {
                    setVehicleIds(vehicleIds.filter((vehicleId) => vehicleId));
                  } else {
                    setVehicleIds([...(vehicleIds ?? []), undefined]);
                  }
                }}
                checked={vehicleIds?.some((id) => !id)}
                type="checkbox"
                style={{
                  verticalAlign: -3,
                  height: 16,
                  width: 16,
                  accentColor: "#bababa",
                }}
              />
              <div>未割当</div>
            </Space>
          </CustomTag>
        )}
        {colors.map((vehicleColor) => {
          const vehicle = props.vehicles.find(
            (vehicle) => vehicle.id === vehicleColor.id
          );
          const deliveriesRelatedToThisVehicle: Delivery[] = JSON.parse(
            JSON.stringify(deliveriesOwn)
          )?.filter((delivery: Delivery) => {
            if (vehicle) {
              // 未割当でない
              return String(delivery.vehicleId) === String(vehicle?.id);
            } else {
              // 未割当
              return !delivery.vehicleId;
            }
          });

          const currentLoadWeight: number = calculateTotalLoadWeight(
            deliveriesRelatedToThisVehicle,
            (detail) => detail.pointType === 2
          );

          const loadingTooMuch: boolean =
            currentLoadWeight > (vehicle?.maxWeight ?? 0);
          return (
            <CustomTag
              style={{
                padding: "10px",
                background: token.colorWhite,
                boxShadow: "1px 2px 3px 0px #1d264533",
                borderRadius: 16,
              }}
              key={vehicleColor.id}
            >
              <Space style={{ width: "100%" }} direction="vertical">
                <Space>
                  <input
                    onChange={(e) => {
                      if (vehicleIds?.includes(vehicle?.id)) {
                        setVehicleIds(
                          vehicleIds.filter(
                            (vehicleId) => vehicleId !== vehicle?.id
                          )
                        );
                      } else {
                        setVehicleIds([...(vehicleIds ?? []), vehicle?.id]);
                      }
                    }}
                    checked={vehicleIds?.includes(vehicle?.id)}
                    type="checkbox"
                    style={{
                      verticalAlign: -3,
                      height: 16,
                      width: 16,
                      accentColor: vehicleColor.color,
                    }}
                  />
                  <div style={{ fontWeight: 700, fontSize: 16 }}>
                    {vehicle?.vehicleCode}
                  </div>
                </Space>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: token.colorText,
                    width: 240,
                  }}
                >
                  ドライバー名: {vehicle?.user?.name}
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: token.colorText,
                    width: 240,
                  }}
                >
                  車格: {vehicle?.weight}t
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: token.colorText,
                    width: 240,
                  }}
                >
                  最大積載量: {vehicle?.maxWeight ?? "--"}t
                </div>
                <div
                  style={{
                    fontWeight: loadingTooMuch ? 700 : 500,
                    fontSize: 16,
                    color: loadingTooMuch ? token.colorError : token.colorText,
                    width: 240,
                  }}
                >
                  現在の総積載量:
                  {currentLoadWeight.toFixed(1)}t (
                  {Math.round(
                    (currentLoadWeight / (vehicle?.maxWeight ?? 1)) * 100
                  )}
                  %)
                </div>
              </Space>
            </CustomTag>
          );
        })}
      </Space>
      <GoogleMapComponent
        style={{ height: 500 }}
        onClickMarker={(e, marker, data: DeliveryCompany) => {
          const infowindow = new google.maps.InfoWindow({
            content: `<div>
              <div>名称: ${data.name}</div>
              <div>ふりがな: ${data.kana}</div>
              <div>郵便番号: 〒${data.zipcode}</div>
              <div>住所: ${data.address}</div>
              <br/>
              <div>受け入れ可能時間: ${data.availableHours}</div>
              <div>必要作業: ${data.needTask}</div>
              ${
                data.deliveryTerm
                  ? `<div>荷渡し条件: ${DeliveryTerm[data.deliveryTerm]}</div>`
                  : ""
              }
            </div>`,
          });
          infowindow.open(marker?.getMap(), marker);
        }}
        onDbclickMarker={(e, data) => {
          props.history.push(`/delivery-companies/${data.id}`);
        }}
        mapCenter={centerOfGravity}
        initialZoom={8}
        markerOptions={markerOptions}
      />
    </Skeleton>
  );
};
