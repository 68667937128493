import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect, useMemo, useState } from "react";
import { HistoryProps } from "routes/app";
import { useEffectSkipFirst, useQuery } from "utils/hooks";
import { theme, Drawer, Table, Space, Flex, Skeleton } from "antd";
import { queryString } from "utils/util";
import { CustomSelectBlockField } from "specifics/input";
import { useFetchGraphContractsApi, useFetchMismatchContractsApi } from "api/contract";
import { ID } from "entities";
import { GraphChart } from "./chart";
import { DescriptionBlock } from "specifics/description";
import { ContractItems } from "entities/contract";
import dayjs from "dayjs";
import { MismatchedTable } from "../mismatched/table";
import { ManualPage } from "components/manuals/manual_page";

export const Mode = {
  月ごと: "month",
  項目ごと: "item",
  納入先ごと: "delivery",
  運送会社ごと: "carrier"
} as const;

type ModeType = keyof typeof Mode;

const TargetPageSize = 50;

export const GraphPage = (props: HistoryProps & { isModalVisible: boolean }) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  const listApi = useFetchGraphContractsApi();
  const targetListApi = useFetchMismatchContractsApi();
  const query = useQuery<{ key: string, mode: ModeType, page: number, pageSize: number }>();

  const [mode, setMode] = useState<string>(query.mode || Mode["月ごと"]);
  const [target, setTarget] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [manualVisible, setManualVisible] = useState(false);
  const [manualId, setManualId] = useState<ID>("");
  const [selectedManualId, setSelectedManualId] = useState<ID>("");
  const showDrawer = (id?: ID, selectedId?: ID) => {
    if (!id) {
      return;
    }
    setManualId(id);
    setSelectedManualId(selectedId || "");
    setManualVisible(true);
  };
  const onClose = () => {
    setManualId("");
    setSelectedManualId("");
    setManualVisible(false);
  }
  // グラフデータ取得
  useEffect(() => {
    props.history.push(`/operations/contracts/${queryString({ ...query, mode: mode })}`);
  }, [mode]);

  useEffect(() => {
    if (query.mode) {
      listApi.execute(queryString(query));
    }
  }, [query.mode]);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
    setOpen(false);
    onClose();
    setTarget("");
  }, [listApi.loading]);

  // 対象データ取得
  useEffect(() => {
    if (target) {
      onClose();
      let path = "/";
      switch (mode) {
        case Mode["月ごと"]:
          setOpen(true);
          return;
        case Mode["項目ごと"]:
          path = "";
          break;
        case Mode["納入先ごと"]:
          path += data()[target].id;
          break;
        case Mode["運送会社ごと"]:
          path += data()[target].id;
          break;
      }
      targetListApi.execute(path + queryString({
        type: target,
        filled: true,
        pageSize: TargetPageSize
      }));
      setOpen(true);
    }
  }, [target]);

  // 対象テーブルでのページネーション
  useEffect(() => {
    if (query.pageSize == TargetPageSize) {
      targetListApi.execute(queryString({
        ...query,
        type: target,
        filled: true
      }));
    }
  }, [query.page]);

  const data = () => listApi.response.data || {};
  const targetData = () => targetListApi.response.data || [];
  const paginationInfo = () => targetListApi.response.meta;


  const reports = useMemo(() => {
    return target && Object.keys(data()).length ?
      [
        { title: "登録されている実態", data: data()[target].total + "件" },
        { title: "不一致", data: data()[target].mismatched + "件" },
        { title: "乖離率", data: data()[target].mismatchPercentage + "%" },
      ] :
      []
  }, [target]);

  const label = () => {
    switch (mode) {
      case Mode["項目ごと"]:
        return ContractItems[target as keyof typeof ContractItems];
      default:
        return target;
    }
  }

  return (
    <Flex vertical className="container">
      <CustomSelectBlockField
        selectItems={Object.entries(Mode).map(([label, value]) => ({
          label,
          value,
          style: mode === value ? { fontWeight: 700, background: token.colorWhite } : {}
        }))}
        size={manualVisible ? 5 : 10}
        itemStyle={{ color: token.colorPrimary, width: manualVisible ? 100 : 160, textAlign: "center", fontSize: 14, padding: "4px 8px", cursor: "pointer", fontWeight: 400 }}
        style={{ padding: 5, height: 34, marginBottom: 16, background: "#E6EAF2", width: manualVisible ? "40%" : "70%" }}
        value={mode}
        onChange={mode => setMode(mode)}
      />
      <GraphChart
        data={data()}
        target={target}
        setTarget={setTarget}
        width={manualVisible ? "40%" : "70%"}
      />
      {/* 項目ごとの詳細、カルテ一覧表示 */}
      <Drawer
        styles={{ body: { background: token.colorWhite } }}
        placement="right"
        closable={false}
        onClose={() => setOpen(false)}
        open={open}
        height="100%"
        width="25%"
        style={{ position: 'absolute', right: manualVisible ? '100%' : '0' }}
        mask={false}
      >
        <Flex vertical gap="large">
          {/* 実績表示 */}
          <DescriptionBlock
            label={
              <Space style={{ fontSize: 16 }}>
                <span>{label()}</span>
                <span style={{ fontWeight: "normal" }}>の実績</span>
              </Space>
            }
          >
            <Table
              dataSource={reports}
              columns={[{ title: '', dataIndex: 'title', key: 'title' }, { title: '', dataIndex: 'data', key: 'data' }]}
              pagination={false}
              showHeader={false}
              rowClassName={(record, index, indent) => {
                if (index === reports.length - 1) {
                  return 'no-divider';
                }
                return '';
              }}
            />
          </DescriptionBlock>
          {/* 対象カルテ一覧表示 */}
          {mode !== Mode["月ごと"] &&
            <DescriptionBlock
              labelStyle={{ height: "initial" }}
              label={
                <Space style={{ fontSize: 16 }} size={5}>
                  <span>{label()}</span>
                  <span style={{ fontWeight: "normal", whiteSpace: "nowrap" }}>が不一致の納入カルテ</span>
                </Space>
              }
            >
              <Skeleton loading={targetListApi.loading}>
                <MismatchedTable
                  history={props.history}
                  dataSource={targetData()}
                  columns={[{
                    render: (item) => <Space direction="vertical" size={0}>
                      {item.manual.title}
                      <span style={{ fontSize: 12, opacity: 0.8 }}>{dayjs(item.manual.updatedAt).format("YYYY/MM/DD")}</span>
                    </Space>
                  }]}
                  pagination={{
                    current: paginationInfo()?.currentPage,
                    pageSize: paginationInfo()?.perPage,
                    total: paginationInfo()?.total,
                  }}
                  showHeader={false}
                  showDrawer={showDrawer}
                  rowClassName={(record, index, indent) => {
                    if (index === targetData().length - 1) {
                      return 'no-divider';
                    }
                    return '';
                  }}
                  hideDisplaySetting={true}
                />
              </Skeleton>
            </DescriptionBlock>
          }
        </Flex>
      </Drawer>
      {/* 対象カルテ表示 */}
      <Drawer
        styles={{
          body: { background: token.colorWhite },
          // todo:closeボタンの右寄せ
          header: { background: token.colorWhite }
        }}
        placement="right"
        closable={true}
        onClose={onClose}
        open={manualVisible}
        height="100%"
        width="25%"
        style={{ position: 'absolute', right: '0' }}
        mask={false}
      >
        {manualVisible && selectedManualId && <ManualPage manualId={manualId} selectedManualId={selectedManualId} width="100%" {...props} />}
      </Drawer>
    </Flex>
  );
};
