import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useDownloadApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import { Point, PointForm, PointSearchForm } from "entities/point";
import { ID, PagingResponse } from "entities";

export type PointsResponse = PagingResponse<Point>;

export function useFetchPointsApi(
  searchForm?: Form<PointSearchForm>
): IndexApiSet<PointsResponse> & { execute: () => void } {
  const apiPath = "point";
  const api = useIndexApi<PointsResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type PointResponse = BaseResponse & {
  data: Point;
};

export function useFetchPointApi(): ApiSet<PointResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<PointResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `point/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

type PostPointResponse = BaseResponse & {
  data: { id?: string };
};

export function usePostPointApi(): ApiSet<PostPointResponse> & {
  execute: (point: PointForm) => void;
} {
  const apiSet = usePostObjectApi<PostPointResponse, PointForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (point: PointForm) => {
    const apiPath = `point`;
    apiSet.execute(apiPath, point);
  };

  return { ...apiSet, execute };
}

export function useEditPointApi(): ApiSet<BaseResponse> & {
  execute: (point: PointForm) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, PointForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (point: PointForm) => {
    const apiPath = `point/${point.id}`;
    apiSet.execute(apiPath, point);
  };

  return { ...apiSet, execute };
}

export function useDeletePointApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `point/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}
