import { Space, Table, Upload, theme } from "antd";
import {
  useDeleteDeliveryPartnerCompanyApi,
  useFetchDeliveryPartnerCompaniesApi,
} from "api/delivery_partner_company";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  DeliveryPartnerCompany,
  DeliveryPartnerCompanySearchForm,
} from "entities/delivery_partner_company";
import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomInputField } from "specifics/input";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { DeliveryPartnerCompaniesTable } from "./delivery_partner_companies_table";
import { ID } from "entities";

const DeliveryPartnerCompaniesPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchDeliveryPartnerCompaniesApi();
  const { token } = theme.useToken();
  const deleteApi = useDeleteDeliveryPartnerCompanyApi();

  useEffect(() => {
    listApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);
  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      title="運送会社"
      extra={[
        <CustomButton
          key="CreateDeliveryPartnerCompanies"
          onClick={() =>
            props.history.push("/operations/delivery-partner-companies/create")
          }
          type="primary"
        >
          運送会社登録
        </CustomButton>,
      ]}
    >
      <DeliveryPartnerCompaniesTable
        handleDelete={(id: ID) => deleteApi.execute(id)}
        history={props.history}
        dataSource={listApi.response.data}
        listApi={listApi}
        pagination={{
          current: listApi.response.meta?.currentPage,
          pageSize: listApi.response.meta?.perPage,
          total: listApi.response.meta?.total,
        }}
        // loading={globalState.loading}
      />
    </CustomPageHeader>
  );
};

export default withRouter(DeliveryPartnerCompaniesPage);
