import { Space, Table, Upload, theme } from "antd";
import { useDeleteConsignorApi, useFetchConsignorsApi } from "api/consignor";
import { useFetchUsersApi } from "api/user";
import { GlobalStateContext } from "contexts/global_state_context";

import { ConsignorSearchForm } from "entities/consignor";
import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton, UploadCSVFileButton } from "specifics/button";
import { CustomInputField } from "specifics/input";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { ConsignorsTable } from "./consignors_table";
import { ID } from "entities";

const ConsignorsPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchConsignorsApi();
  const searchForm = useForm<ConsignorSearchForm>({});
  const deleteApi = useDeleteConsignorApi();

  useEffect(() => {
    listApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      // subTitle={
      //   <CustomInputField
      //     style={{ width: 320, marginLeft: 20 }}
      //     fieldProps={{
      //       prefix: <SearchOutlined color="#9CA3AF" />,
      //     }}
      //     placeholder={"荷主を検索"}
      //     attr="name"
      //     form={searchForm}
      //   />
      // }
      title="荷主"
      extra={[
        // <Upload key="UploadCSVToConsignors">
        //   <UploadCSVFileButton />
        // </Upload>,
        <CustomButton
          key="CreateConsignors"
          onClick={() => props.history.push("/operations/consignors/create")}
          type="primary"
        >
          荷主登録
        </CustomButton>,
      ]}
    >
      <ConsignorsTable
        handleDelete={(id: ID) => deleteApi.execute(id)}
        history={props.history}
        dataSource={listApi.response.data}
        listApi={listApi}
        pagination={{
          current: listApi.response.meta?.currentPage,
          pageSize: listApi.response.meta?.perPage,
          total: listApi.response.meta?.total,
        }}
      />
    </CustomPageHeader>
  );
};

export default withRouter(ConsignorsPage);
