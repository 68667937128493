import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useDownloadApi,
  useIndexApi,
  usePostApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import {
  CharteredCompany,
  CharteredCompanyForm,
  CharteredCompanySearchForm,
} from "entities/chartered_company";
import { ID, PagingResponse } from "entities";

type AllCharteredCompaniesResponse = BaseResponse<CharteredCompany[]>;

export function useFetchAllCharteredCompaniesApi(): ApiSet<AllCharteredCompaniesResponse> & {
  execute: () => void;
} {
  const api = useIndexApi<AllCharteredCompaniesResponse>(new HttpClient(), {
    initialResponse: { data: [] },
  });

  const execute = (): void => {
    const apiPath = `chartered_company/all`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export type CharteredCompaniesResponse = PagingResponse<CharteredCompany>;

export function useFetchCharteredCompaniesApi(
  searchForm?: Form<CharteredCompanySearchForm>
): IndexApiSet<CharteredCompaniesResponse> & {
  execute: (query?: string) => void;
} {
  const apiPath = "chartered_company";
  const api = useIndexApi<CharteredCompaniesResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type CharteredCompanyResponse = BaseResponse & {
  data: CharteredCompany;
};

export function useFetchCharteredCompanyApi(): ApiSet<CharteredCompanyResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<CharteredCompanyResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `chartered_company/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostCharteredCompanyApi(): ApiSet<CharteredCompanyResponse> & {
  execute: (form: Form<CharteredCompanyForm>) => void;
} {
  const apiSet = usePostApi<CharteredCompanyResponse, CharteredCompanyForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<CharteredCompanyForm>) => {
    const apiPath = `chartered_company`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditCharteredCompanyApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<CharteredCompanyForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, CharteredCompanyForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<CharteredCompanyForm>) => {
    const apiPath = `chartered_company/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteCharteredCompanyApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `chartered_company/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}
