import {
  ApiSet,
  BaseResponse,
  BulkPostResponse,
  CountsApiResponse,
  IndexApiSet,
  useDeleteApi,
  useDownloadApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import { User, UserForm, UserSearchForm, UserType } from "entities/user";
import { ID, PagingResponse } from "entities";

const apiBasePath = "user";

type AllUsersResponse = BaseResponse<User[]>;

export function useFetchAllUsersApi(): ApiSet<AllUsersResponse> & {
  execute: () => void;
} {
  const api = useIndexApi<AllUsersResponse>(new HttpClient(), {
    initialResponse: { data: [] },
  });

  const execute = (): void => {
    const apiPath = `${apiBasePath}/all`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export type UsersResponse = PagingResponse<User>;

export function useFetchUsersApi(
  searchForm?: Form<UserSearchForm>
): IndexApiSet<UsersResponse> & { execute: () => void } {
  const apiPath = apiBasePath;
  const api = useIndexApi<UsersResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

type CountsUserApiConfig = {
  givenTimes: string[];
  type?: UserType;
};

export function useCountsUserApi(
  config: CountsUserApiConfig
): ApiSet<CountsApiResponse> & {
  execute: () => void;
} {
  const apiPath = `${apiBasePath}/counts`;
  const api = usePostObjectApi<CountsApiResponse, CountsUserApiConfig>(
    new HttpClient(),
    {
      initialResponse: {
        data: [],
      },
    }
  );

  const execute = (): void => {
    api.execute(apiPath, config);
  };

  return { ...api, execute };
}

export type UserResponse = BaseResponse & {
  data: User;
};

export function useFetchUserApi(): ApiSet<UserResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<UserResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `${apiBasePath}/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}
export function usePostUserApi(): ApiSet<UserResponse> & {
  execute: (form: Form<UserForm>) => void;
} {
  const apiSet = usePostApi<UserResponse, UserForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<UserForm>) => {
    const apiPath = apiBasePath;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditUserApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<UserForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, UserForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<UserForm>) => {
    const apiPath = `${apiBasePath}/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteUserApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `${apiBasePath}/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export function useFetchLoginUserApi(): IndexApiSet<UserResponse> & {
  execute: () => void;
} {
  const apiPath = "user/login_user/";
  const api = useIndexApi<UserResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export function usePostUsersApi(): ApiSet<BulkPostResponse<User>> & {
  execute: (
    users: UserForm[],
    companyId: string
  ) => Promise<BulkPostResponse<User>>;
} {
  const apiSet = usePostObjectApi<
    any,
    { users: UserForm[]; companyId: string }
  >(
    new HttpClient(),
    {
      initialResponse: {
        meta: { successCount: 0, failCount: 0 },
        data: [],
      },
    },
    { formatJson: true }
  );

  const execute = async (
    users: UserForm[],
    companyId: string
  ): Promise<BulkPostResponse<User>> => {
    const apiPath = `${apiBasePath}/bulk`;
    const result = await apiSet.execute(apiPath, { users, companyId });
    return result?.data;
  };

  return { ...apiSet, execute };
}
