import { Button, Divider, Space, Tag, theme } from "antd";
import { useDeleteUserApi, useFetchUserApi } from "api/user";
import { GlobalStateContext } from "contexts/global_state_context";
import { UserType } from "entities/user";

import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { SelectOutlined } from "@ant-design/icons";
import { DescriptionBlock } from "specifics/description";
import { CustomPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { UserTypeView } from "../users_view";

const SuperAdminUserPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const userApi = useFetchUserApi();
  const params = useParams<{ id: string }>();
  const deleteApi = useDeleteUserApi();
  const { token } = theme.useToken();
  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      props.history.goBack();
    }
  }, [deleteApi.loading]);
  useEffect(() => {
    userApi.execute(params.id);
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(userApi.loading);
  }, [userApi.loading]);

  const user = () => {
    return userApi.response.data;
  };

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="メンバー詳細"
      onBack={() => props.history.goBack()}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent>
        <Space
          style={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <Space direction="vertical" size={24}>
            <DescriptionBlock label="客先名称">
              <Space>
                <div>{user().company?.name}</div>
                <Button
                  type="text"
                  shape="circle"
                  onClick={() =>
                    props.history.push(
                      `/super-admin/companies/${user().company?.id}`
                    )
                  }
                  icon={<SelectOutlined />}
                />
              </Space>
            </DescriptionBlock>
            <DescriptionBlock label="氏名">{user()?.name}</DescriptionBlock>
            <DescriptionBlock label="役割">
              <UserTypeView user={user()} />
            </DescriptionBlock>
            <DescriptionBlock label="電話番号">
              {user()?.mobile}
            </DescriptionBlock>

            {user().type === UserType.ドライバー && (
              <DescriptionBlock label="対応可能作業">
                {user().availableJobs}
              </DescriptionBlock>
            )}
            <DescriptionBlock label="メールアドレス">
              {user()?.email}
            </DescriptionBlock>
          </Space>
          <Space size={24} style={{ verticalAlign: "top", height: "100%" }}>
            <CustomButton
              style={{
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() =>
                props.history.push(`/super-admin/users/${user()?.id}/edit`)
              }
            >
              編集
            </CustomButton>
            <CustomButton
              danger
              type="text"
              style={{
                fontSize: 14,
                fontWeight: 400,
                padding: 0,
              }}
              onClick={() => deleteApi.execute(params.id)}
              confirm
              popconfirmProps={{
                title: "削除します。よろしいですか？",
              }}
            >
              削除
            </CustomButton>
          </Space>
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(SuperAdminUserPage);
