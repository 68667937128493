import { Table } from "antd";
import {
  RotationSummary,
  RotationSummaryByUserId,
  RotationsAnalysisHandler,
} from "./rotations_handler";
import { OrderDetail } from "entities/order";
import { CustomTag } from "specifics/tag";
import dayjs from "dayjs";
import { RotationSummariesTable } from "./rotation_summaries_table";
import { SortOrder } from "antd/es/table/interface";
import { sum } from "lodash";

export const RotationsByUserIdTable = ({
  rotationSummariesByUserId,
}: {
  rotationSummariesByUserId: RotationSummaryByUserId[];
}) => {
  type RotationSummaryByUserIdWithSumRow = RotationSummaryByUserId & {
    rotationCountsTimesVehicleMaxWeight: number;
  };
  const sumRow = () => {
    const sum = rotationSummariesByUserId.reduce(
      (acc: RotationSummaryByUserIdWithSumRow, summary) => {
        acc.rotationsCount += summary.rotationsCount;
        acc.unloadCount += summary.rotationsCount;
        acc.loadWeight += summary.loadWeight;
        acc.vehicleMaxWeight += summary.vehicleMaxWeight;
        acc.rotationCountsTimesVehicleMaxWeight +=
          summary.vehicleMaxWeight * summary.rotationsCount;
        acc.loadingRate = RotationsAnalysisHandler.calcLoadingRate(
          acc.loadWeight,
          acc.rotationCountsTimesVehicleMaxWeight
        );
        return acc;
      },
      {
        driverName: "合計",
        rotationsCount: 0,
        unloadCount: 0,
        loadWeight: 0,
        vehicleMaxWeight: 0,
        loadingRate: 0,
        rotationCountsTimesVehicleMaxWeight: 0,
      } as RotationSummaryByUserIdWithSumRow
    );
    return sum;
  };
  return (
    <Table
      rowKey={(record: RotationSummaryByUserId) => record.userId ?? ""}
      dataSource={[...rotationSummariesByUserId, sumRow()]}
      expandable={{
        expandedRowRender: (record: RotationSummaryByUserId) => {
          return (
            <RotationSummariesTable
              rotationsSummaries={record.rotationSummaries}
            />
          );
        },
      }}
      columns={[
        {
          key: "driverName",
          title: "ドライバー名",
          render: (item) => item.driverName ?? "不明",
        },
        {
          key: "rotationsCount",
          title: "回転数",
          render: (item) => `${item.rotationsCount ?? 0}回転`,
          sorter: (
            a: RotationSummaryByUserId,
            b: RotationSummaryByUserId,
            sortOrder?: SortOrder
          ) => {
            return (a.rotationsCount ?? 0) > (b.rotationsCount ?? 0) ? 1 : -1;
          },
        },
        {
          key: "unloadCount",
          title: "降し件数",
          render: (item) => `${item.unloadCount}件`,
          sorter: (
            a: RotationSummaryByUserId,
            b: RotationSummaryByUserId,
            sortOrder?: SortOrder
          ) => {
            return (a.unloadCount ?? 0) > (b.unloadCount ?? 0) ? 1 : -1;
          },
        },
        {
          key: "loadWeight",
          title: "積載量",
          render: (item) => `${item.loadWeight}kg`,
          sorter: (
            a: RotationSummaryByUserId,
            b: RotationSummaryByUserId,
            sortOrder?: SortOrder
          ) => {
            return (a.loadWeight ?? 0) > (b.loadWeight ?? 0) ? 1 : -1;
          },
        },
        {
          key: "vehicleMaxWeight",
          title: "車両の最大積載量",
          render: (item) => `${item.vehicleMaxWeight}t`,
          sorter: (
            a: RotationSummaryByUserId,
            b: RotationSummaryByUserId,
            sortOrder?: SortOrder
          ) => {
            return (a.vehicleMaxWeight ?? 0) > (b.vehicleMaxWeight ?? 0)
              ? 1
              : -1;
          },
        },
        {
          key: "loadingRate",
          title: "積載率",
          render: (item) => (
            <div
              style={{
                color:
                  item.loadingRate > 100
                    ? "blue"
                    : item.loadingRate < 20
                    ? "red"
                    : "default",
                fontWeight:
                  item.loadingRate <= 100 && item.loadingRate > 20
                    ? "default"
                    : 700,
              }}
            >
              {item.loadingRate ? `${item.loadingRate.toFixed(1)}%` : "--"}
            </div>
          ),
          sorter: (
            a: RotationSummaryByUserId,
            b: RotationSummaryByUserId,
            sortOrder?: SortOrder
          ) => {
            return (a.loadingRate ?? 0) > (b.loadingRate ?? 0) ? 1 : -1;
          },
        },
      ]}
      pagination={false}
    />
  );
};
