
import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomPageHeader } from "specifics/page_header";
import { useQuery } from "utils/hooks";
import { MismatchedPage } from "./mismatched/page";
import { Space, theme } from "antd";
import { queryString } from "utils/util";
import { ContractItems } from "entities/contract";
import { UnfilledPage } from "./unfilled/page";
import { CustomButton } from "specifics/button";
import { useFetchAllDeliveryPartnerCompaniesApi } from "api/delivery_partner_company";
import { ContractInputRequestModal } from "./input_request/modal";
import { AllPage } from "./all/page";
import { GraphPage } from "./graph/page";

enum ContractKey {
  All = "すべて",
  Mismatched = "不一致",
  Unfilled = "未記入",
  Graph = "分析(グラフ)"
}

const ContractsPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const allDeliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const query = useQuery<{ page: number, pageSize: number, sorter: string, filter: string, key: ContractKey, type: keyof typeof ContractItems }>();
  const key = query.key ?? ContractKey.All;

  useEffect(() => {
    if (Object.keys(query).length === 0) {
      props.history.push(`/operations/contracts/${queryString()}`)
    }
  }, [JSON.stringify(query)]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    if (isModalVisible) {
      allDeliveryPartnerCompaniesApi.execute();
    }
  }, [isModalVisible]);

  const tabItems = Object.values(ContractKey).map(key => ({ key, label: key }));

  const renderTabItem = (item: { key: ContractKey; label: string }) => (
    <div
      style={{
        cursor: "pointer",
        fontWeight: 700,
        padding: "0px 16px 4px 16px",
        color: key === item.key ? token.colorPrimary : "#6B7280",
        opacity: key === item.key ? 1 : 0.4,
        borderBottom: key === item.key ? `3px solid ${token.colorPrimary}` : `3px solid transparent`,
      }}
      onClick={() => props.history.push(`/operations/contracts/${queryString({ key: item.key })}`)}
    >
      {item.label}
    </div>
  );

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      title="実態管理"
      extra={[
        <CustomButton
          type="primary"
          key={"createManual"}
          onClick={showModal}
        >
          実態の記入を依頼
        </CustomButton>,
      ]}
      footer={
        <Space
          style={{
            background: token.colorWhite,
            border: 1,
            borderRadius: 4,
            fontSize: 16,
            textAlign: "center"
          }}
        >
          {tabItems.map(renderTabItem)}
        </Space>
      }
    >
      {isModalVisible &&
        <ContractInputRequestModal
          history={props.history}
          open={isModalVisible}
          deliveryPartnerCompanies={allDeliveryPartnerCompaniesApi.response.data || []}
          onCancel={() => setIsModalVisible(false)}
        />
      }
      {key === ContractKey.All && <AllPage history={props.history} isModalVisible={isModalVisible} />}
      {key === ContractKey.Mismatched && <MismatchedPage history={props.history} isModalVisible={isModalVisible} />}
      {key === ContractKey.Unfilled && <UnfilledPage history={props.history} isModalVisible={isModalVisible} />}
      {key === ContractKey.Graph && <GraphPage history={props.history} isModalVisible={isModalVisible} />}
    </CustomPageHeader>
  );
};

export default withRouter(ContractsPage);
