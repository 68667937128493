import { BaseEntity, BaseSearchForm } from ".";
import { Company } from "./company";
import { DeliveryCompany } from "./delivery_company";
import { Order, OrderPointType } from "./order";
import { User } from "./user";

// export type PointType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export enum PointType {
  削除 = 0,
  地点 = 1,
  守衛 = 2,
  受付 = 3,
  ヤード = 4,
  場内待機 = 5,
  入口 = 6,
  出口 = 7,
  危険場所 = 8,
  回収場所 = 9,
  鍵 = 10,
  電話 = 11,
  その他 = 12,
  上 = 13,
  下 = 14,
  左 = 15,
  右 = 16,
}

export type Point = BaseEntity & {
  deliveryCompanyId?: string;
  manualId?: string;
  pointType?: PointType;
  latitude?: number;
  longitude?: number;
  memo?: string;

  company?: Company;
  deliveryCompany?: DeliveryCompany;
};

export type PointForm = Point;

export type PointSearchForm = BaseSearchForm & Point;
