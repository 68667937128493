import {
  theme,
  Space,
  Row,
  Col,
  Skeleton,
  Divider,
  Image,
  Progress,
  Button,
  Modal,
  RadioChangeEvent,
  UploadFile,
  Flex,
} from "antd";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { OptionProps } from "antd/lib/mentions";
import { useFetchCurrentCompanyApi } from "api/company";
import { useCheckManualDuplicateApi } from "api/manual";
import { usePostPointApi, useEditPointApi, useDeletePointApi } from "api/point";
import { useFetchTemplateApi } from "api/template";
import { useUploadApi } from "api/uplaod";
import { GlobalStateContext } from "contexts/global_state_context";
import { Company, CompanyManualDisplay, CompanyManualType } from "entities/company";
import { Consignor } from "entities/consignor";
import {
  DeliveryCompany,
} from "entities/delivery_company";
import {
  DriverManualGuidelineAttrs,
  DriverManualInfo,
  Manual,
  ManualDetail,
  ManualGuidelineAttrs,
  ManualInfo,
  ManualKey,
  ManualMarkers,
  ManualProcess,
  validateManual,
} from "entities/manual";
import { PointType, PointForm, Point } from "entities/point";
import { Template, Step, Question } from "entities/template";
import React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DescriptionBlock } from "specifics/description";
import {
  CustomCheckboxGroupField,
  CustomFileField,
  CustomInputField,
  CustomInputNumberField,
  CustomSelectField,
  CustomSelectRadioField,
  CustomTextAreaField,
  LabelView,
} from "specifics/input";
import { GoogleMapComponent } from "utils/google_map_component";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import { filterKanaName, sortQuestions } from "utils/util";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import useMedia from "use-media";
import { TotalTime, AcceptanceTime, WaitingTime, HandlingTerm, DeliveryTerm, HANDLING_TASK, NEED_TASK, ReceiptHandOverTime } from "entities/contract";

export const ManualForm = ({
  form,
  validationResultForm,
  isEdit,
  isDriver,
  template,
  templates,
  companyManualType,
  _key,
  infoIndexState = 0,
  stepIndexState,
  setStepIndexState,
  deliveryCompanies,
  deliveryPartnerCompanies,
  consignors,
  manuals,
  guest,
}: {
  form: Form<Manual>;
  validationResultForm: Form<Manual>;
  isEdit?: boolean;
  isDriver?: boolean;
  template?: Template;
  templates?: Template[];
  companyManualType?: CompanyManualType;
  _key: ManualKey;
  infoIndexState?: number;
  stepIndexState: number;
  setStepIndexState: (stepIndexState: number) => void;
  deliveryCompanies?: DeliveryCompany[];
  deliveryPartnerCompanies?: DeliveryPartnerCompany[];
  consignors?: Consignor[];
  manuals?: Manual[];
  guest?: boolean;
}): JSX.Element => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  const checkManualDuplicateApi = useCheckManualDuplicateApi();
  const uploadApi = useUploadApi();
  const isMobile = useMedia("(max-width: 519px)");

  const selectedSteps = () =>
    template?.steps?.filter((step) =>
      form.object.selectedStepNos?.includes(step.stepNo ?? 0)
    );

  const progressSteps = useMemo(() => {
    return isDriver ?
      // ドライバー:
      // 基本情報(4)-ガイドライン非表示(2)
      Object.keys(DriverManualInfo).filter((key) => isNaN(Number(key))).length - ((!CompanyManualDisplay(companyManualType, ManualKey.基本情報) ? Object.keys(DriverManualGuidelineAttrs).length : 0)) +
      // 必要ステップ選択(1)、ステップ数(?)
      (CompanyManualDisplay(companyManualType, ManualKey.手順) ? 1 + (selectedSteps()?.length ?? 0) : 0) +
      // 構内マップ(1)をカウント
      (CompanyManualDisplay(companyManualType, ManualKey.構内マップ) ? 1 : 0)
      :
      // 管理者:
      // 基本情報(2)-ガイドライン非表示(1)
      Object.keys(ManualInfo).filter((key) => isNaN(Number(key))).length - ((!CompanyManualDisplay(companyManualType, ManualKey.基本情報) ? Object.keys(ManualGuidelineAttrs).length : 0)) +
      // ステップ数(?)
      (CompanyManualDisplay(companyManualType, ManualKey.手順) ? (selectedSteps()?.length ?? 0) : 0) +
      // 構内マップ(1)をカウント
      (CompanyManualDisplay(companyManualType, ManualKey.構内マップ) ? 1 : 0)
  }, [template?.steps?.length, form.object.selectedStepNos]);

  const progressCurrent = useMemo(() => {
    // 進行状況を数え上げ
    return _key + infoIndexState + stepIndexState // 基本情報・手順・構内マップの進行状況
      + (isDriver ? 1 : 0)// ドライバーは手順のstepIndexが-1始まりなので）
  }, [infoIndexState, stepIndexState, _key]);

  const progressWidth = useMemo(() => {
    // 進行状況一個の幅
    return (isDriver ? window.innerWidth - 40 : 720) / progressSteps;
  }, [progressSteps]);

  useEffect(() => {
    validateManual(form, validationResultForm);
    globalState.setLoading(false);
  }, [JSON.stringify(form.object)]);

  const updateTitle = (props: { deliveryCompany?: DeliveryCompany, deliveryPointCompany?: DeliveryCompany, deliveryPartnerCompany?: DeliveryPartnerCompany, consignor?: Consignor }) => {
    const { deliveryCompany, deliveryPointCompany, deliveryPartnerCompany, consignor } = props;
    form.update((form) => {
      if (deliveryCompany) {
        form.title = `${deliveryCompany?.name}`;
        form.title += deliveryPointCompany ? `-${deliveryPointCompany.point?.memo}` : "";
        form.title += deliveryPartnerCompany ? `-${deliveryPartnerCompany.name}` : "";
        form.title += consignor ? `-${consignor.name}` : "";
      }
      form.deliveryCompany = deliveryCompany;
      form.deliveryPointCompany = deliveryPointCompany;
      form.deliveryPartnerCompany = deliveryPartnerCompany;
      form.deliveryPartnerCompanyId = deliveryPartnerCompany?.id;
      form.consignor = consignor;
    });
    globalState.setLoading(false);
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(true);
    // 選択されたdeliveryCompanyIdに基づきdeliveryCompanyを取得
    // 選択されたdeliveryCompanyに基づきdeliveryPartnerCompanyを取得
    const deliveryCompany = deliveryCompanies?.find(
      (company) => company.id === form.object.deliveryCompanyId
    ) || form.object.deliveryCompany;
    const deliveryPointCompany = [deliveryCompany, ...(deliveryCompany?.deliveryCompanies || [])].find(company => company?.id === form.object.deliveryPointCompanyId) || form.object.deliveryPointCompany;
    const deliveryPartnerCompany = deliveryPartnerCompanies?.find(
      (company) => deliveryCompany?.deliveryPartnerCompanies?.length ?
        deliveryCompany.deliveryPartnerCompanies.map(pc => pc.id).includes(company.id ?? '') : // 行先に担当運送会社があるとき
        company.id === form.object.deliveryPartnerCompanyId                                    // 行先に担当運送会社がないとき
    ) || form.object.deliveryPartnerCompany;
    const consignor = consignors?.find(
      (company) => company.id === form.object.consignorId
    ) || form.object.deliveryCompany;
    // title更新
    updateTitle({ deliveryCompany, deliveryPointCompany, deliveryPartnerCompany, consignor });
  }, [form.object.deliveryCompanyId, form.object.deliveryPointCompanyId, form.object.deliveryPartnerCompanyId, form.object.consignorId]);

  useEffectSkipFirst(() => {
    if (form.object.title) {
      // 作成・編集の際title,idに基づき重複する納入カルテ名を検索
      checkManualDuplicateApi.execute(form.object.title, form.object.id ?? "");
      form.updateObject(
        "isTitleDuplicate",
        checkManualDuplicateApi.response.data
      );
    }
  }, [form.object.title]);

  useEffect(() => {
    if (checkManualDuplicateApi.isSuccess()) {
      form.updateObject(
        "isTitleDuplicate",
        !!checkManualDuplicateApi.response.data
      );
    }
  }, [checkManualDuplicateApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(true);
    setTimeout(() => globalState.setLoading(false), 0);
  }, [form.object.deliveryPointCompanyId]);

  const deliveryPointCompany = () =>
    form.object.deliveryCompany?.deliveryCompanies?.find(
      deliveryCompany => deliveryCompany.id === form.object.deliveryPointCompanyId
    );

  const InfoHeader = (title: string, form: Form<Manual>) => (
    <DescriptionBlock
      labelStyle={{
        fontSize: 18,
        marginTop: 10,
      }}
      label={`${title}について入力してください。`}
      valueStyle={{
        fontSize: 12,
        fontWeight: 600,
        opacity: 0.6,
        marginBottom: isDriver ? 0 : 20,
        textAlign: "right"
      }}
    >
      {form.object.deliveryCompany ?
        <>
          {`${form.object.deliveryCompany.name}`}
          <br />
          {`〒${form.object.deliveryCompany.zipcode} ${form.object.deliveryCompany.address}`}
        </> :
        ''
      }
    </DescriptionBlock>
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* ヘッダー */}
      {!isEdit && (
        // カルテ作成時のステップインジケーター
        <Space
          size={32}
          style={{
            textAlign: "center",
            width: isDriver ? "calc(100vw - 30px)" : "100%",
            justifyContent: "space-evenly",
            margin: "0px 15px",
          }}
        >
          <div style={{ position: "relative", width: "100%" }}>
            {template?.steps && (
              <Progress
                style={{
                  margin: isDriver ? 0 : "0 auto",
                  width: isDriver ? "100%" : 720,
                }}
                size={[progressWidth, 10]}
                strokeColor={token.colorPrimary}
                percent={(progressCurrent / progressSteps) * 100}
                steps={progressSteps}
                showInfo={false}
              />
            )}
            <div
              style={{
                width: 60,
                textAlign: "left",
                fontSize: 12,
                fontWeight: "bold",
                color: `${token.colorText}`,
                opacity: 0.6,
                position: "absolute",
                bottom: "-20px",
                left: `${((progressCurrent -
                  (_key === ManualKey.基本情報
                    ? infoIndexState
                    : _key === ManualKey.手順
                      ? stepIndexState + (isDriver ? 1 : 0)
                      : 0) -
                  (isDriver ? 0.4 : 0.7)) /
                  progressSteps) *
                  100
                  }%`,
                transform: "translateX(-50%)",
              }}
            >
              {ManualKey[_key]}
            </div>
          </div>
        </Space>
      )}
      {/* フォーム */}
      {_key === ManualKey.基本情報 && (
        <>
          <Space
            style={{
              margin: 24,
              minHeight: isDriver ? "calc(100vh - 210px)" : "100%",
              width: isDriver ? "calc(100vw - 48px)" : "100%",
              // boxShadow:
              //     "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
              borderRadius: 8,
            }}
            direction="vertical"
          >
            {/* 管理者画面カルテ作成・編集 */}
            {!isDriver && (
              <div
                style={{
                  width: 770,
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: 30,
                }}
              >
                {InfoHeader(ManualInfo[infoIndexState], form)}
                {/* 行先・荷主・納入カルテ名 */}
                {(infoIndexState === ManualInfo.基本情報 || isEdit) && (
                  <>
                    <Row>
                      <Col span={4}>
                        <DescriptionBlock
                          labelStyle={{ fontSize: 16 }}
                          label={`基本情報`}
                        >
                          <></>
                        </DescriptionBlock>
                      </Col>
                      <Col span={1}>
                        <Divider style={{ height: "100%" }} type="vertical" />
                      </Col>
                      <Col span={19}>
                        <>
                          {
                            // 未記入画面からの複数地点の作成時
                            (manuals || []).length > 0 &&
                            <CustomSelectField
                              form={form}
                              validationResultForm={validationResultForm}
                              required
                              label="紐づけるカルテを選択"
                              attr="manualId"
                              selectItems={
                                manuals?.map((m) => ({
                                  label: m.title!,
                                  value: m.id!,
                                })) ?? []
                              }
                              style={{ width: "100%" }}
                            />
                          }
                          <CustomSelectField
                            form={form}
                            validationResultForm={validationResultForm}
                            required
                            disabled={guest || isEdit || !!form.object.deliveryPointCompanyId}
                            label="行先を選択"
                            attr="deliveryCompanyId"
                            selectItems={
                              deliveryCompanies?.map((d) => ({
                                label: d.name!,
                                value: d.id!,
                              })) ?? []
                            }
                            style={{ width: "100%" }}
                            fieldProps={{
                              showSearch: true,
                              filterOption: filterKanaName(deliveryCompanies),
                              optionFilterProp: "label",
                            }}
                          />
                          {
                            // 地点の指定があれば
                            form.object.deliveryPointCompanyId && (
                              <CustomSelectField
                                form={form}
                                validationResultForm={validationResultForm}
                                disabled={true}
                                label="地点"
                                attr="deliveryPointCompanyId"
                                selectItems={
                                  [form.object.deliveryCompany, ...(form.object.deliveryCompany?.deliveryCompanies || [])]?.map((d) => ({
                                    label: d?.point?.memo || d?.name,
                                    value: d?.id,
                                  })) ?? []
                                }
                                style={{ width: "100%" }}
                                fieldProps={{
                                  showSearch: true,
                                  filterOption: filterKanaName([form.object.deliveryCompany || {}, ...(form.object.deliveryCompany?.deliveryCompanies || [])]),
                                  optionFilterProp: "label",
                                  allowClear: true
                                }}
                              />
                            )
                          }
                          {form.object.deliveryCompany &&
                            <Skeleton loading={globalState.loading}>
                              <GoogleMapComponent
                                mapCenter={{
                                  lat:
                                    deliveryPointCompany()?.point
                                      ?.latitude ||
                                    form.object.deliveryCompany?.point
                                      ?.latitude || 0,
                                  lng:
                                    deliveryPointCompany()?.point
                                      ?.longitude ||
                                    form.object.deliveryCompany?.point
                                      ?.longitude || 0,
                                }}
                                markerOptions={[{
                                  position: {
                                    lat:
                                      deliveryPointCompany()?.point
                                        ?.latitude ||
                                      form.object.deliveryCompany?.point
                                        ?.latitude || 0,
                                    lng:
                                      deliveryPointCompany()?.point
                                        ?.longitude ||
                                      form.object.deliveryCompany?.point
                                        ?.longitude || 0,
                                  },
                                  visible: true
                                }]}
                                onClickMarker={(e, marker) => {
                                  const targetPoint = deliveryPointCompany()?.point || form.object.deliveryCompany?.point;
                                  const infowindow = new google.maps.InfoWindow({
                                    content: targetPoint?.memo,
                                  });
                                  targetPoint && infowindow.open(marker?.getMap(), marker);
                                }}
                                disableControll
                                wrapStyle={{ width: 361, height: "initial" }}
                                style={{
                                  height: 205,
                                  borderRadius: 6,
                                  margin: "8px 0",
                                }}
                              />
                              <div
                                style={{
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  color: `${token.colorText}`,
                                  opacity: 0.6,
                                }}
                              >
                                {`〒${form.object.deliveryCompany?.zipcode} ${form.object.deliveryCompany?.address}`}
                              </div>
                            </Skeleton>
                          }
                        </>
                        {!guest &&
                          <DescriptionBlock>
                            <Skeleton loading={globalState.loading}>
                              <CustomSelectField
                                form={form}
                                validationResultForm={validationResultForm}
                                label="運送会社を選択"
                                attr="deliveryPartnerCompanyId"
                                disabled={isEdit}
                                selectItems={
                                  deliveryPartnerCompanies?.filter(c1 =>
                                    // 行先に紐づいた運送会社があればフィルタ
                                    form.object.deliveryCompany?.deliveryPartnerCompanies?.map(c2 => c2.id).includes(c1.id) || !form.object.deliveryCompany?.deliveryPartnerCompanies?.length
                                  ).map((d) => ({
                                    label: d.name!,
                                    value: d.id!,
                                  })) ?? []
                                }
                                style={{ width: "100%" }}
                                fieldProps={{
                                  showSearch: true,
                                  filterOption: filterKanaName(deliveryPartnerCompanies),
                                  optionFilterProp: "label",
                                }}
                              />
                            </Skeleton>
                          </DescriptionBlock>
                        }
                        {!guest &&
                          <DescriptionBlock>
                            <Skeleton loading={globalState.loading}>
                              <CustomSelectField
                                form={form}
                                validationResultForm={validationResultForm}
                                label="荷主を選択"
                                attr="consignorId"
                                selectItems={
                                  consignors?.map((d) => ({
                                    label: d.name!,
                                    value: d.id!,
                                  })) ?? []
                                }
                                style={{ width: "100%" }}
                                fieldProps={{
                                  showSearch: true,
                                  filterOption: filterKanaName(consignors),
                                  optionFilterProp: "label",
                                }}
                              />
                            </Skeleton>
                          </DescriptionBlock>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col span={4}>
                        <DescriptionBlock
                          labelStyle={{ fontSize: 16 }}
                          label={`納入カルテ名`}
                        >
                          <></>
                        </DescriptionBlock>
                      </Col>
                      <Col span={1}>
                        <Divider style={{ height: "100%" }} type="vertical" />
                      </Col>
                      <Col span={19}>
                        <CustomInputField
                          label="納入カルテ名"
                          required
                          form={form}
                          validationResultForm={validationResultForm}
                          attr="title"
                          style={{ width: "100%" }}
                        />
                        {form.object.isTitleDuplicate && (
                          <DescriptionBlock>
                            <CustomInputField
                              label="名前が一致する納入カルテ"
                              labelStyle={{ fontSize: 13, fontWeight: 400 }}
                              form={form}
                              validationResultForm={validationResultForm}
                              disabled
                              attr="title"
                              style={{ width: "100%" }}
                            />
                          </DescriptionBlock>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                {/* 行先での作業 */}
                {(infoIndexState === ManualInfo.行先での作業 || isEdit) &&
                  CompanyManualDisplay(companyManualType, ManualKey.基本情報, isEdit) && (
                    <>
                      {/* 所要時間 */}
                      <Row>
                        <Col span={4}>
                          <DescriptionBlock
                            labelStyle={{ fontSize: 16 }}
                            label={`所要時間`}
                          >
                            <></>
                          </DescriptionBlock>
                        </Col>
                        <Col span={1}>
                          <Divider style={{ height: "100%" }} type="vertical" />
                        </Col>
                        <Col span={19}>
                          <CustomSelectRadioField
                            deselectable
                            form={form}
                            isManual
                            validationResultForm={validationResultForm}
                            label="入門〜出門までの所要時間"
                            attr="totalTime"
                            style={{ width: "100%" }}
                            selectItems={Object.entries(TotalTime)
                              .filter(
                                ([label, value]) => typeof value === "number"
                              )
                              .map(([label, value]) => ({
                                label,
                                value,
                              }))}
                            column={
                              process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                            }
                          />
                          <DescriptionBlock>
                            <CustomSelectRadioField
                              deselectable
                              form={form}
                              isManual
                              validationResultForm={validationResultForm}
                              label="受入の所要時間"
                              attr="acceptanceTime"
                              style={{ width: "100%" }}
                              selectItems={Object.entries(AcceptanceTime)
                                .filter(
                                  ([label, value]) => typeof value === "number"
                                )
                                .map(([label, value]) => ({
                                  label,
                                  value,
                                }))}
                              column={
                                process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                              }
                            />
                          </DescriptionBlock>
                        </Col>
                      </Row>
                      {/* 待ち時間 */}
                      <Row>
                        <Col span={4}>
                          <DescriptionBlock
                            labelStyle={{ fontSize: 16 }}
                            label={`待ち時間`}
                          >
                            <></>
                          </DescriptionBlock>
                        </Col>
                        <Col span={1}>
                          <Divider style={{ height: "100%" }} type="vertical" />
                        </Col>
                        <Col span={19}>
                          <CustomSelectRadioField
                            form={form}
                            isManual
                            validationResultForm={validationResultForm}
                            label="バース待ち時間"
                            attr="waitingTimeForBerth"
                            style={{ width: "100%" }}
                            selectItems={Object.entries(WaitingTime)
                              .filter(
                                ([label, value]) => typeof value === "number"
                              )
                              .map(([label, value]) => ({
                                label,
                                value,
                              }))}
                            column={
                              process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                            }
                          />
                          <DescriptionBlock>
                            <CustomSelectRadioField
                              form={form}
                              isManual
                              validationResultForm={validationResultForm}
                              label="荷役待ち時間"
                              attr="waitingTimeForHandling"
                              style={{ width: "100%" }}
                              selectItems={Object.entries(WaitingTime)
                                .filter(
                                  ([label, value]) => typeof value === "number"
                                )
                                .map(([label, value]) => ({
                                  label,
                                  value,
                                }))}
                              column={
                                process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                              }
                            />
                          </DescriptionBlock>
                          <DescriptionBlock>
                            <CustomSelectRadioField
                              form={form}
                              isManual
                              validationResultForm={validationResultForm}
                              label="納品書/受領書の受け渡しの待ち時間"
                              attr="waitingTimeForReceipt"
                              style={{ width: "100%" }}
                              selectItems={Object.entries(ReceiptHandOverTime)
                                .filter(
                                  ([label, value]) => typeof value === "number"
                                )
                                .map(([label, value]) => ({
                                  label,
                                  value,
                                }))}
                              column={
                                process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                              }
                            />
                          </DescriptionBlock>
                        </Col>
                      </Row>
                      {/* 荷役 */}
                      <Row>
                        <Col span={4}>
                          <DescriptionBlock
                            labelStyle={{ fontSize: 16 }}
                            label={`荷役`}
                          >
                            <></>
                          </DescriptionBlock>
                        </Col>
                        <Col span={1}>
                          <Divider style={{ height: "100%" }} type="vertical" />
                        </Col>
                        <Col span={19}>
                          <CustomSelectRadioField
                            deselectable
                            form={form}
                            isManual
                            validationResultForm={validationResultForm}
                            label="荷役"
                            attr="handlingTerm"
                            style={{ width: "100%" }}
                            selectItems={Object.entries(HandlingTerm)
                              .filter(
                                ([label, value]) => typeof value === "number"
                              )
                              .map(([label, value]) => ({
                                label,
                                value,
                              }))}
                            column={
                              process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                            }
                          />
                        </Col>
                      </Row>
                      {/* 荷渡し条件 */}
                      <Row>
                        <Col span={4}>
                          <DescriptionBlock
                            labelStyle={{ fontSize: 16 }}
                            label={`荷渡し条件`}
                          >
                            <></>
                          </DescriptionBlock>
                        </Col>
                        <Col span={1}>
                          <Divider style={{ height: "100%" }} type="vertical" />
                        </Col>
                        <Col span={19}>
                          <CustomSelectRadioField
                            deselectable
                            form={form}
                            isManual
                            validationResultForm={validationResultForm}
                            label="荷渡し条件"
                            attr="deliveryTerm"
                            style={{ width: "100%" }}
                            selectItems={Object.entries(DeliveryTerm)
                              .filter(
                                ([label, value]) => typeof value === "number"
                              )
                              .map(([label, value]) => ({
                                label,
                                value,
                              }))}
                            column={
                              process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                            }
                            onChange={(e) =>
                              form.update((f) => {
                                f.deliveryTerm = e.target.value;
                                f.deliveryTerm !== DeliveryTerm.その他 && (f.deliveryTermOther = undefined);
                              })
                            }
                          />
                          <DescriptionBlock>
                            <CustomInputField
                              label="荷渡し条件(自由入力欄)"
                              form={form}
                              validationResultForm={validationResultForm}
                              fieldProps={{
                                disabled:
                                  form.object?.deliveryTerm !==
                                  DeliveryTerm.その他,
                              }}
                              attr="deliveryTermOther"
                              style={{ width: "100%" }}
                            />
                          </DescriptionBlock>
                        </Col>
                      </Row>
                      {/* 荷役作業 */}
                      <Row>
                        <Col span={4}>
                          <DescriptionBlock
                            labelStyle={{ fontSize: 16 }}
                            label={`荷役作業`}
                          >
                            <></>
                          </DescriptionBlock>
                        </Col>
                        <Col span={1}>
                          <Divider style={{ height: "100%" }} type="vertical" />
                        </Col>
                        <Col span={19}>
                          <CustomSelectField
                            form={form}
                            validationResultForm={validationResultForm}
                            label="荷役作業(その他を選択したら自由入力可能)"
                            mode="multiple"
                            attr="handlingTask"
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              form.updateObject(
                                "handlingTask",
                                e
                                  .sort((a: string, b: string) =>
                                    a < b ? 1 : -1
                                  )
                                  .join(",")
                              )
                            }
                            fieldProps={{
                              value: form.object.handlingTask?.split(","),
                              children: <></>,
                            }}
                            selectItems={HANDLING_TASK.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                          />
                          <DescriptionBlock>
                            <CustomInputField
                              label="荷役作業(自由入力欄)"
                              form={form}
                              validationResultForm={validationResultForm}
                              fieldProps={{
                                disabled:
                                  !form.object?.handlingTask?.includes("その他"),
                              }}
                              attr="handlingTaskOther"
                              style={{ width: "100%" }}
                            />
                          </DescriptionBlock>
                        </Col>
                      </Row>

                      {/* 附帯業務 */}
                      <Row>
                        <Col span={4}>
                          <DescriptionBlock
                            labelStyle={{ fontSize: 16 }}
                            label={`附帯業務`}
                          >
                            <></>
                          </DescriptionBlock>
                        </Col>
                        <Col span={1}>
                          <Divider style={{ height: "100%" }} type="vertical" />
                        </Col>
                        <Col span={19}>
                          <CustomSelectField
                            form={form}
                            validationResultForm={validationResultForm}
                            label="附帯業務(その他を選択したら自由入力可能)"
                            mode="multiple"
                            attr="needTask"
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              form.updateObject(
                                "needTask",
                                e
                                  .sort((a: string, b: string) =>
                                    a < b ? 1 : -1
                                  )
                                  .join(",")
                              )
                            }
                            fieldProps={{
                              value: form.object.needTask?.split(","),
                              children: <></>,
                            }}
                            selectItems={NEED_TASK.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                          />
                          <DescriptionBlock>
                            <CustomInputField
                              label="附帯業務(自由入力欄)"
                              form={form}
                              validationResultForm={validationResultForm}
                              fieldProps={{
                                disabled:
                                  !form.object?.needTask?.includes("その他"),
                              }}
                              attr="needTaskOther"
                              style={{ width: "100%" }}
                            />
                          </DescriptionBlock>
                        </Col>
                      </Row>

                      {/* 補足資料 */}
                      <Row>
                        <Col span={4}>
                          <DescriptionBlock
                            labelStyle={{ fontSize: 16 }}
                            label={`補足資料`}
                          >
                            <></>
                          </DescriptionBlock>
                        </Col>
                        <Col span={1}>
                          <Divider style={{ height: "100%" }} type="vertical" />
                        </Col>
                        <Col span={19}>
                          <Flex vertical>
                            <DescriptionBlock
                              labelStyle={{ fontSize: 16 }}
                              label="ファイル"
                            >
                              <></>
                            </DescriptionBlock>
                            <span style={{}}>PDF</span>
                            {!!form.object.file && (
                              <Flex vertical>
                                <div style={{ position: "relative" }}>
                                  <iframe
                                    style={{ width: "100%", height: 300 }}
                                    src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${form.object.file}#toolbar=0&navpanes=0`}
                                  >
                                    このブラウザでは iframe
                                    がサポートされていません。
                                  </iframe>
                                  <ArrowsAltOutlined
                                    width={29}
                                    height={29}
                                    style={{
                                      color: "white",
                                      background: "rgba(0,0,0,0.7)",
                                      padding: 5,
                                      borderRadius: "100%",
                                      position: "absolute",
                                      bottom: "10px",
                                      right: "10px",
                                    }}
                                    onClick={showModal}
                                  />
                                  <Modal
                                    width="100%"
                                    open={isModalVisible}
                                    footer={
                                      <Button
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16,
                                          width: "100%",
                                        }}
                                        onClick={handleCancel}
                                      >
                                        閉じる
                                      </Button>
                                    }
                                    closable={false}
                                    onCancel={handleCancel}
                                  >
                                    <iframe
                                      src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${form.object.file}`}
                                      style={{ width: "100%", height: "700px" }}
                                    >
                                      このブラウザでは iframe
                                      がサポートされていません。
                                    </iframe>
                                  </Modal>
                                </div>
                              </Flex>
                            )}
                            <Flex vertical>
                              <CustomFileField
                                form={form}
                                attr={"file"}
                                accept={["pdf"]}
                                text="ファイルをアップロード"
                                fieldProps={{
                                  ...form,
                                  defaultFileList: form.object.file
                                    ? [
                                      {
                                        uid: "1",
                                        name: form.object.file,
                                        status: "done",
                                      },
                                    ]
                                    : [],
                                  itemRender: (originNode) => {
                                    return React.cloneElement(originNode, {
                                      href: undefined,
                                    });
                                  },
                                  disabled: !!form.object.file,
                                  showUploadList: {
                                    showRemoveIcon: true,
                                    removeIcon: () => (
                                      <Button
                                        style={{
                                          background: "#FFEFEE",
                                          height: 27,
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                        danger
                                        onClick={() =>
                                          form.updateObject("file", null)
                                        }
                                      >
                                        削除
                                      </Button>
                                    ),
                                  },
                                }}
                                onChange={async (e) => {
                                  if (e.file.status !== "removed") {
                                    globalState.setLoading(true);
                                    const result = await uploadApi.execute(
                                      e.file as UploadFile<any>
                                    );
                                    form.updateObject(
                                      "file",
                                      result.data.filename
                                    );
                                    globalState.setLoading(false);
                                  }
                                }}
                              ></CustomFileField>
                            </Flex>
                          </Flex>
                        </Col>
                      </Row>
                    </>
                  )}
              </div>
            )}
            {/* ドライバー画面カルテ作成・編集 */}
            {isDriver && (
              <>
                {!isEdit && InfoHeader(DriverManualInfo[infoIndexState], form)}
                {(infoIndexState === DriverManualInfo.基本情報 || isEdit) && (
                  <>
                    <>
                      <CustomSelectField
                        form={form}
                        validationResultForm={validationResultForm}
                        required
                        label="行先を選択"
                        attr="deliveryCompanyId"
                        disabled={guest || isEdit}
                        selectItems={
                          deliveryCompanies?.map((d) => ({
                            label: d.name!,
                            value: d.id!,
                          })) ?? []
                        }
                        style={{ width: "100%" }}
                        fieldProps={{
                          showSearch: true,
                          filterOption: filterKanaName(deliveryCompanies),
                          optionFilterProp: "label",
                        }}
                      />
                      {(
                        // 作成時、カルテ閲覧画面から作成する場合
                        (!isEdit && !!form.object.manualId && (form.object.deliveryCompany?.deliveryCompanies || []).length > 0) ||
                        // 編集時、地点idが存在する場合
                        (isEdit && form.object.deliveryPointCompanyId)
                      ) && (
                          <CustomSelectField
                            form={form}
                            validationResultForm={validationResultForm}
                            disabled={guest || isEdit}
                            label="地点を選択"
                            attr="deliveryPointCompanyId"
                            selectItems={
                              [form.object.deliveryCompany, ...(form.object.deliveryCompany?.deliveryCompanies || [])]?.map((d) => ({
                                label: d?.point?.memo || d?.name,
                                value: d?.id,
                              })) ?? []
                            }
                            style={{ width: "100%" }}
                            fieldProps={{
                              showSearch: true,
                              filterOption: filterKanaName([form.object.deliveryCompany || {}, ...(form.object.deliveryCompany?.deliveryCompanies || [])]),
                              optionFilterProp: "label",
                              allowClear: true
                            }}
                          />
                        )}
                      {form.object.deliveryCompany && (
                        <Skeleton loading={globalState.loading}>
                          <GoogleMapComponent
                            mapCenter={{
                              lat:
                                deliveryPointCompany()?.point
                                  ?.latitude ||
                                form.object.deliveryCompany?.point
                                  ?.latitude || 0,
                              lng:
                                deliveryPointCompany()?.point
                                  ?.longitude ||
                                form.object.deliveryCompany?.point
                                  ?.longitude || 0,
                            }}
                            markerOptions={[{
                              position: {
                                lat:
                                  deliveryPointCompany()?.point
                                    ?.latitude ||
                                  form.object.deliveryCompany?.point
                                    ?.latitude || 0,
                                lng:
                                  deliveryPointCompany()?.point
                                    ?.longitude ||
                                  form.object.deliveryCompany?.point
                                    ?.longitude || 0,
                              },
                              visible: true
                            }]}
                            onClickMarker={(e, marker) => {
                              const targetPoint = deliveryPointCompany()?.point || form.object.deliveryCompany?.point;
                              const infowindow = new google.maps.InfoWindow({
                                content: targetPoint?.memo,
                              });
                              targetPoint && infowindow.open(marker?.getMap(), marker);
                            }}
                            disableControll
                            style={{
                              height: 205,
                              borderRadius: 6,
                              margin: "8px 0",
                            }}
                          />
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: "bold",
                              color: `${token.colorText}`,
                              opacity: 0.6,
                            }}
                          >
                            {`〒${form.object.deliveryCompany?.zipcode} ${form.object.deliveryCompany?.address}`}
                          </div>
                        </Skeleton>
                      )}
                    </>
                    {!guest && <DescriptionBlock>
                      <Skeleton loading={globalState.loading}>
                        <CustomSelectField
                          form={form}
                          validationResultForm={validationResultForm}
                          label="運送会社を選択"
                          disabled={isEdit}
                          attr="deliveryPartnerCompanyId"
                          selectItems={
                            deliveryPartnerCompanies?.filter(c1 =>
                              // 行先に紐づいた運送会社があればフィルタ
                              form.object.deliveryCompany?.deliveryPartnerCompanies?.map(c2 => c2.id).includes(c1.id) || !form.object.deliveryCompany?.deliveryPartnerCompanies?.length
                            ).map((d) => ({
                              label: d.name!,
                              value: d.id!,
                            })) ?? []
                          }
                          style={{ width: "100%" }}
                          fieldProps={{
                            showSearch: true,
                            filterOption: filterKanaName(deliveryPartnerCompanies),
                            optionFilterProp: "label",
                          }}
                        />
                      </Skeleton>
                    </DescriptionBlock>
                    }
                    {!guest && <DescriptionBlock>
                      <Skeleton loading={globalState.loading}>
                        <CustomSelectField
                          form={form}
                          validationResultForm={validationResultForm}
                          label="荷主を選択"
                          attr="consignorId"
                          selectItems={
                            consignors?.map((d) => ({
                              label: d.name!,
                              value: d.id!,
                            })) ?? []
                          }
                          style={{ width: "100%" }}
                          fieldProps={{
                            showSearch: true,
                            filterOption: filterKanaName(consignors),
                            optionFilterProp: "label",
                          }}
                        />
                      </Skeleton>
                    </DescriptionBlock>
                    }
                  </>
                )}
                {(infoIndexState === DriverManualInfo.納入カルテ名 || isEdit) && (
                  <>
                    <DescriptionBlock>
                      <CustomInputField
                        label="納入カルテ名"
                        form={form}
                        validationResultForm={validationResultForm}
                        attr="title"
                        style={{ width: "100%" }}
                      />
                    </DescriptionBlock>
                    {form.object.isTitleDuplicate && (
                      <DescriptionBlock>
                        <CustomInputField
                          label="名前が一致する納入カルテ"
                          labelStyle={{ fontSize: 13, fontWeight: 400 }}
                          form={form}
                          validationResultForm={validationResultForm}
                          disabled
                          attr="title"
                          style={{ width: "100%" }}
                        />
                      </DescriptionBlock>
                    )}
                  </>
                )}
                {(infoIndexState === DriverManualInfo.荷渡しと荷役 || isEdit) &&
                  CompanyManualDisplay(companyManualType, ManualKey.基本情報, isEdit) && (
                    <>
                      {/* 所要時間 */}
                      <DescriptionBlock>
                        <CustomSelectRadioField
                          deselectable
                          form={form}
                          isManual
                          validationResultForm={validationResultForm}
                          label="入門〜出門までの所要時間"
                          attr="totalTime"
                          style={{ width: "100%" }}
                          selectItems={Object.entries(TotalTime)
                            .filter(([label, value]) => typeof value === "number")
                            .map(([label, value]) => ({
                              label,
                              value,
                            }))}
                          column={isMobile ? 1 : 2}
                        />
                      </DescriptionBlock>
                      <DescriptionBlock>
                        <CustomSelectRadioField
                          deselectable
                          form={form}
                          isManual
                          validationResultForm={validationResultForm}
                          label="受入の所要時間"
                          attr="acceptanceTime"
                          style={{ width: "100%" }}
                          selectItems={Object.entries(AcceptanceTime)
                            .filter(([label, value]) => typeof value === "number")
                            .map(([label, value]) => ({
                              label,
                              value,
                            }))}
                          column={isMobile ? 1 : 2}
                        />
                      </DescriptionBlock>

                      {/* 待ち時間 */}
                      <CustomSelectRadioField
                        form={form}
                        isManual
                        validationResultForm={validationResultForm}
                        label="バース待ち時間"
                        attr="waitingTimeForBerth"
                        style={{ width: "100%" }}
                        selectItems={Object.entries(WaitingTime)
                          .filter(
                            ([label, value]) => typeof value === "number"
                          )
                          .map(([label, value]) => ({
                            label,
                            value,
                          }))}
                        column={
                          process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                        }
                      />
                      <DescriptionBlock>
                        <CustomSelectRadioField
                          form={form}
                          isManual
                          validationResultForm={validationResultForm}
                          label="荷役待ち時間"
                          attr="waitingTimeForHandling"
                          style={{ width: "100%" }}
                          selectItems={Object.entries(WaitingTime)
                            .filter(
                              ([label, value]) => typeof value === "number"
                            )
                            .map(([label, value]) => ({
                              label,
                              value,
                            }))}
                          column={
                            process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                          }
                        />
                      </DescriptionBlock>
                      <DescriptionBlock>
                        <CustomSelectRadioField
                          form={form}
                          isManual
                          validationResultForm={validationResultForm}
                          label="納品書/受領書の受け渡しの待ち時間"
                          attr="waitingTimeForReceipt"
                          style={{ width: "100%" }}
                          selectItems={Object.entries(ReceiptHandOverTime)
                            .filter(
                              ([label, value]) => typeof value === "number"
                            )
                            .map(([label, value]) => ({
                              label,
                              value,
                            }))}
                          column={
                            process.env.REACT_APP_TYPE === "ADMIN" ? 2 : 1
                          }
                        />
                      </DescriptionBlock>

                      {/* 荷役 */}
                      <DescriptionBlock>
                        <CustomSelectRadioField
                          deselectable
                          form={form}
                          isManual
                          validationResultForm={validationResultForm}
                          label="荷役"
                          attr="handlingTerm"
                          style={{ width: "100%" }}
                          selectItems={Object.entries(HandlingTerm)
                            .filter(([label, value]) => typeof value === "number")
                            .map(([label, value]) => ({
                              label,
                              value,
                            }))}
                          column={isMobile ? 1 : 2}
                        />
                      </DescriptionBlock>

                      {/* 荷渡し条件 */}
                      <DescriptionBlock>
                        <CustomSelectField
                          form={form}
                          validationResultForm={validationResultForm}
                          label="荷渡し条件"
                          attr="deliveryTerm"
                          style={{ width: "100%" }}
                          selectItems={Object.keys(DeliveryTerm)
                            .filter((key) => isNaN(Number(key)))
                            .map((dnt, index) => ({
                              label: dnt,
                              value: index,
                            }))}
                          onChange={(e) =>
                            form.update((f) => {
                              f.deliveryTerm = e.target.value;
                              f.deliveryTerm !== DeliveryTerm.その他 && (f.deliveryTermOther = undefined);
                            })
                          }
                        />
                      </DescriptionBlock>
                      <DescriptionBlock>
                        <CustomInputField
                          label="荷渡し条件(自由入力欄)"
                          form={form}
                          validationResultForm={validationResultForm}
                          fieldProps={{
                            disabled:
                              form.object?.deliveryTerm !== DeliveryTerm.その他,
                          }}
                          attr="deliveryTermOther"
                          style={{ width: "100%" }}
                        />
                      </DescriptionBlock>

                      {/* 荷役作業 */}
                      <DescriptionBlock>
                        <CustomSelectField
                          form={form}
                          validationResultForm={validationResultForm}
                          label="荷役作業(その他を選択したら自由入力可能)"
                          mode="multiple"
                          attr="handlingTask"
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            form.updateObject(
                              "handlingTask",
                              e
                                .sort((a: string, b: string) => (a < b ? 1 : -1))
                                .join(",")
                            )
                          }
                          fieldProps={{
                            value: form.object.handlingTask?.split(","),
                            children: <></>,
                          }}
                          selectItems={HANDLING_TASK.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </DescriptionBlock>
                      <DescriptionBlock>
                        <CustomInputField
                          label="荷役作業(自由入力欄)"
                          form={form}
                          validationResultForm={validationResultForm}
                          fieldProps={{
                            disabled:
                              !form.object?.handlingTask?.includes("その他"),
                          }}
                          attr="handlingTaskOther"
                          style={{ width: "100%" }}
                        />
                      </DescriptionBlock>
                    </>
                  )}
                {(infoIndexState === DriverManualInfo.附帯業務 || isEdit) &&
                  CompanyManualDisplay(companyManualType, ManualKey.基本情報, isEdit) && (
                    <>
                      {/* 附帯業務 */}
                      <DescriptionBlock>
                        <CustomSelectField
                          form={form}
                          validationResultForm={validationResultForm}
                          label="附帯業務(その他を選択したら自由入力可能)"
                          mode="multiple"
                          attr="needTask"
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            form.updateObject(
                              "needTask",
                              e
                                .sort((a: string, b: string) => (a < b ? 1 : -1))
                                .join(",")
                            )
                          }
                          fieldProps={{
                            value: form.object.needTask?.split(","),
                            children: <></>,
                          }}
                          selectItems={NEED_TASK.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </DescriptionBlock>
                      <DescriptionBlock>
                        <CustomInputField
                          label="附帯業務(自由入力欄)"
                          form={form}
                          validationResultForm={validationResultForm}
                          fieldProps={{
                            disabled: !form.object?.needTask?.includes("その他"),
                          }}
                          attr="needTaskOther"
                          style={{ width: "100%" }}
                        />
                      </DescriptionBlock>

                      {/* 補足資料 */}
                      <DescriptionBlock>
                        <DescriptionBlock
                          labelStyle={{ fontSize: 16 }}
                          label="ファイル"
                        >
                          <></>
                        </DescriptionBlock>
                        <span style={{ fontSize: 14, fontWeight: 400 }}>PDF</span>
                        {!!form.object.file && (
                          <div style={{ position: "relative" }}>
                            <iframe
                              style={{ width: "100%", height: 300 }}
                              src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${form.object.file}#toolbar=0&navpanes=0`}
                            >
                              このブラウザでは iframe がサポートされていません。
                            </iframe>
                            <ArrowsAltOutlined
                              width={29}
                              height={29}
                              style={{
                                color: "white",
                                background: "rgba(0,0,0,0.7)",
                                padding: 5,
                                borderRadius: "100%",
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                              }}
                              onClick={showModal}
                            />
                            <Modal
                              style={{
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                              }}
                              open={isModalVisible}
                              footer={
                                <Button
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 16,
                                    width: "100%",
                                  }}
                                  onClick={handleCancel}
                                >
                                  閉じる
                                </Button>
                              }
                              closable={false}
                              onCancel={handleCancel}
                            >
                              <iframe
                                src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${form.object.file}`}
                                style={{ width: "100%", height: 550 }}
                              >
                                このブラウザでは iframe がサポートされていません。
                              </iframe>
                            </Modal>
                          </div>
                        )}
                        <CustomFileField
                          form={form}
                          attr={"file"}
                          accept={["pdf"]}
                          text="ファイルをアップロード"
                          fieldProps={{
                            ...form,
                            defaultFileList: form.object.file
                              ? [
                                {
                                  uid: "1",
                                  name: form.object.file,
                                  status: "done",
                                },
                              ]
                              : [],
                            itemRender: (originNode) => {
                              return React.cloneElement(originNode, {
                                href: undefined,
                              });
                            },
                            disabled: !!form.object.file,
                            showUploadList: {
                              showRemoveIcon: true,
                              removeIcon: () => (
                                <Button
                                  style={{
                                    background: "#FFEFEE",
                                    height: 27,
                                    fontSize: 12,
                                    fontWeight: "bold",
                                  }}
                                  danger
                                  onClick={() => form.updateObject("file", null)}
                                >
                                  削除
                                </Button>
                              ),
                            },
                          }}
                          onChange={async (e) => {
                            if (e.file.status !== "removed") {
                              globalState.setLoading(true);
                              const result = await uploadApi.execute(
                                e.file as UploadFile<any>
                              );
                              form.updateObject("file", result.data.filename);
                              globalState.setLoading(false);
                            }
                          }}
                        ></CustomFileField>
                      </DescriptionBlock>
                    </>
                  )}
              </>
            )}
          </Space>
        </>
      )}
      {_key === ManualKey.手順 && CompanyManualDisplay(companyManualType, ManualKey.手順, isEdit) && (
        <TemplateFormView
          isDriver={isDriver}
          isEdit={isEdit}
          templates={templates}
          form={form}
          validationResultForm={validationResultForm}
          stepIndexState={stepIndexState}
          setStepIndexState={setStepIndexState}
          guest={guest}
        />
      )}
      {_key === ManualKey.構内マップ &&
        (isDriver ? (
          // ドライバー
          <Space
            style={{
              margin: 24,
              minHeight: isDriver ? "calc(100vh - 210px)" : "100%",
            }}
            direction="vertical"
          >
            <ManualMapForm
              validationResultForm={validationResultForm}
              form={form}
              isDriver
              isEdit={isEdit}
            />
          </Space>
        ) : (
          // 管理者
          <div style={{ width: 720, margin: "0 auto" }}>
            <Skeleton loading={globalState.loading}>
              {!isEdit && (
                <DescriptionBlock
                  labelStyle={{ fontSize: 18, margin: "30px 0 0 0" }}
                  label="作業場所を地図上で示してください。"
                  valueStyle={{
                    fontSize: 12,
                    fontWeight: 600,
                    opacity: 0.6,
                    marginBottom: 20,
                    textAlign: "right"
                  }}
                >
                  {form.object.deliveryCompany ?
                    <>
                      {`${form.object.deliveryCompany.name}`}
                      <br />
                      {`〒${form.object.deliveryCompany.zipcode} ${form.object.deliveryCompany.address}`}
                    </> :
                    ''
                  }
                </DescriptionBlock>
              )}
              <Row>
                <Col span={4}>
                  <DescriptionBlock
                    labelStyle={{ fontSize: 16 }}
                    label="作業場所"
                  >
                    <></>
                  </DescriptionBlock>
                </Col>
                <Col span={1}>
                  <Divider style={{ height: "100%" }} type="vertical" />
                </Col>
                <Col span={19}>
                  <ManualMapForm
                    validationResultForm={validationResultForm}
                    form={form}
                    isEdit={isEdit}
                  />
                </Col>
              </Row>
            </Skeleton>
          </div>
        ))}
    </>
  );
};

type TemplateFormViewProps = {
  form: Form<Manual>;
  validationResultForm: Form<Manual>;
  stepIndexState: number;
  setStepIndexState?: (stepIndexState: number) => void;
  templates?: Template[];
  isEdit?: boolean;
  isDriver?: boolean;
  guest?: boolean;
};

const TemplateFormView = ({
  form,
  validationResultForm,
  stepIndexState,
  setStepIndexState,
  templates,
  isEdit,
  isDriver,
  guest
}: TemplateFormViewProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const templateApi = useFetchTemplateApi();
  const companyApi = useFetchCurrentCompanyApi();
  const isMobile = useMedia("(max-width: 519px)");

  useEffect(() => {
    if (globalState.user.companyId && isEdit) {
      companyApi.execute();
    }
  }, [globalState.user.companyId]);

  useEffect(() => {
    if (form.object.templateId) {
      templateApi.execute(form.object.templateId);
    }
  }, [form.object.templateId]);

  useEffect(() => {
    globalState.setLoading(templateApi.loading || companyApi.loading);
    if (templateApi.isSuccess() && companyApi.isSuccess()) {
      form.set({
        ...form.object,
        templateId: form.object.templateId
          ? form.object.templateId
          : company()?.defaultTemplateId,
        selectedStepNos: (template()?.steps || []).length > 0 ? template()?.steps?.map(step => step.stepNo ?? 0) : form.object.selectedStepNos,
        manualDetails: steps?.length
          ? steps?.map((step) => {
            // stepIdの一致するmanualDetailを取得
            const targetDetail = form.object.manualDetails?.find(
              (detail) => detail.stepId === step.id
            );
            const targetProcesses = targetDetail?.manualProcesses;
            return {
              stepId: step.id,
              stepNo: step.stepNo,
              picture1: undefined,
              ...targetDetail,
              manualProcesses: step.questions?.map((question) => {
                const targetProcess = targetProcesses?.find((process) => process.questionId === question.id);
                return {
                  questionNo: question.questionNo,
                  questionId: question.id,
                  value: "",
                  ...targetProcess,
                };
              }),
            };
          })
          : form.object.manualDetails,
      });
    }
  }, [templateApi.loading, companyApi.loading]);

  const template = (): Template | undefined => {
    return templateApi.response.data;
  };

  const steps = useMemo((): Step[] => {
    return (
      template()?.steps?.filter((step) =>
        !form.object.selectedStepNos || // selectedStepNosがundefinedなら全て表示
        form.object.selectedStepNos?.includes(step.stepNo ?? 0)
      ) ?? []
    );
  }, [template(), form.object.selectedStepNos]);

  const company = (): Company => {
    return companyApi.response.data;
  };

  useEffect(() => {
    if (!isEdit) {
      // カルテ作成時の手順初期状態
      form.set({
        ...form.object,
        manualDetails: steps.map((step) => ({
          stepId: step.id,
          stepNo: step.stepNo,
          picture1: undefined,
          manualProcesses: step.questions?.map((question) => ({
            questionNo: question.questionNo,
            questionId: question.id,
            value: "",
          })),
        })),
      });
    }
  }, [form.object.selectedStepNos]);

  return (
    <div
      style={
        !isDriver && isEdit
          // 管理者編集画面
          ? {}
          // 管理者作成画面・ドライバー作成編集画面
          : {
            width: isDriver ? (guest ? "100%" : "calc(100vw - 48px)") : "initial",
            padding: 24,
            minHeight: isDriver ? "calc(100vh - 210px)" : "100%",
          }
      }
    >
      {(stepIndexState === -1 || (!isDriver && stepIndexState === 0)) &&
        !isEdit && (
          // テンプレート選択画面
          <div
            style={
              isDriver
                ? {}
                : { width: 720, margin: "10px auto", marginBottom: 30 }
            }
          >
            <CustomSelectField
              attr={"templateId"}
              form={form}
              label="カルテテンプレート"
              labelStyle={{ fontSize: 18 }}
              style={{ marginBottom: 20 }}
              selectItems={
                (templates || company().templates)?.map((template) => ({
                  label: template.templateName!,
                  value: template.id!,
                })) || []
              }
            />
            <DescriptionBlock
              labelStyle={{ fontSize: 18, marginBottom: 30 }}
              label="手順の有無を選択してください"
            >
              <></>
            </DescriptionBlock>
            <CustomCheckboxGroupField
              validationResultForm={validationResultForm}
              form={form}
              label="必要な手順"
              labelStyle={{
                fontWeight: 600,
                // color: questionHeader.contents ? "inherit" : token.colorError,
                fontSize: "16px",
                lineHeight: "24px",
              }}
              style={{
                marginTop: 4,
                width: "100%",
              }}
              attr={"selectedStepNos"}
              selectItems={template()?.steps?.map((step) => ({
                label: step.stepName,
                value: step.stepNo,
              }))}
              column={isMobile ? 1 : 2}
              isManual
            />
          </div>
        )}
      {(stepIndexState >= 0) && (
        // 手順入力画面
        <>
          {isDriver ? (
            // ドライバー画面
            <>
              {/* 作成 */}
              {!isEdit && (
                <DescriptionBlock
                  labelStyle={{ fontSize: 18 }}
                  label={`${steps[stepIndexState].stepName
                    }について入力してください`}
                  valueStyle={{
                    fontSize: 12,
                    fontWeight: 600,
                    opacity: 0.6,
                    marginBottom: isDriver ? 0 : 20,
                    textAlign: "right"
                  }}
                >
                  {form.object.deliveryCompany ?
                    <>
                      {`${form.object.deliveryCompany.name}`}
                      <br />
                      {`〒${form.object.deliveryCompany.zipcode} ${form.object.deliveryCompany.address}`}
                    </> :
                    ''
                  }
                </DescriptionBlock>
              )}
              {stepIndexState === 0 && isEdit && (
                <CustomSelectField
                  attr={"templateId"}
                  form={form}
                  label="カルテテンプレート"
                  labelStyle={{ fontSize: 18 }}
                  style={{ marginBottom: 20 }}
                  selectItems={
                    (templates || company().templates)?.map((template) => ({
                      label: template.templateName!,
                      value: template.id!,
                    })) || []
                  }
                />
              )}
              {/* 作成・編集 */}
              <ManualStepFormView
                isEdit={isEdit}
                form={form}
                validationResultForm={validationResultForm}
                step={steps[stepIndexState]}
                stepIndex={stepIndexState}
              />
            </>
          ) : // 管理者画面
            isEdit ? (
              // 編集
              <Space
                direction="horizontal"
                align="start"
                split={<div style={{ padding: 2 }}></div>}
                style={{ paddingBottom: 62 }}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "264px",
                    background: "white",
                    border: 1,
                    borderRadius: 4,
                    fontSize: 18,
                    padding: "20px 0",
                  }}
                >
                  {steps.map((step, stepIndex) => (
                    <div
                      key={stepIndex}
                      style={{
                        cursor: "pointer",
                        fontWeight: stepIndexState === stepIndex ? 700 : 500,
                        lineHeight: "43px",
                        paddingLeft: 15,
                        color:
                          stepIndexState === stepIndex
                            ? token.colorPrimary
                            : "#6B7280",
                        borderLeft:
                          stepIndexState === stepIndex
                            ? `3px solid ${token.colorPrimary}`
                            : "none",
                      }}
                      onClick={() =>
                        setStepIndexState && setStepIndexState(stepIndex)
                      }
                    >
                      {step.stepName}
                    </div>
                  ))}
                </Space>
                <div
                  style={
                    isEdit ? {} : { width: "80%", padding: 24, margin: "0 auto" }
                  }
                >
                  {stepIndexState === 0 && (company().templates || []).length > 0 && (
                    <CustomSelectField
                      attr={"templateId"}
                      form={form}
                      label="カルテテンプレート"
                      labelStyle={{ fontSize: 18 }}
                      style={{ marginBottom: 20 }}
                      fieldProps={{
                        value: company().templates?.map(template => template.id).includes(form.getValue("templateId") as string) ?
                          form.getValue("templateId") :
                          "テンプレートを選択"
                      }}
                      selectItems={
                        company().templates?.map((template) => ({
                          label: template.templateName!,
                          value: template.id!,
                        })) || []
                      }
                    />
                  )}
                  {!templateApi.loading &&
                    templateApi.isSuccess() &&
                    steps.length > 0 && (
                      <>
                        {!isEdit && (
                          <DescriptionBlock
                            labelStyle={{ fontSize: 18 }}
                            label={`${steps[stepIndexState].stepName
                              }について入力してください`}
                            valueStyle={{
                              fontSize: 12,
                              fontWeight: 600,
                              opacity: 0.6,
                              marginBottom: isDriver ? 0 : 20,
                              textAlign: "right"
                            }}
                          >
                            {form.object.deliveryCompany ?
                              <>
                                {`${form.object.deliveryCompany.name}`}
                                <br />
                                {`〒${form.object.deliveryCompany.zipcode} ${form.object.deliveryCompany.address}`}
                              </> :
                              ''
                            }
                          </DescriptionBlock>
                        )}
                        <Row style={{ width: isEdit ? 720 : "100%" }}>
                          <Col span={4}>
                            <DescriptionBlock
                              labelStyle={{ fontSize: 16 }}
                              label={`${steps[stepIndexState].stepName}の手順`}
                            >
                              <></>
                            </DescriptionBlock>
                          </Col>
                          <Col span={1}>
                            <Divider style={{ height: "100%" }} type="vertical" />
                          </Col>
                          <Col span={19}>
                            <ManualStepFormView
                              isEdit={isEdit}
                              form={form}
                              validationResultForm={validationResultForm}
                              step={steps[stepIndexState]}
                              stepIndex={stepIndexState}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                </div>
              </Space>
            ) : (
              // 作成
              <div style={{ width: 720, margin: "10px auto" }}>
                {stepIndexState === -1 && (
                  <CustomSelectField
                    attr={"templateId"}
                    form={form}
                    label="カルテテンプレート"
                    labelStyle={{ fontSize: 18 }}
                    style={{ marginBottom: 20 }}
                    selectItems={
                      company().templates?.map((template) => ({
                        label: template.templateName!,
                        value: template.id!,
                      })) || []
                    }
                  />
                )}
                {!templateApi.loading &&
                  templateApi.isSuccess() &&
                  steps.length && (
                    <>
                      {!isEdit && (
                        <DescriptionBlock
                          labelStyle={{ fontSize: 18 }}
                          label={`${steps[stepIndexState].stepName
                            }について入力してください`}
                          valueStyle={{
                            fontSize: 12,
                            fontWeight: 600,
                            opacity: 0.6,
                            marginBottom: isDriver ? 0 : 20,
                            textAlign: "right"
                          }}
                        >
                          {form.object.deliveryCompany ?
                            <>
                              {`${form.object.deliveryCompany.name}`}
                              <br />
                              {`〒${form.object.deliveryCompany.zipcode} ${form.object.deliveryCompany.address}`}
                            </> :
                            ''
                          }
                        </DescriptionBlock>
                      )}
                      <Row style={{ width: isEdit ? 720 : "100%" }}>
                        <Col span={4}>
                          <DescriptionBlock
                            labelStyle={{ fontSize: 16 }}
                            label={`${steps[stepIndexState].stepName}の手順`}
                          >
                            <></>
                          </DescriptionBlock>
                        </Col>
                        <Col span={1}>
                          <Divider style={{ height: "100%" }} type="vertical" />
                        </Col>
                        <Col span={19}>
                          <ManualStepFormView
                            isEdit={isEdit}
                            form={form}
                            validationResultForm={validationResultForm}
                            step={steps[stepIndexState]}
                            stepIndex={stepIndexState}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
              </div>
            )}
        </>
      )}
    </div>
  );
};

export const ManualMapForm = ({
  form,
  isDriver,
  isEdit,
}: {
  form: Form<Manual>;
  isDriver?: boolean;
  validationResultForm: Form<Manual>;
  isEdit?: boolean;
}) => {
  const pointTypeForm = useForm<Point>({});
  const postPointApi = usePostPointApi();
  const editPointApi = useEditPointApi();
  const deletePointApi = useDeletePointApi();

  const [relavantPoint, setRelavantPoint] = useState<Point>({});
  const globalState = useContext(GlobalStateContext);

  useEffectSkipFirst(() => {
    globalState.setLoading(postPointApi.loading);
    if (postPointApi.isSuccess() && postPointApi.response.data?.id) {
      form.updateObject("points", [
        ...(form.object.points ?? []),
        { ...relavantPoint, id: postPointApi.response?.data?.id },
      ]);
    }
  }, [postPointApi.loading]);

  useEffectSkipFirst(() => {
    if (
      !prevPoints.current?.find(
        (point) =>
          JSON.stringify([
            point.latitude,
            point.longitude,
            point.pointType,
            point.memo,
          ]) ===
          JSON.stringify([
            pointTypeForm.object.latitude,
            pointTypeForm.object.longitude,
            pointTypeForm.object.pointType,
            pointTypeForm.object.memo,
          ])
      )
    ) {
      prevPoints.current = [
        ...(prevPoints.current?.filter(
          (point) =>
            JSON.stringify([point.latitude, point.longitude]) !==
            JSON.stringify([
              pointTypeForm.object.latitude,
              pointTypeForm.object.longitude,
            ]) && !!point.pointType
        ) ?? []),
        ...(pointTypeForm.object.pointType !== PointType.削除
          ? [{ ...pointTypeForm.object }]
          : []),
      ].slice();
      form.updateObject("points", prevPoints.current.slice());
    }
  }, [JSON.stringify(pointTypeForm.object)]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editPointApi.loading);
    if (editPointApi.isSuccess()) {
      form.updateObject(
        "points",
        form.object.points?.map((point) =>
          point.id === relavantPoint.id ? relavantPoint : point
        )
      );
    }
  }, [editPointApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deletePointApi.loading);
    if (deletePointApi.isSuccess()) {
      form.updateObject(
        "points",
        (form.object.points ?? []).filter(
          (point) => point.id !== relavantPoint.id
        )
      );
    }
  }, [deletePointApi.loading]);

  const pointForm = useForm<PointForm>({});

  const prevPoints = useRef<Point[]>(form.object.points ?? []);

  useEffect(() => {
    pointForm.set({
      manualId: form.object.id,
    });
  }, [form.object.id]);

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={16}>
      <div>
        {isDriver && (
          <LabelView
            label={`${isEdit ? "アイコンをタップ、または" : ""
              }作業場所を長押し`}
            style={{ marginBottom: 20 }}
          />
        )}
        {!isDriver && (
          <LabelView
            style={{ fontSize: 16, marginBottom: 10 }}
            label="作業場所をクリック"
          />
        )}
        <Space
          direction="vertical"
          split={
            <Divider
              type="horizontal"
              style={{ marginTop: 8, marginBottom: 8 }}
            />
          }
        >
          <Space direction="vertical">
            <GoogleMapComponent
              isDriver={isDriver}
              pointTypeForm={pointTypeForm}
              mapCenter={{
                lat: form.object.deliveryPointCompany?.point?.latitude || form.object.deliveryCompany?.point?.latitude || 0,
                lng: form.object.deliveryPointCompany?.point?.longitude || form.object.deliveryCompany?.point?.longitude || 0,
              }}
              markerOptions={[
                {
                  position: {
                    lat: form.object.deliveryPointCompany?.point?.latitude || form.object.deliveryCompany?.point?.latitude || 0,
                    lng: form.object.deliveryPointCompany?.point?.longitude || form.object.deliveryCompany?.point?.longitude || 0,
                  },
                  visible: true
                },
                ...(prevPoints.current?.map((point) => ({
                  data: point,
                  position: {
                    lat: point?.latitude ?? 0,
                    lng: point?.longitude ?? 0,
                  },
                  icon: ManualMarkers.find(
                    (manualMaker) => manualMaker.pointType === point.pointType
                  )?.icon,
                  draggable: true,
                  visible: true
                })) ?? []),
              ]}
              onClick={(e: google.maps.MapMouseEvent) => {
                pointTypeForm.set({
                  latitude: e.latLng?.lat(),
                  longitude: e.latLng?.lng(),
                });
              }}
              onClickMarker={(e) => {
                const targetPoint = prevPoints.current?.find(
                  (point) =>
                    JSON.stringify([point.latitude, point.longitude]) ===
                    JSON.stringify([e.latLng?.lat(), e.latLng?.lng()])
                );
                pointTypeForm.set({ ...targetPoint });
                return targetPoint;
              }}
              onDbclickMarker={(e, data: Point) => {
                if (data.id) {
                  deletePointApi.execute(data.id);
                }
                setRelavantPoint(data);
              }}
              onDragstartMarker={(e) => {
                const targetPoint = prevPoints.current?.find(
                  (point) =>
                    JSON.stringify([point.latitude, point.longitude]) ===
                    JSON.stringify([e.latLng?.lat(), e.latLng?.lng()])
                );
                pointTypeForm.set({ ...targetPoint });
                return targetPoint;
              }}
              onDragendMarker={(e, data: Point) => {
                if (e.latLng) {
                  prevPoints.current = [
                    ...(prevPoints.current?.filter(
                      (point) =>
                        JSON.stringify([point.latitude, point.longitude]) !==
                        JSON.stringify([data.latitude, data.longitude])
                    ) ?? []),
                  ].slice();
                  pointTypeForm.set({
                    ...data,
                    latitude: e.latLng.lat(),
                    longitude: e.latLng.lng(),
                  });
                }
              }}
              style={{
                width: isDriver ? "calc(100vw - 48px)" : 548,
              }}
            />
          </Space>
        </Space>
      </div>
    </Space>
  );
};

export const ManualStepFormView = ({
  form,
  validationResultForm,
  step,
  stepIndex,
  isEdit,
}: {
  form: Form<Manual>;
  validationResultForm: Form<Manual>;
  step: Step;
  stepIndex: number;
  isEdit?: boolean;
}) => {
  const fieldProps = {
    form,
    labelStyle: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
  };
  const uploadApi = useUploadApi();
  const globalState = useContext(GlobalStateContext);

  const [uploadedFiles, setUploadedFiles] = useState<
    { name?: string; path?: string }[]
  >(
    [1, 2, 3]
      .map((index) => {
        const pictureKey = `picture${index}`;
        const picture = form.getValue([
          "manualDetails",
          stepIndex,
          pictureKey,
        ]) as string;
        return picture ? { name: picture, path: picture } : {};
        // 作成の時は空のオブジェクトを除外
      })
      .filter((file) => Object.keys(file).length > 0 && !isEdit)
  );
  const uploadCount = useRef(0);

  const storeFileName = (
    fileInfo: { name: string; path: string },
    index?: number
  ) => {
    if (index !== undefined) {
      // 編集時
      setUploadedFiles((prevFiles) => {
        prevFiles[index] = fileInfo;
        return prevFiles.slice();
      });
    } else {
      // 作成時
      setUploadedFiles((prevFiles) => {
        const newFiles = [...prevFiles, fileInfo];
        return newFiles.slice(0, 3); // 最初の3つの要素だけを保持
      });
    }
  };

  const [isPreviewVisible, setIsPreviewVisible] = useState<
    Record<number, boolean>
  >({});

  const togglePreview = (index: number) => {
    setIsPreviewVisible((prev) => {
      const newPreviewState = { ...prev };
      newPreviewState[index] = !newPreviewState[index];
      return newPreviewState;
    });
  };

  useEffectSkipFirst(() => {
    // uploadedFilesに追加されたらフォームを更新します。
    const existingPictureCounts = [1, 2, 3]
      .map((index) =>
        form.getValue(["manualDetails", stepIndex, `picture${index}`])
      )
      .filter(Boolean).length;
    if (existingPictureCounts < uploadedFiles.length)
      uploadedFiles.forEach((file, index) => {
        const pictureAttr = ["manualDetails", stepIndex, `picture${index + 1}`];
        form.updateObject(pictureAttr, file.path);
      });
  }, [uploadedFiles]);

  useEffectSkipFirst(() => {
    // stepが切り替わったらuploadedFilesステートを初期化します。
    [0, 1, 2].forEach((index) => {
      const pictureAttr = ["manualDetails", stepIndex, `picture${index + 1}`];
      const picture = form.getValue(pictureAttr);
      if (index === 0 && picture) {
        setUploadedFiles((prev) => {
          return [{ name: picture as string, path: picture as string }];
        });
      } else if (index === 0) {
        setUploadedFiles([]);
      } else if (picture) {
        setUploadedFiles((prev) => {
          return [
            ...prev,
            { name: picture as string, path: picture as string },
          ];
        });
      } else if (isEdit) {
        setUploadedFiles((prev) => {
          return [...prev, {}];
        });
      }
    });
  }, [step]);


  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {
        // 分岐元質問と分岐内質問をグループ化しつつ並び替える関数
        sortQuestions(step?.questions || [])
          .map((question: Question, index, ary) => {
            const attr = ["manualDetails", stepIndex, "manualProcesses"];
            const questionIndex = (
              form.getValue(attr) as Array<ManualProcess>
            )?.findIndex((process) => process.questionId === question.id);
            const isDisplay = (
              !question.parentQuestionId || // 分岐元質問
              !!(form.getValue(attr) as ManualProcess[])?.find(process => process.questionId === question.parentQuestionId && process.value === question.parentQuestionValue) // 分岐内質問かつ分岐を選択されている
            );

            return questionIndex < 0 ? (
              <></> // process内のquestionIdとquestionのidが一致しない時は表示しない
            ) : (
              <ManualQuestionFormView
                key={questionIndex}
                form={form}
                validationResultForm={validationResultForm}
                stepIndex={stepIndex}
                question={question}
                questionIndex={questionIndex}
                isDisplay={isDisplay}
                isEdit={isEdit}
              />
            );
          })}
      <CustomTextAreaField
        {...fieldProps}
        validationResultForm={validationResultForm}
        attr={["manualDetails", stepIndex, "comment"]}
        label="補足説明"
      />
      <>
        <LabelView label="画像(3枚まで)" />
        {isEdit ? (
          // 編集
          [1, 2, 3].map((index) => {
            const pictureKey = `picture${index}`;
            const picture = form.getValue([
              "manualDetails",
              stepIndex,
              pictureKey,
            ]) as string;
            return (
              <Space
                key={pictureKey}
                style={{
                  flexDirection: "column-reverse",
                  alignItems: "flex-start",
                }}
              >
                <Space>
                  <CustomFileField
                    {...fieldProps}
                    accept={["jpeg", "png"]}
                    attr={["manualDetails", stepIndex, pictureKey]}
                    fieldProps={{
                      ...fieldProps,
                      itemRender: (originNode) => {
                        return React.cloneElement(originNode, {
                          href: undefined,
                        });
                      },
                      disabled: !!picture,
                      fileList: picture
                        ? [
                          {
                            uid: index.toString(),
                            name: uploadedFiles[index - 1]?.name ?? picture,
                            status: "done",
                            url: `${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${picture}`,
                          },
                        ]
                        : undefined,
                      onRemove: () => false,
                      showUploadList: {
                        showRemoveIcon: true,
                        removeIcon: (file) => (
                          <Button
                            style={{
                              background: "#FFEFEE",
                              height: 27,
                              fontSize: 12,
                              fontWeight: "bold",
                            }}
                            danger
                            onClick={() => {
                              const fileIndex = uploadedFiles.findIndex(
                                (f) => f.path === file.url
                              );
                              form.updateObject(
                                ["manualDetails", stepIndex, `picture${index}`],
                                null
                              );
                              setUploadedFiles((prev) =>
                                prev.map((item, index) =>
                                  index === fileIndex
                                    ? { name: "", path: "" }
                                    : item
                                )
                              );
                            }}
                          >
                            削除
                          </Button>
                        ),
                      },
                    }}
                    onChange={async (e) => {
                      if (
                        e.file.status !== "removed" &&
                        e.fileList
                          .map((file) => file.name)
                          .includes(e.file.name)
                      ) {
                        globalState.setLoading(true);
                        const result = await uploadApi.execute(
                          e.file as UploadFile<any>
                        );
                        storeFileName(
                          {
                            name: e.file.name,
                            path: result.data.filename ?? "",
                          },
                          index - 1
                        );
                        globalState.setLoading(false);
                      }
                    }}
                  />
                </Space>
                <Space style={{ flexDirection: "column-reverse" }}>
                  {!!picture && (
                    <div
                      key={picture}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Image
                        preview={false}
                        src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${picture}`}
                      />
                      <ArrowsAltOutlined
                        width={29}
                        height={29}
                        style={{
                          color: "white",
                          background: "rgba(0,0,0,0.7)",
                          padding: 5,
                          borderRadius: "100%",
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                        }}
                        onClick={() => togglePreview(index)}
                      />
                      <Modal
                        open={isPreviewVisible[index]}
                        footer={
                          <Button
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              width: "100%",
                            }}
                            onClick={() => togglePreview(index)}
                          >
                            閉じる
                          </Button>
                        }
                        closable={false}
                        onCancel={() => togglePreview(index)}
                      >
                        <img
                          alt="Preview"
                          style={{ width: "100%" }}
                          src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${picture}`}
                        />
                      </Modal>
                    </div>
                  )}
                </Space>
              </Space>
            );
          })
        ) : (
          // 作成
          <Space
            style={{
              flexDirection: "column-reverse",
              alignItems: "flex-start",
            }}
          >
            <Space>
              <CustomFileField
                {...fieldProps}
                accept={["jpeg", "png"]}
                fieldProps={{
                  ...fieldProps,
                  itemRender: (originNode) => {
                    return React.cloneElement(originNode, { href: undefined });
                  },
                  disabled:
                    uploadedFiles.filter((file) => !!file.name).length >= 3,
                  maxCount: 3,
                  multiple: true,
                  fileList:
                    uploadedFiles.length > 0
                      ? uploadedFiles.map((file, index) => {
                        return {
                          uid: index.toString(),
                          name: file.name,
                          status: "done",
                          url: file.path,
                        } as UploadFile;
                      })
                      : undefined,
                  showUploadList: {
                    showRemoveIcon: true,
                    removeIcon: (file) => (
                      <Button
                        style={{
                          background: "#FFEFEE",
                          height: 27,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                        danger
                        onClick={() => {
                          [1, 2, 3].some((index) => {
                            if (
                              form.getValue([
                                "manualDetails",
                                stepIndex,
                                `picture${index}`,
                              ]) === file.url
                            ) {
                              form.updateObject(
                                ["manualDetails", stepIndex, `picture${index}`],
                                null
                              );
                              return true;
                            }
                          });
                          setUploadedFiles((prev) =>
                            prev.filter((f) => f.path !== file.url)
                          );
                        }}
                      >
                        削除
                      </Button>
                    ),
                  },
                }}
                onChange={async (e) => {
                  if (
                    e.file.status !== "removed" &&
                    e.fileList.map((file) => file.name).includes(e.file.name)
                  ) {
                    globalState.setLoading(true);
                    uploadCount.current += 1;
                    const result = await uploadApi.execute(
                      e.file as UploadFile<any>
                    );
                    storeFileName({
                      name: e.file.name,
                      path: result.data.filename ?? "",
                    });
                  }
                }}
                attr={["manualDetails", stepIndex, "picture"]}
              />
            </Space>
            <Space style={{ flexDirection: "column-reverse" }}>
              {[1, 2, 3].map((index) => {
                if (
                  form.getValue(["manualDetails", stepIndex, `picture${index}`])
                ) {
                  if (index === uploadCount.current) {
                    uploadCount.current = 0;
                    globalState.setLoading(false);
                  }
                  return (
                    <div
                      key={`picture${index}`}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Image
                        preview={false}
                        src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL
                          }${form.getValue([
                            "manualDetails",
                            stepIndex,
                            `picture${index}`,
                          ])}`}
                      />
                      <ArrowsAltOutlined
                        width={29}
                        height={29}
                        style={{
                          color: "white",
                          background: "rgba(0,0,0,0.7)",
                          padding: 5,
                          borderRadius: "100%",
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                        }}
                        onClick={() => togglePreview(index)}
                      />
                      <Modal
                        open={isPreviewVisible[index]}
                        footer={
                          <Button
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              width: "100%",
                            }}
                            onClick={() => togglePreview(index)}
                          >
                            閉じる
                          </Button>
                        }
                        closable={false}
                        onCancel={() => togglePreview(index)}
                      >
                        <img
                          alt="Preview"
                          style={{ width: "100%" }}
                          src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL
                            }${form.getValue([
                              "manualDetails",
                              stepIndex,
                              `picture${index}`,
                            ])}`}
                        />
                      </Modal>
                    </div>
                  );
                }
              })}
            </Space>
          </Space>
        )}
      </>
    </Space>
  );
};

const ManualQuestionFormView = ({
  form,
  validationResultForm,
  stepIndex,
  question,
  questionIndex,
  isDisplay,
  isEdit,
}: {
  form: Form<Manual>;
  validationResultForm: Form<Manual>;
  stepIndex: number;
  question: Question;
  questionIndex: number;
  isDisplay: boolean;
  isEdit?: boolean;
}) => {
  const attr = [
    "manualDetails",
    stepIndex,
    "manualProcesses",
    questionIndex,
    "value",
  ];

  useEffectSkipFirst(() => {
    // 親questionの回答が変わる時に、分岐質問を初期化
    if (!isDisplay) {
      form.updateObject(attr, undefined);
    }
  }, [isDisplay])

  return (isDisplay ?
    <BaseQuestionFormView
      form={form}
      validationResultForm={validationResultForm}
      stepIndex={stepIndex}
      question={question}
      questionIndex={questionIndex}
      onChange={(e) => {
        if (question.type === 1) {
          form.update((f) => {
            const process = (
              ((f.manualDetails as ManualDetail[])[stepIndex] as ManualDetail)
                .manualProcesses as ManualProcess[]
            )[questionIndex] as ManualProcess;
            // todo:分岐元選択時の分岐質問の回答初期化
            // const branchedProcess = (
            //   ((f.manualDetails as ManualDetail[])[stepIndex] as ManualDetail)
            //     .manualProcesses as ManualProcess[]
            // ).find(p => p.parentValue === process.value);
            // if (branchedProcess) {
            //   branchedProcess.value = undefined;
            // }
            process.value = (e as RadioChangeEvent).target.value;
            if (isEdit) {
              (
                (
                  (
                    (f.manualDetails as ManualDetail[])[
                    stepIndex
                    ] as ManualDetail
                  ).manualProcesses as ManualProcess[]
                )[questionIndex] as ManualProcess
              ).flag = "u";
            }
          });
        } else if (question.type === 2) {
          form.update((f) => {
            const process = (
              ((f.manualDetails as ManualDetail[])[stepIndex] as ManualDetail)
                .manualProcesses as ManualProcess[]
            )[questionIndex] as ManualProcess;
            process.value = (e as CheckboxValueType[]).join(",");
            if (isEdit) {
              (
                (
                  (
                    (f.manualDetails as ManualDetail[])[
                    stepIndex
                    ] as ManualDetail
                  ).manualProcesses as ManualProcess[]
                )[questionIndex] as ManualProcess
              ).flag = "u";
            }
          });
        }
      }}
      fieldProps={{
        // @ts-ignore
        value:
          question.type === 1
            ? form.getValue(attr)
            : ((form.getValue(attr) as string) ?? "").split(","),
      }}
    /> :
    <></>
  );
};

export const BaseQuestionFormView = ({
  form,
  validationResultForm,
  stepIndex,
  question,
  questionIndex,
  onChange,
  fieldProps,
  isPreview,
}: {
  form: Form<Manual>;
  validationResultForm: Form<Manual>;
  stepIndex: number;
  question: Question;
  questionIndex: number;
  onChange?:
  | (((e: RadioChangeEvent) => void) & ((e: CheckboxValueType[]) => void))
  | undefined;
  fieldProps?: OptionProps;
  isPreview?: boolean;
}) => {
  const Field =
    question.type === 1 ? CustomSelectRadioField : CustomCheckboxGroupField;
  const [questionHeader, ...questionOptions] = question.questionOptions ?? [];
  const numericOption = questionOptions.find(
    (option) => option.allowsNumericValue
  );
  const freeTextOption = questionOptions.find(
    (option) => option.allowsFreeText
  );
  const isMobile = useMedia("(max-width: 519px)");

  const required = questionHeader.contents?.includes("必須");

  const preAttr = [
    "manualDetails",
    stepIndex,
    "manualProcesses",
    questionIndex,
  ];

  const { token } = theme.useToken();

  const allowsNumericValue = () => {
    return (
      numericOption?.id &&
      (form.getValue([...preAttr, "value"]) as string)?.includes(
        numericOption.id
      )
    );
  };
  const allowsFreeText = () => {
    return (
      freeTextOption?.id &&
      (form.getValue([...preAttr, "value"]) as string)?.includes(
        freeTextOption.id
      )
    );
  };

  const updateNumeric = (val: number | null, unit: string | null) => {
    form.updateObject([...preAttr, "numericValue"], val);
    form.updateObject([...preAttr, "numericUnit"], unit);
  };
  const updateFreeText = (text: string | null) => {
    form.updateObject([...preAttr, "freeText"], text);
  };
  return (
    <>
      <Field
        validationResultForm={validationResultForm}
        key={`question ${questionIndex}`}
        required={required}
        form={form}
        label={
          questionHeader.contents
            ? questionHeader.contents
              ?.replace("【必須】", "")
              .replace("<任意>", "")
              .split("　")[0]
            : "名称未設定の質問"
        }
        labelStyle={{
          fontWeight: 600,
          color: questionHeader.contents ? "inherit" : token.colorError,
          fontSize: "16px",
          lineHeight: "24px",
        }}
        style={{
          marginTop: 4,
          width: "100%",
        }}
        attr={[...preAttr, "value"]}
        selectItems={questionOptions
          .sort((a, b) => {
            // allowsFreeTextがtrueの場合、それらを最後に移動させる
            if (a.allowsFreeText && !b.allowsFreeText) return 1;
            if (!a.allowsFreeText && b.allowsFreeText) return -1;

            // allowsNumericValueがtrueの場合、それらをallowsFreeTextより前、その他の選択肢より後ろに移動させる
            if (!a.allowsFreeText && !b.allowsFreeText) {
              if (a.allowsNumericValue && !b.allowsNumericValue) return 1;
              if (!a.allowsNumericValue && b.allowsNumericValue) return -1;
            }

            // 上記の条件に該当しない場合、番号でソート
            return (a.no ?? 0) > (b.no ?? 0) ? 1 : -1;
          })
          .map((qo) => ({
            label: qo.contents ?? (
              <div style={{ color: "red", fontWeight: 700 }}>
                名称未設定の選択肢
              </div>
            ),
            value: qo.id,
          }))}
        onChange={(e: RadioChangeEvent | CheckboxValueType[]) => {
          if (Array.isArray(e)) {
            // e が CheckboxValueType[] 型の場合の処理
            onChange && onChange(e);
            if (isPreview) {
              return;
            }
            // チェックが外れている時、自由入力、数値入力を初期化
            if (
              numericOption?.id &&
              !(e as CheckboxValueType[]).join(",").includes(numericOption.id)
            ) {
              updateNumeric(null, null);
            }
            if (
              freeTextOption?.id &&
              !(e as CheckboxValueType[]).join(",").includes(freeTextOption.id)
            ) {
              updateFreeText(null);
            }
          } else {
            // e が RadioChangeEvent 型の場合の処理
            onChange && onChange(e);
            if (isPreview) {
              return;
            }
            // チェックが外れている時、自由入力、数値入力を初期化
            if (e.target.value !== numericOption?.id) {
              updateNumeric(null, null);
            }
            if (e.target.value !== freeTextOption?.id) {
              updateFreeText(null);
            }
          }
        }}
        // @ts-ignore
        fieldProps={fieldProps}
        column={isMobile ? 1 : 2}
        isManual
        deselectable
      />
      {allowsNumericValue() && (
        // 数値＋単位
        <CustomInputNumberField
          form={form}
          validationResultForm={validationResultForm}
          style={{
            margin: "4px 0",
            width: "100%",
          }}
          fieldProps={{
            size: "large",
            placeholder: numericOption?.contents,
            addonAfter: numericOption?.value,
            min: 1,
            step: 1,
          }}
          attr={[...preAttr, "numericValue"]}
          onChange={(e) => {
            updateNumeric(e, numericOption?.value ?? null);
          }}
        />
      )}
      {allowsFreeText() && (
        // その他
        <CustomInputField
          form={form}
          validationResultForm={validationResultForm}
          style={{
            margin: "4px 0",
            height: "auto",
          }}
          fieldProps={{
            size: "large",
          }}
          attr={[...preAttr, "freeText"]}
        />
      )}
    </>
  );
};
