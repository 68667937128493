import { BaseEntity, BaseSearchForm, ID } from ".";

import { User } from "./user";

export const VehicleTypes = [
  "平ボディ",
  "ウイング",
  "バン",
  "ダンプ",
  "タンクローリー",
  "産廃エコ",
  "ローリー",
  "トレーラー",
  "積載"
] as const;

export enum VehicleType {
  "平ボディ" = 0,
  "ウイング" = 1,
  "バン" = 2,
  "ダンプ" = 3,
  "タンクローリー" = 4,
  "産廃エコ" = 5,
  "ローリー" = 6,
  "トレーラー" = 7,
  "積載" = 8
}

export const VehicleLicenceTypes = [
  "普通免許",
  "準中型免許",
  "中型免許",
  "大型免許",
] as const;

export type Vehicle = BaseEntity & {
  vehicleCode?: string; // 管理番号
  division?: string; // 営業所
  licenseType?: number; // 免許区分
  carrierType?: number;
  vehicleNo?: string; // 車両No
  weight?: number; // 車格
  vehicleType?: VehicleType; // 車両種類
  maxWeight?: number; // 最大積載量
  memo?: string; // 備考
  displayOrder?: number; // 配車画面並び順
  userId?: ID; // 担当ユーザーID
  user?: User;
};

export const isVehicle = (item: any): item is Vehicle => (item as Vehicle).user !== undefined;

export type VehicleForm = Vehicle;

export type VehicleSearchForm = BaseSearchForm & Vehicle;
