import { Skeleton, theme } from "antd";
import { useDeleteRouteApi, useFetchRoutesApi } from "api/route";
import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst } from "utils/hooks";
import { RoutesTable } from "./routes_table";
import { GlobalStateContext } from "contexts/global_state_context";
import { ID } from "entities";

const RoutesPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchRoutesApi();
  const deleteApi = useDeleteRouteApi();

  useEffect(() => {
    listApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="ルート"
      extra={[
        <CustomButton
          key="CreateRoute"
          onClick={() => props.history.push("/operations/routes/create")}
          type="primary"
        >
          ルート登録
        </CustomButton>,
      ]}
    >
      <Skeleton loading={listApi.loading}>
        <RoutesTable
          history={props.history}
          handleDelete={(id: ID) => deleteApi.execute(id)}
          dataSource={listApi.response.data}
          listApi={listApi}
          pagination={{
            current: listApi.response.meta?.currentPage,
            pageSize: listApi.response.meta?.perPage,
            total: listApi.response.meta?.total,
          }}
        /></Skeleton>
    </CustomPageHeader>
  );
};

export default withRouter(RoutesPage);