import React, { ReactNode, CSSProperties, ForwardedRef, useState } from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableItem, Item } from "./sortable_item";

import AddRotationButton from "./add_rotation";
import { Button, Checkbox, Flex, Input, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ItemData } from "../deliveries_kanban";
import { OrderForm, OrderStatus } from "entities/order";
import { AssignedPartnerCompanyData, AssignedPartnerCompanyDataEnum } from "entities/delivery_partner_company";
import { CookieManager } from "utils/cookie_manager";

interface ContainerProps {
  children: ReactNode;
  driver?: boolean;
  style?: CSSProperties;
  driverTitle?: any;
}

const Container = React.forwardRef(
  (props: ContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, driver, style = {} } = props;

    const containerStyle: CSSProperties = {
      background: "#dadada",
      padding: 10,
      flex: 1,
      borderRadius: 8,
      display: "flex",
      justifyContent: "right",
      gap: 10,
      alignSelf: "stretch",
      minHeight: 50,
      flexDirection: driver ? "row" : "column",
    };

    return (
      <div style={{ backgroundColor: "#F9FAFB", borderRadius: 8 }}>
        {props.driverTitle && <props.driverTitle />}
        <div
          ref={ref}
          style={{
            ...containerStyle,
            ...style,
          }}
        >
          {children}
        </div>
      </div>
    );
  }
);

Container.displayName = "Container";

export { Container };

export type driverOption = {
  value?: string;
  label?: string;
};

interface SortableContainerProps {
  getItems: (id: string) => ItemData[];
  id: string;
  driver?: boolean;
  driverUnassigned?: boolean;
  isChartered?: boolean;
  driverTitle?: any;
  driverOptions?: driverOption[];
  style?: CSSProperties;
  handlePosition: string;
  maxWeight?: number;
  addRotaion?: () => () => void;
  index: number;
  parent?: string;
  isChecked?: boolean;
  isAssigned?: boolean;
  onUpdateOrderDivideds: (id: string, value: any) => void;
  onUpDateOrderDetails: (id: string, value: any) => void;
  onSave: (order: OrderForm) => void;
  onSelected?: (rotationId: string, driverId: string) => void;
  onDeleteRotation: (rotationId: string) => void;
  onCheck?: (rotationId: string, isChecked: boolean) => void;
  onDeleteDivided: (dividedId: string) => void;
  onDeleteOrder: (id: string) => void;
}

export const SortableContainer: React.FC<SortableContainerProps> = ({
  getItems,
  id,
  driver,
  driverUnassigned,
  isChartered,
  addRotaion,
  index,
  parent,
  isChecked,
  isAssigned,
  driverTitle,
  driverOptions,
  onUpdateOrderDivideds,
  onUpDateOrderDetails,
  onSave,
  onSelected,
  onDeleteRotation,
  onCheck,
  onDeleteDivided,
  onDeleteOrder,
  maxWeight,
}) => {
  const [filter, setFilter] = useState("");
  const items = getItems(id);
  const itemIds = items.map((item) => item.id);
  const style = {
    backgroundColor: driver ? "#F9FAFB" : "#E5E7EB",
    minWidth: driver ? "210px" : "0",
    width: driver ? "auto" : "200px",
  };

  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  if (isOver) {
    console.log("is over", id);
  }

  const onSelect = (driverId: string) => {
    if (onSelected) {
      onSelected(id, driverId);
    }
  };

  const assignedWeight = (id: string) => {
    const weight = Number(
      getItems(id).reduce((total, item) => {
        return total + item.assignedWeight!;
      }, 0)
    );
    return weight ? Number(weight.toFixed(1)) : 0;
  };

  return driver && driverUnassigned ? (
    // 未割当レーン
    <SortableItem
      id={id}
      handlePosition="top"
      driver={driver}
      driverUnassigned={driverUnassigned}
    >
      <Container
        ref={setNodeRef}
        driver={driver}
        style={style}
        driverTitle={driverTitle}
      >
        <SortableContext
          items={itemIds}
          strategy={
            driver ? verticalListSortingStrategy : horizontalListSortingStrategy
          }
        >
          {/* アサイン前のカードの溜まり */}
          <div
            style={{
              maxHeight: "calc(100vh - 412px)",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Input
              style={{ fontSize: 12 }}
              placeholder="受注番号or行先名称で検索"
              onChange={(e) => setFilter(e.target.value)}
            />
            {items
              .filter((item) => item.order?.status !== OrderStatus.Assigned)
              .filter((item) => !filter || item.order?.orderNo?.includes(filter) || item.order?.orderDetails?.some(detail => detail.deliveryCompany?.name?.includes(filter)))
              .map((item) => {
                const child = (
                  <Item
                    item={item}
                    getItems={getItems}
                    driverOptions={driverOptions}
                    onDeleteDivided={onDeleteDivided}
                    onDeleteOrder={onDeleteOrder}
                    onUpdateOrderDivideds={onUpdateOrderDivideds}
                    onUpDateOrderDetails={onUpDateOrderDetails}
                    onSave={onSave}
                  />
                );
                if (item.container) {
                  return;
                } else {
                  return (
                    <SortableItem
                      key={item.id}
                      id={item.id}
                      isCharteredItem={item.parent === "chartered"}
                    >
                      {child}
                    </SortableItem>
                  );
                }
              })}
          </div>
          {/* 未割当内の回転 */}
          {items
            .filter((item) => item.container)
            .map((item, index) => {
              return (
                <SortableContainer
                  key={item.id}
                  id={item.id}
                  index={index}
                  parent={item.parent}
                  getItems={getItems}
                  driver={item.driver}
                  driverOptions={driverOptions}
                  handlePosition="top"
                  maxWeight={maxWeight}
                  onUpdateOrderDivideds={onUpdateOrderDivideds}
                  onUpDateOrderDetails={onUpDateOrderDetails}
                  onSave={onSave}
                  onSelected={onSelected}
                  onDeleteRotation={onDeleteRotation}
                  onDeleteDivided={onDeleteDivided}
                  onDeleteOrder={onDeleteOrder}
                />
              );
            })}
        </SortableContext>
        {driver && addRotaion && id !== "chartered" && (
          <AddRotationButton onClick={addRotaion} />
        )}
      </Container>
    </SortableItem>
  ) : (
    // ドライバー、回転
    <SortableItem
      id={id}
      handlePosition="top"
      driver={driver}
      driverUnassigned={driverUnassigned}
      isCharteredItem={isChartered}
    >
      <Container
        ref={setNodeRef}
        driver={driver}
        style={style}
        driverTitle={driverTitle}
      >
        {/* 回転のヘッダー */}
        {!driver && (
          <div style={{ position: "relative" }}>
            {
              <Button
                onClick={() => onDeleteRotation(id)}
                style={{
                  position: "absolute",
                  border: 0,
                  top: 0,
                  right: 0,
                  background: "transparent",
                  cursor: "pointer",
                }}
                icon={
                  <CloseOutlined style={{ fontSize: 14, color: "#919eab" }} />
                }
              ></Button>
            }
            <p
              style={{
                fontSize: 16,
                margin: 0,
              }}
            >
              回転{index + 1}
            </p>
            <Flex vertical>
              {!isChartered && <>
                <Select
                  value={parent !== "undefined" ? parent : "未割当"}
                  style={{ width: 180, margin: "7px 0" }}
                  options={driverOptions}
                  onSelect={onSelect}
                />
                <table
                  style={{
                    color: "#6B7280",
                    width: "100%",
                    fontSize: 13,
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          verticalAlign: "top",
                          textAlign: "left",
                        }}
                      >
                        最大積載量
                      </td>
                      <td
                        style={{
                          verticalAlign: "top",
                          whiteSpace: "nowrap",
                        }}
                      >
                        :
                      </td>
                      <td>{maxWeight}t</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          verticalAlign: "top",
                          textAlign: "left",
                        }}
                      >
                        現在の総積載量
                      </td>
                      <td
                        style={{
                          verticalAlign: "top",
                          whiteSpace: "nowrap",
                        }}
                      >
                        :
                      </td>
                      <td>
                        {assignedWeight(id)}kg(
                        {maxWeight
                          ? Math.round(
                            (assignedWeight(id) / (maxWeight! * 1000)) * 100
                          )
                          : 0}
                        %)
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
              }
            </Flex>
            {parent !== "undefined" && (
              <Checkbox
                checked={isAssigned || isChecked}
                disabled={isAssigned}
                onChange={(e) => onCheck!(id, e.target.checked)}
                style={{
                  color: "black",
                  fontSize: 13,
                }}
              >
                {isAssigned ? "運行指示済み" : "運行指示"}
              </Checkbox>
            )}
          </div>
        )}
        {/* ドライバー、回転 */}
        <SortableContext
          items={itemIds}
          strategy={
            driver ? verticalListSortingStrategy : horizontalListSortingStrategy
          }
        >
          <div
            style={{
              maxHeight: driver ? "calc(100vh - 438px)" : "auto",
              overflowY: driver ? "auto" : "hidden",
              display: "flex",
              flexDirection: driver ? "row" : "column",
              gap: 10,
            }}
          >
            {items.map((item, index) => {
              const child = (
                <Item
                  item={item}
                  getItems={getItems}
                  driverOptions={driverOptions}
                  onDeleteDivided={onDeleteDivided}
                  onDeleteOrder={onDeleteOrder}
                  onUpdateOrderDivideds={onUpdateOrderDivideds}
                  onUpDateOrderDetails={onUpDateOrderDetails}
                  onSave={onSave}
                />
              );

              if (item.container) {
                // 回転
                return (
                  <SortableContainer
                    key={item.id}
                    id={item.id}
                    index={index}
                    parent={item.parent}
                    isChartered={isChartered}
                    isChecked={item.isChecked}
                    isAssigned={item.isAssigned}
                    getItems={getItems}
                    driver={item.driver}
                    driverOptions={driverOptions}
                    handlePosition="top"
                    maxWeight={maxWeight}
                    onUpdateOrderDivideds={onUpdateOrderDivideds}
                    onUpDateOrderDetails={onUpDateOrderDetails}
                    onSave={onSave}
                    onSelected={onSelected}
                    onDeleteRotation={onDeleteRotation}
                    onDeleteDivided={onDeleteDivided}
                    onDeleteOrder={onDeleteOrder}
                    onCheck={onCheck}
                  />
                );
              } else {
                // 配車カード
                return (
                  <SortableItem key={item.id} id={item.id}>
                    {child}
                  </SortableItem>
                );
              }
            })}
          </div>
        </SortableContext>
        {driver && addRotaion && <AddRotationButton onClick={addRotaion} />}
      </Container>
    </SortableItem>
  );
};
