import { ModalProps } from "antd";
import { usePostDeliveryCompaniesApi } from "api/delivery_company";
import {
  CsvImportModal,
  EntityColumn,
} from "components/shared/csv_import_modal";
import {
  DeliveryCompany,
  DeliveryCompanyTypes,
} from "entities/delivery_company";
import { DeliveryCompanyTypeTagView } from "./delivery_companies_view";
import { hankakuKatakanaToHiragana } from "utils/util";
import { useContext, useEffect } from "react";
import { GlobalStateContext } from "contexts/global_state_context";

export const DeliverySuperAdminCompanyCsvImportModal = ({
  ...modalProps
}: ModalProps) => {
  const bulkCreateDeliveryCompanyApi = usePostDeliveryCompaniesApi();

  const entityColumns: EntityColumn<DeliveryCompany>[] = [
    {
      key: "type",
      title: "積み地/降し地",
      dataIndex: "type",
      width: 200,
      render: (item: unknown) =>
        DeliveryCompanyTypeTagView({ type: item as DeliveryCompany["type"] }),
    },
    {
      key: "name",
      title: "行先名称",
      dataIndex: "name",
      required: true,
      width: 200,
    },
    {
      key: "kana",
      title: "ふりがな",
      dataIndex: "kana",
      width: 200,
    },
    { key: "zipcode", title: "郵便番号", dataIndex: "zipcode", width: 200 },
    {
      key: "address",
      title: "住所",
      dataIndex: "address",
      required: true,
      width: 200,
    },
    {
      key: "deliveryPartnerCompanyName",
      title: "担当運送会社",
      dataIndex: "deliveryPartnerCompanyName",
      width: 200,
    },
    {
      key: "maxWeight",
      title: "受入可能車両(t)",
      dataIndex: "maxWeight",
      width: 200,
    },
    {
      key: "needTask",
      title: "必要作業",
      dataIndex: "needTask",
      width: 200,
    },
    {
      key: "deliveryTerm",
      title: "荷渡し条件",
      dataIndex: "deliveryTerm",
      width: 200,
    },
    {
      key: "repName",
      title: "担当者氏名",
      dataIndex: "repName",
      width: 200,
    },
    {
      key: "repTel",
      title: "担当者電話番号",
      dataIndex: "repTel",
      width: 200,
    },
    {
      key: "memo",
      title: "備考",
      dataIndex: "memo",
      width: 200,
    },
  ];

  const csvDataToEntities = (
    csvData: Record<string, any>[],
    valueOf: (csvRow: Record<string, any>, csvColumnName: string) => any
  ): DeliveryCompany[] => {
    return csvData.map((csvRow) => {
      const entity: DeliveryCompany = {};
      entityColumns.forEach((column) => {
        let value = valueOf(csvRow, column.title);
        if (column.dataIndex === "kana" && value) {
          // kanaが半角カタカナでも対処可能に
          value = hankakuKatakanaToHiragana(value).replace(
            /[\u0020\u3000]/g,
            ""
          );
        } else if (column.dataIndex === "type" && value) {
          // 種別を1, 2, 3に変換
          const deliveryCompanyTypesKeys = Object.keys(DeliveryCompanyTypes);
          const deliveryCompanyTypesLabels = deliveryCompanyTypesKeys.map(
            (key) => DeliveryCompanyTypes[Number(key) as 1 | 2 | 3]
          );

          if (deliveryCompanyTypesLabels.includes(value)) {
            value = deliveryCompanyTypesKeys.find(
              (key) => DeliveryCompanyTypes[Number(key) as 1 | 2 | 3] === value
            );
            value = Number(value);
          }
        } else if (column.dataIndex === "zipcode" && value) {
          const postalCodePattern = /^(\d{3})(\d{4})$/;
          if (postalCodePattern.test(value)) {
            value = value.replace(postalCodePattern, "$1-$2");
          }
        }
        entity[column.dataIndex as keyof DeliveryCompany] = value;
      });
      return entity;
    });
  };

  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    globalState.setLoading(bulkCreateDeliveryCompanyApi.loading);
  }, [bulkCreateDeliveryCompanyApi.loading]);

  return (
    <CsvImportModal
      bulkApiResult={{
        data: bulkCreateDeliveryCompanyApi.response,
        error: bulkCreateDeliveryCompanyApi.apiError as any,
      }}
      csvDataToEntities={csvDataToEntities}
      entityColumns={entityColumns}
      entityNameJp="行先"
      handleBulkSendValidatedEntities={async (data) => {
        await bulkCreateDeliveryCompanyApi.execute(data);
      }}
      {...modalProps}
    />
  );
};
