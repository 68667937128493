import { Button, Space, Table, TableProps, theme } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { CompaniesResponse } from "api/company";

import { ID } from "entities";
import { Company, CompanyManualType, CompanySearchForm } from "entities/company";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import Highlighter from "react-highlight-words";
import { GlobalStateContext } from "contexts/global_state_context";
import { IndexApiSet } from "utils/network/api_hooks";
import dayjs from "dayjs";
import { useContext } from "react";
import { Template } from "entities/template";

type CompaniesTableProps = TableProps<Company>;

export const CompaniesTable = (
  props: CompaniesTableProps &
    HistoryProps & {
      onDelete: (id: ID) => void;
      listApi?: IndexApiSet<CompaniesResponse> & {
        execute: (query?: string) => void;
      };
      onDeepCopy?: (id: string) => void;
    }
) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();

  const table = useTable<Company>(props);

  const columns: ColumnsType<Company> = [
    {
      ...table.baseColumn("name"),
      ...table.getColumnSearchProps("name", "客先名称"),
      width: 150,
      title: "客先名称",
      render: (item: Company) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[table.searchText]}
          autoEscape
          textToHighlight={item.name ?? ""}
        />
      ),
    },
    {
      ...table.baseColumn("address"),
      ...table.getColumnSearchProps("address", "住所"),
      width: 150,
      title: "住所",
      render: (item: Company) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[table.searchText]}
          autoEscape
          textToHighlight={item.address ?? ""}
        />
      ),
    },
    {
      ...table.baseColumn("manualType"),
      width: 100,
      title: "カルテ作成タイプ",
      render: (item: Company) => CompanyManualType[item.manualType as CompanyManualType],
      filters: Object.entries(CompanyManualType)
        .filter(([text, value]) => typeof value === 'number')
        .map(([text, value]) => ({
          text,
          value
        })),
      onFilter: (value: string | number | boolean, record: Company) =>
        record.manualType === value,
    },
    {
      ...table.baseColumn("defaultTemplateName"),
      ...table.getColumnSearchProps("defaultTemplateName", "メインカルテ"),
      width: 100,
      title: "メインカルテ",
      render: (item: Company) => item.defaultTemplateName,
    },
    {
      ...table.baseColumn("templates"),
      ...table.getColumnSearchProps("templates", "その他のカルテ"),
      width: 100,
      title: "その他のカルテ",
      render: (item: Company) => (
        <div>
          {item.templates?.map((template: Template, index: number) => (
            <div key={index}>{template?.templateName}</div>
          ))}
        </div>
      ),
    },
    {
      ...table.baseColumn("memo"),
      width: 200,
      title: "メモ",
      render: (item: Company) => item.memo,
    },
    {
      ...table.baseColumn("createdAt"),
      ...table.getColumnSearchProps("createdAt", "作成日"),
      width: 100,
      title: "作成日",
      render: (item) =>
        item.updatedAt ? dayjs(item.createdAt).format("YYYY/MM/DD") : "",
    },
    {
      ...table.baseColumn("updatedAt"),
      ...table.getColumnSearchProps("updatedAt", "最終更新日"),
      width: 100,
      title: "最終更新日",
      render: (item) =>
        item.updatedAt ? dayjs(item.updatedAt).format("YYYY/MM/DD") : "",
    },
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 120,
      render: (item: Company) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/super-admin/companies/${item.id}/edit`);
            }}
          >
            編集
          </CustomButton>
          {props.onDeepCopy && (
            <CustomButton
              style={{
                color: token.colorInfo,
                fontWeight: 500,
                padding: 0,
                height: "30px",
                textAlign: "left",
              }}
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                item.id && props.onDeepCopy && props.onDeepCopy(item.id);
              }}
              confirm
              popconfirmProps={{
                title: "コピーを作成します。よろしいですか？",
              }}
            >
              コピー
            </CustomButton>
          )}
        </Space>
      ),
    },
  ];
  return (
    <CustomTable
      tableKey="companies_table"
      table={table}
      pagination={table.paginationInfo}
      onChange={table.handleChange}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () => props.history.push(`/super-admin/companies/${data.id}`),
      })}
      scroll={{ x: "calc(100vw - 198px - 64px)" }}
      {...props}
    />
  );
};
