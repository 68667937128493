import dayjs from "dayjs";
import { MetricsForm } from "./metrics_config_form";
import { MetricsGroupBy } from "./metrics_config";
import { Space, Tag } from "antd";

export class MetricsFormViewModel {
  static DatetimeFormat = "YYYY-MM-DD";
  static fromToDateView = (metricsForm?: MetricsForm) => {
    if (!metricsForm?.from?.length || !metricsForm?.to?.length) {
      return <>Invalid Value</>;
    } else {
      return (
        <Space size={2}>
          <Tag style={{ margin: 0 }}>
            {dayjs(
              metricsForm?.from,
              MetricsFormViewModel.DatetimeFormat
            ).format("YYYY年MM月DD日")}
          </Tag>
          〜
          <Tag>
            {dayjs(metricsForm?.to, MetricsFormViewModel.DatetimeFormat).format(
              "YYYY年MM月DD日"
            )}
          </Tag>
        </Space>
      );
    }
  };
  static rangeView = (metricsForm?: MetricsForm) => {
    return metricsForm?.range ? (
      <Tag>{metricsForm?.range || 1}日ごと</Tag>
    ) : (
      <></>
    );
  };
  static groupingView = (metricsForm?: MetricsForm) => {
    switch (metricsForm?.groupBy) {
      case MetricsGroupBy.VEHICLE:
        return <Tag color="cyan">車両ごと</Tag>;
      case MetricsGroupBy.DELIVERY_COMPANY:
        return <Tag color="geekblue">行先ごと</Tag>;
      default:
        return <Tag>なし</Tag>;
    }
  };
  static groupsView = (metricsForm?: MetricsForm) => {
    return (
      <Space wrap size={0}>
        {metricsForm?.groups?.map((group) => (
          <Tag key={group.value}>{group.label}</Tag>
        ))}
      </Space>
    );
  };
}
