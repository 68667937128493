import { theme } from "antd";
import { ReactNode, useContext } from "react";

type DescriptionBlockProps = {
  label?: ReactNode;
  children?: ReactNode;
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  childrenEmpty?: ReactNode;
  style?: React.CSSProperties;
};

export const DescriptionBlock = (props: DescriptionBlockProps) => {
  const { token } = theme.useToken();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        gap: "4px",
        ...props.style,
      }}
    >
      <div
        style={{
          height: 24,
          fontSize: 16,
          fontWeight: 600,
          color: token.colorText,
          ...props.labelStyle,
        }}
      >
        {props.label}
      </div>
      <div
        style={{
          fontWeight: 500,
          fontSize: 16,
          color: "#111827",
          width: "100%",
          ...props.valueStyle,
        }}
      >
        {props.children ?? props.childrenEmpty ?? "--"}
      </div>
    </div>
  );
};
