import { Space, theme } from "antd";
import {
  useEditRouteApi,
  useFetchRouteApi,
  usePostRouteApi,
} from "api/route";
import { GlobalStateContext } from "contexts/global_state_context";

import { Route,} from "entities/route";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomContentFooter } from "specifics/footer";
import {
  CustomInputField,
  StringValidation,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { Form, useEffectSkipFirst, useForm, useQuery } from "utils/hooks";

const FormView = (form: Form<Route>, validationResultForm: Form<any>) => {
  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "code", true);
      StringValidation(f, form, "url");
    });
  }, [JSON.stringify(form.object)]);
  return (
    <Space style={{ width: "100%" }} size={16} direction="vertical">
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="ルートコード"
        attr="code"
        required
      />
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="運送条件合意書URL"
        attr="url"
      />
    </Space>
  );
};

const _RoutesCreatePage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const query = useQuery<{ isTemporary?: boolean }>();
  const globalState = useContext(GlobalStateContext);
  const form = useForm<Route>({});
  const validationResultForm = useForm<Record<string, string | undefined>>({});
  const postApi = usePostRouteApi();

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      if (query.isTemporary) {
        window.close();
      } else {
        props.history.goBack();
      }
    }
  }, [postApi.loading]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="ルート登録"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/operations/routes")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) => validationResultForm.object[key] === undefined
                )
              ) {
                postApi.execute(form);
              }
            }}
          >
            登録
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.goBack()}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

const _RoutesEditPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const consignorApi = useFetchRouteApi();
  const params = useParams<{ id: string }>();
  const form = useForm<Route>({});
  useEffect(() => {
    consignorApi.execute(params.id);
  }, []);
  const editApi = useEditRouteApi();
  const validationResultForm = useForm<Record<string, string | undefined>>({});

  useEffectSkipFirst(() => {
    globalState.setLoading(consignorApi.loading);
    if (consignorApi.isSuccess()) {
      form.set(consignorApi.response.data);
    }
  }, [consignorApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.history.push(`/operations/routes/${params.id}`);
    }
  }, [editApi.loading]);

  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="ルート編集"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/operations/routes")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) => validationResultForm.object[key] === undefined
                )
              ) {
                editApi.execute(form);
              }
            }}
          >
            保存
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.goBack()}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const RoutesEditPage = withRouter(_RoutesEditPage);

export const RoutesCreatePage = withRouter(_RoutesCreatePage);
