import { BaseEntity, BaseSearchForm, ID } from ".";
import { DeliveryPartnerVehicle } from "./delivery_partner_vehicle";

export type DeliveryPartnerCompany = BaseEntity & {
  name?: string; //名称
  kana?: string;
  zipcode?: string;
  address?: string;
  repName?: string;
  email?: string;
  tel?: string;
  area?: string;
  memo?: string; // ,区切りの都道府県番号
  nextFollowUpDate?: string;
  followUpPeriodMonths?: number;
  deliveryPartnerVehicles?: DeliveryPartnerVehicle[];
  vehicleIds?: ID[];
};

export type DeliveryPartnerCompanyForm = DeliveryPartnerCompany;

export type DeliveryPartnerCompanySearchForm = BaseSearchForm & DeliveryPartnerCompany;

export type AssignedPartnerCompanyData = {
  vehicleType?: string; // 傭車車種
  vehicleNumber?: string; // 傭車車番
  driverName?: string; // 傭車ドライバー名
  tel?: string; // 傭車携帯番号
}

export type AssignedPartnerCompanyDataForm = {
  [key: string]: AssignedPartnerCompanyData;
}

export enum AssignedPartnerCompanyDataEnum {
  車種 = "vehicleType",
  車番 = "vehicleNumber",
  ドライバー名 = "driverName",
  携帯番号 = "tel",
}
