import {
  Alert,
  Button,
  Col,
  Input,
  Popover,
  Result,
  ResultProps,
  Row,
  Space,
  Statistic,
  StepProps,
  Steps,
  StepsProps,
  Table,
  TableProps,
  Typography,
  Upload,
  message,
  theme,
} from "antd";
import {
  InboxOutlined,
  CheckOutlined,
  CloseOutlined,
  TableOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import csv from "csvtojson";
import _ from "lodash";

const { Dragger } = Upload;

import { Modal, ModalProps } from "antd";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RcFile } from "antd/lib/upload";
import { CustomButton } from "specifics/button";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomSelectField, RequiredLabel } from "specifics/input";
import { useTable } from "specifics/use_table";
import { CustomTable } from "specifics/table";
import { SelectField } from "components/shared/input";
import { BaseEntity } from "entities";
import { ColumnsType } from "antd/es/table";
import { ApiError, BulkPostResponse } from "utils/network/api_hooks";
import { convertToCSV, downloadCSV } from "utils/csv_export";
import dayjs from "dayjs";
import { error } from "console";

export type RelateCsvEntityColumnsForm = {
  index: number;
  key: string;
  entityColumnTitle: string;
  csvColumnTitle?: string;
};

export type EntityColumn<TEntity extends BaseEntity> = {
  key: string;
  title: string;
  dataIndex: string;
  required?: boolean;
  render?: (rawItem: BaseEntity) => ReactNode;
  width?: number;
};

const ENCODING_CANDIDATES = [
  "UTF-8",
  "Shift_JIS",
  "ISO-2022-JP",
  "EUC-JP",
  "EUC-KR",
  "Big5",
  "gb18030",
  "GBK",
] as const;

type EncodingCandidates = (typeof ENCODING_CANDIDATES)[number];

enum StepValue {
  UPLOAD = "UPLOAD",
  VIEW = "VIEW",
  SELECT_COLUMN = "SELECT_COLUMN",
  CONFIRM_BEFORE = "CONFIRM_BEFORE",
  RESULTS = "RESULTS",
}

const useCsvImportModalSteps = () => {
  const steps: { label: string; value: StepValue }[] = [
    { label: "CSVアップロード", value: StepValue.UPLOAD },
    { label: "列の割り当て", value: StepValue.SELECT_COLUMN },
    // { label: "ファイルの確認", value: StepValue.VIEW },
    { label: "送信前の確認", value: StepValue.CONFIRM_BEFORE },
    { label: "結果の確認", value: StepValue.RESULTS },
  ];

  const initialStep = StepValue.UPLOAD;
  const [currentStep, setCurrentStep] = useState<StepValue>(initialStep);
  const currentStepIndex = steps.map((item) => item.value).indexOf(currentStep);

  const initializeStep = () => {
    setCurrentStep(initialStep);
  };
  const isInitialStep = () => currentStepIndex === 0;
  const isFinalStep = () => currentStepIndex === steps.length - 1;
  const toNextStep = () => {
    if (isFinalStep()) {
      message.error("次のステップはありません");
    } else {
      setCurrentStep(steps[currentStepIndex + 1].value);
    }
  };
  const toPriviousStep = () => {
    if (isInitialStep()) {
      message.error("次のステップはありません");
    } else {
      setCurrentStep(steps[currentStepIndex - 1].value);
    }
  };

  const stepItems: StepProps[] = steps.map((step) => ({ title: step.label }));

  return {
    currentStep,
    setCurrentStep,
    currentStepIndex,
    stepItems,
    initializeStep,
    isInitialStep,
    isFinalStep,
    toNextStep,
    toPriviousStep,
  };
};

type BulkApiResult<T extends BaseEntity> = {
  data?: BulkPostResponse<T>;
  error?: {
    errors: Record<string, string[]>;
  };
};

export const CsvImportModal = <TEntity extends BaseEntity>({
  notReadyToUploadCsv,
  notReadyToUploadCsvMessage,
  entityNameJp, // 受注などのテーブル名
  extraForm,
  extraFormView,
  onCancel,
  entityColumns,
  csvDataToEntities,
  handleBulkSendValidatedEntities,
  bulkApiResult,
  ...props
}: ModalProps & {
  notReadyToUploadCsv?: boolean;
  notReadyToUploadCsvMessage?: string;
  extraForm?: JSX.Element;
  extraFormView?: JSX.Element;
  entityNameJp: string;
  entityColumns: EntityColumn<TEntity>[];
  csvDataToEntities: (
    csvData: any[],
    valueOf: (csvRow: Record<string, any>, csvColumnName: string) => any
  ) => TEntity[];
  handleBulkSendValidatedEntities: (data: TEntity[]) => void;
  bulkApiResult?: BulkApiResult<TEntity>;
}) => {
  const [csvData, setCsvData] = useState<any[]>([]);
  const [csvColumns, setCsvColumns] = useState<ColumnsType<any>>([]);
  useEffect(() => {
    if (csvColumns.length) {
      setCurrentStep(StepValue.SELECT_COLUMN);
    }
  }, [JSON.stringify(csvColumns)]);
  const [bulkApiResultState, setBulkApiResultState] = useState<
    BulkApiResult<TEntity>
  >({});

  useEffect(() => {
    setBulkApiResultState(bulkApiResult || {});
    setCurrentStep(StepValue.RESULTS);
  }, [JSON.stringify(bulkApiResult)]);

  const {
    currentStep,
    setCurrentStep,
    currentStepIndex,
    stepItems,
    isInitialStep,
    isFinalStep,
    initializeStep,
    toNextStep,
    toPriviousStep,
  } = useCsvImportModalSteps();

  useEffect(() => {
    if (isInitialStep()) {
      handleInitialize();
    }
  }, [isInitialStep()]);

  const entityColumnTitleRequired = (entityColumnTitle: string) =>
    !!entityColumns.find((column) => column.title === entityColumnTitle)
      ?.required;

  const selectEncodeForm = useForm<{ encode?: EncodingCandidates }>({});
  const relateCsvEntityColumnsForm = useForm<RelateCsvEntityColumnsForm[]>([]);

  const csvColumnToEntityColumn = (csvColumnName: string) => {
    return (
      relateCsvEntityColumnsForm.object.find(
        (col) => col.entityColumnTitle === csvColumnName
      )?.csvColumnTitle || ""
    );
  };

  const valueOf = (csvRow: Record<string, any>, csvColumnName: string) => {
    const entityColumn = csvColumnToEntityColumn(csvColumnName);
    if (Object.keys(csvRow).includes(entityColumn)) {
      return csvRow[entityColumn as keyof Record<string, any>] as any;
    } else return null;
  };

  useEffect(() => {
    const encode = localStorage.getItem("knewit-csv-encoding");
    if (encode) {
      selectEncodeForm.updateObject("encode", encode);
    } else {
      selectEncodeForm.updateObject("encode", "UTF-8");
    }
  }, []);

  useEffect(() => {
    if (selectEncodeForm.object.encode) {
      localStorage.setItem(
        "knewit-csv-encoding",
        selectEncodeForm.object.encode
      );
      handleInitialize();
    }
  }, [selectEncodeForm.object.encode]);

  const entitiesData: TEntity[] = useMemo(() => {
    return csvDataToEntities(csvData, valueOf);
  }, [csvData, JSON.stringify(relateCsvEntityColumnsForm.object)]);

  const handleInitialize = () => {
    setCsvData([]);
    setCsvColumns([]);
    setBulkApiResultState({});
    initializeStep();
  };

  return (
    <Modal
      title={`${entityNameJp}CSVファイルのアップロード`}
      style={{ top: 16 }}
      width={"100vw"}
      onCancel={(e) => {
        onCancel && onCancel(e);
        handleInitialize();
      }}
      footer={[
        <Button
          style={{
            display: currentStep === StepValue.RESULTS ? "none" : "unset",
          }}
          key={"cancel"}
          onClick={(e: any) => {
            if (isInitialStep() && onCancel) {
              onCancel(e);
            } else {
              toPriviousStep();
            }
          }}
        >
          {isInitialStep() ? "閉じる" : "戻る"}
        </Button>,
        <Button
          onClick={async (e: any) => {
            if (isFinalStep()) {
              onCancel && onCancel(e);
              handleInitialize();
            } else if (currentStep === StepValue.CONFIRM_BEFORE) {
              await handleBulkSendValidatedEntities(entitiesData);
            } else {
              toNextStep();
            }
          }}
          key={"ok"}
          type={isFinalStep() ? "default" : "primary"}
          disabled={
            (isInitialStep() && csvData.length === 0) ||
            (currentStep === StepValue.SELECT_COLUMN &&
              relateCsvEntityColumnsForm.object.some((item) => {
                return (
                  entityColumnTitleRequired(item.entityColumnTitle) &&
                  !item.csvColumnTitle
                );
              }))
          }
        >
          {currentStep === StepValue.CONFIRM_BEFORE
            ? "送信"
            : isFinalStep()
            ? "閉じる"
            : "次へ"}
        </Button>,
      ]}
      {...props}
    >
      <Space
        direction="vertical"
        style={{
          width: "calc(100vw - 80px)",
          height: "calc(100vh - 180px)",
          overflowY: "scroll",
        }}
      >
        <Steps
          size="small"
          style={{ padding: 4 }}
          current={currentStepIndex}
          items={stepItems}
        />
        {currentStep === StepValue.UPLOAD && (
          <UploadCsvView
            notReadyToUploadCsv={notReadyToUploadCsv}
            notReadyToUploadCsvMessage={notReadyToUploadCsvMessage}
            extra={extraForm}
            selectEncodeForm={selectEncodeForm}
            setCsvColumns={setCsvColumns}
            setCsvData={setCsvData}
          />
        )}
        {currentStep === StepValue.VIEW && (
          <Space direction="vertical">
            <Table
              style={{ width: "calc(100vw - 80px)" }}
              scroll={{
                y: "calc(100vh - 390px)",
                x: "40%",
              }}
              dataSource={csvData}
              columns={csvColumns}
            />
          </Space>
        )}
        {currentStep === StepValue.SELECT_COLUMN && (
          <Space direction="vertical">
            {relateCsvEntityColumnsForm.object.some(
              (item) =>
                entityColumnTitleRequired(item.entityColumnTitle) &&
                !item.csvColumnTitle
            ) && <Alert type="error" message={"未選択のCSVの列があります。"} />}
            <SelectColumnTable
              relateCsvEntityColumnsForm={relateCsvEntityColumnsForm}
              entityColumns={entityColumns}
              csvColumns={csvColumns}
              entityNameJp={entityNameJp}
              entityColumnTitleRequired={entityColumnTitleRequired}
            />
          </Space>
        )}
        {currentStep === StepValue.CONFIRM_BEFORE && (
          <ConfirmBeforeSendCsvView
            entitiesData={entitiesData}
            extraFormView={extraFormView}
            entityColumns={entityColumns}
            relateCsvEntityColumnsForm={relateCsvEntityColumnsForm}
            entityNameJp={entityNameJp}
          />
        )}
        {currentStep === StepValue.RESULTS &&
          (bulkApiResultState.data?.result?.length ? (
            <ResultsSuccessView
              csvData={csvData}
              entityColumns={entityColumns}
              entityNameJp={entityNameJp}
              response={bulkApiResultState?.data}
            />
          ) : (
            <ResultsErrorView response={bulkApiResultState?.error} />
          ))}
      </Space>
    </Modal>
  );
};

const UploadCsvView = ({
  notReadyToUploadCsv,
  notReadyToUploadCsvMessage,
  extra,
  setCsvData,
  setCsvColumns,
  selectEncodeForm,
}: {
  notReadyToUploadCsv?: boolean;
  notReadyToUploadCsvMessage?: string;
  extra?: JSX.Element;
  setCsvData: Dispatch<SetStateAction<any>>;
  setCsvColumns: Dispatch<SetStateAction<ColumnsType<any>>>;
  selectEncodeForm: Form<{ encode?: EncodingCandidates }>;
}) => {
  const handleUpload = async (file: RcFile) => {
    const reader = new FileReader();
    reader.onload = async (event: any) => {
      const jsonData = await csv().fromString(event.target.result);
      setCsvData(jsonData);
      if (jsonData.length > 0) {
        setCsvColumns(
          Object.keys(jsonData[0]).map((key) => ({
            title: key,
            dataIndex: key,
            key,
            width: 100,
          }))
        );
      }
    };
    reader.readAsText(file, selectEncodeForm.object.encode);
  };

  return (
    <div
      style={{
        height: 500,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <Space>
        <CustomSelectField
          label="エンコード"
          form={selectEncodeForm}
          attr="encode"
          selectItems={ENCODING_CANDIDATES.map((item) => ({
            label: item,
            value: item,
          }))}
          onChange={(e) => {
            selectEncodeForm.updateObject("encode", e);
          }}
          required
        />
        {extra}
      </Space>
      {notReadyToUploadCsv && notReadyToUploadCsvMessage && (
        <Alert type="error" message={notReadyToUploadCsvMessage} />
      )}
      <Dragger
        disabled={notReadyToUploadCsv}
        showUploadList={false}
        maxCount={1}
        name="file"
        accept="text/csv"
        beforeUpload={handleUpload}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          クリックもしくはドラッグ&ドロップしてファイルをアップロードしてください。
        </p>
      </Dragger>
    </div>
  );
};

const SelectColumnTable = <TEntity extends BaseEntity>({
  csvColumns,
  entityColumns,
  relateCsvEntityColumnsForm,
  entityNameJp,
  entityColumnTitleRequired,
}: {
  csvColumns: ColumnsType<any>;
  entityColumns: EntityColumn<TEntity>[];
  relateCsvEntityColumnsForm: Form<RelateCsvEntityColumnsForm[]>;
  entityColumnTitleRequired: (entityColumnTitl: string) => boolean;
  entityNameJp: string;
}) => {
  useEffect(() => {
    const cache: RelateCsvEntityColumnsForm[] = JSON.parse(
      localStorage.getItem(`csvImport/${entityNameJp}`) ?? "[]"
    );
    relateCsvEntityColumnsForm.set(
      entityColumns.map((col, index) => {
        const csvColumn = cache.find(
          (data) => data.entityColumnTitle === col.title
        )?.csvColumnTitle;
        return {
          index,
          key: col.title,
          entityColumnTitle: col.title,
          csvColumnTitle:
            csvColumn && csvColumns.map((col) => col.title).includes(csvColumn)
              ? csvColumn
              : undefined,
          required: col.required,
        };
      })
    );
  }, []);

  useEffect(() => {
    localStorage.setItem(
      `csvImport/${entityNameJp}`,
      JSON.stringify(relateCsvEntityColumnsForm.object)
    );
  }, [JSON.stringify(relateCsvEntityColumnsForm.object)]);

  const columns = [
    {
      title: `${entityNameJp}の列`,
      width: 200,
      render: (record: RelateCsvEntityColumnsForm) => {
        return (
          <Space>
            {record.entityColumnTitle}
            {entityColumnTitleRequired(record.entityColumnTitle) && (
              <RequiredLabel />
            )}
          </Space>
        );
      },
    },
    {
      title: "CSVの列",
      width: 150,
      render: (record: RelateCsvEntityColumnsForm) => (
        <CustomSelectField
          form={relateCsvEntityColumnsForm}
          attr={[record.index, "csvColumnTitle"]}
          includeBlank={!entityColumnTitleRequired(record.entityColumnTitle)}
          selectItems={csvColumns.map((csvColumn) => ({
            label: csvColumn.title as ReactNode,
            value: csvColumn.key,
          }))}
        />
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={relateCsvEntityColumnsForm.object}
      columns={columns}
    />
  );
};

const ConfirmBeforeSendCsvView = <TEntity extends BaseEntity>({
  extraFormView,
  entityColumns,
  entitiesData,
  relateCsvEntityColumnsForm,
  entityNameJp,
  ...resultProps
}: {
  extraFormView?: JSX.Element;
  entityColumns: EntityColumn<TEntity>[];
  entitiesData: TEntity[];
  relateCsvEntityColumnsForm: Form<RelateCsvEntityColumnsForm[]>;
  entityNameJp: string;
} & ResultProps) => {
  const table = useTable<TEntity>();

  return (
    <>
      <Alert
        message={`以下の内容で${entityNameJp}データを送信します。問題がなければ、右下の送信ボタンを押してください。`}
      />
      <Space
        direction="vertical"
        size={0}
        style={{ width: "calc(100vw - 80px)" }}
      >
        <CustomTable
          title={() => extraFormView}
          hideDisplaySetting
          tableKey={`${entityNameJp}_csv_import`}
          scroll={{ x: "20%" }}
          table={table}
          columns={entityColumns}
          dataSource={entitiesData}
        />
      </Space>
    </>
  );
};

const ResultsSuccessView = <TEntity extends BaseEntity>({
  response,
  entityColumns,
  entityNameJp,
  csvData,
}: {
  response?: BulkPostResponse<TEntity>;
  entityColumns: EntityColumn<TEntity>[];
  entityNameJp: string;
  csvData: any[];
}) => {
  console.log(response);
  const { token } = theme.useToken();

  const hasFail = !!response?.meta?.failCount;
  const allFail = !!response?.meta?.failCount && !response.meta?.successCount;
  const allSuccess =
    !!response?.meta?.successCount && !response.meta?.failCount;

  const [openFailedDataModal, setOpenFailedDataModal] = useState(false);

  const failedDataToPreview =
    response?.result
      ?.filter((item) => item.status === "fail")
      .map((item) => ({
        ...item.data,
        errorMessage: item.error ?? "",
      })) || [];

  const handleDownloadFailedData = () => {
    if (response?.result.length !== csvData.length) {
      message.error("エラーデータを書き出す際に問題が発生しました。");
      return;
    }
    const failedDataToDownload = csvData
      .map((item, index) => ({
        ...item,
        エラーメッセージ: response?.result[index].error,
      }))
      .filter((item, index) => {
        return response?.result[index].status === "fail";
      });
    const csv = convertToCSV(failedDataToDownload);
    downloadCSV(
      csv,
      `knewit_${entityNameJp}エラーデータ_${dayjs().format("YYYYMMDDHHmm")}.csv`
    );
  };

  return (
    <>
      <Result
        title={
          allFail
            ? "データを保存できませんでした"
            : allSuccess
            ? "全てのデータを保存しました"
            : "一部のデータを保存できませんでした"
        }
        subTitle={
          hasFail && "保存に失敗したデータを表示し、原因を確認してください。"
        }
        status={allFail ? "error" : allSuccess ? "success" : "info"}
        extra={
          hasFail && [
            <Button
              onClick={() => setOpenFailedDataModal(true)}
              key={"preview"}
              icon={<TableOutlined />}
            >
              失敗したデータを表示
            </Button>,
            <Button
              onClick={handleDownloadFailedData}
              key={"download"}
              icon={<ImportOutlined />}
            >
              失敗したデータを保存
            </Button>,
          ]
        }
      >
        <Row gutter={0}>
          <Col span={9} />
          <Col span={4}>
            <Statistic
              valueStyle={{ color: token.colorInfo }}
              title="成功"
              value={response?.meta?.successCount}
              suffix={"件"}
              prefix={<CheckOutlined />}
            />
          </Col>
          <Col span={4}>
            <Statistic
              valueStyle={{ color: token.colorError }}
              title="失敗"
              value={response?.meta?.failCount}
              suffix="件"
              prefix={<CloseOutlined />}
            />
          </Col>
        </Row>
      </Result>
      <FailedDataModal
        open={openFailedDataModal}
        onCancel={(e) => setOpenFailedDataModal(false)}
        failedData={failedDataToPreview}
        entityColumns={entityColumns}
        entityNameJp={entityNameJp}
      />
    </>
  );
};

const ResultsErrorView = ({
  response,
}: {
  response?: { errors?: Record<string, string[]> };
}) => {
  if (response?.errors) {
    const subTitle = "予期しないエラーが発生しました";
    const content =
      Object.keys(response?.errors || {})
        ?.map((key) => {
          const value = response?.errors![key];
          return `${Number(value[0].split(".")[1]) + 1}行目:${
            value[0].split(".")[2]
          }`;
        })
        .join("\n") || "予期しないエラーが発生しました。";
    return (
      <>
        <Result subTitle={subTitle} status={"error"}>
          <>{content}</>
        </Result>
      </>
    );
  } else {
    return <></>;
  }
};

type WithErrorMessage<TEntiry extends BaseEntity> = TEntiry & {
  errorMessage: string;
};

const FailedDataModal = <TEntity extends BaseEntity>({
  entityColumns,
  failedData,
  entityNameJp,
  ...modalProps
}: {
  entityColumns: EntityColumn<WithErrorMessage<TEntity>>[];
  failedData: WithErrorMessage<TEntity>[];
  entityNameJp: string;
} & ModalProps) => {
  const table = useTable<WithErrorMessage<TEntity>>();
  return (
    <Modal
      style={{ top: 40 }}
      {...modalProps}
      title="保存に失敗したデータ"
      width={"calc(100vw-120px)"}
      okButtonProps={{ style: { display: "none" } }}
      cancelText="閉じる"
    >
      <CustomTable
        tableKey={`${entityNameJp}_csv_import_result`}
        scroll={{ x: "calc(100vw - 120px)" }}
        table={table}
        columns={[
          {
            title: "エラー内容",
            width: 800,
            render: (item) => item.errorMessage,
          },
          ...entityColumns,
        ]}
        dataSource={failedData}
      />
    </Modal>
  );
};
