import { Button, Divider, Space, Table, theme } from "antd";
import { useFetchAllConsignorsApi } from "api/consignor";
import { useFetchAllDeliveryCompaniesApi } from "api/delivery_company";
import { useEditOrderApi, useFetchOrderApi, usePostOrderApi } from "api/order";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  Order,
  OrderDetail,
  OrderType,
  OrderCharterType,
  OrderCharterTypes,
  OrderPointType,
  OrderPointTypes,
  dispatchNo,
  OrderUnitTypes,
} from "entities/order";
import dayjs, { Dayjs } from "dayjs";

import { useContext, useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomContentFooter } from "specifics/footer";
import {
  CustomDateField,
  CustomInputField,
  CustomInputNumberField,
  CustomSelectField,
  LabelView,
  NumberValidation,
  StringValidation,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { Form, useEffectSkipFirst, useForm, useQuery } from "utils/hooks";
import {
  getIndexFilteredByPointType,
  getOrderTypeLabel,
  getOrderCharterTypeLabel,
} from "./orders_view";
import { DeleteOutlined, RedoOutlined } from "@ant-design/icons";
import * as H from "history";
import {
  DeliveryCompany,
  DeliveryCompanyManager,
} from "entities/delivery_company";
import { CSSProperties } from "styled-components";
import { filterKanaName } from "utils/util";

const FormView = (
  form: Form<Order>,
  validationResultForm: Form<Order>,
  history: H.History
) => {
  const { token } = theme.useToken();
  const consignorsApi = useFetchAllConsignorsApi();
  const deliveryCompaniesApi = useFetchAllDeliveryCompaniesApi();
  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    consignorsApi.execute();
    deliveryCompaniesApi.execute();
  }, []);
  const isEdit = !!form.object.id;

  useEffectSkipFirst(() => {
    globalState.setLoading(
      deliveryCompaniesApi.loading || consignorsApi.loading
    );
  }, [deliveryCompaniesApi.loading || consignorsApi.loading]);

  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "orderNo", true);
      StringValidation(f, form, "orderDate", true);
      StringValidation(f, form, "consignorId", true);
      if (form.object.orderType === 0) {
        NumberValidation(f, form, "weight", true);
        NumberValidation(f, form, "price", true);
      }
      StringValidation(f, form, "charterType", true);
      //@ts-ignore
      f.orderDetails = form.object.orderDetails?.map((orderDetail) => {
        if (orderDetail.flag === "d") {
          return {};
        }
        return {
          // @ts-ignore
          deliveryCompanyId: [null, undefined, ""].includes(
            orderDetail.deliveryCompanyId
          )
            ? "必須項目です。"
            : undefined,
        };
      });
    });
  }, [JSON.stringify(form.object)]);
  return (
    <Space style={{ width: "100%" }} size={24} direction="vertical">
      <CustomInputField
        required
        label="受注番号"
        attr="orderNo"
        form={form}
        validationResultForm={validationResultForm}
      />
      <CustomDateField
        label="受注日"
        required
        form={form}
        validationResultForm={validationResultForm}
        attr="orderDate"
        style={{ width: 250 }}
      />
      <Space style={{ width: "100%" }} direction="vertical" size={16}>
        <CustomSelectField
          form={form}
          validationResultForm={validationResultForm}
          label="荷主"
          attr="consignorId"
          required
          selectItems={
            consignorsApi.response.data?.map((consignor) => ({
              label: consignor.name,
              value: consignor.id,
            })) || []
          }
          fieldProps={{
            showSearch: true,
            filterOption: filterKanaName(consignorsApi.response.data),
            optionFilterProp: "label",
          }}
          style={{ width: "100%" }}
        />
        <Space style={{ width: "100%" }} direction="vertical" size={4}>
          <div
            style={{
              fontSize: 16,
              color: token.colorText,
              fontWeight: 400,
            }}
          >
            荷主がまだ登録されていない場合
          </div>
          <Space>
            <CustomButton
              onClick={() => window.open("/consignors/create?isTemporary=true")}
            >
              新規荷主を登録する
            </CustomButton>
            <Button shape="circle" onClick={() => consignorsApi.execute()}>
              <RedoOutlined />
            </Button>
          </Space>
        </Space>
      </Space>
      <Space style={{ width: "100%" }} direction="vertical" size={16}>
        {([2, 1] as const).map((pointType) => (
          <OrderDetailField
            key={pointType}
            form={form}
            validationResultForm={validationResultForm}
            pointType={pointType}
            deliveryCompanies={deliveryCompaniesApi.response.data || []}
          />
        ))}
        <Space style={{ width: "100%" }} direction="vertical" size={4}>
          <div
            style={{
              fontSize: 16,
              color: token.colorText,
              fontWeight: 400,
            }}
          >
            行先(積み先・降し先)がまだ登録されていない場合
          </div>
          <Space>
            <CustomButton
              onClick={() =>
                window.open("/delivery-companies/create?isTemporary=true")
              }
            >
              新規行先を登録する
            </CustomButton>
            <Button
              shape="circle"
              onClick={() => deliveryCompaniesApi.execute()}
            >
              <RedoOutlined />
            </Button>
          </Space>
        </Space>
      </Space>
      {form.object.orderType === 0 && (
        <>
          <CustomInputNumberField
            form={form}
            validationResultForm={validationResultForm}
            label="車格"
            attr="weight"
            required
            style={{ width: 200 }}
            fieldProps={{ addonAfter: "t", min: 0, precision: 0 }}
          />

          <CustomInputNumberField
            form={form}
            validationResultForm={validationResultForm}
            label="金額"
            attr="price"
            required
            style={{ width: 200 }}
            fieldProps={{ addonAfter: "円", min: 0, precision: 0 }}
          />
        </>
      )}
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        label="車両"
        attr="charterType"
        selectItems={Object.keys(OrderCharterTypes)
          .map((key) => Number(key) as OrderCharterType)
          .map((orderCharterType: OrderCharterType) => ({
            label: getOrderCharterTypeLabel(orderCharterType),
            value: orderCharterType,
          }))}
        style={{ width: 123 }}
      />
      <CustomInputField
        label="備考"
        attr="memo"
        form={form}
        validationResultForm={validationResultForm}
      />
    </Space>
  );
};

export const OrderDetailField = (props: {
  pointType: OrderPointType;
  form: Form<Order>;
  deliveryCompanies: DeliveryCompany[];
  validationResultForm: Form<Order>;
  style?: CSSProperties;
}) => {
  const { form, pointType, deliveryCompanies } = props;
  const { token } = theme.useToken();
  const isEdit = !!props.form.object.id;
  return (
    <Space
      style={{ ...props.style, width: "100%" }}
      direction="vertical"
      size={0}
    >
      <DescriptionBlock
        label={<LabelView label={OrderPointTypes[pointType]} required />}
        valueStyle={{
          height: "auto",
        }}
        childrenEmpty={<></>}
      >
        {form.object?.orderDetails
          ?.sort((a, b) => {
            // deliveryCompany.nameに基づいて昇順ソート
            const nameA = a.deliveryCompany?.name || "";
            const nameB = b.deliveryCompany?.name || "";
            return nameA.localeCompare(nameB);
          })
          .map((orderDetail, index) => (
            <OrderDetailForm
              key={index}
              index={index}
              orderDetail={orderDetail}
              {...props}
            />
          ))}
      </DescriptionBlock>
      <div
        key={"add-load-button"}
        style={{
          width: 755,
          background: token.colorBgBase,
          textAlign: "center",
          alignItems: "flex-start",
          borderRadius: 8,
          padding: 10,
        }}
      >
        <CustomButton
          onClick={() =>
            form.updateObject("orderDetails", [
              ...(form.object?.orderDetails ?? []),
              {
                pointType,
                deliveryDate: dayjs().format(),
                flag: isEdit ? "i" : undefined,
                no:
                  Math.max(
                    ...(form.object?.orderDetails?.map(
                      (detail) => detail.no ?? -1
                    ) ?? [])
                  ) + 1,
                loadWeight: null,
              } as OrderDetail,
            ])
          }
        >
          {OrderPointTypes[pointType]}を追加
        </CustomButton>
      </div>
    </Space>
  );
};

export const OrderDetailForm = (props: {
  index: number;
  orderDetail: OrderDetail;
  pointType: OrderPointType;
  form: Form<Order>;
  deliveryCompanies: DeliveryCompany[];
  validationResultForm: Form<Order>;
}) => {
  const isEdit = !!props.form.object.id;
  const { token } = theme.useToken();
  const {
    index,
    orderDetail,
    form,
    deliveryCompanies,
    pointType,
    validationResultForm,
  } = props;

  const deliveryCompany = () =>
    deliveryCompanies.find(
      (deliveryCompany) => deliveryCompany.id === orderDetail.deliveryCompanyId
    );

  return orderDetail.pointType === pointType && orderDetail.flag !== "d" ? (
    <div
      style={{
        width: 755,
        background: token.colorBgBase,
        borderRadius: 8,
        marginBottom: 4,
        padding: 16,
      }}
    >
      <Space
        style={{
          width: 720,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div>
          {getIndexFilteredByPointType(form.object, index, pointType) + 1}.
        </div>
        {(form.object.orderDetails?.filter(
          (detail) => detail.pointType === pointType && detail.flag !== "d"
        )?.length ?? 0) > 1 && (
            <Button
              shape="circle"
              icon={<DeleteOutlined style={{ color: "#B91C1C" }} />}
              type="dashed"
              onClick={() =>
                form.updateObject(
                  "orderDetails",
                  form.object.orderDetails?.filter((_, idx) => idx !== index)
                )
              }
            />
          )}
      </Space>
      <Space
        style={{
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <CustomDateField
          label={`${OrderPointTypes[pointType]}日`}
          fieldProps={{ allowClear: true }}
          form={form}
          validationResultForm={validationResultForm}
          attr={["orderDetails", index, "deliveryDate"]}
          style={{ width: 160 }}
          onChange={(value: Dayjs | null, dataString: string) => {
            form.update((f) => {
              if (f.orderDetails?.length) {
                f.orderDetails[index].deliveryDate = value
                  ? value.format()
                  : null;
                if (isEdit && f.orderDetails[index].id) {
                  f.orderDetails[index].flag = "u";
                }
              }
            });
          }}
        />
        <Space direction="vertical">
          <CustomSelectField
            label={`${OrderPointTypes[pointType]}先`}
            form={form}
            validationResultForm={validationResultForm}
            attr={["orderDetails", index, "deliveryCompanyId"]}
            style={{ width: 424 }}
            selectItems={deliveryCompanies
              .filter((dc: DeliveryCompany) => {
                const deliveryComapnyManager = new DeliveryCompanyManager(dc);
                return deliveryComapnyManager.isSameTypeWithGivenType(
                  pointType
                );
              })
              .map((deliveryCompany) => ({
                label: deliveryCompany.name,
                value: deliveryCompany.id,
              }))}
            onChange={(e) => {
              form.update((f) => {
                if (f.orderDetails?.length) {
                  f.orderDetails[index].deliveryCompanyId = e;
                  if (isEdit && f.orderDetails[index].id) {
                    f.orderDetails[index].flag = "u";
                  }
                }
              });
            }}
            fieldProps={{
              showSearch: true,
              filterOption: filterKanaName(deliveryCompanies),
              optionFilterProp: "label",
            }}
          />
          <Space
            split={<Divider style={{ height: 80 }} type="vertical" />}
            size={8}
            style={{ alignItems: "start" }}
          >
            <CustomInputField
              labelStyle={{ fontSize: 14 }}
              label="品名"
              form={form}
              validationResultForm={validationResultForm}
              attr={["orderDetails", index, "productName"]}
              onChange={(e) => {
                form.update((f) => {
                  if (f.orderDetails?.length) {
                    f.orderDetails[index].productName = e.target.value;
                    if (isEdit && f.orderDetails[index].id) {
                      f.orderDetails[index].flag = "u";
                    }
                  }
                });
              }}
              style={{ width: 119.33 }}
            />
            <Space direction="vertical">
              <Space>
                <CustomInputNumberField
                  style={{ width: 65 }}
                  label="数量・長さ"
                  labelStyle={{ fontSize: 14 }}
                  form={form}
                  validationResultForm={validationResultForm}
                  attr={["orderDetails", index, "quantity"]}
                  fieldProps={{ min: 0, precision: 0 }}
                  onChange={(e) => {
                    form.update((f) => {
                      if (f.orderDetails?.length) {
                        f.orderDetails[index].quantity = e;
                        if (isEdit && f.orderDetails[index].id) {
                          f.orderDetails[index].flag = "u";
                        }
                      }
                    });
                  }}
                />
                <CustomSelectField
                  labelStyle={{ fontSize: 14 }}
                  style={{ width: 50 }}
                  label="単位"
                  form={form}
                  validationResultForm={validationResultForm}
                  attr={["orderDetails", index, "unit"]}
                  selectItems={Object.entries(OrderUnitTypes).map(([key, value]) => ({
                    label: value,
                    value: value,
                  }))}
                  onChange={(e) => {
                    form.update((f) => {
                      if (f.orderDetails?.length) {
                        f.orderDetails[index].unit = e;
                        if (isEdit && f.orderDetails[index].id) {
                          f.orderDetails[index].flag = "u";
                        }
                      }
                    });
                  }}
                />
              </Space>
              <CustomInputNumberField
                labelStyle={{ fontSize: 14 }}
                style={{ width: 119.33 }}
                form={form}
                validationResultForm={validationResultForm}
                attr={["orderDetails", index, "length"]}
                fieldProps={{ addonAfter: "m", min: 0, precision: 1 }}
                onChange={(e) => {
                  form.update((f) => {
                    if (f.orderDetails?.length) {
                      f.orderDetails[index].length = e;
                      if (isEdit && f.orderDetails[index].id) {
                        f.orderDetails[index].flag = "u";
                      }
                    }
                  });
                }}
              />
            </Space>
            <CustomInputField
              labelStyle={{ fontSize: 14 }}
              label="着時間指定"
              form={form}
              validationResultForm={validationResultForm}
              attr={["orderDetails", index, "arrivalTimeSpecified"]}
              onChange={(e) => {
                form.update((f) => {
                  if (f.orderDetails?.length) {
                    f.orderDetails[index].arrivalTimeSpecified = e.target.value;
                    if (isEdit && f.orderDetails[index].id) {
                      f.orderDetails[index].flag = "u";
                    }
                  }
                });
              }}
              style={{ width: 119.33 }}
            />
          </Space>
          <Space
            split={<Divider style={{ height: 80 }} type="vertical" />}
            size={8}
          >
            <DescriptionBlock
              labelStyle={{
                width: 119.33,
                overflow: "revert",
                fontSize: 14,
              }}
              valueStyle={{
                width: 119.33,
                overflow: "revert",
                fontSize: 14,
              }}
              label="住所"
            >
              <div>〒{deliveryCompany()?.zipcode}</div>
              <div>{deliveryCompany()?.address}</div>
            </DescriptionBlock>
            <DescriptionBlock
              labelStyle={{
                width: 119.33,
                overflow: "revert",
                fontSize: 14,
              }}
              valueStyle={{
                overflow: "revert",
                fontSize: 14,
              }}
              label="受入可能車両"
            >
              {deliveryCompany()?.maxWeight &&
                `${deliveryCompany()?.maxWeight}t`}
            </DescriptionBlock>
            <DescriptionBlock
              labelStyle={{
                width: 119.33,
                overflow: "revert",
                fontSize: 14,
              }}
              valueStyle={{
                overflow: "revert",
                fontSize: 14,
              }}
              label="受入可能時間"
            >
              {deliveryCompany()?.availableHours?.replace(",", "\n")}
            </DescriptionBlock>
          </Space>
        </Space>
        <CustomInputNumberField
          label="重量"
          form={form}
          validationResultForm={validationResultForm}
          attr={["orderDetails", index, "loadWeight"]}
          fieldProps={{ addonAfter: "kg", min: 0, precision: 1 }}
          style={{ width: 123 }}
          onChange={(e) => {
            form.update((f) => {
              if (f.orderDetails?.length) {
                f.orderDetails[index].loadWeight = e;
                if (isEdit && f.orderDetails[index].id) {
                  f.orderDetails[index].flag = "u";
                }
              }
            });
          }}
        />
      </Space>
      <CustomInputField
        label="備考"
        form={form}
        validationResultForm={validationResultForm}
        attr={["orderDetails", index, "memo"]}
      />
    </div>
  ) : (
    <></>
  );
};

const _OrdersCreatePage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const params = useQuery<{ category?: OrderType }>();
  const globalState = useContext(GlobalStateContext);
  const postApi = usePostOrderApi();
  const form = useForm<Order>({
    charterType: 0,
    orderType: params.category && (Number(params.category) as OrderType),
    orderDate: dayjs().format(),
    orderDetails: [
      { pointType: 1, deliveryDate: dayjs().format(), no: 1, loadWeight: null },
      { pointType: 2, deliveryDate: dayjs().format(), no: 1, loadWeight: null },
    ],
    companyId: globalState.user.companyId,
  });
  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      props.history.goBack();
    }
  }, [postApi.loading]);

  const validationResultForm = useForm<Order>({});
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
        height: 48,
      }}
      title={`受注登録 ${getOrderTypeLabel(params.category)}`}
      childrenStyle={{
        display: "flex",
        justifyContent: "center",
      }}
      handleLogoClick={() => props.history.push("/operations/orders")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm, props.history)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every((key) => {
                  const value = validationResultForm.object[key as keyof Order];
                  if (key === "orderDetails") {
                    return validationResultForm.object.orderDetails?.every(
                      (item: OrderDetail) =>
                        Object.keys(item).every(
                          (k) => item[k as keyof OrderDetail] === undefined
                        )
                    );
                  } else {
                    return value === undefined;
                  }
                })
              ) {
                postApi.execute(dispatchNo(form.object));
              }
            }}
          >
            登録
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.goBack()}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

const _OrdersEditPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const orderApi = useFetchOrderApi();
  const params = useParams<{ id: string }>();
  const form = useForm<Order>({});
  const editApi = useEditOrderApi();
  useEffect(() => {
    orderApi.execute(params.id);
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(orderApi.loading);
    if (orderApi.isSuccess()) {
      form.set(orderApi.response.data);
    }
  }, [orderApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.history.goBack();
    }
  }, [editApi.loading]);

  const validationResultForm = useForm<Order>({});
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
        height: 48,
      }}
      title={`受注編集 ${getOrderTypeLabel(orderApi.response.data?.orderType)}`}
      childrenStyle={{
        display: "flex",
        justifyContent: "center",
        padding: "auto",
      }}
      handleLogoClick={() => props.history.push("/operations/orders")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm, props.history)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every((key) => {
                  const value = validationResultForm.object[key as keyof Order];
                  if (key === "orderDetails") {
                    return validationResultForm.object.orderDetails?.every(
                      (item: OrderDetail) =>
                        Object.keys(item).every(
                          (k) => item[k as keyof OrderDetail] === undefined
                        )
                    );
                  } else {
                    return value === undefined;
                  }
                })
              ) {
                editApi.execute(dispatchNo(form.object));
              }
            }}
          >
            保存
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.goBack()}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const OrdersEditPage = withRouter(_OrdersEditPage);

export const OrdersCreatePage = withRouter(_OrdersCreatePage);
