import { Popover, PopoverProps, Space, Spin, notification, theme } from "antd";
import { ReactNode, useContext, useState } from "react";
import * as H from "history";
import { HistoryProps } from "routes/app";
import { MdLogout } from "react-icons/md";

import { GlobalStateContext } from "contexts/global_state_context";
import { useLogoutApi } from "api/authorization";
import { useEffectSkipFirst } from "utils/hooks";
import { CookieManager } from "utils/cookie_manager";

export const LoginUserPopover = (
  props: HistoryProps & { children: ReactNode } & PopoverProps
) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  return (
    <Popover
      title={
        <div style={{ padding: 8, width: "100%" }}>
          <div
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: token.colorText,
            }}
          >
            {globalState.user.name ?? <Spin size="small" />}
          </div>
          <div
            style={{ fontSize: 12, fontWeight: 500, color: token.colorText }}
          >
            {globalState.user.mobile}
          </div>
        </div>
      }
      content={<LogoutTip history={props.history} />}
      placement="rightBottom"
      trigger="click"
      overlayInnerStyle={{
        borderRadius: "8px",
        marginBottom: 20,
        padding: 0,
      }}
      {...props}
    >
      {props.children}
    </Popover>
  );
};

const LogoutTip = ({ history }: { history: H.History }) => {
  const [hover, setHover] = useState(false);
  const { token } = theme.useToken();
  const logoutApi = useLogoutApi();
  const globalState = useContext(GlobalStateContext);
  useEffectSkipFirst(() => {
    globalState.setLoading(logoutApi.loading);
    if (logoutApi.isSuccess()) {
      if (process.env.REACT_APP_ENV_MODE !== "LOCAL") {
        CookieManager.removeUserToken();
        CookieManager.removeUserId();
      }
      notification.info({ message: "Successfully logged out" });
      history.push("/login");
    }
  }, [logoutApi.loading]);
  return (
    <Space
      onClick={() => {
        if (process.env.REACT_APP_ENV_MODE === "LOCAL") {
          history.push("/login");
        }
        logoutApi.execute();
      }}
      style={{
        padding: 8,
        background: hover ? token.colorBgBase : token.colorWhite,
        borderRadius: "8px",
        width: "calc(100% - 16px)",
        height: "100%",
        alignItems: "center",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <MdLogout color="#9CA3AF" style={{ verticalAlign: -2 }} size={16} />
      <div>ログアウト</div>
    </Space>
  );
};
