import { Result } from "antd";
import { useLocation, withRouter } from "react-router-dom";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import useMedia from "use-media";

const ConfirmationPage = (props: HistoryProps): JSX.Element => {
  const title = useLocation<{ title: string }>().state.title;
  const isMobile = useMedia("(max-width: 519px)");
  return (
    <div style={{ width: "100%", height: "100vh", alignItems: "center" }}>
      <Result
        style={{
          transition: "all 0.2s",
        }}
        status="success"
        title="完了"
        subTitle={`${title}が完了しました。`}
        extra={[
          <CustomButton
            key={0}
            onClick={() => {
              const nextUrl = isMobile ? `${process.env.REACT_APP_DRIVER_HOST}login` : `${process.env.REACT_APP_ADMIN_HOST}login`;
              window.location.href = nextUrl;
            }}
            type="primary"
          >
            ログイン画面に移動する
          </CustomButton>
        ]}
      />
    </div>
  );
};

export default withRouter(ConfirmationPage);
