import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useIndexApi,
  usePostApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import {
  Route,
  RouteForm,
  RouteSearchForm,
} from "entities/route";
import { ID, PagingResponse } from "entities";

type AllRoutesResponse = BaseResponse<Route[]>;

export function useFetchAllRoutesApi(): ApiSet<AllRoutesResponse> & {
  execute: () => void;
} {
  const api = useIndexApi<AllRoutesResponse>(new HttpClient(), {
    initialResponse: { data: [] },
  });

  const execute = (): void => {
    const apiPath = `route/all`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export type RoutesResponse = PagingResponse<Route>;

export function useFetchRoutesApi(
  searchForm?: Form<RouteSearchForm>
): IndexApiSet<RoutesResponse> & { execute: () => void } {
  const apiPath = "route";
  const api = useIndexApi<RoutesResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type RouteResponse = BaseResponse & {
  data: Route;
};

export function useFetchRouteApi(): ApiSet<RouteResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<RouteResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `route/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostRouteApi(): ApiSet<RouteResponse> & {
  execute: (form: Form<RouteForm>) => void;
} {
  const apiSet = usePostApi<RouteResponse, RouteForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<RouteForm>) => {
    const apiPath = `route`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditRouteApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<RouteForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, RouteForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<RouteForm>) => {
    const apiPath = `route/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteRouteApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `route/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}
