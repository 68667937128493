import { Space } from "antd";

import {
  RightOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { DescriptionBlock } from "specifics/description";

export const ChangeRatioStatisticsView = ({
  items,
}: {
  items: { label: string; percenrt?: number }[];
}) => {
  return (
    <Space style={{ width: "100%", justifyContent: "space-between" }}>
      {items.map((item, index) => {
        return (
          <DescriptionBlock key={index} label={item.label}>
            <ChangeRatioStatisticView percent={item.percenrt} />
          </DescriptionBlock>
        );
      })}
    </Space>
  );
};

const ChangeRatioStatisticView = ({ percent }: { percent?: number }) => {
  const color = !percent ? "#000" : percent > 0 ? "#00A389" : "#FF5B5B";
  const bgColor = !percent ? "#ddd" : percent > 0 ? "#00A38922" : "#FF5B5B22";
  const Icon = !percent
    ? ArrowRightOutlined
    : percent > 0
    ? ArrowUpOutlined
    : ArrowDownOutlined;
  return (
    <Space size={8} align="center">
      <div style={{ background: bgColor, padding: 4, borderRadius: 4 }}>
        <Icon style={{ color, fontSize: 20, fontWeight: 600 }} />
      </div>
      <Space
        style={{
          color,
          fontWeight: 600,
        }}
        align="center"
        size={0}
      >
        <span style={{ fontSize: 20 }}>
          {percent! > 0 ? "+" : percent! < 0 ? "-" : ""}
        </span>
        <span>
          <span style={{ marginLeft: 2, fontSize: 32 }}>
            {percent !== undefined ? Math.abs(percent!) : "--"}
          </span>
          {percent !== undefined && <span style={{ fontSize: 20 }}>%</span>}
        </span>
      </Space>
    </Space>
  );
};
