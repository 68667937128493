import { BaseEntity, BaseSearchForm } from ".";
import { ContractItem } from "./contract";
import { DeliveryPartnerCompany } from "./delivery_partner_company";
import { Manual } from "./manual";
import { Point } from "./point";
import { Route } from "./route";

export const DeliveryCompanyTypes = {
  1: "降し地",
  2: "積み地",
  3: "積み地&降し地",
} as const;

export type DeliveryCompanyType = keyof typeof DeliveryCompanyTypes;

export type DeliveryCompany = BaseEntity & ContractItem & {
  deliveryCompanyId?: string;
  type?: DeliveryCompanyType;
  name?: string;
  kana?: string;
  zipcode?: string;
  address?: string;
  availableHours?: string;
  deliveryPartnerCompanies?: DeliveryPartnerCompany[];
  routes?: Route[];
  maxWeight?: number;
  vehicleType?: 0 | 1;
  repName?: string;
  repTel?: string;
  manualId?: string;
  manual?: Manual;
  manuals?: Manual[];
  memo?: string;
  urls?: { name: string, url: string }[];

  hasManual?: boolean;

  pointId?: string;
  point?: Point;

  deliveryCompanies?: DeliveryCompany[];
};

export type DeliveryCompanyForm = DeliveryCompany;

export type DeliveryCompanySearchForm = BaseSearchForm & DeliveryCompany;

export class DeliveryCompanyManager {
  private deliveryCompany: DeliveryCompany;
  constructor(deliveryCompany: DeliveryCompany) {
    this.deliveryCompany = deliveryCompany;
  }
  public isSameTypeWithGivenType = (givenType: DeliveryCompanyType) => {
    // givenTypeが1なら、this.deliveryCompany.typeが1または3のときtrue
    // givenTypeが2なら、this.deliveryCompany.typeが2または3のときtrue
    if (givenType === 3) return true;
    else
      return (
        this.deliveryCompany.type === givenType ||
        this.deliveryCompany.type === 3
      );
  };
}
