import { Space, theme } from "antd";
import { useFetchIssueApi } from "api/issue";
import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { DriversHeader } from "specifics/drivers_header";
import { CustomTag } from "specifics/tag";

const DriverIssuePage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const params = useParams<{ id: string }>();

  const globalState = useContext(GlobalStateContext);

  const issueApi = useFetchIssueApi();
  useEffect(() => {
    issueApi.execute(params.id);
  }, []);
  useEffect(() => {
    globalState.setLoading(issueApi.loading);
  }, [issueApi.loading]);
  const issue = () => issueApi.response.data;
  return (
    <Space
      style={{
        background: token.colorBgBase,
        minHeight: "100vh",
      }}
      direction="vertical"
      size={0}
    >
      <DriversHeader
        title={issue()?.deliveryCompany?.name ?? ""}
        onBack={() => props.history.push("/drivers/issues")}
      />
      <Space
        style={{
          paddingTop: 48,
        }}
      >
        <Space
          direction="vertical"
          style={{
            background: "#FFFFFF",
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
            borderRadius: "8px",
          }}
          size={0}
        >
          <div
            style={{
              padding: "12px 16px",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "24px",
              borderBottom: "1px solid #F5F5F5",
              width: "calc(100vw - 32px)",
            }}
          >
            報告内容
          </div>
          <Space
            size={16}
            direction="vertical"
            style={{ padding: "20px 16px" }}
          >
            <Space>
              <CustomTag style={{ borderRadius: "4px" }}>
                {issue()?.category}
              </CustomTag>
              {!issue()?.isSolved && (
                <CustomTag
                  style={{
                    width: 48,
                    height: 20,
                    padding: "2px 6px",
                    color: "#EF4444",
                    background: "#FEF2F2",
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "16px",
                  }}
                >
                  未対応
                </CustomTag>
              )}
            </Space>
            <Space direction="vertical" size={8}>
              <div
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: token.colorText,
                }}
              >
                送付先
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#6B7280",
                }}
              >
                {issue()?.toUser?.name ?? "--"}
              </div>
              <div
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: token.colorText,
                }}
              >
                説明
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#6B7280",
                }}
              >
                {issue()?.comment ?? "--"}
              </div>
              <div
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: token.colorText,
                }}
              >
                画像
              </div>
              {issue().img ?
                <img
                  alt="Preview"
                  style={{ width: "100%" }}
                  src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${issue().img}`}
                /> :
                "--"
              }
            </Space>
          </Space>
        </Space>
      </Space>
    </Space>
  );
};

export default withRouter(DriverIssuePage);
