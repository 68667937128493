import { PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Popover, Space, Tag as AntdTag, message } from "antd";
import { BaseEntity } from "entities";
import { Tag } from "entities/tag";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export const EntityTagsView = <TEntity extends BaseEntity & { tags?: Tag[] }>({
  entity,
  candidateTags,
  refetchCandidateTags,
  onAddTagToEntity,
  onRemoveTagFromEntity,
}: {
  entity: TEntity;
  candidateTags: Tag[];
  refetchCandidateTags?: () => void;
  onAddTagToEntity: (
    entityId: string,
    tagId: string
  ) => Promise<Tag | undefined>;
  onRemoveTagFromEntity: (
    entityId: string,
    tagId: string
  ) => Promise<Tag | undefined>;
}) => {
  const form = useForm<{ courseTagIds: string[] }>({});

  useEffect(() => {
    if (entity.id) {
      form.reset({
        courseTagIds: entity.tags?.map((tag) => tag.id) || [],
      });
    }
  }, [entity.id]);

  const [tagsState, setTagsState] = useState<Tag[]>([]);

  useEffect(() => {
    setTagsState(entity.tags || []);
  }, [JSON.stringify(entity.tags)]);

  const selectTagsContent = () => {
    return (
      <Space direction="vertical" onClick={(e) => e.stopPropagation()}>
        {candidateTags.filter(
          (tag) => !tagsState?.some((item) => item.id === tag.id)
        ).length ? (
          candidateTags
            .filter((tag) => !tagsState?.some((item) => item.id === tag.id))
            .map((tag) => {
              return (
                <AntdTag
                  color="cyan"
                  style={{
                    boxShadow: "0 0.5mm 1mm rgba(0, 0, 0, 0.3)",
                    cursor: "pointer",
                  }}
                  key={tag.id}
                  onClick={async (e) => {
                    if (entity.id && tag.id) {
                      const newTag = await onAddTagToEntity(entity.id, tag.id);
                      if (newTag) {
                        message.success("タグを追加しました");
                        setTagsState([...tagsState, newTag]);
                      }
                    }
                  }}
                >
                  {tag.name}
                </AntdTag>
              );
            })
        ) : (
          <Alert message="追加できるタグはありません" />
        )}
      </Space>
    );
  };

  return (
    <Space size={0} onClick={(e) => e.stopPropagation()}>
      {tagsState?.map((tag) => {
        return (
          <DeletableTagView
            key={tag.id}
            tag={tag}
            onDelete={async () => {
              if (entity.id && tag.id) {
                const deletedTag = await onRemoveTagFromEntity(
                  entity.id,
                  tag.id
                );
                if (deletedTag) {
                  message.success("タグを外しました");
                  setTagsState(
                    tagsState.filter((tagItem) => tagItem.id !== deletedTag.id)
                  );
                }
              }
            }}
          />
        );
      })}
      <Popover
        trigger={"click"}
        onOpenChange={() => refetchCandidateTags && refetchCandidateTags()}
        content={selectTagsContent}
        title={"タグの選択"}
      >
        <Button
          onClick={(e) => e.stopPropagation()}
          size="small"
          shape="circle"
          icon={<PlusOutlined />}
        />
      </Popover>
    </Space>
  );
};

const DeletableTagView = ({
  tag,
  onDelete,
}: {
  tag: Tag;
  onDelete: (tagId: string) => void;
}) => {
  const [canDelete, setCanDelete] = useState(false);
  return (
    <AntdTag
      color="cyan"
      onClose={(e) => {
        e.stopPropagation();
        tag.id && onDelete(tag.id);
      }}
      key={tag.id}
      closable={!!onDelete && canDelete}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.stopPropagation();
        setCanDelete(!canDelete);
      }}
    >
      {tag.name}
    </AntdTag>
  );
};
