// utils/network/api_hooks.ts
import { ApiSet, BaseResponse, usePostObjectApi, usePutApi, useShowApi } from "utils/network/api_hooks";
import { HttpClient } from "../utils/network/axios";
import { Notification, NotificationForm } from "entities/notification";

export type NotificationsResponse = BaseResponse & {
  data: Notification[];
};

export type NotificationResponse = BaseResponse & {
  data: Notification | null;
};

export type NotificationCountResponse = { data: number; }

export function useFetchNotificationsApi(): ApiSet<NotificationsResponse> & {
  execute: () => void;
} {
  const api = useShowApi<NotificationsResponse>(new HttpClient(), {
    initialResponse: { data: [] }, // 通知は複数存在する可能性があるため、初期値は空の配列
  });

  const execute = (): void => {
    const apiPath = `notification`; // 通知を取得するAPIのパス
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function useFetchNotificationsCountApi(): ApiSet<NotificationCountResponse> & {
  execute: () => void;
} {
  const api = useShowApi<NotificationCountResponse>(new HttpClient(), {
    initialResponse: { data: 0 },
  });

  const execute = (): void => {
    const apiPath = `notification/count`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function useFinishNotificationApi(): ApiSet<NotificationResponse> & {
  execute: (id: string, status?: { status: string }) => void;
} {
  const api = usePutApi<NotificationResponse, { status: string }>(
    new HttpClient(),
    {
      initialResponse: { data: null }, // 更新後の通知データ
    },
    { formatJson: true }
  );

  const execute = (id: string, status?: { status: string }): void => {
    const apiPath = `notification/${id}`; // 通知のステータスを更新するAPIのパス
    api.execute(apiPath, status);
  };

  return {
    ...api,
    execute,
  };
}

export function useEditNotificationApi(): ApiSet<NotificationResponse> & {
  execute: (form: NotificationForm) => void;
} {
  const apiSet = usePostObjectApi<NotificationResponse, NotificationForm>(
    new HttpClient(),
    {
      initialResponse: { data: null }, // 編集後の通知データも複数の可能性を考慮
    },
    { formatJson: true }
  );

  const execute = (notification: NotificationForm) => {
    const apiPath = `notifications`; // 通知を編集するAPIのパス
    apiSet.execute(apiPath, notification);
  };

  return { ...apiSet, execute };
}
