import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useDownloadApi,
  useIndexApi,
  usePostApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import {
  Consignor,
  ConsignorForm,
  ConsignorSearchForm,
} from "entities/consignor";
import { ID, PagingResponse } from "entities";

type AllCosignorsResponse = BaseResponse<Consignor[]>;

export function useFetchAllConsignorsApi(): ApiSet<AllCosignorsResponse> & {
  execute: () => void;
} {
  const api = useIndexApi<AllCosignorsResponse>(new HttpClient(), {
    initialResponse: { data: [] },
  });

  const execute = (): void => {
    const apiPath = `consignor/all`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export type ConsignorsResponse = PagingResponse<Consignor>;

export function useFetchConsignorsApi(
  searchForm?: Form<ConsignorSearchForm>
): IndexApiSet<ConsignorsResponse> & { execute: () => void } {
  const apiPath = "consignor";
  const api = useIndexApi<ConsignorsResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type ConsignorResponse = BaseResponse & {
  data: Consignor;
};

export function useFetchConsignorApi(): ApiSet<ConsignorResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<ConsignorResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `consignor/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostConsignorApi(): ApiSet<ConsignorResponse> & {
  execute: (form: Form<ConsignorForm>) => void;
} {
  const apiSet = usePostApi<ConsignorResponse, ConsignorForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<ConsignorForm>) => {
    const apiPath = `consignor`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditConsignorApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<ConsignorForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, ConsignorForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<ConsignorForm>) => {
    const apiPath = `consignor/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteConsignorApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `consignor/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}
