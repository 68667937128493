import { ID } from ".";

export const NotificationStatus = {
  "進行中": "進行中",
  "完了": "完了",
} as const;

export type NotificationStatus = typeof NotificationStatus[keyof typeof NotificationStatus];

export const NotificationType = {
  "すべて": "すべて",
  "定期フォロー": "定期フォロー",
  "回答済み": "回答済み",
  "未回答": "未回答",
} as const;

export type NotificationType = typeof NotificationType[keyof typeof NotificationType];

export const NotificationEntityType = {
  "運送会社": "App\\Models\\DeliveryPartnerCompany",
} as const;

export type NotificationEntityType = typeof NotificationEntityType[keyof typeof NotificationEntityType];

export type Notification = {
  id: ID;
  companyId: ID;
  entityId: ID;
  entityType: string;
  type: NotificationType;
  status: NotificationStatus;
  content?: string; // JSON文字列を想定
  entity?: any;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type NotificationForm = {
  companyId: ID;
  entityId: ID;
  entityType: string;
  type: NotificationType;
  status: NotificationStatus;
  content?: string;
  description?: string;
};