import { CharteredCompany } from "entities/chartered_company";
import { CharteredVehicle } from "entities/chartered_vehicle";
import { VehicleTypes } from "entities/vehicle";

export const getIndex = (
  charteredCompany: CharteredCompany,
  detailIndex: number
): number => {
  if (charteredCompany.charteredVehicles === undefined) return -1;
  else if (charteredCompany.charteredVehicles.length <= detailIndex) return -1;
  else {
    return charteredCompany.charteredVehicles
      .slice(0, detailIndex)
      .filter((cv) => cv.flag !== "d").length;
  }
};

export const CharteredVehicleTypeView = (
  charteredVehicle?: CharteredVehicle
) => {
  if (charteredVehicle?.vehicleType !== undefined) {
    return VehicleTypes[charteredVehicle.vehicleType as number];
  } else {
    return "--";
  }
};
