import { Popover, Space, theme } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { PageHeader, PageHeaderProps } from "@ant-design/pro-layout";

import { CSSProperties, ReactNode, useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { MdArrowBackIos } from "react-icons/md";

type CustomPageHeaderProps = PageHeaderProps & {
  childrenStyle?: React.CSSProperties;
  headerBottom?: ReactNode;
  navMenu?: ReactNode;
  height?: React.CSSProperties;
};

export const CustomPageHeader = (props: CustomPageHeaderProps) => {
  const { children, headerBottom, childrenStyle, style, height, ...rest } = props;
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <div style={{ height: "100vh", ...height }}>
      <PageHeader
        style={{
          backgroundColor: token.colorWhite,
          borderBottom: `1px solid ${token.colorBorder}`, // ToDo: Fix
          // height: 78,
          ...style,
        }}
        backIcon={props.onBack && <LeftOutlined />}
        extra={
          props.navMenu ? (
            <Popover
              open={open}
              onOpenChange={handleOpenChange}
              content={<div onClick={() => setOpen(false)}>{props.navMenu}</div>}
              trigger="click"
              overlayInnerStyle={{ background: token.colorWhite, marginRight: 20, padding: 3 }}
            >
              <MoreOutlined style={{ fontSize: 24, paddingTop: 5, cursor: 'pointer', color: "#9CA3AF", verticalAlign: "-4", transform: 'rotate(90deg)' }} />
            </Popover>
          ) :
            props.extra
        }
        {...rest}
      />
      <div style={{ height: "calc(100vh - 110px)", ...height }}>
        <div style={{ background: token.colorWhite }}>{headerBottom}</div>
        <div
          style={{
            padding: "32px",
            width: "100%",
            ...childrenStyle,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export const CustomFormPageHeader = (props: {
  children: ReactNode;
  style?: CSSProperties;
  childrenStyle?: CSSProperties;
  title: ReactNode;
  onBack?: () => void;
  handleLogoClick?: () => void;
  extra?: ReactNode;
}) => {
  const { token } = theme.useToken();

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Space
        size={0}
        style={{
          height: 48,
          padding: "6px 16px",
          background: token.colorWhite,
          width: "100%",
          boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
          zIndex: 1000,
          position: "fixed",
          top: 0,
          justifyContent: "space-between",
          ...props.style
        }}
      >
        <Space>
          {/* <div
            onClick={() => {
              props.handleLogoClick && props.handleLogoClick();
            }}
            style={{
              width: (globalState.dimension.width - 32 - 803) / 2,
              cursor: props.handleLogoClick ? "pointer" : "auto",
            }}
          >
            {(globalState.dimension.width - 32 - 803) / 2 > 150 && (
              <Image src={Logo} preview={false} width={108} />
            )}
          </div> */}
          {props.onBack && (
            <div
              style={{
                padding: "12px 16px",
                color: "#9CA3AF",
                cursor: "pointer",
              }}
            >
              <MdArrowBackIos
                size={24}
                style={{ verticalAlign: "-4" }}
                onClick={() => {
                  if (props.onBack) {
                    props.onBack();
                  }
                }}
              />
            </div>
          )}
          <Space
            size={0}
            style={{
              height: 48,
              color: "#111827",
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            {props.title}
          </Space>
        </Space>
        {props.extra && (
          <div style={{ marginLeft: 'auto' }}>
            {props.extra}
          </div>
        )}
      </Space>
      <div
        style={{
          padding: 32,
          paddingTop: 50,
          width: "100%",
          ...props.childrenStyle,
        }}
      >
        {props.children}
      </div>
    </Space>
  );
};
