import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import * as H from "history";
import { CookieManager } from "utils/cookie_manager";
import { GlobalStateContext } from "contexts/global_state_context";
import {
  AuthorizationForm,
  useFetchSanctumApi,
  useRegisterApi,
} from "api/authorization";
import { useEffectSkipFirst, useForm } from "utils/hooks";

import { Space, theme } from "antd";
import { CustomInputField } from "specifics/input";
import { CustomButton } from "specifics/button";
import { HistoryProps } from "routes/app";
import useMedia from "use-media";

const RegisterPage = ({ history }: HistoryProps) => {
  const apiSet = useRegisterApi();
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);

  const isMobile = useMedia("(max-width: 519px)");
  const form = useForm<AuthorizationForm>({ mobile: "", password: "" });
  const confirmPasswordForm = useForm<AuthorizationForm>({
    mobile: "",
    password: "",
  });

  const sanctumApi = useFetchSanctumApi();

  useEffect(() => {
    globalState.setLoading(apiSet.loading);
  }, [apiSet.loading]);

  useEffect(() => {
    if (apiSet.response.token) {
      CookieManager.saveUserToken(apiSet.response.token);
      globalState.setLoading(false);
      history.push("/dashboards");
    }
  }, [apiSet.response.token]);

  useEffect(() => {
    globalState.setError(apiSet.apiError);
  }, [apiSet.apiError]);

  useEffectSkipFirst(() => {
    globalState.setLoading(sanctumApi.loading);
    if (sanctumApi.isSuccess()) {
      apiSet.execute(form);
    }
  }, [sanctumApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(apiSet.loading);
    if (apiSet.isSuccess()) {
      history.push(
        process.env.REACT_APP_TYPE === "ADMIN" ? "/orders" : "/users"
      );
    }
  }, [apiSet.loading]);

  const handleClickRegisterButton = () => {
    // sanctumApi.execute();
    apiSet.execute(form);
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: isMobile ? "flex-start" : "center",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Space
        style={{
          backgroundColor: token.colorWhite,
          width: isMobile ? globalState.dimension.width - 30 : 448,
          padding: isMobile ? "0px 0px" : "32px 40px 32px 40px",
          zIndex: 1,
          boxShadow: isMobile ? "none" : "0 0.5mm 0.5mm rgba(0, 0, 0, 0.3)",
          borderRadius: 6,
        }}
        direction="vertical"
        size={24}
      >
        <CustomInputField
          label="携帯電話番号"
          placeholder="080-1234-5678"
          attr="mobile"
          form={form}
        />
        <CustomInputField
          label="パスワード"
          placeholder="パスワード"
          attr="password"
          form={form}
          type="password"
          style={{ marginBottom: 0 }}
        />
        <CustomInputField
          label="パスワード(確認用)"
          placeholder="パスワード"
          attr="password"
          form={confirmPasswordForm}
          type="password"
          style={{ marginBottom: 0 }}
        />

        <CustomButton
          type="primary"
          onClick={() => {
            handleClickRegisterButton();
          }}
          style={{
            width: "100%",
            height: !isMobile ? 42 : 50,
            paddingTop: !isMobile ? "auto" : 9,
            paddingBottom: !isMobile ? "auto" : 9,
          }}
        >
          登録
        </CustomButton>
      </Space>
    </Space>
  );
};

export default withRouter(RegisterPage);
