import dayjs from "dayjs";
import { ID } from "entities";
import { DeliveryCompany } from "entities/delivery_company";
import { OrderPointType } from "entities/order";
import { Rotation } from "entities/rotation";

export type RotationSummary = {
  deliveryDate: string; // YYYY-MM-DD
  driverName?: string;
  rotationTurn: number; // 回転順番
  userId?: ID;
  vehicleMaxWeight?: number; // 際大積載量(t)
  unloadCount: number;
  loadWeight: number; // 積載重量(kg)
  loadingRate?: number; // 積載率(%) = 積載重量(kg) / 最大積載量(t) * 100 / 1000(t→kg変換)
  rotation: Rotation;
};

export type RotationSummaryByUserId = {
  userId?: ID;
  driverName?: string;
  vehicleMaxWeight: number; // 際大積載量(t)
  unloadCount: number;
  loadWeight: number; // 積載重量(kg)
  loadingRate?: number; // 積載率(%) = 積載重量(kg) / 最大積載量(t) * 100 / 1000(t→kg変換)
  rotationsCount: number;
  rotationSummaries: RotationSummary[];
};

export type RotationSummaryByDeliveryCompanyId = {
  driverName?: string;
  loadWeight: number; // 積載重量(kg)
  pointType: OrderPointType;
  pointTypejp?: "積" | "降";
  deliveryCompany: DeliveryCompany;
  deliveryCompanyId: ID;
  deliveryCompanyIdAndPointType: string;
};

export class RotationsAnalysisHandler {
  rotations: Rotation[] = [];
  constructor(rotations: Rotation[]) {
    this.rotations = rotations.filter((rotation) => rotation.isAssigned);
  }

  public static calcLoadingRate = (
    loadWeightKg?: number,
    vehicleMaxWeightT?: number,
    rotationsCount = 1
  ): number | undefined => {
    if (!loadWeightKg) return 0;
    if (!vehicleMaxWeightT) return undefined;
    return loadWeightKg / vehicleMaxWeightT / rotationsCount / 10;
  };

  public toRotationSummaries = (): RotationSummary[] => {
    return this.rotations
      .map((rotation) => {
        const vehicleMaxWeight = rotation.vehicle?.maxWeight || 0;
        const loadWeight = rotation.orderDivideds.reduce(
          (totalWeight, orderDivided) =>
            totalWeight +
            (orderDivided.orderDetails
              ?.filter((orderDetail) => orderDetail.pointType === 2)
              ?.reduce(
                (innerTotalWeight, orderDetail) =>
                  innerTotalWeight + (Number(orderDetail.loadWeight) || 0),
                0
              ) || 0),
          0
        );
        const unloadCount = rotation.orderDivideds.reduce(
          (total, orderDivided) =>
            total +
            (orderDivided.orderDetails
              ? orderDivided.orderDetails.filter(
                  (orderDetail) => orderDetail.pointType === 1
                ).length
              : 0),
          0
        );
        return {
          rotation: {
            ...rotation,
            orderDivideds: rotation.orderDivideds.map((divided) => ({
              ...divided,
              orderDetails: divided.orderDetails?.sort((a, b) =>
                (a.turnInRotation ?? 0) > (b.turnInRotation ?? 0) ? 1 : -1
              ),
            })),
          },
          userId: rotation.userId,
          deliveryDate: dayjs(rotation.deliveryDate).format("YYYY-MM-DD"),
          driverName: rotation.user?.name,
          unloadCount,
          loadWeight,
          vehicleMaxWeight,
          loadingRate: RotationsAnalysisHandler.calcLoadingRate(
            loadWeight,
            vehicleMaxWeight
          ),
          rotationTurn: rotation.rotationTurn || 0,
        };
      })
      .sort((a, b) =>
        `${a.deliveryDate}-${a.driverName}-${a.rotationTurn}` <
        `${b.deliveryDate}-${b.driverName}-${b.rotationTurn}`
          ? -1
          : 1
      );
  };

  public toRotationSummariesByUserId = () => {
    const summaries = this.toRotationSummaries();
    const summariesByUserId: RotationSummaryByUserId[] = summaries.reduce(
      (acc, summary) => {
        const userId = summary.userId || "unknown";
        if (acc.some((item) => item.userId === userId)) {
          const index = acc.findIndex((item) => item.userId === userId);
          acc[index].unloadCount += summary.unloadCount;
          acc[index].loadWeight += summary.loadWeight;
          acc[index].rotationsCount += 1;
          acc[index].loadingRate = RotationsAnalysisHandler.calcLoadingRate(
            acc[index].loadWeight,
            summary.vehicleMaxWeight,
            acc[index].rotationsCount
          );
          acc[index].rotationSummaries.push(summary);
        } else {
          acc.push({
            userId,
            vehicleMaxWeight: summary.vehicleMaxWeight ?? 0,
            driverName: summary.driverName,
            unloadCount: summary.unloadCount || 0,
            loadWeight: summary.loadWeight,
            loadingRate: RotationsAnalysisHandler.calcLoadingRate(
              summary.loadWeight,
              summary.vehicleMaxWeight
            ),
            rotationsCount: 1,
            rotationSummaries: [summary],
          });
        }
        return acc;
      },
      [] as RotationSummaryByUserId[]
    );
    return summariesByUserId;
  };

  public toRotationSummariesByOrderDetailid = () => {
    const summaries = this.toRotationSummaries();
    const summariesByOrderDetail = summaries.reduce(
      (acc, summary: RotationSummary) => {
        const { rotation, userId, rotationTurn, ...summaryRest } = summary;
        return [
          ...acc,
          ...rotation.orderDivideds.reduce((accOrderDivides, orderDivided) => {
            return [
              ...accOrderDivides,
              ...(orderDivided.orderDetails?.map((orderDetail) => {
                const {
                  deliveryCompany,
                  deliveryCompanyId,
                  companyId,
                  createdAt,
                  updatedAt,
                  memo,
                  orderId,
                  orderDividedId,
                  pointType,
                  id,
                  no,
                  turnInRotation,
                  deliveryDate,
                  loadWeight,
                  ...orderDetailRest
                } = orderDetail;
                const sortKey = `${deliveryDate}-${rotationTurn}-${turnInRotation}`;
                return {
                  ...orderDetailRest,
                  ...summaryRest,
                  loadWeight: Number(loadWeight),
                  rotationTurn: rotationTurn + 1,
                  deliveryDate: dayjs(deliveryDate).format("YYYY-MM-DD"),
                  deliveryCompany,
                  deliveryCompanyId,
                  pointType,
                  pointTypeJp:
                    pointType === 1 ? "降" : pointType === 2 ? "積" : undefined,
                  sortKey,
                };
              }) || []),
            ];
          }, [] as any[]),
        ].sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1));
      },
      [] as any[]
    );
    return summariesByOrderDetail;
  };

  public toRotationSummariesByDeliveryCompanyId = () => {
    const summariesByOrderDetail = this.toRotationSummariesByOrderDetailid();
    const summariesByDeliveryCompanyId = summariesByOrderDetail.reduce(
      (acc, summary) => {
        const deliveryCompanyId = summary.deliveryCompanyId || "unknown";
        const deliveryCompanyIdAndPointType = `${deliveryCompanyId}-${summary.pointType}`;
        if (
          acc.some(
            (item: any) =>
              item.deliveryCompanyIdAndPointType ===
              deliveryCompanyIdAndPointType
          )
        ) {
          const index = acc.findIndex(
            (item: any) =>
              item.deliveryCompanyIdAndPointType ===
              deliveryCompanyIdAndPointType
          );

          acc[index].loadWeight += summary.loadWeight;
        } else {
          acc.push({
            deliveryCompanyIdAndPointType,
            pointType: summary.pointType,
            pointTypeJp: summary.pointTypeJp as "積" | "降",
            deliveryCompanyId,
            deliveryCompany: summary.deliveryCompany,
            loadWeight: summary.loadWeight,
          } as RotationSummaryByDeliveryCompanyId);
        }
        return acc;
      },
      [] as RotationSummaryByDeliveryCompanyId[]
    );
    return summariesByDeliveryCompanyId;
  };
}
