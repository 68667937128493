import { DrawerProps, Drawer, ModalProps, Button } from "antd";
import { MetricsForm, MetricsFormView } from "./metrics_config_form";
import { Form } from "utils/hooks";
import { MetricsHistoryDrawer } from "./history/metrics_history_drawer";

export const AnalysisMetricsConfigDrawer = ({
  form,
  validationResultForm,
  onPostForm,
  historyDraawerOpen,
  setHistoryDraawerOpen,
  onClickHistory,
  ...drwwerProps
}: {
  form: Form<MetricsForm>;
  validationResultForm: Form<MetricsForm>;
  onPostForm: () => void;
  historyDraawerOpen: boolean;
  setHistoryDraawerOpen: (newState: boolean) => void;
  onClickHistory?: (history: MetricsForm) => void;
} & DrawerProps) => {
  return (
    <Drawer title="分析条件の設定" {...drwwerProps}>
      <MetricsFormView
        validationResultForm={validationResultForm}
        form={form}
        onPostForm={onPostForm}
      />
      <MetricsHistoryDrawer
        open={historyDraawerOpen}
        onClose={() => setHistoryDraawerOpen(false)}
        onClickHistory={onClickHistory}
      />
    </Drawer>
  );
};
