import { theme } from "antd";
import {
  useDeleteTemplateApi,
  useFetchTemplatesApi,
  usePostTemplateApi,
} from "api/template";
import { GlobalStateContext } from "contexts/global_state_context";

import { Template } from "entities/template";
import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { TemplatesTable } from "../templates_table";
import { ID } from "entities";

const SuperAdminTemplatesPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchTemplatesApi();
  const copyForm = useForm<Template>({});
  const deleteApi = useDeleteTemplateApi();
  const postApi = usePostTemplateApi();

  useEffect(() => {
    listApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      listApi.execute();
    }
  }, [postApi.loading]);

  useEffectSkipFirst(() => {
    if (!copyForm.isEmpty()) {
      postApi.execute(copyForm);
    }
  }, [JSON.stringify(copyForm.object)]);

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      title="テンプレート"
      extra={[
        <CustomButton
          key="CreateTemplates"
          onClick={() => props.history.push("/super-admin/templates/create")}
          type="primary"
        >
          テンプレート登録
        </CustomButton>,
      ]}
    >
      <TemplatesTable
        handleDelete={(id: ID) => deleteApi.execute(id)}
        handleCopy={(item: Template) => copyForm.set(item)}
        history={props.history}
        dataSource={listApi.response.data}
      />
    </CustomPageHeader>
  );
};

export default withRouter(SuperAdminTemplatesPage);
