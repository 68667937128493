import { Skeleton, theme } from "antd";

export const NumberStatisticsView = ({
  value,
  unit,
  loading,
}: {
  value: number;
  unit?: string;
  loading?: boolean;
}) => {
  const { token } = theme.useToken();
  return (
    <Skeleton loading={loading}>
      <div
        style={{
          color: token.colorPrimary,
          fontWeight: 700,
          fontStyle: "normal",
          height: 242,
          padding: "60px 0px 30px 0px",
        }}
      >
        <span style={{ fontSize: 80 }}>{value ?? "--"}</span>
        <span style={{ fontSize: 60 }}>{unit}</span>
      </div>
    </Skeleton>
  );
};
