import { Space, TableProps, Typography, theme } from "antd";
import { ColumnsType } from "antd/lib/table";

import { ID } from "entities";
import { CharteredCompany } from "entities/chartered_company";
import { useContext } from "react";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { AttrColumn, CustomTable } from "specifics/table";
import { CustomTableText } from "specifics/table_text";
import { useTable } from "specifics/use_table";
import { Prefectures } from "utils/util";
import { CharteredVehicleTypeView } from "./chartered_vehicles_view";
import Highlighter from "react-highlight-words";
import { CharteredVehiclesResponse } from "api/chartered_vehicle";
import { IndexApiSet } from "utils/network/api_hooks";

type CharteredVehiclesTableProps = TableProps<CharteredCompany>;

const { Paragraph, Text } = Typography;

export const CharteredVehiclesTable = (
  props: CharteredVehiclesTableProps &
    HistoryProps & { handleDelete: (id: ID) => void } & {
      listApi?: IndexApiSet<CharteredVehiclesResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const { token } = theme.useToken();
  const table = useTable<CharteredCompany>(props);
  const columns: ColumnsType<CharteredCompany> = [
    {
      ...table.baseColumn("name"),
      ...table.getColumnSearchProps("name", "名称"),
      title: "名称",
      width: 150,
      render: (item) => {
        return <CustomTableText width={150}>{item.name}</CustomTableText>;
      },
    },
    {
      ...table.baseColumn("kana"),
      ...table.getColumnSearchProps("kana", "ふりがな"),
      title: "ふりがな",
      width: 200,
      render: (item) => {
        return <CustomTableText width={200}>{item.kana}</CustomTableText>;
      },
    },
    {
      ...table.baseColumn("address"),
      ...table.getColumnSearchProps("address", "住所"),
      title: "住所",
      width: 266,
      render: (item) => {
        return <CustomTableText width={266}>{item.address}</CustomTableText>;
      },
    },
    {
      ...table.baseColumn("repName"),
      ...table.getColumnSearchProps("repName", "担当者氏名"),
      title: "担当者氏名",
      width: 180,
      render: (item) => {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[table.searchText]}
            autoEscape
            textToHighlight={item.repName ?? "--"}
          />
        );
      },
    },
    {
      ...table.baseColumn("tel"),
      ...table.getColumnSearchProps("tel", "電話番号"),
      title: "電話番号",
      width: 180,
      render: (item) => {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[table.searchText]}
            autoEscape
            textToHighlight={item.tel ?? "--"}
          />
        );
      },
    },
    AttrColumn<CharteredCompany>({
      attr: "charteredVehicles",
      label: "保有車両",
      width: 181,
      render: (item) =>
        item.charteredVehicles
          ?.map(
            (vehicle) =>
              `${CharteredVehicleTypeView(vehicle)}(${vehicle.weight}t)`
          )
          .join(","),
    }),
    AttrColumn<CharteredCompany>({
      attr: "area",
      label: "主要エリア",
      width: 181,
      render: (item) =>
        item.area
          ?.split(",")
          .map((id) => Prefectures[id])
          .join(","),
    }),
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 138,
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(
                `/operations/chartered-vehicles/${item.id}/edit`
              );
            }}
          >
            編集
          </CustomButton>
          <CustomButton
            style={{
              fontWeight: 500,
              padding: 0,
              height: "30px",
              width: "28px",
              textAlign: "left",
            }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              props.handleDelete(item.id);
            }}
            danger
            confirm
            popconfirmProps={{
              title: "削除します。よろしいですか？",
            }}
          >
            削除
          </CustomButton>
        </Space>
      ),
    },
  ];

  return (
    <CustomTable
      tableKey="chartered_vehicles_table"
      table={table}
      pagination={table.paginationInfo}
      onChange={table.handleChange}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () =>
          props.history.push(`/operations/chartered-vehicles/${data.id}`),
      })}
      {...props}
    />
  );
};
