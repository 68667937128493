import {
  ApiSet,
  BaseResponse,
  BulkPostResponse,
  IndexApiSet,
  useDeleteApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import { Company, CompanyForm, CompanySearchForm } from "entities/company";
import { ID } from "entities";

type AllCompaniesResponse = BaseResponse<Company[]>;

export type CompaniesResponse = BaseResponse<Company[]>;

export function useFetchAllCompaniesApi(
  searchForm?: Form<CompanySearchForm>
): IndexApiSet<AllCompaniesResponse> & { execute: () => void } {
  const apiPath = "company";
  const api = useIndexApi<AllCompaniesResponse>(new HttpClient(), {
    initialResponse: {},
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (): void => {
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export type CompanyResponse = BaseResponse & {
  data: Company;
};

export function useFetchCompanyApi(): ApiSet<CompanyResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<CompanyResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `company/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function useFetchCurrentCompanyApi(): ApiSet<CompanyResponse> & {
  execute: () => void;
} {
  const api = useShowApi<CompanyResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (): void => {
    const apiPath = `company/current`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function useFetchCompanyViewApi(): ApiSet<CompanyResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<CompanyResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `company-view/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostCompanyApi(): ApiSet<CompanyResponse> & {
  execute: (form: Form<CompanyForm>) => void;
} {
  const apiSet = usePostApi<CompanyResponse, CompanyForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<CompanyForm>) => {
    const apiPath = `company`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditCompanyApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<CompanyForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, CompanyForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<CompanyForm>) => {
    const apiPath = `company/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteCompanyApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `company/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export function usePostCompaniesApi(): ApiSet<BulkPostResponse<Company>> & {
  execute: (companies: CompanyForm[]) => Promise<BulkPostResponse<Company>>;
} {
  const apiSet = usePostObjectApi<any, { companies: CompanyForm[] }>(
    new HttpClient(),
    {
      initialResponse: {
        meta: { successCount: 0, failCount: 0 },
        data: [],
      },
    },
    { formatJson: true }
  );

  const execute = async (
    companies: CompanyForm[]
  ): Promise<BulkPostResponse<Company>> => {
    const apiPath = `company/bulk`;
    const result = await apiSet.execute(apiPath, { companies });
    return result.data;
  };

  return { ...apiSet, execute };
}

export function useDeepCopyCompanyApi(): ApiSet<BulkPostResponse<Company>> & {
  execute: (id: ID) => Promise<BulkPostResponse<Company>>;
} {
  const apiSet = usePostObjectApi<any, unknown>(
    new HttpClient(),
    {
      initialResponse: {
        meta: { successCount: 0, failCount: 0 },
        data: [],
      },
    },
    { formatJson: true }
  );

  const execute = async (id: ID): Promise<BulkPostResponse<any>> => {
    const apiPath = `company/deep_copy/${id}`;
    const result = await apiSet.execute(apiPath, {});
    return result.data;
  };

  return { ...apiSet, execute };
}
