import { theme } from "antd";
import {
  useDeleteDeliveryCompanyApi,
  useFetchdeliveryCompaniesApi
} from "api/delivery_company";
import { useFetchAllDeliveryPartnerCompaniesApi } from "api/delivery_partner_company";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  DeliveryCompanySearchForm,
} from "entities/delivery_company";
import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton, UploadCSVFileButton } from "specifics/button";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { DeliveryCompaniesTable } from "./delivery_companies_table";
import { ID } from "entities";
import { DeliverySuperAdminCompanyCsvImportModal } from "./delivery_company_csv_import_modal";

const deliveryCompaniesPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchdeliveryCompaniesApi();
  const allDeliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const searchForm = useForm<DeliveryCompanySearchForm>({});
  const deleteApi = useDeleteDeliveryCompanyApi();
  const [importCSVModalOpen, setImportCsvModalopen] = useState(false);

  useEffect(() => {
    if (!importCSVModalOpen) {
      listApi.execute();
    }
  }, [importCSVModalOpen]);

  useEffect(() => {
    allDeliveryPartnerCompaniesApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading || allDeliveryPartnerCompaniesApi.loading);
  }, [listApi.loading, allDeliveryPartnerCompaniesApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      title="行先"
      extra={[
        <UploadCSVFileButton
          key="UploadCsvFileButton"
          onClick={() => setImportCsvModalopen(true)}
        />,
        <CustomButton
          key="CreatedeliveryCompanies"
          onClick={() =>
            props.history.push("/operations/delivery-companies/create")
          }
          type="primary"
        >
          行先登録
        </CustomButton>,
      ]}
    >
      <DeliveryCompaniesTable
        handleDelete={(id: ID) => deleteApi.execute(id)}
        history={props.history}
        dataSource={listApi.response.data}
        listApi={listApi}
        deliveryPartnerCompanies={allDeliveryPartnerCompaniesApi.response.data || []}
        pagination={{
          current: listApi.response.meta?.currentPage,
          pageSize: listApi.response.meta?.perPage,
          total: listApi.response.meta?.total,
        }}
      />
      <DeliverySuperAdminCompanyCsvImportModal
        open={importCSVModalOpen}
        onCancel={(e) => setImportCsvModalopen(false)}
      />
    </CustomPageHeader>
  );
};

export default withRouter(deliveryCompaniesPage);
