import { Space, Table, Upload, theme } from "antd";
import { useDeleteVehicleApi, useFetchVehiclesApi } from "api/vehicle";
import { useFetchUsersApi } from "api/user";
import { GlobalStateContext } from "contexts/global_state_context";

import { Vehicle, VehicleSearchForm } from "entities/vehicle";
import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton, UploadCSVFileButton } from "specifics/button";
import { CustomInputField } from "specifics/input";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { VehiclesTable } from "./vehicles_table";
import { ID } from "entities";
import { VehicleCsvImportModal } from "./vehilce_csv_import_modal";

const VehiclesPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchVehiclesApi();
  const searchForm = useForm<VehicleSearchForm>({});
  const deleteApi = useDeleteVehicleApi();
  const [importCSVModalOpen, setImportCsvModalopen] = useState(false);

  useEffect(() => {
    if (!importCSVModalOpen) {
      listApi.execute();
    }
  }, [importCSVModalOpen]);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      title="車両"
      extra={[
        <UploadCSVFileButton
          key={"UploadCsvFileButton"}
          onClick={() => setImportCsvModalopen(true)}
        />,
        <CustomButton
          key="CreateVehicles"
          onClick={() => props.history.push("/operations/vehicles/create")}
          type="primary"
        >
          車両登録
        </CustomButton>,
      ]}
    >
      <VehiclesTable
        handleDelete={(id: ID) => deleteApi.execute(id)}
        history={props.history}
        dataSource={listApi.response.data}
        listApi={listApi}
        pagination={{
          current: listApi.response.meta?.currentPage,
          pageSize: listApi.response.meta?.perPage,
          total: listApi.response.meta?.total,
        }}
      />
      <VehicleCsvImportModal
        open={importCSVModalOpen}
        onCancel={(e) => setImportCsvModalopen(false)}
      />
    </CustomPageHeader>
  );
};

export default withRouter(VehiclesPage);
