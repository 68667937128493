import { BaseResponse } from "utils/network/api_hooks";

/**
 * IDのtype alias
 */
export type ID = string;

/**
 * Entityのベース
 */
export type BaseEntity = {
  id?: ID;
  companyId?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type FileAttachment = {
  name: string;
  url: string;
};

export type Truncate = "year" | "month" | "week" | "day";

export type GroupingSearchForm = {
  truncate?: Truncate;
  truncDateColumn?: string;
  truncDateColumnType?: "datetime" | "date";
  groupBy?: string;
  aggregate?: string; // example: 合計: Sum('price'), 集計: Count('id'), 計算: F(Max('price') - Min('price'))
  aggregateAlias?: string;
  groupOrdering?: string;
  groupingValues?: string;
};

export type BaseSearchForm = GroupingSearchForm & {
  ordering?: string;
  page?: number;
  pageSize?: number;
  perPage?: number;
  sorter?: string;
  filter?: string;
};

/**
 * ページングされたレスポンス
 */
type Links = {
  first: string;
  last: string;
  prev: string | null;
  next: string | null;
};

type Meta = {
  currentPage: number;
  perPage: number;
  from: number;
  lastPage: number;
  path: string;
  to: number;
  total: number;
};

export type PagingResponse<T> = BaseResponse<T[]> & {
  links?: Links;
  meta?: Meta;
};

export type Group = Record<string, any>;

export type GroupByResponse = BaseResponse & {
  groups: Record<string, any>[];
};

export type AggregateResponse = BaseResponse & {
  aggregate: Record<string, any>;
};

export enum FormFlag {
  DELETE = "delete",
  CREATE = "create",
  UPDATE = "update",
}
