import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect, useState } from "react";
import { HistoryProps } from "routes/app";
import { useEffectSkipFirst, useQuery } from "utils/hooks";
import { UnfilledTable } from "./table";
import { theme, Drawer } from "antd";
import { queryString } from "utils/util";
import { ContractItems, GroupBy } from "entities/contract";
import { useFetchMismatchContractsApi, useFetchUnfilledContractsApi } from "api/contract";
import { ManualPage } from "components/manuals/manual_page";
import { ID } from "entities";

export const UnfilledPage = (props: HistoryProps & { isModalVisible: boolean }) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  const listApi = useFetchMismatchContractsApi();
  const query = useQuery<{ page: number, pageSize: number, sorter: string, filter: string, type: keyof typeof ContractItems }>();

  useEffect(() => {
    if (Object.keys(query).length > 0 && !props.isModalVisible) {
      listApi.execute(`/undefined/${GroupBy.未記入のみ}${queryString(query)}`);
    }
  }, [JSON.stringify(query)]);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  const contracts = () => listApi.response.data;
  const paginationInfo = () => listApi.response.meta;

  const [manualVisible, setManualVisible] = useState(false);
  const [selectedManualId, setSelectedManualId] = useState<ID>("");
  const showDrawer = (id?: ID) => {
    if (!id) {
      return;
    }
    setSelectedManualId(id);
    setManualVisible(true);
  };
  const onClose = () => {
    setSelectedManualId("");
    setManualVisible(false);
  }

  return (
    <>
      <UnfilledTable
        history={props.history}
        showDrawer={showDrawer}
        dataSource={contracts()}
        pagination={{
          current: paginationInfo()?.currentPage,
          pageSize: paginationInfo()?.perPage,
          total: paginationInfo()?.total,
        }}
        query={query}
      />
      <Drawer
        headerStyle={{ background: token.colorWhite }}
        bodyStyle={{ padding: 0 }}
        placement="right"
        closable={false}
        onClose={onClose}
        open={manualVisible}
        height="100%"
        width="50%"
      >
        {manualVisible && selectedManualId && <ManualPage manualId={selectedManualId} {...props} />}
      </Drawer>
    </>
  );
};
