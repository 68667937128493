import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import { Manual, ManualForm, ManualSearchForm } from "entities/manual";
import { ID, PagingResponse } from "entities";
import { TagResponse, TagsResponse } from "./tag";
import { Tag, TagForm } from "entities/tag";
import { DeliveryTerm } from "entities/contract";

type AllManualsResponse = BaseResponse<Manual[]>;

export function useFetchAllManualsApi(): ApiSet<AllManualsResponse> & {
  execute: (query?: string) => void;
} {
  const api = useIndexApi<AllManualsResponse>(new HttpClient(), {
    initialResponse: { data: [] },
  });

  const execute = (query?: string): void => {
    const apiPath = `manual/all`;
    api.execute(apiPath + (query || ""));
  };

  return { ...api, execute };
}

export type ManualsResponse = PagingResponse<Manual>;

export function useFetchManualsApi(
  searchForm?: Form<ManualSearchForm>
): IndexApiSet<ManualsResponse> & { execute: (query?: string) => void } {
  const apiPath = "manual";
  const api = useIndexApi<ManualsResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query || ""));
  };

  return { ...api, execute };
}

export type ManualResponse = BaseResponse & {
  data: Manual;
};

export function useFetchManualApi(): ApiSet<ManualResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<ManualResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `manual/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

type FilterManualConfig = {
  hasManuals?: boolean;
  deliveryTerm?: DeliveryTerm;
  existNeedTask?: boolean;
  hasNeedTaskMismatchedManuals?: boolean;
  hasDeliveryTermsMismatchedManuals?: boolean;
  requested?: boolean;
  deliveryPartnerCompanyId?: string;
};

export function useFetchFilterAllManualsApi(): ApiSet<AllManualsResponse> & {
  execute: (config?: FilterManualConfig) => void;
} {
  const apiPath = `manual/filterall`;
  const api = usePostObjectApi<
    AllManualsResponse,
    FilterManualConfig
  >(new HttpClient(), {
    initialResponse: {
      data: [],
    },
  });

  const execute = (config?: FilterManualConfig): void => {
    api.execute(apiPath, config);
  };

  return { ...api, execute };
}

export function useFetchManualViewApi(): ApiSet<ManualResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<ManualResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `manual-view/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostManualApi(): ApiSet<ManualResponse> & {
  execute: (form: Form<ManualForm>) => void;
} {
  const apiSet = usePostApi<ManualResponse, ManualForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<ManualForm>) => {
    const apiPath = `manual`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditManualApi(): ApiSet<ManualResponse> & {
  execute: (form: Form<ManualForm>) => void;
} {
  const apiSet = usePostApi<ManualResponse, ManualForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<ManualForm>) => {
    const apiPath = `manual/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteManualApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `manual/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export function useDeleteManualsApi(): ApiSet<BaseResponse> & {
  execute: (idsForm: { ids: ID[] }) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, { ids: ID[] }>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (idsForm: { ids: ID[] }) => {
    const apiPath = `bulk_delete_manual`;
    apiSet.execute(apiPath, idsForm);
  };

  return { ...apiSet, execute };
}

export function useBulkRequestManualsApi(): ApiSet<BaseResponse> & {
  execute: (idsForm: { ids: ID[], deliveryPartnerCompanyId: string }) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, { ids: ID[] }>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (idsForm?: { ids: ID[], deliveryPartnerCompanyId: string }) => {
    const apiPath = `manual/bulk_request`;
    apiSet.execute(apiPath, idsForm);
  };

  return { ...apiSet, execute };
}

type CheckManualExistApiResponse = BaseResponse & {
  data: { id?: string };
};

export function useCheckManualExistApi(): ApiSet<CheckManualExistApiResponse> & {
  execute: (deliveryCompanyId: string, consignorId?: string, partnerCompanyId?: string) => Promise<Manual | undefined>;
} {
  const apiSet = useShowApi<CheckManualExistApiResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = async (deliveryCompanyId: string, consignorId?: string, partnerCompanyId?: string) => {
    const apiPath = `manual_exist/${deliveryCompanyId}/${consignorId}/${partnerCompanyId}`;
    const response = await apiSet.execute(apiPath);
    return response.data;
  };

  return { ...apiSet, execute };
}

type CheckManualDuplicateApiResponse = BaseResponse & {
  data: boolean;
};

export function useCheckManualDuplicateApi(): ApiSet<CheckManualDuplicateApiResponse> & {
  execute: (title: string, id?: string) => void;
} {
  const apiSet = useShowApi<CheckManualDuplicateApiResponse>(new HttpClient(), {
    initialResponse: { data: false },
  });

  const execute = (title: string, id?: string) => {
    const apiPath = `manual_duplicate/${title}${id ? "/" + id : ""}`;
    apiSet.execute(apiPath);
  };

  return { ...apiSet, execute };
}

export function useFetchTagsForManualApi(): ApiSet<TagsResponse> & {
  execute: () => void;
} {
  const apiSet = useIndexApi<TagsResponse>(new HttpClient(), {
    initialResponse: { data: [] },
  });

  const execute = () => {
    const apiPath = `manual/tags`;
    apiSet.execute(apiPath);
  };

  return { ...apiSet, execute };
}

export function useStoreManualTagApi(): ApiSet<TagResponse> & {
  execute: (createTagInput: TagForm) => void;
} {
  const apiSet = usePostObjectApi<TagResponse, TagForm>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (createTagInput: TagForm) => {
    const apiPath = `manual/tags`;
    apiSet.execute(apiPath, createTagInput);
  };

  return { ...apiSet, execute };
}

export function useUpdateManualTagApi(): ApiSet<TagResponse> & {
  execute: (updateTagInput: TagForm) => void;
} {
  const apiSet = usePostObjectApi<TagResponse, TagForm>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (updateTagInput: TagForm) => {
    const apiPath = `manual/tags/${updateTagInput.id}`;
    apiSet.execute(apiPath, updateTagInput);
  };

  return { ...apiSet, execute };
}

export function useDeleteManualTagApi(): ApiSet<TagResponse> & {
  execute: (tagId: string) => void;
} {
  const apiSet = useDeleteApi<TagResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (tagId: string) => {
    const apiPath = `manual/tags/${tagId}`;
    apiSet.execute(apiPath);
  };

  return { ...apiSet, execute };
}

export function useAddTagToManualApi(): ApiSet<TagResponse> & {
  execute: (manualId: string, tagId: string) => Promise<Tag | undefined>;
} {
  const apiSet = usePostObjectApi<TagResponse, { tagId: string }>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    }
  );

  const execute = async (manualId: string, tagId: string) => {
    const apiPath = `manual/${manualId}/add_tags`;
    const response = await apiSet.execute(apiPath, { tagId });
    return response.data;
  };

  return { ...apiSet, execute };
}

export function useRemoveTagFromManualApi(): ApiSet<TagResponse> & {
  execute: (manualId: string, tagId: string) => Promise<Tag | undefined>;
} {
  const apiSet = useDeleteApi<TagResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = async (manualId: string, tagId: string) => {
    const apiPath = `manual/${manualId}/tags/${tagId}`;
    const response = await apiSet.execute(apiPath);
    return response.data;
  };

  return { ...apiSet, execute };
}
