import {
  ApiSet,
  BaseResponse,
  CountsApiResponse,
  IndexApiSet,
  useDeleteApi,
  useDownloadApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import { Issue, IssueForm, IssueSearchForm } from "entities/issue";
import { ID, PagingResponse } from "entities";

export type IssuesResponse = PagingResponse<Issue>;

export function useFetchIssuesApi(
  searchForm?: Form<IssueSearchForm>
): IndexApiSet<IssuesResponse> & { execute: () => void } {
  const apiPath = "issue";
  const api = useIndexApi<IssuesResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type IssueResponse = BaseResponse & {
  data: Issue;
};

export function useFetchIssueApi(): ApiSet<IssueResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<IssueResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `issue/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostIssueApi(): ApiSet<IssueResponse> & {
  execute: (form: Form<IssueForm>) => void;
} {
  const apiSet = usePostApi<IssueResponse, IssueForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<IssueForm>) => {
    const apiPath = `issue`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditIssueApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<IssueForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, IssueForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<IssueForm>) => {
    const apiPath = `issue/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteIssueApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `issue/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export function useSolveIssueApi(): ApiSet<IssueResponse> & {
  execute: (id: ID) => void;
} {
  const apiSet = usePostApi<IssueResponse, IssueForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (id: ID) => {
    const apiPath = `issue/${id}/solved`;
    apiSet.execute(apiPath);
  };

  return { ...apiSet, execute };
}

type CountsIssueApiConfig = {
  givenTimes: string[];
};

export function useCountsIssueApi(
  config: CountsIssueApiConfig
): ApiSet<CountsApiResponse> & {
  execute: () => void;
} {
  const apiPath = `issue/counts`;
  const api = usePostObjectApi<CountsApiResponse, CountsIssueApiConfig>(
    new HttpClient(),
    {
      initialResponse: {
        data: [],
      },
    }
  );

  const execute = (): void => {
    api.execute(apiPath, config);
  };

  return { ...api, execute };
}

export type IssueCountResponse = BaseResponse<number>

export function useFetchIssueCountApi(): ApiSet<IssueCountResponse> & {
  execute: () => void;
} {
  const api = useShowApi<IssueCountResponse>(new HttpClient(), {
    initialResponse: { data: 0 },
  });

  const execute = (): void => {
    const apiPath = `issue_count`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}
