import { Card, Col, Flex, Modal, Radio, Row, Skeleton, Space, message, theme } from 'antd';
import { ExclamationCircleOutlined, CheckOutlined, ScheduleOutlined, FolderOpenOutlined } from "@ant-design/icons";
import Meta from 'antd/es/card/Meta';
import { GlobalStateContext } from 'contexts/global_state_context';
import { CSSProperties, useContext, useEffect, useMemo, useState } from 'react';
import { CustomSelectBlockField } from 'specifics/input';
import { Notification, NotificationEntityType, NotificationStatus, NotificationType } from 'entities/notification';
import { useFetchNotificationsApi, useFinishNotificationApi } from 'api/notification';
import dayjs from 'dayjs';
import { CustomButton } from 'specifics/button';
import { ContractCompletionPage } from 'components/contracts/completion/page';
import { ContractInputRequestModal } from 'components/contracts/input_request/modal';
import { useGenerateTemporaryTokenApi } from 'api/temporary_token';
import { copyToClipboard } from 'utils/util';
import { DeliveryPartnerCompany } from 'entities/delivery_partner_company';
import { useBulkRequestManualsApi } from 'api/manual';
import { Manual } from 'entities/manual';

enum RequestStatus {
  依頼を完了 = 1,
  依頼を取り消し = 2
}

interface NotificationModalProps {
  history: any;
  open: boolean;
  onClose: () => void;
  style: CSSProperties;
}

const NotificationModal = ({ history, open, onClose, style }: NotificationModalProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const [status, setStatus] = useState<NotificationStatus>(NotificationStatus.進行中);
  const [type, setType] = useState<NotificationType>(NotificationType.すべて);
  const [isRequestModalVisible, setIsRequestModalVisible] = useState<boolean>(false);
  const [value, setValue] = useState();
  const notificationsApi = useFetchNotificationsApi();
  const finishNotificationApi = useFinishNotificationApi();
  const generateTokenApi = useGenerateTemporaryTokenApi();
  const bulkRequestManualsApi = useBulkRequestManualsApi();

  const [openModalId, setOpenModalId] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      notificationsApi.execute();
    }
  }, [open]);

  useEffect(() => {
    if (finishNotificationApi.isSuccess()) {
      notificationsApi.execute();
    }
  }, [finishNotificationApi.loading]);

  const notifications = useMemo(() =>
    notificationsApi.response.data.filter(notification =>
      notification.status === status &&
      (notification.type === type || type === NotificationType.すべて)
    )
    , [notificationsApi.isSuccess(), status, type]);

  const manualUpdatedAt = (notification: Notification) => {
    const manuals = notificationsApi.response.data.find(n => n.id === notification.id)?.entity?.manuals;
    if (manuals && manuals.length > 0) {
      return dayjs(manuals.reduce((latest: Manual, current: Manual) => {
        return dayjs(latest.updatedAt).isAfter(dayjs(current.updatedAt)) ? latest : current;
      }).updatedAt).format("YYYY/MM/DD");
    }
    return null;
  };

  const avatar = (notification: Notification) => {
    let iconStyle: CSSProperties = {
      fontSize: 20,
      padding: 10,
      borderRadius: "100%"
    };
    let IconComponent;

    switch (notification.type) {
      case NotificationType.定期フォロー:
        iconStyle = {
          ...iconStyle,
          backgroundColor: token.colorWarningBg,
          color: token.colorWarning,
        };
        IconComponent = <ScheduleOutlined style={iconStyle} />;
        break;
      case NotificationType.回答済み:
        iconStyle = {
          ...iconStyle,
          backgroundColor: token.colorSuccessBg,
          color: token.colorSuccess,
        };
        IconComponent = <CheckOutlined style={iconStyle} />;
        break;
      case NotificationType.未回答:
        iconStyle = {
          ...iconStyle,
          backgroundColor: token.colorErrorBg,
          color: token.colorErrorActive,
        };
        IconComponent = <ExclamationCircleOutlined style={iconStyle} />;
        break;
    }

    return (
      <Flex align='center' gap={8}>
        {notification.status === NotificationStatus.進行中 &&
          <div style={{ padding: 4, borderRadius: "100%", background: token.colorPrimary }}></div>
        }
        {IconComponent}
      </Flex>
    );
  };

  const title = (notification: Notification) => {
    switch (notification.type) {
      case NotificationType.定期フォロー: {
        const followUpPeriodMonths = notification.entity?.followUpPeriodMonths ? `(${notification.entity.followUpPeriodMonths}ヶ月)` : "";
        return `への定期フォローを行なってください。${followUpPeriodMonths}`;
      }
      case NotificationType.回答済み: {
        return "が記入依頼に回答しました。";
      }
      case NotificationType.未回答: {
        return "が記入依頼への回答を完了していません。";
      }
      default: {
        return "通知があります。";
      }
    }
  };

  const description = (notification: Notification) => {
    switch (notification.entityType) {
      case NotificationEntityType.運送会社: {
        return (
          <Flex vertical>
            <Card style={{ cursor: "pointer" }} onClick={() => setOpenModalId(notification.id)} >
              <Flex justify='space-between'>
                {notification.entity.manuals.length ?
                  // 対象カルテの表示
                  <Space direction='vertical' style={{ color: token.colorPrimary, fontSize: 14 }} size={0}>
                    <span style={{ fontWeight: 'bold' }}>{notification.entity.manuals[0].title || ''}</span>
                    {notification.entity.manuals.length > 1 &&
                      <span>ほか{notification.entity.manuals.length - 1}件</span>
                    }
                  </Space> :
                  // 対象カルテが存在しない時
                  <span>対象のカルテが存在しないか削除されています。</span>
                }
                <FolderOpenOutlined style={{ opacity: 0.5, fontSize: 16 }} />
              </Flex>
            </Card>
            {
              notification.type !== NotificationType.回答済み &&
              <Space style={{ marginTop: 10 }}>
                <Modal
                  title={
                    <div>
                      {`${manualUpdatedAt(notification)}に更新済みのカルテが含まれます。`}
                      <br />
                      入力を依頼しますか？
                    </div>
                  }
                  open={isRequestModalVisible}
                  width={400}
                  onCancel={(e) => {
                    setIsRequestModalVisible(false);
                  }}
                  footer={[
                    <CustomButton key={1} onClick={() => setIsRequestModalVisible(false)}>戻る</CustomButton>,
                    <CustomButton
                      type="primary"
                      key={2}
                      disabled={!value}
                      onClick={(e: any) => {
                        if (value === RequestStatus.依頼を完了) {
                          bulkRequestManualsApi.execute({ ids: notification.entity.manuals.map((manual: Manual) => manual.id || ''), deliveryPartnerCompanyId: notification.entityId || '' });
                        }
                        setIsRequestModalVisible(false);
                      }}
                    >
                      終了する
                    </CustomButton>
                  ]}
                  closeIcon={null}
                >
                  <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
                    <Space direction="vertical">
                      {Object.entries(RequestStatus)
                        .filter(([label, value]) => typeof value === "number")
                        .map(([label, value]) =>
                          <Radio key={value} value={value}>
                            {label}
                          </Radio>
                        )}
                    </Space>
                  </Radio.Group>
                </Modal>
                {
                  notification.entity.manuals.length > 0 &&
                  <>
                    <CustomButton
                      style={{ height: 34 }}
                      onClick={() => {
                        setIsRequestModalVisible(true);
                        copyUrls(notification.entity)
                      }}
                    >
                      記入用リンクをコピー
                    </CustomButton>
                    <CustomButton
                      style={{ height: 34 }}
                      type="primary"
                      onClick={() => {
                        setIsRequestModalVisible(true);
                        let mailText = "";
                        let expiresAt = "";
                        switch (notification.type) {
                          case NotificationType.定期フォロー:
                            mailText = globalState.setting.customSetting?.followUpMail || '';
                            expiresAt = dayjs().add(Number(globalState.setting.customSetting?.followUpPeriodMonths), 'day').format('YYYY-MM-DD');
                            break;
                          case NotificationType.未回答:
                            mailText = globalState.setting.customSetting?.reminderMail || '';
                            expiresAt = dayjs().add(Number(globalState.setting.customSetting?.reminderPeriodDays), 'day').format('YYYY-MM-DD');
                            break;
                        }
                        handleMailTo(notification.entity, mailText, expiresAt)
                      }}
                    >
                      依頼メールを作成
                    </CustomButton></>
                }
              </Space>
            }
            {
              openModalId === notification.id ?
                notification.type === NotificationType.回答済み ?
                  <Modal
                    className='notification-view'
                    open={openModalId === notification.id}
                    onCancel={() => setOpenModalId(null)}
                    style={{ top: 20 }}
                    width="100vw"
                    styles={{ body: { padding: 0 } }}
                    footer={null}
                  >
                    <ContractCompletionPage
                      readonly
                      history={history}
                      manuals={notification.entity.manuals}
                      deliveryPartnerCompany={notification.entity}
                    />
                  </Modal> :
                  <ContractInputRequestModal
                    history={history}
                    open={openModalId === notification.id}
                    onCancel={() => setOpenModalId(null)}
                    selectedDeliveryPartnerCompany={notification.entity}
                  /> :
                <></>
            }
          </Flex>
        );
      }
      default: {
        return notification.description;
      }
    }
  };

  const url = async (deliveryPartnerCompanyId?: string, expiresAt?: string) => {
    // 一時トークンを生成
    const res = await generateTokenApi.execute({ expirationOption: globalState.setting.customSetting?.reminderPeriodDays });
    return `${process.env.REACT_APP_ADMIN_HOST}contracts/completion/${deliveryPartnerCompanyId}?token=${res.data.token}&expiresAt=${expiresAt}`
  }

  const copyUrls = async (deliveryPartnerCompany: DeliveryPartnerCompany) => {
    const expiresAt = dayjs().add(Number(globalState.setting.customSetting?.reminderPeriodDays), 'day').format('YYYY-MM-DD');
    const link = await url(deliveryPartnerCompany.id, expiresAt);
    copyToClipboard(link);

    // 成功メッセージを表示
    message.success("クリップボードに記入用リンクを一括コピーしました。");
  };

  const handleMailTo = async (deliveryPartnerCompany: DeliveryPartnerCompany, mailText?: string, expiresAt?: string) => {
    const to = deliveryPartnerCompany.email || '';
    const subject = "納入先での実態記入のご依頼";

    // 一時トークンを生成
    const link = await url(deliveryPartnerCompany.id, expiresAt);
    const body = `${mailText}\n\n${link}\n\nリンクの有効期限：${expiresAt}まで`;

    const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Modal
      mask={false}
      closable={false}
      style={{
        ...style
      }}
      width={600}
      title={<>
        <Flex justify='center' style={{ width: "100%" }}>
          <CustomSelectBlockField
            style={{ display: 'flex', justifyContent: 'center' }}
            itemStyle={{ textAlign: "center", width: 160, fontSize: 14 }}
            selectedColor={token.colorWhite}
            selectItems={Object.entries(NotificationStatus).map(([label, value]) => ({
              label,
              value,
            }))}
            value={status}
            onChange={status => setStatus(status)}
          />
        </Flex>
        <Row style={{ cursor: "pointer", borderBottom: `1px solid ${token.colorBorder}`, gap: 10, marginTop: 10 }}>
          {Object.values(NotificationType).map((value) => (
            <Col key={value} style={{ textAlign: "center", padding: "0 5px" }}>
              <div
                style={{
                  fontWeight: type === value ? 700 : 500,
                  fontSize: "14px",
                  lineHeight: "20px",
                  padding: "4px 8px",
                  color: type === value ? token.colorPrimary : "#6B7280",
                  borderBottom: type === value ? `2px solid ${token.colorPrimary}` : "none",
                }}
                onClick={() => setType(value)}
              >
                {value}
              </div>
            </Col>
          ))}
        </Row>
      </>}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Skeleton loading={notificationsApi.loading}>
        <Flex vertical style={{ height: `calc(${globalState.dimension.height}px - 220px)`, overflowY: 'auto' }}>
          {notifications.length ?
            notifications.map(notification => (
              <Card
                key={notification.id}
                style={{
                  marginTop: 16,
                  borderBottom: `1px solid ${token.colorBorder}`,
                  background: `${token.colorBgBase}`,
                  border: 0,
                  transition: 'background-color 0.3s, opacity 0.3s',
                }}
                bodyStyle={{ padding: "24px 10px 24px 6px" }}
                onMouseEnter={e => {
                  e.currentTarget.style.backgroundColor = token.colorPrimaryBgHover;
                  status === NotificationStatus.進行中 && ((e.currentTarget.querySelector('.complete-button') as HTMLElement).style.opacity = '1');
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.backgroundColor = token.colorBgBase;
                  status === NotificationStatus.進行中 && ((e.currentTarget.querySelector('.complete-button') as HTMLElement).style.opacity = '0');
                }}
              >
                <Meta
                  avatar={avatar(notification)}
                  title={
                    <Row justify="space-between" gutter={0}>
                      <Col span={19}>
                        <Space direction='vertical' size={0} style={{ lineHeight: 1, width: "100%" }}>
                          <span style={{ fontSize: 15, width: "100%", whiteSpace: 'normal' }}>
                            {notification.entity.name}
                            <span style={{ fontWeight: 400 }}>{title(notification)}</span>
                          </span>
                          <span style={{ fontSize: 12, fontWeight: 400, width: "100%", whiteSpace: 'normal' }}>{dayjs(notification.createdAt).format('YYYY/MM/DD')}</span>
                        </Space>
                      </Col>
                      <Col span={5} style={{ textAlign: 'right' }}>
                        {status === NotificationStatus.進行中 &&
                          <CustomButton onClick={() => finishNotificationApi.execute(notification.id)} className='complete-button' type='primary' icon={<CheckOutlined />} style={{ opacity: 0, transition: 'opacity 0.3s', height: 34 }}>完了</CustomButton>
                        }
                      </Col>
                    </Row>
                  }
                  description={description(notification)}
                />
              </Card>
            )) :
            "該当する通知はありません"
          }
        </Flex>
      </Skeleton>
    </Modal >
  );
};

export default NotificationModal;
