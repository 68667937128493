import {
  Alert,
  Button,
  Col,
  Divider,
  Flex,
  Row,
  Space,
  Table,
  theme,
} from "antd";
import { useFetchRotationsApi } from "api/rotation";
import { GlobalStateContext } from "contexts/global_state_context";
import dayjs from "dayjs";
import { Rotation } from "entities/rotation";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import {
  CustomRangeField,
  CustomSelectBlockField,
  LabelView,
} from "specifics/input";
import { CustomPageHeader } from "specifics/page_header";
import { Form, useForm } from "utils/hooks";
import { RotationsAnalysisHandler } from "./rotations_handler";
import { RotationSummariesTable } from "./rotation_summaries_table";
import { RotationsByUserIdTable } from "./rotation_summaries_by_user_id_table";
import { convertToCSV, downloadCSV } from "utils/csv_export";
import { RotationsByDeliveryCompanyIdTable } from "./rotation_summaries_by_delivery_company_id";

type SearchConditionForm = {
  fromDate?: string;
  toDate?: string;
};

const AnalysisDeliveriesPage = () => {
  const { token } = theme.useToken();

  const globalState = useContext(GlobalStateContext);
  const rotationsApi = useFetchRotationsApi();

  enum Mode {
    BY_ROTATION = "BY_ROTATION",
    BY_DRIVER = "BY_DRIVER",
    BY_DELIVERY_COMPANY = "BY_DELIVERY_COMPANY",
  }
  const [mode, setMode] = useState<Mode>(Mode.BY_DRIVER);
  const [rotations, setRotations] = useState<Rotation[]>([]);
  const searchForm = useForm<SearchConditionForm>({
    fromDate: dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    toDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  });

  const fetchRotations = () => {
    const filters = `deliveryDate:${dayjs(searchForm.object.fromDate).format(
      "YYYY-MM-DD"
    )},${dayjs(searchForm.object.toDate).format("YYYY-MM-DD")}`;
    const filterQuery = `?filter=${encodeURIComponent(filters)}`;
    rotationsApi.execute(filterQuery);
  };

  useEffect(() => {
    globalState.setLoading(rotationsApi.loading);
    if (rotationsApi.isSuccess()) {
      setRotations(rotationsApi.response.data || []);
    }
  }, [rotationsApi.loading]);

  const handler = useMemo(() => {
    return new RotationsAnalysisHandler(rotations);
  }, [rotations.length]);

  return (
    <CustomPageHeader
      style={{
        borderBottom: `0px`,
      }}
      title={"配車分析"}
      extra={
        <InputSearchConditionsForm
          form={searchForm}
          onSubmit={fetchRotations}
        />
      }
    >
      {rotations.length ? (
        <Space size={16} direction="vertical" style={{ width: "100%" }}>
          <Flex justify="space-between">
            <CustomSelectBlockField
              selectItems={[
                {
                  label: "ドライバー別サマリ",
                  value: Mode.BY_DRIVER,
                },
                {
                  label: "回転別サマリ",
                  value: Mode.BY_ROTATION,
                },
                {
                  label: "地点別サマリ",
                  value: Mode.BY_DELIVERY_COMPANY,
                },
              ]}
              value={mode}
              onChange={(e: Mode) => setMode(e)}
            />
            <Button
              onClick={() => {
                const csv = convertToCSV(
                  handler.toRotationSummariesByOrderDetailid().map((item) => {
                    const {
                      deliveryCompanyId,
                      deliveryCompany,
                      deletedAt,
                      sortKey,
                      ...rest
                    } = item;
                    return {
                      ...rest,
                      deliveryCompanyName: deliveryCompany.name,
                    };
                  })
                );
                downloadCSV(
                  csv,
                  `knewit_配車サマリ_${searchForm.object.fromDate}-${searchForm.object.toDate}.csv`
                );
              }}
            >
              CSV出力
            </Button>
          </Flex>

          {mode === Mode.BY_DRIVER && (
            <CardView title="ドライバー別サマリ">
              <RotationsByUserIdTable
                rotationSummariesByUserId={handler.toRotationSummariesByUserId()}
              />
            </CardView>
          )}
          {mode === Mode.BY_ROTATION && (
            <CardView title="回転別サマリ">
              <RotationSummariesTable
                rotationsSummaries={handler.toRotationSummaries()}
              />
            </CardView>
          )}
          {mode === Mode.BY_DELIVERY_COMPANY && (
            <CardView title="地点別サマリ">
              <RotationsByDeliveryCompanyIdTable
                rotationSummariesByDeliveryCompanyId={handler.toRotationSummariesByDeliveryCompanyId()}
              />
            </CardView>
          )}
        </Space>
      ) : (
        <Alert message="配送日を指定して検索ボタンを押してください。" />
      )}
    </CustomPageHeader>
  );
};

const InputSearchConditionsForm = ({
  form,
  onSubmit,
}: {
  form: Form<SearchConditionForm>;
  onSubmit: () => void;
}) => {
  return (
    <Space>
      <LabelView label="配送日" />

      <CustomRangeField
        form={form}
        attr={"fromDate"}
        fieldProps={{
          value: [dayjs(form.object.fromDate), dayjs(form.object.toDate)],
        }}
        onChange={([from, to]) => {
          form.update((f) => {
            f.fromDate = from;
            f.toDate = to;
          });
        }}
      />
      <Button block type="primary" onClick={onSubmit}>
        検索
      </Button>
    </Space>
  );
};

export default withRouter(AnalysisDeliveriesPage);

const CardView = ({
  title,
  extra,
  children,
}: {
  title: string;
  children: ReactNode;
  extra?: ReactNode;
}) => {
  const { token } = theme.useToken();
  return (
    <Space
      size={16}
      style={{
        width: "100%",
        background: token.colorWhite,
        borderRadius: 16,
        padding: 12,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
      }}
      direction="vertical"
    >
      <Flex justify="space-between" style={{ width: "100%" }}>
        <div style={{ fontWeight: 700, fontSize: 20 }}>{title}</div>
        {extra}
      </Flex>
      {children}
    </Space>
  );
};
