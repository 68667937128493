import { Space, Table, Upload, theme } from "antd";
import {
  useDeepCopyCompanyApi,
  useDeleteCompanyApi,
  useFetchAllCompaniesApi,
} from "api/company";
import { GlobalStateContext } from "contexts/global_state_context";

import { Company, CompanySearchForm } from "entities/company";
import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton, UploadCSVFileButton } from "specifics/button";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { CompaniesTable } from "../companies_table";
import { ID } from "entities";
import { SuperAdminCompanyCsvImportModal } from "./super_admin_company_csv_import_modal";

const SuperAdminCompaniesPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchAllCompaniesApi();
  const searchForm = useForm<CompanySearchForm>({});
  const deleteApi = useDeleteCompanyApi();
  const copyCompanyApi = useDeepCopyCompanyApi();

  useEffect(() => {
    globalState.setLoading(copyCompanyApi.loading);
    if (copyCompanyApi.isSuccess()) {
      listApi.execute();
    }
  }, [copyCompanyApi.loading]);

  const [importCSVModalOpen, setImportCsvModalopen] = useState(false);

  useEffect(() => {
    if (!importCSVModalOpen) {
      listApi.execute();
    }
  }, [importCSVModalOpen]);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      extra={
        <UploadCSVFileButton onClick={() => setImportCsvModalopen(true)} />
      }
      title="客先"
    >
      <CompaniesTable
        onDelete={(id: ID) => deleteApi.execute(id)}
        history={props.history}
        dataSource={listApi.response.data || []}
        onDeepCopy={(id: ID) => copyCompanyApi.execute(id)}
      />
      <SuperAdminCompanyCsvImportModal
        open={importCSVModalOpen}
        onCancel={(e) => setImportCsvModalopen(false)}
      />
    </CustomPageHeader>
  );
};

export default withRouter(SuperAdminCompaniesPage);
