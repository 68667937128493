// utils/network/api_hooks.ts
import { ApiSet, BaseResponse, usePostObjectApi, useShowApi } from "utils/network/api_hooks";
import { HttpClient } from "../utils/network/axios";
import { Setting, SettingForm } from "entities/setting";

export type SettingResponse = BaseResponse & {
  data: Setting;
};

export function useFetchSettingApi(): ApiSet<SettingResponse> & {
  execute: () => void;
} {
  const api = useShowApi<SettingResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (): void => {
    const apiPath = `setting`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}


export function useEditSettingApi(): ApiSet<SettingResponse> & {
  execute: (form: SettingForm) => void;
} {
  const apiSet = usePostObjectApi<SettingResponse, SettingForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (setting: SettingForm) => {
    const apiPath = `setting`;
    apiSet.execute(apiPath, setting);
  };

  return { ...apiSet, execute };
}