import type { ModalProps } from "antd";
import { Badge, Calendar, Modal } from "antd";
import type { Dayjs } from "dayjs";
import dayjs from 'dayjs'
import type { CellRenderInfo } from "rc-picker/lib/interface";
import { Delivery } from "entities/delivery";
import { useState } from "react";
import _ from "lodash";
import { CustomButton } from "specifics/button";
import { Order } from "entities/order";

export const DeliveriesCalendar = ({
  selectedDate,
  setSelectedDate,
  deliveries,
  getDateRepresentingDelivery,
  ...modalProps
}: {
  selectedDate?: Date;
  setSelectedDate: (date: Date) => void;
  deliveries: Order[];
  getDateRepresentingDelivery: (item: Order) => string;
} & ModalProps) => {
  const [open, setOpen] = useState(false);

  const dateCellRender = (value: Dayjs) => {
    return (
      <ul className="events">
        {deliveries
          ?.filter(
            (item) =>
              getDateRepresentingDelivery(item) === value.format("YYYY/MM/DD")
          )
          .map((item: Delivery) => (
            <li key={item.id}>
              <Badge status={"success"} text={item.order?.orderNo ?? "--"} />
            </li>
          ))}
      </ul>
    );
  };

  const cellRender = (current: Dayjs, info: CellRenderInfo<Dayjs>) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return <></>;
    return info.originNode;
  };

  const handleComplete = (e: any) => {
    modalProps.onOk && modalProps.onOk(e);
    setOpen(false);
  };

  return (
    <>
      <Modal
        width={1000}
        style={{ top: 8 }}
        title={`選択した積み日: ${dayjs(selectedDate).format("YYYY/MM/DD")}`}
        footer={
          <CustomButton type="primary" onClick={handleComplete}>
            選択して閉じる
          </CustomButton>
        }
        open={open}
        onCancel={handleComplete}
        bodyStyle={{ height: window.innerHeight - 150, overflowY: "scroll" }}
        {...modalProps}
      >
        <style>
          {`
          .events {
          margin: 0;
          padding: 0;
          list-style: none;
          }
          .events .ant-badge-status {
            width: 100%;
            overflow: hidden;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .notes-month {
            font-size: 28px;
            text-align: center;
          }
          .notes-month section {
            font-size: 28px;
          }
        `}
        </style>
        <Calendar
          onChange={(date) => setSelectedDate(date.toDate())}
          defaultValue={dayjs(selectedDate)}
          style={{ padding: 16 }}
          cellRender={cellRender}
        />
      </Modal>
      <CustomButton onClick={() => setOpen(true)}>
        カレンダーで積み日を選択
      </CustomButton>
    </>
  );
};
