import { Space, Typography, theme } from "antd";
import { withRouter } from "react-router";
import { MdArrowForwardIos } from "react-icons/md";

import { CustomTag } from "specifics/tag";
import dayjs from "dayjs";
import { DriversHeader } from "specifics/drivers_header";
import { HistoryProps } from "routes/app";
import { GroupedList } from "specifics/grouped_list";
import { Issue } from "entities/issue";
import { useContext, useEffect } from "react";

import { useFetchIssuesApi } from "api/issue";
import { useEffectSkipFirst } from "utils/hooks";
import { GlobalStateContext } from "contexts/global_state_context";

import { MdLockOutline } from "react-icons/md";
import { useMedia } from "use-media";

const { Text } = Typography;

const DriversDeliversPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  const isMobile = useMedia("(max-width: 519px)");
  const listApi = useFetchIssuesApi();
  useEffect(() => {
    listApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        background: token.colorBgBase,
        minHeight: "100vh",
      }}
    >
      <DriversHeader
        title="報告一覧"
        onBack={() => {
          props.history.push("/drivers/delivers");
        }}
      />

      <Space
        size={16}
        direction="vertical"
        style={{ padding: 16, paddingTop: 48 }}
      >
        {listApi.response.data?.length === 0 ? (
          <Space
            size={32}
            direction="vertical"
            style={{ textAlign: "center", marginTop: 40 }}
          >
            <Space
              style={{
                marginTop: 8,
                textAlign: "center",
                width: globalState.dimension.width - 32,
              }}
              size={24}
              direction="vertical"
            >
              <MdLockOutline
                style={{ height: 64, width: 64, color: "#9CA3AF" }}
              />
              <Space direction="vertical" size={13}>
                <div style={{ fontWeight: 700, fontSize: 16, color: "#000" }}>
                  報告がありません
                </div>
                <div
                  style={{
                    fontSize: 16,
                    color: "#6B7280",
                    padding: "0px 8px 0px 8px",
                  }}
                >
                  納入カルテ詳細ページから報告できます。
                </div>
              </Space>
            </Space>
          </Space>
        ) : (
          <GroupedList
            style={{
              background: "#FFFFFF",
              border: "1px solid #E5E7EB",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "6px",
              width: "calc(100vw - 32px)",
            }}
            data={listApi.response.data || []}
            getGroup={(issue: Issue) =>
              dayjs(issue.createdAt).format("YYYY年MM月DD日")
            }
            recordView={(record) => (
              <Space
                size={4}
                style={{
                  justifyContent: "space-between",
                  width: "calc(100vw - 64px)",
                }}
                onClick={() =>
                  props.history.push(`/drivers/issues/${record.id}`)
                }
              >
                <Space style={{ width: "100%" }} size={0} direction="vertical">
                  <Space>
                    <Text style={{ width: 180, color: "#6b7280" }} ellipsis>
                      {record.deliveryCompany?.name}
                    </Text>
                    {!record.isSolved && (
                      <CustomTag
                        style={{
                          width: 48,
                          height: 20,
                          padding: "2px 6px",
                          color: "#EF4444",
                          background: "#FEF2F2",
                          fontSize: "12px",
                          fontWeight: 600,
                          lineHeight: "16px",
                        }}
                      >
                        未対応
                      </CustomTag>
                    )}
                  </Space>
                  <Space size={16} direction="vertical">
                    <CustomTag
                      style={{
                        height: 20,
                        padding: "2px 6px",
                        fontSize: "12px",
                        lineHeight: "16px",
                      }}
                    >
                      {record?.category}
                    </CustomTag>
                  </Space>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#6B7280",
                    }}
                  >
                    {record.deliveryCompany?.address}
                  </div>
                </Space>
                <MdArrowForwardIos color="#9CA3AF" />
              </Space>
            )}
          />
        )}
      </Space>
    </Space>
  );
};

export default withRouter(DriversDeliversPage);

// delivery_swap

// {
//   ids: [
//     {
//       id: 1,
//       no: 0,
//     },
//     {
//       id: 2,
//       no: 1,
//     },
//   ];
// }
