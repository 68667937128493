import {
  Button,
  Modal,
  ModalProps,
  Popconfirm,
  Row,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Tag as AntdTag, Col, Alert } from "antd";
import { InputField } from "components/shared/input";
import { Form } from "utils/hooks";
import { Tag, TagForm } from "entities/tag";

export const TagsFormModal = ({
  tagsForm,
  onStoreTag,
  onUpdateTag,
  onDeleteTag,
  ...modalProps
}: {
  tagsForm: Form<{ tags: Tag[] }>;
  onStoreTag: (createTagInput: TagForm) => void;
  onUpdateTag: (updateTagInput: TagForm) => void;
  onDeleteTag: (tagId: string) => void;
} & ModalProps) => {
  return (
    <Modal title="タグ管理" {...modalProps} footer={null}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {tagsForm.object.tags?.length ? (
          tagsForm.object.tags?.map((tag, index) => {
            return (
              <TagItemForm
                form={tagsForm}
                index={index}
                key={`course-tags-form-${index}`}
                onUpdateTag={onUpdateTag}
                onDeleteTag={onDeleteTag}
              />
            );
          })
        ) : (
          <Alert message="タグは登録されていません" />
        )}
        <CreateTagButton form={tagsForm} onStoreTag={onStoreTag} />
      </Space>
    </Modal>
  );
};

const CreateTagButton = ({
  onStoreTag,
  form,
}: {
  onStoreTag: (createTagInput: TagForm) => void;
  form: Form<{ tags: Tag[] }>;
}) => {
  const createTagInput: TagForm = {
    name: "",
  };
  return (
    <Button
      // disabled={form.object.tags?.some((tag) => !tag?.name?.length)}
      onClick={() => {
        onStoreTag(createTagInput);
      }}
      style={{ width: "100%" }}
    >
      +
    </Button>
  );
};

const TagItemForm = ({
  index,
  form,
  onUpdateTag,
  onDeleteTag,
}: {
  index: number;
  form: Form<{ tags: Tag[] }>;

  onUpdateTag: (updateTagInput: TagForm) => void;
  onDeleteTag: (tagId: string) => void;
}) => {
  const [canEdit, setCanEdit] = useState(false);
  const attr = ["tags", index];
  const currentTag = form.getValue(attr) as Tag;
  const isEmptyTag = () => !currentTag?.name?.length;

  useEffect(() => {
    if (isEmptyTag()) {
      setCanEdit(true);
    }
  }, [isEmptyTag()]);
  return (
    <Row gutter={8}>
      <Col span={22}>
        {canEdit ? (
          <InputField
            fieldProps={{
              onBlur: () => {
                onUpdateTag(currentTag);
                if (currentTag.name?.length) {
                  setCanEdit(false);
                }
              },
            }}
            form={form}
            attr={[...attr, "name"]}
          />
        ) : (
          <AntdTag color="cyan" onClick={() => setCanEdit(true)}>
            {(form.getValue(attr) as Tag)?.name}
          </AntdTag>
        )}
      </Col>
      <Col span={2}>
        <Popconfirm
          icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
          title="タグの削除"
          description="この処理は取り消せません。本当に宜しいですか？"
          onConfirm={() => {
            if (currentTag.id) {
              onDeleteTag(currentTag.id);
            }
          }}
          okText="はい"
          cancelText="いいえ"
          okButtonProps={{ type: "dashed" }}
          cancelButtonProps={{ type: "primary" }}
        >
          <Button type="text" danger shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Col>
    </Row>
  );
};
