import { Popover, Space, theme } from "antd";
import { ReactNode, useState } from "react";
import { MdArrowBackIos, MdMoreVert } from "react-icons/md";

export type HeaderProps = {
  title: ReactNode;
  onBack?: () => void;
  navMenu?: ReactNode;
};

export const DriversHeader = (props: HeaderProps) => {
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  return (
    <Space
      style={{
        height: 48,
        width: "100vw",
        textAlign: "center",
        alignItems: "center",
        boxShadow:
          "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
        position: "fixed",
        top: 0,
        zIndex: 1000,
        background: token.colorWhite,
      }}
      size={0}
    >
      {props.onBack && (
        <div
          style={{
            padding: "12px 16px",
            color: "#9CA3AF",
            cursor: "pointer",
          }}
        >
          <MdArrowBackIos
            size={24}
            style={{ verticalAlign: "-4" }}
            onClick={() => {
              if (props.onBack) {
                props.onBack();
              }
            }}
          />
        </div>
      )}
      <div
        style={{
          paddingTop: "14.5px",
          paddingBottom: "14.5px",
          lineHeight: "19px",
          fontWeight: 700,
          fontSize: 16,
          alignItems: "center",
          width: props.onBack ? "calc(100vw - 112px)" : "100vw",
        }}
      >
        {props.title}
      </div>
      {props.navMenu && (
        <Popover
          open={open}
          onOpenChange={handleOpenChange}
          content={<div onClick={() => setOpen(false)}>{props.navMenu}</div>}
          trigger="click"
          overlayInnerStyle={{ background: token.colorWhite, marginRight: 5, padding: 3 }}
        >
          <MdMoreVert size={24} style={{ cursor: 'pointer', color: "#9CA3AF", padding: '12px 16px', verticalAlign: "-4", transform: 'rotate(90deg)' }} />
        </Popover>
      )}
    </Space>
  );
};
