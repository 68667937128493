import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useDownloadApi,
  useIndexApi,
  usePostApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import {
  CharteredVehicle,
  CharteredVehicleForm,
  CharteredVehicleSearchForm,
} from "entities/chartered_vehicle";
import { ID, PagingResponse } from "entities";

export type CharteredVehiclesResponse = PagingResponse<CharteredVehicle>;

export function useFetchCharteredVehiclesApi(
  searchForm?: Form<CharteredVehicleSearchForm>
): IndexApiSet<CharteredVehiclesResponse> & { execute: () => void } {
  const apiPath = "chartered_vehicle";
  const api = useIndexApi<CharteredVehiclesResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type CharteredVehicleResponse = BaseResponse & {
  data: CharteredVehicle;
};

export function useFetchCharteredVehicleApi(): ApiSet<CharteredVehicleResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<CharteredVehicleResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `chartered_vehicle/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostCharteredVehicleApi(): ApiSet<CharteredVehicleResponse> & {
  execute: (form: Form<CharteredVehicleForm>) => void;
} {
  const apiSet = usePostApi<CharteredVehicleResponse, CharteredVehicleForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<CharteredVehicleForm>) => {
    const apiPath = `chartered_vehicle`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditCharteredVehicleApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<CharteredVehicleForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, CharteredVehicleForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<CharteredVehicleForm>) => {
    const apiPath = `chartered_vehicle/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteCharteredVehicleApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `chartered_vehicle/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}
