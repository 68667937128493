import { BaseEntity, BaseSearchForm, ID } from ".";
import { Consignor } from "./consignor";
import { DeliveryCompany } from "./delivery_company";
import { Order, OrderDetail, OrderDivided } from "./order";
import { User } from "./user";
import { Vehicle } from "./vehicle";

export const DeliveryCategories = ["スポット", "常用"] as const;
export type DeliveryType = 0 | 1;

export const DeliveryCharterTypes = [0, 1] as const;
export type DeliveryCharterType = (typeof DeliveryCharterTypes)[number];

export type OrderPointType = 1 | 2;
export const OrderPointTypes: Record<OrderPointType, string> = {
  1: "降し",
  2: "積み",
};

export type Delivery = BaseEntity & {
  orderId?: ID;
  order?: Order;
  charterType?: 0 | 1;
  vehicleId?: ID;
  user?: User;
  userId?: ID;
  charteredCompanyId?: ID;
  memo?: string;
  deliveryDetails?: DeliveryDetail[];
};

export type DeliveryDetail = BaseEntity & {
  flag?: "u" | "i" | "d";
  deliveryId?: ID;
  delivery?: Delivery;
  pointType?: OrderPointType; // 1:降し先、2:積み先
  no?: number;
  deliveryCompanyId?: string;
  deliveryCompany?: DeliveryCompany;
  loadWeight?: number;
  deliveryDate?: string;
};

export type DeliveryForm = Delivery;

export type DeliverySearchForm = BaseSearchForm & Delivery;

export function calculateTotalLoadWeight(
  divideds: OrderDivided[],
  deliveryDetailSelectFunction?: (orderDetail: OrderDetail) => boolean
): number {
  let totalLoadWeight = 0;

  divideds.forEach((divided) => {
    if (divided.orderDetails) {
      divided.orderDetails.forEach((detail) => {
        if (
          !deliveryDetailSelectFunction ||
          deliveryDetailSelectFunction(detail)
        ) {
          totalLoadWeight += Number(detail.loadWeight) || 0;
        }
      });
    }
  });

  return totalLoadWeight;
}
