import { Button, Modal, QRCode, QRCodeProps, Space, message } from "antd";
import { useState } from "react";
import html2canvas from "html2canvas";

export const QRCodeModalButton = ({
  text,
  pngName,
  QRCodeProps,
}: {
  text: string;
  pngName?: string;
  QRCodeProps?: QRCodeProps;
}) => {
  const [open, setOpen] = useState(false);
  const saveQRCodeAsImage = () => {
    // QRコードを含む要素を取得
    const qrcodeElement = document.getElementById("qrcode");

    // html2canvasを使用して要素をCanvasに描画
    if (qrcodeElement) {
      html2canvas(qrcodeElement).then((canvas) => {
        // Canvasを画像として保存
        const imageData = canvas.toDataURL("image/png"); // PNG形式で保存
        const link = document.createElement("a");
        link.download = pngName ?? "qrcode.png";
        link.href = imageData;
        link.click();
      });
    }
  };
  return (
    <>
      <span
        onClick={() => setOpen(true)}
      >
        QRコードを表示
      </span>
      <Modal
        width={270}
        onCancel={() => setOpen(false)}
        open={open}
        title="QRコード"
        bodyStyle={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          width: "100%",
          gap: 8,
        }}
        footer={null}
      >
        <div
          id="qrcode"
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <QRCode value={text || "-"} {...QRCodeProps} />
        </div>
        <Button
          key={"saveImage"}
          onClick={() => {
            saveQRCodeAsImage();
            message.success("QRコードを保存しました");
            setOpen(false);
          }}
          type="primary"
        >
          QRコードを画像として保存
        </Button>
      </Modal>
    </>
  );
};
