import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useIndexApi,
  usePostObjectApi,
} from "utils/network/api_hooks";
import { HttpClient } from "../utils/network/axios";

import { PagingResponse } from "entities";
import { Contract, GraphContract } from "entities/contract";
import { Manual } from "entities/manual";

export type ContractsResponse = PagingResponse<Contract>;
export type GraphContractsResponse = BaseResponse<GraphContract>

export const useFetchAllContractsApi =
  (): IndexApiSet<ContractsResponse> & { execute: (query?: string) => void } => {
    const apiPath = "contract/all";
    const api = useIndexApi<ContractsResponse>(new HttpClient(), {
      initialResponse: {
        data: [],
        links: {
          first: "",
          last: "",
          prev: null,
          next: null,
        },
        meta: {
          currentPage: 0,
          from: 0,
          lastPage: 0,
          path: "",
          perPage: 0,
          to: 0,
          total: 0,
        },
      },
      initialState: {
        page: 1,
        perPage: 100000000,
      },
    });

    const execute = (query?: string): void => {
      api.execute(apiPath + (query ? query : ""));
    };

    return { ...api, execute };
  }

export const useFetchMismatchContractsApi =
  (): IndexApiSet<ContractsResponse> & { execute: (query?: string) => void } => {
    const apiPath = "contract/mismatch";
    const api = useIndexApi<ContractsResponse>(new HttpClient(), {
      initialResponse: {
        data: [],
        links: {
          first: "",
          last: "",
          prev: null,
          next: null,
        },
        meta: {
          currentPage: 0,
          from: 0,
          lastPage: 0,
          path: "",
          perPage: 0,
          to: 0,
          total: 0,
        },
      },
      initialState: {
        page: 1,
        perPage: 100000000,
      },
    });

    const execute = (query?: string): void => {
      api.execute(apiPath + (query ? query : ""));
    };

    return { ...api, execute };
  }

export const useFetchUnfilledContractsApi =
  (): IndexApiSet<ContractsResponse> & { execute: (query?: string) => void } => {
    const apiPath = "contract/unfilled";
    const api = useIndexApi<ContractsResponse>(new HttpClient(), {
      initialResponse: {
        data: [],
        links: {
          first: "",
          last: "",
          prev: null,
          next: null,
        },
        meta: {
          currentPage: 0,
          from: 0,
          lastPage: 0,
          path: "",
          perPage: 0,
          to: 0,
          total: 0,
        },
      },
      initialState: {
        page: 1,
        perPage: 100000000,
      },
    });

    const execute = (query?: string): void => {
      api.execute(apiPath + (query ? query : ""));
    };

    return { ...api, execute };
  }

export const useFetchGraphContractsApi =
  (): IndexApiSet<GraphContractsResponse> & { execute: (query: string) => void } => {
    const apiPath = "contract/graph";
    const api = useIndexApi<GraphContractsResponse>(new HttpClient(), {
      initialResponse: {
      },
      initialState: {
      },
    });

    const execute = (query: string): void => {
      api.execute(apiPath + query);
    };

    return { ...api, execute };
  }

export function useBulkUpdateContractsApi(): ApiSet<BaseResponse> & {
  execute: (manuals: Manual[], deliveryPartnerCompanyId: string) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, { contracts: Manual[], deliveryPartnerCompanyId: string }>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (manuals: Manual[], deliveryPartnerCompanyId: string) => {
    const apiPath = `contract/bulk_update`;
    apiSet.execute(apiPath, { contracts: manuals, deliveryPartnerCompanyId });
  };

  return { ...apiSet, execute };
}