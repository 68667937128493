import { Divider, Space, theme } from "antd";
import { useDeleteConsignorApi, useFetchConsignorApi } from "api/consignor";
import { GlobalStateContext } from "contexts/global_state_context";

import { Consignor } from "entities/consignor";
import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { DeliveryTermView } from "components/delivery_companies/delivery_companies_view";

const ConsignorPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const consignorApi = useFetchConsignorApi();
  const params = useParams<{ id: string }>();
  const consignorForm = useForm<Consignor>({});
  useEffect(() => {
    consignorApi.execute(params.id);
  }, []);

  const deleteApi = useDeleteConsignorApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      props.history.push("/operations/consignors");
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(consignorApi.loading);
    if (consignorApi.isSuccess()) {
      consignorForm.set(consignorApi.response.data);
    }
  }, [consignorApi.loading]);

  const consignor = () => {
    return consignorApi.response.data;
  };

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="荷主詳細"
      onBack={() => props.history.goBack()}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent>
        <Space
          style={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <Space direction="vertical" size={24}>
            <Space direction="horizontal" size={24}>
              <DescriptionBlock label="名称">
                {consignor()?.name}
              </DescriptionBlock>
              <DescriptionBlock label="ふりがな">
                {consignor()?.kana}
              </DescriptionBlock>
            </Space>
            <DescriptionBlock label="郵便番号">
              {consignor()?.zipcode}
            </DescriptionBlock>
            <DescriptionBlock label="住所">
              {consignor()?.address}
            </DescriptionBlock>
            <DescriptionBlock label="荷渡し条件">
              {DeliveryTermView(consignor().deliveryTerm)}
            </DescriptionBlock>
            <DescriptionBlock label="備考">
              {consignor()?.memo}
            </DescriptionBlock>
          </Space>
          <Space size={24} style={{ verticalAlign: "top", height: "100%" }}>
            <CustomButton
              style={{
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() =>
                props.history.push(
                  `/operations/consignors/${consignor()?.id}/edit`
                )
              }
            >
              編集
            </CustomButton>
            <CustomButton
              danger
              type="text"
              style={{
                fontSize: 14,
                fontWeight: 400,
                padding: 0,
              }}
              confirm
              popconfirmProps={{
                title: "削除します。よろしいですか？",
              }}
              onClick={() => {
                deleteApi.execute(params.id);
              }}
            >
              削除
            </CustomButton>
          </Space>
        </Space>
        <Divider />
        <Space
          style={{
            width: "100%",
          }}
          direction="horizontal"
          size={24}
        >
          <DescriptionBlock label="最終更新" style={{ width: 235.86 }}>
            {dayjs(consignor()?.updatedAt).format("YYYY/MM/DD")}
          </DescriptionBlock>
          <DescriptionBlock label="担当者氏名" style={{ width: 235.86 }}>
            {consignor()?.repName}
          </DescriptionBlock>
          <DescriptionBlock label="電話番号" style={{ width: 235.86 }}>
            {consignor()?.repTel}
          </DescriptionBlock>
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(ConsignorPage);
