import { Space } from "antd";
import { BaseEntity } from "entities";
import { CSSProperties } from "react";

const groupBy = <T extends any>(
  array: readonly T[],
  prop: (v: T) => string
): Record<string, T[]> => {
  return array.reduce((groups: { [key: string]: T[] }, item) => {
    const val = prop(item);
    groups[val] = groups[val] ?? [];
    groups[val].push(item);
    return groups;
  }, {});
};

type GroupedListProps<T> = {
  data: T[];
  getGroup: (record: T) => string;
  recordView: (group: T) => JSX.Element;
  onClickRecord?: (record: T) => void;
  style?: CSSProperties;
  recordStyle?: CSSProperties;
  groupStyle?: CSSProperties;
};

export const GroupedList = <T extends any>(props: GroupedListProps<T>) => {
  const groupedData = groupBy(props.data, props.getGroup);
  return (
    <Space
      size={0}
      direction="vertical"
      style={{ width: "100%", ...props.style }}
    >
      {Object.keys(groupedData).sort((a, b) => a > b ? 1 : -1).map((group: string) => {
        return (
          <Space
            style={{ width: "100%" }}
            size={0}
            direction="vertical"
            key={group}
          >
            <Space
              style={{
                width: "calc(100% - 32px)",
                padding: "4px 16px",
                background: "#E5E7EB",
                fontSize: "12px",
                fontWeight: "16px",
                ...props.groupStyle,
              }}
            >
              {group}
            </Space>
            <Space direction="vertical" size={0} style={{ width: "100%", cursor: "pointer" }}>
              {groupedData[group].map((record, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: "calc(100% - 32px)",
                      padding: "12px 16px",
                      borderTop: "1px solid #E5E7EB",
                      ...props.recordStyle,
                    }}
                  >
                    {props.recordView(record)}
                  </div>
                );
              })}
            </Space>
          </Space>
        );
      })}
    </Space>
  );
};
