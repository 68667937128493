import dayjs from "dayjs";
import { ID } from "entities";
import React from "react";

export const AnalysisBaseSearchFormDatetimeFormat = "YYYY-MM" as const;

type AnalysisBaseSearchForm = {
  datetime: string;
  setDatetime: React.Dispatch<React.SetStateAction<string>>;
};

export const defaultValue: AnalysisBaseSearchForm = {
  datetime: dayjs().endOf("month").format(),
  setDatetime: () => {},
};

export const AnalysisSearchContext = React.createContext(defaultValue);
