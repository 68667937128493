import { Space, theme } from "antd";
import { useFetchIssueApi } from "api/issue";
import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { DescriptionBlock } from "specifics/description";
import { DriversHeader } from "specifics/drivers_header";
import { CustomPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { CustomTag } from "specifics/tag";

const IssuePage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const params = useParams<{ id: string }>();

  const globalState = useContext(GlobalStateContext);

  const issueApi = useFetchIssueApi();
  useEffect(() => {
    issueApi.execute(params.id);
  }, []);
  useEffect(() => {
    globalState.setLoading(issueApi.loading);
  }, [issueApi.loading]);
  const issue = () => issueApi.response.data;

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="報告詳細"
      onBack={() => props.history.push("/operations/issues")}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent>
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <DescriptionBlock label="報告内容">
            <CustomTag style={{ borderRadius: "4px" }}>
              {issue()?.category}
            </CustomTag>
            {!issue()?.isSolved && (
              <CustomTag
                style={{
                  width: 48,
                  height: 20,
                  padding: "2px 6px",
                  color: "#EF4444",
                  background: "#FEF2F2",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "16px",
                }}
              >
                未対応
              </CustomTag>
            )}
          </DescriptionBlock>
          <DescriptionBlock label="送付先">
            {issue()?.toUser?.name}
          </DescriptionBlock>
          <DescriptionBlock label="説明">
            {issue()?.comment}
          </DescriptionBlock>
          <DescriptionBlock label="画像">
            {issue().img ?
              <img
                alt="Preview"
                style={{ width: "100%" }}
                src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${issue().img}`}
              /> :
              "--"
            }
          </DescriptionBlock>
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(IssuePage);

