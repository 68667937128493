import { Skeleton, Space, theme } from "antd";
import {
  useDeleteDeliveryCompanyApi,
  useFetchDeliveryCompanyApi,
} from "api/delivery_company";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  DeliveryCompany
} from "entities/delivery_company";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, withRouter } from "react-router";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst } from "utils/hooks";
import { HistoryProps } from "routes/app";
import { CustomShadowedContent } from "specifics/shadowed_content";
import dayjs from "dayjs";
import { GoogleMapComponent } from "utils/google_map_component";
import { DeliveryCompanyTypeView } from "./delivery_companies_view";
import { useReactToPrint } from 'react-to-print';
import "components/../../public/printStyles.css";
import { TotalTime, AcceptanceTime, WaitingTime, HandlingTerm, DeliveryTerm, ReceiptHandOverTime } from "entities/contract";

const DeliveryCompanyPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const deliveryCompanyApi = useFetchDeliveryCompanyApi();
  const params = useParams<{ id: string }>();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "行先詳細"
  });

  useEffect(() => {
    deliveryCompanyApi.execute(params.id);
    // (async () => {
    //   const result = await getLL("岐阜県岐阜市");
    // })();
  }, []);

  const { token } = theme.useToken();
  const deleteApi = useDeleteDeliveryCompanyApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      props.history.push("/operations/delivery-companies");
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deliveryCompanyApi.loading);
  }, [deliveryCompanyApi.loading]);

  const [selected, setSelcted] = useState<DeliveryCompany>({});
  const deliveryCompany = (): DeliveryCompany => {
    return selected?.id ? selected : deliveryCompanyApi.response.data;
  };

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="行先詳細"
      onBack={() => props.history.goBack()}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent>
        <Space direction="vertical" size={24} ref={componentRef}>
          <Space
            style={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            <Space>
              <DescriptionBlock label="種別">
                {DeliveryCompanyTypeView(deliveryCompany().type)}
              </DescriptionBlock>
              <DescriptionBlock label="名称">
                {deliveryCompany()?.name}
              </DescriptionBlock>
              {deliveryCompany()?.point?.memo &&
                <DescriptionBlock label="地点">
                  {deliveryCompany()?.point?.memo}
                </DescriptionBlock>
              }
            </Space>
            <Space size={24} style={{ verticalAlign: "top", height: "100%" }}>
              <CustomButton
                className="invisible-on-print"
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                onClick={handlePrint}>
                印刷
              </CustomButton>
              <CustomButton
                className="invisible-on-print"
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                onClick={() =>
                  props.history.push(
                    `/operations/delivery-companies/${deliveryCompanyApi.response.data?.id
                    }/edit`
                  )
                }
              >
                編集
              </CustomButton>
              <CustomButton
                className="invisible-on-print"
                danger
                type="text"
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  padding: 0,
                }}
                confirm
                popconfirmProps={{
                  title: "すでにカルテが作成されている行先については、紐づくカルテが削除されます。よろしいですか？"
                }}
                onClick={() => {
                  deleteApi.execute(params.id);
                }}
              >
                削除
              </CustomButton>
            </Space>
          </Space>
          {!(Object.keys(selected).length > 0) && <DescriptionBlock label="ふりがな">
            {deliveryCompany()?.kana}
          </DescriptionBlock>}

          <DescriptionBlock label="受入可能時間">
            {deliveryCompany()?.availableHours}
          </DescriptionBlock>
          <DescriptionBlock label="受入可能車両">
            {deliveryCompany()?.maxWeight}t
          </DescriptionBlock>
          <DescriptionBlock label="担当運送会社">
            {deliveryCompany()?.deliveryPartnerCompanies?.map(company =>
              <div key={company.id}>{company.name}</div>
            )}
          </DescriptionBlock>
          <DescriptionBlock label="入門〜出門までの所要時間">
            {TotalTime[deliveryCompany().totalTime as TotalTime] ?? "--"}
          </DescriptionBlock>
          <DescriptionBlock label="受入の所要時間">
            {AcceptanceTime[deliveryCompany().acceptanceTime as AcceptanceTime] ?? "--"}
          </DescriptionBlock>
          <DescriptionBlock label="バース待ち時間">
            {WaitingTime[deliveryCompany().waitingTimeForBerth as WaitingTime] ?? "--"}
          </DescriptionBlock>
          <DescriptionBlock label="荷役待ち時間">
            {WaitingTime[deliveryCompany().waitingTimeForHandling as WaitingTime] ?? "--"}
          </DescriptionBlock>
          <DescriptionBlock label="納品書/受領書の受け渡しの待ち時間">
            {WaitingTime[deliveryCompany().waitingTimeForReceipt as ReceiptHandOverTime] ?? "--"}
          </DescriptionBlock>
          <DescriptionBlock label="荷役">
            {deliveryCompany()?.handlingTerm !== undefined
              ? HandlingTerm[
              deliveryCompany()?.handlingTerm as HandlingTerm
              ]
              : "--"}
          </DescriptionBlock>
          <DescriptionBlock label="荷渡し条件">
            {deliveryCompany()?.deliveryTerm != undefined
              ? DeliveryTerm[
              deliveryCompany()?.deliveryTerm as DeliveryTerm
              ]
              : "--"}
          </DescriptionBlock>
          <DescriptionBlock label="その他の荷渡し条件">
            {deliveryCompany()?.deliveryTermOther ?? "--"}
          </DescriptionBlock>
          <DescriptionBlock label="荷役作業">
            {deliveryCompany()?.handlingTask ?? "--"}
          </DescriptionBlock>
          {deliveryCompany()?.handlingTask?.includes("その他") && (
            <DescriptionBlock label="その他の荷役作業">
              {deliveryCompany()?.handlingTaskOther}
            </DescriptionBlock>
          )}
          <DescriptionBlock label="附帯業務">
            {deliveryCompany()?.needTask}
          </DescriptionBlock>
          {deliveryCompany()?.needTask?.includes("その他") && (
            <DescriptionBlock label="その他の附帯業務">
              {deliveryCompany()?.needTaskOther}
            </DescriptionBlock>
          )}
          <DescriptionBlock label="備考">
            {deliveryCompany()?.memo}
          </DescriptionBlock>
          <DescriptionBlock label="URL">
            {deliveryCompany()?.urls?.map(({ name, url }) => (
              <div key={name}>
                <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>
              </div>
            ))}
          </DescriptionBlock>
          <Space
            style={{
              width: "100%",
            }}
            direction="horizontal"
            size={24}
          >
            <DescriptionBlock label="最終更新" style={{ width: 235.86 }}>
              {dayjs(deliveryCompany()?.updatedAt).format("YYYY/MM/DD")}
            </DescriptionBlock>
            <DescriptionBlock label="担当者氏名" style={{ width: 235.86 }}>
              {deliveryCompany()?.repName}
            </DescriptionBlock>
            <DescriptionBlock label="電話番号" style={{ width: 235.86 }}>
              {deliveryCompany()?.repTel}
            </DescriptionBlock>
          </Space>
          {!(Object.keys(selected).length > 0) && <DescriptionBlock label="郵便番号">
            {deliveryCompany()?.zipcode}
          </DescriptionBlock>}

          <DescriptionBlock label="住所">
            {deliveryCompany()?.address}
            <Skeleton loading={globalState.loading}>
              <GoogleMapComponent
                isDeliveryCompany
                mapCenter={{
                  lat: selected.point?.latitude || deliveryCompanyApi.response.data.point?.latitude || 0,
                  lng: selected.point?.longitude || deliveryCompanyApi.response.data.point?.longitude || 0,
                }}
                markerOptions={
                  [
                    // deliveryCompaniesからマーカーオプションの配列をマッピング
                    ...(deliveryCompanyApi.response.data.deliveryCompanies?.map(company => ({
                      position: {
                        lat: company.point?.latitude ?? 0, // latitudeがない場合は0を使用
                        lng: company.point?.longitude ?? 0, // longitudeがない場合は0を使用
                      },
                      visible: true
                    })) ?? []), // deliveryCompaniesが未定義の場合は空の配列を展開
                    {
                      position: {
                        lat: deliveryCompanyApi.response.data.point?.latitude ?? 0, // レスポンスのpointからlatitudeを取得、未定義の場合は0
                        lng: deliveryCompanyApi.response.data.point?.longitude ?? 0, // レスポンスのpointからlongitudeを取得、未定義の場合は0
                      },
                      visible: true
                    }
                  ]
                }
                onClickMarker={(e, marker) => {
                  const targetPointCompany = deliveryCompanyApi.response.data.deliveryCompanies?.find(company =>
                    JSON.stringify([company.point?.latitude, company.point?.longitude]) ===
                    JSON.stringify([marker.getPosition()?.lat(), marker.getPosition()?.lng()])) || {};
                  setSelcted(targetPointCompany);
                }}
                onMouseOverMarker={(e, marker) => {
                  const targetPointCompany = [...(deliveryCompanyApi.response.data.deliveryCompanies || []), deliveryCompanyApi.response.data]?.find(company =>
                    JSON.stringify([company.point?.latitude, company.point?.longitude]) ===
                    JSON.stringify([marker.getPosition()?.lat(), marker.getPosition()?.lng()]));
                  const infowindow = new google.maps.InfoWindow({
                    content: targetPointCompany?.point?.memo,
                  });
                  targetPointCompany && infowindow.open(marker?.getMap(), marker);
                }}
              />
            </Skeleton>
          </DescriptionBlock>
          <div className="invisible-on-print">
            <DescriptionBlock label="座標周辺のストリートビュー">
              <iframe
                // src="http://maps.google.co.jp/maps?&output=embed&q=loc:34.705516,137.737142&z=18"
                src={`https://maps.google.com/maps?layer=c&q=${deliveryCompany()?.point?.latitude
                  },${deliveryCompany()?.point?.longitude}&cbll=${deliveryCompany()?.point?.latitude
                  },${deliveryCompany()?.point?.longitude
                  }&cbp=,90,0,0,10&source=embed&output=svembed`}
                style={{
                  height: 344,
                  width: "calc(803px - 48px)",
                  border: 0,
                }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </DescriptionBlock>
          </div>
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(DeliveryCompanyPage);
