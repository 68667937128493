import { Space, SpaceProps, theme } from "antd";

import { CSSProperties, ReactNode, useContext } from "react";
import { useMedia } from "use-media";

type CustomShadowedContentProps = SpaceProps & {
  children: ReactNode;
  style?: CSSProperties;
};

export const CustomShadowedContent = (props: CustomShadowedContentProps) => {
  const { children, style, ...rest } = props;
  const { token } = theme.useToken();

  const isMobile = useMedia("(max-width: 519px)");
  return (
    <Space
      direction="vertical"
      size={0}
      style={{
        backgroundColor: token.colorWhite,
        width: isMobile ? "100%" : 803,
        filter:
          "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))",
        borderRadius: "6px",
        padding: isMobile ? 0 : 24,
        height: "100%",
        ...style,
      }}
      {...rest}
    >
      {children}
    </Space>
  );
};
