import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect, useMemo, useState } from "react";
import { HistoryProps } from "routes/app";
import { useEffectSkipFirst, useQuery } from "utils/hooks";
import { MismatchedTable } from "./table";
import { Space, theme, Tag as AntdTag, Button, Modal, message, Drawer } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { queryString, rearrangeArray } from "utils/util";
import { CustomSelectBlockField } from "specifics/input";
import { ContractItems } from "entities/contract";
import { CookieManager } from "utils/cookie_manager";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DisplaySettingItem, TableSettings } from "specifics/table";
import { useFetchMismatchContractsApi } from "api/contract";
import { ManualPage } from "components/manuals/manual_page";
import { ID } from "entities";

export const MismatchedPage = (props: HistoryProps & { isModalVisible: boolean }) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  const listApi = useFetchMismatchContractsApi();
  const query = useQuery<{ page: number, pageSize: number, sorter: string, filter: string, type: keyof typeof ContractItems }>();

  const [mode, setMode] = useState<"すべての不一致" | "項目ごと">(query.type ? "項目ごと" : "すべての不一致");
  const [open, setOpen] = useState(false);
  const [itemSettings, setItemSettings] = useState<TableSettings>({ ...CookieManager.getTableSettings("contract_items") });

  const itemsSelector = useMemo(() => {
    const sortedItems = [...(Object.keys(ContractItems).map(
      key => ({
        key,
        title: ContractItems[key as keyof typeof ContractItems]
      })
    ) ?? [])].sort(
      (a, b) =>
        (itemSettings[a.key as string]?.order ?? 0) -
        (itemSettings[b.key as string]?.order ?? 0)
    );
    const renderedItems = sortedItems.filter(
      (item) => !(itemSettings[item.key as string]?.hide ?? false)
    );
    return { sortedItems, renderedItems };
  }, [JSON.stringify(itemSettings)]);

  const [item, setItem] = useState<keyof typeof ContractItems>(query.type ? query.type : itemsSelector.renderedItems[0].key as keyof typeof ContractItems ?? "deliveryTerm");

  useEffect(() => {
    if (mode === "項目ごと") {
      props.history.push(`/operations/contracts/${queryString({
        pageSize: query.pageSize,
        key: "不一致",
        type: item,
        filled: true,
      })}`);
    } else {
      props.history.push(`/operations/contracts/${queryString({
        pageSize: query.pageSize,
        key: "不一致",
        filled: true,
      })}`);
    }
  }, [item, mode]);

  useEffect(() => {
    if (Object.keys(query).length > 0 && !props.isModalVisible) {
      listApi.execute(queryString(query));
    }
  }, [JSON.stringify(query)]);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  const contracts = () => listApi.response.data;
  const paginationInfo = () => listApi.response.meta;

  const [manualVisible, setManualVisible] = useState(false);
  const [manualId, setManualId] = useState<ID>("");
  const [selectedManualId, setSelectedManualId] = useState<ID>("");
  const showDrawer = (id?: ID, selectedId?: ID) => {
    if (!id) {
      return;
    }
    setManualId(id);
    setSelectedManualId(selectedId || "");
    setManualVisible(true);
  };
  const onClose = () => {
    setManualId("");
    setSelectedManualId("");
    setManualVisible(false);
  }

  return (
    <>
      <CustomSelectBlockField
        selectItems={[
          {
            label: "すべての不一致",
            value: "すべての不一致",
            style: mode === "すべての不一致" ? { fontWeight: 700, background: token.colorWhite } : {}
          },
          {
            label: "項目ごと",
            value: "項目ごと",
            style: mode === "項目ごと" ? { fontWeight: 700, background: token.colorWhite } : {}
          },
        ]}
        itemStyle={{ color: token.colorPrimary, width: 160, textAlign: "center", fontSize: 14, padding: "4px 8px", cursor: "pointer", fontWeight: 400 }}
        style={{ padding: 5, height: 34, marginBottom: 16, background: "#E6EAF2" }}
        value={mode}
        onChange={mode => setMode(mode)}
      />
      {mode === "項目ごと" && <div style={{ marginBottom: 16 }}>
        {itemsSelector.renderedItems
          .map((i) =>
            <AntdTag
              onClick={() => setItem(i.key as keyof typeof ContractItems)}
              style={{
                borderRadius: 100, cursor: "pointer", background: "#E6EAF2", border: `1px solid #E5E7EB`, fontSize: 14, padding: "5px 15px", marginInlineEnd: 8,
                ...item === i.key ? { background: token.colorPrimary, border: "none", fontWeight: 700, color: token.colorWhite } : {}
              }
              }
              key={i.key}
            >
              {i.title}
            </AntdTag>
          )}
        <Button style={{ borderRadius: 100 }} onClick={() => setOpen(true)}><EditOutlined />項目を編集</Button>
      </div>}
      <MismatchedTable
        history={props.history}
        showDrawer={showDrawer}
        dataSource={contracts()}
        pagination={{
          current: paginationInfo()?.currentPage,
          pageSize: paginationInfo()?.perPage,
          total: paginationInfo()?.total,
        }}
        query={query}
        mode={mode}
        item={item}
      />
      <Modal
        title="項目を設定"
        open={open}
        footer={
          <Space>
            <Button
              onClick={() => {
                setItemSettings({});
                CookieManager.removeTableSettings("contract_items");
              }}
            >
              デフォルトに戻す
            </Button>
            <Button type="primary" onClick={() => setOpen(false)}>
              閉じる
            </Button>
          </Space>
        }
        onCancel={() => setOpen(false)}
      >
        <DragDropContext
          onDragEnd={(result, provided) => {
            if (typeof result.destination?.index === "number") {
              const newSettings: TableSettings = {};
              rearrangeArray(
                itemsSelector.sortedItems,
                result.source.index,
                result.destination?.index
              ).forEach((col, order) => {
                newSettings[col.key as string] = {
                  ...itemSettings[col.key as string],
                  order,
                };
              });
              setItemSettings(newSettings);
              CookieManager.setTableSettings("contract_items", newSettings);
              message.info("列の順番を変更しました。");
            }
          }}
        >
          <Droppable droppableId={"table-config"}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {itemsSelector.sortedItems?.map((col, index) => (
                  <Draggable
                    key={col.key}
                    draggableId={`${col.key}`}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          key={col.key}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DisplaySettingItem
                            settings={itemSettings}
                            col={col}
                            onChangeColVisibleState={() => {
                              const newSettings = {
                                ...itemSettings,
                                [col.key as string]: {
                                  ...itemSettings[col.key as string],
                                  hide: !itemSettings[col.key as string]?.hide,
                                },
                              };
                              setItemSettings(newSettings);
                              CookieManager.setTableSettings(
                                "contract_items",
                                newSettings
                              );
                            }}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Modal>
      <Drawer
        headerStyle={{ background: token.colorWhite }}
        bodyStyle={{ padding: 0 }}
        placement="right"
        closable={false}
        onClose={onClose}
        open={manualVisible}
        height="100%"
        width="50%"
      >
        {manualVisible && manualId && <ManualPage manualId={manualId} selectedManualId={selectedManualId} {...props} />}
      </Drawer>
    </>
  );
};
