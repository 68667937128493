import { ID } from "entities";
import { Rotation, RotationForm } from "entities/rotation";
import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
} from "utils/network/api_hooks";
import { HttpClient } from "utils/network/axios";

export type RotationsResponse = BaseResponse<Rotation[]>;

export function useFetchRotationsApi(): IndexApiSet<RotationsResponse> & {
  execute: (query?: string) => void;
} {
  const api = useIndexApi<RotationsResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
    },
  });

  const execute = (query?: string): void => {
    const apiPath = `rotation`;
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type RotationResponse = BaseResponse & {
  data: Rotation;
};

export function usePostRotationApi(): ApiSet<RotationResponse> & {
  execute: (rotation: RotationForm, setLoading?: boolean) => Promise<any>;
} {
  const apiSet = usePostObjectApi<RotationResponse, RotationForm>(
    new HttpClient(),
    {
      initialResponse: { data: { orderDivideds: [] } },
    },
    { formatJson: true }
  );

  const execute = async (
    rotation: RotationForm,
    setLoading?: boolean
  ): Promise<any> => {
    const apiPath = `rotation`;
    return apiSet.execute(apiPath, rotation, setLoading);
  };

  return { ...apiSet, execute };
}

export function useDeleteRotationApi(): ApiSet<BaseResponse> & {
  execute: (id: ID, setLoading?: boolean) => Promise<void>;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = async (id: ID, setLoading?: boolean): Promise<void> => {
    const apiPath = `rotation/${id}`;
    await api.execute(apiPath, setLoading);
  };

  return { ...api, execute };
}

export function usePostRotationInstructionsApi(): ApiSet<RotationResponse> & {
  execute: (idsForm: { ids: ID[]; isAssigned: boolean }) => void;
} {
  const apiSet = usePostObjectApi<
    RotationResponse,
    { ids: ID[]; isAssigned: boolean }
  >(
    new HttpClient(),
    {
      initialResponse: { data: { orderDivideds: [] } },
    },
    { formatJson: true }
  );

  const execute = (idsForm: { ids: ID[]; isAssigned: boolean }) => {
    const apiPath = `rotation/instructions`;
    apiSet.execute(apiPath, idsForm);
  };

  return { ...apiSet, execute };
}
