// @ts-ignore
import Board from "react-trello";

import { useFetchVehiclesApi } from "api/vehicle";
import { useEffect } from "react";
import { Divider, Space, theme } from "antd";
import { Form } from "utils/hooks";
import { Delivery, calculateTotalLoadWeight } from "entities/delivery";
import { Vehicle } from "entities/vehicle";
import { HistoryProps } from "routes/app";

export const DeliveriesKanban = (
  props: {
    form: Form<Delivery[]>;
    onEdit: (form: Delivery) => void;
    vehicles: Vehicle[];
  } & HistoryProps
) => {
  const deliveriesOwn = props.form.object?.filter(
    // 自社のdelivery一覧
    (d: Delivery) => d.charterType === 0
  );

  const { token } = theme.useToken();
  const vehiclesApi = useFetchVehiclesApi();

  const vehicles = () => vehiclesApi.response.data || [];

  useEffect(() => {
    vehiclesApi.execute();
  }, []);

  const genLane = (vehicle?: Vehicle) => {
    // vehicleがundefined <=> 未割当
    const deliveriesRelatedToThisVehicle: Delivery[] = JSON.parse(
      JSON.stringify(deliveriesOwn)
    )?.filter((delivery: Delivery) => {
      if (vehicle) {
        // 未割当でない
        return String(delivery.vehicleId) === String(vehicle?.id);
      } else {
        // 未割当
        return !delivery.vehicleId;
      }
    });

    const currentLoadWeight: number = calculateTotalLoadWeight(
      deliveriesRelatedToThisVehicle,
      (detail) => detail.pointType === 2
    );

    const loadingTooMuch: boolean =
      currentLoadWeight > (vehicle?.maxWeight ?? 0);
    return {
      id: vehicle?.id ?? "undefined",
      laneId: vehicle?.id ?? "undefined",
      title: vehicle ? (
        <Space direction="vertical">
          <div style={{ fontWeight: 700, fontSize: 16 }}>
            {vehicle?.vehicleCode}
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: 16,
              color: token.colorText,
            }}
          >
            ドライバー名: {vehicle?.user?.name}
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: 16,
              color: token.colorText,
              width: 240,
            }}
          >
            車格: {vehicle?.weight}t
          </div>
          <div
            style={{
              fontWeight: 500,
              fontSize: 16,
              color: token.colorText,
            }}
          >
            最大積載量: {vehicle.maxWeight ?? "--"}t
          </div>
          <div
            style={{
              fontWeight: loadingTooMuch ? 700 : 500,
              fontSize: 16,
              color: loadingTooMuch ? token.colorError : token.colorText,
            }}
          >
            現在の総積載量:
            {currentLoadWeight.toFixed(1)}t (
            {Math.round((currentLoadWeight / (vehicle.maxWeight ?? 1)) * 100)}
            %)
          </div>
          <Divider style={{ margin: "8px 0px 8px 0px" }} />
        </Space>
      ) : (
        <div style={{ height: 80 }}>
          未設定
          <Divider style={{ margin: "8px 0px 8px 0px" }} />
        </div>
      ),
      cards: deliveriesRelatedToThisVehicle?.map((delivery: Delivery) => ({
        id: delivery.id,
        metadata: { orderId: delivery.order?.id },
        title: <Space>受注番号:{delivery.order?.orderNo}</Space>,
        description: (
          <Space direction="vertical">
            <div>
              積み
              <div>
                {delivery.deliveryDetails
                  ?.filter((detail) => detail.pointType === 2)
                  ?.map((detail) => (
                    <div key={detail.id}>
                      ・{detail.deliveryCompany?.name}({detail.loadWeight}t)
                    </div>
                  ))}
              </div>
            </div>
            <div>
              降し
              <div>
                {delivery.deliveryDetails
                  ?.filter((detail) => detail.pointType === 1)
                  ?.map((detail) => (
                    <div key={detail.id}>
                      ・{detail.deliveryCompany?.name}({detail.loadWeight}t)
                    </div>
                  ))}
              </div>
            </div>
          </Space>
        ),
      })),
    };
  };

  const getData = () => {
    return {
      lanes: [
        genLane(),
        ...vehicles().map((vehicle, index) => genLane(vehicle)),
      ],
    };
  };

  return (
    <Board
      onCardClick={(cardId: string, metadata: any) => {
        props.history.push(`/orders/${metadata.orderId}`);
      }}
      handleDragEnd={(
        cardId: string,
        sourceLaneId: string,
        targetLaneId: string,
        position: any,
        cardDetails: any
      ) => {
        if (cardId) {
          props.onEdit({
            id: cardId,
            vehicleId: targetLaneId ?? null,
            // @ts-ignore
            userId:
              props.vehicles.find((v) => v.id === targetLaneId)?.userId ?? null,
          });
        }
      }}
      style={{
        backgroundColor: token.colorWhite,
        borderRadius: 16,
        height: window.innerHeight - 220,
      }}
      cardStyle={{
        padding: "8px 16px",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        borderRadius: "8px",
      }}
      laneStyle={{
        overflow: "scroll",
        height: "100%",
        maxHeight: window.innerHeight - 240,
        background: token.colorBgBase,
        borderRadius: "8px",
        marginLeft: 8,
      }}
      draggable
      hideCardDeleteIcon
      data={getData()}
    />
  );
};
