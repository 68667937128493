import { theme } from "antd";
import { type } from "os";
import { CSSProperties, ReactNode, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  XAxisProps,
  YAxisProps,
} from "recharts";
import { Margin } from "recharts/types/util/types";
import { CustomShadowedContent } from "specifics/shadowed_content";

type BarInfo<T extends Record<string, any>> = {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  payload?: T;
};

const getRoundedRectPath = ({
  x,
  y,
  width,
  height,
  borderRadius,
  ...rest
}: any) => {
  const adjustedBorderRadius = Math.min(height / 2, width / 2, borderRadius);
  const adjustedWidth = width - 2 * adjustedBorderRadius;
  const adjustedHeight = height - 2 * adjustedBorderRadius;
  return `
    M ${x + adjustedBorderRadius}, ${y}
    H ${x + adjustedWidth + adjustedBorderRadius}
    Q ${x + width}, ${y}, ${x + width}, ${y + adjustedBorderRadius}
    V ${y + adjustedHeight + adjustedBorderRadius}
    Q ${x + width}, ${y + height}, ${x + adjustedWidth + adjustedBorderRadius
    }, ${y + height}
    H ${x + adjustedBorderRadius}
    Q ${x}, ${y + height}, ${x}, ${y + adjustedHeight + adjustedBorderRadius}
    V ${y + adjustedBorderRadius}
    Q ${x}, ${y}, ${x + adjustedBorderRadius}, ${y}
    Z
  `;
};

type RenderBarProps<T extends Record<string, any>> = Omit<
  BarInfo<T>,
  "fill"
> & {
  fill: string | ((hover: boolean) => string);
  borderRadius: number;
  label?: T;
  onBarClick?: () => void;
};

const RenderBar = <T extends Record<string, any>>({
  fill,
  x,
  y,
  width,
  height,
  borderRadius = 12,
  label,
  onBarClick, // onBarClickを引数に追加
  ...rest
}: RenderBarProps<T>) => {
  const [hover, setHover] = useState(false);
  const handleOnClick = () => {
    if (onBarClick) { // payloadとonBarClickが存在する場合のみ実行
      onBarClick();
    }
  };
  return (
    <g onClick={() => handleOnClick()}> {/* gタグでラップし、ここにonClickを設定 */}
      <path
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        d={getRoundedRectPath({ x, y, width, height, borderRadius })}
        stroke="none"
        fill={typeof fill === "string" ? fill : fill(hover)}
        style={{ transition: "fill 0.3s" }}
      />
    </g>
  );
};

const CustomTooltip = <T extends Record<string, any>>({
  active,
  payload,
  label,
  getTooltipLabel,
  getTooltipBody,
  toolTipBodyStyle,
}: {
  active?: boolean;
  payload?: any[];
  label?: string | number;
  getTooltipLabel?: (payload: T) => string | number;
  getTooltipBody?: (payload: T) => ReactNode;
  toolTipBodyStyle?: CSSProperties;
}) => {
  const { token } = theme.useToken();
  if (active && payload && payload.length) {
    return (
      <CustomShadowedContent style={{ padding: 18, width: "100%" }}>
        <div style={{ fontSize: 14, color: token.colorTextSecondary }}>
          {getTooltipLabel ? getTooltipLabel(payload[0]) : label}
        </div>
        <div
          style={{
            fontSize: 22,
            color: token.colorPrimary,
            fontWeight: 700,
            ...toolTipBodyStyle,
          }}
        >
          {getTooltipBody ? getTooltipBody(payload[0]) : payload[0].value}
        </div>
      </CustomShadowedContent>
    );
  }

  return null;
};

export const CustomBarChat = <T extends Record<string, any>>({
  data,
  xAxisLabelKey,
  valueKey,
  height = 293,
  width = 302,
  barSize,
  barGap,
  tooltipProps,
  xaxisProps,
  yaxisProps,
  getTooltipLabel,
  getTooltipBody,
  toolTipBodyStyle,
  fill = (payload, hover, valueKey) => (hover ? "#1D2645" : "#EAEAEA"),
  showLegend = false,
  grid = false,
  onBarClick,
}: {
  data: T[];
  xAxisLabelKey: keyof T;
  valueKey: keyof T | (keyof T)[];
  width?: number;
  height?: number;
  barSize?: number;
  barGap?: number;
  tooltipProps?: TooltipProps<any, any>;
  xaxisProps?: XAxisProps;
  yaxisProps?: YAxisProps;
  getTooltipLabel?: (key: { payload: T }) => string | number;
  getTooltipBody?: (key: { payload: T }) => ReactNode;
  toolTipBodyStyle?: CSSProperties;
  fill?:
  | string
  | ((payload?: T, hover?: boolean, valueKey?: keyof T) => string);
  margin?: Margin;
  showLegend?: boolean;
  grid?: boolean;
  onBarClick?: (payload: T) => void;
}): JSX.Element => {
  const { token } = theme.useToken();

  return (
    <BarChart
      className="bar-chart-pading-disabled"
      data={data}
      width={width}
      height={height}
      barSize={barSize}
      barGap={barGap}
    >
      <YAxis hide {...yaxisProps} />
      <XAxis
        tickMargin={10}
        dataKey={xAxisLabelKey as string}
        axisLine={false}
        tickLine={false}
        {...xaxisProps}
      />
      <Tooltip
        contentStyle={{ borderRadius: 12 }}
        itemStyle={{ color: token.colorTextSecondary }}
        content={
          <CustomTooltip
            getTooltipLabel={getTooltipLabel}
            getTooltipBody={getTooltipBody}
            toolTipBodyStyle={toolTipBodyStyle}
          />
        }
        {...tooltipProps}
      />
      {showLegend && <Legend />}
      {grid && <CartesianGrid strokeDasharray="3 3" />}
      {Array.isArray(valueKey) ? (
        valueKey.map((group) => (
          <Bar
            key={`bar-${group as string}`}
            fill={
              typeof fill === "string"
                ? fill
                : fill(undefined, undefined, group)
            }
            shape={(
              { fill: _, x, y, width, height, payload, ...rest }: BarInfo<T> // このfillは上書きしてしまって問題ない。
            ) => (
              <RenderBar<T>
                fill={
                  typeof fill === "string"
                    ? fill
                    : (hover: boolean) => fill(payload, hover, group)
                }
                x={x}
                y={y}
                width={width}
                height={height}
                borderRadius={12}
                {...rest}
                onBarClick={() => onBarClick && payload && onBarClick(payload)}
              />
            )}
            dataKey={group as string}
          />
        ))
      ) : (
        <Bar
          shape={(
            { fill: _, x, y, width, height, payload, ...rest }: BarInfo<T> // このfillは上書きしてしまって問題ない。
          ) => (
            <RenderBar<T>
              fill={
                typeof fill === "string"
                  ? fill
                  : (hover: boolean) => fill(payload, hover)
              }
              x={x}
              y={y}
              width={width}
              height={height}
              borderRadius={12}
              {...rest}
              onBarClick={() => onBarClick && payload && onBarClick(payload)}
            />
          )}
          dataKey={valueKey as string}
        />
      )}
    </BarChart>
  );
};
