import { SelectItem } from "components/shared/input";

export enum MetricsName {
  LOAD_ITEMS_COUNTS = "LOAD_ITEMS_COUNTS", // 積み件数
  UNLOAD_ITEMS_COUNTS = "UNLOAD_ITEMS_COUNTS", // 降ろし件数
  ROTATIONS_COUNTS = "ROTATIONS_COUNTS", // 回転数
  ACTUAL_VEHICLE_RATE = "ACTUAL_VEHICLE_RATE", // 実車率
  LOADING_RATE = "LOADING_RATE", // 積載率
  MILEAGE = "MILEAGE", // 走行距離
  MILEAGE_PER_ROTATION = "MILEAGE_PER_ROTATION", // 一回転あたりの走行距離
  MILEAGE_PER_ORDER = "MILEAGE_PER_ORDER", // 一件あたりの走行距離
}

export enum MetricsGroupBy {
  NOT_SELECTED = "null",
  VEHICLE = "vehicle_id",
  DELIVERY_COMPANY = "delivery_company_id",
}

export type MetricGroupItem = {
  label: string;
  value: string;
};

export enum MetricDataType {
  SUM = "sum",
  RATE = "rate",
}

export type MetricConfig = {
  metricName: MetricsName;
  metricNameJp: string;
  includeFromToDateTime: boolean;
  includeRangeDays: boolean;
  canGroupedBy: MetricsGroupBy[];
  unit?: string;
  dataType?: MetricDataType;
};

type MetricsConfig = MetricConfig[];

export const METRIC_NAME_LIST = Object.keys(MetricsName).filter((key) =>
  isNaN(Number(key))
) as MetricsName[];

export class MetricsConfigManager {
  static metricConfig = (metricName: MetricsName): MetricConfig => {
    const baseMetricConfig: Omit<MetricConfig, "metricName" | "metricNameJp"> =
      {
        includeFromToDateTime: true,
        includeRangeDays: true,
        canGroupedBy: [MetricsGroupBy.NOT_SELECTED],
      };
    switch (metricName) {
      case MetricsName.LOAD_ITEMS_COUNTS:
        return {
          ...baseMetricConfig,
          metricName,
          metricNameJp: "積み件数",
          canGroupedBy: [
            MetricsGroupBy.VEHICLE,
            MetricsGroupBy.DELIVERY_COMPANY,
            MetricsGroupBy.NOT_SELECTED,
          ],
          unit: "件",
          dataType: MetricDataType.SUM,
        };
      case MetricsName.UNLOAD_ITEMS_COUNTS:
        return {
          ...baseMetricConfig,
          metricName,
          metricNameJp: "降ろし件数",
          canGroupedBy: [
            MetricsGroupBy.VEHICLE,
            MetricsGroupBy.DELIVERY_COMPANY,
            MetricsGroupBy.NOT_SELECTED,
          ],
          unit: "件",
          dataType: MetricDataType.SUM,
        };
      case MetricsName.ROTATIONS_COUNTS:
        return {
          ...baseMetricConfig,
          metricName,
          metricNameJp: "回転数",
          canGroupedBy: [
            MetricsGroupBy.VEHICLE,
            MetricsGroupBy.DELIVERY_COMPANY,
            MetricsGroupBy.NOT_SELECTED,
          ],
          unit: "回転",
          dataType: MetricDataType.SUM,
        };
      case MetricsName.ACTUAL_VEHICLE_RATE:
        return {
          ...baseMetricConfig,
          metricName,
          metricNameJp: "実車率",
          canGroupedBy: [MetricsGroupBy.VEHICLE, MetricsGroupBy.NOT_SELECTED],
          unit: "%",
          dataType: MetricDataType.RATE,
        };
      case MetricsName.LOADING_RATE:
        return {
          ...baseMetricConfig,
          metricName,
          metricNameJp: "積載率",
          canGroupedBy: [MetricsGroupBy.VEHICLE, MetricsGroupBy.NOT_SELECTED],
          unit: "%",
          dataType: MetricDataType.RATE,
        };
      case MetricsName.MILEAGE:
        return {
          ...baseMetricConfig,
          metricName,
          metricNameJp: "走行距離",
          canGroupedBy: [MetricsGroupBy.VEHICLE, MetricsGroupBy.NOT_SELECTED],
          unit: "km",
          dataType: MetricDataType.SUM,
        };
      case MetricsName.MILEAGE_PER_ROTATION:
        return {
          ...baseMetricConfig,
          metricName,
          metricNameJp: "一回転あたりの走行距離",
          canGroupedBy: [MetricsGroupBy.VEHICLE, MetricsGroupBy.NOT_SELECTED],
          unit: "km",
          dataType: MetricDataType.SUM,
        };
      case MetricsName.MILEAGE_PER_ORDER:
        return {
          ...baseMetricConfig,
          metricName,
          metricNameJp: "一件あたりの走行距離",
          canGroupedBy: [MetricsGroupBy.VEHICLE, MetricsGroupBy.NOT_SELECTED],
          unit: "km",
          dataType: MetricDataType.SUM,
        };
      default:
        throw `UnknownMetricConfigError ${metricName}`;
    }
  };

  static groupedBySelectItems = (
    canGroupedBy: MetricConfig["canGroupedBy"]
  ): SelectItem[] => {
    return (
      canGroupedBy?.map((groupBy) => {
        switch (groupBy) {
          case MetricsGroupBy.DELIVERY_COMPANY:
            return {
              label: "行先",
              value: groupBy,
            };
          case MetricsGroupBy.VEHICLE:
            return {
              label: "車両",
              value: groupBy,
            };
          default:
            return {
              label: "選択無し",
              value: MetricsGroupBy.NOT_SELECTED,
            };
        }
      }) ?? []
    );
  };
}

export const METRICS_CONFIG: MetricsConfig = METRIC_NAME_LIST.map(
  (metricName) => MetricsConfigManager.metricConfig(metricName)
);
