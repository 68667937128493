import { Button, QRCode, Select, Space, TableProps, message, theme } from "antd";
import { ColumnsType } from "antd/lib/table";

import { ID } from "entities";
import { Manual } from "entities/manual";
import dayjs from "dayjs";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { Prefectures, copyToClipboard, queryString } from "utils/util";
import { EntityTagsView } from "../tags/entity_tags_view";
import { SettingOutlined, LinkOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  useAddTagToManualApi,
  useDeleteManualTagApi,
  useFetchTagsForManualApi,
  useRemoveTagFromManualApi,
  useStoreManualTagApi,
  useUpdateManualTagApi,
} from "api/manual";
import { Key, useContext, useEffect, useLayoutEffect, useState } from "react";
import { TagsFormModal } from "components/tags/tags_form_modal";
import { Tag } from "entities/tag";
import { useGenerateTemporaryTokenApi } from "api/temporary_token";
import ReactDOM from "react-dom";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { Status } from "entities/contract";
import { statusTag } from "specifics/status_tag";
import { GlobalStateContext } from "contexts/global_state_context";
type ManualsTableProps = TableProps<Manual>;

export const ManualsTable = (
  props: ManualsTableProps &
    HistoryProps & {
      handleDelete: (id: ID) => void;
      handleBulkDelete: (ids: ID[]) => void;
      deliveryPartnerCompanies: DeliveryPartnerCompany[];
      query: {
        page: number;
        pageSize: number;
        sorter: string;
        filter: string;
      };
    }
) => {
  const globalState = useContext(GlobalStateContext);
  const { handleDelete, handleBulkDelete, query, dataSource, ...rest } = props;
  const table = useTable<Manual>(props);
  const prefectures = Prefectures;
  const [openManageTagModal, setOpenManageTagModal] = useState(false);
  const { token } = theme.useToken();

  const idsForm = useForm<{ ids: ID[] }>({ ids: [] });
  const tagsForm = useForm<{ tags: Tag[] }>({ tags: [] });

  const listTagsForManualsApi = useFetchTagsForManualApi();

  useEffect(() => {
    listTagsForManualsApi.execute();
  }, []);

  useLayoutEffect(() => {
    if (listTagsForManualsApi.isSuccess()) {
      tagsForm.updateObject("tags", listTagsForManualsApi.response.data || []);
    }
  }, [listTagsForManualsApi.loading]);

  const addTagToManualApi = useAddTagToManualApi();
  const removeTagFromManualApi = useRemoveTagFromManualApi();

  const storeManualTagApi = useStoreManualTagApi();

  useEffect(() => {
    if (storeManualTagApi.isSuccess()) {
      message.success("タグを作成しました");
      tagsForm.updateObject("tags", [
        ...tagsForm.object.tags,
        storeManualTagApi.response.data,
      ]);
    }
  }, [storeManualTagApi.loading]);
  const updateManualTagApi = useUpdateManualTagApi();

  useEffect(() => {
    if (updateManualTagApi.isSuccess()) {
      message.success("タグを更新しました");
      tagsForm.updateObject(
        "tags",
        tagsForm.object.tags.map((tag) =>
          tag.id === updateManualTagApi.response.data?.id
            ? updateManualTagApi.response.data
            : tag
        )
      );
    }
  }, [updateManualTagApi.loading]);

  const deleteManualTagApi = useDeleteManualTagApi();

  useEffect(() => {
    if (deleteManualTagApi.isSuccess()) {
      message.success("タグを削除しました");
      tagsForm.updateObject(
        "tags",
        tagsForm.object.tags?.filter(
          (tag) => tag.id !== deleteManualTagApi.response.data?.id
        )
      );
    }
  }, [deleteManualTagApi.loading]);

  useEffectSkipFirst(() => {
    idsForm.updateObject("ids", table.selectedRowKeys);
  }, [table.selectedRowKeys]);

  const generateTokenApi = useGenerateTemporaryTokenApi();
  const columns: ColumnsType<Manual> = [
    {
      ...table.baseColumn("title"),
      ...table.getColumnSearchProps("title", "納入カルテ"),
      title: "納入カルテ",
      width: "380px",
      render: (item) =>
        item.title ?? `${item.deliveryCompany?.name}-${item.deliveryPartnerCompany?.name}-${item.consignor?.name}`,
    },
    {
      ...table.baseColumn("deliveryCompany.address"),
      ...table.getColumnSearchProps("deliveryCompany", "行先", Object.values(prefectures), "都道府県"),
      title: "住所",
      width: "300px",
      render: (item) => item.deliveryCompany.address,
    },
    {
      ...table.baseColumn("deliveryCompany.name"),
      ...table.getColumnSearchProps("deliveryCompany", "行先"),
      onFilter: (value, record) =>
        (record.deliveryCompany?.name || "")
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      title: "行先/荷主",
      width: "300px",
      render: (item) =>
        <div>
          {item.deliveryCompany?.name}<br />
          {item.consignor?.name}
        </div>
    },
    {
      ...table.baseColumn("deliveryPartnerCompany.id"),
      title: "担当運送会社",
      width: "212px",
      render: (item) => item.deliveryPartnerCompany?.name || "--",
      filters: props.deliveryPartnerCompanies?.map(company => ({
        text: company.name || '',
        value: company.id || ''
      })),
    },
    {
      ...table.baseColumn("tags.ids"),
      title: (
        <Space>
          <div>タグ</div>
          <Button
            onClick={() => setOpenManageTagModal(true)}
            type="text"
            shape="circle"
            icon={<SettingOutlined />}
          />
        </Space>
      ),
      width: 250,
      render: (item) => {
        return (
          <EntityTagsView
            entity={item}
            candidateTags={tagsForm.object.tags}
            onAddTagToEntity={async (manualId, tagId) => {
              const newTag = await addTagToManualApi.execute(manualId, tagId);
              return newTag;
            }}
            onRemoveTagFromEntity={async (manualId, tagId) => {
              const deletedTag = await removeTagFromManualApi.execute(
                manualId,
                tagId
              );
              return deletedTag;
            }}
            refetchCandidateTags={() => listTagsForManualsApi.execute()}
          />
        );
      },
      filters: tagsForm.object.tags.map((tag) => ({
        text: tag.name ?? "",
        value: tag.id ?? "",
      })),
    },
    {
      ...table.baseColumn("status"),
      title: "ステータス",
      width: 128,
      render: (item) => item.status < Status.その他 && statusTag(item.status, token),
      filters: Object.entries(Status)
        .filter(([label, value]) =>
          typeof value === "number" && value === Status.未記入 // 現状未記入だけをフィルタリング
        ).map(([label, value]) => ({
          text: label,
          value: value,
        })),
    },
    {
      ...table.baseColumn("updatedAt"),
      sorter: (a, b) => ((a.updatedAt ?? "") > (b.updatedAt ?? "") ? 1 : -1),
      title: "最終更新日",
      width: 127,
      render: (item) => item.updatedAt ? dayjs(item.updatedAt).format("YYYY/MM/DD") : "--",
    },
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 108,
      render: (item) => (
        <Space size={16}>
          {props.handleDelete && (
            <CustomButton
              style={{
                fontWeight: 500,
                padding: 0,
                height: "30px",
                width: "28px",
                textAlign: "left",
              }}
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                props.handleDelete(item.id);
              }}
              danger
              confirm
              popconfirmProps={{
                title: "削除します。よろしいですか？",
                okText: "削除",
                cancelText: "戻る",
              }}
            >
              削除
            </CustomButton>
          )}
          <Button
            type="link"
            icon={
              <LinkOutlined style={{ fontSize: 20, transform: 'rotate(45deg)' }} />
            }
            onClick={async (e) => {
              e.stopPropagation();
              const res = await generateTokenApi.execute({ expirationOption: globalState.setting.customSetting?.urlExpiration });
              const token = encodeURIComponent(res.data.token);
              copyToClipboard(`${item.title}\n${process.env.REACT_APP_ADMIN_HOST}manuals/${item.id}?token=${token}&isEdit=true`);
              message.success(
                "クリップボードに閲覧用リンクをコピーしました。"
              );
            }}
          />
        </Space>
      ),
    },
  ];

  const handleBulkURLCopy = async (ids: ID[]) => {
    // 各行に対してトークンを生成し、情報を連結する非同期処理を準備
    const copyPromises = ids.map(async (id) => {
      // 一時トークンを生成
      const res = await generateTokenApi.execute({ expirationOption: globalState.setting.customSetting?.urlExpiration  });
      // 対応する行のデータを見つける
      const item = dataSource?.find((d) => d.id === id);
      const token = encodeURIComponent(res.data.token);
      // 必要な情報を形成
      return `${item?.deliveryCompany?.name}\n${process.env.REACT_APP_ADMIN_HOST}manuals/${id}?token=${token}&isEdit=true`;
    });

    // すべての非同期処理が完了するのを待つ
    const copyTexts = await Promise.all(copyPromises);

    // 結果を改行で連結してクリップボードにコピー
    const finalText = copyTexts.join('\n');
    copyToClipboard(finalText);

    // 成功メッセージを表示
    message.success("クリップボードに閲覧用リンクを一括コピーしました。");
  };

  const handleBulkQRPrint = async (ids: ID[]) => {
    // 印刷用のiframeを作成
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    document.body.appendChild(iframe);

    // iframeのdocument.bodyを取得
    const docContainer = iframe.contentDocument?.body || iframe.contentWindow?.document.body as HTMLElement;

    // 各IDに対してトークンを生成し、QRコードのURLを準備
    const urls = await Promise.all(ids.map(async (id) => {
      const res = await generateTokenApi.execute({ expirationOption: globalState.setting.customSetting?.qrExpiration  });
      const item = dataSource?.find(d => d.id === id);
      const title = item?.title ?? `${item?.deliveryCompany?.name}-${item?.consignor?.name}`
      return {
        url: `${process.env.REACT_APP_ADMIN_HOST}manuals/${id}?token=${res.data.token}`,
        title: title
      };
    }));

    // 10件ごとにデータを分割
    const pages: { url: string; title: string; }[][] = [];
    for (let i = 0; i < urls.length; i += 10) {
      pages.push(urls.slice(i, i + 10));
    }

    // 印刷用のコンポーネントを準備
    const PrintComponent = () => (
      <div>
        {pages.map((page, pageIndex) => (
          <div key={pageIndex} style={{ pageBreakAfter: pageIndex < pages.length - 1 ? 'always' : 'auto' }}>
            {page.map((data, dataIndex) => (
              <div key={dataIndex} style={{ display: 'inline-block', width: 'calc(50% - 20px)' }}>
                <p style={{ textAlign: "center", margin: "10px 0" }}>{data.title}</p>
                <QRCode value={data.url} size={128} style={{ margin: "0 auto" }} />
              </div>
            ))}
          </div>
        ))}
      </div>
    );

    // 印刷用のコンテンツをiframeにレンダリング
    ReactDOM.render(<PrintComponent />, docContainer);

    // レンダリングが完了した後、印刷を実行
    setTimeout(() => {
      iframe.contentWindow?.print();
      document.body.removeChild(iframe);
    }, 500);
  };

  const pagination = {
    current: props.pagination ? props.pagination.current ?? 0 : 0,
    pageSize: props.pagination ? props.pagination.pageSize ?? 0 : 0,
    total: props.pagination ? props.pagination.total ?? 0 : 0,
    start: 0,
    last: 0,
  };
  pagination.start = (pagination?.current - 1) * pagination?.pageSize + 1;
  pagination.last = pagination?.current * pagination?.pageSize;

  const clearRowSelection = () => {
    idsForm.resetForm();
    table.setSelectedRowKeys([]);
  };

  return (
    <>
      <CustomTable
        loading={listTagsForManualsApi.loading}
        tableKey="manuals_table"
        table={table}
        extraLeft={
          <Space size={25} style={{ fontSize: 13 }}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <span style={{ fontSize: 15, fontWeight: 500 }}>
                {pagination.total}
              </span>
              件中&ensp;
              <span style={{ fontSize: 15, fontWeight: 500 }}>
                {pagination.start}
              </span>
              ~
              <span style={{ fontSize: 15, fontWeight: 500 }}>
                {pagination.total < pagination.last ? `${pagination.total}` : `${pagination.last}`}
              </span>
              件を表示
            </div>
            <div>
              表示件数
              <Select
                style={{ width: 85, marginLeft: 6 }}
                defaultValue={Number(query.pageSize)}
                options={[
                  { value: 10, label: "10件" },
                  { value: 20, label: "20件" },
                  { value: 50, label: "50件" },
                  { value: 100, label: "100件" },
                ]}
                onChange={(value) =>
                  props.history.push(
                    `/operations/manuals/${queryString({
                      ...query,
                      pageSize: value,
                    })}`
                  )
                }
              />
            </div>
          </Space>
        }
        rowSelection={table.rowSelection}
        pagination={table.paginationInfo}
        showSizeChanger={false}
        onChange={table.handleChange}
        columns={columns}
        onRow={(data, index) => ({
          onClick: () => props.history.push(`/operations/manuals/${data.id}`),
        })}
        dataSource={dataSource?.map((d) => ({ ...d, key: d?.id }))}
        onBulkDelete={() => {
          handleBulkDelete(idsForm.object.ids);
          clearRowSelection();
        }}
        onBulkURLCopy={() => {
          handleBulkURLCopy(idsForm.object.ids);
          clearRowSelection();
        }}
        onBulkQRPrint={() => {
          handleBulkQRPrint(idsForm.object.ids);
          clearRowSelection();
        }}
        {...rest}
      />
      <TagsFormModal
        open={openManageTagModal}
        onCancel={(e) => {
          e.stopPropagation();
          setOpenManageTagModal(false);
        }}
        tagsForm={tagsForm}
        onStoreTag={(createTagInput) =>
          storeManualTagApi.execute(createTagInput)
        }
        onUpdateTag={(updateTagInput) =>
          updateManualTagApi.execute(updateTagInput)
        }
        onDeleteTag={(tagId) => deleteManualTagApi.execute(tagId)}
      />
    </>
  );
};
