import { User } from "entities/user";
import { Setting } from "entities/setting";
import { ConfirmOption, Dimension } from "containers/global_state_container";
import React, { ReactNode, SetStateAction } from "react";

export type NotificationColor = "success" | "info" | "warning" | "error";

type GlobalState = {
  setting: Setting;
  setSetting: (setting: Setting) => void;
  user: User;
  setUser: (user: User) => void;
  users: User[];
  setUsers: (user: User[]) => void;
  loading: boolean;
  setLoading: (value: SetStateAction<boolean>) => void;
  apiError: { [key: string]: string };
  setError: (value: SetStateAction<{ [key: string]: string }>) => void;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  dimension: Dimension;
  setDimension: (dimension: Dimension) => void;
  issueCount: number;
  setIssueCount: (count: number) => void;
  notificationsCount: number;
  setNotificationsCount: (count: number) => void;
};

export const initialGlobalState: GlobalState = {
  setting: {},
  setSetting: () => { },
  user: {},
  setUser: () => { },
  users: [],
  setUsers: () => { },
  loading: false,
  setLoading: () => { },
  apiError: {},
  setError: () => { },
  collapsed: false,
  setCollapsed: () => { },
  dimension: { width: 1000, height: 700 },
  setDimension: () => { },
  issueCount: 0,
  setIssueCount: () => { },
  notificationsCount: 0,
  setNotificationsCount: () => { },
};

export const GlobalStateContext = React.createContext(initialGlobalState);
