import { Button, Popover, Space, Table, TableProps, Tag, theme } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { OrdersResponse, useFetchOrdersApi } from "api/order";
import { GlobalStateContext } from "contexts/global_state_context";

import { BaseEntity, ID } from "entities";
import { Order, OrderPointType, OrderType } from "entities/order";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import { Form, FormAttrType, useEffectSkipFirst, useForm } from "utils/hooks";
import {
  DeleteOrCancelCloseOrderButton,
  OrderTypeView,
  getOrderTypeLabel,
} from "./orders_view";
import { CustomTableText } from "specifics/table_text";
import { RiExternalLinkFill } from "react-icons/ri";
import { IndexApiSet } from "utils/network/api_hooks";

export type OrdersTableProps = TableProps<Order>;

export const OrdersTable = (
  props: OrdersTableProps &
    HistoryProps & {
      handleDelete: (id: ID) => void;
      handleCancelClose: (id: ID) => void;
      handleBulkDelete?: (ids: ID[]) => void;
      handleBulkClose?: (ids: ID[]) => void;
      handleBulkCancelClose?: (ids: ID[]) => void;
    } & {
      listApi?: IndexApiSet<OrdersResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const {
    handleDelete,
    handleBulkDelete,
    handleCancelClose,
    handleBulkCancelClose,
    handleBulkClose,
    dataSource,
    ...rest
  } = props;
  const table = useTable<
    Order & { loadingDate?: string; unloadingDate?: string }
  >(props);
  const { token } = theme.useToken();

  const idsForm = useForm<{ ids: ID[] }>({ ids: [] });

  useEffectSkipFirst(() => {
    idsForm.updateObject("ids", table.selectedRowKeys);
  }, [table.selectedRowKeys]);

  useEffect(() => {
    table.setSortedInfo({ columnKey: "createdAt", order: "descend" });
  }, []);

  const representOrderDetailDate = (
    order: Order,
    pointType: OrderPointType
  ) => {
    const list = order.orderDetails?.filter(
      (orderDetail) => orderDetail.pointType === pointType
    );
    if (!list?.length) {
      return "--";
    }
    const date = list[0]?.deliveryDate;
    return date ? dayjs(date).format("MM/DD") : "";
  };

  const orderColumns: ColumnsType<Order> = [
    {
      ...table.baseColumn("orderNo"),
      ...table.getColumnSearchProps("orderNo", "受注番号"),
      title: "受注番号",
      sorter: (a, b) => ((a.orderNo ?? "") > (b.orderNo ?? "") ? 1 : -1),
      render: (item: Order) => item.orderNo ?? "--",
      width: 150,
      fixed: "left",
    },
    {
      ...table.baseColumn("orderType"),
      title: "カテゴリ",
      width: "200px",
      filters: ([0, 1] as const).map((orderType) => ({
        text: getOrderTypeLabel(orderType),
        value: orderType,
      })),
      onFilter: (value: string | number | boolean, record: Order) =>
        record.orderType === value,
      render: (item) => OrderTypeView(item),
    },
    {
      ...table.baseColumn("createdAt"),
      ...table.getColumnSearchProps("createdAt", "登録日"),
      title: "登録日",
      width: "120px",
      render: (item) => {
        return item.createdAt
          ? dayjs(item.createdAt).format("YYYY/MM/DD")
          : "--";
      },
      sorter: (a, b) =>
        (dayjs(a.createdAt).format("YYYY/MM/DD") ?? "") >
        (dayjs(b.createdAt).format("YYYY/MM/DD") ?? "")
          ? 1
          : -1,
    },
    {
      ...table.baseColumn("orderDate"),
      ...table.getColumnSearchProps("orderDate", "受注日"),
      render: (item) => dayjs(item.orderDate).format("YYYY/MM/DD") ?? "--",
      title: "受注日",
      width: "120px",
      sorter: (a, b) =>
        (dayjs(a.orderDate).format("YYYY/MM/DD") ?? "") >
        (dayjs(b.orderDate).format("YYYY/MM/DD") ?? "")
          ? 1
          : -1,
    },
    {
      ...table.baseColumn("consignor.name"),
      ...table.getColumnSearchProps("consignor", "荷主"),
      onFilter: (value, record) =>
        (record.consignor?.name || "")
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      title: "荷主",
      width: "240px",
      render: (item) => (
        <Space size={0}>
          <CustomTableText width={190}>{item.consignor?.name}</CustomTableText>
          {item.consignor && (
            <Popover content="荷主詳細へ移動">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  props.history.push(
                    `/operations/consignors/${item.consignor.id}`
                  );
                }}
                shape="circle"
                type="text"
                icon={<RiExternalLinkFill />}
              />
            </Popover>
          )}
        </Space>
      ),
    },
    {
      ...table.baseColumn("orderDetails.loadingDate"),
      ...table.getColumnSearchProps("loadingDate", "積み日"),
      title: "積み日",
      width: "130px",
      sorter: (a, b) =>
        representOrderDetailDate(a, 2) > representOrderDetailDate(b, 2)
          ? 1
          : -1,
      render: (item: Order) => {
        return representOrderDetailDate(item, 2);
      },
    },
    {
      ...table.baseColumn("orderDetails.unloadingDate"),
      ...table.getColumnSearchProps("unloadingDate", "降し日"),
      title: "降し日",
      width: "130px",
      sorter: (a, b) =>
        representOrderDetailDate(a, 1) > representOrderDetailDate(b, 1)
          ? 1
          : -1,
      render: (item: Order) => {
        return representOrderDetailDate(item, 1);
      },
    },
    {
      key: "loadingDeliveryCompany",
      title: "積み地",
      width: "240px",
      render: (item: Order) => {
        return (
          <Space direction="vertical" size={0}>
            {item.orderDetails
              ?.filter((detail) => detail.pointType === 2 ?? [])
              ?.map((detail, i) => (
                <Space size={0} key={`LoadingDeliveryCompanyName${i}`}>
                  <CustomTableText width={190}>
                    {detail?.deliveryCompany?.name}
                  </CustomTableText>

                  <Popover content="積み地詳細へ移動">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.history.push(
                          `/operations/delivery-companies/${detail.deliveryCompany?.id}`
                        );
                      }}
                      shape="circle"
                      type="text"
                      icon={<RiExternalLinkFill />}
                    />
                  </Popover>
                </Space>
              ))}
          </Space>
        );
      },
    },
    {
      key: "loadingWeight",
      title: "重量/キログラム",
      width: "140px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails
              ?.filter((od) => od.pointType === 2)
              ?.map((od) => (
                <div key={od.id}>{od.loadWeight}kg</div>
              ))}
          </>
        );
      },
    },
    {
      key: "unloadingDeliveryCompany",
      title: "降し地",
      width: "240px",
      render: (item: Order) => {
        return (
          <Space direction="vertical" size={0}>
            {item.orderDetails
              ?.filter((detail) => detail.pointType === 1 ?? [])
              ?.map((detail, i) => (
                <Space size={0} key={`UnloadingDeliveryCompanyName${i}`}>
                  <CustomTableText width={190}>
                    {detail?.deliveryCompany?.name}
                  </CustomTableText>

                  <Popover content="降し地詳細へ移動">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.history.push(
                          `/operations/delivery-companies/${detail.deliveryCompany?.id}`
                        );
                      }}
                      shape="circle"
                      type="text"
                      icon={<RiExternalLinkFill />}
                    />
                  </Popover>
                </Space>
              ))}
          </Space>
        );
      },
    },
    {
      ...table.baseColumn("updatedAt"),
      ...table.getColumnSearchProps("updatedAt", "最終更新日"),
      title: "最終更新日",
      width: "170px",
      render: (item) => {
        return item.updatdAt
          ? dayjs(item.updatedAt).format("YYYY/MM/DD")
          : "--";
      },
      sorter: (a, b) =>
        (dayjs(a.updatedAt).format("YYYY/MM/DD") ?? "") >
        (dayjs(b.updatedAt).format("YYYY/MM/DD") ?? "")
          ? 1
          : -1,
    },
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: "138px",
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/operations/orders/${item.id}/edit`);
            }}
            disabled={!!item?.isConvert}
          >
            編集
          </CustomButton>
          <DeleteOrCancelCloseOrderButton
            inTable
            item={item}
            handleCancelClose={props.handleCancelClose}
            handleDelete={handleDelete}
          />
        </Space>
      ),
    },
  ];

  const selectedClosedIds = () =>
    idsForm.object.ids.filter(
      (id) =>
        !!props.dataSource?.find((order: Order) => order.id === id)?.isConvert
    );
  const selectedOpenIds = () =>
    idsForm.object.ids.filter(
      (id) =>
        !props.dataSource?.find((order: Order) => order.id === id)?.isConvert
    );

  const clearRowSelection = () => {
    idsForm.resetForm();
    table.setSelectedRowKeys([]);
  };

  return (
    <Space direction="vertical" style={{ width: "calc(100vw - 264px)" }}>
      <Space style={{ justifyContent: "space-between", width: "100%" }}>
        <div />
      </Space>
      <CustomTable
        tableKey="orders_table"
        extraLeft={<div>{table.selectedRowKeys.length}件選択中</div>}
        extraRight={
          <Space>
            {handleBulkDelete && (
              <Button
                danger
                disabled={!selectedOpenIds().length}
                onClick={() => {
                  handleBulkDelete(selectedOpenIds());
                  clearRowSelection();
                }}
              >
                一括削除
              </Button>
            )}
          </Space>
        }
        table={table}
        onChange={table.handleChange}
        pagination={table.paginationInfo}
        rowSelection={table.rowSelection}
        columns={orderColumns}
        onRow={(data, index) => ({
          onClick: () => props.history.push(`/operations/orders/${data?.id}`),
        })}
        dataSource={props.dataSource?.map((d) => ({ ...d, key: d?.id }))}
        // @ts-ignore
        scroll={{ y: "calc(100vh - 282px)" }}
        style={{ height: "calc(100vh - 290px)" }}
        {...rest}
      />
    </Space>
  );
};
