import {
  Button,
  Col,
  Modal,
  Popover,
  Progress,
  Row,
  Space,
  Table,
  TableProps,
  Tag,
  theme,
} from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import {
  Delivery,
  DeliveryCharterType,
  DeliveryCharterTypes,
  calculateTotalLoadWeight,
} from "entities/delivery";
import dayjs from "dayjs";
import { HistoryProps } from "routes/app";
import { CustomSelectField, CustomTextAreaField } from "specifics/input";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import { Form, useForm } from "utils/hooks";
import { useContext } from "react";
import { GlobalStateContext } from "contexts/global_state_context";
import { Vehicle } from "entities/vehicle";
import { CharteredCompany } from "entities/chartered_company";
import {
  OrderTypeView,
  getOrderCharterTypeLabel,
  getOrderTypeLabel,
} from "components/orders/orders_view";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomTableText } from "specifics/table_text";
import { RiExternalLinkFill } from "react-icons/ri";
import { FileTextOutlined } from "@ant-design/icons";
import { OrderPointType } from "entities/order";
import { IndexApiSet } from "utils/network/api_hooks";
import { DeliveriesResponse } from "api/delivery";

export type DeliveriesTableProps = TableProps<Delivery>;

export const DeliveriesTable = (
  props: DeliveriesTableProps & {
    form: Form<Delivery[]>; // FormのDelivery[] (可変)
    vehicles: Vehicle[];
    charteredCompanies: CharteredCompany[];
    onEdit: (form: Delivery) => void;
  } & HistoryProps & {
      listApi?: IndexApiSet<DeliveriesResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const table = useTable<
    Delivery & { loadingDate?: string; unloadingDate?: string }
  >(props);
  const deliveries = () => props.form.object;

  const editMemoForm = useForm<Delivery>({});

  const representDeliveryDetailDate = (
    delivery: Delivery,
    pointType: OrderPointType
  ) => {
    const list = delivery.deliveryDetails?.filter(
      (deliveryDetail) => deliveryDetail.pointType === pointType
    );
    if (!list?.length) {
      return "--";
    }
    const date = list[0]?.deliveryDate;
    return dayjs(date).format("MM/DD");
  };

  const columns: ColumnsType<Delivery> = [
    {
      key: "loadingDeliveryCompany",
      title: "積み地",
      width: 200,
      render: (item: Delivery) => {
        return (
          <Space direction="vertical" size={0}>
            {item.deliveryDetails
              ?.filter((detail) => detail.pointType === 2 ?? [])
              ?.map((detail, i) => (
                <Space size={0} key={`UnloadingDeliveryCompanyName${i}`}>
                  <CustomTableText width={100}>
                    {detail?.deliveryCompany?.name}
                  </CustomTableText>

                  <Popover content="積み地詳細へ移動">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.history.push(
                          `/delivery-companies/${detail.deliveryCompany?.id}`
                        );
                      }}
                      shape="circle"
                      type="text"
                      icon={<RiExternalLinkFill />}
                    />
                  </Popover>
                  <Popover trigger="hover" content="手順書へ移動">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.history.push(
                          `/check-exist-manuals?deliveryCompanyId=${detail.deliveryCompany?.id}&consignorId=${item.order?.consignorId}`
                        );
                      }}
                      shape="circle"
                      type="text"
                      icon={<FileTextOutlined />}
                    />
                  </Popover>
                </Space>
              ))}
          </Space>
        );
      },
      fixed: true,
    },
    {
      key: "loadingWeight",
      title: "積み重量",
      width: 100,
      render: (item: Delivery) => {
        return (
          <>
            {item.deliveryDetails
              ?.filter((od) => od.pointType === 2)
              ?.map((od) => (
                <div key={od.id}>{od.loadWeight}t</div>
              ))}
          </>
        );
      },
      fixed: true,
    },
    {
      key: "unloadingDeliveryCompany",
      title: "降し地",
      width: 200,

      render: (item: Delivery) => {
        return (
          <Space direction="vertical" size={0}>
            {item.deliveryDetails
              ?.filter((detail) => detail.pointType === 1 ?? [])
              ?.map((detail, i) => (
                <Space size={0} key={`UnloadingDeliveryCompanyName${i}`}>
                  <CustomTableText width={100}>
                    {detail?.deliveryCompany?.name}
                  </CustomTableText>

                  <Popover trigger="hover" content="降し地詳細へ移動">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.history.push(
                          `/delivery-companies/${detail.deliveryCompany?.id}`
                        );
                      }}
                      shape="circle"
                      type="text"
                      icon={<RiExternalLinkFill />}
                    />
                  </Popover>
                  <Popover trigger="hover" content="手順書へ移動">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.history.push(
                          `/check-exist-manuals?deliveryCompanyId=${detail.deliveryCompany?.id}&consignorId=${item.order?.consignorId}`
                        );
                      }}
                      shape="circle"
                      type="text"
                      icon={<FileTextOutlined />}
                    />
                  </Popover>
                </Space>
              ))}
          </Space>
        );
      },
      fixed: true,
    },
    {
      ...table.baseColumn("charterType"),
      title: "自社/傭車",
      width: 180,
      onCell: (record: Delivery, index?: number) => ({
        record,
        index,
        title: "自社/傭車",
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      filters: ([0, 1] as const).map((charterType) => ({
        text: getOrderCharterTypeLabel(charterType),
        value: charterType,
      })),
      onFilter: (value: string | number | boolean, record: Delivery) =>
        record.order?.orderType === value,
    },
    {
      title: "車両",
      width: 340,
      onCell: (record: Delivery, index?: number) => ({
        record,
        index,
        title: "車両",
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
    },
    {
      title: "車両の現在の積載状況",
      width: 200,
      render: (item: Delivery) => {
        if (!item.vehicleId) return "--";
        else {
          const vehicle = props.vehicles?.find((v) => v.id === item.vehicleId);
          const deliveriesRelatedToThisVehicle: Delivery[] =
            props.form.object?.filter((delivery: Delivery) => {
              return String(delivery.vehicleId) === String(item.vehicleId);
            }) ?? [];

          const currentLoadWeight: number = calculateTotalLoadWeight(
            deliveriesRelatedToThisVehicle,
            (detail) => detail.pointType === 2
          );

          const loadingTooMuch: boolean =
            currentLoadWeight > (vehicle?.maxWeight ?? 0);
          return (
            <div
              style={{
                textAlign: "center",
                fontWeight: loadingTooMuch ? 700 : "auto",
                color: loadingTooMuch ? token.colorError : "inherit",
              }}
            >
              {currentLoadWeight.toFixed(1)}t / {vehicle?.maxWeight ?? 0}t (
              {Math.round(
                (currentLoadWeight / (vehicle?.maxWeight ?? 1)) * 100
              )}
              %)
              <Progress
                size={"small"}
                showInfo={false}
                percent={Math.round(
                  (currentLoadWeight / (vehicle?.maxWeight ?? 1)) * 100
                )}
              />
            </div>
          );
        }
      },
    },
    // {
    //   title: "ドライバー",
    //   width: 200,
    //   onCell: (record: Delivery, index?: number) => ({
    //     record,
    //     index,
    //     title: "ドライバー",
    //     onClick: (e) => {
    //       e.stopPropagation();
    //     },
    //   }),
    // },
    {
      title: "傭車の保有会社",
      width: 200,
      onCell: (record: Delivery, index?: number) => ({
        record,
        index,
        title: "傭車の保有会社",
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
    },
    {
      key: "loadingDate",
      title: "積み日",
      width: 100,

      render: (item: Delivery) => {
        const list = item.deliveryDetails?.filter(
          (detail) => detail.pointType === 2
        );
        if (!list?.length) {
          return "--";
        }
        const date = list[0]?.deliveryDate;
        return dayjs(date).format("MM/DD");
      },
    },
    {
      ...table.baseColumn("unloadingDate"),
      ...table.getColumnSearchProps("unloadingDate", "降し日"),
      title: "降し日",
      width: "130px",
      sorter: (a, b) =>
        representDeliveryDetailDate(a, 1) > representDeliveryDetailDate(b, 1)
          ? 1
          : -1,
      render: (item: Delivery) => {
        return representDeliveryDetailDate(item, 1);
      },
      onFilter: (value, record) => {
        const recordValue = representDeliveryDetailDate(record, 1);
        return recordValue?.includes(String(value));
      },
    },
    {
      ...table.baseColumn("orderNo"),
      title: "受注番号",
      sorter: (a, b) =>
        (a.order?.orderNo ?? "") > (b.order?.orderNo ?? "") ? 1 : -1,
      render: (item: Delivery) => item.order?.orderNo ?? "--",
      width: 130,
    },
    {
      ...table.baseColumn("orderType"),
      title: "カテゴリ",
      width: "130px",
      filters: ([0, 1] as const).map((orderType) => ({
        text: getOrderTypeLabel(orderType),
        value: orderType,
      })),
      onFilter: (value: string | number | boolean, record: Delivery) =>
        record.order?.orderType === value,
      render: (item) => OrderTypeView(item.order),
    },
    {
      ...table.baseColumn("order.consignor.name"),
      ...table.getColumnSearchProps("order", "荷主"),
      onFilter: (value, record) =>
        (record.order?.consignor?.name || "")
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      title: "荷主",
      width: "240px",
      render: (item) => (
        <Space>
          <CustomTableText width={180}>
            {item.order?.consignor?.name}
          </CustomTableText>
          {item.order?.consignor && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.history.push(`/consignors/${item.order?.consignor.id}`);
              }}
              shape="circle"
              type="text"
              icon={<RiExternalLinkFill />}
            />
          )}
        </Space>
      ),
    },
    {
      title: "備考",
      width: 350,
      onCell: (record: Delivery, index?: number) => ({
        record,
        index,
        title: "備考",
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
    },
  ];

  const EditableCell = (cellProps: EditableCellProps) => {
    const { title, inputType, record, children, ...restProps } = cellProps;
    const sortedIndex = props.form.object
      .map((item: Delivery) => item?.id)
      .indexOf(record?.id);
    switch (title) {
      case "自社/傭車":
        return (
          <td {...restProps}>
            <CustomSelectField
              form={props.form}
              attr={[sortedIndex, "charterType"]}
              style={{ width: 150 }}
              selectItems={DeliveryCharterTypes.map(
                (charterType: DeliveryCharterType) => ({
                  label: getOrderCharterTypeLabel(charterType),
                  value: charterType,
                })
              )}
              onChange={(e) => {
                if (props.form.object[sortedIndex].id) {
                  props.onEdit({
                    id: props.form.object[sortedIndex].id,
                    charterType: e,
                    // @ts-ignore
                    userId: null,
                    // @ts-ignore
                    vehicleId: null,
                    // @ts-ignore
                    charteredCompanyId: null,
                  });
                }
              }}
            />
          </td>
        );
      case "車両":
        return (
          <td {...restProps}>
            {props.form.getValue([sortedIndex, "charterType"]) === 0 ? (
              <CustomSelectField
                form={props.form}
                attr={[sortedIndex, "vehicleId"]}
                style={{ width: 310 }}
                onChange={(e) => {
                  if (props.form.object[sortedIndex].id) {
                    props.onEdit({
                      id: props.form.object[sortedIndex].id,
                      vehicleId: e,
                      userId: props.vehicles.find((v) => v.id === e)?.userId,
                    });
                  }
                }}
                selectItems={props.vehicles.map((vehicle, i) => ({
                  label: (
                    <Space>
                      {vehicle.vehicleCode}
                      <Tag color="cyan">担当:{vehicle.user?.name}</Tag>
                      <Tag color="pink">
                        最大積載量:{vehicle.maxWeight ?? 0}t
                      </Tag>
                    </Space>
                  ),
                  value: vehicle.id,
                }))}
              />
            ) : (
              "--"
            )}
          </td>
        );
      case "傭車の保有会社":
        return (
          <td {...restProps}>
            {props.form.getValue([sortedIndex, "charterType"]) === 1 ? (
              // 傭車
              <CustomSelectField
                form={props.form}
                attr={[sortedIndex, "charteredCompanyId"]}
                style={{ width: 150 }}
                selectItems={props.charteredCompanies.map(
                  (charteredCompany, i) => ({
                    label: charteredCompany.name,
                    value: charteredCompany.id,
                  })
                )}
                onChange={(e) => {
                  if (props.form.object[sortedIndex].id) {
                    props.onEdit({
                      id: props.form.object[sortedIndex].id,
                      charteredCompanyId: e,
                    } as Delivery);
                  }
                }}
              />
            ) : (
              // 自車
              "--"
            )}
          </td>
        );
      case "備考":
        return (
          <td {...restProps}>
            {/* <CustomTextAreaField
                style={{ height: 30 }}
                form={props.form}
                attr={[index, "memo"]}
                onChange={(e) => {
                  if (props.form.object[index].id) {
                    props.onEdit({
                      id: props.form.object[index].id,
                      memo: e,
                    });
                  }
                }}
              /> */}
            <Space>
              <div style={{ width: 200 }}>
                {props.form.object[sortedIndex]?.memo ?? "--"}
              </div>
              <Button onClick={() => editMemoForm.set({ ...record })}>
                備考を編集
              </Button>
            </Space>
          </td>
        );
      default:
        return <td {...restProps}>{children}</td>;
    }
  };

  return (
    <>
      <CustomTable
        table={table}
        tableKey="deliveries_table"
        pagination={table.paginationInfo}
        onChange={table.handleChange}
        columns={columns}
        dataSource={deliveries()}
        onRow={(data, index) => ({
          onClick: () => props.history.push(`/orders/${data.orderId}`),
        })}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        {...props}
      />
      <Modal
        open={!!editMemoForm.object.id}
        onCancel={() => editMemoForm.resetForm()}
        onOk={() => {
          props.onEdit({
            id: editMemoForm.object.id,
            memo: editMemoForm.object.memo,
          });
          editMemoForm.resetForm();
        }}
        title="備考の編集"
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row>
            <Col span={12}>
              <DescriptionBlock label="受注番号">
                {editMemoForm.object.order?.orderNo}
              </DescriptionBlock>
            </Col>
            <Col span={12}>
              <DescriptionBlock label="カテゴリ">
                {OrderTypeView(editMemoForm.object.order)}
              </DescriptionBlock>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DescriptionBlock label="受注日">
                {dayjs(editMemoForm.object.order?.orderDate).format(
                  "YYYY/MM/DD"
                )}
              </DescriptionBlock>
            </Col>
            <Col span={12}>
              <DescriptionBlock label="荷主">
                {editMemoForm.object.order?.consignor?.name}
              </DescriptionBlock>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CustomTextAreaField
                label="備考"
                style={{ height: 60 }}
                form={editMemoForm}
                attr={"memo"}
              />
            </Col>
          </Row>
        </Space>
      </Modal>
    </>
  );
};

type EditableCellProps = React.HTMLAttributes<HTMLElement> & {
  title: any;
  inputType: "number" | "text";
  record: Delivery;
  index: number;
  children: React.ReactNode;
};
