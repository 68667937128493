import { MetricsForm } from "components/analysis/metrics/metrics_config_form";
import { useEffect, useState } from "react";
import {
  ApiSet,
  BaseResponse,
  usePostObjectApi,
} from "utils/network/api_hooks";
import { HttpClient } from "utils/network/axios";

export type AnalysisMetrics = {
  metrics?: ({
    datetime?: string; // "YYYY-MM-DD"
  } & Record<string, number>)[];
  query?: MetricsForm;
};

export type AnalysisMetricsResponse = BaseResponse<AnalysisMetrics>;

export function usePostAnalysisConfigApi(): ApiSet<AnalysisMetricsResponse> & {
  execute: (params: MetricsForm) => void;
} {
  const [metricsFrom, setMetricsForm] = useState<MetricsForm>();
  const apiSet = usePostObjectApi<AnalysisMetricsResponse, MetricsForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true, originalResponse: true }
  );

  const execute = (params: MetricsForm) => {
    const apiPath = `analysis`;
    apiSet.execute(apiPath, params);
    setMetricsForm(params);
  };

  return {
    ...apiSet,
    execute,
    response: {
      ...apiSet.response,
      data: {
        ...apiSet.response.data,
        query: metricsFrom,
      },
    },
  };
}
