import { Button, ButtonProps, Popconfirm, PopconfirmProps, theme, Modal, ModalFuncProps } from "antd";

import { CSSProperties, HTMLAttributes, useState } from "react";
import useMedia from "use-media";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";

type DialogconfirmProps = ModalFuncProps & { footerStyle?: CSSProperties }

export const CustomButton = (
  props: ButtonProps & {
    confirm?: boolean;
    popconfirmProps?: PopconfirmProps;
    dialogconfirmProps?: DialogconfirmProps;
  }
) => {
  const { onClick, danger, style, disabled, type, confirm, popconfirmProps, dialogconfirmProps, ...rest } =
    props;
  const { okButtonProps, cancelButtonProps, ...popconfirmPropsRest } =
    popconfirmProps ?? {};
  const isMobile = useMedia("(max-width: 519px)");
  const confirmOkButtonProps: ButtonProps = {
    type: danger ? "default" : "primary",
    style: {
      height: 24,
    },
  };
  const confirmCancelButtonProps: ButtonProps = {
    type: danger ? "primary" : "default",
    style: {
      height: 24,
    },
  };

  return dialogconfirmProps ?
    (
      <ButtonDialogConfirm {...props} {...{ isMobile }}>
      </ButtonDialogConfirm>
    ) :
    (
      <ButtonPopconfirm
        icon={
          danger ? (
            <ExclamationCircleOutlined style={{ color: "red" }} />
          ) : undefined
        }
        disabled={!props.confirm || props.disabled}
        placement="topLeft"
        title={"確認"}
        style={{ borderRadius: 8 }}
        overlayInnerStyle={{ borderRadius: 8 }}
        onCancel={(e: any) => {
          e.stopPropagation();
        }}
        onConfirm={(e: any) => {
          onClick && onClick(e);
        }}
        okButtonProps={{
          ...confirmOkButtonProps,
          ...okButtonProps,
          style: {
            ...confirmOkButtonProps.style,
            ...okButtonProps?.style,
          },
        }}
        cancelButtonProps={{
          ...confirmCancelButtonProps,
          ...cancelButtonProps,
          style: {
            ...confirmCancelButtonProps.style,
            ...cancelButtonProps?.style,
          },
        }}
        okText="はい"
        cancelText="いいえ"
        {...popconfirmPropsRest}
      >
        <Button
          style={{
            borderRadius: 6,
            height: !isMobile ? 42 : 38,
            transition: "none",
            fontWeight: 600,
            ...style,
          }}
          disabled={disabled}
          onClick={(e: any) => {
            e.stopPropagation();
            !props.confirm && onClick ? onClick(e) : null;
          }}
          type={type}
          danger={danger}
          {...rest}
        />
      </ButtonPopconfirm>
    );
};


export const ButtonDialogConfirm = (props: ButtonProps & {
  dialogconfirmProps?: DialogconfirmProps; isMobile?: boolean,
}) => {
  const { style, isMobile, children, dialogconfirmProps, onClick, ...rest } = props;

  const { title, okButtonProps, cancelButtonProps, okText, cancelText, content, onOk, footerStyle, ...restDialogProps } =
    dialogconfirmProps || {};

  const titleNode = <span style={{ fontWeight: "bold", fontSize: 18 }}>{title}</span>;
  const contentNode = <span style={{ fontSize: 15 }}>{content}</span>;

  const confirmOkButtonProps: ButtonProps = {
    ...okButtonProps,
    style: {
      fontWeight: "bold",
      fontSize: 16,
      height: !isMobile ? 42 : 38,
      ...okButtonProps?.style,
    },
  };

  const confirmCancelButtonProps: ButtonProps = {
    ...cancelButtonProps,
    style: {
      fontWeight: "bold",
      fontSize: 16,
      height: !isMobile ? 42 : 38,
      ...cancelButtonProps?.style,
    },
  };
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
    onOk && onOk();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        style={{
          borderRadius: 6,
          height: !isMobile ? 42 : 38,
          transition: "none",
          fontWeight: 600,
          ...style,
        }}
        onClick={(e) => {
          onClick && onClick(e);
          showModal()
        }}
        {...rest}
      >
        {children}
      </Button>
      <Modal
        open={open}
        title={titleNode}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: 24, ...footerStyle }}>
            <Button onClick={handleCancel} {...confirmCancelButtonProps}>{cancelText}</Button>
            <Button onClick={handleOk} {...confirmOkButtonProps}>{okText}</Button>
          </div>
        }
        onCancel={handleCancel}
        {...restDialogProps}
      >
        {contentNode}
      </Modal>
    </>
  );
};

export const ButtonPopconfirm = (props: PopconfirmProps) => {
  return !props.disabled ? (
    <Popconfirm {...props}>{props.children}</Popconfirm>
  ) : (
    <>{props.children}</>
  );
};

export const UploadCSVFileButton = (props: HTMLAttributes<HTMLDivElement>) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
        gap: "4px",
        width: 241,
        height: 42,
        border: "2px dashed #D1D5DB",
        borderRadius: "6px",
        flex: "none",
        order: 0,
        flexGrow: 0,
        cursor: hover ? "pointer" : "auto",
        background: hover ? token.colorBgBase : "inherit",
        transition: "none",
      }}
      {...props}
    >
      <UploadOutlined style={{ color: token.colorPrimary }} />
      <div
        style={{
          padding: 0,
          width: 184,
          height: 20,
          fontSize: 14,
          fontWeight: 500,
          textAlign: "justify",
          color: token.colorPrimary,
          cursor: "pointer",
        }}
      >
        CSVファイルをアップロード
      </div>
    </div>
  );
};
