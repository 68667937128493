import { Space, Table, Upload, theme } from "antd";
import { useFetchIssueCountApi, useFetchIssuesApi, useSolveIssueApi } from "api/issue";
import { useFetchUsersApi } from "api/user";
import { GlobalStateContext } from "contexts/global_state_context";

import { IssueSearchForm } from "entities/issue";
import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomInputField } from "specifics/input";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { IssuesTable } from "./issues_table";
import dayjs from "dayjs";
import { useFetchdeliveryCompaniesApi } from "api/delivery_company";

const IssuesPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchIssuesApi();
  const solveApi = useSolveIssueApi();
  const issueCountApi = useFetchIssueCountApi();

  useEffect(() => {
    listApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(solveApi.loading);
    if (solveApi.isSuccess()) {
      listApi.execute();
      issueCountApi.execute();
    }
  }, [solveApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(issueCountApi.loading);
    if (issueCountApi.isSuccess()) {
      globalState.setIssueCount(issueCountApi.response.data || 0);
    }
  }, [issueCountApi.loading]);

  const issues = () => listApi.response.data;

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="報告受信"
    >
      <IssuesTable
        history={props.history}
        dataSource={issues()}
        handleSolve={(id) => solveApi.execute(id)}
        listApi={listApi}
        pagination={{
          current: listApi.response.meta?.currentPage,
          pageSize: listApi.response.meta?.perPage,
          total: listApi.response.meta?.total,
        }}
      />
    </CustomPageHeader>
  );
};

export default withRouter(IssuesPage);
