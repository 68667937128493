import {
  ApiSet,
  BaseResponse,
  usePostObjectApi,
} from "utils/network/api_hooks";
import { HttpClient } from "../utils/network/axios";

import { UploadFile } from "antd";

type UploadResponse = BaseResponse & {
  data: { filename?: string };
};

export function useUploadApi(): ApiSet<UploadResponse> & {
  execute: (file: UploadFile<any>) => Promise<UploadResponse>;
} {
  const apiSet = usePostObjectApi<
    UploadResponse,
    { file: UploadFile<any> }
  >(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    // { formatJson: true }
  );

  const execute = async (file: UploadFile<any>) => {
    const apiPath = `upload`;
    return await apiSet.execute(apiPath, { file });
  };

  return { ...apiSet, execute };
}
