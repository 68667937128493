import { Space, Tag } from "antd";
import { User, UserType } from "entities/user";

export const UserTypeView = ({ user }: { user?: User }) => {
  switch (user?.type) {
    case UserType.運行管理者:
      return (
        <Space direction="vertical" size={4}>
          <div>運行管理者</div>
          <Tag color={user?.canAccessBoard ? "blue" : "red"}>
            ボード利用{user?.canAccessBoard ? "可能" : "不可"}
          </Tag>
        </Space>
      );
    case UserType.ドライバー:
      return <>ドライバー</>;
    case UserType.knewit社員:
      return <>knewit社員</>;
    default:
      return <>--</>;
  }
};
