import { Space, theme } from "antd";
import {
  useDeleteCharteredVehicleApi,
  useFetchCharteredVehicleApi,
} from "api/chartered_vehicle";
import { GlobalStateContext } from "contexts/global_state_context";

import { CharteredCompany } from "entities/chartered_company";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { HistoryProps } from "routes/app";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { Prefectures } from "utils/util";
import { CharteredVehicleTypeView, getIndex } from "./chartered_vehicles_view";

const CharteredVehiclePage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const charteredVehicleApi = useFetchCharteredVehicleApi();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    charteredVehicleApi.execute(params.id);
  }, []);

  const deleteApi = useDeleteCharteredVehicleApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      props.history.push("/operations/chartered-vehicles");
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(charteredVehicleApi.loading);
  }, [charteredVehicleApi.loading]);

  const charteredCompany = (): CharteredCompany => {
    return charteredVehicleApi.response.data;
  };

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="傭車詳細"
      onBack={() => props.history.push("/operations/chartered-vehicles")}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent>
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <Space
            style={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            <DescriptionBlock label="名称">
              {charteredCompany()?.name}
            </DescriptionBlock>
            <Space size={24} style={{ verticalAlign: "top", height: "100%" }}>
              <CustomButton
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                onClick={() =>
                  props.history.push(
                    `/operations/chartered-vehicles/${charteredCompany()?.id}/edit`
                  )
                }
              >
                編集
              </CustomButton>
              <CustomButton
                danger
                type="text"
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  padding: 0,
                }}
                confirm
                popconfirmProps={{
                  title: "削除します。よろしいですか？",
                }}
                onClick={() => {
                  deleteApi.execute(params.id);
                }}
              >
                削除
              </CustomButton>
            </Space>
          </Space>

          <DescriptionBlock label="ふりがな">
            {charteredCompany()?.kana}
          </DescriptionBlock>
          <DescriptionBlock label="郵便番号">
            {charteredCompany()?.zipcode}
          </DescriptionBlock>
          <DescriptionBlock label="住所">
            {charteredCompany()?.address}
          </DescriptionBlock>
          <DescriptionBlock label="担当者氏名">
            {charteredCompany()?.repName}
          </DescriptionBlock>
          <DescriptionBlock label="電話番号">
            {charteredCompany()?.tel}
          </DescriptionBlock>
          <DescriptionBlock label="保有車両">
            <Space direction="vertical" size={8}>
              {charteredCompany()?.charteredVehicles?.length === 0 && "--"}
              {charteredCompany()?.charteredVehicles?.map(
                (charteredVehicle, index) => (
                  <Space
                    key={charteredVehicle?.id}
                    size={16}
                    style={{
                      width: 755,
                      background: token.colorBgBase,
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      padding: 16,
                      borderRadius: 8,
                    }}
                  >
                    <Space
                      style={{
                        alignItems: "flex-start",
                      }}
                    >
                      <div>{getIndex(charteredCompany(), index) + 1}.</div>
                      <DescriptionBlock label="車両種類">
                        {CharteredVehicleTypeView(charteredVehicle)}
                      </DescriptionBlock>
                    </Space>
                    <DescriptionBlock label="車格" style={{ width: 110 }}>
                      {charteredVehicle?.weight}t
                    </DescriptionBlock>
                  </Space>
                )
              )}

              <DescriptionBlock label="主要エリア">
                {charteredCompany()
                  ?.area?.split(",")
                  .map((prefectureId) => Prefectures[prefectureId])
                  .join(",")}
              </DescriptionBlock>
            </Space>
          </DescriptionBlock>
          {/* <DescriptionBlock label="主要エリア">
            {charteredCompany()?.area?.map((area) => (
              <CustomTag key={area}>{area}</CustomTag>
            ))}
          </DescriptionBlock> */}
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(CharteredVehiclePage);
