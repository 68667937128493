import { BaseEntity } from "entities";
import { Manual } from "./manual";
import { DeliveryCompany } from "./delivery_company";
import { valueType } from "antd/es/statistic/utils";
import { DeliveryPartnerCompany } from "./delivery_partner_company";

export const NEED_TASK = [
  "なし",
  "仕分",
  "棚入れ/棚出し",
  "ラベル貼り",
  "かんばん",
  "パレットへの載せ替え",
  "入出庫処理",
  "その他（自由入力）",
] as const;

export const HANDLING_TASK = [
  "なし",
  "手積み・手降し",
  "クレーン",
  "玉掛け",
  "フォークリフト",
  "その他（自由入力）",
] as const;

export type NEED_TASK_TYPE = (typeof NEED_TASK)[number];

export enum DeliveryTerm {
  "指定置き場渡し" = 0,
  "車上渡し" = 1,
  "その他" = 2
}

export type DeliveryTermType = keyof typeof DeliveryTerm;

export enum HandlingTerm {
  "自主荷役" = 0,
  "納入先が荷役" = 1,
}

export type HandlingTermType = keyof typeof HandlingTerm;

export enum TotalTime {
  '１時間以内' = 0,
  '１時間〜２時間' = 1,
  '２時間以上' = 2
}

export type TotalTimeType = keyof typeof TotalTime;

export enum AcceptanceTime {
  '１５分以内' = 3,
  '１５分〜３０分' = 0,
  '３０分〜６０分' = 1,
  '６０分以上' = 2
}

export type AcceptanceTimeType = keyof typeof AcceptanceTime;

export enum WaitingTime {
  'なし' = 0,
  '１５分以内' = 3,
  '１５分〜３０分' = 1,
  '３０分以上' = 2,
}

export type WaitingTimeType = keyof typeof WaitingTime;

export enum ReceiptHandOverTime {
  'なし' = 0,
  '１０分' = 3,
  '１０分〜３０分' = 1,
  '３０分以上' = 2,
}

export enum ContractItems {
  deliveryTerm = "荷渡し条件",
  deliveryTermOther = "その他の荷渡し条件",
  handlingTerm = "荷役",
  handlingTask = "荷役作業",
  handlingTaskOther = "その他の荷役作業",
  needTask = "附帯業務",
  needTaskOther = "その他の附帯業務",
  totalTime = "入門〜出門までの所要時間",
  acceptanceTime = "受入の所要時間",
  waitingTimeForBerth = 'バース待ち時間',
  waitingTimeForHandling = '荷役待ち時間',
  waitingTimeForReceipt = '納品書/受領書の受け渡しの待ち時間',
}

export const ContractItemMapping = {
  totalTime: TotalTime,
  acceptanceTime: AcceptanceTime,
  waitingTimeForBerth: WaitingTime,
  waitingTimeForHandling: WaitingTime,
  waitingTimeForReceipt: ReceiptHandOverTime,
  deliveryTerm: DeliveryTerm,
  deliveryTermOther: "deliveryTerm",
  handlingTerm: HandlingTerm,
  handlingTask: HANDLING_TASK,
  handlingTaskOther: "handlingTask",
  needTask: NEED_TASK,
  needTaskOther: "needTask",
};

export type ContractItemValueType = TotalTime | AcceptanceTime | WaitingTime | WaitingTime | ReceiptHandOverTime | DeliveryTerm | Array<string> | string | null;

export type ContractItem = {
  totalTime?: TotalTime;
  acceptanceTime?: AcceptanceTime;
  existWaitingTimeForBerth?: boolean;
  waitingTimeForBerth?: WaitingTime | null;
  existWaitingTimeForHandling?: boolean;
  waitingTimeForHandling?: WaitingTime | null;
  existWaitingTimeForReceipt?: boolean;
  waitingTimeForReceipt?: ReceiptHandOverTime | null;
  handlingTerm?: HandlingTerm
  deliveryTerm?: DeliveryTerm;
  deliveryTermOther?: string | null;
  existHandlingTask?: boolean;
  handlingTask?: string | null;
  handlingTaskOther?: string | null;
  existNeedTask?: boolean;
  needTask?: string | null;
  needTaskOther?: string | null;
}

export enum Status {
  "回答期限切れ" = 1,
  "未記入" = 2,
  "一年経過" = 3,
  "半年経過" = 4,
  "三ヶ月経過" = 5,
  "依頼中" = 6,
  "その他" = 7,
}

export enum GroupBy {
  "すべての担当カルテ" = "all",
  "未記入のみ" = "unfilled",
  "期間超過のみ" = "exceeded",
  "未記入+期間超過" = "fullFiltered",
}

export type Contract = BaseEntity & {
  title?: string;
  manual?: Manual;
  deliveryCompany?: DeliveryCompany;
  deliveryPartnerCompany?: DeliveryPartnerCompany;
  status?: Status;
  items?: string;
  counts?: number;
  manualUpdatedAt?: string;
  contractUpdatedAt?: string;
};

export const hasMismatch = (title?: string, actual?: valueType | null, contract?: valueType | null) => {
  switch (title) {
    case "その他の荷渡し条件":
      return false;

    case "その他の荷役作業":
      return false;

    case "その他の附帯業務":
      return false;

    default:
      return actual != contract;
  }
}

export const formatContractFieldValue = (target?: Manual | DeliveryCompany, title?: string) => {
  switch (title) {
    case "入門〜出門までの所要時間":
      return target?.totalTime != undefined
        ? TotalTime[target?.totalTime]
        : "--";

    case "受入の所要時間":
      return target?.acceptanceTime != undefined
        ? AcceptanceTime[target.acceptanceTime]
        : "--";

    case "荷渡し条件":
      return target?.deliveryTerm != undefined
        ? DeliveryTerm[target.deliveryTerm]
        : "--";

    case "荷役":
      return target?.handlingTerm != undefined
        ? HandlingTerm[target.handlingTerm]
        : "--";

    case "荷役作業":
      return target?.handlingTask != undefined
        ? target.handlingTask
        : "--";

    case "附帯業務":
      return target?.needTask != undefined
        ? target.needTask
        : "--";

    case "その他の荷渡し条件":
      return target?.deliveryTermOther != undefined
        ? target.deliveryTermOther
        : undefined;

    case "その他の荷役作業":
      return target?.handlingTaskOther != undefined
        ? target.handlingTaskOther
        : undefined;

    case "その他の附帯業務":
      return target?.needTaskOther != undefined
        ? target.needTaskOther
        : undefined;

    case "バース待ち時間":
      return target?.waitingTimeForBerth != undefined ?
        WaitingTime[target.waitingTimeForBerth]
        : "--";

    case "荷役待ち時間":
      return target?.waitingTimeForHandling != undefined ?
        WaitingTime[target.waitingTimeForHandling]
        : "--";

    case "納品書/受領書の受け渡しの待ち時間":
      return target?.waitingTimeForReceipt != undefined ?
        ReceiptHandOverTime[target.waitingTimeForReceipt]
        : "--";

    default:
      return "--";
  }
}

export type ContractInputRequestForm = {
  deliveryPartnerId?: string;
  groupBy?: string;
}

export type GraphContract = {
  [key: string]: {
    id: string,
    total: number,
    mismatched: number,
    mismatchPercentage: number
  }
}