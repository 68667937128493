import { Space } from "antd";
import { DriversManualPage } from "components/drivers_manuals/drivers_manual_page";
import { DriversManualsCreatePageInner, DriversManualsEditPageInner } from "components/drivers_manuals/drivers_maunal_form";
import { ManualPage } from "components/manuals/manual_page";
import { ManualsEditPageInner, _ManualsCreatePage } from "components/manuals/manuals_form_page";
import { ManualKey } from "entities/manual";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import useMedia from "use-media";
import { CookieManager } from "utils/cookie_manager";
import { useQuery } from "utils/hooks";

const _GuestManualsCreatePage = (props: HistoryProps) => {
  const params = useQuery<{ token: string }>();
  CookieManager.saveUserToken(params.token);
  const isMobile = useMedia("(max-width: 519px)");
  return isMobile ?
    <DriversManualsCreatePageInner guest {...props} /> :
    <_ManualsCreatePage guest {...props} />;
}

const _GuestManualsEditPage = (props: HistoryProps) => {
  const params = useQuery<{ token: string; manualId: string; key: ManualKey }>();
  params.token && CookieManager.saveUserToken(params.token);
  const isMobile = useMedia("(max-width: 519px)");
  return (
    <Space style={{ justifyContent: "center", width: "100%" }}>
      {isMobile ?
        <DriversManualsEditPageInner guest manualId={params.manualId} _key={Number(params.key) || ManualKey.基本情報} onEdit={() => { props.history.push("/confirmation", { title: "納入カルテの記入" }) }} {...props} /> :
        <ManualsEditPageInner guest _key={Number(params.key) || ManualKey.基本情報} {...props} />
      }
    </Space>
  );
}

const _GuestManualPage = (props: HistoryProps) => {
  const params = useQuery<{ token: string; isEdit: boolean; delivererId: string; }>();
  CookieManager.saveUserToken(params.token);
  const isMobile = useMedia("(max-width: 519px)");
  return isMobile ?
    <DriversManualPage guest isEdit={params.isEdit} delivererId={params.delivererId} {...props} /> :
    <ManualPage guest isEdit={params.isEdit} delivererId={params.delivererId} {...props} />;
}

export const GuestManualsCreatePage = withRouter(_GuestManualsCreatePage);
export const GuestManualsEditPage = withRouter(_GuestManualsEditPage);
export const GuestManualPage = withRouter(_GuestManualPage);