import { Flex } from 'antd';
import { GlobalStateContext } from 'contexts/global_state_context';
import { CustomSetting, DefaultSetting, SettingForm, SettingType } from 'entities/setting';
import { useContext } from 'react';
import { CustomButton } from 'specifics/button';
import { CustomInputField, CustomInputNumberField, CustomSelectField, CustomTextAreaField } from 'specifics/input';
import { Form, useEffectSkipFirst } from 'utils/hooks';

type SettingFormItemProps = {
  defaultSetting: DefaultSetting;
  form: Form<SettingForm>;
  validationResultForm: Form<SettingForm>;
  attr: string;
  onSave: () => void;
  onDefault: (category: string, key: string) => void;
};

const SettingFormItem = ({ defaultSetting, form, validationResultForm, attr, onSave, onDefault }: SettingFormItemProps) => {
  const globalState = useContext(GlobalStateContext);

  const isSelectItem = (defaultSetting.type === SettingType.select || defaultSetting.type === SettingType.multipleSelect);
  useEffectSkipFirst(() => {
    // selectタイプの選択肢に変更があった時
    if (globalState.setting?.customSetting &&
      form.getValue(["content", attr]) !== globalState.setting.customSetting[attr as keyof CustomSetting]
    ) {
      onSave();
    }
  }, [isSelectItem && form.getValue(["content", attr])]);

  const renderInputField = () => {
    switch (defaultSetting.type) {
      case SettingType.number:
        return (
          <Flex align='center' gap={10} style={{ marginTop: 10 }}>
            <CustomInputNumberField
              form={form}
              validationResultForm={validationResultForm}
              attr={["content", attr]}
            />
            <CustomButton
              type='primary'
              onClick={() => onSave()}
            >
              保存
            </CustomButton>
          </Flex>
        );

      case SettingType.text:
        return (
          <CustomInputField
            form={form}
            validationResultForm={validationResultForm}
            attr={["content", attr]}
          />
        );

      case SettingType.textarea:
        return (
          <>
            <CustomTextAreaField
              form={form}
              validationResultForm={validationResultForm}
              attr={["content", attr]}
              style={{ padding: 7 }}
            />
            <Flex gap={10} style={{ marginTop: 10 }}>
              <CustomButton
                onClick={() => onDefault(defaultSetting.category, attr)}
              >
                デフォルトに戻す
              </CustomButton>
              <CustomButton
                type='primary'
                onClick={() => onSave()}
              >
                保存
              </CustomButton>
            </Flex>
          </>
        );

      case SettingType.select:
        return (
          <CustomSelectField
            form={form}
            validationResultForm={validationResultForm}
            attr={["content", attr]}
            selectItems={Object.entries(defaultSetting.items || {}).map(([label, value]) => ({ label, value }))}
            fieldProps={{
              value: Number(form.getValue(["content", attr]))
            }}
          />
        );

      case SettingType.multipleSelect:
        return (
          <CustomSelectField
            form={form}
            validationResultForm={validationResultForm}
            attr={["content", attr]}
            selectItems={
              defaultSetting.items ?
                Object.entries(defaultSetting.items || {}).map(([label, value]) => ({ label, value })) :
                globalState.users
                  .filter(user => !!user.email)
                  .map(({ name, email }) => ({ label: name, value: email }))
            }
            fieldProps={{
              mode: "multiple",
              value: form.getValue(["content", attr]) || []
            }
            }
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <div>
      {renderInputField()}
    </div>
  );
};

export default SettingFormItem;
