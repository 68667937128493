import {
  Col,
  Radio,
  Row,
  Space,
  message,
  theme,
} from "antd";
import _ from "lodash";

import { Modal, ModalProps } from "antd";
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useEffectSkipFirst, useForm, useQuery } from "utils/hooks";
import { CustomSelectField, CustomSelectRadioField } from "specifics/input";
import { BaseSearchForm } from "entities";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { Contract, ContractInputRequestForm, GroupBy } from "entities/contract";
import { copyToClipboard, filterKanaName, queryString } from "utils/util";
import { useFetchMismatchContractsApi } from "api/contract";
import { ContractInputRequestTable } from "./table";
import { HistoryProps } from "routes/app";
import dayjs from "dayjs";
import { useGenerateTemporaryTokenApi } from "api/temporary_token";
import { CustomButton } from "specifics/button";
import { useBulkRequestManualsApi } from "api/manual";
import { GlobalStateContext } from "contexts/global_state_context";
import { Manual } from "entities/manual";

enum StepValue {
  運送会社の選択 = 1,
  カルテの選択 = 2,
  入力依頼 = 3
}

enum RequestStatus {
  依頼を完了 = 1,
  依頼を取り消し = 2
}

const useModalSteps = (selected: boolean) => {
  const initialStep = selected ? StepValue.カルテの選択 : StepValue.運送会社の選択;
  const [currentStep, setCurrentStep] = useState<StepValue>(initialStep);

  const toNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const toPriviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return {
    currentStep,
    toNextStep,
    toPriviousStep,
  };
};

export const ContractInputRequestModal = ({
  deliveryPartnerCompanies,
  selectedDeliveryPartnerCompany,
  onCancel,
  ...props
}: ModalProps & HistoryProps & {
  deliveryPartnerCompanies?: DeliveryPartnerCompany[];
  selectedDeliveryPartnerCompany?: DeliveryPartnerCompany;
}) => {
  const {
    currentStep,
    toNextStep,
    toPriviousStep,
  } = useModalSteps(!!selectedDeliveryPartnerCompany);
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const form = useForm<ContractInputRequestForm>({ deliveryPartnerId: selectedDeliveryPartnerCompany?.id, groupBy: GroupBy.すべての担当カルテ });
  const validationResultForm = useForm<ContractInputRequestForm>({});
  const deliveryPartnerCompany = () => deliveryPartnerCompanies?.find(company => company.id === form.getValue("deliveryPartnerId")) || selectedDeliveryPartnerCompany;
  const listApi = useFetchMismatchContractsApi();
  const generateTokenApi = useGenerateTemporaryTokenApi();
  const bulkRequestManualsApi = useBulkRequestManualsApi();
  const query = useQuery<BaseSearchForm>();
  const result = useMemo(() => listApi.response, [listApi.loading]);
  const [selectedManuals, setSelectedManuals] = useState<Contract[]>([]);
  const [isFinishModalVisible, setIsFinishModalVisible] = useState<boolean>(false);
  const [value, setValue] = useState();

  useEffectSkipFirst(() => {
    const id = form.object.deliveryPartnerId || '';
    const filter = form.object.groupBy || '';
    listApi.execute(`/${id}/${filter}${queryString(query)}`);
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (form.object.deliveryPartnerId && form.object.groupBy) {
      const id = form.object.deliveryPartnerId;
      const filter = form.object.groupBy;
      const expiresAt = globalState.setting.customSetting?.reminderPeriodDays;

      props.history.push(`/operations/contracts/${form.object.deliveryPartnerId || selectedDeliveryPartnerCompany?.id}/${filter}${queryString({ ...query, expiresAt, page: 1, })}`);
      listApi.execute(`/${id}/${filter}${queryString({ page: 1, expiresAt, })}`);
    }
  }, [form.object.deliveryPartnerId, form.object.groupBy]);

  const isStep = (step: StepValue) => currentStep === step;

  const url = async (expiresAt: string) => {
    // 一時トークンを生成
    const res = await generateTokenApi.execute({ expirationOption: globalState.setting.customSetting?.reminderPeriodDays });
    return `${process.env.REACT_APP_ADMIN_HOST}contracts/completion/${deliveryPartnerCompany()?.id}?token=${res.data.token}&expiresAt=${expiresAt}`
  }

  const copyUrls = async () => {
    const expiresAt = dayjs().add(Number(globalState.setting.customSetting?.reminderPeriodDays), 'day').format('YYYY-MM-DD');
    const link = await url(expiresAt);
    copyToClipboard(link);

    // 成功メッセージを表示
    message.success("クリップボードに記入用リンクを一括コピーしました。");
  };

  const handleMailTo = async () => {
    const to = deliveryPartnerCompany()?.email;
    const subject = "納入先での実態記入のご依頼";

    // 一時トークンを生成
    const expiresAt = dayjs().add(Number(globalState.setting.customSetting?.reminderPeriodDays), 'day').format('YYYY-MM-DD');
    const link = await url(expiresAt);
    const text = globalState.setting.customSetting?.requestMail;
    const body = `${text}\n\n${link}\n\nリンクの有効期限：${expiresAt}まで`;

    const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const manualUpdatedAt = useMemo(() => {
    return selectedManuals.length ?
      dayjs(selectedManuals.reduce((latest: Manual, current: Manual) => {
        return dayjs(latest.updatedAt).isAfter(dayjs(current.updatedAt)) ? latest : current;
      }).updatedAt).format("YYYY/MM/DD") :
      null;
  }, [selectedManuals]);

  return (
    <Modal
      title={`実態の記入を依頼`}
      width={isStep(StepValue.運送会社の選択) ? 330 : "100vw"}
      styles={{ body: isStep(StepValue.運送会社の選択) ? { paddingTop: 32 } : { padding: "32px 60px 0", height: 835, overflowY: "scroll" } }}
      centered
      onCancel={(e) => {
        if (isStep(StepValue.入力依頼)) {
          setIsFinishModalVisible(true);
        } else { onCancel && onCancel(e) }
      }}
      footer={null}
      closeIcon={
        !isStep(StepValue.運送会社の選択) &&
        <CustomButton
          onClick={(e: any) => {
            if (isStep(StepValue.入力依頼)) {
              setIsFinishModalVisible(true);
            } else { onCancel && onCancel(e) }
          }
          }
          style={{ right: 32 }}
        >
          閉じる
        </CustomButton>
      }
      {...props}
    >
      <Modal
        title={
          <div>
            {
              `${manualUpdatedAt}に更新済みのカルテが含まれます。`
            }
            <br />
            入力を依頼しますか？
          </div>
        }
        open={isFinishModalVisible}
        width={400}
        onCancel={(e) => {
          setIsFinishModalVisible(false);
          onCancel && onCancel(e);
        }}
        footer={[
          <CustomButton key={1} onClick={() => setIsFinishModalVisible(false)}>戻る</CustomButton>,
          <CustomButton
            type="primary"
            key={2}
            disabled={!value}
            onClick={(e: any) => {
              if (value === RequestStatus.依頼を完了) {
                bulkRequestManualsApi.execute({ ids: selectedManuals.map(manual => manual.id || ''), deliveryPartnerCompanyId: deliveryPartnerCompany()?.id || '' });
              }
              setIsFinishModalVisible(false);
              onCancel && onCancel(e);
            }}
          >
            終了する
          </CustomButton>
        ]}
        closeIcon={null}
      >
        <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
          <Space direction="vertical">
            {Object.entries(RequestStatus)
              .filter(([label, value]) => typeof value === "number")
              .map(([label, value]) =>
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              )}
          </Space>
        </Radio.Group>
      </Modal>
      {isStep(StepValue.運送会社の選択) && (
        <CustomSelectField
          style={{ width: "100%", margin: "20px 0 70px", }}
          form={form}
          validationResultForm={validationResultForm}
          attr={"deliveryPartnerId"}
          selectItems={(deliveryPartnerCompanies || []).map(partner => ({
            value: partner.id,
            label: partner.name
          }))}
          fieldProps={{
            showSearch: true,
            filterOption: filterKanaName(deliveryPartnerCompanies)
          }}
        />
      )}
      {(isStep(StepValue.カルテの選択) || isStep(StepValue.入力依頼)) &&
        <Space
          direction="vertical"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size={0}>
            <Row align="middle">
              <Col flex="none" style={{ fontSize: 16 }}>依頼先：</Col>
              <Col flex="auto">
                <CustomSelectField
                  style={{ width: "100%" }}
                  form={form}
                  validationResultForm={validationResultForm}
                  attr={"deliveryPartnerId"}
                  selectItems={(deliveryPartnerCompanies || []).map(partner => ({
                    value: partner.id,
                    label: partner.name
                  }))}
                  disabled={true}
                  fieldProps={{
                    value: null,
                    placeholder:
                      <Space>
                        <span style={{ fontSize: 17, fontWeight: "bold", color: "black" }}>{deliveryPartnerCompany()?.name}</span>
                        <span style={{ color: "black", opacity: 0.6 }}>{deliveryPartnerCompany()?.email}</span>
                      </Space>
                  }}
                />
              </Col>
            </Row>
            <Space direction="vertical" style={{ width: "100%", marginTop: 25, marginBottom: 10 }}>
              {isStep(StepValue.カルテの選択) &&
                <CustomSelectRadioField
                  form={form}
                  validationResultForm={validationResultForm}
                  attr="groupBy"
                  label="実態の記入を依頼する納入カルテを選択してください。"
                  selectItems={Object.entries(GroupBy)
                    .map(([label, value]) => ({
                      label,
                      value
                    }))}
                  isManual
                  column={4}
                  style={{ width: 1000 }}
                  direction="horizontal"
                />}
              {isStep(StepValue.入力依頼) &&
                <>以下のカルテについて実態の記入を依頼します</>
              }
            </Space>
            <ContractInputRequestTable
              history={props.history}
              dataSource={result.data}
              pagination={(currentStep === StepValue.カルテの選択) ?
                {
                  current: result.meta?.currentPage,
                  pageSize: result.meta?.perPage,
                  total: result.meta?.total,
                } :
                undefined
              }
              query={query}
              selectable={currentStep === StepValue.カルテの選択}
              selectedManuals={selectedManuals}
              setSelectedManuals={setSelectedManuals}
            />
          </Space>
        </Space>
      }
      <Space
        style={{
          justifyContent: "space-between",
          background: token.colorWhite,
          width: isStep(StepValue.運送会社の選択) ? "calc(100% - 48px)" : "calc(100% - 168px)",
          padding: isStep(StepValue.運送会社の選択) ? "10px 24px" : "10px 84px",
          borderRadius: "0 0 8px 8px",
          borderTop: isStep(StepValue.カルテの選択) ? `2px solid ${token.colorBorder}` : "none",
          display: (!isStep(StepValue.カルテの選択) || selectedManuals.length) ? "flex" : "none",
          position: "absolute",
          left: 0,
          bottom: 0
        }}>
        <div style={{ fontSize: 13 }}>
          {isStep(StepValue.カルテの選択) && <>
            <span style={{ fontSize: 15 }}>
              {selectedManuals.length}
            </span>
            件を選択中
          </>}
        </div>
        <Space>
          {isStep(StepValue.入力依頼) &&
            <CustomButton
              type="link"
              onClick={copyUrls}
            >
              <span>
                記入用リンクをコピー
              </span>
            </CustomButton>
          }
          {(isStep(StepValue.運送会社の選択) || isStep(StepValue.入力依頼)) &&
            <CustomButton
              onClick={(e: any) => {
                isStep(StepValue.運送会社の選択) ?
                  onCancel && onCancel(e) :
                  toPriviousStep();
              }}
            >
              戻る
            </CustomButton>
          }
          {isStep(StepValue.入力依頼) &&
            <CustomButton
              onClick={handleMailTo}
              type="primary"
            >
              依頼メールの作成
            </CustomButton>
          }
          {!isStep(StepValue.入力依頼) &&
            <CustomButton
              onClick={async (e: any) => {
                toNextStep();
              }}
              type={"primary"}
              disabled={!form.object.deliveryPartnerId}
            >
              {isStep(StepValue.運送会社の選択) ?
                "依頼する" :
                "次へ"
              }
            </CustomButton>
          }
        </Space>
      </Space>
    </Modal>
  );
};