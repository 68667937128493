import { Progress, Space, Table, TableProps, Upload, theme } from "antd";
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomPageHeader } from "specifics/page_header";
import { CustomDateField } from "specifics/input";
import { useForm } from "utils/hooks";
import dayjs, { Dayjs } from "dayjs";
import { useCountsDeliveryCompanyApi } from "api/delivery_company";
import {
  CountsApiResponseData,
  PercentagesApiResponseData,
  PercentagesApiResponseDataItem,
} from "utils/network/api_hooks";
import { CustomAreaChat } from "specifics/recharts/custom_area_chat";
import {
  AnalysisDetailsCountsPageTemplate,
  useAnalysisDetailsPage,
} from "./analysis_details_counts_page_template";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";

const AnalysisDetailsWithManualsPercentagesPage = ({
  history,
}: HistoryProps) => {
  const { datetimes, searchForm } = useAnalysisDetailsPage({ history });

  const countsAllDeliveryCompanyApi = useCountsDeliveryCompanyApi({
    givenTimes: datetimes,
  });

  const countsHasManualsDeliveryCompanyApi = useCountsDeliveryCompanyApi({
    givenTimes: datetimes,
    hasManuals: true,
  });

  useEffect(() => {
    countsAllDeliveryCompanyApi.execute();
    countsHasManualsDeliveryCompanyApi.execute();
  }, [datetimes]);
  const calculateDeliveryCompanyWithManualsPercentages = (
    allCounts?: CountsApiResponseData,
    manualCounts?: CountsApiResponseData
  ): PercentagesApiResponseData => {
    if (!allCounts || !manualCounts) return [];

    // 各datetimeのマッピングを作成
    const allCountsMap = new Map<string, number>(
      allCounts.map((item) => [item.datetime, item.count])
    );
    const manualCountsMap = new Map<string, number>(
      manualCounts.map((item) => [item.datetime, item.count])
    );

    const result: PercentagesApiResponseData = [];

    for (const [datetime, count] of allCountsMap) {
      const manualCount = manualCountsMap.get(datetime) ?? 0;
      const percentage = count !== 0 ? (manualCount / count) * 100 : 0;
      result.push({ datetime, percentage });
    }

    return result;
  };

  const deliveryCompanyWithManualsPercentages = useMemo(() => {
    return calculateDeliveryCompanyWithManualsPercentages(
      countsAllDeliveryCompanyApi.response.data,
      countsHasManualsDeliveryCompanyApi.response.data
    ).map((item) => ({ ...item, day: dayjs(item.datetime).format("DD日") }));
  }, [
    countsAllDeliveryCompanyApi.loading,
    countsHasManualsDeliveryCompanyApi.loading,
  ]);

  const table = useTable<PercentagesApiResponseDataItem>();

  const columns: TableProps<PercentagesApiResponseDataItem>["columns"] = [
    {
      key: "date",
      title: "日付",
      rowScope: "row",
      render: (item: PercentagesApiResponseDataItem) =>
        dayjs(item.datetime).format("YYYY年MM月DD日"),
    },
    {
      key: "row",
      title: "作成率",
      render: (item: PercentagesApiResponseDataItem) =>
        `${Math.round(10 * item.percentage) / 10 || 0}%`,
    },
  ];

  return (
    <AnalysisDetailsCountsPageTemplate
      searchForm={searchForm}
      title="納入カルテ作成率"
      history={history}
    >
      <ResponsiveContainer width={"100%"} height={300}>
        <CustomAreaChat
          dataKey="percentage"
          height={250}
          data={deliveryCompanyWithManualsPercentages}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          xAxisLabelKey="day"
          yaxisProps={{ domain: [0, 100] }}
          getTooltipLabel={(item) =>
            dayjs(item.payload.datetime).format("YYYY年MM月DD日")
          }
          getTooltipBody={(item) =>
            `${Math.round(10 * item.payload.percentage) / 10 || 0}%`
          }
        />
      </ResponsiveContainer>
      <CustomTable
        table={table}
        columns={columns}
        pagination={false}
        tableKey="analysis_details_with_manuals_percentages_table"
        dataSource={deliveryCompanyWithManualsPercentages}
      />
    </AnalysisDetailsCountsPageTemplate>
  );
};

export default withRouter(AnalysisDetailsWithManualsPercentagesPage);
