import { ResponsiveContainer } from "recharts";

import { useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import dayjs, { Dayjs } from "dayjs";
import { CustomBarChat } from "specifics/recharts/custom_bar_chat";
import { useCountsIssueApi } from "api/issue";
import {
  AnalysisDetailsCountsPageTemplate,
  useAnalysisDetailsPage,
} from "./analysis_details_counts_page_template";
import { useTable } from "specifics/use_table";
import { CountsApiResponseDataItem } from "utils/network/api_hooks";
import { TableProps } from "antd";
import { CustomTable } from "specifics/table";

const AnalysisDetailsIssuesCountsPage = ({ history }: HistoryProps) => {
  const { datetimes, searchForm } = useAnalysisDetailsPage({ history });

  const countsApi = useCountsIssueApi({
    givenTimes: datetimes,
  });

  useEffect(() => {
    countsApi.execute();
  }, [datetimes]);

  const table = useTable<CountsApiResponseDataItem>();

  const data = useMemo(() => {
    return (
      countsApi.response.data?.map((item) => ({
        ...item,
        day: dayjs(item.datetime).format("DD日"),
      })) || []
    );
  }, [countsApi.loading]);

  const columns: TableProps<CountsApiResponseDataItem>["columns"] = [
    {
      key: "date",
      title: "日付",
      rowScope: "row",
      render: (item: CountsApiResponseDataItem) =>
        dayjs(item.datetime).format("YYYY年MM月DD日"),
    },
    {
      key: "row",
      title: "件数",
      render: (item: CountsApiResponseDataItem) => `${item.count}件`,
    },
  ];

  return (
    <AnalysisDetailsCountsPageTemplate
      searchForm={searchForm}
      title="報告数"
      history={history}
    >
      <ResponsiveContainer width={"100%"} height={300}>
        <CustomBarChat
          valueKey="count"
          height={250}
          data={
            countsApi.response.data?.map((item) => ({
              ...item,
              day: dayjs(item.datetime).format("DD日"),
            })) || []
          }
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          xAxisLabelKey="day"
          getTooltipLabel={(item) =>
            dayjs(item.payload.datetime).format("YYYY年MM月DD日")
          }
          getTooltipBody={(item) => `${item.payload.count}件`}
        />
      </ResponsiveContainer>
      <CustomTable
        table={table}
        columns={columns}
        pagination={false}
        tableKey="analysis_details_issues_counts_table"
        dataSource={data}
      />
    </AnalysisDetailsCountsPageTemplate>
  );
};

export default withRouter(AnalysisDetailsIssuesCountsPage);
