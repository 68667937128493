import { Button, Space, Table, TableProps, theme } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { VehiclesResponse, useDeleteVehicleApi } from "api/vehicle";

import { ID } from "entities";
import {
  Vehicle,
  VehicleLicenceTypes,
  VehicleSearchForm,
  VehicleTypes,
} from "entities/vehicle";
import { useContext } from "react";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { AttrColumn, CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import { VehicleLicenseTypeView, VehicleTypeView } from "./vehicle_view";
import Highlighter from "react-highlight-words";
import { GlobalStateContext } from "contexts/global_state_context";
import { CustomTableText } from "specifics/table_text";
import { RiExternalLinkFill } from "react-icons/ri";
import { IndexApiSet } from "utils/network/api_hooks";

type VehiclesTableProps = TableProps<Vehicle>;

export const VehiclesTable = (
  props: VehiclesTableProps &
    HistoryProps & { handleDelete: (id: ID) => void } & {
      listApi?: IndexApiSet<VehiclesResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();

  const table = useTable<Vehicle>(props);

  const columns: ColumnsType<Vehicle> = [
    {
      ...table.baseColumn("vehicleCode"),
      ...table.getColumnSearchProps("vehicleCode", "管理番号"),
      width: "130px",
      title: "管理番号",
      render: (item) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[table.searchText]}
          autoEscape
          textToHighlight={item.vehicleCode}
        />
      ),
    },
    {
      ...table.baseColumn("licenseType"),
      width: "126px",
      title: "免許区分",
      filters: VehicleLicenceTypes.map((t, index) => ({
        text: t,
        value: index,
      })),
      onFilter: (value: string | number | boolean, record: Vehicle) =>
        record.licenseType === value,
      render: VehicleLicenseTypeView,
    },
    {
      ...table.baseColumn("user.id"),
      filters: globalState.users.map((user, index) => ({
        text: user.name ?? "",
        value: user.id ?? "",
      })),
      onFilter: (value: string | number | boolean, record: Vehicle) =>
        record.user?.id === value,
      title: "担当ドライバー",
      width: 148,
      render: (item) => (
        <Space size={0}>
          <CustomTableText width={100}>{item.user?.name}</CustomTableText>
          {item.user && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.history.push(`/operations/users/${item.user.id}`);
              }}
              shape="circle"
              type="text"
              icon={<RiExternalLinkFill />}
            />
          )}
        </Space>
      ),
    },
    {
      ...table.baseColumn("vehicleNo"),
      ...table.getColumnSearchProps("vehicleNo", "車両番号"),
      width: 150,
      title: "車両番号",
      render: (item) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[table.searchText]}
          autoEscape
          textToHighlight={item.vehicleNo}
        />
      ),
    },
    {
      ...table.baseColumn("weight"),
      sorter: (a, b) => ((a.weight ?? "") > (b.weight ?? "") ? 1 : -1),
      width: 100,
      render: (item) => `${item.weight}t`,
      title: "車格",
    },
    {
      ...table.baseColumn("vehicleType"),
      render: VehicleTypeView,
      filters: VehicleTypes.map((t, index) => ({ text: t, value: index })),
      onFilter: (value: string | number | boolean, record: Vehicle) =>
        record.vehicleType === value,
      width: 120,
      title: "車両種類",
    },
    AttrColumn<Vehicle>({ attr: "memo", label: "備考", width: 400 }),
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 138,
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/operations/vehicles/${item.id}/edit`);
            }}
          >
            編集
          </CustomButton>
          <CustomButton
            style={{
              fontWeight: 500,
              padding: 0,
              height: "30px",
              width: "28px",
              textAlign: "left",
            }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              props.handleDelete(item.id);
            }}
            danger
            confirm
            popconfirmProps={{
              title: "削除します。よろしいですか？",
            }}
          >
            削除
          </CustomButton>
        </Space>
      ),
    },
  ];
  return (
    <CustomTable
      tableKey="vehicles_table"
      table={table}
      pagination={table.paginationInfo}
      onChange={table.handleChange}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () => props.history.push(`/operations/vehicles/${data.id}`),
      })}
      {...props}
    />
  );
};
