import {
  Button,
  Col,
  Divider,
  Popconfirm,
  Row,
  Space,
  message,
  theme,
} from "antd";
import {
  useFetchTemplateApi,
  usePostTemplateApi,
  useEditTemplateApi,
} from "api/template";
import { GlobalStateContext } from "contexts/global_state_context";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import { Question, QuestionOption, Step, Template } from "entities/template";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import {
  CustomInputField,
  CustomInputNumberField,
  CustomSelectRadioField,
  StringValidation,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import { Form, ParsableValue, useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomContentFooter } from "specifics/footer";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { DescriptionBlock } from "specifics/description";
import { Manual, ManualProcess } from "entities/manual";
import { BaseQuestionFormView } from "components/shared/manual_form";
import { FormFlag, ID } from "entities/index";

type EditQuestionInfo = {
  stepIndex?: number;
  questionIndex?: number;
};

type EditBranchedQuestionInfo = {
  parentQuestionId?: string;
  parentQuestionValue?: string;
  questionIndex?: number;
};

const defaultQuestionOption: QuestionOption = {
  no: 4,
};

const defaultQuestion: Question = {
  questionOptions: [
    { no: 1, contents: "質問文" },
    { no: 2, contents: "選択肢1" },
    { no: 3, contents: "選択肢2" },
  ],
  questionNo: 1,
  type: 1,
};

const defaultStep: Step = {
  questions: [{ ...defaultQuestion }],
  stepNo: 1,
  stepName: "STEP名称",
};

const TemplateFormView = ({
  form,
  validationResultForm,
  manualForm,
  manualValidationResultForm,
  isEdit,
}: {
  form: Form<Template>;
  validationResultForm: Form<Template>;
  manualForm: Form<Manual>;
  manualValidationResultForm: Form<Manual>;
  isEdit?: boolean;
}) => {
  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "templateName", true);
    });
  }, [JSON.stringify(form.object.templateName)]);

  const [editQuestionInfo, setEditQuestionInfo] = useState<EditQuestionInfo>(
    {}
  );
  const [editBranchedQuestionInfo, setEditBranchedQuestionInfo] = useState<EditBranchedQuestionInfo>(
    {}
  );

  const [isStepDraggable, setIsStepDraggable] = useState(false);
  const onDragStepEnd = (result: DropResult) => {
    // Stepドロップ時の処理をここに実装
    const { source, destination } = result;
    if (
      destination?.index === undefined ||
      source.index === undefined ||
      !form.object.steps
    ) {
      return;
    }
    const steps: Step[] = form.object.steps;
    const swapedSteps = steps.filter((id, index) => {
      return index !== source.index;
    });
    swapedSteps.splice(destination?.index, 0, steps[source.index]);
    form.updateObject(
      "steps",
      swapedSteps.map((step: Step, no: number) => {
        no++; // step_noは１始まり
        return {
          ...step,
          stepNo: no,
        };
      })
    );
  };

  return (
    <Space
      style={{ width: "100%", borderRadius: 8 }}
      size={16}
      direction="vertical"
    >
      <CustomInputField
        required
        form={form}
        attr={"templateName"}
        validationResultForm={validationResultForm}
        label="テンプレート名称"
      />
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <DragDropContext onDragEnd={onDragStepEnd}>
        <Droppable droppableId="droppable-steps">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
            >
              {form.object.steps
                ?.sort((a, b) => ((a.stepNo ?? 0) > (b.stepNo ?? 0) ? 1 : -1))
                ?.map((step, stepIndex) => {
                  if (isEdit && step.flag === FormFlag.DELETE) {
                    return <></>;
                  } else {
                    return (
                      <Draggable
                        key={step.id}
                        draggableId={step.id ?? ""}
                        index={stepIndex}
                        isDragDisabled={
                          editQuestionInfo.stepIndex === stepIndex ||
                          !isStepDraggable
                        }
                      >
                        {(provided, snapshot) => {
                          return (
                            <div style={{ position: "relative" }}>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  // ドラッグ中のカスタムスタイル
                                  ...(snapshot.isDragging
                                    ? {
                                      cursor: "grabbing",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                    }
                                    : {}),
                                }}
                              >
                                <TemplateStepFormView
                                  key={`step${stepIndex}`}
                                  form={form}
                                  stepIndex={stepIndex}
                                  validationResultForm={validationResultForm}
                                  editQuestionInfo={editQuestionInfo}
                                  setEditQuestionInfo={setEditQuestionInfo}
                                  editBranchedQuestionInfo={editBranchedQuestionInfo}
                                  setEditBranchedQuestionInfo={setEditBranchedQuestionInfo}
                                  manualForm={manualForm}
                                  manualValidationResultForm={
                                    manualValidationResultForm
                                  }
                                  isEdit={isEdit}
                                  isStepDraggable={isStepDraggable}
                                  setIsStepDraggable={setIsStepDraggable}
                                  stepDragHandleProps={provided.dragHandleProps}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  }
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <CustomButton
        style={{ width: "100%" }}
        icon={<PlusOutlined />}
        onClick={() => {
          setEditQuestionInfo({
            stepIndex: form.object.steps?.length ?? 0,
            questionIndex: 0,
          });
          form.updateObject(
            "steps",
            JSON.parse(
              JSON.stringify([
                ...(form.object.steps ?? []),
                {
                  ...defaultStep,
                  templateId: form.object.id,
                  stepNo:
                    Math.max(
                      ...(form.object.steps?.map((step) => step.stepNo || 0) ??
                        [])
                    ) + 1,
                } as Step,
              ])
            )
          );
        }}
      >
        STEPを追加
      </CustomButton>
    </Space>
  );
};

const TemplateStepFormView = ({
  form,
  stepIndex,
  validationResultForm,
  editQuestionInfo,
  setEditQuestionInfo,
  editBranchedQuestionInfo,
  setEditBranchedQuestionInfo,
  manualForm,
  manualValidationResultForm,
  isEdit,
  isStepDraggable,
  setIsStepDraggable,
  stepDragHandleProps,
}: {
  form: Form<Template>;
  stepIndex: number;
  validationResultForm: Form<Template>;
  editQuestionInfo: EditQuestionInfo;
  setEditQuestionInfo: React.Dispatch<React.SetStateAction<EditQuestionInfo>>;
  editBranchedQuestionInfo: EditBranchedQuestionInfo;
  setEditBranchedQuestionInfo: React.Dispatch<React.SetStateAction<EditBranchedQuestionInfo>>;
  manualForm: Form<Manual>;
  manualValidationResultForm: Form<Manual>;
  isEdit?: boolean;
  isStepDraggable?: boolean;
  setIsStepDraggable?: (isStepDraggable: boolean) => void;
  stepDragHandleProps?: DraggableProvidedDragHandleProps | null;
}) => {
  // useEffect(() => {
  //   validationResultForm.update((f) => {
  //     StringValidation(f, form, "templateNo", true);
  //   });
  // }, [JSON.stringify(form.object)]);
  const { token } = theme.useToken();
  const scrollRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    // stepを開いた時、その位置までスクロール
    if (scrollRef.current) {
      scrollRef.current?.scrollIntoView({ block: "center" });
      scrollRef.current = null;
    }
  }, [scrollRef.current]);

  const onDragQuestionEnd = (result: DropResult, isBranched?: boolean, parentQuestionId?: string, parentQuestionValue?: ParsableValue) => {
    // 質問ドロップ時の処理をここに実装
    const { source, destination } = result;
    if (
      destination?.index === undefined ||
      source.index === undefined ||
      !form.object.steps
    ) {
      return;
    }
    const questions: Question[] = form.object.steps[stepIndex].questions ?? [];
    const { targetQuestions, removedQuestions, targetProcesses, removedProcesses } = questions.reduce((acc: { targetQuestions: Question[]; removedQuestions: Question[]; targetProcesses: ManualProcess[]; removedProcesses: ManualProcess[]; }, question, index) => {
      // 分岐内と分岐元の質問を分ける。
      const isMatch = (
        isBranched ?
          question.parentQuestionId === parentQuestionId && question.parentQuestionValue === parentQuestionValue :
          !question.parentQuestionId && !question.parentQuestionValue
      );
      const manualAttr = [
        "manualDetails",
        stepIndex,
        "manualProcesses",
        index,
        "value"
      ];

      // 条件に一致した場合は targetQuestions に、一致しない場合は removedQuestions に追加
      if (isMatch) {
        acc.targetQuestions.push(question);
        acc.targetProcesses.push({ value: manualForm.getValue(manualAttr) as string });
      } else {
        acc.removedQuestions.push(question);
        acc.removedProcesses.push({ value: manualForm.getValue(manualAttr) as string })
      }

      return acc;
    }, { targetQuestions: [], removedQuestions: [], targetProcesses: [], removedProcesses: [] });
    // 質問フォームと選択状況をそれぞれ同様に入れ替える。
    const swapedQuestions = targetQuestions.filter((question, index) => index !== source.index);
    swapedQuestions.splice(destination?.index, 0, targetQuestions[source.index]);
    const swappedProcesses = targetProcesses.filter((process, index) => index !== source.index);
    swappedProcesses.splice(destination?.index, 0, targetProcesses[source.index]);
    form.updateObject(
      ["steps", stepIndex, "questions"],
      isBranched ?
        // 分岐質問は質問の後ろに回す
        [
          ...removedQuestions,
          ...swapedQuestions.map((question: Question, no: number) => ({
            ...question,
            questionNo: no,
          }))
        ] :
        // 質問は分岐質問の前に回す
        [
          ...swapedQuestions.map((question: Question, no: number) => ({
            ...question,
            questionNo: no,
          })),
          ...removedQuestions
        ]
    );
    manualForm.updateObject(
      ["manualDetails", stepIndex, "manualProcesses"],
      isBranched ?
        [
          ...removedProcesses,
          ...swappedProcesses.map((process: ManualProcess) => ({
            ...process
          }))
        ] :
        [
          ...swappedProcesses.map((process: ManualProcess) => ({
            ...process
          })),
          ...removedProcesses
        ]
    );
  };

  return (
    <DescriptionBlock
      label={
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <Space style={{ display: isStepDraggable ? "none" : "flex" }}>
            <CustomInputNumberField
              form={form}
              label="STEPの順番"
              validationResultForm={validationResultForm}
              attr={["steps", stepIndex, "stepNo"]}
              fieldProps={{ min: 1, step: 1 }}
            />
          </Space>
          <span
            id={`step${stepIndex}`}
            onClick={() => {
              setIsStepDraggable && setIsStepDraggable(!isStepDraggable);
              if (stepIndex > 0 && isStepDraggable) {
                const element = document.getElementById(`step${stepIndex}`);
                scrollRef.current = element;
              }
            }}
            style={{
              opacity: 0.5,
              cursor: "grab",
              transform: "rotate(90deg)",
              background: token.colorBgBase,
              border: "none",
              boxShadow: "none",
              position: "absolute",
              top: 10,
              left: "50%",
            }}
            {...stepDragHandleProps}
          >
            <PauseOutlined />
          </span>
          <Popconfirm
            title={`STEPを削除します。よろしいですか？`}
            okButtonProps={{ type: "default" }}
            okText="はい"
            cancelButtonProps={{ type: "primary" }}
            cancelText="いいえ"
            onConfirm={() => {
              if ((form.getValue(["steps"]) as Step[])?.length <= 1) {
                message.error(
                  "テンプレートに紐づくSTEPが残り1つのときは、それ以上削除できません"
                );
              } else {
                form.update((f) => {
                  let newSteps;
                  if (
                    !isEdit ||
                    !form.object.steps![stepIndex].id // 作成時もしくは、編集時かつIDが存在しないEntityを消去する時
                  ) {
                    newSteps = f.steps?.filter(
                      (_, index) => index !== stepIndex
                    );
                  } else {
                    newSteps = f.steps?.map((item, index) =>
                      index !== stepIndex
                        ? {
                          ...item,
                          stepNo: index > stepIndex ? index : index + 1,
                        }
                        : { ...item, flag: FormFlag.DELETE }
                    );
                  }
                  f.steps = JSON.parse(JSON.stringify(newSteps));
                });
              }
            }}
          >
            <Button
              style={{ display: isStepDraggable ? "none" : "block" }}
              icon={<DeleteOutlined />}
              danger
              shape="circle"
            />
          </Popconfirm>
        </Space>
      }
      labelStyle={{
        width: "100%",
        marginBottom: isStepDraggable ? 0 : 16,
        height: isStepDraggable ? 0 : "auto",
      }}
      style={{
        background: token.colorBgBase,
        padding: 16,
        borderRadius: 8,
        position: "relative",
      }}
      valueStyle={{ display: "flex", flexDirection: "column", gap: 16 }}
    >
      <CustomInputField
        attr={["steps", stepIndex, "stepName"]}
        form={form}
        label={`STEPの名称`}
      />
      <div
        style={{
          display: isStepDraggable ? "none" : "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <DraggableQuestionsFormView
          form={form}
          validationResultForm={validationResultForm}
          stepIndex={stepIndex}
          isEdit={isEdit}
          onDragQuestionEnd={onDragQuestionEnd}
          manualForm={manualForm}
          manualValidationResultForm={manualValidationResultForm}
          editQuestionInfo={editQuestionInfo}
          setEditQuestionInfo={setEditQuestionInfo}
          editBranchedQuestionInfo={editBranchedQuestionInfo}
          setEditBranchedQuestionInfo={setEditBranchedQuestionInfo}
        />
      </div>
      <CustomButton
        style={{ width: "100%", display: isStepDraggable ? "none" : "block" }}
        onClick={() => {
          const questions = (form.getValue(["steps", stepIndex, "questions"]) ?? []) as Question[];

          // parentQuestionIdを持つものと持たないものに分ける
          const { parentQuestions, childQuestions } = questions.reduce((acc: { parentQuestions: Question[]; childQuestions: Question[]; }, question) => {
            if (question.parentQuestionId !== null && question.parentQuestionId !== undefined) {
              acc.childQuestions.push(question);
            } else {
              acc.parentQuestions.push(question);
            }
            return acc;
          }, { parentQuestions: [], childQuestions: [] });

          setEditQuestionInfo({
            stepIndex: stepIndex,
            questionIndex: parentQuestions.length,
          });
          form.updateObject(
            ["steps", stepIndex, "questions"],
            JSON.parse(
              JSON.stringify([
                ...parentQuestions,
                {
                  ...defaultQuestion,
                  stepId: form.getValue(["steps", stepIndex, "id"]),
                  questionNo:
                    Math.max(
                      ...(questions?.map(
                        (question) => question.questionNo || 0
                      ) ?? [])
                    ) + 1,
                } as Question,
                ...childQuestions,
              ])
            )
          );
        }}
        icon={<PlusOutlined />}
      >
        質問を追加
      </CustomButton>
    </DescriptionBlock>
  );
};

const DraggableQuestionsFormView = ({ form, validationResultForm, stepIndex, isEdit, isBranched, parentQuestionId, parentQuestionValue, manualForm, manualValidationResultForm, editQuestionInfo, setEditQuestionInfo, editBranchedQuestionInfo, setEditBranchedQuestionInfo, onDragQuestionEnd }: {
  form: Form<Template>;
  validationResultForm: Form<Template>;
  stepIndex: number;
  isEdit?: boolean;
  isBranched?: boolean;
  parentQuestionId?: ID;
  parentQuestionValue?: ID;
  manualForm: Form<Manual>;
  manualValidationResultForm: Form<Manual>;
  editQuestionInfo: EditQuestionInfo;
  setEditQuestionInfo: React.Dispatch<React.SetStateAction<EditQuestionInfo>>;
  editBranchedQuestionInfo: EditBranchedQuestionInfo;
  setEditBranchedQuestionInfo: React.Dispatch<React.SetStateAction<EditBranchedQuestionInfo>>;
  onDragQuestionEnd: (result: DropResult, isBranched?: boolean, parentQuestionId?: string, parentQuestionValue?: string) => void;
}) => {
  const { token } = theme.useToken();

  const questionsAll = () => form.object.steps && form.object.steps[stepIndex].questions;
  const targetQuestions = () => questionsAll()?.filter(question =>
    isBranched ?
      question.parentQuestionId === parentQuestionId && question.parentQuestionValue === parentQuestionValue :
      !question.parentQuestionId && !question.parentQuestionValue
  );

  return <>
    <DragDropContext onDragEnd={(result) => onDragQuestionEnd(result, isBranched, parentQuestionId, parentQuestionValue)}>
      <Droppable droppableId="droppable-questions">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={isBranched ? { margin: "10px 0" } : {}}>
            {targetQuestions()
              ?.sort((a, b) =>
                (a.questionNo ?? 0) > (b.questionNo ?? 0) ? 1 : -1
              )
              ?.map((question, questionIndex, questions) =>
                <Draggable
                  key={questionIndex}
                  draggableId={questionIndex.toString()}
                  index={questionIndex}
                  isDragDisabled={
                    editQuestionInfo.stepIndex === stepIndex
                  }
                >
                  {(provided, snapshot) => {
                    const attr = [
                      "manualDetails",
                      stepIndex,
                      "manualProcesses",
                      questionIndex
                    ];

                    const targetQuestionIndex = isBranched ?
                      questionsAll()?.findIndex(q => question.questionNo === q.questionNo && q.parentQuestionId === parentQuestionId && q.parentQuestionValue === parentQuestionValue) ?? questionIndex : // 分岐質問のindex
                      questionIndex // 分岐元質問のindex

                    return (
                      <div style={{ position: "relative" }}>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            // ドラッグ中のカスタムスタイル
                            ...(snapshot.isDragging
                              ? {
                                cursor: "grabbing",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                background: "lightgrey",
                                boxShadow:
                                  "0px 0px 10px rgba(0,0,0,0.2)",
                              }
                              : {}),
                          }}
                        >
                          {isEdit && question.flag === FormFlag.DELETE ? (
                            <></>
                          ) : ((!isBranched && questionIndex === editQuestionInfo.questionIndex &&
                            stepIndex === editQuestionInfo.stepIndex) ||
                            (isBranched && questionIndex === editBranchedQuestionInfo.questionIndex &&
                              parentQuestionId === editBranchedQuestionInfo.parentQuestionId &&
                              parentQuestionValue === editBranchedQuestionInfo.parentQuestionValue)) ?
                            (
                              <QuestionFormView
                                form={form}
                                stepIndex={stepIndex}
                                questionIndex={targetQuestionIndex}
                                validationResultForm={validationResultForm}
                                key={`questionform-${stepIndex}-${questionIndex}`}
                                onPreviewQuestion={() => {
                                  setEditQuestionInfo({})
                                  setEditBranchedQuestionInfo({})
                                }}
                                isEdit={isEdit}
                              />
                            ) : (
                              <>
                                <Space
                                  style={{
                                    width: "100%",
                                    border: `1px solid ${token.colorBorder}`,
                                    borderRadius: 5,
                                    background: isBranched ? token.colorFillTertiary : "initial",
                                    padding: 10,
                                    marginBottom:
                                      questionIndex < questions.length - 1 ? 10 : 0,
                                  }}
                                  key={`preview-${stepIndex}-${questionIndex}`}
                                  direction="vertical"
                                  size={0}
                                >
                                  <Row gutter={[10, 0]} {...provided.dragHandleProps}>
                                    <Col style={{ flex: 1 }}>
                                      <BaseQuestionFormView
                                        onChange={(e) => {
                                          // ラジオボタン&&分岐元の質問の時
                                          if (!Array.isArray(e) && !isBranched) {
                                            manualForm.updateObject(attr, e.target.value ? {
                                              questionId: question.id,
                                              value: e.target.value
                                            } :
                                              null)
                                          }
                                        }}
                                        form={manualForm}
                                        validationResultForm={
                                          manualValidationResultForm
                                        }
                                        stepIndex={stepIndex}
                                        question={question}
                                        questionIndex={targetQuestionIndex}
                                        isPreview
                                      />
                                    </Col>
                                    <Col>
                                      <Space>
                                        <Popconfirm
                                          title={`質問を削除します。よろしいですか？`}
                                          okButtonProps={{ type: "default" }}
                                          okText="はい"
                                          cancelButtonProps={{
                                            type: "primary",
                                          }}
                                          cancelText="いいえ"
                                          onConfirm={() => {
                                            if (
                                              (
                                                form.getValue([
                                                  "steps",
                                                  stepIndex,
                                                  "questions",
                                                ]) as Question[]
                                              )?.length <= 1
                                            ) {
                                              message.error(
                                                "STEPに紐づく質問が残り1つのときは、それ以上削除できません"
                                              );
                                            } else {
                                              form.update((f) => {
                                                let newQuestions;
                                                if (
                                                  !isEdit ||
                                                  !form.object.steps![stepIndex]
                                                    .questions![targetQuestionIndex]
                                                    .id // 作成時もしくは、編集時かつIDが存在しないEntityを消去する時
                                                ) {
                                                  newQuestions = f.steps![
                                                    stepIndex
                                                  ].questions?.filter(
                                                    (_, index) =>
                                                      index !== targetQuestionIndex
                                                  );
                                                } else {
                                                  newQuestions = f.steps![
                                                    stepIndex
                                                  ].questions?.map(
                                                    (item, index) =>
                                                      index !== targetQuestionIndex
                                                        ? item
                                                        : {
                                                          ...item,
                                                          flag: FormFlag.DELETE,
                                                        }
                                                  );
                                                }
                                                f.steps![stepIndex].questions =
                                                  JSON.parse(
                                                    JSON.stringify(newQuestions)
                                                  );
                                              });
                                            }
                                          }}
                                        >
                                          <Button
                                            icon={<DeleteOutlined />}
                                            danger
                                            shape="circle"
                                          />
                                        </Popconfirm>
                                        <Button
                                          onClick={() =>
                                            isBranched ?
                                              setEditBranchedQuestionInfo({
                                                questionIndex,
                                                parentQuestionId,
                                                parentQuestionValue
                                              }) :
                                              setEditQuestionInfo({
                                                questionIndex,
                                                stepIndex,
                                              })
                                          }
                                          icon={<EditOutlined />}
                                          shape="circle"
                                        />
                                      </Space>
                                    </Col>
                                  </Row>
                                  {manualForm.getValue([...attr, "value"]) && !isBranched &&
                                    <DraggableQuestionsFormView
                                      form={form}
                                      validationResultForm={validationResultForm}
                                      stepIndex={stepIndex}
                                      isEdit={isEdit}
                                      isBranched
                                      parentQuestionId={question.id}
                                      parentQuestionValue={manualForm.getValue([...attr, "value"]) as ID}
                                      onDragQuestionEnd={onDragQuestionEnd}
                                      manualForm={manualForm}
                                      manualValidationResultForm={manualValidationResultForm}
                                      editQuestionInfo={editQuestionInfo}
                                      setEditQuestionInfo={setEditQuestionInfo}
                                      editBranchedQuestionInfo={editBranchedQuestionInfo}
                                      setEditBranchedQuestionInfo={setEditBranchedQuestionInfo}
                                    />
                                  }
                                </Space>
                              </>
                            )}
                        </div>
                      </div>
                    );
                  }}
                </Draggable>
              )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    {isBranched &&
      <CustomButton
        style={{ width: "100%" }}
        onClick={() => {
          const questions = (form.getValue(["steps", stepIndex, "questions"]) || []) as Question[];
          const branchedQuestions = targetQuestions();
          setEditBranchedQuestionInfo({
            parentQuestionId,
            parentQuestionValue,
            questionIndex: branchedQuestions?.length,
          });
          form.updateObject(
            ["steps", stepIndex, "questions"],
            JSON.parse(
              JSON.stringify([
                ...questions,
                {
                  ...defaultQuestion,
                  stepId: form.getValue(["steps", stepIndex, "id"]),
                  questionNo:
                    Math.max(
                      ...(branchedQuestions?.length ?
                        branchedQuestions.map(
                          (question) => question.questionNo || 0
                        ) ?? [] :
                        [0])
                    ) + 1,
                  parentQuestionId,
                  parentQuestionValue
                } as Question,
              ])
            )
          );
        }}
        icon={<PlusOutlined />}
      >
        質問を追加
      </CustomButton>
    }
  </>
}

const QuestionFormView = ({
  form,
  stepIndex,
  questionIndex,
  validationResultForm,
  onPreviewQuestion,
  isEdit,
}: {
  form: Form<Template>;
  stepIndex: number;
  questionIndex: number;
  validationResultForm: Form<Template>;
  onPreviewQuestion?: () => void;
  isEdit?: boolean;
}) => {
  // useEffect(() => {
  //   validationResultForm.update((f) => {
  //     StringValidation(f, form, "templateNo", true);
  //   });
  // }, [JSON.stringify(form.object)]);
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();

  return (
    <DescriptionBlock
      label={
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <div
            style={{
              height: 24,
              fontSize: 16,
              fontWeight: 600,
              color: token.colorText,
            }}
          >
            質問{questionIndex + 1}
          </div>
          {onPreviewQuestion && (
            <Button
              onClick={onPreviewQuestion}
              icon={<EyeOutlined />}
              shape="circle"
            />
          )}
        </Space>
      }
      labelStyle={{ width: "100%" }}
      style={{
        background: token.colorBgLayout,
        padding: 16,
        borderRadius: 8,
      }}
      valueStyle={{ display: "flex", flexDirection: "column", gap: 16 }}
    >
      <CustomSelectRadioField
        form={form}
        attr={["steps", stepIndex, "questions", questionIndex, "type"]}
        label={`種別`}
        selectItems={[
          { label: "1つを選択", value: 1 },
          { label: "複数選択", value: 2 },
        ]}
      />
      {(
        form.getValue([
          "steps",
          stepIndex,
          "questions",
          questionIndex,
          "questionOptions",
        ]) as QuestionOption[]
      )
        .sort((a, b) => {
          // allowsFreeTextがtrueの場合、それらを最後に移動させる
          if (a.allowsFreeText && !b.allowsFreeText) return 1;
          if (!a.allowsFreeText && b.allowsFreeText) return -1;

          // allowsNumericValueがtrueの場合、それらをallowsFreeTextより前、その他の選択肢より後ろに移動させる
          if (!a.allowsFreeText && !b.allowsFreeText) {
            if (a.allowsNumericValue && !b.allowsNumericValue) return 1;
            if (!a.allowsNumericValue && b.allowsNumericValue) return -1;
          }

          // 上記の条件に該当しない場合、番号でソート
          return (a.no ?? 0) > (b.no ?? 0) ? 1 : -1;
        })
        .map((questionOption, questionOptionIndex) => {
          if (isEdit && questionOption.flag === FormFlag.DELETE) {
            return <></>;
          }
          return (
            <QuestionOptionFormView
              key={`qusionOption-${stepIndex}-${questionIndex}-${questionOptionIndex}`}
              form={form}
              stepIndex={stepIndex}
              questionIndex={questionIndex}
              questionOptionIndex={questionOptionIndex}
              validationResultForm={validationResultForm}
              isEdit={isEdit}
            />
          );
        })}
      <Row>
        <Col span={8}>
          <CustomButton
            style={{ width: "100%" }}
            icon={<PlusOutlined />}
            onClick={() => {
              const questionOptions = (form.getValue([
                "steps",
                stepIndex,
                "questions",
                questionIndex,
                "questionOptions",
              ]) ?? []) as QuestionOption[];
              form.updateObject(
                [
                  "steps",
                  stepIndex,
                  "questions",
                  questionIndex,
                  "questionOptions",
                ],
                JSON.parse(
                  JSON.stringify([
                    ...questionOptions,
                    {
                      ...defaultQuestionOption,
                      questionId: form.getValue([
                        "steps",
                        stepIndex,
                        "questions",
                        questionIndex,
                        "id",
                      ]),
                      no:
                        Math.max(
                          ...(questionOptions?.map(
                            (questionOption) => questionOption.no || 0
                          ) ?? [])
                        ) + 1,
                      contents: `選択肢`,
                    } as QuestionOption,
                  ])
                )
              );
            }}
          >
            質問の選択肢を追加
          </CustomButton>
        </Col>
        <Col span={8}>
          {!(
            form.getValue([
              "steps",
              stepIndex,
              "questions",
              questionIndex,
              "questionOptions",
            ]) as QuestionOption[]
          ).some(
            (option) =>
              option.allowsNumericValue && option.flag !== FormFlag.DELETE
          ) && (
              <CustomButton
                style={{ width: "100%" }}
                icon={<PlusOutlined />}
                onClick={() => {
                  const questionOptions = (form.getValue([
                    "steps",
                    stepIndex,
                    "questions",
                    questionIndex,
                    "questionOptions",
                  ]) ?? []) as QuestionOption[];
                  form.updateObject(
                    [
                      "steps",
                      stepIndex,
                      "questions",
                      questionIndex,
                      "questionOptions",
                    ],
                    JSON.parse(
                      JSON.stringify([
                        ...questionOptions,
                        {
                          ...defaultQuestionOption,
                          questionId: form.getValue([
                            "steps",
                            stepIndex,
                            "questions",
                            questionIndex,
                            "id",
                          ]),
                          no:
                            Math.max(
                              ...(questionOptions?.map(
                                (questionOption) => questionOption.no || 0
                              ) ?? [])
                            ) + 1,
                          contents: `数値で入力`,
                          allowsNumericValue: true,
                        } as QuestionOption,
                      ])
                    )
                  );
                }}
              >
                数値、単位を追加
              </CustomButton>
            )}
        </Col>
        <Col span={8}>
          {!(
            form.getValue([
              "steps",
              stepIndex,
              "questions",
              questionIndex,
              "questionOptions",
            ]) as QuestionOption[]
          ).some(
            (option) => option.allowsFreeText && option.flag !== FormFlag.DELETE
          ) && (
              <CustomButton
                style={{ width: "100%" }}
                icon={<PlusOutlined />}
                onClick={() => {
                  const questionOptions = (form.getValue([
                    "steps",
                    stepIndex,
                    "questions",
                    questionIndex,
                    "questionOptions",
                  ]) ?? []) as QuestionOption[];
                  form.updateObject(
                    [
                      "steps",
                      stepIndex,
                      "questions",
                      questionIndex,
                      "questionOptions",
                    ],
                    JSON.parse(
                      JSON.stringify([
                        ...questionOptions,
                        {
                          ...defaultQuestionOption,
                          questionId: form.getValue([
                            "steps",
                            stepIndex,
                            "questions",
                            questionIndex,
                            "id",
                          ]),
                          no:
                            Math.max(
                              ...(questionOptions?.map(
                                (questionOption) => questionOption.no || 0
                              ) ?? [])
                            ) + 1,
                          contents: `その他`,
                          allowsFreeText: true,
                        } as QuestionOption,
                      ])
                    )
                  );
                }}
              >
                その他を追加
              </CustomButton>
            )}
        </Col>
      </Row>
    </DescriptionBlock>
  );
};

const QuestionOptionFormView = ({
  form,
  stepIndex,
  questionIndex,
  questionOptionIndex,
  validationResultForm,
  isEdit,
}: {
  form: Form<Template>;
  stepIndex: number;
  questionIndex: number;
  questionOptionIndex: number;
  validationResultForm: Form<Template>;
  isEdit?: boolean;
}) => {
  const { token } = theme.useToken();
  const attr = [
    "steps",
    stepIndex,
    "questions",
    questionIndex,
    "questionOptions",
    questionOptionIndex,
  ];

  const questionOption = form.getValue(attr) as QuestionOption;
  return questionOption.no === 1 ? (
    <CustomInputField
      attr={[...attr, "contents"]}
      form={form}
      label={`質問文`}
    />
  ) : (
    <DescriptionBlock
      label={
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <div
            style={{
              height: 24,
              fontSize: 16,
              fontWeight: 600,
              color: token.colorText,
            }}
          >
            回答選択肢{questionOptionIndex}
          </div>
          <Popconfirm
            title={`回答選択肢を削除します。よろしいですか？`}
            okButtonProps={{ type: "default" }}
            okText="はい"
            cancelButtonProps={{ type: "primary" }}
            cancelText="いいえ"
            onConfirm={() => {
              if (questionOption.no === 1) {
                message.error("この回答選択肢は削除できません。");
              } else if (
                (
                  form.getValue([
                    "steps",
                    stepIndex,
                    "questions",
                    questionIndex,
                    "questionOptions",
                  ]) as QuestionOption[]
                )?.length <= 3
              ) {
                // no = 1のQuestionOptionは、質問のタイトル文に割り当てたれるため、選択肢の数+1なので、<= 3でOK
                message.error(
                  "回答選択肢が残り2つのときは、それ以上削除できません"
                );
              } else {
                form.update((f) => {
                  let newQuestionOptions;
                  if (
                    !isEdit ||
                    !form.object.steps![stepIndex].questions![questionIndex]
                      .questionOptions![questionOptionIndex].id // 作成時もしくは、編集時かつIDが存在しないEntityを消去する時
                  ) {
                    newQuestionOptions = f.steps![stepIndex].questions![
                      questionIndex
                    ].questionOptions?.filter(
                      (_, index) => index !== questionOptionIndex
                    );
                  } else {
                    newQuestionOptions = f.steps![stepIndex].questions![
                      questionIndex
                    ].questionOptions?.map((item, index) =>
                      index !== questionOptionIndex
                        ? item
                        : { ...item, flag: FormFlag.DELETE }
                    );
                  }
                  f.steps![stepIndex].questions![
                    questionIndex
                  ].questionOptions = JSON.parse(
                    JSON.stringify(newQuestionOptions)
                  );
                });
              }
            }}
          >
            <Button
              onClick={() => { }}
              icon={<DeleteOutlined />}
              danger
              shape="circle"
            />
          </Popconfirm>
        </Space>
      }
      labelStyle={{ width: "100%", marginBottom: 8 }}
      valueStyle={{
        background: token.colorBgTextHover,
        padding: 16,
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <CustomInputField
        attr={[...attr, "contents"]}
        form={form}
        label={`文言`}
      />
      {!questionOption.allowsFreeText && (
        <CustomInputField
          attr={[...attr, "value"]}
          form={form}
          label={questionOption.allowsNumericValue ? `単位` : `説明`}
          isHighlight={!questionOption.allowsNumericValue}
        />
      )}
    </DescriptionBlock>
  );
};

const _SuperAdminTemplatesCreatePage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);

  const { token } = theme.useToken();
  const form = useForm<Template>({
    steps: [defaultStep],
  });
  const manualForm = useForm<Manual>({
    manualDetails: [{
      manualProcesses: defaultStep.questions?.map((question) => ({
        value: null
      }))
    }]
  }); // preview用

  const postApi = usePostTemplateApi();

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      props.history.push("/super-admin/templates");
    }
  }, [postApi.loading]);

  const validationResultForm = useForm<Template>({});
  const manualValidationResultForm = useForm<Manual>({}); // preview用
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="テンプレート登録"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/super-admin/templates")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          <TemplateFormView
            form={form}
            validationResultForm={validationResultForm}
            manualForm={manualForm}
            manualValidationResultForm={manualValidationResultForm}
          />
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) =>
                    validationResultForm.object[key as keyof Template] ===
                    undefined
                )
              ) {
                postApi.execute(form);
              }
            }}
          >
            登録
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.push("/super-admin/templates")}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

const _SuperAdminTemplatesEditPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const templateApi = useFetchTemplateApi();
  const params = useParams<{ id: string }>();
  const form = useForm<Template>({});
  const manualForm = useForm<Manual>({}); // preview用
  const editApi = useEditTemplateApi();
  const { token } = theme.useToken();
  useEffect(() => {
    templateApi.execute(params.id);
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(templateApi.loading);
    if (templateApi.isSuccess()) {
      form.set({ ...templateApi.response.data });
      manualForm.set({
        // templateのstep,questionsからmanualFormを生成
        manualDetails: templateApi.response.data?.steps?.map((step) => ({
          manualProcesses: step.questions?.map((question) => ({
            value: null
          }))
        })
        )
      });
    }
  }, [templateApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.history.push(
        `/super-admin/templates/${templateApi.response.data?.id}`
      );
    }
  }, [editApi.loading]);

  const validationResultForm = useForm<Template>({});
  const manualValidationResultForm = useForm<Manual>({}); // preview用
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="テンプレート編集"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/super-admin/templates")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          <TemplateFormView
            form={form}
            validationResultForm={validationResultForm}
            manualForm={manualForm}
            manualValidationResultForm={manualValidationResultForm}
            isEdit
          />
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every((key) => {
                  return (
                    validationResultForm.object[key as keyof Template] ===
                    undefined
                  );
                })
              ) {
                editApi.execute(form);
              }
            }}
          >
            保存
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.push("/super-admin/templates")}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const SuperAdminTemplatesEditPage = withRouter(
  _SuperAdminTemplatesEditPage
);

export const SuperAdminTemplatesCreatePage = withRouter(
  _SuperAdminTemplatesCreatePage
);
