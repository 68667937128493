import { render } from "@testing-library/react";
import { ModalProps } from "antd";
import { usePostCompaniesApi } from "api/company";
import {
  CsvImportModal,
  EntityColumn,
} from "components/shared/csv_import_modal";
import { GlobalStateContext } from "contexts/global_state_context";
import { Company } from "entities/company";
import { useContext, useEffect } from "react";

export const SuperAdminCompanyCsvImportModal = ({
  ...modalProps
}: ModalProps) => {
  const bulkCreateCompanyApi = usePostCompaniesApi();

  const entityColumns: EntityColumn<Company>[] = [
    {
      key: "name",
      title: "客先名称",
      dataIndex: "name",
      required: true,
      width: 200,
    },
    { key: "kana", title: "ふりがな", dataIndex: "kana", width: 200 },
    { key: "zipcode", title: "郵便番号", dataIndex: "zipcode", width: 200 },
    { key: "tel", title: "電話番号", dataIndex: "tel", width: 200 },
    { key: "repName", title: "担当者氏名", dataIndex: "rep_name", width: 200 },
    {
      key: "repTel",
      title: "担当者電話番号",
      dataIndex: "rep_tel",
      width: 200,
    },
    {
      key: "address",
      title: "住所",
      dataIndex: "address",
      required: true,
      width: 200,
    },
  ];

  const csvDataToEntities = (
    csvData: Record<string, any>[],
    valueOf: (csvRow: Record<string, any>, csvColumnName: string) => any
  ): Company[] => {
    return csvData.map((csvRow) => {
      const entity: Company = {};
      entityColumns.forEach((column) => {
        entity[column.dataIndex as keyof Company] = valueOf(
          csvRow,
          column.title
        );
      });
      return entity;
    });
  };
  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    globalState.setLoading(bulkCreateCompanyApi.loading);
  }, [bulkCreateCompanyApi.loading]);

  return (
    <CsvImportModal
      csvDataToEntities={csvDataToEntities}
      bulkApiResult={{
        data: bulkCreateCompanyApi.response,
        error: bulkCreateCompanyApi.apiError as any,
      }}
      entityColumns={entityColumns}
      entityNameJp="取引先"
      handleBulkSendValidatedEntities={async (data) => {
        bulkCreateCompanyApi.execute(data);
      }}
      {...modalProps}
    />
  );
};
