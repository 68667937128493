import { BaseEntity, BaseSearchForm } from ".";
import { Company } from "./company";
import { Vehicle } from "./vehicle";

export enum UserType {
  "運行管理者" = 1,
  "ドライバー" = 2,
  "knewit社員" = 3,
}

export type User = BaseEntity & {
  name?: string;
  email?: string;
  type?: UserType;
  canAccessBoard?: boolean;
  mobile?: string;
  password?: string;

  company?: Company;
  availableJobs?: string;
  vehicles?: Vehicle[];
};

export type UserForm = User;

export type UserSearchForm = BaseSearchForm & User;
