import { Button, Divider, Popover, Space, TableProps, Tag, theme } from "antd";
import { ColumnsType } from "antd/lib/table";

import { ID } from "entities";
import { Manual } from "entities/manual";
import dayjs from "dayjs";
import { useContext } from "react";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { AttrColumn, CustomTable } from "specifics/table";
import { CustomTableText } from "specifics/table_text";
import { useTable } from "specifics/use_table";
import { RiExternalLinkFill } from "react-icons/ri";
import { IndexApiSet } from "utils/network/api_hooks";
import { ManualResponse, ManualsResponse } from "api/manual";
import { DeliveryTermView } from "components/delivery_companies/delivery_companies_view";
type ManualsTableProps = TableProps<Manual>;

export const AnalysisManualsTable = (
  props: ManualsTableProps &
    HistoryProps & { handleDelete: (id: ID) => void } & {
      listApi?: IndexApiSet<ManualsResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const table = useTable<Manual>(props);
  const { token } = theme.useToken();

  const columns: ColumnsType<Manual> = [
    {
      ...table.baseColumn("consignor.name"),
      ...table.getColumnSearchProps("consignor", "荷主"),
      onFilter: (value, record) =>
        (record.consignor?.name || "")
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      title: "荷主",
      width: "240px",
      render: (item) => (
        <Space>
          <CustomTableText width={180}>{item.consignor?.name}</CustomTableText>
          {item.consignor && (
            <Popover content="荷主詳細へ移動">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  props.history.push(
                    `/operations/consignors/${item.consignor.id}`
                  );
                }}
                shape="circle"
                type="text"
                icon={<RiExternalLinkFill />}
              />
            </Popover>
          )}
        </Space>
      ),
    },
    {
      ...table.baseColumn("deliveryCompany.name"),
      ...table.getColumnSearchProps("deliveryCompany", "行先名称"),
      onFilter: (value, record) =>
        (record.deliveryCompany?.name || "")
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      title: "行先名称",
      width: "240px",
      render: (item) => (
        <Space>
          <CustomTableText width={180}>
            {item.deliveryCompany?.name}
          </CustomTableText>
          {item.deliveryCompany && (
            <Popover content="行先詳細へ移動">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  props.history.push(
                    `/operations/delivery-companies/${item.deliveryCompany.id}`
                  );
                }}
                shape="circle"
                type="text"
                icon={<RiExternalLinkFill />}
              />
            </Popover>
          )}
        </Space>
      ),
    },

    AttrColumn<Manual>({
      attr: ["deliveryCompany", "address"],
      label: "行先住所",
      width: "250px",
    }),
    // {
    //   ...table.baseColumn("deliveryCompany.deliveryTerm"),
    //   sorter: (a, b) => ((a.updatedAt ?? "") > (b.updatedAt ?? "") ? 1 : -1),
    //   title: "自主荷役(契約)",
    //   width: 150,
    //   render: (item: Manual) =>
    //     DeliveryTermView(item.deliveryCompany?.deliveryTerm),
    // },
    {
      ...table.baseColumn("deliveryTerm"),
      sorter: (a, b) => ((a.updatedAt ?? "") > (b.updatedAt ?? "") ? 1 : -1),
      title: "自主荷役",
      width: 150,
      render: (item: Manual) => (
        <Space direction="vertical">
          <Popover
            trigger="hover"
            content={
              <Space split={<Divider type="vertical" style={{ margin: 1 }} />}>
                <Space direction="vertical">
                  <div style={{ fontWeight: 700 }}>契約</div>
                  {DeliveryTermView(item?.deliveryCompany?.deliveryTerm)}
                </Space>
                <Space direction="vertical">
                  <div style={{ fontWeight: 700 }}>実態</div>
                  {DeliveryTermView(item?.deliveryTerm)}
                </Space>
              </Space>
            }
          >
            <Tag
              color={
                item.deliveryTerm !== item.deliveryCompany?.deliveryTerm
                  ? "red"
                  : "blue"
              }
            >
              契約と相違
              {item.deliveryTerm !== item.deliveryCompany?.deliveryTerm
                ? "あり"
                : "なし"}
            </Tag>
          </Popover>
        </Space>
      ),
    },
    // {
    //   ...table.baseColumn("deliveryCompany.needTask"),
    //   sorter: (a, b) => ((a.updatedAt ?? "") > (b.updatedAt ?? "") ? 1 : -1),
    //   title: "付帯業務(契約)",
    //   width: 150,
    //   render: (item: Manual) => item.deliveryCompany?.needTask ?? "--",
    // },
    {
      ...table.baseColumn("needTask"),
      sorter: (a, b) => ((a.updatedAt ?? "") > (b.updatedAt ?? "") ? 1 : -1),
      title: "付帯業務",
      width: 150,
      render: (item: Manual) => (
        <Popover
          trigger="hover"
          content={
            <Space
              align="start"
              split={<Divider type="vertical" style={{ margin: 1 }} />}
            >
              <Space direction="vertical">
                <div style={{ fontWeight: 700 }}>契約</div>
                {item.deliveryCompany?.needTask?.split(",").length ? (
                  item.deliveryCompany?.needTask
                    ?.split(",")
                    .map((item) => <div key={item}>{item}</div>)
                ) : (
                  <>--</>
                )}
              </Space>
              <Space direction="vertical">
                <div style={{ fontWeight: 700 }}>実態</div>
                {item.needTask?.split(",").length ? (
                  item.needTask
                    ?.split(",")
                    .map((item) => <div key={item}>{item}</div>)
                ) : (
                  <>--</>
                )}
              </Space>
            </Space>
          }
        >
          <Tag
            color={
              item.needTask !== item.deliveryCompany?.needTask ? "red" : "blue"
            }
          >
            契約と相違
            {item.needTask !== item.deliveryCompany?.needTask ? "あり" : "なし"}
          </Tag>
        </Popover>
      ),
    },
    {
      ...table.baseColumn("updatedAt"),
      sorter: (a, b) => ((a.updatedAt ?? "") > (b.updatedAt ?? "") ? 1 : -1),
      title: "最終更新日",
      width: 150,
      render: (item) => dayjs(item.updatedAt).format("YYYY/MM/DD"),
    },
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 138,
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/operations/manuals/${item.id}/edit`);
            }}
          >
            編集
          </CustomButton>
          <CustomButton
            style={{
              fontWeight: 500,
              padding: 0,
              height: "30px",
              width: "28px",
              textAlign: "left",
            }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              props.handleDelete(item.id);
            }}
            danger
            confirm
            popconfirmProps={{
              title: "削除します。よろしいですか？",
              okText: "削除",
              cancelText: "戻る",
            }}
          >
            削除
          </CustomButton>
        </Space>
      ),
    },
  ];
  return (
    <CustomTable
      tableKey="analysis_manuals_table"
      table={table}
      pagination={table.paginationInfo}
      onChange={table.handleChange}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () => props.history.push(`/operations/manuals/${data.id}`),
      })}
      {...props}
    />
  );
};
