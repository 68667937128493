import { Space, theme } from "antd";

import { ReactNode, useContext, useEffect, useMemo } from "react";
import { HistoryProps } from "routes/app";
import { CustomPageHeader } from "specifics/page_header";
import { CustomDateField } from "specifics/input";
import { Form, useForm, useQuery } from "utils/hooks";
import dayjs, { Dayjs } from "dayjs";
import QueryString from "qs";
import {
  AnalysisBaseSearchFormDatetimeFormat,
  AnalysisSearchContext,
} from "contexts/analysis_search_context";

export const AnalysisDetailsCountsPageTemplate = <
  SearchForm extends { datetime: string }
>({
  title,
  children,
  history,
  searchForm,
  handleBack,
}: {
  searchForm: Form<SearchForm>;
  title: string;
  children: ReactNode;
  handleBack?: () => void;
} & HistoryProps) => {
  const { token } = theme.useToken();

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title={title}
      onBack={() =>
        history.push(
          `/analysis/visualization?datetime=${dayjs(
            searchForm.object.datetime
          ).format(AnalysisBaseSearchFormDatetimeFormat)}`
        )
      }
      extra={
        <CustomDateField
          form={searchForm}
          attr="datetime"
          itemProps={{ style: { marginBottom: 0 } }}
          fieldProps={{
            picker: "month",
            format: "YYYY年MM月",
            disabledDate: (date: Dayjs) =>
              date >= dayjs().startOf("month").add(1, "month"),
            onChange: (value: Dayjs | null) =>
              value &&
              history.replace(
                `${location.pathname}?${QueryString.stringify({
                  ...searchForm.object,
                  datetime: value
                    .endOf("month")
                    .format(AnalysisBaseSearchFormDatetimeFormat),
                } as SearchForm)}`
              ),
          }}
        />
      }
    >
      <Space
        size={30}
        style={{
          width: "100%",
          background: token.colorWhite,
          borderRadius: 16,
          padding: 24,
        }}
        direction="vertical"
      >
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <div style={{ fontWeight: 700, fontSize: 20 }}>
            {title} ({dayjs(searchForm.object.datetime).format("YYYY年MM月")})
          </div>
        </Space>
        {children}
      </Space>
    </CustomPageHeader>
  );
};

export const useAnalysisDetailsPage = <
  SearchForm extends { datetime: string }
>({
  history,
}: HistoryProps) => {
  const query = useQuery<SearchForm>();

  const { setDatetime } = useContext(AnalysisSearchContext);
  const searchForm = useForm<SearchForm>({
    ...query,
  });

  useEffect(() => {
    if (!query.datetime) {
      history.replace(
        `${location.pathname}?${QueryString.stringify({
          ...query,
          datetime: dayjs().endOf("month").format("YYYY-MM"),
        } as SearchForm)}`
      );
    } else {
      searchForm.set({
        ...query,
        datetime: dayjs(query.datetime, "YYYY-MM").endOf("month").format(),
      });
    }
  }, [JSON.stringify(query)]);

  const datetimes = useMemo(() => {
    setDatetime(searchForm.object.datetime);

    const dateList = [];
    let startDate = dayjs(searchForm.object.datetime)
      .startOf("month")
      .add(1, "day")
      .subtract(1, "millisecond");
    const endDate = dayjs(searchForm.object.datetime).endOf("month");
    while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
      dateList.push(startDate.format());
      startDate = startDate.add(1, "day");
    }
    return dateList;
  }, [searchForm.object.datetime]);
  return {
    datetimes,
    searchForm,
  };
};
