import { Table } from "antd";
import { RotationSummaryByDeliveryCompanyId } from "./rotations_handler";
import { OrderDetail } from "entities/order";
import { CustomTag } from "specifics/tag";
import { SortOrder } from "antd/es/table/interface";

export const RotationsByDeliveryCompanyIdTable = ({
  rotationSummariesByDeliveryCompanyId,
}: {
  rotationSummariesByDeliveryCompanyId: RotationSummaryByDeliveryCompanyId[];
}) => {
  return (
    <Table
      dataSource={rotationSummariesByDeliveryCompanyId}
      columns={[
        {
          key: "deliveryCompanyId",
          title: "地点",
          render: (item: OrderDetail) => item.deliveryCompany?.name,
          sorter: (a: OrderDetail, b: OrderDetail, sortOrder?: SortOrder) => {
            return (a.deliveryCompany?.name ?? "") >
              (b.deliveryCompany?.name ?? "")
              ? 1
              : -1;
          },
        },
        {
          key: "pointType",
          title: "積/降",
          filters: [
            { text: "降", value: 1 },
            { text: "積", value: 2 },
          ],
          onFilter: (value, record) => record.pointType === value,
          render: (item: OrderDetail) => (
            <CustomTag
              style={{
                height: 24,
                borderRadius: 4,
                padding: "2px 6px 2px 6px",
                color: item?.pointType === 1 ? "#10B981" : "#EF4444",
                background: item?.pointType === 1 ? "#ECFDF5" : "#FEF2F2",
                lineHeight: "20px",
                fontWeight: "bolder",
              }}
            >
              {item?.pointType === 2 ? "積み" : "降し"}
            </CustomTag>
          ),
        },
        {
          key: "loadWeight",
          title: "重量",
          render: (item: OrderDetail) =>
            `${Number(item.loadWeight ?? 0)?.toFixed(1)}kg`,
          sorter: (a: OrderDetail, b: OrderDetail, sortOrder?: SortOrder) => {
            return (a.loadWeight ?? 0) > (b.loadWeight ?? 0) ? 1 : -1;
          },
        },
      ]}
    />
  );
};
