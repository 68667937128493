import { Space, theme } from "antd";
import { useFetchCompanyApi, useEditCompanyApi } from "api/company";
import { GlobalStateContext } from "contexts/global_state_context";

import { Company, CompanyManualType } from "entities/company";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import {
  CustomInputField,
  CustomSelectField,
  CustomTextAreaField,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomContentFooter } from "specifics/footer";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { useFetchTemplatesApi } from "api/template";
import { hankaku2Zenkaku } from "utils/util";

const FormView = (form: Form<Company>, validationResultForm: Form<Company>) => {
  const templatesApi = useFetchTemplatesApi();
  useEffect(() => {
    templatesApi.execute();
  }, []);
  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    globalState.setLoading(templatesApi.loading);
  }, [templatesApi.loading]);

  return (
    <Space style={{ width: "100%" }} size={16} direction="vertical">
      <CustomInputField
        form={form}
        attr={"name"}
        label="名称"
      />
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="郵便番号"
        onChange={(e) => {
          const value = hankaku2Zenkaku(e.target.value);
          if (
            e.target.value.length === 0 ||
            value.match(/^\d{1,3}-?\d{0,4}$/)
          ) {
            form.updateObject(
              "zipcode",
              value.length > 3 && value[3] !== "-" && !value.includes("-")
                ? value.slice(0, 3) + "-" + value.slice(3)
                : value.slice(0, 8)
            );
          } else {
            console.log("invalid");
          }
        }}
        attr="zipcode"
        required
        style={{ width: 180 }}
      />
      <CustomInputField
        form={form}
        label="住所"
        attr="address"
        style={{ width: "100%" }}
        required
        validationResultForm={validationResultForm}
      />
      <CustomInputField
        form={form}
        attr={"repName"}
        label="代表者氏名"
      // validationResultForm={validationResultForm}
      />
      <CustomInputField
        form={form}
        attr={"repTel"}
        label="代表者電話番号"
        onChange={(e) => {
          const value = hankaku2Zenkaku(e.target.value);
          if (value.match(/^\d{0,13}$/)) {
            form.updateObject("repTel", value.slice(0, 13));
          } else {
            console.log("invalid");
          }
        }}
      // validationResultForm={validationResultForm}
      />
      <CustomSelectField
        form={form}
        attr={"manualType"}
        label="カルテ作成タイプ"
        validationResultForm={validationResultForm}
        selectItems={
          Object.entries(CompanyManualType)
            .filter(([label, value]) => typeof value === 'number')
            .map(([label, value]) => ({
              label,
              value
            }))
        }
      />
      <CustomSelectField
        form={form}
        attr={"selectableTemplateIds"}
        label="選択可能なテンプレート"
        validationResultForm={validationResultForm}
        selectItems={
          templatesApi.response.data
            ?.filter((item) => item.id)
            .map((item) => ({
              label: item.templateName ?? "名無しのテンプレート",
              value: item.id!,
            })) || []
        }
        fieldProps={{ loading: templatesApi.loading }}
        style={{ width: "100%" }}
        mode="multipleSelect"
      />
      <CustomSelectField
        form={form}
        attr={"defaultTemplateId"}
        label="デフォルトのテンプレート"
        validationResultForm={validationResultForm}
        selectItems={
          templatesApi.response.data
            ?.filter((item) => item.id)
            .map((item) => ({
              label: item.templateName ?? "名無しのテンプレート",
              value: item.id!,
            })) || []
        }
        fieldProps={{ loading: templatesApi.loading }}
      />
      <CustomTextAreaField
        form={form}
        attr={"memo"}
        label="備考"
      // validationResultForm={validationResultForm}
      />
    </Space>
  );
};

const _CompaniesEditPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const companyApi = useFetchCompanyApi();
  const params = useParams<{ id: string }>();
  const form = useForm<Company>({});
  const editApi = useEditCompanyApi();
  const { token } = theme.useToken();
  useEffect(() => {
    companyApi.execute(params.id);
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(companyApi.loading);
    if (companyApi.isSuccess()) {
      form.set({ ...companyApi.response.data, id: params.id });
    }
  }, [companyApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.history.push(`/super-admin/companies/${params.id}`);
    }
  }, [editApi.loading]);

  const validationResultForm = useForm<Company>({});
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="客先編集"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/super-admin/companies")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) =>
                    validationResultForm.object[key as keyof Company] ===
                    undefined
                )
              ) {
                editApi.execute(form);
              }
            }}
          >
            保存
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.push("/super-admin/companies")}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const SuperAdminCompaniesEditPage = withRouter(_CompaniesEditPage);
