import { BrowserRouter, Route } from "react-router-dom";
import AppRoutes from "./routes/app";
import GlobalStateContainer from "containers/global_state_container";

import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import "dayjs/locale/ja";
import dayjs from "dayjs";
dayjs.locale("ja");

const App = (): JSX.Element => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextSecondary: "#6B7280",
          colorBorder: "#E5E7EB",
          colorBgBase: "#F9FAFB",
          colorBgContainer: "#FFF",
          colorPrimary: "#1D2645",
          colorPrimaryHover: "#1D2645AA",
          colorPrimaryBgHover: "#d2d4da",
          colorPrimaryBg: "#d2d4da",
          // colorLink: "#1D2645",
          // colorLinkHover: "#1D2645AA",
        },
      }}
    >
      <GlobalStateContainer>
        <BrowserRouter>
          <Route component={AppRoutes} />
        </BrowserRouter>
      </GlobalStateContainer>
    </ConfigProvider>
  );
};

export default App;

if (document.getElementById("reactApp")) {
  ReactDOM.render(<App />, document.getElementById("reactApp"));
}
