import { Skeleton, Space, theme } from "antd";
import { useFetchAllConsignorsApi } from "api/consignor";
import { useFetchAllDeliveryCompaniesApi, useFetchDeliveryCompanyApi } from "api/delivery_company";
import {
  useEditManualApi,
  useFetchManualApi,
  usePostManualApi,
} from "api/manual";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  Manual,
  ManualKey,
  ManualInfo,
  ManualInfoAttrs,
} from "entities/manual";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";

import { CustomFormPageHeader } from "specifics/page_header";
import { Form, useEffectSkipFirst, useForm, useQuery } from "utils/hooks";

import { useFetchTemplateApi } from "api/template";
import { Template } from "entities/template";

import { ManualForm } from "components/shared/manual_form";
import { useFetchCurrentCompanyApi } from "api/company";
import { Company, CompanyManualDisplay, CompanyManualType } from "entities/company";
import { ID } from "entities";
import { DeliveryCompany } from "entities/delivery_company";
import { Consignor } from "entities/consignor";
import { useFetchAllDeliveryPartnerCompaniesApi } from "api/delivery_partner_company";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { CookieManager } from "utils/cookie_manager";

const ManualPageFooter = ({
  form,
  isEdit,
  companyManualType,
  _key,
  setKey,
  infoIndexState,
  setInfoIndexState,
  stepIndexState,
  setStepIndexState,
  validationResultForm,
  template,
  onSubmit,
  guest
}: {
  form: Form<Manual>;
  validationResultForm: Form<Manual>;
  isEdit?: boolean;
  companyManualType?: CompanyManualType;
  _key: ManualKey;
  setKey: (key: ManualKey) => void;
  infoIndexState: number;
  setInfoIndexState: (infoIndexState: number) => void;
  stepIndexState: number;
  setStepIndexState: (stepIndexState: number) => void;
  template?: Template;
  onSubmit: () => void;
  guest?: boolean
}) => {
  const stepCount = (): number => template?.steps?.filter(step => form.object.selectedStepNos?.includes(step.stepNo ?? 0)).length ?? 0;
  const enableNextButton = (): boolean => {
    switch (_key) {
      case ManualKey.基本情報: {
        const keys = isEdit ?
          Object.keys(validationResultForm.object) : // 編集時全項目
          ManualInfoAttrs[ManualInfo[infoIndexState] as keyof typeof ManualInfoAttrs] // 作成時基本情報項目のみ
        if (
          keys.every((key) =>
            (key === "manualDetails" || key === "points" || key === "templateId")
              ? true
              : validationResultForm.object[key as keyof Manual] === undefined
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
      case ManualKey.手順: {
        if (stepIndexState === -1) {
          // テンプレート選択画面
          return true;
        }
        const details = validationResultForm.object.manualDetails;

        // detailsが未定義、または空の場合は直ちにtrueを返す
        if (!details?.length) return true;

        const currentDetail = details[stepIndexState];
        const allProcessesUndefined = !!currentDetail.manualProcesses?.every(process => process.value === undefined);
        const commentUndefined = currentDetail.comment === undefined;

        // すべてのプロセスのvalueがundefinedであり、かつcommentもundefinedの場合にのみtrueを返す
        return allProcessesUndefined && commentUndefined;
      }
      case ManualKey.構内マップ: {
        const points = validationResultForm.object.points;

        // pointsが未定義、または空の場合は直ちにtrueを返す
        if (!points?.length) return true;

        const allPointsUndefined = points.every(point => Object.values(point).every(val => val === undefined));
        return allPointsUndefined;
      }
      default:
        return false;
    }
  };
  const displayNextButton = (): boolean => {
    if (isEdit) return true;
    switch (_key) {
      case ManualKey.基本情報: {
        return infoIndexState !== ManualInfo.行先での作業 || CompanyManualDisplay(companyManualType, ManualKey.手順);
      }
      case ManualKey.手順: {
        return stepIndexState < stepCount() - 1 || CompanyManualDisplay(companyManualType, ManualKey.構内マップ);
      }
      case ManualKey.構内マップ: {
        return true;
      }
      default:
        return false;
    }
  }
  const displayBackButton = (): boolean => {
    if (isEdit) return false;
    switch (_key) {
      case ManualKey.基本情報: {
        return infoIndexState !== ManualInfo.基本情報;
      }
      case ManualKey.手順: {
        return true;
      }
      case ManualKey.構内マップ: {
        return CompanyManualDisplay(companyManualType, ManualKey.手順);
      }
      default:
        return false;
    }
  }

  return (
    <Space
      style={isEdit && !guest ? {} :
        { // 作成画面とゲスト編集画面
          width: guest ? "100%" : "calc(100vw - 200px)",
          position: isEdit && guest ? "static" : "fixed",
          padding: isEdit && guest ? 0 : "10px 38px",
          boxShadow:
            "0px -1px 3px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06)",
          flexDirection: "row-reverse",
          background: "white",
          bottom: 0,
        }}
    >
      {/* 『送信』『次へ』ボタン */}
      {displayNextButton() &&
        <CustomButton
          type="primary"
          style={{ width: 120, height: 50 }}
          onClick={() => {
            form.setValidate(true);
            if (enableNextButton()) {
              if (isEdit) {
                onSubmit();
              } else {
                switch (_key) {
                  case ManualKey.基本情報:
                    (infoIndexState === ManualInfo.行先での作業 || !CompanyManualDisplay(companyManualType, ManualKey.基本情報)) ?
                      setKey(ManualKey.手順) :
                      setInfoIndexState(infoIndexState + 1);
                    break;
                  case ManualKey.手順:
                    stepIndexState < stepCount() - 1 && setStepIndexState(stepIndexState + 1);
                    stepIndexState === stepCount() - 1 && setKey(ManualKey.構内マップ);
                    break;
                  case ManualKey.構内マップ:
                    onSubmit();
                    break;
                  default:
                    console.log("Unhandled case");
                }
              }
              form.setValidate(false);
              window.scrollTo({ top: 0 });
            }
          }}
        >
          {(isEdit || _key === ManualKey.構内マップ) ? "送信" : "次へ"}
        </CustomButton>
      }
      {/* 作成時の基本情報入力までの『送信』ボタン */}
      {
        _key === ManualKey.基本情報 && infoIndexState === ManualInfo.行先での作業 && !isEdit && (
          <CustomButton
            type="primary"
            style={{ width: 120, height: 50 }}
            onClick={() => {
              form.setValidate(true);
              if (enableNextButton()) {
                onSubmit();
                form.setValidate(false);
              }
            }}
          >
            送信
          </CustomButton>
        )
      }
      {/* 『戻る』ボタン */}
      {displayBackButton() && (
        <CustomButton
          style={{ width: 120, height: 50 }}
          onClick={() => {
            switch (_key) {
              case ManualKey.基本情報:
                setInfoIndexState(infoIndexState - 1);
                break;
              case ManualKey.手順:
                if (stepIndexState === 0 && setKey) setKey(ManualKey.基本情報);
                else setStepIndexState(stepIndexState - 1);
                break;
              case ManualKey.構内マップ:
                setKey(ManualKey.手順);
                break;
              default:
                console.log("Unhandled case");
            }
          }}
        >
          戻る
        </CustomButton>
      )}
    </Space>
  );
};

export const _ManualsCreatePage = (props: HistoryProps & { guest?: boolean }) => {
  const { token } = theme.useToken();
  const topRef = useRef<HTMLDivElement>(null);
  const globalState = useContext(GlobalStateContext);
  const deliveryCompanyApi = useFetchDeliveryCompanyApi();
  const deliveryCompaniesApi = useFetchAllDeliveryCompaniesApi();
  const deliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const consignorsApi = useFetchAllConsignorsApi();
  const companyCurrentApi = useFetchCurrentCompanyApi();
  const templateApi = useFetchTemplateApi();
  const postApi = usePostManualApi();
  const [key, setKey] = useState<ManualKey>(ManualKey.基本情報);
  const [infoIndexState, setInfoIndexState] = useState<number>(0);
  const [stepIndexState, setStepIndexState] = useState<number>(0);

  const template = (): Template => {
    return templateApi.response.data ?? {};
  };

  const company = (): Company => {
    return companyCurrentApi.response.data;
  }

  const manuals = (): Manual[] => {
    return deliveryCompanyApi.response.data.manuals ?? []
  }

  const deliveryCompanies = (): DeliveryCompany[] => {
    return deliveryCompaniesApi.response.data ?? []
  }

  const deliveryPartnerCompanies = (): DeliveryPartnerCompany[] => {
    return deliveryPartnerCompaniesApi.response.data ?? []
  }

  const consignors = (): Consignor[] => {
    return consignorsApi.response.data ?? []
  }

  const params = useQuery<{ deliveryCompanyId?: ID; deliveryPartnerCompanyId?: ID; consignorId?: ID; manualId?: ID; deliveryPointCompanyId?: ID }>();

  const form = useForm<Manual>({
    consignorId: params.consignorId,
    deliveryPartnerCompanyId: params.deliveryPartnerCompanyId,
    deliveryCompanyId: params.deliveryCompanyId,
    manualId: params.manualId,
    deliveryPointCompanyId: params.deliveryPointCompanyId,
    companyId: globalState.user.companyId,
    points: []
  });
  const validationResultForm = useForm<Manual>({});

  useEffect(() => {
    deliveryCompaniesApi.execute();
    deliveryPartnerCompaniesApi.execute();
    consignorsApi.execute();
    companyCurrentApi.execute();
    if (!params.manualId && params.deliveryCompanyId && params.deliveryPointCompanyId) {
      deliveryCompanyApi.execute(params.deliveryCompanyId);
    }
  }, []);

  useEffect(() => {
    if (topRef.current) {
      topRef.current?.scrollIntoView();
    }
  }, [stepIndexState]);

  useEffectSkipFirst(() => {
    globalState.setLoading(templateApi.loading || companyCurrentApi.loading);
    if (template()) {
      form.set({
        ...form.object,
        templateId: form.object.templateId ? form.object.templateId : company()?.defaultTemplateId,
        selectedStepNos: template().steps?.map(step => step.stepNo ?? 0),
        manualDetails: template().steps?.filter(step => form.object.selectedStepNos?.includes(step.stepNo ?? 0)).map((step) => ({
          stepNo: step?.stepNo,
          picture1: undefined,
          manualProcesses: step?.questions?.map((question) => ({
            questionNo: question.questionNo,
            questionId: question.id,
            value: "",
          })),
        })),
      });
    }
  }, [templateApi.loading, companyCurrentApi.loading]);

  useEffectSkipFirst(() => {
    if (form.object.templateId) {
      templateApi.execute(form.object.templateId);
    }
  }, [form.object.templateId]);

  useEffect(() => {
    if (deliveryCompaniesApi.isSuccess() && consignorsApi.isSuccess() &&
      (params.consignorId || params.deliveryCompanyId)) {
      const deliveryCompany = deliveryCompanies()?.find(company => company.id === params.deliveryCompanyId);
      const deliveryPartnerCompany = deliveryCompany?.deliveryPartnerCompanies?.length === 1 ? deliveryCompany?.deliveryPartnerCompanies[0] : undefined;
      const consignor = consignors()?.find(company => company.id === params.consignorId);
      form.update((f) => {
        f.deliveryCompanyId = params.deliveryCompanyId;
        f.deliveryCompany = deliveryCompany;
        f.deliveryPartnerCompanyId = deliveryPartnerCompany?.id;
        f.deliveryPartnerCompany = deliveryPartnerCompany;
        f.consignorId = params.consignorId;
        f.consignor = consignor;
      });
    }
  }, [deliveryCompaniesApi.loading, consignorsApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      if (props.guest) {
        props.history.push("/confirmation", { title: "納入カルテの作成" });
      } else {
        const redirectTo = () => {
          if (params.manualId) {
            return `/operations/manuals/${params.manualId}`;
          }
          return "/operations/manuals";
        };
        props.history.push(redirectTo());
      }
    }
  }, [postApi.loading]);

  return (
    <div ref={topRef}>
      <CustomFormPageHeader
        style={{
          width: props.guest ? "100%" : "calc(100vw - 200px)",
          paddingRight: 38,
        }}
        title={"納入カルテ作成"}
        childrenStyle={{
          display: "flex",
          justifyContent: "center",
          padding: 10,
          paddingTop: 10,
        }}
        handleLogoClick={() => props.history.push("/operations/manuals")}
        extra={props.guest ? <></> :
          <CustomButton
            style={{ width: 120 }}
            dialogconfirmProps={{
              title: "編集内容を削除しますか？",
              content: "納入カルテの作成を終了します。",
              okText: "削除する",
              onOk: () => {
                props.history.push("/operations/manuals");
              },
              cancelText: "戻る",
              okButtonProps: { danger: true, style: { background: "#FFEFEE" } },
              icon: null,
              centered: true,
              closable: false,
            }}
          >
            キャンセル
          </CustomButton>
        }
      >
        <></>
      </CustomFormPageHeader>
      <Space direction="vertical" style={{ display: 'flex', gap: 62, paddingTop: 50, }}>
        <Skeleton loading={deliveryCompaniesApi.loading || deliveryPartnerCompaniesApi.loading || consignorsApi.loading || companyCurrentApi.loading}>
          <ManualForm
            form={form}
            validationResultForm={validationResultForm}
            template={template()}
            templates={company()?.templates}
            companyManualType={company().manualType}
            _key={key}
            infoIndexState={infoIndexState}
            stepIndexState={stepIndexState}
            setStepIndexState={setStepIndexState}
            deliveryCompanies={deliveryCompanies()}
            deliveryPartnerCompanies={deliveryPartnerCompanies()}
            consignors={consignors()}
            manuals={manuals()}
            guest={props.guest}
          />
        </Skeleton>
        <ManualPageFooter
          isEdit={false}
          form={form}
          validationResultForm={validationResultForm}
          companyManualType={company()?.manualType}
          _key={key}
          setKey={setKey}
          infoIndexState={infoIndexState}
          setInfoIndexState={setInfoIndexState}
          stepIndexState={stepIndexState}
          setStepIndexState={setStepIndexState}
          template={template()}
          onSubmit={() => postApi.execute(form)}
          guest={props.guest}
        />
      </Space>
    </div>
  );
};

const _ManualsEditPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  type SearchForm = {
    key: ManualKey;
  };
  const query = useQuery<SearchForm>();

  return (
    <CustomFormPageHeader
      style={{
        width: "calc(100vw - 200px)",
        paddingRight: 38,
      }}
      title={
        <Space direction="horizontal">
          {`${ManualKey[query.key]}を編集`}
        </Space>
      }
      extra={
        <CustomButton
          style={{ width: 120 }}
          dialogconfirmProps={{
            title: "編集内容を削除しますか？",
            content: "納入カルテの編集を終了します。",
            okText: "削除する",
            onOk: () => {
              props.history.goBack();
            },
            cancelText: "戻る",
            okButtonProps: { danger: true, style: { background: "#FFEFEE" } },
            icon: null,
            centered: true,
            closable: false,
          }}
        >
          キャンセル
        </CustomButton>
      }
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/operations/manuals")}
    >
      <ManualsEditPageInner {...props} _key={Number(query.key)} />
    </CustomFormPageHeader>
  );
};

export const ManualsEditPageInner = (props: HistoryProps & { isCancel?: boolean, guest?: boolean, _key: ManualKey }) => {
  const globalState = useContext(GlobalStateContext);
  const manualApi = useFetchManualApi();
  const templateApi = useFetchTemplateApi();
  const deliveryCompanyApi = useFetchAllDeliveryCompaniesApi();
  const deliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const consignorsApi = useFetchAllConsignorsApi();
  const params = useParams<{ id: string }>();
  const form = useForm<Manual>({});
  const validationResultForm = useForm<Manual>({});
  const editApi = useEditManualApi();
  const [key, setKey] = useState<ManualKey>(props._key ?? ManualKey.基本情報);
  const [infoIndexState, setInfoIndexState] = useState<number>(0);
  const [stepIndexState, setStepIndexState] = useState<number>(0);

  useEffect(() => {
    deliveryCompanyApi.execute();
    deliveryPartnerCompaniesApi.execute();
    consignorsApi.execute();
    manualApi.execute(params.id);
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(manualApi.loading);
    if (manualApi.isSuccess() && manualApi.response.data.templateId) {
      templateApi.execute(manualApi.response.data.templateId);
    }
  }, [manualApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(templateApi.loading);
    if (templateApi.isSuccess()) {
      // templateApiからstepのidの配列を取得
      const stepIds =
        templateApi.response.data?.steps?.map((step) => step.id) ?? [];
      // manualDetailsからstepIdがstepIdsに含まれる要素だけをフィルター
      const filteredManualDetails =
        manualApi.response.data.manualDetails?.filter((detail) =>
          stepIds.includes(detail.stepId)
        ) || [];
      form.set({
        // カルテ名、カルテ詳細、選択されたstepを初期ローディング
        ...manualApi.response.data,
        title: manualApi.response.data.title
          ? manualApi.response.data.title
          : `${manualApi.response.data.deliveryCompany?.name}-${manualApi.response.data.consignor?.name}`,
        manualDetails: filteredManualDetails,
        selectedStepNos: templateApi.response.data?.steps?.map(step => step.stepNo ?? 0),
      });
    }
  }, [templateApi.loading]);

  const template: Template = useMemo(() => {
    globalState.setLoading(templateApi.loading);
    if (templateApi.isSuccess()) {
      return templateApi.response.data || {};
    } else return {};
  }, [templateApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      if (props.guest) {
        props.history.push(`/manuals/${editApi.response.data.manualId || params.id}?token=${CookieManager.getUserToken()}&isEdit=true`);
      } else {
        props?.history.push(`/operations/manuals/${editApi.response.data.manualId || params.id}`);
      }
    }
  }, [editApi.loading]);

  return (
    <Space direction="vertical" size={16} style={{ paddingBottom: 62 }}>
      <Skeleton loading={form.isEmpty()}>
        {!form.isEmpty() && (
          <ManualForm
            form={form}
            validationResultForm={validationResultForm}
            template={template}
            _key={props._key}
            isEdit
            infoIndexState={infoIndexState}
            stepIndexState={stepIndexState}
            setStepIndexState={setStepIndexState}
            deliveryCompanies={deliveryCompanyApi.response.data}
            deliveryPartnerCompanies={deliveryPartnerCompaniesApi.response.data}
            consignors={consignorsApi.response.data}
          />
        )}
      </Skeleton>
      <Space
        style={{
          boxShadow:
            "0px -1px 3px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06)",
          flexDirection: "row-reverse",
          width: props.guest ? "100vw" : "calc(100vw - 200px)",
          background: "white",
          position: "fixed",
          left: props.guest ? 0 : 200,
          bottom: 0,
          padding: "10px 38px",
        }}
      >
        <ManualPageFooter
          isEdit
          guest={props.guest}
          form={form}
          validationResultForm={validationResultForm}
          _key={key}
          setKey={setKey}
          infoIndexState={infoIndexState}
          setInfoIndexState={setInfoIndexState}
          stepIndexState={stepIndexState}
          setStepIndexState={setStepIndexState}
          template={template}
          onSubmit={() => editApi.execute(form)}
        />
      </Space>
    </Space>
  );
};

export const ManualsEditPage = withRouter(_ManualsEditPage);

export const ManualsCreatePage = withRouter(_ManualsCreatePage);