import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { useQuery } from "utils/hooks";
import dayjs from "dayjs";
import { DeliveriesPage } from "./deliveries_page";
import { DeliveriesOldPage } from "./old/deliveries_old_page";

const DeliveriesRoot = (props: HistoryProps) => {
    const query = useQuery<{ date: string; }>();
    const releasedDate = "2023-10-01"
    const currentDate = query.date ? query.date : dayjs().format('YYYY/MM/DD');

    return (
        new Date(currentDate) < new Date(releasedDate) ?
            <DeliveriesOldPage {...props} /> :
            <DeliveriesPage {...props} />
    );
};

export default withRouter(DeliveriesRoot);