import { Vehicle, VehicleLicenceTypes, VehicleTypes } from "entities/vehicle";

export const VehicleTypeView = (vehicle?: Vehicle) => {
  if (vehicle?.vehicleType !== undefined) {
    return VehicleTypes[vehicle.vehicleType as number]
  } else {
    return "--"
  }
}

export const VehicleLicenseTypeView = (vehicle?: Vehicle) => {
  if (vehicle?.licenseType !==undefined) {
    return VehicleLicenceTypes[vehicle.licenseType as number]
  } else {
    return "--"
  }
}
