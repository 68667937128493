import {
  ApiSet,
  BaseResponse,
  BulkPostResponse,
  CountsApiResponse,
  IndexApiSet,
  useDeleteApi,
  useDownloadApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import { Vehicle, VehicleForm, VehicleSearchForm } from "entities/vehicle";
import { ID, PagingResponse } from "entities";

type AllVehiclesResponse = BaseResponse<Vehicle[]>;

export function useFetchAllVehiclesApi(): ApiSet<AllVehiclesResponse> & {
  execute: () => void;
} {
  const api = useIndexApi<AllVehiclesResponse>(new HttpClient(), {
    initialResponse: { data: [] },
  });

  const execute = (): void => {
    const apiPath = `vehicle/all`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export type VehiclesResponse = PagingResponse<Vehicle>;

export function useFetchVehiclesApi(
  searchForm?: Form<VehicleSearchForm>
): IndexApiSet<VehiclesResponse> & { execute: (query?: string) => void } {
  const apiPath = "vehicle";
  const api = useIndexApi<VehiclesResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type VehicleResponse = BaseResponse & {
  data: Vehicle;
};

export function useFetchVehicleApi(): ApiSet<VehicleResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<VehicleResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `vehicle/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

type CountsVehicleApiConfig = {
  givenTimes: string[];
};

export function useCountsVehicleApi(
  config: CountsVehicleApiConfig
): ApiSet<CountsApiResponse> & {
  execute: () => void;
} {
  const apiPath = `vehicle/counts`;
  const api = usePostObjectApi<CountsApiResponse, CountsVehicleApiConfig>(
    new HttpClient(),
    {
      initialResponse: {
        data: [],
      },
    }
  );

  const execute = (): void => {
    api.execute(apiPath, config);
  };

  return { ...api, execute };
}

export function usePostVehicleApi(): ApiSet<VehicleResponse> & {
  execute: (form: Form<VehicleForm>) => void;
} {
  const apiSet = usePostApi<VehicleResponse, VehicleForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<VehicleForm>) => {
    const apiPath = `vehicle`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditVehicleApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<VehicleForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, VehicleForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<VehicleForm>) => {
    const apiPath = `vehicle/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
  // const apiSet = usePostApi<BaseResponse, VehicleForm>(new HttpClient(), {
  //   initialResponse: {},
  // });

  // const execute = (form: Form<VehicleForm>) => {
  //   const apiPath = `vehicle/${form.object.id}`;
  //   apiSet.execute(apiPath, form.object);
  // };

  // return { ...apiSet, execute };
}

export function useDeleteVehicleApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `vehicle/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export function usePostVehiclesApi(): ApiSet<BulkPostResponse<Vehicle>> & {
  execute: (vehicles: VehicleForm[]) => Promise<BulkPostResponse<Vehicle>>;
} {
  const apiSet = usePostObjectApi<any, { vehicles: VehicleForm[] }>(
    new HttpClient(),
    {
      initialResponse: {
        meta: { successCount: 0, failCount: 0 },
        data: [],
      },
    },
    { formatJson: true }
  );

  const execute = async (
    vehicles: VehicleForm[]
  ): Promise<BulkPostResponse<Vehicle>> => {
    const apiPath = `vehicle/bulk`;
    const result = await apiSet.execute(apiPath, { vehicles });
    return result.data;
  };

  return { ...apiSet, execute };
}
