import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, withRouter } from "react-router-dom";
import * as H from "history";
import {
  Badge,
  Image,
  Layout as AntdLayout,
  Menu,
  MenuProps,
  Popover,
  Space,
  Button,
  theme,
  Dropdown,
  message,
} from "antd";
import {
  HomeOutlined,
  NotificationOutlined,
  UserOutlined,
  LineChartOutlined,
  TableOutlined,
  GoldOutlined,
  MailOutlined,
} from "@ant-design/icons";
import Logo from "components/../../public/logo.png";
import { GlobalStateContext } from "contexts/global_state_context";

import { HiOutlineUserCircle } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { LoginUserPopover } from "components/login/logout_view";
import { getEnvColor } from "utils/util";
import { HistoryProps } from "routes/app";

const { Header, Content, Sider } = AntdLayout;

type MenuItem = Required<MenuProps>["items"][number];

type LayoutProps = {
  children?: ReactNode;
  history: H.History;
};
const SuperAdminLayout = (props: LayoutProps): JSX.Element => {
  const globalState = useContext(GlobalStateContext);
  const canAccessBoard = globalState.user?.canAccessBoard;

  const { token } = theme.useToken();

  const location = useLocation();

  const isFormPage = (): boolean => {
    return (
      location.pathname.includes("create") || location.pathname.includes("edit")
    );
  };

  const hideSider = () => {
    return isFormPage();
  };

  const HeaderLogoView = (props: HistoryProps) => {
    return (
      <div style={{ padding: "20px 8px 20px 8px", cursor: "pointer" }}>
        <div
          onClick={() => props.history.push("/super-admin/companies")}
          style={{ width: "100%", cursor: "pointer" }}
        >
          {process.env.REACT_APP_ENV_MODE === "PRODUCTION" ||
          process.env.REACT_APP_ENV_MODE === "LOCAL" ? (
            <Image src={Logo} preview={false} width={124} />
          ) : (
            <div style={{ fontWeight: 700, fontSize: 20 }}>
              {process.env.REACT_APP_ENV_MODE}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <AntdLayout style={{ width: "100vw", height: "100%" }}>
      {!hideSider() && (
        <Sider
          theme="light"
          width={200}
          className="site-layout-background"
          collapsed={globalState.collapsed}
          style={{
            border: "1px solid",
            borderColor: token.colorBorder,
            background: token.colorWhite,
            height: "100vh",
          }}
        >
          <Space
            style={{
              justifyContent: "space-between",
              height: "100%",
              width: "100%",
            }}
            direction="vertical"
          >
            <div style={{ paddingLeft: 8 }}>
              <HeaderLogoView history={props.history} />

              <SuperAdminMenu history={props.history} />
            </div>
            <LoginUserPopover history={props.history}>
              <Space
                style={{
                  borderTop: `1px solid ${token.colorBorder}`,
                  padding: 16,
                  height: 69,
                  width: "100%",
                }}
                size={12}
              >
                <HiOutlineUserCircle
                  style={{
                    color: "#6B7280",
                    verticalAlign: "middle",
                  }}
                  size={32}
                />
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: token.colorText,
                    }}
                  >
                    {globalState.user.name}
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      color: token.colorText,
                    }}
                  >
                    {globalState.user.mobile}
                  </div>
                </div>
              </Space>
            </LoginUserPopover>
          </Space>
        </Sider>
      )}
      <AntdLayout style={{ height: "auto" }}>
        <Content
          style={{
            height: "100vh",
            overflowY: "scroll",
            width: hideSider()
              ? globalState.dimension.width
              : globalState.dimension.width - 200,
            backgroundColor: token.colorBgBase,
          }}
        >
          {props.children}
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
};

export default withRouter(SuperAdminLayout);

const location2menuKey = (location: H.Location) => [
  location.pathname.split("/").slice(0, 3).join("/"),
];

const SuperAdminMenu = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const location = useLocation();

  const transform = "scale(1.15, 1.15)";

  const menuItems: MenuItem[] = [
    {
      key: "/super-admin/companies",
      label: "客先",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <GoldOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/super-admin/companies");
      },
    },
    {
      key: "/super-admin/users",
      label: "アカウント",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <UserOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/super-admin/users");
      },
    },
    {
      key: "/super-admin/templates",
      label: "テンプレート",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <HomeOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/super-admin/templates");
      },
    },
  ];
  return (
    <>
      <div
        style={{
          paddingLeft: 8,
          marginTop: 32,
          fontWeight: 600,
        }}
      >
        管理
      </div>
      <Menu
        selectedKeys={location2menuKey(location)}
        mode="inline"
        theme="light"
        style={{ border: "none" }}
        items={menuItems}
      />
    </>
  );
};
