import { useDeleteManualApi, useFetchManualsApi } from "api/manual";
import { GlobalStateContext } from "contexts/global_state_context";

import { ManualSearchForm } from "entities/manual";
import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomButton } from "specifics/button";
import { ID } from "entities";
import { theme } from "antd";
import { AnalysisManualsTable } from "./analysis_manuals_table";

const AnalysisManualsPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  const listApi = useFetchManualsApi();
  const searchForm = useForm<ManualSearchForm>({});
  const deleteApi = useDeleteManualApi();

  useEffect(() => {
    listApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);
  const manuals = () => listApi.response.data;

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);
  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      // subTitle={
      //   <CustomInputField
      //     style={{ width: 320, marginLeft: 20 }}
      //     fieldProps={{
      //       prefix: <SearchOutlined color="#9CA3AF" />,
      //     }}
      //     placeholder={"納入カルテを検索"}
      //     attr="id"
      //     form={searchForm}
      //   />
      // }
      title="納入カルテ"
      extra={[
        <CustomButton
          type="primary"
          key={"createManual"}
          onClick={() => props.history.push("/operations/manuals/create")}
        >
          納入カルテ作成
        </CustomButton>,
      ]}
    >
      <AnalysisManualsTable
        handleDelete={(id: ID) => deleteApi.execute(id)}
        history={props.history}
        dataSource={listApi.response.data}
        listApi={listApi}
        pagination={{
          current: listApi.response.meta?.currentPage,
          pageSize: listApi.response.meta?.perPage,
          total: listApi.response.meta?.total,
        }}
      />
    </CustomPageHeader>
  );
};

export default withRouter(AnalysisManualsPage);
