import { Space, TableProps, theme } from "antd";
import { ColumnsType } from "antd/lib/table";

import { ID } from "entities";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { AttrColumn, CustomTable } from "specifics/table";
import { CustomTableText } from "specifics/table_text";
import { useTable } from "specifics/use_table";
import { Prefectures } from "utils/util";
import { DeliveryPartnerVehicleTypeView } from "./delivery_partner_companies_view";
import { DeliveryPartnerCompaniesResponse, useEditDeliveryPartnerCompanyApi } from "api/delivery_partner_company";
import { IndexApiSet } from "utils/network/api_hooks";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomSelectField } from "specifics/input";
import { useContext, useEffect } from "react";
import { GlobalStateContext } from "contexts/global_state_context";

type DeliveryPartnerCompaniesTableProps = TableProps<DeliveryPartnerCompany>;

export const DeliveryPartnerCompaniesTable = (
  props: DeliveryPartnerCompaniesTableProps &
    HistoryProps & { handleDelete: (id: ID) => void } & {
      listApi?: IndexApiSet<DeliveryPartnerCompaniesResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const { token } = theme.useToken();
  const form = useForm<DeliveryPartnerCompany>({});
  const validationResultForm = useForm<DeliveryPartnerCompany>({});
  const editApi = useEditDeliveryPartnerCompanyApi();
  const globalState = useContext(GlobalStateContext);
  const table = useTable<DeliveryPartnerCompany>(props);

  useEffectSkipFirst(() => {
    editApi.execute(form);
  }, [form.object.followUpPeriodMonths]);
  useEffect(() => {
    if (editApi.isSuccess()) {
      globalState.setLoading(true);
      props.listApi?.execute();
    }
  }, [editApi.loading])
  const columns: ColumnsType<DeliveryPartnerCompany> = [
    {
      ...table.baseColumn("name"),
      ...table.getColumnSearchProps("name", "名称"),
      title: "名称",
      width: 200,
      render: (item) => {
        return <CustomTableText width={150}>{item.name}</CustomTableText>;
      },
    },
    AttrColumn<DeliveryPartnerCompany>({
      attr: "area",
      label: "主要エリア",
      width: 316,
      render: (item) =>
        item.area
          ?.split(",")
          .map((id) => Prefectures[id])
          .join(","),
    }),
    AttrColumn<DeliveryPartnerCompany>({
      attr: "deliveryPartnerVehicles",
      label: "保有車両",
      width: 316,
      render: (item) =>
        item.deliveryPartnerVehicles
          ?.map(
            (vehicle) =>
              `${DeliveryPartnerVehicleTypeView(vehicle)}(${vehicle.weight}t)`
          )
          .join(","),
    }),
    AttrColumn<DeliveryPartnerCompany>({
      attr: "followUpPeriodMonths",
      label: "定期フォロー",
      width: 152,
      render: (item) =>
        <CustomSelectField
          style={{ width: "100%" }}
          form={form}
          validationResultForm={validationResultForm}
          attr="followUpPeriodMonths"
          selectItems={Object.entries((globalState.setting.defaultSetting || {})["実態の記入依頼"]?.find(setting => setting.key === "followUpPeriodMonths")?.items || []).map(([label, value]) => ({
            label, value
          }))}
          fieldProps={{
            value: item.followUpPeriodMonths || Number(globalState.setting.customSetting?.followUpPeriodMonths),
            onClick: (e) => e.stopPropagation()
          }}
          onChange={(e) => {
            form.set({
              ...item,
              followUpPeriodMonths: e
            })
          }}
        />
    }),
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 138,
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(
                `/operations/delivery-partner-companies/${item.id}/edit`
              );
            }}
          >
            編集
          </CustomButton>
          <CustomButton
            style={{
              fontWeight: 500,
              padding: 0,
              height: "30px",
              width: "28px",
              textAlign: "left",
            }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              props.handleDelete(item.id);
            }}
            danger
            confirm
            popconfirmProps={{
              title: "削除します。よろしいですか？",
            }}
          >
            削除
          </CustomButton>
        </Space>
      ),
    },
  ];

  return (
    <CustomTable
      tableKey="delivery_partner_companies_table"
      table={table}
      pagination={table.paginationInfo}
      onChange={table.handleChange}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () =>
          props.history.push(`/operations/delivery-partner-companies/${data.id}`),
      })}
      {...props}
    />
  );
};
