import { Button, Result } from "antd";
import * as H from "history";
import { GlobalStateContext } from "contexts/global_state_context";
import React, { useContext } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";

const Error404Page = (props: HistoryProps): JSX.Element => {
  const globalState = useContext(GlobalStateContext);
  const location = useLocation();

  return (
    <div style={{ width: "100%", height: "100vh", alignItems: "center" }}>
      <Result
        style={{
          transition: "all 0.2s",
        }}
        status="404"
        title="404 Not found."
        subTitle="指定したURLではページが見つかりませんでした。"
        extra={
          <CustomButton
            onClick={() => {
              let nextPath;
              switch (process.env.REACT_APP_TYPE) {
                case "ADMIN":
                  nextPath = "/operations/orders";
                  break;
                case "DRIVER":
                  nextPath = "/drivers/delivers";
                  break;
                case "SUPER_ADMIN":
                  nextPath = "/super-admin/templates";
                  break;
                default:
                  return;
              }
              props.history.push(nextPath);
            }}
            type="primary"
          >
            ホームへ戻る
          </CustomButton>
        }
      />
    </div>
  );
};

export default withRouter(Error404Page);
