import "dayjs/locale/zh-cn";
import locale from "antd/es/locale/ja_JP";

import {
  Button,
  ConfigProvider,
  RadioChangeEvent,
  Space,
  Switch,
  theme,
} from "antd";
import {
  CheckboxGroupField,
  CheckboxGroupFieldProps,
  DateField,
  DateFieldProps,
  FileField,
  FileFieldProps,
  InputField,
  InputFieldProps,
  RangeField,
  RangeFieldProps,
  TimeField,
  TimeFieldProps,
  SelectField,
  SelectFieldProps,
  SelectItem,
  SelectRadioField,
  SelectRadioFieldProps,
  TextAreaField,
  TextAreaFieldProps,
  TreeSelectField,
  TreeSelectFieldProps,
  TimeRangeField,
  TimeRangeFieldProps,
  InputNumberFieldProps,
  InputNumberField,
  BooleanSwitchField,
  BooleanSwitchFieldProps,
} from "components/shared/input";

import { CSSProperties, useState } from "react";
import useMedia from "use-media";
import { UploadOutlined } from "@ant-design/icons";
import { Form, FormAttrType } from "utils/hooks";
import { BaseEntity } from "entities";
import dayjs from "dayjs";
import "moment/locale/ja";
import { includesHighlightRegex } from "utils/util";

dayjs.locale("ja");

export type FormRule = {
  isValid: (value: any) => boolean;
  message: string;
};

export const CustomInputField = <T extends any>(props: InputFieldProps<T>) => {
  const {
    form,
    attr,
    label,
    isHighlight,
    required,
    style,
    labelStyle,
    itemProps,
    ...rest
  } = props;

  const isMobile = useMedia("(max-width: 519px)");
  const [highlightMode, setHighlightMode] = useState(
    includesHighlightRegex(form.getValue(attr) as string)
  );
  return (
    <div style={{ textAlign: "left" }}>
      {
        <LabelView
          label={label}
          style={labelStyle}
          required={required}
          isHighlight={isHighlight}
          highlightMode={highlightMode}
          setHighlightMode={setHighlightMode}
        />
      }
      <InputField
        form={form}
        attr={attr}
        isHighlight={isHighlight && highlightMode}
        {...{
          itemProps: {
            style: { marginBottom: 0 },
            ...itemProps,
          },
          style: {
            borderRadius: 6,
            height: isMobile ? 42 : 32,
            transition: "none",
            ...style,
          },
          ...rest,
        }}
      />
    </div>
  );
};

export const CustomInputNumberField = <T extends any>(
  props: InputNumberFieldProps<T>
) => {
  const { form, attr, label, required, style, labelStyle, itemProps, fieldProps, ...rest } =
    props;

  const isMobile = useMedia("(max-width: 519px)");
  return (
    <div style={{ textAlign: "left" }}>
      {<LabelView label={label} style={labelStyle} required={required} />}
      <InputNumberField
        form={form}
        attr={attr}
        {...{
          itemProps: {
            style: { marginBottom: 0 },
            ...itemProps,
          },
          fieldProps: {
            max: 99999999,
            min: -99999999,
            ...fieldProps,
          },
          style: {
            borderRadius: 6,
            height: isMobile ? 42 : 32,
            transition: "none",
            ...style,
          },
          ...rest,
        }}
      />
    </div>
  );
};

export const CustomTextAreaField = <T extends any>(
  props: TextAreaFieldProps<T> & { labelStyle?: CSSProperties }
) => {
  const { token } = theme.useToken();
  const { label, labelStyle, required, style, itemProps, ...rest } = props;

  return (
    <div>
      <style>
        {`
          .ant-input:hover {
            border-color: ${token.colorPrimaryHover};
          }
          .ant-input:focus {
            border-color: ${token.colorPrimaryHover};
            box-shadow: 0 0 0 2px ${token.colorPrimaryBgHover};
          }
        `}
      </style>
      {<LabelView style={labelStyle} label={label} required={required} />}
      <TextAreaField
        {...{
          itemProps: { style: { marginBottom: 0 }, ...itemProps },
          style: { borderRadius: 6, ...style },
          ...rest,
        }}
      />
    </div>
  );
};

export const CustomFileField = <T extends any>(
  props: FileFieldProps<T> & { labelStyle?: CSSProperties }
) => {
  const { label, labelStyle, required, style, itemProps, fieldProps, text, accept, ...rest } =
    props;

  enum Accepts {
    png = "image/png",
    jpeg = "image/jpeg",
    pdf = "application/pdf",
    ppt = "application/vnd.ms-powerpoint",
    pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  }

  return (
    <>
      {<LabelView style={labelStyle} label={label} required={required} />}
      <FileField
        fieldProps={{
          accept: accept?.map(acc => Accepts[acc as keyof typeof Accepts]).join(", "),
          beforeUpload: () => false,
          ...fieldProps,
          disabled: false,
        }}
        uploadButton={
          <Button
            disabled={fieldProps?.disabled}
            icon={<UploadOutlined />}
            style={{ fontWeight: 400, width: "100%" }}
          >
            {text ?? "画像をアップロード"}
          </Button >
        }
        {...{
          itemProps: { style: { marginBottom: 0 }, ...itemProps },
          style: { borderRadius: 6, width: "100%", ...style },
          ...rest,
        }}
      />
    </>
  );
};

export const CustomSelectField = <T extends any>(
  props: SelectFieldProps<T>
) => {
  const { label, required, style, labelStyle, itemProps, fieldProps, ...rest } =
    props;

  const isMobile = useMedia("(max-width: 519px)");
  return (
    <div className="my-select-container">
      {<LabelView label={label} style={labelStyle} required={required} />}
      <SelectField
        {...{
          itemProps: {
            style: { marginBottom: 0, ...itemProps?.style },
            ...itemProps,
          },
          style: {
            textAlign: "left",
            borderRadius: 6,
          },
          fieldProps: {
            dropdownStyle: { borderRadius: 6 },
            children: <></>,
            style: {
              height: props.fieldProps?.mode ? undefined : isMobile ? 42 : 32,
              width: 250,
              ...style,
            },
            ...fieldProps,
          },
          ...rest,
        }}
      />
    </div>
  );
};

export const CustomTreeSelectField = <T extends any>(
  props: TreeSelectFieldProps<T>
) => {
  const { form, attr, label, required, style, itemProps, ...rest } = props;

  return (
    <div className="my-select-container">
      {<LabelView label={label} required={required} />}
      <TreeSelectField
        form={form}
        attr={attr}
        {...{
          itemProps: { style: { marginBottom: 0 }, ...itemProps },
          style: { borderRadius: 6, width: 250, ...style },
          ...rest,
        }}
      />
    </div>
  );
};

export const CustomDateField = <T extends any>(props: DateFieldProps<T>) => {
  const { form, attr, label, required, style, itemProps, fieldProps, ...rest } =
    props;

  return (
    <div className="my-date-picker-container">
      {<LabelView label={label} required={required} />}
      <ConfigProvider locale={locale}>
        <DateField
          form={form}
          attr={attr}
          fieldProps={{
            ...fieldProps,
          }}
          {...{
            itemProps: { style: { marginBottom: 0 }, ...itemProps },
            style: { borderRadius: 6, ...style },
            ...rest,
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export const CustomTimeField = <T extends any>(props: TimeFieldProps<T>) => {
  const { form, attr, label, required, style, itemProps, fieldProps, ...rest } =
    props;

  return (
    <div className="my-date-picker-container">
      {<LabelView label={label} required={required} />}
      <ConfigProvider locale={locale}>
        <TimeField
          form={form}
          attr={attr}
          fieldProps={{ allowClear: false, ...fieldProps }}
          {...{
            itemProps: { style: { marginBottom: 0 }, ...itemProps },
            style: { borderRadius: 6, ...style },
            ...rest,
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export const CustomTimeRangeField = <T extends any>(
  props: TimeRangeFieldProps<T>
) => {
  const { form, attr, label, required, style, itemProps, fieldProps, ...rest } =
    props;

  return (
    <div>
      {<LabelView label={label} required={required} />}
      <ConfigProvider locale={locale}>
        <TimeRangeField
          form={form}
          attr={attr}
          fieldProps={{ allowClear: false, ...fieldProps }}
          {...{
            itemProps: { style: { marginBottom: 0 }, ...itemProps },
            style: { borderRadius: 6, ...style },
            ...rest,
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export const CustomRangeField = <T extends any>(props: RangeFieldProps<T>) => {
  const { form, attr, label, required, style, itemProps, fieldProps, ...rest } =
    props;

  return (
    <div className="my-date-picker-container">
      {<LabelView label={label} required={required} />}
      <RangeField
        form={form}
        attr={attr}
        fieldProps={{ allowClear: false, ...fieldProps }}
        {...{
          itemProps: { style: { marginBottom: 0 }, ...itemProps },
          style: { borderRadius: 6, ...style },
          ...rest,
        }}
      />
    </div>
  );
};

export const CustomCheckboxGroupField = <T extends any>(
  props: CheckboxGroupFieldProps<T> & { labelStyle?: CSSProperties }
) => {
  const { label, labelStyle, required, itemProps, ...rest } = props;
  return (
    <div>
      {
        <LabelView
          label={label as string}
          style={labelStyle}
          required={required}
        />
      }
      <CheckboxGroupField {...rest} />
    </div>
  );
};

export const CustomSelectRadioField = <T extends any>(
  props: SelectRadioFieldProps<T> & {
    labelStyle?: CSSProperties;
    deselectable?: boolean;
  }
) => {
  const {
    label,
    labelStyle,
    required,
    itemProps,
    attr,
    form,
    deselectable,
    ...rest
  } = props;
  return (
    <div>
      {
        <LabelView
          label={label as string}
          style={labelStyle}
          required={required}
          deselectable={deselectable}
          onChange={props.onChange ?? ((e: RadioChangeEvent) => { form.updateObject(attr, e.target.value); })}
          attr={attr}
          form={form}
        />
      }
      <SelectRadioField
        direction="vertical"
        {...rest}
        attr={attr}
        form={form}
      />
    </div>
  );
};

export const CustomBooleanSwitchField = <T extends any>(
  props: BooleanSwitchFieldProps<T> & {
    labelStyle?: CSSProperties;
    deselectable?: boolean;
  }
) => {
  const {
    label,
    labelStyle,
    required,
    itemProps,
    attr,
    form,
    deselectable,
    ...rest
  } = props;
  return (
    <div>
      {
        <LabelView
          label={label as string}
          style={labelStyle}
          required={required}
          deselectable={deselectable}
          attr={attr}
          form={form}
        />
      }
      <BooleanSwitchField {...rest} attr={attr} form={form} />
    </div>
  );
};

type LabelViewProps = {
  label: string | undefined;
  required?: boolean;
  style?: React.CSSProperties;
  deselectable?: boolean;
  isHighlight?: boolean;
  highlightMode?: boolean;
  setHighlightMode?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: ((e: RadioChangeEvent) => void) | undefined;
  attr?: FormAttrType<any>;
  form?: Form<any>;
};

export const RequiredLabel = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "2px 4px",
        gap: "10px",
        width: 36,
        height: 24,
        background: "#FEF2F2",
        borderRadius: "4px",
        fontWeight: 700,
        fontSize: 14,
        color: "#B91C1C",
      }}
    >
      必須
    </div>
  );
};

type DeselectButtonProps = {
  disabled: boolean;
  onChange?: (e: RadioChangeEvent) => void;
};

export const DeselectButton = (props: DeselectButtonProps) => {
  return (
    <Button
      style={{
        padding: 5,
        fontSize: 12,
        fontWeight: "bold",
      }}
      onClick={() => {
        const e: any = { target: { value: "" } };
        props.onChange?.(e);
      }}
      disabled={props.disabled}
    >
      選択を解除
    </Button>
  );
};

export const LabelView = (props: LabelViewProps) => {
  const {
    label,
    required,
    deselectable,
    isHighlight,
    highlightMode,
    setHighlightMode,
    style,
    attr,
    form,
    ...rest
  } = props;
  const { token } = theme.useToken();
  return label ? (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // height: 24,
        marginBottom: 4,
        width: "100%",
      }}
    >
      <Space
        style={{
          flexDirection: "row",
        }}
        size={10}
      >
        <div
          style={{
            minHeight: 24,
            lineHeight: "24px",
            fontWeight: 600,
            fontSize: 16,
            color: token.colorText,
            ...style,
          }}
        >
          {label}
        </div>
        {required && <RequiredLabel />}
        {isHighlight && setHighlightMode && (
          <>
            <Switch
              checkedChildren="削除"
              unCheckedChildren="ハイライト"
              onChange={(val) => setHighlightMode(val)}
              defaultChecked={highlightMode}
            />
            <span style={{ fontSize: 12, color: token.colorTextSecondary }}>
              {"{{}}"}で囲われた場所をハイライト
            </span>
          </>
        )}
      </Space>
      {deselectable && (
        <DeselectButton
          onChange={props.onChange}
          disabled={props.form?.getValue(props.attr ?? []) == null}
        />
      )}{" "}
      {/* 右側にDeselectButtonをレンダリング */}
    </div>
  ) : (
    <></>
  );
};

type CustomSelectBlockFieldProps = {
  selectItems: SelectItem[];
  value: any;
  onChange: (value: any) => void;
  style?: CSSProperties;
  itemStyle?: CSSProperties;
  selectedColor?: string;
  size?: number;
};

export const CustomSelectBlockField = (props: CustomSelectBlockFieldProps) => {
  return (
    <Space
      size={props.size ?? 32}
      style={{
        padding: 2,
        height: 42,
        background: "#F3F4F6",
        borderRadius: "8px",
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 600,
        alignItems: "center",
        ...props.style,
      }}
    >
      {props.selectItems.map((item, index) => (
        <div
          key={index}
          style={{
            padding: "8px 12px",
            background:
              props.value === item.value
                ? props.selectedColor ?? "#E5E7EB"
                : "inherit",
            borderRadius: "6px",
            boxShadow:
              props.value === item.value
                ? "3px 3px 3px 0 rgba(0, 0, 0, 0.1)"
                : "inherit",
            ...props.itemStyle,
            ...item.style,
          }}
          onClick={() => props.onChange(item.value)}
        >
          {item.label}
        </div>
      ))}
    </Space>
  );
};
export const BaseValidation = <T extends Record<string, any>>(
  f: T,
  form: Form<T>,
  attr: keyof T,
  invalidForm: (form: Form<T>) => boolean,
  errMsg: string
) => {
  if (invalidForm(form)) {
    f[attr] = errMsg as T[keyof T];
  } else {
    f[attr] = undefined as T[keyof T];
  }
};

export const StringValidation = <T extends Record<string, any>>(
  f: T,
  form: Form<T>,
  attr: keyof T,
  required?: boolean
) => {
  if (
    required &&
    // @ts-ignore
    [undefined, null, ""].includes(form.getValue(attr as FormAttrType<T>))
  ) {
    f[attr] = "必須項目です。" as T[keyof T];
  } else if (
    required &&
    ((form.getValue(attr as FormAttrType<T>) as string)?.length ?? 0) >= 256
  ) {
    f[attr] = "文字列の長さは256文字未満である必要があります 。" as T[keyof T];
  } else {
    f[attr] = undefined as T[keyof T];
  }
};

export const KanaValidation = <T extends BaseEntity>(
  f: T,
  form: Form<T>,
  attr: keyof T,
  required?: boolean
) => {
  if (
    required &&
    // @ts-ignore
    [undefined, null, ""].includes(form.getValue(attr as FormAttrType<T>))
  ) {
    f[attr] = "必須項目です。" as T[keyof T];
  } else if (
    required &&
    !(form.getValue(attr as FormAttrType<T>) as string)?.match(/^[ぁ-んー\s]+$/)
  ) {
    f[attr] = "すべて全角ひらがなで入力してください。" as T[keyof T];
  } else if (
    required &&
    ((form.getValue(attr as FormAttrType<T>) as string)?.length ?? 0) >= 256
  ) {
    f[attr] = "文字列の長さは256文字未満である必要があります 。" as T[keyof T];
  } else {
    f[attr] = undefined as T[keyof T];
  }
};

export const ZipCodeValidation = <T extends BaseEntity>(
  f: T,
  form: Form<T>,
  attr: keyof T,
  required?: boolean
) => {
  const value = form.getValue(attr as FormAttrType<T>) as string | undefined;
  if (
    required &&
    // @ts-ignore
    [undefined, null, ""].includes(value)
  ) {
    f[attr] = "必須項目です。" as T[keyof T];
  } else if (
    (required && (value?.length ?? 0) < 8) ||
    (value && !value?.match(/^\d{1,3}-?\d{0,4}$/))
  ) {
    f[attr] = "郵便番号はxxx-xxxxの形式で入力してください。" as T[keyof T];
  } else {
    f[attr] = undefined as T[keyof T];
  }
};

export const BooleanValidation = <T extends BaseEntity>(
  f: T,
  form: Form<T>,
  attr: keyof T,
  required?: boolean
) => {
  if (
    required &&
    // @ts-ignore
    [undefined, null, ""].includes(form.getValue(attr as FormAttrType<T>))
  ) {
    f[attr] = "必須項目です。" as T[keyof T];
  } else {
    f[attr] = undefined as T[keyof T];
  }
};

export const NumberValidation = <T extends Record<string, any>>(
  f: T,
  form: Form<T>,
  attr: keyof T,
  required?: boolean
) => {
  if (
    required &&
    // @ts-ignore
    [undefined, null, ""].includes(form.getValue(attr as FormAttrType<T>))
  ) {
    f[attr] = "必須項目です。" as T[keyof T];
  } else {
    f[attr] = undefined as T[keyof T];
  }
};

export const TelValidation = <T extends BaseEntity>(
  f: T,
  form: Form<T>,
  attr: keyof T,
  required?: boolean
) => {
  const value = form.getValue(attr as FormAttrType<T>) as string | undefined;
  if (
    required &&
    // @ts-ignore
    [undefined, null, ""].includes(value)
  ) {
    f[attr] = "必須項目です。" as T[keyof T];
  } else if (required && (value?.length ?? 0) < 10) {
    f[attr] =
      "電話番号はハイフン(-)無しの10桁以上の半角数字で入力してください。" as T[keyof T];
  } else {
    f[attr] = undefined as T[keyof T];
  }
};

export const EmailValidation = <T extends BaseEntity>(
  f: T,
  form: Form<T>,
  attr: keyof T,
  required?: boolean
) => {
  const value = form.getValue(attr as FormAttrType<T>) as string | undefined;
  if (
    required &&
    // @ts-ignore
    [undefined, null, ""].includes(value)
  ) {
    f[attr] = "必須項目です。" as T[keyof T];
  } else if (required && !value?.match(/[\w\-._]+@[\w\-._]+\.[A-Za-z]+/)) {
    f[attr] = "メールアドレスの形式が不適切です。" as T[keyof T];
  } else {
    f[attr] = undefined as T[keyof T];
  }
};
