import { Select, Space, TableProps, theme, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

import { ID } from "entities";
import { Manual } from "entities/manual";
import dayjs from "dayjs";
import { HistoryProps } from "routes/app";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { queryString } from "utils/util";
import { Contract, Status } from "entities/contract";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";

type ContractsTableProps = TableProps<Contract>;

export const AllTable = (
  props: ContractsTableProps &
    HistoryProps & {
      query: {
        page: number;
        pageSize: number;
        sorter: string;
        filter: string;
      };
      deliveryPartnerCompanies: DeliveryPartnerCompany[];
      showDrawer: (id?: ID) => void
    }
) => {
  const { query, dataSource, ...rest } = props;
  const table = useTable<Manual>(props);
  const { token } = theme.useToken();

  const idsForm = useForm<{ ids: ID[] }>({ ids: [] });

  useEffectSkipFirst(() => {
    idsForm.updateObject("ids", table.selectedRowKeys);
  }, [table.selectedRowKeys]);

  const statusTag = (status: Status) => {
    let backgroundColor = '';
    let textColor = '';
    let borderColor = ''
    if (status <= Status.一年経過) {
      backgroundColor = token.colorErrorActive;
      textColor = token.colorWhite;
      borderColor = backgroundColor;
    } else if (status <= Status.三ヶ月経過) {
      backgroundColor = token.colorErrorBg;
      textColor = token.colorErrorActive;
      borderColor = token.colorErrorActive;
    } else {
      backgroundColor = token.colorPrimaryBg;
      textColor = token.colorPrimaryText;
      borderColor = backgroundColor;
    }

    return (
      <Tag
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          borderColor: borderColor
        }}
      >
        <Space size={3}>
          {status <= Status.三ヶ月経過 && <ExclamationCircleOutlined />}
          {Status[status]}
        </Space>
      </Tag>
    );
  };

  const columns: ColumnsType<Contract> = [
    {
      ...table.baseColumn("title"),
      sorter: (a, b) => ((a.title || "") > (b.title || "") ? 1 : -1),
      title: "カルテタイトル",
      width: 500,
      render: (item) => item.title,
    },
    {
      ...table.baseColumn("deliveryCompany"),
      sorter: (a, b) => ((a.deliveryCompany?.name || "") > (b.deliveryCompany?.name || "") ? 1 : -1),
      title: "行先",
      width: 212,
      render: (item) => item.deliveryCompany?.name || "--",
    },
    {
      ...table.baseColumn("deliveryPartnerCompany"),
      sorter: (a, b) => ((a.deliveryPartnerCompany?.name || "") > (b.deliveryPartnerCompany?.name || "") ? 1 : -1),
      title: "担当運送会社",
      width: 212,
      render: (item) => item.deliveryPartnerCompany?.name || "--",
      filters: props.deliveryPartnerCompanies?.map(company => ({
        text: company.name || '',
        value: company.id || ''
      })),
    },
    {
      ...table.baseColumn("status"),
      sorter: (a, b) => ((a.status || "") > (b.status || "") ? 1 : -1),
      title: "ステータス",
      width: 128,
      render: (item) => item.status < Status.その他 && statusTag(item.status),
      filters: Object.entries(Status).filter(([text, value]) => typeof value === "number").map(([text, value]) => ({
        text, value
      })),
    },
    {
      ...table.baseColumn("updatedAt"),
      sorter: (a, b) => ((a.manualUpdatedAt || "") > (b.manualUpdatedAt || "") ? 1 : -1),
      title: "最終更新日",
      width: 128,
      render: (item) => item.manualUpdatedAt ? dayjs(item.manualUpdatedAt).format("YYYY/MM/DD") : "--",
    },
  ];

  const pagination = {
    current: props.pagination ? props.pagination.current || 0 : 0,
    pageSize: props.pagination ? props.pagination.pageSize || 0 : 0,
    total: props.pagination ? props.pagination.total || 0 : 0,
    start: 0,
    last: 0,
  };
  pagination.start = (pagination?.current - 1) * pagination?.pageSize + 1;
  pagination.last = pagination?.current * pagination?.pageSize;

  let singleClickTimer: NodeJS.Timeout;
  let firstClickEvent = false;
  const oneClickHandler = (onClick: () => void) => {
    if (!firstClickEvent) {
      firstClickEvent = true;
      singleClickTimer = setTimeout(() => {
        onClick();
        firstClickEvent = false;
      }, 300);
    }
  };
  const doubleClickHandler = (onDoubleClick: () => void) => {
    clearTimeout(singleClickTimer);
    onDoubleClick()
    firstClickEvent = false;
  };
  return (
    <CustomTable
      tableKey="contracts_table"
      table={table}
      extraLeft={
        <Space size={25} style={{ fontSize: 13 }}>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              {pagination.total}
            </span>
            件中&ensp;
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              {pagination.start}
            </span>
            ~
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              {pagination.total < pagination.last ? `${pagination.total}` : `${pagination.last}`}
            </span>
            件を表示
          </div>
          <div>
            表示件数
            <Select
              style={{ width: 85, marginLeft: 6 }}
              defaultValue={Number(query.pageSize)}
              options={[
                { value: 10, label: "10件" },
                { value: 20, label: "20件" },
                { value: 50, label: "50件" },
                { value: 100, label: "100件" },
              ]}
              onChange={(value) =>
                props.history.push(
                  `/operations/contracts/${queryString({
                    ...query,
                    pageSize: value,
                  })}`
                )
              }
            />
          </div>
        </Space>
      }
      pagination={table.paginationInfo}
      showSizeChanger={false}
      onChange={table.handleChange}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () => {
          console.log(data.manual?.manualId || data.id);
          oneClickHandler(() => props.showDrawer(data.manual?.manualId || data.id));
        },
        onDoubleClick: () => {
          doubleClickHandler(() => props.history.push(`/operations/manuals/${data.manual?.manualId || data.id}`));
        },
      })}
      dataSource={dataSource?.map((d) => ({ ...d, key: d?.id }))}
      {...rest}
    />
  );
};
