import { Button, Space } from "antd";
import {
  BaseValidation,
  CustomDateField,
  CustomInputNumberField,
  CustomRangeField,
  CustomSelectField,
  CustomSelectRadioField,
  NumberValidation,
  StringValidation,
} from "specifics/input";
import { Form } from "utils/hooks";
import {
  MetricsGroupBy,
  MetricsName,
  METRICS_CONFIG,
  MetricsConfigManager,
  MetricGroupItem,
} from "./metrics_config";
import { useContext, useEffect, useMemo, useState } from "react";
import { CustomButton } from "specifics/button";
import dayjs from "dayjs";
import { useFetchAllDeliveryCompaniesApi } from "api/delivery_company";
import { useFetchAllVehiclesApi } from "api/vehicle";
import { SelectItem } from "components/shared/input";
import { GlobalStateContext } from "contexts/global_state_context";

export type MetricsForm = {
  name?: MetricsName;
  nameJp?: string;
  from?: string;
  to?: string;
  range?: number;
  groupBy?: MetricsGroupBy;
  groups?: MetricGroupItem[];
};

export const MetricsFormView = ({
  form,
  validationResultForm,
  onPostForm,
}: {
  form: Form<MetricsForm>;
  validationResultForm: Form<MetricsForm>;
  onPostForm: () => void;
}) => {
  const metricConfig = useMemo(() => {
    return METRICS_CONFIG.find(
      (config) => config.metricName === form.object.name
    );
  }, [form.object.name]);

  return (
    <Space size={16} direction="vertical" style={{ width: "100%" }}>
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        attr="name"
        selectItems={METRICS_CONFIG.map((config) => ({
          label: config.metricNameJp,
          value: config.metricName,
        }))}
        label="指標の名前"
        onChange={(e) => {
          form.update((f) => {
            f.name = e;
            f.nameJp = METRICS_CONFIG.find(
              (config) => config.metricName === e
            )?.metricNameJp;
            f.groupBy = MetricsGroupBy.NOT_SELECTED;
          });
        }}
      />
      {metricConfig?.includeFromToDateTime && (
        <CustomRangeField
          form={form}
          validationResultForm={validationResultForm}
          attr={"from"} // 便宜上の設定。ToDateTimeも同時にs設定s
          label="期間"
          fieldProps={{
            value: [dayjs(form.object.from), dayjs(form.object.to)],
          }}
          onChange={([from, to]) => {
            form.update((f) => {
              f.from = from;
              f.to = to;
            });
          }}
        />
      )}
      {metricConfig?.includeRangeDays && (
        <CustomInputNumberField
          form={form}
          validationResultForm={validationResultForm}
          attr={"range"}
          label="何日ごとに"
          fieldProps={{
            addonAfter: "日",
            min: 1,
            step: 1,
          }}
        />
      )}
      {metricConfig?.canGroupedBy?.length && (
        <>
          <CustomSelectRadioField
            form={form}
            validationResultForm={validationResultForm}
            attr="groupBy"
            label="グルーピング"
            selectItems={MetricsConfigManager.groupedBySelectItems(
              metricConfig.canGroupedBy
            )}
          />
          <SelectMetricsGroupsField
            form={form}
            validationResultForm={validationResultForm}
          />
        </>
      )}
      <Button onClick={onPostForm} type="primary" style={{ width: "100%" }}>
        検索
      </Button>
    </Space>
  );
};

const SelectMetricsGroupsField = ({
  form,
  validationResultForm,
}: {
  form: Form<MetricsForm>;
  validationResultForm: Form<MetricsForm>;
}) => {
  const listDeliveryCompaniesApi = useFetchAllDeliveryCompaniesApi();
  const listVehiclesApi = useFetchAllVehiclesApi();
  const globalState = useContext(GlobalStateContext);

  const [selectItems, setSelectItems] = useState<SelectItem[]>([]);

  const metricConfig = useMemo(() => {
    const metricConfig_ = METRICS_CONFIG.find(
      (config) => config.metricName === form.object.name
    );
    if (form.object.groupBy === MetricsGroupBy.NOT_SELECTED) {
      const dataType = metricConfig_?.dataType;
      form.updateObject("groups", [
        { label: form.object.nameJp, value: dataType },
      ]);
    }
    return metricConfig_;
  }, [form.object.name]);

  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "name", true);
      if (metricConfig?.includeFromToDateTime) {
        StringValidation(f, form, "from", true);
        StringValidation(f, form, "to", true);
      }
      if (metricConfig?.includeRangeDays) {
        NumberValidation(f, form, "range", true);
      }
      if (metricConfig?.canGroupedBy.length) {
        StringValidation(f, form, "groupBy", true);
      }
      BaseValidation(
        f,
        form,
        "groups",
        (form) => !form.object.groups?.length,
        "必須項目です。"
      );
    });
  }, [JSON.stringify(form.object)]);

  useEffect(() => {
    switch (form.object.groupBy) {
      case MetricsGroupBy.VEHICLE:
        listVehiclesApi.execute();
        break;
      case MetricsGroupBy.DELIVERY_COMPANY:
        listDeliveryCompaniesApi.execute();
        break;
      default:
        form.updateObject("groups", [
          { label: form.object.nameJp, value: metricConfig?.dataType },
        ]);
        break;
    }
  }, [form.object.groupBy]);

  useEffect(() => {
    globalState.setLoading(listVehiclesApi.loading);
    if (
      listVehiclesApi.isSuccess() &&
      form.object.groupBy === MetricsGroupBy.VEHICLE
    ) {
      setSelectItems(
        listVehiclesApi.response.data?.map((item) => ({
          label: item.vehicleCode!,
          value: item.id!,
        })) || []
      );
      form.updateObject(
        "groups",
        form.object.groups?.filter((group) =>
          listVehiclesApi.response.data?.some((item) => item.id === group.value)
        )
      );
    }
  }, [listVehiclesApi.loading]);

  useEffect(() => {
    globalState.setLoading(listDeliveryCompaniesApi.loading);
    if (
      listDeliveryCompaniesApi.isSuccess() &&
      form.object.groupBy === MetricsGroupBy.DELIVERY_COMPANY
    ) {
      setSelectItems(
        listDeliveryCompaniesApi.response.data?.map((item) => ({
          label: item.name!,
          value: item.id!,
        })) || []
      );
      form.updateObject(
        "groups",
        form.object.groups?.filter((group) =>
          listDeliveryCompaniesApi.response.data?.some(
            (item) => item.id === group.value
          )
        )
      );
    }
  }, [listDeliveryCompaniesApi.loading]);

  return form.object.groupBy === MetricsGroupBy.VEHICLE ||
    form.object.groupBy === MetricsGroupBy.DELIVERY_COMPANY ? (
    <CustomSelectField
      form={form}
      validationResultForm={validationResultForm}
      attr={"groups"}
      label="グルーピングのキー"
      selectItems={selectItems}
      fieldProps={{
        mode: "multiple",
        filterOption: (input, option) => {
          return (
            (
              selectItems?.find((item) => item.value === option?.value)
                ?.label as string
            )?.includes(input) ?? true
          );
        },
      }}
      onChange={(e: string[]) => {
        form.updateObject(
          "groups",
          selectItems.filter((item) => e.includes(item.value))
        );
      }}
    />
  ) : (
    <></>
  );
};
