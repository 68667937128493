import { Space, theme } from "antd";
import {
  useDeleteDeliveryPartnerCompanyApi,
  useFetchDeliveryPartnerCompanyApi,
} from "api/delivery_partner_company";
import { GlobalStateContext } from "contexts/global_state_context";

import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst } from "utils/hooks";
import { HistoryProps } from "routes/app";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { Prefectures } from "utils/util";
import { DeliveryPartnerVehicleTypeView, getIndex } from "./delivery_partner_companies_view";

const DeliveryPartnerCompanyPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const deliveryPartnerCompanyApi = useFetchDeliveryPartnerCompanyApi();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    deliveryPartnerCompanyApi.execute(params.id);
  }, []);

  const deleteApi = useDeleteDeliveryPartnerCompanyApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      props.history.push("/operations/delivery-partner-companies");
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deliveryPartnerCompanyApi.loading);
  }, [deliveryPartnerCompanyApi.loading]);

  const deliveryPartnerCompany = (): DeliveryPartnerCompany => {
    return deliveryPartnerCompanyApi.response.data;
  };

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="運送会社詳細"
      onBack={() => props.history.push("/operations/delivery-partner-companies")}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent>
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <Space
            style={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            <DescriptionBlock label="名称">
              {deliveryPartnerCompany()?.name}
            </DescriptionBlock>
            <Space size={24} style={{ verticalAlign: "top", height: "100%" }}>
              <CustomButton
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                onClick={() =>
                  props.history.push(
                    `/operations/delivery-partner-companies/${deliveryPartnerCompany()?.id}/edit`
                  )
                }
              >
                編集
              </CustomButton>
              <CustomButton
                danger
                type="text"
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  padding: 0,
                }}
                confirm
                popconfirmProps={{
                  title: "削除します。よろしいですか？",
                }}
                onClick={() => {
                  deleteApi.execute(params.id);
                }}
              >
                削除
              </CustomButton>
            </Space>
          </Space>

          <DescriptionBlock label="ふりがな">
            {deliveryPartnerCompany()?.kana}
          </DescriptionBlock>
          <DescriptionBlock label="郵便番号">
            {deliveryPartnerCompany()?.zipcode}
          </DescriptionBlock>
          <DescriptionBlock label="住所">
            {deliveryPartnerCompany()?.address}
          </DescriptionBlock>
          <DescriptionBlock label="担当者氏名">
            {deliveryPartnerCompany()?.repName}
          </DescriptionBlock>
          <DescriptionBlock label="電話番号">
            {deliveryPartnerCompany()?.tel}
          </DescriptionBlock>
          <DescriptionBlock label="メールアドレス">
            {deliveryPartnerCompany()?.email}
          </DescriptionBlock>
          <DescriptionBlock label="保有車両">
            <Space direction="vertical" size={8}>
              {deliveryPartnerCompany()?.deliveryPartnerVehicles?.length === 0 && "--"}
              {deliveryPartnerCompany()?.deliveryPartnerVehicles?.map(
                (deliveryPartnerVehicle, index) => (
                  <Space
                    key={deliveryPartnerVehicle?.id}
                    size={16}
                    style={{
                      width: 755,
                      background: token.colorBgBase,
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      padding: 16,
                      borderRadius: 8,
                    }}
                  >
                    <Space
                      style={{
                        alignItems: "flex-start",
                      }}
                    >
                      <div>{getIndex(deliveryPartnerCompany(), index) + 1}.</div>
                      <DescriptionBlock label="車両種類">
                        {DeliveryPartnerVehicleTypeView(deliveryPartnerVehicle)}
                      </DescriptionBlock>
                    </Space>
                    <DescriptionBlock label="車格" style={{ width: 110 }}>
                      {deliveryPartnerVehicle?.weight}t
                    </DescriptionBlock>
                  </Space>
                )
              )}

              <DescriptionBlock label="主要エリア">
                {deliveryPartnerCompany()
                  ?.area?.split(",")
                  .map((prefectureId) => Prefectures[prefectureId])
                  .join(",")}
              </DescriptionBlock>
            </Space>
          </DescriptionBlock>
          {/* <DescriptionBlock label="主要エリア">
            {deliveryPartnerCompany()?.area?.map((area) => (
              <CustomTag key={area}>{area}</CustomTag>
            ))}
          </DescriptionBlock> */}
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(DeliveryPartnerCompanyPage);
