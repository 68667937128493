import { Descriptions, Space, theme } from "antd";
import chroma from "chroma-js";

import { useMemo } from "react";
import { ResponsiveContainer } from "recharts";
import { CustomBarChat } from "specifics/recharts/custom_bar_chat";
import { ContractItems, GraphContract } from "entities/contract";

type GraphChartProps = {
  data: GraphContract;
  target: string;
  setTarget: (target: string) => void;
  width: string;
}

export const GraphChart = ({ data, target, setTarget, width }: GraphChartProps) => {
  const { token } = theme.useToken();

  const colorPallates = chroma
    .scale(["#A9A8FF", "#3730D4"])
    .colors(2); // 2色のパレット（totalとmismatched用）

  const plotData = useMemo(() => {
    const initialData = data ? Object.keys(data).map(key => ({
      label: key,
      id: data[key].id,
      total: data[key].total,
      mismatched: data[key].mismatched,
      mismatchPercentage: data[key].mismatchPercentage,
    })) : [];

    // データが7個未満の場合、空のデータを追加
    if (7 - initialData.length > 0) {
      initialData.push(...Array(7 - initialData.length).fill({}));
    }

    return initialData;
  }, [data]);

  const maxDataValue = useMemo(() => {
    return Math.max(...plotData.map(d => Math.max(d.total || 0, d.mismatched || 0)));
  }, [plotData]);

  const formatedLabel = (value: any) => {
    const label = ContractItems[value as keyof typeof ContractItems] || value;
    return label.length > 5 ? `${label.substring(0, 5)}...` : label // ラベルが4文字以上の場合は末尾を...で省略
  }

  const onBarClick = (payload: any) => {
    setTarget(payload.label);
  };

  return (
    <Space
      size={30}
      style={{
        width: width ? width : "100%",
        background: token.colorWhite,
        borderRadius: 16,
        padding: 24,
        overflowX: 'auto'
      }}
      direction="vertical"
    >
      <Descriptions size="small">
        <Space>
          <span><span style={{ color: colorPallates[0] }}>●</span>登録されている実態（件）</span>
          <span><span style={{ color: colorPallates[1] }}>●</span>不一致（件）</span>
          <span><span style={{ color: 'black' }}>■</span>乖離率</span>
        </Space>
      </Descriptions>
      {data &&
        <ResponsiveContainer width={plotData.length * 100} height={300}>
          <CustomBarChat
            fill={(payload, hover, group) => {
              const isActive = (!target || target === payload?.label);
              const color = group === 'total' ? colorPallates[0] : colorPallates[1];
              const opacity = isActive ? 1 : 0.2; // 選択されたバーは不透明、それ以外は半透明
              return chroma(color).alpha(opacity).css(); // CSS形式の色指定に変更
            }}
            valueKey={['total', 'mismatched']}
            height={250}
            data={plotData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            barSize={16} // バーの幅を設定
            barGap={10} // バー間の間隔を設定
            xAxisLabelKey="label"
            xaxisProps={{
              tickFormatter: formatedLabel
            }}
            yaxisProps={{
              hide: false,
              domain: [0, maxDataValue],
              allowDecimals: false
            }}
            tooltipProps={{
              cursor: false,
              position: { y: 100 },
              wrapperStyle: {
                padding: 0,
                outline: 'none',
                boxShadow: 'none',
              },
              content: ({ active, payload }) => (active && payload && payload.length) ?
                <div style={{
                  fontSize: 12,
                  padding: 6,
                  color: token.colorWhite,
                  backgroundColor: token.colorBgSpotlight,
                  borderColor: token.colorBgSpotlight,
                  borderRadius: '10px'
                }}>
                  {`${payload[0].payload.mismatchPercentage}%`}
                </div> :
                null
            }}
            onBarClick={onBarClick}
            grid
          />
        </ResponsiveContainer>
      }
    </Space>
  );
};