import { Table } from "antd";
import { RotationSummary } from "./rotations_handler";
import { OrderDetail } from "entities/order";
import { CustomTag } from "specifics/tag";
import dayjs from "dayjs";
import { RotationsByDeliveryCompanyIdTable } from "./rotation_summaries_by_delivery_company_id";
import { SortOrder } from "antd/es/table/interface";

export const RotationSummariesTable = ({
  rotationsSummaries,
}: {
  rotationsSummaries: RotationSummary[];
}) => {
  return (
    <Table
      dataSource={rotationsSummaries}
      rowKey={(record: RotationSummary) => record.rotation.id ?? ""}
      expandable={{
        expandedRowRender: (record: RotationSummary) => {
          return (
            <RotationsByDeliveryCompanyIdTable
              rotationSummariesByDeliveryCompanyId={record.rotation.orderDivideds
                .reduce((acc, orderDivided) => {
                  return [...acc, ...(orderDivided.orderDetails || [])];
                }, [] as any[])
                .filter((orderDetail) => orderDetail.loadWeight > 0)}
            />
          );
        },
      }}
      columns={[
        {
          key: "deliveryDate",
          title: "配送日",
          render: (item) => dayjs(item.deliveryDate).format("YYYY-MM-DD"),
          sorter: (
            a: RotationSummary,
            b: RotationSummary,
            sortOrder?: SortOrder
          ) => {
            return a.deliveryDate > b.deliveryDate ? 1 : -1;
          },
        },
        {
          key: "driverName",
          title: "ドライバー名",
          render: (item) => item.driverName ?? "不明",
        },
        {
          key: "rotationTurn",
          title: "回転",
          render: (item) => `${(item.rotationTurn ?? 0) + 1}回転目`,
        },
        {
          key: "unloadCount",
          title: "降し件数",
          render: (item) => `${item.unloadCount}件`,
          sorter: (
            a: RotationSummary,
            b: RotationSummary,
            sortOrder?: SortOrder
          ) => {
            return (a.unloadCount ?? 0) > (b.unloadCount ?? 0) ? 1 : -1;
          },
        },
        {
          key: "loadWeight",
          title: "積載量",
          render: (item) => `${item.loadWeight}kg`,
          sorter: (
            a: RotationSummary,
            b: RotationSummary,
            sortOrder?: SortOrder
          ) => {
            return (a.loadWeight ?? 0) > (b.loadWeight ?? 0) ? 1 : -1;
          },
        },
        {
          key: "vehicleMaxWeight",
          title: "車両の最大積載量",
          render: (item) => `${item.vehicleMaxWeight}t`,
          sorter: (
            a: RotationSummary,
            b: RotationSummary,
            sortOrder?: SortOrder
          ) => {
            return (a.vehicleMaxWeight ?? 0) > (b.vehicleMaxWeight ?? 0)
              ? 1
              : -1;
          },
        },
        {
          key: "loadingRate",
          title: "積載率",
          sorter: (
            a: RotationSummary,
            b: RotationSummary,
            sortOrder?: SortOrder
          ) => {
            return (a.loadingRate ?? 0) > (b.loadingRate ?? 0) ? 1 : -1;
          },
          render: (item) => (
            <div
              style={{
                color:
                  item.loadingRate > 100
                    ? "blue"
                    : item.loadingRate < 20
                    ? "red"
                    : "default",
                fontWeight:
                  item.loadingRate <= 100 && item.loadingRate > 20
                    ? "default"
                    : 700,
              }}
            >
              {item.loadingRate ? `${item.loadingRate.toFixed(1)}%` : "--"}
            </div>
          ),
        },
      ]}
      pagination={false}
    />
  );
};
