import { DatePicker, Divider, Image, Space, theme } from "antd";
import { withRouter } from "react-router";
import { RiMapPin2Line } from "react-icons/ri";
import { BiCommentDetail } from "react-icons/bi";
import { HiOutlineUserCircle } from "react-icons/hi";
import { BsCaretDownFill } from "react-icons/bs";
import {
  MdDragIndicator,
  MdArrowForwardIos,
  MdLockOutline,
} from "react-icons/md";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useContext, useEffect, useMemo, useState } from "react";

import { CustomTag } from "specifics/tag";
import dayjs, { Dayjs } from "dayjs";

import { useSwapOrderApi } from "api/order";
import { HistoryProps } from "routes/app";
import { DeliveryDetail } from "entities/delivery";
import { LoginUserPopover } from "components/login/logout_view";

import Logo from "components/../../public/logo.png";
import { GlobalStateContext } from "contexts/global_state_context";
import { useCheckManualExistApi } from "api/manual";
import { useEffectSkipFirst } from "utils/hooks";
import { ID } from "entities";
import { Dictionary, groupBy } from "lodash";
import locale from "antd/es/date-picker/locale/ja_JP";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useFetchRotationsApi } from "api/rotation";
import { Rotation } from "entities/rotation";
import { OrderDetail } from "entities/order";

const DriversDeliversPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const [date, setDate] = useState<Dayjs>(dayjs().startOf("month"));

  const rotationsApi = useFetchRotationsApi();
  const swapApi = useSwapOrderApi();
  const globalState = useContext(GlobalStateContext);

  const existManualApi = useCheckManualExistApi();
  const [ids, setIds] = useState<{ deliveryCompanyId?: ID; consignorId?: ID }>(
    {}
  );

  useEffect(() => {
    rotationsApi.execute();
  }, []);

  const [deliveryDetailBefore, setDeliveryDetailBefore] = useState<
    DeliveryDetail | undefined
  >(undefined);
  useEffectSkipFirst(() => {
    globalState.setLoading(existManualApi.loading);
    if (existManualApi.isSuccess()) {
      if (existManualApi.response.data.id) {
        if (!deliveryDetailBefore) {
          props.history.push(
            `/drivers/manuals/${existManualApi.response.data.id}?deliveryCompanyId=${ids.deliveryCompanyId}&consignorId=${ids.consignorId}`
          );
        } else {
          props.history.push(
            `/drivers/manuals/${existManualApi.response.data.id}?lat=${deliveryDetailBefore.deliveryCompany?.point?.latitude}&lng=${deliveryDetailBefore.deliveryCompany?.point?.longitude}`
          );
        }
      } else {
        if (!deliveryDetailBefore) {
          props.history.push(
            `/drivers/delivery-companies/detail?consignorId=${ids.consignorId}&deliveryCompanyId=${ids.deliveryCompanyId}`
          );
        } else {
          props.history.push(
            `/drivers/delivery-companies/detail?consignorId=${ids.consignorId}&deliveryCompanyId=${ids.deliveryCompanyId}&lat=${deliveryDetailBefore.deliveryCompany?.point?.latitude}&lng=${deliveryDetailBefore.deliveryCompany?.point?.longitude}`
          );
        }
      }
    }
  }, [existManualApi.loading]);

  const rotations = (): Rotation[] => {
    // 選択月の月初、月末の範囲でフィルター
    const startDate = dayjs(date).startOf("month");
    const endDate = dayjs(date).endOf("month");

    return (
      rotationsApi.response.data?.filter((rotation) => {
        const rotationDate = dayjs(rotation.deliveryDate);
        return (
          (rotationDate.isAfter(startDate) || rotationDate.isSame(startDate)) &&
          (rotationDate.isBefore(endDate) || rotationDate.isSame(endDate))
        );
      }) || []
    );
  };

  const groupedRotations: Dictionary<Rotation[]> = useMemo(() => {
    globalState.setLoading(rotationsApi.loading);
    // deliveryDateに基づいてグループ化
    return groupBy(rotations(), (rotation) => {
      return dayjs(rotation.deliveryDate).format("YYYY/MM/DD");
    });
  }, [rotationsApi.loading, date]);

  useEffectSkipFirst(() => {
    globalState.setLoading(swapApi.loading);
    if (swapApi.isSuccess()) {
      rotationsApi.execute();
    }
  }, [swapApi.loading]);

  const assignedWeight = (rotation: Rotation) => {
    let totalLoadWeight = 0;
    rotation.orderDivideds?.forEach((divided) => {
      divided.orderDetails?.forEach((detail) => {
        if (detail.pointType === 2 && detail.loadWeight) {
          totalLoadWeight += parseFloat(detail.loadWeight?.toString() ?? "");
        }
      });
    });
    return totalLoadWeight ? Number(totalLoadWeight.toFixed(1)) : 0;
  };

  return (
    <div
      css={css`
        .ant-picker-input > input,
        .ant-picker-input {
          font-weight: 700;
          color: #111827;
          font-size: 18px;
          cursor: pointer;
        }
        .ant-picker-suffix {
          color: #111827;
        }
        .ant-picker-content th,
        .ant-picker-content td {
          font-weight: 600;
        }
        .ant-picker-large {
          padding: 0;
        }
        .ant-picker-cell-selected {
          background: #e7e7e7;
        }
      `}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
          height: "100vh",
          background: token.colorBgBase,
        }}
      >
        <Space
          style={{
            height: 48,
            padding: "6px 16px",
            background: token.colorWhite,
            justifyContent: "space-between",
            width: "100%",
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
            position: "fixed",
            top: 0,
            zIndex: 1000,
            boxSizing: "border-box",
          }}
        >
          <Image src={Logo} preview={false} width={108} />
          <Space
            size={0}
            style={{ height: 48 }}
            split={<Divider style={{ height: 24 }} type="vertical" />}
          >
            <Space size={16}>
              <Space
                size={0}
                style={{
                  color: "#6B7280",
                  fontSize: 9,
                  fontWeight: 700,
                  textAlign: "center",
                  background: token.colorWhite,
                  cursor: "pointer",
                }}
                direction="vertical"
                onClick={() => props.history.push("/drivers/manuals")}
              >
                <RiMapPin2Line
                  style={{
                    height: 20,
                    width: 16,
                    transform: "scale(1.33, 1.38)",
                  }}
                />
                <div
                  style={{
                    color: "#6B7280",
                    fontSize: "6px",
                    fontWeight: 700,
                    textAlign: "center",
                    height: 9,
                  }}
                >
                  納入カルテ
                </div>
              </Space>
              <Space
                size={0}
                style={{
                  color: "#6B7280",
                  fontSize: 9,
                  fontWeight: 700,
                  textAlign: "center",
                  cursor: "pointer",
                }}
                direction="vertical"
                onClick={() => props.history.push("/drivers/issues")}
              >
                <BiCommentDetail
                  style={{
                    height: 20,
                    width: 16,
                    transform: "scale(1.33, 1.38)",
                  }}
                />
                <div
                  style={{
                    color: "#6B7280",
                    fontSize: "6px",
                    fontWeight: 700,
                    textAlign: "center",
                    height: 9,
                  }}
                >
                  報告一覧
                </div>
              </Space>
            </Space>
            <LoginUserPopover
              history={props.history}
              placement="bottomRight"
              overlayInnerStyle={{
                borderRadius: "8px",
                marginTop: 10,
                padding: 0,
              }}
            >
              <HiOutlineUserCircle
                style={{
                  color: "#6B7280",
                  cursor: "pointer",
                }}
                size={26}
              />
            </LoginUserPopover>
          </Space>
        </Space>
        <Space
          size={16}
          direction="vertical"
          style={{ padding: 16, paddingTop: 48 }}
        >
          {/* @ts-ignore */}
          <DatePicker // ToDo: Fix Bug
            value={date}
            suffixIcon={<BsCaretDownFill />}
            locale={locale}
            allowClear={false}
            picker="month"
            bordered={false}
            format={(value: Dayjs) =>
              value.month() + 1 >= 10
                ? value.format("配送一覧    MM月")
                : value.format("配送一覧    M月")
            }
            size="large"
            onChange={(date, locale) => {
              if (date) {
                // @ts-ignore
                const newDate = dayjs()
                  .year(date.year())
                  .month(date.month())
                  .date(1);
                setDate(newDate);
              }
            }}
          />
          {rotations().length === 0 ? (
            <Space
              size={32}
              direction="vertical"
              style={{ textAlign: "center", marginTop: 40 }}
            >
              <Space
                style={{
                  marginTop: 8,
                  textAlign: "center",
                  width: globalState.dimension.width - 32,
                }}
                size={24}
                direction="vertical"
              >
                <MdLockOutline
                  style={{ height: 64, width: 64, color: "#9CA3AF" }}
                />
                <Space direction="vertical" size={13}>
                  <div style={{ fontWeight: 700, fontSize: 16, color: "#000" }}>
                    まだ配送が作成されていません
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      color: "#6B7280",
                      padding: "0px 8px 0px 8px",
                    }}
                  >
                    運行管理者がまだ配送を作成していません。
                  </div>
                </Space>
              </Space>
            </Space>
          ) : (
            <>
              {Object.keys(groupedRotations)
                .sort()
                .map((group) => {
                  return (
                    <Space key={group} direction="vertical">
                      <span
                        style={{
                          fontSize: 14,
                          width: 32,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {dayjs(group).locale("ja").format("ddd")}
                      </span>
                      <Space style={{ alignItems: "flex-start" }}>
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            width: 32,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            ...(group === dayjs().format("YYYY/MM/DD") && {
                              height: 32,
                              background: "black",
                              color: "white",
                              borderRadius: "50%",
                            }),
                          }}
                        >
                          {dayjs(group).date()}
                        </span>
                        <Space direction="vertical">
                          {groupedRotations[group].map(
                            (rotation: Rotation, index: number) => {
                              return (
                                <Space
                                  key={rotation.id}
                                  direction="vertical"
                                  style={{
                                    width: "calc(100vw - 94px)",
                                    background: "#E5E7EB",
                                    border: "1px solid #E5E7EB",
                                    boxShadow:
                                      "0px 1px 2px rgba(0, 0, 0, 0.05)",
                                    borderRadius: "6px",
                                    padding: 10,
                                  }}
                                >
                                  <span
                                    style={{ fontSize: 16, fontWeight: 500 }}
                                  >
                                    {index + 1}回転目
                                  </span>
                                  <table
                                    style={{
                                      color: "#6B7280",
                                      width: "100%",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            verticalAlign: "top",
                                            textAlign: "left",
                                            paddingRight: 10,
                                          }}
                                        >
                                          最大積載量
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "top",
                                            width: 1,
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          :
                                        </td>
                                        <td>{rotation.maxWeight}t</td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            verticalAlign: "top",
                                            textAlign: "left",
                                            paddingRight: 10,
                                          }}
                                        >
                                          現在の総積載量
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "top",
                                            width: 1,
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          :
                                        </td>
                                        <td>
                                          {assignedWeight(rotation)}kg（
                                          {rotation.maxWeight
                                            ? Math.round(
                                                (Number(
                                                  assignedWeight(rotation)
                                                ) /
                                                  (rotation.maxWeight * 1000)) *
                                                  100
                                              )
                                            : 0}
                                          %）
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <DragDropContext
                                    onDragEnd={(result: DropResult) => {
                                      const { source, destination } = result;
                                      if (
                                        !destination ||
                                        source.droppableId !==
                                          destination.droppableId ||
                                        source.index === destination.index ||
                                        !rotation.orderDivideds
                                      ) {
                                        return;
                                      }
                                      const details = (
                                        rotation.orderDivideds?.flatMap(
                                          (divided) => {
                                            return (
                                              divided.orderDetails ?? []
                                            ).filter((detail) => {
                                              // 積み降ろし 1-複/複-1/複-複 のパターンの時に、複を分割した際の0tの行き先をフィルタ
                                              // 同じorderDetailsの中で、同じpointTypeを持つ他の要素が存在するか確認
                                              const hasDuplicate =
                                                divided.orderDetails?.some(
                                                  (otherDetail) =>
                                                    otherDetail !== detail &&
                                                    otherDetail.pointType ===
                                                      detail.pointType
                                                );
                                              // 重複があり、かつdetail.loadWeightが0,null,undefinedでない場合、または重複がない場合、detailを保持
                                              return (
                                                !hasDuplicate ||
                                                (hasDuplicate &&
                                                  !!detail.loadWeight)
                                              );
                                            });
                                          }
                                        ) ?? ([] as OrderDetail[])
                                      ).reduce(
                                        (
                                          acc: OrderDetail[],
                                          detail: OrderDetail
                                        ) => {
                                          // 行き先の重複をフィルタ
                                          // 同じdeliveryCompanyIdで、同じpointTypeを持つorderDetailが既に追加されていないか確認
                                          if (
                                            !acc.some(
                                              (d) =>
                                                d.deliveryCompanyId ===
                                                  detail.deliveryCompanyId &&
                                                d.pointType === detail.pointType
                                            )
                                          ) {
                                            acc.push(detail);
                                          }
                                          return acc;
                                        },
                                        []
                                      );
                                      const currentIds = details
                                        .sort(
                                          (a, b) =>
                                            (a?.turnInRotation ?? 0) -
                                            (b?.turnInRotation ?? 0)
                                        )
                                        .map((detail) => detail.id ?? "");
                                      const [removed] = currentIds.splice(
                                        source.index,
                                        1
                                      );
                                      currentIds.splice(
                                        destination.index,
                                        0,
                                        removed
                                      );
                                      swapApi.execute({
                                        ids: currentIds.map(
                                          (id: ID, turnInRotation: number) => ({
                                            id,
                                            turnInRotation,
                                            name: details?.find(
                                              (detail) => detail.id === id
                                            )?.deliveryCompany?.name,
                                          })
                                        ),
                                      });
                                    }}
                                  >
                                    <Droppable droppableId={rotation.id!}>
                                      {(provided, snapshot) => (
                                        <div
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          style={{
                                            background: "white",
                                            border: "1px solid #D1D5DB",
                                            borderRadius: 6,
                                          }}
                                        >
                                          {(
                                            rotation.orderDivideds?.flatMap(
                                              (divided) => {
                                                return (
                                                  divided.orderDetails ?? []
                                                ).filter((detail) => {
                                                  // 積み降ろし 1-複/複-1/複-複 のパターンの時に、複を分割した際の0tの行き先をフィルタ
                                                  // 同じorderDetailsの中で、同じpointTypeを持つ他の要素が存在するか確認
                                                  const hasDuplicate =
                                                    divided.orderDetails?.some(
                                                      (otherDetail) =>
                                                        otherDetail !==
                                                          detail &&
                                                        otherDetail.pointType ===
                                                          detail.pointType
                                                    );
                                                  // 重複があり、かつdetail.loadWeightが0,null,undefinedでない場合、または重複がない場合、detailを保持
                                                  return (
                                                    !hasDuplicate ||
                                                    (hasDuplicate &&
                                                      !!detail.loadWeight)
                                                  );
                                                });
                                              }
                                            ) ?? ([] as OrderDetail[])
                                          )
                                            .reduce(
                                              (
                                                acc: OrderDetail[],
                                                detail: OrderDetail
                                              ) => {
                                                // 行き先の重複をフィルタ
                                                // 同じdeliveryCompanyIdで、同じpointTypeを持つorderDetailが既に追加されていないか確認
                                                if (
                                                  !acc.some(
                                                    (d) =>
                                                      d.deliveryCompanyId ===
                                                        detail.deliveryCompanyId &&
                                                      d.pointType ===
                                                        detail.pointType
                                                  )
                                                ) {
                                                  acc.push(detail);
                                                }
                                                return acc;
                                              },
                                              []
                                            )
                                            .sort(
                                              (a, b) =>
                                                (a?.turnInRotation ?? 0) -
                                                (b?.turnInRotation ?? 0)
                                            )
                                            .map((detail, index) => (
                                              <Draggable
                                                key={detail?.id}
                                                draggableId={detail!.id!}
                                                index={index}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    onClick={() => {
                                                      const divided =
                                                        rotation.orderDivideds?.find(
                                                          (divided) =>
                                                            divided.id ===
                                                            detail?.orderDividedId
                                                        );
                                                      // setDeliveryDetailBefore(
                                                      //   index === 0
                                                      //     ? undefined
                                                      //     : (divided?.orderDetails ??
                                                      //       [])[index - 1]
                                                      // );
                                                      setIds({
                                                        deliveryCompanyId:
                                                          detail?.deliveryCompanyId,
                                                        consignorId:
                                                          divided?.consignorId,
                                                      });
                                                      if (
                                                        detail?.deliveryCompanyId &&
                                                        divided?.consignorId
                                                      ) {
                                                        existManualApi.execute(
                                                          detail.deliveryCompanyId,
                                                          divided.consignorId
                                                        );
                                                      } else {
                                                        // 基本このケースはケアしなくて良い説
                                                        props.history.push(
                                                          `/drivers/delivery-companies/detail?deliveryCompanyId=${detail?.deliveryCompanyId}`
                                                        );
                                                      }
                                                    }}
                                                    ref={provided.innerRef}
                                                    key={detail?.id}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <Space
                                                      style={{
                                                        padding: 16,
                                                        borderTop:
                                                          "1px solid #F3F4F6",
                                                        justifyContent:
                                                          "space-between",
                                                        boxSizing: "border-box",
                                                        fontSize: 14,
                                                      }}
                                                    >
                                                      <Space>
                                                        <MdDragIndicator color="#D1D5DB" />
                                                        <CustomTag
                                                          style={{
                                                            height: 24,
                                                            borderRadius: 4,
                                                            padding:
                                                              "2px 6px 2px 6px",
                                                            color:
                                                              detail?.pointType ===
                                                              1
                                                                ? "#10B981"
                                                                : "#EF4444",
                                                            background:
                                                              detail?.pointType ===
                                                              1
                                                                ? "#ECFDF5"
                                                                : "#FEF2F2",
                                                            lineHeight: "20px",
                                                            fontWeight:
                                                              "bolder",
                                                          }}
                                                        >
                                                          {detail?.pointType ===
                                                          2
                                                            ? "積み"
                                                            : "降し"}
                                                        </CustomTag>
                                                        <span
                                                          style={{
                                                            width:
                                                              window.innerWidth >
                                                              480
                                                                ? "100%"
                                                                : 150,
                                                            display:
                                                              "inline-block",
                                                          }}
                                                        >
                                                          {
                                                            detail
                                                              ?.deliveryCompany
                                                              ?.name
                                                          }
                                                        </span>
                                                      </Space>
                                                      <MdArrowForwardIos />
                                                    </Space>
                                                  </div>
                                                )}
                                              </Draggable>
                                            ))}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </Space>
                              );
                            }
                          )}
                        </Space>
                      </Space>
                    </Space>
                  );
                })}
            </>
          )}
        </Space>
      </Space>
    </div>
  );
};

export default withRouter(DriversDeliversPage);
