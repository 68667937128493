import { Button, Col, Collapse, Divider, Drawer, Menu, Modal, Row, Skeleton, Space, Switch, message, theme } from "antd";
import { CloseOutlined, CarOutlined, EditOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import { useFetchDeliveryCompanyApi } from "api/delivery_company";
import { DeliveryCompany } from "entities/delivery_company";
import { useContext, useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { DriversHeader } from "specifics/drivers_header";
import { useMedia } from "use-media";

import { DriverIssueModal } from "components/drivers_issues/drivers_issue_form";
import { useDeleteManualApi, useFetchManualApi, useFetchManualViewApi } from "api/manual";
import { Manual, ManualKey, ManualMarkers } from "entities/manual";
import { DeliveryCompanyCardView } from "components/delivery_companies/delivery_companies_view";
import { GoogleMapComponent } from "utils/google_map_component";
import { useFetchTemplateApi, useFetchTemplateViewApi } from "api/template";
import { useEffectSkipFirst, useQuery } from "utils/hooks";
import { GlobalStateContext } from "contexts/global_state_context";
import { Consignor } from "entities/consignor";
import { useFetchConsignorApi } from "api/consignor";
import { ManualPreview } from "components/manuals/manual_page";
import { Point, PointType } from "entities/point";
import {
  DriversManualsCreatePageInner,
  DriversManualsEditPageInner,
} from "./drivers_maunal_form";
import dayjs from "dayjs";
import { copyToClipboard } from "utils/util";
import { ManualContractCardView } from "components/manuals/manual_contract_card_view";
import { ConsignorCardView } from "components/consignors/consignors_view";
import { DeliveryPartnerCompanyCardView } from "components/delivery_partner_companies/delivery_partner_companies_view";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";

export const DriversManualPage = (props: HistoryProps & { guest?: boolean; isEdit?: boolean; delivererId?: string; }) => {
  const params = useParams<{ id: string }>();

  const showApi = useFetchManualApi();

  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    showApi.execute(params.id);
  }, [params.id]);

  useEffectSkipFirst(() => {
    globalState.setLoading(showApi.loading);
  }, [showApi.loading]);

  const [open, setOpen] = useState(false);

  const manual = (): Manual => showApi.response.data;

  const [visible, setVisible] = useState(false);

  const onEdit = () => {
    showApi.execute(params.id);
    setVisible(false);
  };

  return (
    <>
      <DriverIssueModal
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        open={open}
        history={props.history}
        deliveryCompany={showApi.response.data.deliveryCompany ?? {}}
        guest={props.guest}
        delivererId={props.delivererId}
      />
      {manual().deliveryCompany && (
        <DriverManualDetailInnerPage
          manual={manual()}
          deliveryCompany={manual().deliveryCompany}
          deliveryPartnerCompany={manual().deliveryPartnerCompany}
          consignor={manual().consignor}
          history={props.history}
          showIssueModal={() => setOpen(true)}
          visible={visible}
          setVisible={setVisible}
          onEdit={onEdit}
          guest={props.guest}
          isEdit={props.isEdit}
        />
      )}
    </>
  );
};

export default withRouter(DriversManualPage);

const DriversManualReadPageInner = (props: HistoryProps) => {
  const params = useParams<{ id: string }>();

  const isMobile = useMedia("(max-width: 519px)");
  const showApi = useFetchManualViewApi();

  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    showApi.execute(params.id);
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(showApi.loading);
  }, [showApi.loading]);

  const manual = (): Manual => showApi.response.data;

  return (
    <>
      {manual().deliveryCompany &&
        <DriverManualDetailInnerPage
          readOnly
          manual={manual()}
          deliveryCompany={manual()?.deliveryCompany}
          consignor={manual()?.consignor}
          history={props.history}
        />
      }
    </>
  );
};

export const DriversManualReadPage = withRouter(DriversManualReadPageInner);

const DriversDeliveryCompanyPageInner = (props: HistoryProps) => {
  const query = useQuery<{
    deliveryCompanyId?: string;
    consignorId?: string;
  }>();
  const deliveryCompanyApi = useFetchDeliveryCompanyApi();
  const consignorApi = useFetchConsignorApi();

  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    if (query.deliveryCompanyId && query.consignorId) {
      consignorApi.execute(query.consignorId);
      deliveryCompanyApi.execute(query.deliveryCompanyId);
    }
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(consignorApi.loading || deliveryCompanyApi.loading);
  }, [consignorApi.loading || deliveryCompanyApi.loading]);
  const [open, setOpen] = useState(false);

  const [visible, setVisible] = useState(false);

  return (
    <>
      <DriverIssueModal
        onCancel={() => setOpen(false)}
        open={open}
        history={props.history}
        deliveryCompany={deliveryCompanyApi.response.data}
      />
      {consignorApi.isSuccess() && deliveryCompanyApi.isSuccess() && (
        <DriverManualDetailInnerPage
          consignor={consignorApi.response.data}
          deliveryCompany={deliveryCompanyApi.response.data}
          history={props.history}
          showIssueModal={() => setOpen(true)}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </>
  );
};

export const DriversDeliveryCompanyPage = withRouter(
  DriversDeliveryCompanyPageInner
);

export const DriverManualDetailInnerPage = ({
  consignor,
  deliveryCompany,
  deliveryPartnerCompany,
  manual,
  history,
  readOnly,
  showIssueModal,
  visible,
  setVisible,
  onEdit,
  guest,
  isEdit
}: {
  consignor?: Consignor;
  deliveryCompany?: DeliveryCompany;
  deliveryPartnerCompany?: DeliveryPartnerCompany;
  manual?: Manual;
  readOnly?: boolean;
  showIssueModal?: () => void;
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  onEdit?: () => void;
  guest?: boolean;
  isEdit?: boolean;
} & HistoryProps) => {
  const { token } = theme.useToken();
  const [selectedPointCompany, setSelectedPointCompany] = useState<DeliveryCompany>(manual?.deliveryCompany || {});
  const [selectedPointManual, setSelectedPointManual] = useState<Manual>(manual || {});
  const [key, setKey] = useState<ManualKey>(ManualKey.基本情報);
  const [consignorOpen, setConsignorOpen] = useState(false);
  const [deliveryPartnerCompanyOpen, setDeliveryPartnerCompanyOpen] = useState(false);
  const [mismatchOpen, setMismatchOpen] = useState(false);
  const templateApi = readOnly
    ? useFetchTemplateViewApi()
    : useFetchTemplateApi();
  const deleteManualApi = useDeleteManualApi();
  const globalState = useContext(GlobalStateContext);
  const manualPoints = () => [manual, ...(manual?.manuals || [])];
  const deliveryCompanyPoints = () => [manual?.deliveryCompany, ...(manual?.deliveryCompany?.deliveryCompanies || [])];

  useEffect(() => {
    if (manual?.templateId) {
      templateApi.execute(manual.templateId!);
    }
  }, [manual?.templateId]);

  useEffectSkipFirst(() => {
    if (manual?.manuals && selectedPointManual.id) {
      const targetManual = [...manual.manuals, manual].find(target => target.id === selectedPointManual.id);
      targetManual && setSelectedPointManual(targetManual);
    }
  }, [JSON.stringify(manual)]);

  useEffectSkipFirst(() => {
    globalState.setLoading(templateApi.loading);
  }, [templateApi.loading]);

  useEffectSkipFirst(() => {
    if (selectedPointManual?.templateId) {
      templateApi.execute(selectedPointManual?.templateId);
    }
  }, [selectedPointCompany?.id]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteManualApi.loading);
    if (deleteManualApi.isSuccess()) {
      history.goBack();
    }
  }, [deleteManualApi.loading]);

  const showDrawer = () => {
    setVisible && setVisible(true);
  };

  const onClose = () => {
    setVisible && setVisible(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <Space
      style={{
        background: token.colorBgBase,
        minHeight: "100vh",
        width: "100vw",
      }}
      direction="vertical"
      size={0}
    >
      <DriversHeader
        title="納入カルテ"
        onBack={(readOnly || guest) ? undefined : () => history.goBack()}
        navMenu={
          manual ? (
            <Menu style={{ border: "none", boxShadow: "none" }}>
              <Menu.Item
                style={{
                  backgroundColor: "transparent",
                  fontSize: 15,
                  fontWeight: "normal",
                }}
                onClick={() => {
                  if (selectedPointManual?.id) {
                    const manualUrl = `${process.env.REACT_APP_DRIVER_HOST}drivers/manuals/view/${selectedPointManual.id}`;
                    copyToClipboard(manualUrl);
                    message.success(
                      "クリップボードに納入カルテのリンクをコピーしました。"
                    );
                  }
                }}
              >
                共有
              </Menu.Item>
              <Menu.Item style={{ backgroundColor: "transparent" }}>
                <CustomButton
                  dialogconfirmProps={{
                    title: "この納入カルテを削除しますか？",
                    okText: "削除する",
                    onOk: () => {
                      selectedPointManual?.id && deleteManualApi.execute(selectedPointManual.id);
                    },
                    cancelText: "戻る",
                    okButtonProps: {
                      danger: true,
                      style: { background: "#FFEFEE" },
                    },
                    icon: null,
                    centered: true,
                    closable: false,
                  }}
                  danger
                  style={{
                    color: token.colorError,
                    border: "none",
                    fontSize: 15,
                    padding: 0,
                    fontWeight: "normal",
                  }}
                >
                  カルテを削除する
                </CustomButton>
              </Menu.Item>
            </Menu>
          ) : undefined
        }
      />
      <Space
        direction="vertical"
        size={25}
        style={{
          width: "100%",
          padding: "16px 16px 0px 16px",
          boxSizing: "border-box",
          background: token.colorWhite,
          marginTop: 3,
          paddingTop: 48,
        }}
      >
        <Space size={10} direction="vertical">
          <div
            style={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "22px",
            }}
          >
            {selectedPointManual?.title}
            {guest ? <></> :
              <EditOutlined
                style={{ marginLeft: 5, color: "#1C1E37", opacity: 0.5, cursor: "pointer" }}
                onClick={showDrawer}
              />
            }
          </div>
          <div
            style={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "17px",
              // color: "#6B7280",
            }}
          >
            <Space direction="vertical" size={0}>
              <div>荷主：<Button onClick={() => setConsignorOpen(true)} type="link" style={{ padding: 0, height: "auto" }}>{selectedPointManual?.consignor?.name}</Button></div>
              <Modal open={consignorOpen} onCancel={() => setConsignorOpen(false)} footer={null}
                title={
                  <div style={{ fontSize: 20 }}>
                    荷主情報
                    <Divider style={{ margin: "10px 0 0 0", width: "100%", borderWidth: 2 }} />
                  </div>
                }
              >
                <ConsignorCardView consignor={consignor} />
              </Modal>
              <div>運送会社：<Button onClick={() => setDeliveryPartnerCompanyOpen(true)} type="link" style={{ padding: 0, height: "auto" }}>{selectedPointManual?.deliveryPartnerCompany?.name}</Button></div>
              <Modal open={deliveryPartnerCompanyOpen} onCancel={() => setDeliveryPartnerCompanyOpen(false)} footer={null}
                title={
                  <div style={{ fontSize: 20 }}>
                    運送会社情報
                    <Divider style={{ margin: "10px 0 0 0", width: "100%", borderWidth: 2 }} />
                  </div>
                }
              >
                <DeliveryPartnerCompanyCardView deliveryPartnerCompany={deliveryPartnerCompany} />
              </Modal>
              <Collapse
                className="collapse"
                style={{ fontSize: 13 }}
                items={[{
                  key: 1,
                  label: <span style={{ width: "fit-content" }}>編集</span>,
                  headerClass: "collapse-header",
                  children: <>
                    <div>
                      <div>
                        作成：
                        {
                          globalState.users.find((user) => user.id === manual?.createdBy)?.name ??
                          globalState.users.find((user) => user.id === manual?.updatedBy)?.name
                        }
                      </div>
                      <div>
                        {manual?.createdAt
                          ? dayjs(manual?.createdAt).format("YYYY/M/D")
                          : ""}
                      </div>
                    </div>
                    <div>
                      <div>
                        最終更新：
                        {
                          globalState.users.find((user) => user.id === manual?.updatedBy)
                            ?.name
                        }
                      </div>
                      <div>
                        {manual?.updatedAt
                          ? dayjs(manual?.updatedAt).format("YYYY/M/D")
                          : ""}
                      </div>
                    </div>
                  </>
                }]}
                ghost
              />
            </Space>
          </div>
        </Space>
        <Row style={{ cursor: "pointer" }}>
          {deliveryCompanyPoints().map((company, index, ary) => (
            <Col key={index} flex={1} style={{ textAlign: "center" }}>
              <div
                style={{
                  fontWeight: (company?.id === manual?.deliveryCompany?.id && !selectedPointCompany) || selectedPointCompany?.id === company?.id ? 700 : 500,
                  fontSize: "16px",
                  lineHeight: "20px",
                  padding: "0px 4px 16px 4px",
                  color: (company?.id === manual?.deliveryCompany?.id && !selectedPointCompany) || selectedPointCompany?.id === company?.id ? token.colorPrimary : "#6B7280",
                  borderBottom:
                    (company?.id === manual?.deliveryCompany?.id && !selectedPointCompany) || selectedPointCompany?.id === company?.id ? `3px solid ${token.colorPrimary}` : "none",
                }}
                onClick={() => {
                  setSelectedPointCompany(company || {});
                  const targetManual = manualPoints()?.find(manual =>
                    manual?.deliveryPointCompanyId === company?.id ||
                    manual?.deliveryCompanyId === company?.id
                  );
                  setSelectedPointManual(targetManual || {});
                }}
              >
                {company?.point?.memo || company?.name}
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={8} style={{ textAlign: "center" }}>
            <div
              style={{
                fontWeight: key === ManualKey.基本情報 ? 700 : 500,
                fontSize: "16px",
                lineHeight: "20px",
                padding: "0px 4px 16px 4px",
                color: key === ManualKey.基本情報 ? token.colorPrimary : "#6B7280",
                borderBottom:
                  key === ManualKey.基本情報 ? `3px solid ${token.colorPrimary}` : "none",
              }}
              onClick={() => setKey(ManualKey.基本情報)}
            >
              基本情報
            </div>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <div
              style={{
                fontWeight: key === ManualKey.手順 ? 700 : 500,
                fontSize: "16px",
                lineHeight: "20px",
                color: key === ManualKey.手順 ? token.colorPrimary : "#6B7280",
                borderBottom:
                  key === ManualKey.手順 ? `3px solid ${token.colorPrimary}` : "none",
                padding: "0px 4px 16px 4px",
              }}
              onClick={() => setKey(ManualKey.手順)}
            >
              手順
            </div>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <div
              style={{
                fontWeight: key === ManualKey.構内マップ ? 700 : 500,
                fontSize: "16px",
                lineHeight: "20px",
                color: key === ManualKey.構内マップ ? token.colorPrimary : "#6B7280",
                borderBottom:
                  key === ManualKey.構内マップ ? `3px solid ${token.colorPrimary}` : "none",
                padding: "0px 4px 16px 4px",
              }}
              onClick={() => setKey(ManualKey.構内マップ)}
            >
              構内マップ
            </div>
          </Col>
        </Row>
      </Space>
      <Space
        direction="vertical"
        style={{
          margin: 16,
          width: "calc(100vw - 32px)",
          paddingBottom: guest ? 0 : 58,
        }}
        size={0}
      >
        {/* 基本情報 */}
        <Space
          direction="vertical"
          style={{ display: key === ManualKey.基本情報 ? "flex" : "none" }}
          size={20}
        >
          <Space direction="vertical" style={{ width: "100%", border: 1, borderRadius: 4, fontSize: 18 }}>
            <Skeleton loading={globalState.loading}>
              <DeliveryCompanyCardView
                style={{ padding: "10px 10px 0 10px", width: guest ? "100%" : "calc(100vw - 52px)", background: token.colorWhite }}
                deliveryCompany={deliveryCompany}
              />
              {manual && <Space style={{ fontSize: 14, margin: "15px 0", width: "100%", justifyContent: "end" }}>
                契約との不一致を見る
                <Switch onChange={val => setMismatchOpen(val)} style={{ width: 34 }} />
              </Space>}
              <ManualContractCardView
                style={{ padding: "0 10px 10px 10px", width: guest ? "100%" : "calc(100vw - 52px)", background: token.colorWhite }}
                deliveryCompany={selectedPointCompany}
                manual={selectedPointManual}
                mismatchOpen={mismatchOpen}
              />
              {manual?.file &&
                <div style={{ position: 'relative' }}>
                  <DescriptionBlock
                    labelStyle={{ fontSize: 16 }}
                    label="【ファイル】"
                  >
                    <></>
                  </DescriptionBlock>
                  <iframe
                    style={{ width: "100%", height: 300 }}
                    src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${manual.file}#toolbar=0&navpanes=0`}
                  >
                    このブラウザでは iframe がサポートされていません。
                  </iframe>
                  <ArrowsAltOutlined
                    width={29}
                    height={29}
                    style={{
                      color: "white",
                      background: "rgba(0,0,0,0.7)",
                      padding: 5,
                      borderRadius: "100%",
                      position: 'absolute',
                      bottom: '10px',
                      right: '10px',
                    }}
                    onClick={showModal}
                  />
                  <Modal
                    style={{ top: 0, bottom: 0, right: 0, left: 0, width: '100%', height: '100%' }}
                    open={isModalVisible}
                    footer={
                      <Button style={{ fontWeight: "bold", fontSize: 16, width: "100%" }} onClick={handleCancel}>
                        閉じる
                      </Button>
                    }
                    closable={false}
                    onCancel={handleCancel}
                  >
                    <iframe
                      src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${manual.file}`}
                      style={{ width: '100%', height: 550 }}
                    >
                      このブラウザでは iframe がサポートされていません。
                    </iframe>
                  </Modal>
                </div>
              }
            </Skeleton>
          </Space>
          <Skeleton loading={globalState.loading}>
            <GoogleMapComponent
              mapCenter={{
                lat: selectedPointCompany?.point?.latitude || 0,
                lng: selectedPointCompany?.point?.longitude || 0,
              }}
              markerOptions={[
                {
                  position: {
                    lat: selectedPointCompany?.point?.latitude || 0,
                    lng: selectedPointCompany?.point?.longitude || 0,
                  },
                  visible: true
                }
              ]}
              onClickMarker={(e, marker, data: Point) => {
                const targetPointCompany = deliveryCompanyPoints().find(company =>
                  JSON.stringify([company?.point?.latitude, company?.point?.longitude]) ===
                  JSON.stringify([marker.getPosition()?.lat(), marker.getPosition()?.lng()]));
                const infowindow = new google.maps.InfoWindow({
                  content: targetPointCompany?.point?.memo,
                });
                infowindow.open(marker?.getMap(), marker);
              }}
              style={{ height: 344, margin: "10px 0" }}
            />
          </Skeleton>
          <CustomButton
            style={{ width: "100%" }}
            icon={<CarOutlined />}
            onClick={() =>
              window.open(
                `https://www.google.com/maps/dir/?api=1&destination=${deliveryCompany?.point?.latitude},${deliveryCompany?.point?.longitude}&travelmode=driving&dir_action=navigate`
              )
            }
          >
            ナビを起動する
          </CustomButton>
        </Space>
        {/* 手順 */}
        <Space
          direction="vertical"
          size={24}
          style={{ display: key === ManualKey.手順 ? "flex" : "none", overflowX: "hidden" }}
        >
          <Space
            size={24}
            direction="vertical"
            style={{ width: "100%", boxSizing: "border-box" }}
          >
            <Skeleton loading={globalState.loading}>
              {ManualPreview(selectedPointManual, templateApi.response.data || {})}
            </Skeleton>
          </Space>
        </Space>
        {/* 構内マップ */}
        <Space
          direction="vertical"
          size={24}
          style={{ display: key === ManualKey.構内マップ ? "flex" : "none" }}
        >
          <Space direction="vertical">
            <Space direction="vertical">
              <div style={{ fontSize: 18, fontWeight: "bold" }}>作業</div>
              <Space size={6} wrap>
                <Row gutter={[8, 3]}>
                  {Object.keys(PointType)
                    .filter(
                      (key): key is keyof typeof PointType =>
                        isNaN(Number(key)) &&
                        PointType[key as keyof typeof PointType] !== PointType.削除 &&
                        PointType[key as keyof typeof PointType] !== PointType.地点
                    )
                    .map((label) => (
                      <Col span={12} key={label}>
                        <CustomButton
                          style={{
                            width: "100%",
                            display: "flex",
                            paddingLeft: 25,
                            justifyContent: "flex-start",
                            cursor: "default",
                            boxShadow: "unset",
                            fontWeight: "normal",
                            pointerEvents: "none",
                          }}
                        >
                          <Space>
                            <div style={{ paddingTop: 5 }}>
                              {
                                ManualMarkers.find(
                                  (manualMaker) =>
                                    manualMaker.pointType === PointType[label]
                                )?.componentIcon
                              }
                            </div>
                            {label}
                          </Space>
                        </CustomButton>
                      </Col>
                    ))}
                </Row>
              </Space>
            </Space>
          </Space>
          <Skeleton loading={globalState.loading}>
            <GoogleMapComponent
              mapCenter={{
                lat: selectedPointCompany?.point?.latitude || 0,
                lng: selectedPointCompany?.point?.longitude || 0,
              }}
              markerOptions={[
                {
                  position: {
                    lat: selectedPointCompany?.point?.latitude || 0,
                    lng: selectedPointCompany?.point?.longitude || 0,
                  },
                  visible: true
                },
                ...(selectedPointManual?.points?.map((point) => ({
                  data: point,
                  position: {
                    lat: point?.latitude ?? 0,
                    lng: point?.longitude ?? 0,
                  },
                  icon: ManualMarkers.find(
                    (manualMaker) => manualMaker.pointType === point.pointType
                  )?.icon,
                  visible: true
                })) ?? []),
              ]}
              onClickMarker={(e, marker, data: Point) => {
                const targetPointCompany = deliveryCompanyPoints().find(company =>
                  JSON.stringify([company?.point?.latitude, company?.point?.longitude]) ===
                  JSON.stringify([marker.getPosition()?.lat(), marker.getPosition()?.lng()]));
                const infowindow = new google.maps.InfoWindow({
                  content: targetPointCompany?.name || data.memo,
                });
                infowindow.open(marker?.getMap(), marker);
              }}
            />
          </Skeleton>
        </Space>
        {!readOnly && (
          <div
            style={{
              display: "flex",
              gap: 20,
              justifyContent: "center",
              position: "fixed",
              bottom: 0,
              left: 0,
              padding: 10,
              width: guest ? "100%" : "calc(100vw - 20px)",
              background: "#F9FAFB",
            }}
          >
            {guest && !isEdit ? <></> :
              <CustomButton onClick={showDrawer} style={{ width: "42%" }}>
                {selectedPointManual.id ? "編集する" : "作成する"}
              </CustomButton>
            }
            <CustomButton
              type="primary"
              style={{ width: "42%" }}
              onClick={showIssueModal}
            >
              報告
            </CustomButton>
            <Drawer
              headerStyle={{ background: token.colorWhite, borderBottom: key === ManualKey.手順 ? "none" : "initial" }}
              bodyStyle={{ padding: 0 }}
              title={
                <div
                  style={{
                    display: "flex",
                    fontSize: 16,
                    justifyContent: "space-between",
                  }}
                >
                  <span></span>
                  <span>
                    {selectedPointManual.id ?
                      `${ManualKey[key]}の編集` :
                      '新規納入カルテ'}
                  </span>
                  <CloseOutlined onClick={onClose} />
                </div>
              }
              placement="bottom"
              closable={false}
              onClose={onClose}
              open={visible}
              height="100%"
            >
              {visible &&
                (selectedPointManual.id ?
                  <DriversManualsEditPageInner
                    history={history}
                    _key={key}
                    onEdit={() => {
                      onEdit && onEdit()
                    }}
                    manualId={selectedPointManual.id}
                  /> :
                  <DriversManualsCreatePageInner history={history} deliveryCompanyId={deliveryCompany?.id} manualId={manual?.id} deliveryPointCompanyId={selectedPointCompany.id} />
                )
              }
            </Drawer>
          </div>
        )}
      </Space>
    </Space >
  );
};
