import {
  Progress,
  Space,
  Table,
  TableColumnProps,
  TableColumnType,
  TableProps,
  Upload,
  theme,
} from "antd";
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomPageHeader } from "specifics/page_header";
import { CustomDateField } from "specifics/input";
import { useForm } from "utils/hooks";
import dayjs, { Dayjs } from "dayjs";
import { useCountsDeliveryCompanyApi } from "api/delivery_company";
import {
  CountsApiResponseData,
  CountsApiResponseDataItem,
  PercentagesApiResponseData,
} from "utils/network/api_hooks";
import { CustomAreaChat } from "specifics/recharts/custom_area_chat";
import { useCountsUserApi } from "api/user";
import { useCountsVehicleApi } from "api/vehicle";
import { CustomBarChat } from "specifics/recharts/custom_bar_chat";
import {
  AnalysisDetailsCountsPageTemplate,
  useAnalysisDetailsPage,
} from "./analysis_details_counts_page_template";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";

const AnalysisDetailsVehiclesCountsPage = ({ history }: HistoryProps) => {
  const { datetimes, searchForm } = useAnalysisDetailsPage({ history });

  const countsVehiclesApi = useCountsVehicleApi({
    givenTimes: datetimes,
  });

  useEffect(() => {
    countsVehiclesApi.execute();
  }, [datetimes]);

  const table = useTable<CountsApiResponseDataItem>();

  const data = useMemo(() => {
    return (
      countsVehiclesApi.response.data?.map((item) => ({
        ...item,
        day: dayjs(item.datetime).format("DD日"),
      })) || []
    );
  }, [countsVehiclesApi.loading]);

  const columns: TableProps<CountsApiResponseDataItem>["columns"] = [
    {
      key: "date",
      title: "日付",
      rowScope: "row",
      render: (item: CountsApiResponseDataItem) =>
        dayjs(item.datetime).format("YYYY年MM月DD日"),
    },
    {
      key: "row",
      title: "件数",
      render: (item: CountsApiResponseDataItem) => `${item.count}両`,
    },
  ];

  return (
    <AnalysisDetailsCountsPageTemplate
      searchForm={searchForm}
      title="車両数"
      history={history}
    >
      <ResponsiveContainer width={"100%"} height={300}>
        <CustomBarChat
          valueKey="count"
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          xAxisLabelKey="day"
          getTooltipLabel={(item) =>
            dayjs(item.payload.datetime).format("YYYY年MM月DD日")
          }
          getTooltipBody={(item) => `${item.payload.count}両`}
        />
      </ResponsiveContainer>
      <CustomTable
        table={table}
        columns={columns}
        pagination={false}
        tableKey="analysis_details_vehicles_counts_table"
        dataSource={data}
      />
    </AnalysisDetailsCountsPageTemplate>
  );
};

export default withRouter(AnalysisDetailsVehiclesCountsPage);
