import { Space, theme } from "antd";
import {
  useFetchVehicleApi,
  usePostVehicleApi,
  useEditVehicleApi,
} from "api/vehicle";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  Vehicle,
  VehicleLicenceTypes,
  VehicleTypes,
} from "entities/vehicle";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import {
  CustomInputField,
  CustomInputNumberField,
  CustomSelectField,
  CustomTextAreaField,
  NumberValidation,
  StringValidation,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomContentFooter } from "specifics/footer";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { DescriptionBlock } from "specifics/description";

const FormView = (form: Form<Vehicle>, validationResultForm: Form<Vehicle>) => {
  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "vehicleCode", true);
      StringValidation(f, form, "licenseType", true);
      StringValidation(f, form, "vehicleNo", true);
      StringValidation(f, form, "vehicleType", true);
      NumberValidation(f, form, "weight", true);
      StringValidation(f, form, "userId", true);
      StringValidation(f, form, "maxWeight", true);
    });
  }, [JSON.stringify(form.object)]);
  const globalState = useContext(GlobalStateContext);

  return (
    <Space style={{ width: "100%" }} size={16} direction="vertical">
      <DescriptionBlock label="営業所">{form.object.division}</DescriptionBlock>
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="管理番号"
        attr="vehicleCode"
        style={{ width: 180 }}
        required
      />
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        label="免許区分"
        attr="licenseType"
        style={{ width: 180 }}
        selectItems={VehicleLicenceTypes.map((type, index) => ({
          label: type,
          value: index,
        }))}
        required
      />
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="車両No."
        attr="vehicleNo"
        style={{ width: 180 }}
        required
      />
      <CustomInputNumberField
        form={form}
        validationResultForm={validationResultForm}
        label="車格"
        attr="weight"
        required
        style={{ width: 123 }}
        fieldProps={{ addonAfter: "t", min: 0, precision: 0 }}
      />
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        label="車両種類"
        attr="vehicleType"
        style={{ width: 180 }}
        selectItems={VehicleTypes.map((type, index) => ({
          label: type,
          value: index,
        }))}
        required
      />

      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        label="担当ドライバー"
        attr="userId"
        style={{ width: 180 }}
        selectItems={globalState.users.map((user) => ({
          label: user.name,
          value: user.id,
        }))}
        required
      />
      <CustomInputNumberField
        form={form}
        validationResultForm={validationResultForm}
        label="最大積載量"
        attr="maxWeight"
        style={{ width: 123 }}
        required
        fieldProps={{ addonAfter: "t", min: 0, precision: 0 }}
      />

      <CustomTextAreaField
        form={form}
        validationResultForm={validationResultForm}
        label="備考"
        attr="memo"
      />
    </Space>
  );
};

const _VehiclesCreatePage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);

  const { token } = theme.useToken();
  const form = useForm<Vehicle>({
    companyId: globalState.user.companyId,
    division: "営業所",
  });
  const postApi = usePostVehicleApi();

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      props.history.push("/operations/vehicles");
    }
  }, [postApi.loading]);

  const validationResultForm = useForm<Vehicle>({});
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="車両登録"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/operations/vehicles")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) =>
                    validationResultForm.object[key as keyof Vehicle] ===
                    undefined
                )
              ) {
                postApi.execute(form);
              }
            }}
          >
            登録
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.push("/operations/vehicles")}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

const _VehiclesEditPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const vehicleApi = useFetchVehicleApi();
  const params = useParams<{ id: string }>();
  const form = useForm<Vehicle>({});
  const editApi = useEditVehicleApi();
  const { token } = theme.useToken();
  useEffect(() => {
    vehicleApi.execute(params.id);
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(vehicleApi.loading);
    if (vehicleApi.isSuccess()) {
      form.set({ ...vehicleApi.response.data });
    }
  }, [vehicleApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.history.push(`/operations/vehicles/${vehicleApi.response.data.id}`);
    }
  }, [editApi.loading]);

  const validationResultForm = useForm<Vehicle>({});
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="車両編集"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/operations/vehicles")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) =>
                    validationResultForm.object[key as keyof Vehicle] ===
                    undefined
                )
              ) {
                editApi.execute(form);
              }
            }}
          >
            保存
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.push("/operations/vehicles")}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const VehiclesEditPage = withRouter(_VehiclesEditPage);

export const VehiclesCreatePage = withRouter(_VehiclesCreatePage);
