import { Alert, Space, message, theme } from "antd";
import { useDeleteTemplateApi, useFetchTemplateApi } from "api/template";
import { BaseQuestionFormView } from "components/shared/manual_form";
import { GlobalStateContext } from "contexts/global_state_context";
import { Manual } from "entities/manual";

import { Template } from "entities/template";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { sortQuestions } from "utils/util";

const SuperAdminTemplatePage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);

  const { token } = theme.useToken();
  const templateApi = useFetchTemplateApi();
  const params = useParams<{ id: string }>();
  useEffect(() => {
    templateApi.execute(params.id);
  }, []);
  const deleteApi = useDeleteTemplateApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      props.history.goBack();
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(templateApi.loading);
  }, [templateApi.loading]);

  const template = (): Template | undefined => {
    return templateApi.response.data;
  };

  const manualForm = useForm<Manual>({}); // preview用
  const manualValidationResultForm = useForm<Manual>({}); // preview用

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
        width: "100%",
      }}
      title="テンプレート詳細"
      onBack={() => props.history.push("/super-admin/templates")}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent
        style={{ flexDirection: "column", display: "flex", gap: 16 }}
      >
        <Space
          style={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <Space direction="vertical" size={24} style={{ width: "100%" }}>
            <DescriptionBlock style={{ width: "100%" }} label="名称">
              {template()?.templateName}
            </DescriptionBlock>

            {template()?.steps?.map((step, stepIndex) => {
              return (
                <Space
                  direction="vertical"
                  key={step.id!}
                  style={{ width: "100%" }}
                >
                  <p>STEP:{stepIndex + 1}</p>
                  {sortQuestions(step.questions || [])
                    .map((question, questionIndex) => (
                      <BaseQuestionFormView
                        key={question.id!}
                        onChange={() =>
                          message.error("プレビュー用なので選択肢は選べません")
                        }
                        form={manualForm}
                        validationResultForm={manualValidationResultForm}
                        stepIndex={stepIndex}
                        question={question}
                        questionIndex={questionIndex}
                        isPreview
                      />
                    ))}
                </Space>
              );
            })}
          </Space>
          <Space size={24} style={{ verticalAlign: "top", height: "100%" }}>
            <CustomButton
              style={{
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() =>
                props.history.push(
                  `/super-admin/templates/${template()?.id}/edit`
                )
              }
            >
              編集
            </CustomButton>
            <CustomButton
              danger
              type="text"
              style={{
                fontSize: 14,
                fontWeight: 400,
                padding: 0,
              }}
              confirm
              popconfirmProps={{
                title: "削除します。よろしいですか？",
              }}
              onClick={() => {
                deleteApi.execute(params.id);
              }}
            >
              削除
            </CustomButton>
          </Space>
        </Space>
        {!template()?.steps?.length && (
          <Alert
            message="STEPは登録されていません"
            type="error"
            style={{ width: "100%" }}
          />
        )}
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(SuperAdminTemplatePage);
