import { ReactNode, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { CookieManager } from "../utils/cookie_manager";
import { AppRouteHelper } from "../routes/app";
import { useFetchAllUsersApi } from "api/user";
import { GlobalStateContext } from "contexts/global_state_context";
import { useEffectSkipFirst, useQuery } from "utils/hooks";
import { useFetchIssueCountApi } from "api/issue";
import { useFetchSettingApi } from "api/setting";
import { useFetchNotificationsCountApi } from "api/notification";

type UserAuthProps = {
  children: ReactNode;
};

const UserAuth = ({ children }: UserAuthProps): JSX.Element => {
  const isAuthenticated = !!CookieManager.getUserToken()
  const isTokenAccessed = isTokenAccess();
  const globalState = useContext(GlobalStateContext);
  const issueCountApi = useFetchIssueCountApi();
  const notificationsCountApi = useFetchNotificationsCountApi();
  const usersApi = useFetchAllUsersApi();
  const settingApi = useFetchSettingApi();

  useEffect(() => {
    if (isAuthenticated) {
      if (process.env.REACT_APP_TYPE === "ADMIN") {
        issueCountApi.execute();
        settingApi.execute();
        notificationsCountApi.execute();
      }
      process.env.REACT_APP_TYPE !== "SUPER_ADMIN" && issueCountApi.execute();
      usersApi.execute();
    }
  }, [isAuthenticated]);

  useEffectSkipFirst(() => {
    if (usersApi.isSuccess()) {
      globalState.setUsers(usersApi.response.data ?? []);
      globalState.setUser(
        usersApi.response.data?.find(
          (u) => u.id === CookieManager.getUserId()
        ) ?? {}
      );
    }
  }, [usersApi.loading]);

  useEffectSkipFirst(() => {
    if (issueCountApi.isSuccess()) {
      globalState.setIssueCount(issueCountApi.response.data || 0);
    }
  }, [issueCountApi.loading]);

  useEffectSkipFirst(() => {
    if (notificationsCountApi.isSuccess()) {
      globalState.setNotificationsCount(notificationsCountApi.response.data || 0);
    }
  }, [notificationsCountApi.loading]);

  useEffectSkipFirst(() => {
    if (settingApi.isSuccess()) {
      globalState.setSetting(settingApi.response.data);
    }
  }, [settingApi.loading]);

  if (process.env.REACT_APP_ENV_MODE === "LOCAL") {
    return <>{children}</>;
  } else {
    return (globalState.users.length > 0 && isAuthenticated) || isTokenAccessed ? (
      <>{children}</>
    ) : isAuthenticated ? (
      <div></div>
    ) : (
      <Redirect to={{ pathname: AppRouteHelper.login() }} />
    );
  }
};

const isTokenAccess = (): boolean => {
  type Token = {
    token: string;
  };
  const query = useQuery<Token>();
  // 一時トークンを含んだアクセスかどうか判定
  const isToken = !!query.token;

  return isToken;
};

export default UserAuth;
