import { BaseSearchForm, ID } from ".";
import { ManualKey } from "./manual";
import { Point } from "./point";
import { Template } from "./template";

export enum CompanyManualType {
  全て = 1,
  ガイドラインのみ = 2,
  マニュアルのみ = 3
}

export const CompanyManualDisplay = (type?: CompanyManualType, manualKey?: ManualKey, isEdit?: boolean) => {
  if (isEdit) {
    // 編集時はいずれも表示
    return true;
  }
  switch (type) {
    case CompanyManualType.全て:
      return true;
    case CompanyManualType.ガイドラインのみ:
      return manualKey === ManualKey.基本情報;
    case CompanyManualType.マニュアルのみ:
      return manualKey === ManualKey.手順 || manualKey === ManualKey.構内マップ;
    default:
      return false;
  }
}

export type Company = {
  id?: ID;
  type?: number;
  manualType?: CompanyManualType;
  name?: string;
  kana?: string;
  zipcode?: string;
  address?: string;
  tel?: string;
  repName?: string;
  repTel?: string;
  memo?: string;

  defaultTemplateName?: string;
  templates?: Template[];
  defaultTemplateId?: ID;
  selectableTemplateIds?: ID[];

  pointId?: string;
  point?: Point;
  createdAt?: string;
  updatedAt?: string;
};

export type CompanyForm = Company;

export type CompanySearchForm = BaseSearchForm & Company;
