import { Space, notification, theme } from "antd";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { DriversHeader } from "specifics/drivers_header";
import { useMedia } from "use-media";
import { MdLockOutline } from "react-icons/md";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { ForgetPasswordForm, useSendSmsAPi } from "api/authorization";
import { CustomInputField, TelValidation } from "specifics/input";
import { CustomButton } from "specifics/button";
import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect } from "react";
import { hankaku2Zenkaku } from "utils/util";

const ForgetPasswordPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const isMobile = useMedia("(max-width: 519px)");
  const form = useForm<ForgetPasswordForm>({ tel: "" });
  const sendSmsApi = useSendSmsAPi();
  const validationResultForm = useForm<ForgetPasswordForm>({ tel: "" });

  useEffectSkipFirst(() => {
    globalState.setLoading(sendSmsApi.loading);
    if (sendSmsApi.isSuccess()) {
      notification.success({
        message:
          "入力した電話番号宛に、パスワード再設定用のリンクを送信しました。",
      });
    }
  }, [sendSmsApi.loading]);

  useEffect(() => {
    validationResultForm.update((f) => {
      TelValidation(f, form, "tel", true);
    });
  }, [JSON.stringify(form.object)]);

  const globalState = useContext(GlobalStateContext);

  return (
    <Space
      direction="vertical"
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        alignItems: isMobile ? "unset" : "center",
        justifyContent: isMobile ? "flex-start" : "center",
      }}
    >
      {isMobile && (
        <DriversHeader
          title="パスワードを忘れた場合"
          onBack={() => props.history.push("/login")}
        />
      )}
      <Space
        style={{
          backgroundColor: token.colorWhite,
          width: isMobile ? globalState.dimension.width : 448,
          padding: isMobile ? "0px 0px" : "32px 40px 32px 40px",
          zIndex: 1,
          boxShadow: isMobile ? "none" : "0 0.5mm 0.5mm rgba(0, 0, 0, 0.3)",
          borderRadius: 6,
          paddingTop: isMobile ? 32 : 0,
        }}
        direction="vertical"
        size={24}
      >
        <Space
          size={32}
          direction="vertical"
          style={{ margin: "0px 16px 0px 16px" }}
        >
          <Space
            style={{ marginTop: 8, textAlign: "center", width: "100%" }}
            size={24}
            direction="vertical"
          >
            <MdLockOutline
              style={{ height: 64, width: 64, color: "#9CA3AF" }}
            />
            <Space direction="vertical" size={13}>
              <div style={{ fontWeight: 700, fontSize: 16, color: "#000" }}>
                パスワードをお忘れですか？
              </div>
              <div
                style={{
                  fontSize: 16,
                  color: "#6B7280",
                  padding: "0px 8px 0px 8px",
                }}
              >
                アカウントの携帯電話番号をご入力ください。パスワード再設定用のメールをお送りします。
              </div>
            </Space>
          </Space>
          <CustomInputField
            required
            validationResultForm={validationResultForm}
            form={form}
            attr="tel"
            label="携帯電話番号"
            onChange={(e) => {
              const value = hankaku2Zenkaku(e.target.value);
              if (value.match(/^\d{0,13}$/)) {
                form.updateObject("tel", value.slice(0, 13));
              } else {
                console.log("invalid");
              }
            }}
          />
          <Space direction="vertical" size={16} style={{ width: "100%" }}>
            <CustomButton
              style={{
                width: "100%",
                height: !isMobile ? 42 : 50,
                paddingTop: !isMobile ? "auto" : 9,
                paddingBottom: !isMobile ? "auto" : 9,
              }}
              onClick={() => {
                form.setValidate(true);
                if (
                  Object.keys(validationResultForm.object).every(
                    (key) =>
                      validationResultForm.object[
                        key as keyof ForgetPasswordForm
                      ] === undefined
                  )
                ) {
                  sendSmsApi.execute(form.object.tel);
                }
              }}
            >
              パスワード再設定URLを送信
            </CustomButton>
            {!isMobile && (
              <CustomButton
                style={{
                  width: "100%",
                  height: !isMobile ? 42 : 50,
                  paddingTop: !isMobile ? "auto" : 9,
                  paddingBottom: !isMobile ? "auto" : 9,
                }}
                onClick={() => props.history.push("/login")}
              >
                ログイン画面へ戻る
              </CustomButton>
            )}
          </Space>
        </Space>
      </Space>
    </Space>
  );
};

export default withRouter(ForgetPasswordPage);
