import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  FormItemProps,
  Input,
  InputNumber,
  InputNumberProps,
  InputProps,
  InputRef,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
  TimePickerProps,
  TimeRangePickerProps,
  TreeSelect,
  TreeSelectProps,
  Upload,
  UploadProps,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { RangePickerProps } from "antd/lib/date-picker";
import { Rule } from "antd/lib/form";
import { Option } from "antd/lib/mentions";
import { OptionProps, SelectProps } from "antd/lib/select";
import { LiteralUnion } from "antd/lib/_util/type";
import React, { ReactNode, useRef, useState } from "react";
import useMedia from "use-media";
import { Form as MyForm, FormAttrType, useEffectSkipFirst } from "utils/hooks";
import TextArea, { TextAreaProps } from "antd/lib/input/TextArea";
import { CSSProperties } from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { DataNode } from "antd/lib/tree";
import { valueType } from "antd/lib/statistic/utils";
import { PasswordProps } from "antd/lib/input";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ja";
import locale from "antd/es/date-picker/locale/ja_JP";
import { SwitchProps } from "antd/lib";
import { makeTextHighlight, removeHighlightRegex } from "utils/util";
declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;

// import { Form as MyForm, FormAttrType, useEffectSkipFirst } from "../../utils";

export type ValidationResult = {
  message: string;
  status: "error" | "warning";
};

export type InputFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  placeholder?: string;
  onChange?: (e: any) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: (InputProps & React.RefAttributes<InputRef>) | PasswordProps;
  required?: boolean;
  type?: InputProps["type"];
  min?: number;
  max?: number;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  validationResultForm?: MyForm<any>;
  onlyHankaku?: boolean;
  isHighlight?: boolean;
  validation?: (e: React.ChangeEvent<HTMLInputElement>) => boolean;
  children?: ReactNode;
};

export const InputField = <T extends any>(props: InputFieldProps<T>) => {
  const value = props.form.getValue(props.attr) as string;
  const ref = useRef<InputRef>(null);

  useEffectSkipFirst(() => {
    const element = ref.current;
    if (!element || !element.input) {
      return;
    }
    const formValue = props.form.getValue(props.attr);
    if (element.input.value !== formValue) {
      element.input.value = (formValue ?? "") as string;
    }
  }, [props.form.getValue(props.attr)]);

  const handleTextHighlight = () => {
    if (!props.isHighlight) {
      return;
    }
    props.form.updateObject(props.attr, makeTextHighlight(value));
  };

  useEffectSkipFirst(() => {
    if (!props.isHighlight) {
      props.form.updateObject(props.attr, removeHighlightRegex(value));
    }
  }, [props.isHighlight]);

  const rules = [] as Rule[];
  if (props.required !== undefined)
    rules.push({
      required: props.required,
      message: `${props.label}は必須項目です。`,
    });

  const Field = props.type === "password" ? Input.Password : Input;
  return (
    <>
      <Form.Item
        label={
          props.label && (
            <div style={{ display: "flex" }}>
              <div>{props.required && <RequiredSign />}</div>
              <div>{props.label}</div>
            </div>
          )
        }
        rules={rules}
        validateStatus={
          props.form.validate &&
          !!props.validationResultForm?.getValue(props.attr)
            ? "error"
            : undefined
        }
        help={
          props.form.validate &&
          (props.validationResultForm?.getValue(props.attr) as string)
        }
        {...props.itemProps}
      >
        {(() => {
          return (
            <>
              <Field
                style={props.style}
                ref={ref}
                disabled={!!props.disabled}
                value={
                  (value ?? "") as
                    | string
                    | ReadonlyArray<string>
                    | number
                    | undefined
                }
                defaultValue={
                  props.form.getValue(props.attr) as
                    | string
                    | ReadonlyArray<string>
                    | number
                    | undefined
                }
                placeholder={props.placeholder}
                onMouseUp={handleTextHighlight}
                onChange={(e) => {
                  if (props.onChange) {
                    props.onChange(e);
                  } else {
                    if (
                      typeof e.target.value === "string" &&
                      props.onlyHankaku
                    ) {
                      if (!e.target.value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/))
                        return;
                    }
                    let value: number | string = e.target.value;
                    if (props.type === "number") {
                      if (String(e.target.value).match(/^-?\d+$/)) {
                        value = parseInt(e.target.value);
                      } else if (
                        String(e.target.value).match(/^-?\d+\.?\d*$/)
                      ) {
                        value = parseFloat(e.target.value);
                      }
                    }
                    if (props.validation && !props.validation(e)) {
                      return;
                    }
                    props.form.updateObject(props.attr, value);
                  }
                }}
                type={props.type}
                addonAfter={props.addonAfter}
                addonBefore={props.addonBefore}
                {...props.fieldProps}
              />
            </>
          );
        })()}
        {props.children}
      </Form.Item>
    </>
  );
};

export type InputNumberFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  onChange?: (e: any) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: InputNumberProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  validation?: (e: React.ChangeEvent<HTMLInputElement>) => boolean;
  children?: ReactNode;
};

export const InputNumberField = <T extends any>(
  props: InputNumberFieldProps<T>
) => {
  const value = props.form.getValue(props.attr);
  const ref = useRef<HTMLInputElement>(null);

  useEffectSkipFirst(() => {
    const element = ref.current;
    if (!element || !element?.value) {
      return;
    }
    const formValue = props.form.getValue(props.attr);
    if (element?.value !== formValue) {
      element.value = (formValue ?? "") as string;
    }
  }, [props.form.getValue(props.attr)]);

  const rules = [] as Rule[];
  if (props.required !== undefined)
    rules.push({
      required: props.required,
      message: `${props.label}は必須項目です。`,
    });
  return (
    <>
      <Form.Item
        label={
          props.label && (
            <div style={{ display: "flex" }}>
              <div>{props.required && <RequiredSign />}</div>
              <div>{props.label}</div>
            </div>
          )
        }
        rules={rules}
        validateStatus={
          props.form.validate &&
          !!props.validationResultForm?.getValue(props.attr)
            ? "error"
            : undefined
        }
        help={
          props.form.validate &&
          (props.validationResultForm?.getValue(props.attr) as string)
        }
        {...props.itemProps}
      >
        {(() => {
          return (
            <InputNumber
              style={props.style}
              ref={ref}
              disabled={!!props.disabled}
              value={value as valueType | null}
              defaultValue={
                props.form.getValue(props.attr) as valueType | undefined
              }
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                } else {
                  props.form.updateObject(props.attr, e);
                }
              }}
              {...props.fieldProps}
            />
          );
        })()}
        {props.children}
      </Form.Item>
    </>
  );
};

export type TextAreaFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  onChange?: (e: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: TextAreaProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  validation?: (e: React.ChangeEvent<HTMLInputElement>) => boolean;
  children?: ReactNode;
};

export const TextAreaField = <T extends any>(props: TextAreaFieldProps<T>) => {
  const value = props.form.getValue(props.attr);
  const ref = useRef<InputRef>(null);

  useEffectSkipFirst(() => {
    const element = ref.current;
    if (!element || !element.input) {
      return;
    }
    const formValue = props.form.getValue(props.attr);
    if (element.input.value !== formValue) {
      element.input.value = (formValue ?? "") as string;
    }
  }, [props.form.getValue(props.attr)]);

  const rules = [] as Rule[];
  if (props.required !== undefined)
    rules.push({
      required: props.required,
      message: `${props.label}は必須項目です。`,
    });
  return (
    <>
      <Form.Item
        label={
          props.label && (
            <div style={{ display: "flex" }}>
              <div>{props.required && <RequiredSign />}</div>
              <div>{props.label}</div>
            </div>
          )
        }
        rules={rules}
        validateStatus={
          props.form.validate &&
          !!props.validationResultForm?.getValue(props.attr)
            ? "error"
            : undefined
        }
        help={
          props.form.validate &&
          (props.validationResultForm?.getValue(props.attr) as string)
        }
        {...props.itemProps}
      >
        <TextArea
          style={props.style}
          ref={ref}
          disabled={!!props.disabled}
          value={
            (value ?? "") as string | ReadonlyArray<string> | number | undefined
          }
          defaultValue={
            props.form.getValue(props.attr) as
              | string
              | ReadonlyArray<string>
              | number
              | undefined
          }
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onChange={(e) => {
            props.form.updateObject(props.attr, e.target.value);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          {...props.fieldProps}
        />
        {props.children}
      </Form.Item>
    </>
  );
};

export type SelectItem = {
  label: ReactNode;
  value: any;
  style?: CSSProperties;
};

export type SelectFieldProps<T> = {
  selectItems: SelectItem[];
  form: MyForm<T>;
  attr: FormAttrType<T>;
  mode?: "multiple" | "tags" | "multipleSelect";
  label?: string;
  labelStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: SelectProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  includeBlank?: boolean;
};

export const SelectField = <T extends any>(props: SelectFieldProps<T>) => {
  return (
    <>
      <Form.Item
        style={{ width: "100%" }}
        label={
          props.label && (
            <div style={{ display: "flex", overflow: "wrap" }}>
              {props.required && <RequiredSign />}
              {props.label}
            </div>
          )
        }
        validateStatus={
          props.form.validate &&
          !!props.validationResultForm?.getValue(props.attr)
            ? "error"
            : undefined
        }
        help={
          props.form.validate &&
          (props.validationResultForm?.getValue(props.attr) as string)
        }
        {...props.itemProps}
      >
        <Select
          style={props.style}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange(e);
            } else {
              // 一般的な処理
              props.form.updateObject(props.attr, e);
            }
          }}
          mode={props.mode === "multipleSelect" ? "multiple" : props.mode}
          value={props.form.getValue(props.attr)}
          virtual={false}
          {...props.fieldProps}
        >
          {props.includeBlank && (
            <Option key={`select-field-blank-option`} value={undefined}>
              未選択
            </Option>
          )}
          {props.selectItems.map((item, i) => (
            <Option
              key={`${i}`}
              value={item.value}
              style={{ width: "100%", ...item.style }}
            >
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export type TreeSelectFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  labelStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: TreeSelectProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  includeBlank?: boolean;
  treeData: any[];
};

export const TreeSelectField = <T extends any>(
  props: TreeSelectFieldProps<T>
) => {
  return (
    <>
      <Form.Item
        style={{ width: "100%" }}
        label={
          props.label && (
            <div style={{ display: "flex", overflow: "wrap" }}>
              {props.required && <RequiredSign />}
              {props.label}
            </div>
          )
        }
        validateStatus={
          props.form.validate &&
          !!props.validationResultForm?.getValue(props.attr)
            ? "error"
            : undefined
        }
        help={
          props.form.validate &&
          (props.validationResultForm?.getValue(props.attr) as string)
        }
        {...props.itemProps}
      >
        <TreeSelect
          treeData={props.treeData}
          style={props.style}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange(e);
            } else {
              // 一般的な処理
              props.form.updateObject(props.attr, e);
            }
          }}
          value={props.form.getValue(props.attr)}
          {...props.fieldProps}
        />
      </Form.Item>
    </>
  );
};

export type TimeFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: TimePickerProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  includeBlank?: boolean;
};

export const TimeField = <T extends any>(props: TimeFieldProps<T>) => {
  return (
    <Form.Item
      label={
        props.label && (
          <div style={{ display: "flex", overflow: "wrap" }}>
            {props.required && <RequiredSign />}
            {props.label}
          </div>
        )
      }
      validateStatus={
        props.form.validate &&
        !!props.validationResultForm?.getValue(props.attr)
          ? "error"
          : undefined
      }
      help={
        props.form.validate &&
        (props.validationResultForm?.getValue(props.attr) as string)
      }
      {...props.itemProps}
    >
      <TimePicker
        style={{ width: "100%" }}
        value={dayjs(
          props.form.getValue(props.attr) as string,
          props.fieldProps?.format as string
        )}
        onChange={(value: Dayjs | null, dataString: string) => {
          if (props.onChange) {
            props.onChange(dataString);
          } else {
            // 一般的な処理
            props.form.updateObject(props.attr, dataString);
          }
        }}
        {...props.fieldProps}
      />
    </Form.Item>
  );
};

export type TimeRangeFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: TimeRangePickerProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  includeBlank?: boolean;
};

export const TimeRangeField = <T extends any>(
  props: TimeRangeFieldProps<T>
) => {
  return (
    <Form.Item
      label={
        props.label && (
          <div style={{ display: "flex", overflow: "wrap" }}>
            {props.required && <RequiredSign />}
            {props.label}
          </div>
        )
      }
      validateStatus={
        props.form.validate &&
        !!props.validationResultForm?.getValue(props.attr)
          ? "error"
          : undefined
      }
      help={
        props.form.validate &&
        (props.validationResultForm?.getValue(props.attr) as string)
      }
      {...props.itemProps}
    >
      <TimePicker.RangePicker
        style={{ width: "100%" }}
        // @ts-ignore
        onChange={(e: any) => {
          if (props.onChange) {
            props.onChange(e);
          } else {
            // 一般的な処理
            props.form.updateObject(props.attr, e);
          }
        }}
        {...props.fieldProps}
      />
    </Form.Item>
  );
};

export type DateFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  mode?: "multiple" | "tags";
  label?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  onChange?: DatePickerProps["onChange"];
  itemProps?: FormItemProps<any>;
  fieldProps?: DatePickerProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  includeBlank?: boolean;
};

export const DateField = <T extends any>(props: DateFieldProps<T>) => {
  return (
    <Form.Item
      label={
        props.label && (
          <div style={{ display: "flex", overflow: "wrap" }}>
            {props.required && <RequiredSign />}
            {props.label}
          </div>
        )
      }
      validateStatus={
        props.form.validate &&
        !!props.validationResultForm?.getValue(props.attr)
          ? "error"
          : undefined
      }
      help={
        props.form.validate &&
        (props.validationResultForm?.getValue(props.attr) as string)
      }
      {...props.itemProps}
    >
      <DatePicker
        style={{ width: "100%" }}
        value={
          props.form.getValue(props.attr)
            ? dayjs(
                props.form.getValue(props.attr) as string,
                props.fieldProps?.format as string
              )
            : null
        }
        onChange={(value: Dayjs | null, dataString: string) => {
          if (props.onChange) {
            props.onChange(value, dataString);
          } else {
            // 一般的な処理
            props.form.updateObject(props.attr, dataString);
          }
        }}
        allowClear={
          props.fieldProps?.allowClear !== undefined
            ? props.fieldProps.allowClear
            : false
        }
        locale={locale}
        {...props.fieldProps}
      />
    </Form.Item>
  );
};

export type RangeFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: RangePickerProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  includeBlank?: boolean;
};

const { RangePicker } = DatePicker;
export const RangeField = <T extends any>(props: RangeFieldProps<T>) => {
  return (
    <Form.Item
      label={
        props.label && (
          <div style={{ display: "flex", overflow: "wrap" }}>
            {props.required && <RequiredSign />}
            {props.label}
          </div>
        )
      }
      validateStatus={
        props.form.validate &&
        !!props.validationResultForm?.getValue(props.attr)
          ? "error"
          : undefined
      }
      help={
        props.form.validate &&
        (props.validationResultForm?.getValue(props.attr) as string)
      }
      {...props.itemProps}
    >
      {/* @ts-ignore */}
      <RangePicker
        ranges={{
          Today: [dayjs(), dayjs()],
          "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
        }}
        style={{ width: "100%" }}
        onChange={(
          values: RangeValue<Dayjs> | null,
          formatString: [string, string]
        ) => {
          if (props.onChange) {
            props.onChange(formatString);
          } else {
            // 一般的な処理
            props.form.updateObject(props.attr, formatString);
          }
        }}
        {...props.fieldProps}
      />
    </Form.Item>
  );
};

type CheckboxFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: OptionProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  colSpan?: number;
  children?: ReactNode;
};

export const CheckboxField = <T extends any>(props: CheckboxFieldProps<T>) => {
  const isMobile = useMedia({ maxWidth: "575px" });
  return (
    <>
      <Form.Item
        label={
          props.label && (
            <div style={{ display: "flex" }}>
              <div>{props.required && <RequiredSign />}</div>
              <div>{props.label}</div>
            </div>
          )
        }
        validateStatus={
          props.form.validate &&
          !!props.validationResultForm?.getValue(props.attr)
            ? "error"
            : undefined
        }
        help={
          props.form.validate &&
          (props.validationResultForm?.getValue(props.attr) as string)
        }
        style={{ marginBottom: 0 }}
        {...props.itemProps}
      >
        <Checkbox
          {...props.fieldProps}
          style={props.style}
          disabled={props.disabled}
          checked={props.form.getValue(props.attr) as boolean}
          onChange={(e: CheckboxChangeEvent) => {
            if (props.onChange) {
              props.onChange(e);
            } else {
              // 一般的な処理
              props.form.updateObject(props.attr, e.target.checked);
            }
          }}
        >
          {props.children}
        </Checkbox>
      </Form.Item>
    </>
  );
};

export type CheckboxGroupFieldProps<T> = {
  selectItems?: SelectItem[];
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: ReactNode;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: CheckboxValueType[]) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: OptionProps;
  required?: boolean;
  isManual?: boolean;
  validationResultForm?: MyForm<any>;
  column?: number;
  children?: ReactNode;
};

export const CheckboxGroupField = <T extends any>(
  props: CheckboxGroupFieldProps<T>
) => {
  const isMobile = useMedia({ maxWidth: "575px" });
  return (
    <>
      <Form.Item
        label={
          props.label && (
            <div style={{ display: "flex" }}>
              <div>{props.required && <RequiredSign />}</div>
              <div>{props.label}</div>
            </div>
          )
        }
        validateStatus={
          props.form.validate &&
          !!props.validationResultForm?.getValue(props.attr)
            ? "error"
            : undefined
        }
        help={
          props.form.validate &&
          (props.validationResultForm?.getValue(props.attr) as string)
        }
        style={{ marginBottom: 0 }}
        {...props.itemProps}
      >
        <Checkbox.Group
          style={{
            ...props.style,
          }}
          disabled={props.disabled}
          onChange={(e: CheckboxValueType[]) => {
            if (props.onChange) {
              props.onChange(e);
            } else {
              props.form.updateObject(props.attr, e);
            }
          }}
          value={props.form.getValue(props.attr) as string[]}
          {...props.fieldProps}
        >
          <Row
            style={props.isManual ? { width: "100%" } : {}}
            gutter={
              props.isManual ? [props.column ?? 0 > 1 ? 8 : 0, 12] : [0, 0]
            }
          >
            {props.selectItems?.map((item, i) => (
              <Col
                key={`col ${i}`}
                span={props.column ? 24 / props.column : isMobile ? 24 : 12}
              >
                <Checkbox
                  style={
                    props.isManual
                      ? {
                          width: "100%",
                          lineHeight: "43px",
                          boxSizing: "border-box",
                          padding: "0 10px",
                          border: "1px solid #d9d9d9",
                          borderRadius: "5px",
                          background: "white",
                        }
                      : { lineHeight: "32px" }
                  }
                  key={`${item.value}-${i}`}
                  value={item.value}
                >
                  {item.label}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
        {props.children && props.children}
      </Form.Item>
    </>
  );
};

export type SelectRadioFieldProps<T> = {
  selectItems: SelectItem[];
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: RadioChangeEvent) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: OptionProps;
  required?: boolean;
  isManual?: boolean;
  column?: number;
  validationResultForm?: MyForm<any>;
  direction?: "horizontal" | "vertical";
  children?: ReactNode;
};

export const SelectRadioField = <T extends any>(
  props: SelectRadioFieldProps<T>
) => {
  const renderRadioButtons = () => {
    return (
      <Radio.Group
        {...props.fieldProps}
        style={{ ...props.style }}
        disabled={props.disabled}
        onChange={(e: RadioChangeEvent) => {
          if (props.onChange) {
            props.onChange(e);
          } else {
            // 一般的な処理
            props.form.updateObject(props.attr, e.target.value);
          }
        }}
        value={props.form.getValue(props.attr)}
      >
        {props.isManual ? (
          // カルテの選択肢
          <Row
            style={{ width: "100%" }}
            gutter={
              props.isManual ? [props.column ?? 0 > 1 ? 8 : 0, 12] : [0, 0]
            }
          >
            {props.selectItems.map((item, i) => (
              <Col key={`${i}`} span={props.column ? 24 / props.column : 24}>
                <Radio
                  value={item.value}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    padding: 10,
                    border: "1px solid #d9d9d9",
                    borderRadius: "5px",
                    background: "white",
                    marginRight: -8,
                  }}
                >
                  {item.label}
                </Radio>
              </Col>
            ))}
          </Row>
        ) : (
          // それ以外
          <Space direction={props.direction}>
            {props.selectItems.map((item, i) => (
              <Radio key={`${i}`} value={item.value}>
                {item.label}
              </Radio>
            ))}
          </Space>
        )}
      </Radio.Group>
    );
  };

  return (
    <>
      <Form.Item
        style={{ marginBottom: 0 }}
        label={
          props.label && (
            <div style={{ display: "flex" }}>
              {props.required && <RequiredSign />}
              {props.label}
            </div>
          )
        }
        validateStatus={
          props.form.validate &&
          !!props.validationResultForm?.getValue(props.attr)
            ? "error"
            : undefined
        }
        help={
          props.form.validate &&
          (props.validationResultForm?.getValue(props.attr) as string)
        }
        {...props.itemProps}
      >
        {renderRadioButtons()}
        {props.children}
      </Form.Item>
    </>
  );
};

export type FileFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onChange?: (e: UploadChangeParam<UploadFile<any>>) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: UploadProps;
  required?: boolean;
  validationResultForm?: MyForm<any>;
  direction?: "horizontal" | "vertical";
  children?: ReactNode;
  accept?: string[];

  text?: string;
  icon?: ReactNode;
  uploadButton?: ReactNode;
};

export const FileField = <T extends any>(props: FileFieldProps<T>) => {
  return (
    <>
      <Form.Item
        style={{ marginBottom: 0 }}
        label={
          props.label && (
            <div style={{ display: "flex" }}>
              {props.required && <RequiredSign />}
              {props.label}
            </div>
          )
        }
        validateStatus={
          props.form.validate &&
          !!props.validationResultForm?.getValue(props.attr)
            ? "error"
            : undefined
        }
        help={
          props.form.validate &&
          (props.validationResultForm?.getValue(props.attr) as string)
        }
        {...props.itemProps}
      >
        {props.children}
        <Upload
          className="always-show-remove-icon"
          onChange={(e: any) => {
            if (props.onChange) {
              props.onChange(e);
            } else {
              props.form.updateObject(props.attr, e.file);
            }
          }}
          {...props.fieldProps}
        >
          {props.uploadButton ?? (
            <Button
              disabled={props.fieldProps?.disabled || props.disabled}
              icon={props.icon ?? <UploadOutlined />}
            >
              {props.text ?? "Upload"}
            </Button>
          )}
        </Upload>
      </Form.Item>
    </>
  );
};
export type BooleanSwitchFieldProps<T> = {
  form: MyForm<T>;
  attr: FormAttrType<T>;
  label?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: boolean) => void;
  itemProps?: FormItemProps<any>;
  fieldProps?: SwitchProps;
  checkedLable?: string;
  unCheckedLable?: string;
  required?: boolean;
  validationResultForm?: MyForm<any>;
};

export const BooleanSwitchField = <T extends any>(
  props: BooleanSwitchFieldProps<T>
) => {
  return (
    <Form.Item
      name="form.object.isMenstruation"
      style={{ marginBottom: 0 }}
      label={
        props.label && (
          <div style={{ display: "flex" }}>
            {props.required && <RequiredSign />}
            {props.label}
          </div>
        )
      }
      validateStatus={
        props.form.validate &&
        !!props.validationResultForm?.getValue(props.attr)
          ? "error"
          : undefined
      }
      help={
        props.form.validate &&
        (props.validationResultForm?.getValue(props.attr) as string)
      }
      {...props.itemProps}
    >
      <Switch
        style={props.style}
        checkedChildren={props.checkedLable}
        unCheckedChildren={props.unCheckedLable}
        checked={props.form.getValue(props.attr) as boolean}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e);
          } else {
            // 一般的な処理
            props.form.updateObject(props.attr, e);
          }
        }}
        {...props.fieldProps}
      />
    </Form.Item>
  );
};

const RequiredSign = () => {
  return (
    <div
      style={{
        display: "inline-block",
        marginRight: "4px",
        marginTop: "4px",
        color: "#ff4d4f",
        fontSize: "14px",
        lineHeight: 1,
      }}
    >
      *
    </div>
  );
};
