import { Space, Table, TableProps, Tag, theme } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { UsersResponse } from "api/user";

import { BaseEntity, ID } from "entities";
import { User, UserSearchForm, UserType } from "entities/user";
import Highlighter from "react-highlight-words";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { AttrColumn, CustomTable, getObjectValue } from "specifics/table";
import { useTable } from "specifics/use_table";
import { IndexApiSet } from "utils/network/api_hooks";
import { UserTypeView } from "./users_view";

type UsersTableProps = TableProps<User>;
export const UsersTable = (
  props: UsersTableProps &
    HistoryProps & { handleDelete: (id: ID) => void } & {
      listApi?: IndexApiSet<UsersResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const table = useTable<User>(props);
  const { token } = theme.useToken();

  const columns: ColumnsType<User> = [
    ...(process.env.REACT_APP_TYPE == "SUPER_ADMIN"
      ? [
          {
            ...table.baseColumn("company.name"),
            title: "客先名称",
            render: (item: User) => item?.company?.name ?? "--",
          },
        ]
      : []),
    {
      ...table.baseColumn("name"),
      title: "氏名",
      ...table.getColumnSearchProps("name", "氏名"),
      sorter: (a, b) => ((a.name ?? "") > (b.name ?? "") ? 1 : -1),
      render: (item) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[table.searchText]}
          autoEscape
          textToHighlight={item.name}
        />
      ),
    },
    AttrColumn<User>({ attr: "mobile", label: "電話番号" }),
    AttrColumn<User>({ attr: "email", label: "メール" }),
    {
      ...table.baseColumn("type"),
      title: "役割",
      sorter: (a, b) => ((a.type ?? "") > (b.type ?? "") ? 1 : -1),
      filters: [
        {
          text: "管理者",
          value: 1,
        },
        {
          text: "ドライバー",
          value: 2,
        },
      ],
      onFilter: (value: string | number | boolean, record: User) =>
        record.type === value,
      render: (item: User) => <UserTypeView user={item} />,
    },
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 138,
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              const env = process.env.REACT_APP_TYPE;
              if (env === "ADMIN") {
                props.history.push(`/operations/users/${item.id}/edit`);
              } else if (env == "SUPER_ADMIN") {
                props.history.push(`/super-admin/users/${item.id}/edit`);
              }
            }}
          >
            編集
          </CustomButton>
          <CustomButton
            style={{
              fontWeight: 500,
              padding: 0,
              height: "30px",
              width: "28px",
              textAlign: "left",
            }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              props.handleDelete(item.id);
            }}
            danger
            confirm
            popconfirmProps={{
              title: "削除します。よろしいですか？",
            }}
          >
            削除
          </CustomButton>
        </Space>
      ),
    },
  ];
  return (
    <CustomTable
      tableKey="users_table"
      table={table}
      pagination={table.paginationInfo}
      onChange={table.handleChange}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () => {
          const env = process.env.REACT_APP_TYPE;
          if (env === "ADMIN") {
            props.history.push(`/operations/users/${data.id}`);
          } else if (env == "SUPER_ADMIN") {
            props.history.push(`/super-admin/users/${data.id}`);
          }
        },
      })}
      {...props}
    />
  );
};
