import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useIndexApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { HttpClient } from "../utils/network/axios";

import { ID, PagingResponse } from "entities";
import { Contract } from "entities/contract";
import { Form } from "utils/hooks";
import { Manual } from "entities/manual";

type Draft = {
  id?: string;
  formId?: string;
  formType?: string;
  data?: string;
};

export type DraftResponse = BaseResponse & { data: Draft }

export function useFetchDraftApi(): ApiSet<DraftResponse> & {
  execute: (formId: ID) => void;
} {
  const api = useShowApi<DraftResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (formId: ID): void => {
    const apiPath = `draft/${formId}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostDraftApi(): ApiSet<BaseResponse> & {
  execute: (draft: Draft) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, Draft>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (draft: Draft) => {
    const apiPath = `draft`;
    apiSet.execute(apiPath, draft);
  };

  return { ...apiSet, execute };
}

export function useDeleteDraftApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `draft/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}
