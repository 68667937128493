import { Button, Modal, ModalProps, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons"
import { Order, OrderDetail, OrderDivided, OrderForm, OrderType, OrderTypes } from "entities/order";
import { useContext, useEffect, useState } from "react";
import { DeliveryEditView } from "./delivery_edit_view";
import { DeliveryDetailView } from "./delivery_detail_view";
import { useEffectSkipFirst, useForm, useQuery } from "utils/hooks";
import dayjs from "dayjs";
import { GlobalStateContext } from "contexts/global_state_context";
import { driverOption } from "./kanban_container/container";
import { ItemData } from "./deliveries_kanban";
import { CustomButton } from "specifics/button";

export const DeliveryEditModal = ({
    open,
    order,
    onCancel,
    onSave,
    onUpDateOrderDetails,
    onDeleteDivided,
    onDeleteOrder,
    driverOptions,
    getItems,
    ...props
}: ModalProps & {
    order: Order,
    onCancel: () => void,
    onSave: (order: OrderForm) => void,
    onDeleteDivided: (dividedId: string) => void,
    onDeleteOrder: (id: string) => void,
    onUpDateOrderDetails: (id: string, value: any) => void,
    driverOptions: driverOption[],
    getItems: (id: string) => ItemData[]
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const toggleIsEdit = () => {
        setIsEdit(!isEdit);
    }

    const params = useQuery<{ category?: OrderType }>();
    const globalState = useContext(GlobalStateContext);
    const form = useForm<Order>({
        charterType: 0,
        orderType: params.category && (Number(params.category) as OrderType),
        orderDate: dayjs().format(),
        companyId: globalState.user.companyId,
    });
    const validationResultForm = useForm<Order>({});

    const onClose = () => {
        onCancel();
        setIsEdit(false);
    }

    useEffect(() => {
        form.set(order);
    }, []);

    return (
        <Modal
            maskClosable={true}
            closable={false}
            title={order &&
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {`受注詳細 ${OrderTypes[order.orderType!]}`}
                    <div>
                        {!isEdit && <>
                            <Button onClick={toggleIsEdit} style={{ marginRight: 10, fontSize: 14, fontWeight: "bolder" }}>編集</Button>
                            <CustomButton
                                dialogconfirmProps={{
                                    title: "受注を削除しますか？",
                                    content:
                                        <div>
                                            この受注は
                                            {order.orderDivideds ?
                                                <span><br />{order.orderDivideds.map(divided => divided.driverName).join('、')}<br />に割り当てられています。</span> :
                                                "誰にも割り当てられていません。"}
                                        </div>,
                                    okText: "削除する",
                                    onOk: () => { order.id && onDeleteOrder(order.id) },
                                    cancelText: "戻る",
                                    okButtonProps: { danger: true, style: { background: "#FFEFEE" } },
                                    icon: null,
                                    centered: true,
                                    closable: false,
                                }}
                                danger
                                style={{ height: 32, background: "#FFEFEE", marginRight: 10, fontSize: 14, fontWeight: "bolder" }}
                            >
                                削除
                            </CustomButton>
                        </>}
                        <Button onClick={onClose} style={{ border: 0, padding: 0, background: "transparent" }} icon={<CloseOutlined style={{ fontSize: 18 }} />}></Button>
                    </div>
                </div>
            }
            footer={order &&
                <div>
                    {isEdit &&
                        <div>
                            <Button onClick={toggleIsEdit} style={{ marginRight: 10, fontSize: 14, fontWeight: "bolder", border: 0, background: "transparent" }}>キャンセル</Button>
                            <Button onClick={() => {
                                form.setValidate(true);
                                if (
                                    Object.keys(validationResultForm.object).every((key) => {
                                        const value = validationResultForm.object[key as keyof Order];
                                        if (key === "orderDetails") {
                                            return validationResultForm.object.orderDetails?.every(
                                                (item: OrderDetail) =>
                                                    Object.keys(item).every(
                                                        (k) => item[k as keyof OrderDetail] === undefined
                                                    )
                                            );
                                        } else if (key === "orderDivideds" && value !== undefined) {
                                            return validationResultForm.object.orderDivideds?.every((divided: OrderDivided) => {
                                                return divided.orderDetails?.every((detail: OrderDetail) => {
                                                    return detail.loadWeight === undefined;
                                                });
                                            });
                                        } else {
                                            return value === undefined;
                                        }
                                    })
                                ) {
                                    onSave(form.object);
                                    onClose();
                                }
                            }} type="primary" style={{ marginRight: 10, fontSize: 14, fontWeight: "bolder" }}>保存</Button>
                        </div>
                    }
                </div>
            }
            open={open}
            onCancel={onClose}
            width={815}
        >
            <Space
                direction="vertical"
                style={{
                    height: "700px",
                    overflowY: "scroll",
                }}
            >
                {isEdit ? <DeliveryEditView form={form} validationResultForm={validationResultForm} driverOptions={driverOptions} getItems={getItems} /> : <DeliveryDetailView order={order} />}
            </Space>
        </Modal>
    )
}