import { GlobalStateContext } from "contexts/global_state_context";
import { Space, theme } from "antd";
import { useFetchCompanyApi } from "api/company";

import dayjs from "dayjs";
import { useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { CompanyManualType } from "entities/company";

const SuperAdminCompanyPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const companyApi = useFetchCompanyApi();
  const params = useParams<{ id: string }>();
  useEffect(() => {
    companyApi.execute(params.id);
  }, []);

  const company = () => {
    return companyApi.response.data;
  };

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="客先詳細"
      onBack={() => props.history.goBack()}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent>
        <Space
          style={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <Space direction="vertical" size={24}>
            <DescriptionBlock label="名称">{company()?.name}</DescriptionBlock>
            <DescriptionBlock label="郵便番号">
              {company()?.zipcode}
            </DescriptionBlock>
            <DescriptionBlock label="住所">
              {company()?.address}
            </DescriptionBlock>
            <DescriptionBlock label="代表者氏名">
              {company()?.repName}
            </DescriptionBlock>
            <DescriptionBlock label="代表電話番号">
              {company()?.repTel}
            </DescriptionBlock>
            <DescriptionBlock label="カルテ作成タイプ">
              {CompanyManualType[company()?.manualType as CompanyManualType]}
            </DescriptionBlock>
            <DescriptionBlock label="選択可能なテンプレート">
              {company()
                ?.templates?.map((template) => template.templateName)
                ?.join(",")}
            </DescriptionBlock>
            <DescriptionBlock label="デフォルトのテンプレート">
              {company()?.defaultTemplateName}
            </DescriptionBlock>
            <Space
              style={{
                width: "100%",
              }}
              direction="horizontal"
              size={24}
            >
              <DescriptionBlock label="作成日" style={{ width: 235.86 }}>
                {company()?.createdAt
                  ? dayjs(company()?.createdAt).format("YYYY/MM/DD")
                  : ""}
              </DescriptionBlock>
              <DescriptionBlock label="最終更新日" style={{ width: 235.86 }}>
                {company()?.updatedAt
                  ? dayjs(company()?.updatedAt).format("YYYY/MM/DD")
                  : ""}
              </DescriptionBlock>
            </Space>
            <DescriptionBlock label="備考">{company()?.memo}</DescriptionBlock>
          </Space>

          <Space size={24} style={{ verticalAlign: "top", height: "100%" }}>
            <CustomButton
              style={{
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() =>
                props.history.push(
                  `/super-admin/companies/${company()?.id}/edit`
                )
              }
            >
              編集
            </CustomButton>
          </Space>
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(SuperAdminCompanyPage);
