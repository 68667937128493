import {
  Modal,
  Progress,
  Skeleton,
  Space,
  Spin,
  Table,
  Upload,
  message,
  theme,
} from "antd";
import { GlobalStateContext } from "contexts/global_state_context";
import { useContext, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomPageHeader } from "specifics/page_header";
import {
  AnalysisBaseCardView,
  AnalysisDetailButton,
} from "../view/analysis_base_card_view";
import { ChangeRatioStatisticsView } from "../view/change_ratio_statistics_view";
import { NumberStatisticsView } from "../view/number_statistics_view";
import dayjs, { Dayjs } from "dayjs";
import { useForm, useQuery } from "utils/hooks";
import {
  FilterDeliveryCompanyConfig,
  useCountsDeliveryCompanyApi,
  useFetchFilterAllDeliveryCompaniesApi,
} from "api/delivery_company";
import {
  CountsApiResponseData,
  PercentagesApiResponseData,
} from "utils/network/api_hooks";
import { CustomDateField } from "specifics/input";
import { CustomBarChat } from "specifics/recharts/custom_bar_chat";
import { useCountsUserApi } from "api/user";
import { UserType } from "entities/user";
import { useCountsVehicleApi } from "api/vehicle";
import { useCountsIssueApi } from "api/issue";
import {
  AnalysisBaseSearchFormDatetimeFormat,
  AnalysisSearchContext,
} from "contexts/analysis_search_context";
import QueryString from "qs";
import { DeliveryCompany } from "entities/delivery_company";
import { DeliveryCompaniesTable } from "components/delivery_companies/delivery_companies_table";
import { DeliveryTerm } from "entities/contract";

const CompanyVisualizationPage = ({ history }: HistoryProps) => {
  const { token } = theme.useToken();
  type SearchForm = {
    datetime: string;
  };
  const query = useQuery<SearchForm>();
  const { datetime, setDatetime } = useContext(AnalysisSearchContext);
  const searchForm = useForm<SearchForm>({ datetime });
  useEffect(() => {
    setDatetime(searchForm.object.datetime);
  }, [searchForm.object.datetime]);
  useEffect(() => {
    if (!query.datetime) {
      history.replace(
        `${location.pathname}?${QueryString.stringify({
          ...query,
          datetime: dayjs()
            .endOf("month")
            .format(AnalysisBaseSearchFormDatetimeFormat),
        } as SearchForm)}`
      );
    } else {
      searchForm.set({
        ...query,
        datetime: dayjs(query.datetime, AnalysisBaseSearchFormDatetimeFormat)
          .endOf("month")
          .format(),
      });
    }
  }, [JSON.stringify(query)]);

  const givenTimes = useMemo(() => {
    return [
      dayjs(datetime).subtract(5, "month").format(),
      dayjs(datetime).subtract(4, "month").format(),
      dayjs(datetime).subtract(3, "month").format(),
      dayjs(datetime).subtract(2, "month").format(),
      dayjs(datetime).subtract(1, "month").format(),
      dayjs(datetime).format(),
    ];
  }, [datetime]);

  const countsDriversApi = useCountsUserApi({
    givenTimes,
    type: UserType.ドライバー,
  });

  const countsVehiclesApi = useCountsVehicleApi({
    givenTimes,
  });

  const countsIssuesApi = useCountsIssueApi({
    givenTimes,
  });

  const countsAllDeliveryCompanyApi = useCountsDeliveryCompanyApi({
    givenTimes,
  });

  const countsZeroDeliveryTermDeliveryCompanyApi = useCountsDeliveryCompanyApi({
    givenTimes: [dayjs(datetime).format()],
    deliveryTerm: DeliveryTerm.指定置き場渡し,
  });

  const countsExistNeedTaskDeliveryTermDeliveryCompanyApi =
    useCountsDeliveryCompanyApi({
      givenTimes: [dayjs(datetime).format()],
      existNeedTask: true,
    });

  const countsHasManualsDeliveryCompanyApi = useCountsDeliveryCompanyApi({
    givenTimes,
    hasManuals: true,
  });

  const countsHasDeliveryTermsMismatchedManualsDeliveryCompanyApi =
    useCountsDeliveryCompanyApi({
      givenTimes: [dayjs(datetime).format()],
      hasDeliveryTermsMismatchedManuals: true,
    });
  const countsHasNeedTaskMismatchedManualsDeliveryCompanyApi =
    useCountsDeliveryCompanyApi({
      givenTimes: [dayjs(datetime).format()],
      hasNeedTaskMismatchedManuals: true,
    });

  useEffect(() => {
    countsAllDeliveryCompanyApi.execute();
    countsZeroDeliveryTermDeliveryCompanyApi.execute();
    countsExistNeedTaskDeliveryTermDeliveryCompanyApi.execute();
    countsHasManualsDeliveryCompanyApi.execute();
    countsHasNeedTaskMismatchedManualsDeliveryCompanyApi.execute();
    countsHasDeliveryTermsMismatchedManualsDeliveryCompanyApi.execute();
    countsDriversApi.execute();
    countsVehiclesApi.execute();
    countsIssuesApi.execute();
  }, [givenTimes]);

  const [showContentsModalConfig, setShowContentsModalConfig] = useState<{
    open: boolean;
    title?: string;
    filterAllConfing?: FilterDeliveryCompanyConfig;
  }>({ open: false });

  const filterAllDeliveryCompanyApi = useFetchFilterAllDeliveryCompaniesApi();
  useEffect(() => {
    filterAllDeliveryCompanyApi.execute(
      showContentsModalConfig.filterAllConfing
    );
  }, [showContentsModalConfig.filterAllConfing]);

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{
        display: "flex",
        gap: 24,
        flexDirection: "column",
      }}
      title="経営レポート"
      extra={
        <CustomDateField
          form={searchForm}
          attr="datetime"
          itemProps={{ style: { marginBottom: 0 } }}
          fieldProps={{
            picker: "month",
            format: "YYYY年MM月",
            disabledDate: (date: Dayjs) =>
              date >= dayjs().startOf("month").add(1, "month"),
            onChange: (value: Dayjs | null) =>
              value &&
              searchForm.updateObject(
                "datetime",
                value.endOf("month").format()
              ),
          }}
        />
      }
    >
      <Space size={24} style={{ justifyContent: "center", width: "100%" }}>
        <DriversCountsCardView
          onClickDetail={() =>
            history.push(
              `/analysis/details/drivers-counts?datetime=${dayjs(
                datetime
              ).format(AnalysisBaseSearchFormDatetimeFormat)}`
            )
          }
          loading={countsDriversApi.loading}
          countsDrivers={countsDriversApi.response.data}
          currentDatetime={dayjs(datetime)}
        />
        <IssuesCountsCardView
          onClickDetail={() =>
            history.push(
              `/analysis/details/issues-counts?datetime=${dayjs(
                datetime
              ).format(AnalysisBaseSearchFormDatetimeFormat)}`
            )
          }
          loading={countsIssuesApi.loading}
          countsIssues={countsIssuesApi.response.data}
          currentDatetime={dayjs(datetime)}
        />
        <VehiclesCountsCardView
          onClickDetail={() =>
            history.push(
              `/analysis/details/vehicles-counts?datetime=${dayjs(
                datetime
              ).format(AnalysisBaseSearchFormDatetimeFormat)}`
            )
          }
          loading={countsVehiclesApi.loading}
          countsVehicles={countsVehiclesApi.response.data}
          currentDatetime={dayjs(datetime)}
        />
      </Space>
      <Space size={24} style={{ justifyContent: "center", width: "100%" }}>
        <DeliveryCompanyWithManualsPercentagesCardView
          onClickDetail={() =>
            history.push(
              `/analysis/details/with-manuals-percentages?datetime=${dayjs(
                datetime
              ).format(AnalysisBaseSearchFormDatetimeFormat)}`
            )
          }
          loading={
            countsAllDeliveryCompanyApi.loading ||
            countsHasManualsDeliveryCompanyApi.loading
          }
          countsAllDeliveryCompany={countsAllDeliveryCompanyApi.response.data}
          countsHasManualsDeliveryCompany={
            countsHasManualsDeliveryCompanyApi.response.data
          }
          currentDatetime={dayjs(datetime)}
        />

        <AnalysisBaseCardView
          title="付帯業務ありの行先"
          headerRight={
            <AnalysisDetailButton
              onClick={() => {
                if (
                  !(countsExistNeedTaskDeliveryTermDeliveryCompanyApi.response
                    .data ?? [])[0]?.count
                ) {
                  message.error("付帯業務ありの行先はありません。");
                } else {
                  setShowContentsModalConfig({
                    open: true,
                    title: "付帯業務ありの行先一覧",
                    filterAllConfing: { existNeedTask: true },
                  });
                }
              }}
            />
          }
        >
          <>
            <NumberStatisticsView
              loading={
                countsExistNeedTaskDeliveryTermDeliveryCompanyApi.loading
              }
              value={
                (countsExistNeedTaskDeliveryTermDeliveryCompanyApi.response
                  .data ?? [])[0]?.count
              }
              unit="件"
            />
            <ChangeRatioStatisticsView
              items={[
                { label: "前年比", percenrt: undefined },
                { label: "前四半期比", percenrt: undefined },
                { label: "前月比", percenrt: undefined },
              ]}
            />
          </>
        </AnalysisBaseCardView>
        <AnalysisBaseCardView
          title="自主荷役の行先"
          headerRight={
            <AnalysisDetailButton
              onClick={() => {
                if (
                  !(countsZeroDeliveryTermDeliveryCompanyApi.response.data ??
                    [])[0]?.count
                ) {
                  message.error("自主荷役の行先はありません。");
                } else {
                  setShowContentsModalConfig({
                    open: true,
                    title: "自主荷役の行先一覧",
                    filterAllConfing: { deliveryTerm: DeliveryTerm.指定置き場渡し },
                  });
                }
              }}
            />
          }
        >
          <>
            <NumberStatisticsView
              loading={countsZeroDeliveryTermDeliveryCompanyApi.loading}
              value={
                (countsZeroDeliveryTermDeliveryCompanyApi.response.data ??
                  [])[0]?.count
              }
              unit="件"
            />
            <ChangeRatioStatisticsView
              items={[
                { label: "前年比", percenrt: undefined },
                { label: "前四半期比", percenrt: undefined },
                { label: "前月比", percenrt: undefined },
              ]}
            />
          </>
        </AnalysisBaseCardView>
      </Space>
      <Space size={24} style={{ justifyContent: "center", width: "100%" }}>
        <AnalysisBaseCardView
          title="付帯業務の契約不一致の行先"
          headerRight={
            <AnalysisDetailButton
              onClick={() => {
                if (
                  !(countsHasNeedTaskMismatchedManualsDeliveryCompanyApi
                    .response.data ?? [])[0]?.count
                ) {
                  message.error("付帯業務の契約不一致の行先はありません。");
                } else {
                  setShowContentsModalConfig({
                    open: true,
                    title: "付帯業務の契約不一致の行先一覧",
                    filterAllConfing: { hasNeedTaskMismatchedManuals: true },
                  });
                }
              }}
            />
          }
        >
          <>
            <NumberStatisticsView
              loading={
                countsHasNeedTaskMismatchedManualsDeliveryCompanyApi.loading
              }
              value={
                (countsHasNeedTaskMismatchedManualsDeliveryCompanyApi.response
                  .data ?? [])[0]?.count
              }
              unit="件"
            />
            <ChangeRatioStatisticsView
              items={[
                { label: "前年比", percenrt: undefined },
                { label: "前四半期比", percenrt: undefined },
                { label: "前月比", percenrt: undefined },
              ]}
            />
          </>
        </AnalysisBaseCardView>
        <AnalysisBaseCardView
          title="自主荷役の契約不一致の行先"
          headerRight={
            <AnalysisDetailButton
              onClick={() => {
                if (
                  !(countsHasDeliveryTermsMismatchedManualsDeliveryCompanyApi
                    .response.data ?? [])[0]?.count
                ) {
                  message.error("自主荷役の契約不一致の行先はありません。");
                } else {
                  setShowContentsModalConfig({
                    open: true,
                    title: "自主荷役の契約不一致の行先一覧",
                    filterAllConfing: {
                      hasDeliveryTermsMismatchedManuals: true,
                    },
                  });
                }
              }}
            />
          }
        >
          <>
            <NumberStatisticsView
              loading={
                countsHasDeliveryTermsMismatchedManualsDeliveryCompanyApi.loading
              }
              value={
                (countsHasDeliveryTermsMismatchedManualsDeliveryCompanyApi
                  .response.data ?? [])[0]?.count
              }
              unit="件"
            />
            <ChangeRatioStatisticsView
              items={[
                { label: "前年比", percenrt: undefined },
                { label: "前四半期比", percenrt: undefined },
                { label: "前月比", percenrt: undefined },
              ]}
            />
          </>
        </AnalysisBaseCardView>
      </Space>
      <Modal
        width={window.innerWidth - 32}
        style={{ top: 16 }}
        footer={null}
        open={showContentsModalConfig.open}
        title={showContentsModalConfig.title}
        onCancel={() => setShowContentsModalConfig({ open: false })}
      >
        {filterAllDeliveryCompanyApi.loading ? (
          <Spin />
        ) : (
          <DeliveryCompaniesTable
            history={history}
            dataSource={filterAllDeliveryCompanyApi.response.data}
          />
          // filterAllDeliveryCompanyApi.response.data?.map(
          //   (deliveryComapny: DeliveryCompany) => (
          //     <div key={deliveryComapny.id}>{deliveryComapny.name}</div>
          //   )
          // )
        )}
      </Modal>
    </CustomPageHeader>
  );
};

export default withRouter(CompanyVisualizationPage);

const DeliveryCompanyWithManualsPercentagesCardView = ({
  onClickDetail,
  loading,
  currentDatetime,
  countsAllDeliveryCompany,
  countsHasManualsDeliveryCompany,
}: {
  onClickDetail: () => void;
  loading?: boolean;
  currentDatetime?: Dayjs;
  countsHasManualsDeliveryCompany?: CountsApiResponseData;
  countsAllDeliveryCompany?: CountsApiResponseData;
}) => {
  const { token } = theme.useToken();
  const calculateDeliveryCompanyWithManualsPercentages = (
    allCounts?: CountsApiResponseData,
    manualCounts?: CountsApiResponseData
  ): PercentagesApiResponseData => {
    if (!allCounts || !manualCounts) return [];

    // 各datetimeのマッピングを作成
    const allCountsMap = new Map<string, number>(
      allCounts.map((item) => [item.datetime, item.count])
    );
    const manualCountsMap = new Map<string, number>(
      manualCounts.map((item) => [item.datetime, item.count])
    );

    const result: PercentagesApiResponseData = [];

    for (const [datetime, count] of allCountsMap) {
      const manualCount = manualCountsMap.get(datetime) ?? 0;
      const percentage = count !== 0 ? (manualCount / count) * 100 : 0;
      result.push({ datetime, percentage });
    }

    return result;
  };

  const deliveryCompanyWithManualsPercentages = useMemo(() => {
    return calculateDeliveryCompanyWithManualsPercentages(
      countsAllDeliveryCompany,
      countsHasManualsDeliveryCompany
    ).map((item) => ({ ...item, month: dayjs(item.datetime).format("YY/MM") }));
  }, [countsAllDeliveryCompany, countsHasManualsDeliveryCompany]);

  return (
    <AnalysisBaseCardView
      title="納入カルテ作成率"
      headerRight={<AnalysisDetailButton onClick={onClickDetail} />}
      loading={loading}
    >
      <CustomBarChat
        data={deliveryCompanyWithManualsPercentages}
        xAxisLabelKey="month"
        valueKey="percentage"
        getTooltipLabel={(item) => {
          return dayjs(item.payload.datetime).format("YY年MM月");
        }}
        yaxisProps={{
          domain: [0, 100],
        }}
        getTooltipBody={(item) =>
          `${Math.round(10 * item.payload.percentage) / 10 || 0}%`
        }
        fill={(payload) =>
          dayjs(payload?.datetime).month() === currentDatetime?.month()
            ? token.colorPrimary
            : "#EAEAEA"
        }
      />
    </AnalysisBaseCardView>
  );
};

const DriversCountsCardView = ({
  onClickDetail,
  loading,
  currentDatetime,
  countsDrivers,
}: {
  onClickDetail: () => void;
  loading?: boolean;
  currentDatetime?: Dayjs;
  countsDrivers?: CountsApiResponseData;
}) => {
  const { token } = theme.useToken();

  return (
    <AnalysisBaseCardView
      title="ドライバー数"
      headerRight={<AnalysisDetailButton onClick={onClickDetail} />}
      loading={loading}
    >
      <CustomBarChat
        data={
          countsDrivers?.map((item) => ({
            ...item,
            month: dayjs(item.datetime).format("YY/MM"),
          })) || []
        }
        xAxisLabelKey="month"
        valueKey="count"
        getTooltipLabel={(item) => {
          return dayjs(item.payload.datetime).format("YY年MM月");
        }}
        getTooltipBody={(item) => `${item.payload.count}人`}
        fill={(payload) =>
          dayjs(payload?.datetime).month() === currentDatetime?.month()
            ? token.colorPrimary
            : "#EAEAEA"
        }
      />
    </AnalysisBaseCardView>
  );
};

const VehiclesCountsCardView = ({
  onClickDetail,
  loading,
  currentDatetime,
  countsVehicles,
}: {
  onClickDetail: () => void;
  loading?: boolean;
  currentDatetime?: Dayjs;
  countsVehicles?: CountsApiResponseData;
}) => {
  const { token } = theme.useToken();

  return (
    <AnalysisBaseCardView
      title="車両数"
      headerRight={<AnalysisDetailButton onClick={onClickDetail} />}
      loading={loading}
    >
      <CustomBarChat
        data={
          countsVehicles?.map((item) => ({
            ...item,
            month: dayjs(item.datetime).format("YY/MM"),
          })) || []
        }
        xAxisLabelKey="month"
        valueKey="count"
        getTooltipLabel={(item) => {
          return dayjs(item.payload.datetime).format("YY年MM月");
        }}
        getTooltipBody={(item) => `${item.payload.count}両`}
        fill={(payload, hover) =>
          dayjs(payload?.datetime).month() === currentDatetime?.month() || hover
            ? token.colorPrimary
            : "#EAEAEA"
        }
      />
    </AnalysisBaseCardView>
  );
};

const IssuesCountsCardView = ({
  onClickDetail,
  loading,
  currentDatetime,
  countsIssues,
}: {
  onClickDetail: () => void;
  loading?: boolean;
  currentDatetime?: Dayjs;
  countsIssues?: CountsApiResponseData;
}) => {
  const { token } = theme.useToken();

  return (
    <AnalysisBaseCardView
      title="報告数"
      headerRight={<AnalysisDetailButton onClick={onClickDetail} />}
      loading={loading}
    >
      <CustomBarChat
        data={
          countsIssues?.map((item) => ({
            ...item,
            month: dayjs(item.datetime).format("YY/MM"),
          })) || []
        }
        xAxisLabelKey="month"
        valueKey="count"
        getTooltipLabel={(item) => {
          return dayjs(item.payload.datetime).format("YY年MM月");
        }}
        getTooltipBody={(item) => `${item.payload.count}件`}
        fill={(payload, hover) =>
          dayjs(payload?.datetime).month() === currentDatetime?.month() || hover
            ? token.colorPrimary
            : "#EAEAEA"
        }
      />
    </AnalysisBaseCardView>
  );
};
