import React, { ReactNode, CSSProperties, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Card, } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { ItemData } from "../deliveries_kanban";
import { DeliveryEditModal } from "../delivery_edit_modal";
import { OrderForm, } from "entities/order";
import { driverOption } from "./container";
import { DeliveryCompany } from "entities/delivery_company";

interface ItemProps {
    item: ItemData;
    driverOptions?: driverOption[],
    getItems: (id: string) => ItemData[];
    onUpdateOrderDivideds: (id: string, value: any) => void;
    onUpDateOrderDetails: (id: string, value: any) => void;
    onSave: (order: OrderForm) => void;
    onDeleteDivided: (dividedId: string) => void;
    onDeleteOrder: (id: string) => void;
}

export const Item: React.FC<ItemProps> = ({ item, getItems, onUpdateOrderDivideds, onUpDateOrderDetails, onSave, onDeleteDivided, onDeleteOrder, driverOptions }) => {
    const style: CSSProperties = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #D1D5DB",
        background: "white",
        borderRadius: 8,
        width: 180
    };

    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true);
    }
    const closeModal = () => {
        setOpen(false);
    }

    const cellStyle = {
        paddingLeft: "20px",
        fontSize: 16,
    }

    let loadingCompany = item.order?.orderDetails
        ?.filter(detail => detail.pointType === 2)
        .map(detail => ({
            ...detail.deliveryCompany,
            loadWeight: detail.loadWeight ?? 0
        }));

    let unloadingCompany = item.order?.orderDetails
        ?.filter(detail => detail.pointType === 1)
        .map(detail => ({
            ...detail.deliveryCompany,
            loadWeight: detail.loadWeight ?? 0
        }));

    // 回転に属する配車カード、積み・降し先が複数あり、積荷の重量が0の時は表示しない。
    if (item.parent !== "undefined" && (loadingCompany ?? []).length > 1) {
        loadingCompany = item.orderDivided?.orderDetails
            ?.filter(detail => detail.pointType === 2 && (detail.loadWeight ?? 0) > 0)
            .map(detail => {
                return {
                    ...detail.deliveryCompany, // 既存のdeliveryCompanyオブジェクトを展開
                    loadWeight: detail.loadWeight ?? 0// 新しいプロパティとしてloadWeightを追加
                };
            });
    }

    if (item.parent !== "undefined" && (unloadingCompany ?? []).length > 1) {
        unloadingCompany = item.orderDivided?.orderDetails
            ?.filter(detail => detail.pointType === 1 && (detail.loadWeight ?? 0) > 0)
            .map(detail => {
                return {
                    ...detail.deliveryCompany, // 既存のdeliveryCompanyオブジェクトを展開
                    loadWeight: detail.loadWeight ?? 0// 新しいプロパティとしてloadWeightを追加
                };
            });
    }


    return <div style={style}>
        {open &&
            <DeliveryEditModal
                open={open}
                order={item.order!}
                onCancel={closeModal}
                onSave={onSave}
                onUpDateOrderDetails={onUpDateOrderDetails}
                onDeleteDivided={onDeleteDivided}
                onDeleteOrder={onDeleteOrder}
                driverOptions={driverOptions ?? []}
                getItems={getItems}
            />}
        <Card
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    受注番号：{item?.order?.orderNo}
                    {item.parent !== ("undefined" || "chartered") && < Button onClick={() => { onDeleteDivided(item.id) }} style={{ position: 'absolute', border: 0, top: 0, right: 0, background: "transparent", cursor: 'pointer' }} icon={<CloseOutlined style={{ fontSize: 14, color: '#919eab' }} />}></Button>}
                </div>
            }
            onClick={showModal} headStyle={{ fontSize: 10, minHeight: 25, padding: 0 }} bodyStyle={{ color: "#4B5563", fontSize: 10, padding: 0 }} style={{ width: 180, padding: "0 20px" }}
        >
            <div style={{ fontWeight: 600, marginTop: 5, whiteSpace: "normal" }}>
                積み
                <div style={{ fontWeight: 500, backgroundColor: "#F3F4F6", borderRadius: 4, padding: 5 }}>
                    {loadingCompany?.map(company => (<div key={item.id + company?.id}>{company?.name}{`（${company?.loadWeight}kg）`}</div>))}
                </div>
            </div>
            <div style={{ fontWeight: 600, marginTop: 5, whiteSpace: "normal" }}>
                降し
                <div style={{ fontWeight: 500, backgroundColor: "#F3F4F6", borderRadius: 4, padding: 5 }}>
                    {unloadingCompany?.map(company => (<div key={item.id + company?.id}>{company?.name}{`（${company?.loadWeight}kg）`}</div>))}
                </div>
            </div>
            <div style={{ fontWeight: 600, marginTop: 5 }}>
                重量
                <div style={{ fontWeight: 400 }}>
                    {Number(item.assignedWeight).toFixed(1)}/{item?.order?.loadWeight}kg
                </div>
            </div>
        </Card >
    </div >
}


interface SortableItemProps {
    children: ReactNode;
    handlePosition?: "right" | "top";
    id: string;
    driver?: boolean;
    driverUnassigned?: boolean;
    isCharteredItem?: boolean;
}

export const SortableItem: React.FC<SortableItemProps> = ({ children, handlePosition = "right", id, driver, driverUnassigned, isCharteredItem }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        position: driver && driverUnassigned ? "sticky" : "relative",
        left: 0,
        zIndex: driver && driverUnassigned ? 1 : 0
    };

    let handleStyle = {
        position: "absolute",
        fontSize: 36,
        color: "black",
        cursor: "grab"
    };

    const rightHandleStyle = {
        right: 25,
        top: 0,
        bottom: 0,
        transform: "rotate(90deg)"
    };

    const topHandleStyle = {
        right: 40,
        top: 0
    };

    if (handlePosition === "right") {
        handleStyle = {
            ...handleStyle,
            ...rightHandleStyle
        };
    } else if (handlePosition === "top") {
        handleStyle = {
            ...handleStyle,
            ...topHandleStyle
        };
    }

    return (driver && driverUnassigned) || isCharteredItem ? (
        <div style={{
            ...style,
        }}>
            {children}
        </div>
    ) : (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
}
