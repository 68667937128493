import { Space, SpaceProps, theme } from "antd";

import { CSSProperties, ReactNode, useContext } from "react";

type CustomContentFooterProps = SpaceProps & {
  children: ReactNode;
  style?: CSSProperties;
};

export const CustomContentFooter = (props: CustomContentFooterProps) => {
  const { children, style, ...rest } = props;
  const { token } = theme.useToken();

  return (
    <Space
      style={{
        height: 62,
        display: "flex",
        flexDirection: "row-reverse",
        width: "100%",
        padding: "12px 24px 12px 24px",
        background: token.colorBgBase,
        borderBottomRightRadius: "6px",
        borderBottomLeftRadius: "6px",
        ...style,
      }}
      {...rest}
    >
      {children}
    </Space>
  );
};
