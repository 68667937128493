import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";
import Axios from "axios";
import { useContext } from "react";
import { GlobalStateContext } from "contexts/global_state_context";
import { BaseEntity, ID } from "entities";
import { message, notification } from "antd";
import { UserType } from "entities/user";
import { CookieManager } from "utils/cookie_manager";

export type AuthorizationForm = {
  mobile?: string;
  password?: string;
} & BaseEntity;

export type ForgetPasswordForm = {
  tel?: string;
} & BaseEntity;

export type ResetPasswordForm = BaseEntity & {
  password?: string;
  confirmPassword?: string;
  userId?: ID;
  token?: string;
};

type AuthorizationResponse = BaseResponse & {
  token: string;
  username: string;
  userid: string;
  type?: 1 | 2;
};

/**
 * 認証Api
 */

export const useFetchSanctumApi = (): IndexApiSet<BaseResponse> & {
  execute: () => Promise<void>;
} => {
  const api = useIndexApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = async (): Promise<void> => {
    api.setLoading(true);
    const response: BaseResponse = await Axios.get(
      `${process.env.REACT_APP_API_HOST?.replace(
        "/api/",
        "/"
      )}sanctum/csrf-cookie`
    );
    api.setResponse(response);
    api.setStatus(response.status);
    api.setLoading(false);
  };

  return { ...api, execute };
};

export const useRegisterApi = (): ApiSet<AuthorizationResponse> & {
  execute: (form: Form<AuthorizationForm>) => void;
} => {
  const apiPath = "register";
  const apiSet = usePostApi<AuthorizationResponse, AuthorizationForm>(
    new HttpClient(),
    {
      initialResponse: { token: "", username: "", userid: "" },
    }
  );

  const execute = async (form: Form<AuthorizationForm>) => {
    const apiClient = Axios.create({
      xsrfHeaderName: "X-CSRF-Token",
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    });
    await apiClient.get(
      `${process.env.REACT_APP_API_HOST?.replace("/api/", "/").replace(
        "/api/",
        "/"
      )}sanctum/csrf-cookie`
    );
    await apiClient.post(`${process.env.REACT_APP_API_HOST}register`);
    // apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
};

export const useLoginApi = (): ApiSet<AuthorizationResponse> & {
  execute: (form: Form<AuthorizationForm>) => void;
} => {
  const globalState = useContext(GlobalStateContext);
  const apiSet = usePostApi<AuthorizationResponse, AuthorizationForm>(
    new HttpClient(),
    {
      initialResponse: { token: "", username: "", userid: "" },
    }
  );

  const execute = async (form: Form<AuthorizationForm>) => {
    if (apiSet.loading) {
      return;
    }
    apiSet.setLoading(true);
    Axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST?.replace(
      "/api/",
      "/"
    ).replace("/admin", "")}`;
    Axios.defaults.headers.post["Content-Type"] = "application/json";
    Axios.defaults.headers.post["Accept"] = "application/json";
    Axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
    Axios.defaults.withCredentials = true;
    Axios.defaults.xsrfCookieName = "XSRF-TOKEN";
    Axios.defaults.xsrfHeaderName = "laravel-session";
    Axios.interceptors.request.use(function (config) {
      const token = CookieManager.getUserToken();
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
    try {
      const response = await Axios.get("/sanctum/csrf-cookie");
      const res = await Axios.post(
        `${process.env.REACT_APP_API_HOST}login`,
        form.object
      );
      if (res.data.status === 200) {
        const data: AuthorizationResponse = res.data;
        if (
          (process.env.REACT_APP_TYPE === "ADMIN" &&
            res.data.type === UserType.運行管理者) ||
          (process.env.REACT_APP_TYPE === "DRIVER" &&
            res.data.type === UserType.ドライバー) ||
          (process.env.REACT_APP_TYPE === "SUPER_ADMIN" &&
            res.data.type === UserType.knewit社員)
        ) {
          if (data?.message) {
            message.success(data.message);
          }
          apiSet.setResponse(data);
          apiSet.setStatus(res.status);
          form?.resetForm();
        } else {
          notification["error"]({
            message: "USER_TYPEが不正です。",
            description: `${process.env.REACT_APP_TYPE === "ADMIN" ? "管理者" : "ドライバー"
              }システムに、${res.data.type === UserType.運行管理者
                ? "管理者"
                : res.data.type === UserType.ドライバー
                  ? "ドライバー"
                  : "Unknown"
              }システム(usertype=${res.data.type
              })のユーザーでログインしようとしています`,
          });
          apiSet.setResponse({ ...data, message: "USER_TYPEが不正です。" });
          apiSet.setStatus(401);
        }
      } else {
        notification["error"]({
          message: `エラー`,
          description: JSON.stringify(res),
        });
      }
    } catch (e) {
      apiSet.handleError(e);
    }
    apiSet.setLoading(false);
  };

  return { ...apiSet, execute };
};

export const useLogoutApi = (): ApiSet<BaseResponse> & {
  execute: () => void;
} => {
  const apiSet = usePostApi<BaseResponse, BaseEntity>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = async () => {
    const apiPath = "logout";
    apiSet.execute(apiPath);
  };

  return { ...apiSet, execute };
};

export const useChangePasswordApi = (): ApiSet<BaseResponse> & {
  execute: (object: ResetPasswordForm) => void;
} => {
  const apiSet = usePostObjectApi<BaseResponse, BaseEntity>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = async (object: ResetPasswordForm) => {
    const apiPath = "passwordchange";
    apiSet.execute(apiPath, object);
  };

  return { ...apiSet, execute };
};

type CheckTokenResponse = {
  data?: { result?: boolean };
} & BaseResponse;

export function useCheckTokenApi(): ApiSet<CheckTokenResponse> & {
  execute: (object?: ResetPasswordForm) => void;
} {
  const globalState = useContext(GlobalStateContext);
  const apiSet = usePostObjectApi<CheckTokenResponse, BaseEntity>(
    new HttpClient(),
    {
      initialResponse: {},
    }
  );

  const execute = async (object?: ResetPasswordForm) => {
    const apiPath = "tokencheck";
    apiSet.execute(apiPath, object);
  };

  return { ...apiSet, execute };
}

export function useSendSmsAPi(): ApiSet<BaseResponse> & {
  execute: (tel?: string) => void;
} {
  const apiSet = useShowApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (tel?: string) => {
    const apiPath = `sendsms/${tel}`;
    apiSet.execute(apiPath);
  };

  return { ...apiSet, execute };
}

export const useTempTokenAuthApi = (): ApiSet<AuthorizationResponse> & {
  execute: () => Promise<boolean>;
} => {
  const globalState = useContext(GlobalStateContext);
  const apiSet = usePostApi<AuthorizationResponse, AuthorizationForm>(
    new HttpClient(),
    {
      initialResponse: { token: "", username: "", userid: "" },
    }
  );

  const execute = async () => {
    if (apiSet.loading) {
      return false;
    }
    apiSet.setLoading(true);
    Axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST?.replace(
      "/api/",
      "/"
    ).replace("/admin", "")}`;
    Axios.defaults.headers.post["Content-Type"] = "application/json";
    Axios.defaults.headers.post["Accept"] = "application/json";
    Axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
    Axios.defaults.withCredentials = true;
    Axios.defaults.xsrfCookieName = "XSRF-TOKEN";
    Axios.defaults.xsrfHeaderName = "laravel-session";
    Axios.interceptors.request.use(function (config) {
      const token = CookieManager.getUserToken();
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
    try {
      await Axios.get("/sanctum/csrf-cookie"); // CSRFトークンの設定を試みる
      apiSet.setLoading(false);
      return true; // リクエストが成功した場合はtrueを返す
    } catch (e) {
      apiSet.handleError(e);
      apiSet.setLoading(false);
      return false; // エラーが発生した場合はfalseを返す
    }
  };

  return { ...apiSet, execute };
};