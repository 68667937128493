import { Space, TableProps, theme } from "antd";
import { ColumnsType } from "antd/lib/table";

import { ID } from "entities";
import {
  DeliveryCompany,
  DeliveryCompanyManager,
  DeliveryCompanyType,
  DeliveryCompanyTypes,
} from "entities/delivery_company";
import dayjs from "dayjs";
import Highlighter from "react-highlight-words";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomTable } from "specifics/table";
import { CustomTableText } from "specifics/table_text";
import { useTable } from "specifics/use_table";
import { DeliveryCompanyTypeTagView } from "./delivery_companies_view";
import { DeliveryCompaniesResponse } from "api/delivery_company";
import { IndexApiSet } from "utils/network/api_hooks";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";

type deliveryCompaniesTableProps = TableProps<DeliveryCompany>;

export const DeliveryCompaniesTable = (
  props: deliveryCompaniesTableProps &
    HistoryProps & { handleDelete?: (id: ID) => void } & {
      listApi?: IndexApiSet<DeliveryCompaniesResponse> & {
        execute: (query?: string) => void;
      };
      deliveryPartnerCompanies?: DeliveryPartnerCompany[];
    }
) => {
  const table = useTable<DeliveryCompany>(props);
  const { token } = theme.useToken();
  const columns: ColumnsType<DeliveryCompany> = [
    {
      ...table.baseColumn("name"),
      ...table.getColumnSearchProps("name", "名称"),
      title: "名称",
      width: 150,
      render: (item) => {
        return <CustomTableText width={150}>{item.name}</CustomTableText>;
      },
      fixed: "left",
    },
    {
      ...table.baseColumn("type"),
      title: "種別",
      width: 100,
      render: DeliveryCompanyTypeTagView,
      filters: Object.keys(DeliveryCompanyTypes)
        .map((key) => Number(key) as DeliveryCompanyType)
        .filter((key) => key === 1 || key === 2)
        .map((key) => {
          return {
            value: key,
            text: DeliveryCompanyTypes[key],
          };
        }),
      onFilter: (value: string | number | boolean, record: DeliveryCompany) => {
        const manager = new DeliveryCompanyManager(record);
        return manager.isSameTypeWithGivenType(value as DeliveryCompanyType);
      },
    },
    {
      ...table.baseColumn("address"),
      ...table.getColumnSearchProps("address"),
      title: "住所",
      width: 266,
      render: (item) => {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[table.searchText]}
            autoEscape
            textToHighlight={item.address ?? "--"}
          />
        );
      },
    },
    {
      ...table.baseColumn("deliveryPartnerCompanies.delivery_partner_companies.id"),
      title: "担当運送会社",
      width: 266,
      render: (item) =>
        <div>
          {item.deliveryPartnerCompanies?.map((company: DeliveryPartnerCompany) =>
            <div key={company.id}>{company.name}</div>
          )}
        </div>,
      filters: props.deliveryPartnerCompanies?.map(company => ({
        text: company.name || '',
        value: company.id || ''
      })),
    },
    {
      ...table.baseColumn("updatedAt"),
      sorter: (a, b) => ((a.updatedAt ?? "") > (b.updatedAt ?? "") ? 1 : -1),
      title: "最終更新日",
      width: 127,
      render: (item) => dayjs(item.updatedAt).format("YYYY/MM/DD"),
    },
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 138,
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(
                `/operations/delivery-companies/${item.id}/edit`
              );
            }}
          >
            編集
          </CustomButton>
          {props.handleDelete && (
            <CustomButton
              style={{
                fontWeight: 500,
                padding: 0,
                height: "30px",
                width: "28px",
                textAlign: "left",
              }}
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                props.handleDelete!(item.id);
              }}
              danger
              confirm
              popconfirmProps={{
                title: "すでにカルテが作成されている行先については、紐づくカルテが削除されます。よろしいですか？",
              }}
            >
              削除
            </CustomButton>
          )}
        </Space>
      ),
    },
  ];
  return (
    <CustomTable
      tableKey="delivery_companies_table"
      table={table}
      pagination={table.paginationInfo}
      columns={columns}
      onChange={table.handleChange}
      onRow={(data, index) => ({
        onClick: () =>
          props.history.push(`/operations/delivery-companies/${data.id}`),
      })}
      {...props}
    />
  );
};
