import { ButtonProps, Progress, Skeleton, Space, theme } from "antd";
import { RightOutlined } from "@ant-design/icons";

import { CustomButton } from "specifics/button";
import { CSSProperties, ReactNode } from "react";

export const AnalysisBaseCardView = ({
  title,
  headerRight,
  children,
  style,
  loading,
}: {
  title?: string;
  headerRight?: ReactNode;
  children: JSX.Element;
  style?: CSSProperties;
  loading?: boolean;
}) => {
  const { token } = theme.useToken();
  return (
    <Space
      size={30}
      style={{
        width: 350,
        background: token.colorWhite,
        borderRadius: 16,
        padding: 24,
        ...style,
      }}
      direction="vertical"
    >
      <Space style={{ justifyContent: "space-between", width: "100%" }}>
        <div style={{ fontWeight: 700, fontSize: 20 }}>{title}</div>
        <div>{headerRight}</div>
      </Space>
      <Space
        direction="vertical"
        size={30}
        style={{
          textAlign: "center",
          width: "100%",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Skeleton loading={loading}>{children}</Skeleton>
      </Space>
    </Space>
  );
};

export const AnalysisDetailButton = (props: ButtonProps) => {
  return (
    <CustomButton {...props}>
      <span>詳細</span>
      <RightOutlined />
    </CustomButton>
  );
};
