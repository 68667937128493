import { Col, Divider, Row, Space } from "antd";
import { DeliveryTermView } from "components/delivery_companies/delivery_companies_view";

import { Consignor } from "entities/consignor";

type ConsignorCardViewProps = {
  consignor?: Consignor;
  style?: React.CSSProperties;
};

export const ConsignorCardView = (
  { consignor, style }: ConsignorCardViewProps
) => {
  return (
    <Space
      size={12}
      direction="vertical"
      style={{ width: "100%", ...style }}
    >
      <Row>
        <div style={{
          fontWeight: "bold",
          fontSize: "18px",
          lineHeight: "40px",
          padding: "0px 5px",
          width: "100%"
        }}>
          {consignor?.name}
        </div>
        <div style={{
          fontSize: "16px",
          padding: "0px 5px"
        }}>
          〒{consignor?.zipcode}<br />
          {consignor?.address}
        </div>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between">
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>ふりがな</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{consignor?.kana}</Col>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between">
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>荷渡し条件</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{DeliveryTermView(consignor?.deliveryTerm)}</Col>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between">
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>備考</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{consignor?.memo ?? "--"}</Col>
      </Row>
    </Space>
  );
};
