import { Col, Divider, Row, Space } from "antd";
import { DeliveryTerm } from "entities/contract";

import {
  DeliveryCompany,
  DeliveryCompanyType,
  DeliveryCompanyTypes,
} from "entities/delivery_company";
import { CustomTag } from "specifics/tag";

type DeliveryCompanyCardViewProps = {
  deliveryCompany?: DeliveryCompany;
  style?: React.CSSProperties;
};

export const DeliveryCompanyCardView = (
  {
    deliveryCompany,
    style,
  }: DeliveryCompanyCardViewProps
) => {
  return (
    <Space
      size={0}
      direction="vertical"
      style={style}
    >
      <Row style={{ padding: "8px 4px" }}>
        <div style={{
          fontWeight: "bold",
          fontSize: "18px",
          lineHeight: "40px",
          padding: "0px 5px",
          width: "100%"
        }}>
          {deliveryCompany?.name}
        </div>
        {deliveryCompany?.address &&
          <div style={{
            fontSize: "16px",
            padding: "0px 5px"
          }}>
            〒{deliveryCompany?.zipcode}<br />
            {deliveryCompany?.address}
          </div>
        }
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between" style={{ marginTop: 12, padding: "8px 4px" }}>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>積み下ろし区分</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{DeliveryCompanyTypeView(deliveryCompany?.type)}</Col>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between" style={{ marginTop: 12, padding: "8px 4px" }}>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>受入可能時間</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{deliveryCompany?.availableHours}</Col>
      </Row>
      <Divider style={{ margin: 0, borderWidth: 2 }} />
      <Row justify="space-between" style={{ marginTop: 12, padding: "8px 4px" }}>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>受入可能車両</Col>
        <Col style={{ fontSize: 16, padding: "0px 5px" }}>{deliveryCompany?.maxWeight}t</Col>
      </Row>
    </Space>
  );
};

export const DeliveryCompanyTypeView = (type?: DeliveryCompanyType) => {
  if (type === undefined) return "--";
  else return DeliveryCompanyTypes[type];
};

const DeliveryCompanyTypeTagViewBase = (type: DeliveryCompanyType) => {
  if (type === 1 || type === 2) {
    return (
      <CustomTag
        color={type === 2 ? "success" : "error"}
        style={{
          height: 24,
          borderRadius: 4,
          padding: "2px 6px 2px 6px",
          fontSize: "14px",
          lineHeight: "20px",
        }}
      >
        {DeliveryCompanyTypeView(type)}
      </CustomTag>
    );
  } else return <></>;
};

export const DeliveryCompanyTypeTagView = (item: DeliveryCompany) => {
  if (item.type === 1 || item.type === 2) {
    return DeliveryCompanyTypeTagViewBase(item.type);
  } else if (item.type === 3) {
    return (
      <Space direction="vertical">
        <>{DeliveryCompanyTypeTagViewBase(2)}</>
        <>{DeliveryCompanyTypeTagViewBase(1)}</>
      </Space>
    );
  } else {
    return <></>;
  }
};

export const DeliveryTermView = (deliveryTerm?: DeliveryTerm) => {
  return deliveryTerm !== undefined ? DeliveryTerm[deliveryTerm] : "--";
};

type TimeInterval = {
  start: number;
  end: number;
};

function parseTime(s: string): number {
  const [hour, minute] = s?.split(":")?.map(Number);
  return hour * 60 + minute;
}

function mergeIntervals(intervals: TimeInterval[]): TimeInterval[] {
  const sortedIntervals = intervals.sort((a, b) => a.start - b.start);
  const merged: TimeInterval[] = [sortedIntervals[0]];

  for (let i = 1; i < sortedIntervals.length; i++) {
    const last = merged[merged.length - 1];
    if (last.end < sortedIntervals[i].start) {
      merged.push(sortedIntervals[i]);
    } else if (last.end < sortedIntervals[i].end) {
      last.end = sortedIntervals[i].end;
    }
  }
  return merged;
}

export function totalAvailableHours(timeStr: string): string {
  const intervals: TimeInterval[] = timeStr
    ?.split(",")
    ?.filter((s) => s)
    ?.map((s) => {
      const [start, end] = s?.split("~");
      return { start: parseTime(start), end: parseTime(end) };
    });

  const merged = mergeIntervals(intervals);
  const totalMinutes = merged.reduce(
    (sum, { start, end }) => sum + end - start,
    0
  );

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}時間${minutes ? `${minutes}分` : ""}`;
}
