import { Alert, Button, Descriptions, Skeleton, Space, theme } from "antd";
import chroma from "chroma-js";

import { HistoryProps } from "routes/app";
import { CustomPageHeader } from "specifics/page_header";
import { useForm } from "utils/hooks";
import ReactJson from "react-json-view";
import { withRouter } from "react-router";
import { MetricsForm } from "./metrics_config_form";
import { AnalysisMetricsConfigDrawer } from "./metrics_config_form_drawer";
import { useContext, useEffect, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { usePostAnalysisConfigApi } from "api/analysis";
import { METRICS_CONFIG } from "./metrics_config";
import { ResponsiveContainer } from "recharts";
import { CustomBarChat } from "specifics/recharts/custom_bar_chat";
import dayjs from "dayjs";
import {
  NumberValidation,
  BaseValidation,
  StringValidation,
} from "specifics/input";
import { OpenMetricsHistoryDraerButton } from "./history/metrics_history_drawer";
import { MetricsHistoryManager } from "./history/metrics_history_manager";
import { MetricsFormViewModel } from "./metrics_form_view";
import { GlobalStateContext } from "contexts/global_state_context";
import DescriptionsItem from "antd/es/descriptions/Item";

const AnalysisMetricsPage = ({ history }: HistoryProps) => {
  const { token } = theme.useToken();

  const form = useForm<MetricsForm>({
    from: dayjs()
      .subtract(1, "months")
      .format(MetricsFormViewModel.DatetimeFormat),
    to: dayjs().format(MetricsFormViewModel.DatetimeFormat),
  });
  const validationResultForm = useForm<MetricsForm>({});
  const [formDrawerOpen, setFormDrwerOpen] = useState(true);
  const [historyDrawerOpen, setHistoryDrwerOpen] = useState(false);

  const globalState = useContext(GlobalStateContext);
  const postApi = usePostAnalysisConfigApi();
  useEffect(() => {
    globalState.setLoading(postApi.loading);
  }, [postApi.loading]);

  const metricConfig = useMemo(() => {
    const metricConfig_ = METRICS_CONFIG.find(
      (config) => config.metricName === postApi.response.data?.query?.name
    );
    return metricConfig_;
  }, [postApi.response.data?.query?.name]);

  const colorPallates = chroma
    .scale([token.colorPrimary, token.colorError])
    .colors(postApi.response.data?.query?.groups?.length || 1);

  const plotData = transformData(
    postApi.response.data?.metrics ?? [],
    postApi.response.data?.query?.groups ?? []
  );

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title={"詳細分析"}
      extra={[
        <Button
          key={"formModalOpenButton"}
          type="primary"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => {
            setFormDrwerOpen(true);
          }}
        />,
      ]}
    >
      <Skeleton loading={postApi.loading}>
        {!postApi.isSuccess() ? (
          <Alert type="info" message="分析条件を指定してください" />
        ) : (
          <Space
            size={30}
            style={{
              width: "100%",
              background: token.colorWhite,
              borderRadius: 16,
              padding: 24,
            }}
            direction="vertical"
          >
            <Space style={{ justifyContent: "space-between", width: "100%" }}>
              <div style={{ fontWeight: 700, fontSize: 20 }}>
                {postApi.response.data?.query?.nameJp}
              </div>
            </Space>
            <Descriptions size="small" column={3}>
              <Skeleton loading={!postApi.response.data?.query}>
                <Descriptions.Item label="期間">
                  {MetricsFormViewModel.fromToDateView(
                    postApi.response.data?.query
                  )}
                  {MetricsFormViewModel.rangeView(postApi.response.data?.query)}
                </Descriptions.Item>
                <Descriptions.Item label="グルーピング">
                  <Space>
                    {MetricsFormViewModel.groupingView(
                      postApi.response.data?.query
                    )}
                  </Space>
                </Descriptions.Item>
              </Skeleton>
            </Descriptions>
            <ResponsiveContainer width={"100%"} height={300}>
              <CustomBarChat
                fill={(payload, hover, group) => {
                  return colorPallates[
                    postApi.response.data?.query?.groups
                      ?.map((group) => group.label)
                      .indexOf(group as string) ?? 0
                  ];
                }}
                valueKey={
                  postApi.response.data?.query?.groups?.map(
                    (group) => group.label
                  ) || []
                }
                height={250}
                data={plotData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                xAxisLabelKey="datetime"
                yaxisProps={{
                  domain: metricConfig?.unit === "%" ? [0, 100] : undefined,
                  hide: false,
                  unit: metricConfig?.unit,
                }}
                getTooltipLabel={(item) =>
                  dayjs(item.payload.datetime).format("YYYY年MM月DD日")
                }
                getTooltipBody={(item) => {
                  return (
                    <Space direction="vertical">
                      {postApi.response.data?.query?.groups?.map((group) => {
                        return (
                          <div
                            key={group.value as string}
                            style={{
                              color:
                                colorPallates[
                                  postApi.response.data?.query?.groups
                                    ?.map((g) => g.label)
                                    ?.indexOf(group.label as string) ?? 0
                                ],
                            }}
                          >
                            {group.label}:{" "}
                            {item.payload[group.label].toFixed(1)}
                            {metricConfig?.unit}
                          </div>
                        );
                      })}
                    </Space>
                  );
                }}
                showLegend
                grid
              />
            </ResponsiveContainer>
            {postApi.isSuccess() &&
              process.env.REACT_APP_ENV_MODE === "DEVELOPMENT" && (
                <DescriptionsItem label="API Response">
                  <ReactJson
                    src={postApi.response.data as Record<string, unknown>}
                  />
                </DescriptionsItem>
              )}
          </Space>
        )}
      </Skeleton>
      <AnalysisMetricsConfigDrawer
        onClickHistory={(metricsConfigHistory) => {
          form.set(metricsConfigHistory);
          setHistoryDrwerOpen(false);
        }}
        open={formDrawerOpen}
        extra={
          <OpenMetricsHistoryDraerButton
            key="openMetricsHistoryDraerButton"
            onClick={() => {
              setFormDrwerOpen(true);
              setHistoryDrwerOpen(true);
            }}
          />
        }
        onPostForm={() => {
          form.setValidate(true);
          if (
            Object.keys(validationResultForm.object).every(
              (key) =>
                validationResultForm.object[key as keyof MetricsForm] ===
                undefined
            )
          ) {
            postApi.execute(form.object);
            MetricsHistoryManager.setHistories(form.object);
            setFormDrwerOpen(false);
          }
        }}
        historyDraawerOpen={historyDrawerOpen}
        setHistoryDraawerOpen={(newState: boolean) =>
          setHistoryDrwerOpen(newState)
        }
        onClose={() => setFormDrwerOpen(false)}
        form={form}
        validationResultForm={validationResultForm}
      />
    </CustomPageHeader>
  );
};

export default withRouter(AnalysisMetricsPage);

const transformData = (
  data: Record<string, number>[],
  mapping: { label: string; value: string }[]
) => {
  return data.map((item) => {
    const transformed: Record<string, number> = {
      datetime: item.datetime,
    };

    mapping.forEach((map) => {
      transformed[map.label] = item[map.value];
    });

    return transformed;
  });
};
