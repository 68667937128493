import { ID } from ".";

export type Setting = {
  customSetting?: CustomSetting;
  defaultSetting?: { [category: string]: DefaultSetting[] };
};

export type SettingForm = {
  content?: { [key: string]: string | string[] | number };
};

export enum SettingType {
  "number" = "number",
  "text" = "text",
  "textarea" = "textarea",
  "select" = "select",
  "multipleSelect" = "multiple_select",
}

export type CustomSetting = {
  requestMail: string;
  followUpPeriodMonths: number;
  followUpMail: string;
  reminderPeriodDays: number;
  reminderMail: string;
  mailToUsers: string[];
  qrExpiration: number;
  urlExpiration: number;
  [key: string]: string | string[] | number;
}

export type DefaultSetting = {
  id?: ID;
  category: string;
  key?: string;
  label?: string;
  description?: string;
  value?: string | string[] | number;
  type?: SettingType;
  items?: { [key: string]: string | number };
};
