import {
  ApiSet,
  BaseResponse,
  BulkPostResponse,
  CountsApiResponse,
  IndexApiSet,
  useDeleteApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import {
  DeliveryCompany,
  DeliveryCompanyForm,
  DeliveryCompanySearchForm,
} from "entities/delivery_company";
import { ID, PagingResponse } from "entities";
import { DeliveryTerm } from "entities/contract";

type AllDeliveryCompaniesResponse = BaseResponse<DeliveryCompany[]>;

export function useFetchAllDeliveryCompaniesApi(): ApiSet<AllDeliveryCompaniesResponse> & {
  execute: () => void;
} {
  const api = useIndexApi<AllDeliveryCompaniesResponse>(new HttpClient(), {
    initialResponse: { data: [] },
  });

  const execute = (): void => {
    const apiPath = `delivery_company/all`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export type DeliveryCompaniesResponse = PagingResponse<DeliveryCompany>;

export function useFetchdeliveryCompaniesApi(
  searchForm?: Form<DeliveryCompanySearchForm>
): IndexApiSet<DeliveryCompaniesResponse> & { execute: () => void } {
  const apiPath = "delivery_company";
  const api = useIndexApi<DeliveryCompaniesResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type DeliveryCompanyResponse = BaseResponse & {
  data: DeliveryCompany;
};

export function useFetchDeliveryCompanyApi(): ApiSet<DeliveryCompanyResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<DeliveryCompanyResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `delivery_company/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export type FilterDeliveryCompanyConfig = {
  hasManuals?: boolean;
  deliveryTerm?: DeliveryTerm;
  existNeedTask?: boolean;
  hasNeedTaskMismatchedManuals?: boolean;
  hasDeliveryTermsMismatchedManuals?: boolean;
};

type CoutnsDeliveryCompanyConfig = FilterDeliveryCompanyConfig & {
  givenTimes: string[];
};

export function useCountsDeliveryCompanyApi(
  config: CoutnsDeliveryCompanyConfig
): ApiSet<CountsApiResponse> & {
  execute: () => void;
} {
  const apiPath = `delivery_company/counts`;
  const api = usePostObjectApi<CountsApiResponse, CoutnsDeliveryCompanyConfig>(
    new HttpClient(),
    {
      initialResponse: {
        data: [],
      },
    }
  );

  const execute = (): void => {
    api.execute(apiPath, config);
  };

  return { ...api, execute };
}

export function useFetchFilterAllDeliveryCompaniesApi(): ApiSet<AllDeliveryCompaniesResponse> & {
  execute: (config?: FilterDeliveryCompanyConfig) => void;
} {
  const apiPath = `delivery_company/filterall`;
  const api = usePostObjectApi<
    AllDeliveryCompaniesResponse,
    FilterDeliveryCompanyConfig
  >(new HttpClient(), {
    initialResponse: {
      data: [],
    },
  });

  const execute = (config?: FilterDeliveryCompanyConfig): void => {
    api.execute(apiPath, config);
  };

  return { ...api, execute };
}

export function usePostDeliveryCompanyApi(): ApiSet<DeliveryCompanyResponse> & {
  execute: (form: Form<DeliveryCompanyForm>) => void;
} {
  const apiSet = usePostApi<DeliveryCompanyResponse, DeliveryCompanyForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<DeliveryCompanyForm>) => {
    const apiPath = "delivery_company";
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditDeliveryCompanyApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<DeliveryCompanyForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, DeliveryCompanyForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<DeliveryCompanyForm>) => {
    const apiPath = `delivery_company/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteDeliveryCompanyApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `delivery_company/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export function usePostDeliveryCompaniesApi(): ApiSet<
  BulkPostResponse<DeliveryCompany>
> & {
  execute: (
    deliveryCompanies: DeliveryCompanyForm[]
  ) => Promise<BulkPostResponse<DeliveryCompany>>;
} {
  const apiSet = usePostObjectApi<
    any,
    { deliveryCompanies: DeliveryCompanyForm[] }
  >(
    new HttpClient(),
    {
      initialResponse: {
        meta: { successCount: 0, failCount: 0 },
        data: [],
      },
    },
    { formatJson: true }
  );

  const execute = async (
    deliveryCompanies: DeliveryCompanyForm[]
  ): Promise<BulkPostResponse<DeliveryCompany>> => {
    const apiPath = `delivery_company/bulk`;
    const result = await apiSet.execute(apiPath, { deliveryCompanies });
    return result.data;
  };

  return { ...apiSet, execute };
}
