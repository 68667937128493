import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useIndexApi,
  usePostApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import { Template, TemplateForm, TemplateSearchForm } from "entities/template";
import { ID, PagingResponse } from "entities";

type AllTemplatesResponse = BaseResponse<Template[]>;

export type TemplatesResponse = PagingResponse<Template>;

export function useFetchAllTemplatesApi(
  searchForm?: Form<TemplateSearchForm>
): IndexApiSet<AllTemplatesResponse> & { execute: () => void } {
  const apiPath = "template/all";
  const api = useIndexApi<AllTemplatesResponse>(new HttpClient(), {
    initialResponse: { data: [] },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (): void => {
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export type TemplateResponse = BaseResponse<Template>;

export function useFetchTemplateApi(): ApiSet<TemplateResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<TemplateResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `template/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function useFetchTemplatesApi(
  searchForm?: Form<TemplateSearchForm>
): IndexApiSet<TemplatesResponse> & { execute: (query?: string) => void } {
  const apiPath = "template";
  const api = useIndexApi<TemplatesResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export function useFetchTemplateViewApi(): ApiSet<TemplateResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<TemplateResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `template-view/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostTemplateApi(): ApiSet<TemplateResponse> & {
  execute: (form: Form<TemplateForm>) => void;
} {
  const apiSet = usePostApi<TemplateResponse, TemplateForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<TemplateForm>) => {
    const apiPath = `template`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditTemplateApi(): ApiSet<BaseResponse> & {
  execute: (form: Form<TemplateForm>) => void;
} {
  const apiSet = usePostApi<BaseResponse, TemplateForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (form: Form<TemplateForm>) => {
    const apiPath = `template/${form.object.id}`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useDeleteTemplateApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `template/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}
