import React, { ReactNode, useContext, useEffect, useState } from "react";
import { GlobalStateContext } from "contexts/global_state_context";
import { ApiError } from "utils/network/api_hooks";
import { User } from "entities/user";

import { Spin } from "antd";
import { Setting } from "entities/setting";

export type ConfirmOption = {
  confirmWord?: string;
  backdropStatic?: boolean;
  onCancel?: () => void;
  hideCancel?: boolean;
};

export type Dimension = {
  width: number;
  height: number;
};

type GlobalStateContainerProps = {
  children: ReactNode;
};

const GlobalStateContainer: React.FC<GlobalStateContainerProps> = (
  props: GlobalStateContainerProps
) => {
  const [setting, setSetting] =  React.useState<Setting>({});
  const [user, setUser] = React.useState<User>({});
  const [users, setUsers] = React.useState<User[]>([]);
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [apiError, setApiError] = React.useState<ApiError>({});
  const [confirm, setConfirm] = React.useState<{
    message: ReactNode;
    callback?: () => void;
    option?: ConfirmOption;
  }>({
    message: <></>,
    callback: () => {},
  });
  const [showToast, setShowToast] = React.useState(false);
  const [issueCount, setIssueCount] = React.useState(0);
  const [notificationsCount, setNotificationsCount] = React.useState(0);

  const location = window.location;

  const showSider = (): boolean => {
    return (
      process.env.REACT_APP_TYPE === "ADMIN" &&
      !(
        location.pathname.includes("create") ||
        location.pathname.includes("edit") ||
        location.pathname.includes("login") ||
        location.pathname.includes("password") ||
        location.pathname === "/"
      )
    );
  };

  const getWindowDimensions = (): Dimension => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [dimension, setDimension] = useState<Dimension>(getWindowDimensions());

  useEffect(() => {
    const onResize = () => {
      setDimension(getWindowDimensions());
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <GlobalStateContext.Provider
      value={{
        setting: setting,
        setSetting: setSetting,
        user: user,
        setUser: setUser,
        users,
        setUsers,
        loading: loading,
        setLoading: setLoading,
        apiError: apiError,
        setError: setApiError,
        collapsed: collapsed,
        setCollapsed: setCollapsed,
        dimension: dimension,
        setDimension: setDimension,
        issueCount,
        setIssueCount,
        notificationsCount,
        setNotificationsCount,
      }}
    >
      <div>
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: `calc(100vw - ${showSider() ? "190px" : "0px"})`,
              height: "100vh",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#8888",
              zIndex: 1000000,
              paddingLeft: showSider() ? 190 : 0,
            }}
          >
            <Spin style={{ width: "100%", textAlign: "center" }} size="large" />
          </div>
        )}
        {props.children}
      </div>
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateContainer;
