import { Col, Row, Skeleton, Space, theme } from "antd";
import { DriverManualInfo, DriverManualInfoAttrs, Manual, ManualKey } from "entities/manual";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { Form, useEffectSkipFirst, useForm, useQuery } from "utils/hooks";

import { useFetchTemplateApi } from "api/template";
import { GlobalStateContext } from "contexts/global_state_context";
import { useFetchAllDeliveryCompaniesApi } from "api/delivery_company";
import { useFetchAllConsignorsApi } from "api/consignor";
import {
  useEditManualApi,
  useFetchManualApi,
  usePostManualApi,
} from "api/manual";
import { Template } from "entities/template";
import { ID } from "entities";
import { ManualForm } from "components/shared/manual_form";
import { useFetchCurrentCompanyApi } from "api/company";
import { Company, CompanyManualDisplay, CompanyManualType } from "entities/company";
import { DeliveryCompany } from "entities/delivery_company";
import { Consignor } from "entities/consignor";
import { useFetchAllDeliveryPartnerCompaniesApi } from "api/delivery_partner_company";

const DriversManualPageFooter = ({
  form,
  isEdit,
  companyManualType,
  _key,
  setKey,
  infoIndexState,
  setInfoIndexState,
  stepIndexState,
  setStepIndexState,
  validationResultForm,
  template,
  onSubmit,
}: {
  form: Form<Manual>;
  validationResultForm: Form<Manual>;
  isEdit?: boolean;
  companyManualType?: CompanyManualType;
  _key: ManualKey;
  setKey: (key: ManualKey) => void;
  infoIndexState: DriverManualInfo;
  setInfoIndexState: (infoIndexState: DriverManualInfo) => void;
  stepIndexState: number;
  setStepIndexState: (stepIndexState: number) => void;
  template: Template;
  onSubmit: () => void;
}) => {
  const stepCount = (): number =>
    template?.steps?.filter((step) =>
      form.object.selectedStepNos?.includes(step.stepNo ?? 0)
    ).length ?? 0;
  const enableNextButton = (): boolean => {
    switch (_key) {
      case ManualKey.基本情報: {
        const keys = DriverManualInfoAttrs[DriverManualInfo[infoIndexState] as keyof typeof DriverManualInfoAttrs]
        return keys.every((key) =>
          key === "manualDetails" || key === "templateId"
            ? true
            : validationResultForm.object[key as keyof Manual] === undefined
        );
      }
      case ManualKey.手順: {
        if (stepIndexState === -1) {
          // テンプレート選択画面
          return true;
        }
        const details = validationResultForm.object.manualDetails;

        // detailsが未定義、または空の場合は直ちにtrueを返す
        if (!details?.length) return true;

        const currentDetail = details[stepIndexState];
        const allProcessesUndefined = !!currentDetail.manualProcesses?.every(process => process.value === undefined);
        const commentUndefined = currentDetail.comment === undefined;

        // すべてのプロセスのvalueがundefinedであり、かつcommentもundefinedの場合にのみtrueを返す
        return allProcessesUndefined && commentUndefined;
      }
      case ManualKey.構内マップ: {
        const points = validationResultForm.object.points;

        // pointsが未定義、または空の場合は直ちにtrueを返す
        if (!points?.length) return true;

        const allPointsUndefined = points.every(point => Object.values(point).every(val => val === undefined));
        return allPointsUndefined;
      }
      default:
        return false;
    }
  };
  const displayNextButton = (): boolean => {
    if (isEdit) return true;
    switch (_key) {
      case ManualKey.基本情報: {
        return infoIndexState !== DriverManualInfo.附帯業務 || CompanyManualDisplay(companyManualType, ManualKey.手順);
      }
      case ManualKey.手順: {
        return stepIndexState < stepCount() - 1 || CompanyManualDisplay(companyManualType, ManualKey.構内マップ);
      }
      case ManualKey.構内マップ: {
        return true;
      }
      default:
        return false;
    }
  }
  const displayBackButton = (): boolean => {
    if (isEdit) return false;
    switch (_key) {
      case ManualKey.基本情報: {
        return infoIndexState !== DriverManualInfo.基本情報;
      }
      case ManualKey.手順: {
        return true;
      }
      case ManualKey.構内マップ: {
        return CompanyManualDisplay(companyManualType, ManualKey.手順);
      }
      default:
        return false;
    }
  }

  return (
    <Space
      style={{
        width: "calc(100vw - 20px)",
        flexDirection: "row-reverse",
        margin: 10,
        background: "white",
      }}
    >
      {/* 『送信』『次へ』ボタン */}
      {displayNextButton() &&
        <CustomButton
          type="primary"
          style={{ width: 120, height: 50 }}
          disabled={!enableNextButton() && form.validate}
          onClick={() => {
            form.setValidate(true);
            if (enableNextButton()) {
              if (isEdit) {
                onSubmit();
              } else {
                switch (_key) {
                  case ManualKey.基本情報:
                    infoIndexState === DriverManualInfo.附帯業務 || (!CompanyManualDisplay(companyManualType, ManualKey.基本情報) && infoIndexState === DriverManualInfo.納入カルテ名) ?
                      setKey(ManualKey.手順) :
                      setInfoIndexState(infoIndexState + 1);
                    setStepIndexState(-1);
                    infoIndexState !== DriverManualInfo.基本情報 && form.setValidate(false);
                    break;
                  case ManualKey.手順:
                    stepIndexState < stepCount() - 1 && setStepIndexState(stepIndexState + 1);
                    stepIndexState === stepCount() - 1 && setKey(ManualKey.構内マップ);
                    form.setValidate(false);
                    break;
                  case ManualKey.構内マップ:
                    onSubmit();
                    break;
                  default:
                    console.log("Unhandled case");
                }
              }
              form.setValidate(false);
              window.scrollTo({ top: 0 });
            }
          }}
        >
          {(isEdit || _key === ManualKey.構内マップ) ? "送信" : "次へ"}
        </CustomButton>
      }
      {
        // 作成時の基本情報入力までの『送信』ボタン
        _key === ManualKey.基本情報 && infoIndexState === DriverManualInfo.附帯業務 && !isEdit && (
          <CustomButton
            type="primary"
            style={{ width: 110, height: 50 }}
            onClick={() => {
              form.setValidate(true);
              if (enableNextButton()) {
                onSubmit();
                form.setValidate(false);
              }
            }}
          >
            送信
          </CustomButton>
        )
      }
      {displayBackButton() && (
        <CustomButton
          style={{ width: 110, height: 50 }}
          onClick={() => {
            switch (_key) {
              case ManualKey.基本情報:
                setInfoIndexState(infoIndexState - 1);
                break;
              case ManualKey.手順:
                if (stepIndexState === -1) setKey(ManualKey.基本情報);
                else setStepIndexState(stepIndexState - 1);
                break;
              case ManualKey.構内マップ:
                setKey(ManualKey.手順);
                break;
              default:
                console.log("Unhandled case");
            }
          }}
        >
          戻る
        </CustomButton>
      )
      }
    </Space >
  );
};

export const DriversManualsCreatePageInner = (
  props: HistoryProps & { deliveryCompanyId?: ID; consignorId?: ID, manualId?: ID, deliveryPointCompanyId?: ID, guest?: boolean }
) => {
  const { token } = theme.useToken();
  const topRef = useRef<HTMLDivElement>(null);
  const [key, setKey] = useState<ManualKey>(ManualKey.基本情報);

  const [infoIndexState, setInfoIndexState] = useState<DriverManualInfo>(DriverManualInfo.基本情報);
  const [stepIndexState, setStepIndexState] = useState<number>(-1); // 必要手順確認画面を含むため-1

  useEffect(() => {
    if (topRef.current) {
      topRef.current?.scrollIntoView();
    }
  }, [infoIndexState, stepIndexState]);

  const form = useForm<Manual>({
    consignorId: props.consignorId,
    deliveryCompanyId: props.deliveryCompanyId,
    manualId: props.manualId,
    deliveryPointCompanyId: props.deliveryPointCompanyId,
    points: []
  });

  const validationResultForm = useForm<Manual>({});

  const globalState = useContext(GlobalStateContext);

  const deliveryCompaniesApi = useFetchAllDeliveryCompaniesApi();
  const deliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const consignorsApi = useFetchAllConsignorsApi();
  const companyApi = useFetchCurrentCompanyApi();
  const templateApi = useFetchTemplateApi();

  const template = (): Template => {
    return templateApi.response.data ?? {};
  };

  const company = (): Company => {
    return companyApi.response.data ?? {};
  };

  const deliveryCompanies = (): DeliveryCompany[] => {
    return deliveryCompaniesApi.response.data || [];
  };

  const deliveryPartnerCompanies = (): DeliveryCompany[] => {
    return deliveryPartnerCompaniesApi.response.data || [];
  };

  const consignors = (): Consignor[] => {
    return consignorsApi.response.data || [];
  };

  useEffect(() => {
    deliveryCompaniesApi.execute();
    deliveryPartnerCompaniesApi.execute();
    consignorsApi.execute();
    companyApi.execute();
  }, []);

  useEffect(() => {
    if (deliveryCompaniesApi.isSuccess() && consignorsApi.isSuccess() &&
      (form.object.consignorId || form.object.deliveryCompanyId)) {
      const deliveryCompany = deliveryCompanies()?.find(company => company.id === form.object.deliveryCompanyId);
      const deliveryPointCompany = deliveryCompany?.deliveryCompanies?.find(company => company.id === form.object.deliveryPointCompanyId);
      const deliveryPartnerCompany = deliveryCompany?.deliveryPartnerCompanies?.length === 1 ? deliveryCompany?.deliveryPartnerCompanies[0] : undefined;
      const consignor = consignors()?.find(company => company.id === form.object.consignorId);
      form.update((f) => {
        f.deliveryCompany = deliveryCompany;
        f.deliveryPointCompany = deliveryPointCompany;
        f.deliveryPartnerCompanyId = deliveryPartnerCompany?.id;
        f.deliveryPartnerCompany = deliveryPartnerCompany;
        f.consignor = consignor;
      });
    }
  }, [deliveryCompaniesApi.loading, consignorsApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(
      templateApi.loading || companyApi.loading
    );
    if (template()) {
      form.set({
        ...form.object,
        templateId: form.object.templateId ? form.object.templateId : company()?.defaultTemplateId,
        selectedStepNos: template().steps?.map(step => step.stepNo ?? 0),
        manualDetails: template().steps?.filter(step => form.object.selectedStepNos?.includes(step.stepNo ?? 0)).map((step) => ({
          stepNo: step?.stepNo,
          picture1: undefined,
          manualProcesses: step?.questions?.map((question) => ({
            questionNo: question.questionNo,
            questionId: question.id,
            value: "",
          })),
        })),
      });
    }
  }, [templateApi.loading || companyApi.loading]);

  useEffect(() => {
    if (form.object.templateId) {
      templateApi.execute(form.object.templateId);
    }
  }, [form.object.templateId]);

  const postApi = usePostManualApi();

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      if (props.guest) {
        props.history.push("/confirmation", { title: "納入カルテの作成" });
      } else {
        const redirectTo = () => {
          if (props.deliveryCompanyId || props.consignorId) {
            if (props.manualId) {
              return `/drivers/manuals/${props.manualId}`;
            }
            return `/drivers/delivers`;
          }
          return `/drivers/manuals/${postApi.response.data.id}`;
        };

        props.history.push(redirectTo());
      }
    }
  }, [postApi.loading]);

  return (
    <Space
      style={{
        minHeight: "calc(100vh - 75px)",
        paddingTop: 20,
      }}
      direction="vertical"
      size={0}
      ref={topRef}
    >
      {deliveryCompanies().length && consignors().length && company().manualType && (
        <ManualForm
          form={form}
          validationResultForm={validationResultForm}
          template={template()}
          templates={company()?.templates}
          companyManualType={company().manualType}
          _key={key}
          isDriver
          infoIndexState={infoIndexState}
          stepIndexState={stepIndexState}
          setStepIndexState={setStepIndexState}
          deliveryCompanies={deliveryCompanies()}
          deliveryPartnerCompanies={deliveryPartnerCompanies()}
          consignors={consignors()}
          guest={props.guest}
        />
      )}
      <DriversManualPageFooter
        isEdit={false}
        form={form}
        companyManualType={company().manualType}
        validationResultForm={validationResultForm}
        _key={key}
        setKey={setKey}
        infoIndexState={infoIndexState}
        setInfoIndexState={setInfoIndexState}
        stepIndexState={stepIndexState}
        setStepIndexState={setStepIndexState}
        template={template() || {}}
        onSubmit={() => postApi.execute(form)}
      />
    </Space>
  );
};

export const DriversManualsCreatePage = withRouter(
  DriversManualsCreatePageInner
);

export const DriversManualsEditPageInner = (props: HistoryProps & { _key: ManualKey, onEdit?: () => void, manualId: ID, guest?: boolean }) => {
  const { token } = theme.useToken();

  const [key, setKey] = useState<ManualKey>(props._key ?? ManualKey.基本情報);

  const [infoIndexState, setInfoIndexState] = useState<DriverManualInfo>(DriverManualInfo.基本情報);
  const [stepIndexState, setStepIndexState] = useState<number>(0);

  const globalState = useContext(GlobalStateContext);
  const manualApi = useFetchManualApi();
  const templateApi = useFetchTemplateApi();
  const deliveryCompaniesApi = useFetchAllDeliveryCompaniesApi();
  const deliveryPartnerCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi();
  const consignorsApi = useFetchAllConsignorsApi();
  const form = useForm<Manual>({});
  const validationResultForm = useForm<Manual>({});
  const editApi = useEditManualApi();

  useEffect(() => {
    deliveryCompaniesApi.execute();
    deliveryPartnerCompaniesApi.execute();
    consignorsApi.execute();
    manualApi.execute(props.manualId);
  }, []);

  useEffectSkipFirst(() => {
    if (props._key !== undefined) {
      setKey(props._key);
    }
  }, [props._key]);

  useEffectSkipFirst(() => {
    globalState.setLoading(manualApi.loading);
    if (manualApi.isSuccess() && manualApi.response.data.templateId) {
      templateApi.execute(manualApi.response.data.templateId);
    }
  }, [manualApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(templateApi.loading);
    if (templateApi.isSuccess()) {
      // templateApiからstepのidの配列を取得
      const stepIds =
        templateApi.response.data?.steps?.map((step) => step.id) ?? [];
      // manualDetailsからstepIdがstepIdsに含まれる要素だけをフィルター
      const filteredManualDetails =
        manualApi.response.data.manualDetails?.filter((detail) =>
          stepIds.includes(detail.stepId)
        ) ?? [];
      form.set({
        ...manualApi.response.data,
        manualDetails: filteredManualDetails,
        selectedStepNos: templateApi.response.data?.steps?.map(
          (step) => step.stepNo ?? 0
        ),
      });
    }
  }, [templateApi.loading]);

  const template: Template = useMemo(() => {
    globalState.setLoading(templateApi.loading);
    if (templateApi.isSuccess()) {
      return templateApi.response.data || {};
    } else return {};
  }, [templateApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.onEdit && props.onEdit();
    }
  }, [editApi.loading]);

  return (
    <Space
      style={{
        background: token.colorBgBase,
        minHeight: props._key !== undefined ? "100%" : "100vh",
        paddingTop: props._key !== undefined ? 0 : 60,
      }}
      direction="vertical"
      size={0}
    >
      <Skeleton loading={form.isEmpty()}>
        {/* 手順選択タブ */}
        {key === ManualKey.手順 && (
          <Row
            style={{
              background: token.colorWhite,
              paddingTop: 10,
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            {template?.steps?.map((step, stepIndex) => (
              <Col key={stepIndex} style={{ textAlign: "center", flex: 1 }}>
                <div
                  style={{
                    fontWeight: stepIndexState === stepIndex ? 700 : 500,
                    fontSize: "16px",
                    lineHeight: "20px",
                    padding: "0px 4px 16px 4px",
                    color:
                      stepIndexState === stepIndex
                        ? token.colorPrimary
                        : "#6B7280",
                    borderBottom:
                      stepIndexState === stepIndex
                        ? `3px solid ${token.colorPrimary}`
                        : "none",
                  }}
                  onClick={() => setStepIndexState(stepIndex)}
                >
                  {step.stepName}
                </div>
              </Col>
            ))}
          </Row>
        )}
        <ManualForm
          form={form}
          validationResultForm={validationResultForm}
          template={template}
          _key={key}
          isDriver
          isEdit
          infoIndexState={infoIndexState}
          stepIndexState={stepIndexState}
          setStepIndexState={setStepIndexState}
          deliveryCompanies={deliveryCompaniesApi.response.data}
          deliveryPartnerCompanies={deliveryPartnerCompaniesApi.response.data}
          consignors={consignorsApi.response.data}
        />
      </Skeleton>
      <DriversManualPageFooter
        isEdit
        form={form}
        validationResultForm={validationResultForm}
        _key={key}
        setKey={setKey}
        infoIndexState={infoIndexState}
        setInfoIndexState={setInfoIndexState}
        stepIndexState={stepIndexState}
        setStepIndexState={setStepIndexState}
        template={template}
        onSubmit={() => {
          editApi.execute(form);
        }}
      />
    </Space>
  );
};
