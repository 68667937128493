import { notification, Space, Table, theme } from "antd";
import {
  useEditConsignorApi,
  useFetchConsignorApi,
  useFetchConsignorsApi,
  usePostConsignorApi,
} from "api/consignor";
import { useFetchUsersApi } from "api/user";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  Consignor,
  ConsignorSearchForm,
} from "entities/consignor";
import { DeliveryTerm } from "entities/contract";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomContentFooter } from "specifics/footer";
import {
  CustomInputField,
  CustomSelectField,
  CustomTextAreaField,
  KanaValidation,
  StringValidation,
  TelValidation,
  ZipCodeValidation,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { Form, useEffectSkipFirst, useForm, useQuery } from "utils/hooks";
import { findAddressByZip, hankaku2Zenkaku } from "utils/util";

const FormView = (form: Form<Consignor>, validationResultForm: Form<any>) => {
  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "name", true);
      KanaValidation(f, form, "kana", true);
      ZipCodeValidation(f, form, "zipcode", true);
      StringValidation(f, form, "address", true);
      StringValidation(f, form, "deliveryTerm", true);
      StringValidation(f, form, "repName");
      TelValidation(f, form, "repTel");
    });
  }, [JSON.stringify(form.object)]);
  return (
    <Space style={{ width: "100%" }} size={16} direction="vertical">
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="名称"
        attr="name"
        required
      />
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="ふりがな"
        attr="kana"
        required
      />
      <Space direction="vertical">
        <CustomInputField
          validationResultForm={validationResultForm}
          form={form}
          label="郵便番号"
          onChange={(e) => {
            const value = hankaku2Zenkaku(e.target.value);
            console.log(
              value,
              value.match(/^\d{1,3}-?\d{0,4}$/),
              form.getValue("zipcode")
            );
            if (
              e.target.value.length === 0 ||
              value.match(/^\d{1,3}-?\d{0,4}$/)
            ) {
              form.updateObject(
                "zipcode",
                value.length > 3 && value[3] !== "-" && !value.includes("-")
                  ? value.slice(0, 3) + "-" + value.slice(3)
                  : value.slice(0, 8)
              );
            } else {
              console.log("invalid");
            }
          }}
          attr="zipcode"
          required
          style={{ width: 180 }}
        />
        {/* <CustomButton
          style={{ height: 32, width: 180 }}
          onClick={async () => {
            globalState.setLoading(true);
            const res = await findAddressByZip(form.object.zipcode ?? "");
            globalState.setLoading(false);
            if (res.status === 200) {
              if (res.data.prefecture && res.data.city && res.data.street) {
                form.updateObject(
                  "address",
                  `${res.data.prefecture}${res.data.city}${res.data.street}`
                );
              } else {
                notification.error({
                  message: `Error`,
                  description: "郵便番号が見つかりませんでした。",
                });
              }
            } else {
              notification.error({
                message: `Error (Status Code is ${res.status})`,
                description: res.message,
              });
            }
          }}
          type="primary"
        >
          郵便番号から住所を検索
        </CustomButton> */}
      </Space>
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="住所"
        attr="address"
        required
      />
      <CustomSelectField
        form={form}
        label="荷渡し条件"
        validationResultForm={validationResultForm}
        attr="deliveryTerm"
        required
        selectItems={Object.keys(DeliveryTerm).filter(key => isNaN(Number(key))).map((cnt, index) => ({
          label: cnt,
          value: index,
        }))}
        style={{ width: 180 }}
      />
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="担当者氏名"
        attr="repName"
        style={{ width: 223 }}
      />
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="電話番号"
        onChange={(e) => {
          const value = hankaku2Zenkaku(e.target.value);
          if (value.match(/^\d{0,13}$/)) {
            form.updateObject("repTel", value.slice(0, 13));
          } else {
            console.log("invalid");
          }
        }}
        attr="repTel"
        style={{ width: 223 }}
      />
      <CustomTextAreaField
        validationResultForm={validationResultForm}
        form={form}
        label="備考"
        attr="memo"
      />
    </Space>
  );
};

const _ConsignorsCreatePage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const query = useQuery<{ isTemporary?: boolean }>();
  const globalState = useContext(GlobalStateContext);
  const form = useForm<Consignor>({});
  const validationResultForm = useForm<Record<string, string | undefined>>({});
  const postApi = usePostConsignorApi();

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      if (query.isTemporary) {
        window.close();
      } else {
        props.history.goBack();
      }
    }
  }, [postApi.loading]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="荷主登録"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/operations/consignors")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) => validationResultForm.object[key] === undefined
                )
              ) {
                postApi.execute(form);
              }
            }}
          >
            登録
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.goBack()}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

const _ConsignorsEditPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const consignorApi = useFetchConsignorApi();
  const params = useParams<{ id: string }>();
  const form = useForm<Consignor>({});
  useEffect(() => {
    consignorApi.execute(params.id);
  }, []);
  const editApi = useEditConsignorApi();
  const validationResultForm = useForm<Record<string, string | undefined>>({});

  useEffectSkipFirst(() => {
    globalState.setLoading(consignorApi.loading);
    if (consignorApi.isSuccess()) {
      form.set(consignorApi.response.data);
    }
  }, [consignorApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.history.push(`/operations/consignors/${params.id}`);
    }
  }, [editApi.loading]);

  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="荷主編集"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/operations/consignors")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) => validationResultForm.object[key] === undefined
                )
              ) {
                editApi.execute(form);
              }
            }}
          >
            保存
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.goBack()}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const ConsignorsEditPage = withRouter(_ConsignorsEditPage);

export const ConsignorsCreatePage = withRouter(_ConsignorsCreatePage);
