import { CookieManager } from "utils/cookie_manager";
import { MetricsForm } from "../metrics_config_form";

const MAX_HISTORIES_COUNT = 10 as const;

export class MetricsHistoryManager {
  static setHistories = (form: MetricsForm) => {
    const histories: MetricsForm[] = CookieManager.getMetricsHistories();
    const newHistories = [
      form,
      ...histories.filter(
        (history) => JSON.stringify(history) !== JSON.stringify(form)
      ),
    ].splice(0, MAX_HISTORIES_COUNT);
    CookieManager.saveMetricsHistories(newHistories);
  };

  static getHistories = (): MetricsForm[] => {
    return CookieManager.getMetricsHistories();
  };
  static removeHistory = (form: MetricsForm): MetricsForm[] => {
    const histories: MetricsForm[] = CookieManager.getMetricsHistories();
    const newHistories = histories.filter(
      (history) => JSON.stringify(history) !== JSON.stringify(form)
    );
    CookieManager.saveMetricsHistories(newHistories);
    return newHistories;
  };
}
