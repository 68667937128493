import { theme, Col, Row, Skeleton, Card, Menu, MenuProps, Flex, Space, message, Select } from "antd";
import { MailOutlined, CheckCircleOutlined, CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { GlobalStateContext } from "contexts/global_state_context";
import { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomPageHeader } from "specifics/page_header";
import { useFetchCurrentCompanyApi } from "api/company";
import { Form, useQuery } from "utils/hooks";
import { useFetchDeliveryPartnerCompanyApi } from "api/delivery_partner_company";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import dayjs from "dayjs";
import { useFetchFilterAllManualsApi } from "api/manual";
import { Manual } from "entities/manual";
import { ContractCompletionTable } from "./table";
import { useBulkUpdateContractsApi } from "api/contract";
import Meta from "antd/es/card/Meta";
import { CookieManager } from "utils/cookie_manager";
import useMedia from "use-media";
import { useDeleteDraftApi, useFetchDraftApi, usePostDraftApi } from "api/draft";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";

export const ContractCompletionPage = (
  props: HistoryProps & {
    readonly?: boolean;
    manuals?: Manual[];
    deliveryPartnerCompany?: DeliveryPartnerCompany;
  }) => {
  const params = useParams<{ id: string }>();
  const query = useQuery<{ token: string; expiresAt: string; }>();
  query.token && CookieManager.saveUserToken(query.token);

  const companyApi = useFetchCurrentCompanyApi();
  const partnerCompanyApi = useFetchDeliveryPartnerCompanyApi();
  const requestedManualsApi = useFetchFilterAllManualsApi();
  const showDraftApi = useFetchDraftApi();
  const postDraftApi = usePostDraftApi();
  const deleteDraftApi = useDeleteDraftApi();
  const postManualFormsApi = useBulkUpdateContractsApi();
  const [isPasswordAuthenticated, setIsPasswordAuthenticated] = useState<boolean>(false);
  const [selectedManual, setSelectedManual] = useState<Manual>({});
  const [manualFormsState, setManualFormsState] = useState<{ [id: string]: { form: Form<Manual>, validationResultForm: Form<Manual>, completed: boolean } }>({})

  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  const isMobile = useMedia("(max-width: 519px)");

  const company = () => companyApi.response.data;
  const manuals = () => requestedManualsApi.response.data?.length ? requestedManualsApi.response.data : undefined;
  const manualsGroupedByParentManual = useMemo(() =>
    Object.values(
      (manuals() || props.manuals)?.reduce(
        (acc: { [id: string]: Manual }, manual) => {
          const key = manual.manualId;
          if (key) {
            if (!acc[key]) {
              acc[key] = { id: manual.manualId, deliveryCompany: manual.deliveryCompany, manuals: [] };
            }
            // 現在のmanualのidがmanuals配列内にないことを確認
            const isIdUnique = !acc[key].manuals?.find(m => m.id === manual.id);
            if (isIdUnique) {
              acc[key].manuals?.push(manual);
            }
          } else {
            // manualIdがnullの場合、そのまま配列に追加
            acc[manual.id || ''] = { ...manual, id: manual.id };
          }
          return acc;
        }, {}) || {}
    ), [manuals, props.manuals]);

  const completedManuals = useMemo(() => (
    Object.entries(manualFormsState).reduce((acc: Manual[], [id, { form, completed }]) => {
      if (completed) {
        acc.push(form.object);
      }
      return acc;
    }, [])
  ), [manualFormsState]);

  useEffect(() => {
    if (!props.readonly) {
      companyApi.execute();
      partnerCompanyApi.execute(params.id);
    }
  }, []);

  useEffect(() => {
    if (isPasswordAuthenticated && !requestedManualsApi.isSuccess() && !props.readonly) {
      requestedManualsApi.execute({ requested: true, deliveryPartnerCompanyId: params.id });
      showDraftApi.execute(params.id);
    }
  }, [isPasswordAuthenticated]);

  useEffect(() => {
    globalState.setLoading(requestedManualsApi.loading);
    if (requestedManualsApi.isSuccess() && manualsGroupedByParentManual.length === 0) {
      // 回答済み次第下書きを削除
      deleteDraftApi.execute(params.id);
    }
  }, [requestedManualsApi.loading]);

  useEffect(() => {
    if (showDraftApi.isSuccess()) {
      setManualFormsState(JSON.parse(showDraftApi.response.data.data || '{}'));
    }
  }, [showDraftApi.loading]);

  useEffect(() => {
    if (postManualFormsApi.isSuccess()) {
      message.success("回答を送信しました。");
      setSelectedManual({});
      requestedManualsApi.execute({ requested: true, deliveryPartnerCompanyId: params.id });
    }
  }, [postManualFormsApi.loading]);

  type MenuItem = Required<MenuProps>['items'][number];

  const isItemCompleted = (id?: string) => (
    completedManuals.map(manual => manual.id).includes(id)
  );
  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuProps['items'] = manualsGroupedByParentManual?.map((manual) =>
    getItem(manual.deliveryCompany?.name, `group-${manual.id}`, null, [
      // 親マニュアル（入力依頼中）
      ...(manual.requested || (props.readonly && props.manuals?.map(m => m.id).includes(manual.id))) ?
        [getItem(
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>{manual.deliveryCompany?.point?.memo || manual.deliveryCompany?.name}</span>
            {isItemCompleted(manual.id) ? <CheckCircleOutlined style={{ fontSize: 18 }} /> : <></>}
          </div>,
          `${manual.id}`,
        )] :
        [],
      // 子マニュアル（入力依頼中）
      ...(manual.manuals || []).filter(subManual => subManual.requested || props.readonly).map(subManual =>
        getItem(
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>{subManual.deliveryPointCompany?.point?.memo || subManual.deliveryCompany?.name}</span>
            {isItemCompleted(subManual.id) ? <CheckCircleOutlined style={{ fontSize: 18 }} /> : <></>}
          </div>,
          `${manual.id},${subManual.id}`
        )),
    ])
  );

  const handleMenuClick = useCallback((e: any) => {
    const keyParts = e.key.split(','); // e.key を '親id,子id' で分割
    let selected;

    if (keyParts.length > 1) {
      // 子マニュアルの場合
      const [parentId, childId] = keyParts;
      const parentManual = manualsGroupedByParentManual?.find(manual => manual.id === parentId);

      if (parentManual && parentManual.manuals) {
        selected = parentManual.manuals.find(subManual => subManual.id === childId);
      }
    } else {
      // 親マニュアルの場合
      selected = manualsGroupedByParentManual?.find(manual => manual.id === e.key);
    }

    if (selected) {
      setSelectedManual(selected); // 選択された manual をセット
    }
  }, [manualsGroupedByParentManual]);

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`
      }}
      height={props.readonly ? { height: "100%" } : {}}
      title={props.readonly ? "回答内容を確認" : "納入先での実態把握"}
      childrenStyle={{ display: "flex", flexDirection: props.readonly ? "column" : "row", width: "initial", ...!(manualsGroupedByParentManual.length > 0) ? { alignItems: "center", justifyContent: "center", height: "100%" } : {} }}
    >
      <Skeleton loading={companyApi.loading}>
        {!isPasswordAuthenticated && !props.readonly ?
          // 認証前
          <>
            {company() ?
              // パスワード入力画面
              <Card
                headStyle={{ borderBottom: 0 }}
                title={
                  <Row style={{ padding: "32px 0", display: "flex", gap: 12 }}>
                    <Col
                      span={4}
                      style={{
                        fontSize: 32,
                        height: 56,
                        maxWidth: 56,
                        color: "#A9A8FF",
                        background: "#EFEFFE",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MailOutlined />
                    </Col>
                    <Col span={20} style={{ fontSize: 18, fontWeight: "bold", whiteSpace: "break-spaces" }}>{`${company().name}より納入先での実態の記入依頼が届きました。`}</Col>
                  </Row>
                }
                bordered={false}
                style={{ width: 460, boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.08)" }}
              >
                {/* <DescriptionBlock label="パスワード">
                  <Input placeholder="パスワードを入力" style={{ height: 42 }} />
                </DescriptionBlock> */}
                <DescriptionBlock>
                  {`有効期限：${dayjs(query.expiresAt).format("YYYY/MM/DD")}`}
                </DescriptionBlock>
                <DescriptionBlock style={{ textAlign: "right" }}>
                  <CustomButton onClick={() => setIsPasswordAuthenticated(true)} type="primary">開く</CustomButton>
                </DescriptionBlock>
              </Card> :
              // 有効期限切れ画面
              <Card style={{ width: 460, height: 120, padding: 8 }}>
                <Meta
                  avatar={
                    <div
                      style={{
                        fontSize: 32,
                        height: 56,
                        width: 56,
                        color: "#ED595B",
                        background: "#FFD9D6",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ExclamationCircleOutlined />
                    </div>
                  }
                  title={<span style={{ fontSize: 18, fontWeight: "bold" }}>リンクの有効期限が切れました。</span>}
                  description={<span style={{ fontSize: 15 }}>共有元へご連絡をお願いします。</span>}
                />
              </Card>
            }
          </> :
          // 認証後
          <Skeleton loading={requestedManualsApi.loading}>
            {props.readonly &&
              <Row align="middle" style={{ marginBottom: 30 }}>
                <Col flex="none" style={{ fontSize: 16 }}>依頼先：</Col>
                <Col flex="auto">
                  <Select
                    style={{ width: "100%" }}
                    disabled={true}
                    placeholder={
                      <Space>
                        <span style={{ fontSize: 17, fontWeight: "bold", color: "black" }}>{props.deliveryPartnerCompany?.name}</span>
                        <span style={{ color: "black", opacity: 0.6 }}>{props.deliveryPartnerCompany?.email}</span>
                      </Space>
                    }
                  />
                </Col>
              </Row>
            }
            {manualsGroupedByParentManual.length > 0 ?
              // 回答画面
              <Row gutter={[20, 55]} style={{ width: "initial" }} wrap={isMobile ? true : false}>
                <Col flex="none" style={{ position: "relative" }}>
                  <Menu
                    onClick={handleMenuClick}
                    style={{ width: isMobile ? "calc(100vw - 62px)" : 256 }}
                    mode="inline"
                    items={items}
                  />
                  {!props.readonly && <Flex justify="space-between" style={{ marginTop: 10 }}>
                    <CustomButton
                      onClick={() => {
                        postDraftApi.execute({
                          formId: params.id,
                          data: JSON.stringify(manualFormsState)
                        })
                        message.success("下書きを保存しました。")
                      }}
                    >
                      下書きを保存する
                    </CustomButton>
                    <CustomButton
                      type="primary"
                      // style={{ marginTop: 10, position: "absolute", right: isMobile ? 0 : 10 }}
                      dialogconfirmProps={completedManuals.length > 0 ?
                        {
                          title: <Space>回答を送信</Space>,
                          content:
                            <Flex vertical gap="middle" style={{ fontSize: 16, height: 500 }}>
                              {completedManuals.length}件の実態について、記入内容を送信します。よろしいですか？
                              <Space direction="vertical" style={{ fontSize: 14, background: token.colorWhite, padding: "16px 8px", overflowY: "auto" }}>
                                {completedManuals.map(manual => manual.title)}
                              </Space>
                            </Flex>,
                          cancelText: "戻る",
                          okText: "送信する",
                          okButtonProps: { type: "primary" },
                          onOk: () => {
                            // 完了したManualsのIDリストを取得
                            const completedIds = completedManuals.map(manual => manual.id);
                            // completedManualsに含まれないmanualFormsStateのエントリのみを抽出
                            const filteredManualFormsState = Object.entries(manualFormsState).reduce((
                              acc: { [id: string]: { form: Form<Manual>, validationResultForm: Form<Manual>, completed: boolean } },
                              [id, value]
                            ) => {
                              if (!completedIds.includes(id)) {
                                acc[id] = value; // IDがcompletedIdsに含まれない場合のみ、新しいオブジェクトに追加
                              }
                              return acc;
                            }, {});
                            // 送信するmanualを除外
                            setManualFormsState(filteredManualFormsState);
                            postDraftApi.execute({
                              formId: params.id,
                              data: JSON.stringify(filteredManualFormsState)
                            });

                            postManualFormsApi.execute(completedManuals, params.id);
                          },
                          icon: null,
                          width: 720,
                          centered: true,
                          footerStyle: { justifyContent: "end" },
                          closable: false,
                        } :
                        {
                          title: "未回答の項目があります。",
                          okText: "戻る",
                          okButtonProps: { type: "primary", style: { marginLeft: "auto" } },
                          cancelButtonProps: { style: { display: "none" } },
                          icon: null,
                          width: 328,
                          centered: true,
                          closable: false
                        }
                      }
                      onClick={() => {
                        setManualFormsState(prevForms => {
                          // 必須入力を画面に表示
                          Object.values(prevForms).forEach(({ form }) => {
                            form.setValidate ?
                              // 一度選択してuseFormされている
                              form.setValidate(true) :
                              // 下書きのまま
                              form.validate = true;
                          });
                          return prevForms;
                        });
                      }}
                    >
                      送信する
                    </CustomButton>
                  </Flex>}
                </Col>
                <Col flex="auto">
                  <ContractCompletionTable
                    selectedManual={selectedManual}
                    manualFormsState={manualFormsState}
                    setManualFormsState={setManualFormsState}
                    isMobile={isMobile}
                    readonly={props.readonly}
                  />
                </Col>
              </Row> :
              // 完了画面
              <Card style={{ width: 460, height: 120, padding: 8 }}>
                <Meta
                  avatar={
                    <div
                      style={{
                        fontSize: 32,
                        height: 56,
                        width: 56,
                        color: "#A9A8FF",
                        background: "#EFEFFE",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckOutlined />
                    </div>
                  }
                  title={<span style={{ fontSize: 18, fontWeight: "bold" }}>ご回答ありがとうございました！</span>}
                  description={<span style={{ fontSize: 15 }}>定期的な実態把握にご協力お願い致します。</span>}
                />
              </Card>
            }
          </Skeleton>
        }
      </Skeleton >
    </CustomPageHeader >
  );
};

export default withRouter(ContractCompletionPage);
