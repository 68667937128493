import { Space, Table, theme } from "antd";
import {
  useEditUserApi,
  useFetchUserApi,
  useFetchUsersApi,
  usePostUserApi,
} from "api/user";
import { GlobalStateContext } from "contexts/global_state_context";

import { User, UserSearchForm, UserType } from "entities/user";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import {
  CustomBooleanSwitchField,
  CustomInputField,
  CustomSelectField,
  EmailValidation,
  StringValidation,
  TelValidation,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomContentFooter } from "specifics/footer";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { hankaku2Zenkaku } from "utils/util";
import { useFetchAllCompaniesApi } from "api/company";
import { NEED_TASK } from "entities/contract";

const FormView = (
  form: Form<User>,
  validationResultForm: Form<any>,
  isEdit?: boolean
) => {
  const listCompaniesApi = useFetchAllCompaniesApi();
  useEffect(() => {
    listCompaniesApi.execute();
  }, []);

  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "type", true);
      StringValidation(f, form, "name", true);
      TelValidation(f, form, "mobile", true);
      EmailValidation(f, form, "email", false);
      if (form.object.type === UserType.ドライバー) {
        StringValidation(f, form, "availableJobs", true);
      } else {
        f.availableJobs = undefined;
      }
    });
  }, [JSON.stringify(form.object)]);

  return (
    <Space style={{ width: "100%" }} size={16} direction="vertical">
      <CustomSelectField
        disabled={isEdit}
        form={form}
        validationResultForm={validationResultForm}
        label="客先"
        attr="companyId"
        style={{ width: 180 }}
        selectItems={
          listCompaniesApi.response.data?.map((item) => ({
            label: item.name,
            value: item.id,
          })) || []
        }
        required
      />
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="氏名"
        attr="name"
        style={{ width: 180 }}
        required
      />
      <CustomSelectField
        form={form}
        validationResultForm={validationResultForm}
        label="役割"
        attr="type"
        style={{ width: 180 }}
        selectItems={[
          {
            label: "運行管理",
            value: UserType.運行管理者,
          },
          {
            label: "ドライバー",
            value: UserType.ドライバー,
          },
        ]}
        disabled={form.object.type === UserType.knewit社員}
        required
        onChange={(e) => {
          form.update((f) => {
            f.type = e;
            f.canAccessBoard = false;
          });
        }}
      />
      {form.object.type === UserType.運行管理者 && (
        <CustomBooleanSwitchField
          form={form}
          validationResultForm={validationResultForm}
          label="ボードへのアクセス権限"
          attr={"canAccessBoard"}
          fieldProps={{
            checkedChildren: "あり",
            unCheckedChildren: "なし",
          }}
        />
      )}
      {form.object.type === UserType.ドライバー && (
        <CustomSelectField
          form={form}
          validationResultForm={validationResultForm}
          label="対応可能作業"
          mode="multiple"
          attr="availableJobs"
          style={{ width: 803 - 48 }}
          onChange={(e) => form.updateObject("availableJobs", e.join(","))}
          fieldProps={{
            value: form.object.availableJobs?.split(","),
            children: <></>,
          }}
          required
          selectItems={NEED_TASK.filter((item) => item !== "その他（自由入力）").map(
            (item) => ({
              label: item,
              value: item,
            })
          )}
        />
      )}
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="電話番号"
        onChange={(e) => {
          const value = hankaku2Zenkaku(e.target.value);
          if (value.match(/^\d{0,13}$/)) {
            form.updateObject("mobile", value.slice(0, 13));
          } else {
            console.log("invalid");
          }
        }}
        attr="mobile"
        required
      />
      <CustomInputField
        form={form}
        validationResultForm={validationResultForm}
        label="メールアドレス"
        attr="email"
        style={{ width: "100%" }}
      />
    </Space>
  );
};

const _UsersCreatePage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  const form = useForm<User>({});
  const postApi = usePostUserApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      props.history.push("/super-admin/users");
    }
  }, [postApi.loading]);

  const validationResultForm = useForm<Record<string, string | undefined>>({});
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="メンバー登録"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/super-admin/users")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) => validationResultForm.object[key] === undefined
                )
              ) {
                postApi.execute(form);
              }
            }}
          >
            登録
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.push("/super-admin/users")}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

const _UsersEditPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);

  const { token } = theme.useToken();
  const userApi = useFetchUserApi();
  const params = useParams<{ id: string }>();
  const form = useForm<User>({});
  const editApi = useEditUserApi();
  useEffect(() => {
    userApi.execute(params.id);
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(userApi.loading);
    if (userApi.isSuccess()) {
      form.set(userApi.response.data);
    }
  }, [userApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.history.push(`/super-admin/users/${params.id}`);
    }
  }, [editApi.loading]);

  const validationResultForm = useForm<Record<string, string | undefined>>({});
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="メンバー編集"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() => props.history.push("/super-admin/users")}
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm, true)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every(
                  (key) => validationResultForm.object[key] === undefined
                )
              ) {
                editApi.execute(form);
              }
            }}
          >
            保存
          </CustomButton>
          <CustomButton
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
            onClick={() => props.history.push("/super-admin/users")}
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const SuperAdminUsersEditPage = withRouter(_UsersEditPage);

export const SuperAdminUsersCreatePage = withRouter(_UsersCreatePage);
