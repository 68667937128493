import axios, { AxiosResponse } from "axios";
import { GlobalToken, message } from "antd";
import CRC32 from "crc-32";
import { SelectItem } from "components/shared/input";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import { DefaultOptionType } from "antd/es/select";
import { DeliveryCompany } from "entities/delivery_company";
import { Consignor } from "entities/consignor";
import { CharteredCompany } from "entities/chartered_company";
import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { Vehicle, isVehicle } from "entities/vehicle";
import { Question } from "entities/template";
import { ID } from "entities";
import { useGenerateTemporaryTokenApi } from "api/temporary_token";
import { ManualKey } from "entities/manual";

export const queryString = ({
  page = 1,
  pageSize = 10,
  ...params
}: Record<string, any> = {}) => {
  const queryParts = Object.entries({ page, pageSize, ...params })
    .map(([key, value]) => {
      return value ? `${encodeURIComponent(key)}=${value}` : '';
    })
    .filter(part => part !== '');

  return `?${queryParts.join('&')}`;
};

export const isAllValuesValidated: any = (obj: any) => {
  // オブジェクトの値がundefinedかどうかをチェック
  if (obj === undefined) {
    return true;
  }

  // オブジェクトが配列の場合、各要素に対して再帰的にチェック
  if (Array.isArray(obj)) {
    return obj.every(isAllValuesValidated);
  }

  // オブジェクトがオブジェクトの場合、各プロパティの値に対して再帰的にチェック
  if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).every(isAllValuesValidated);
  }

  // 上記のいずれにも該当しない場合は、値がundefinedではないため、falseを返す
  return false;
}

export const sortQuestions = (questions: Question[]): Question[] => {
  const parentQuestions = questions.filter(q => !q.parentQuestionId);
  const childQuestions = questions.filter(q => q.parentQuestionId);

  const groupedChildren = childQuestions.reduce((acc: any, question) => {
    const { parentQuestionId } = question;
    if (!acc[parentQuestionId ?? ""]) {
      acc[parentQuestionId ?? ""] = [];
    }
    acc[parentQuestionId ?? ""].push(question);
    return acc;
  }, {});

  Object.keys(groupedChildren).forEach(key => {
    groupedChildren[key].sort((a: Question, b: Question) => (a.questionNo || 0) - (b.questionNo || 0));
  });

  return parentQuestions.flatMap(parent => [parent, ...(groupedChildren[parent.id || ""] || [])]);
}

export const highlightText = (text: string, token: GlobalToken) =>
  text.replace(
    /{{(.*?)}}/g,
    `<span style="padding:2px 5px;border-radius:2px;font-weight:bold;color:${token.colorTextBase};background-color:${token.colorTextQuaternary};">$1</span>`
  );
export const sanitize = (text: string) => DOMPurify.sanitize(text);
export const includesHighlightRegex = (text: string) =>
  /{{(.*?)}}/g.exec(text) !== null;
export const removeHighlightRegex = (text: string) =>
  text.replace(/{{|}}/g, "");
export const makeTextHighlight = (value: string) => {
  const selection = window.getSelection();
  if (selection && selection.toString().trim()) {
    let selectedText = selection.toString();
    const selectedTextStart = value.indexOf(selectedText);
    const selectedTextEnd = selectedTextStart + selectedText.length;
    // {{ または }} が含まれている場合、除去する
    selectedText = removeHighlightRegex(selectedText);

    // 以下で選択されたテキストを特殊文字({{}})で囲う処理を行う
    let updatedValue = value;

    // テキスト全体を検索して、{{}}で囲まれた部分を見つける正規表現
    const highlightRegex = /{{(.*?)}}/g;
    let match;
    while ((match = highlightRegex.exec(value)) !== null) {
      const matchedText = match[1];
      const matchedTextStart = match.index;
      const matchedTextEnd = matchedTextStart + matchedText.length + 2; // '{{' の分を足す

      // 選択されたテキストの位置とハイライトされたテキストの位置が重複しているかチェック
      if (
        selectedText.includes(matchedText) ||
        (selectedTextStart >= matchedTextStart &&
          selectedTextStart < matchedTextEnd) ||
        (selectedTextEnd > matchedTextStart &&
          selectedTextEnd <= matchedTextEnd)
      ) {
        // 重複がある場合、ハイライトを解除
        updatedValue = updatedValue.replace(`{{${matchedText}}}`, matchedText);
        break;
      }
    }

    // 新たにハイライトを適用
    const highlightedText = `{{${selectedText}}}`;
    updatedValue = updatedValue.replace(selectedText, highlightedText);
    return updatedValue;
  } else {
    return value;
  }
};

export const filterKanaName =
  (items?: (CharteredCompany | DeliveryCompany | Consignor | DeliveryPartnerCompany | Vehicle)[]) =>
    (input: string, option?: DefaultOptionType) => {
      const item = items?.find((item) => item.id === option?.value);
      const inputLowercased = input.toLowerCase();
      let kanaMatches;
      let nameMatches;
      if (item && isVehicle(item)) {
        nameMatches = item?.user?.name?.toLowerCase().includes(inputLowercased);
      } else {
        nameMatches = item?.name?.toLowerCase().includes(inputLowercased);
        kanaMatches = item?.kana?.toLowerCase().includes(inputLowercased);
      }

      return kanaMatches || nameMatches || false;
    };

export const bulkEditManualUrlText = async (ids: ID[], dataSource: readonly any[]) => {
  const generateTokenApi = useGenerateTemporaryTokenApi();
  // 各行に対してトークンを生成し、情報を連結する非同期処理を準備
  const copyPromises = ids.map(async (id) => {
    // 一時トークンを生成
    const res = await generateTokenApi.execute({ expirationOption: "1month" });
    const token = encodeURIComponent(res.data.token);
    // 対応する行のデータを見つける
    const item = dataSource?.find((d) => d.id === id);
    // 必要な情報を形成
    return encodeURIComponent(`${item?.deliveryCompany?.name}\n${process.env.REACT_APP_ADMIN_HOST}manuals/${item.manual.id}/edit?key=${ManualKey.基本情報}&token=${token}`);
  });

  // すべての非同期処理が完了するのを待つ
  const copyTexts = await Promise.all(copyPromises);

  // 結果を改行で連結
  const finalText = copyTexts.join('\n');
  return finalText;
}

export const toPercent = (value: number): string => {
  return `${Math.floor(value * 100)} %`;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

export const zeroPadding = (num: number, length: number) => {
  return ("0000000000" + num).slice(-length);
};

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const convertToCamelCase = (obj: any): Record<string, any> => {
  const camelCaseKeys = (key: string) => key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        return [key, convertToCamelCase(value)];
      }
      return [camelCaseKeys(key), value];
    })
  );
};

export const convertPos2Str = (
  col: number,
  row: number,
  lowercase?: boolean
) => {
  const strPos = `${ALPHABET[col - 1]}${row}`;
  if (lowercase) {
    return strPos.toLowerCase();
  }
  return strPos;
};

export const convertStr2Pos = (str_position: string): number[] => {
  const alphabet = str_position[0];
  const x = Number(str_position.substring(1));
  const y = ALPHABET.indexOf(alphabet);
  return [x - 1, y];
};

export const downloadFile = (
  content: string | Uint8Array,
  filename: string,
  contentType: string
) => {
  const blob = new Blob([content], { type: contentType });
  const link = document.createElement("a");
  link.download = filename; // ダウンロードファイル名称
  link.href = window.URL.createObjectURL(blob); // オブジェクト URL を生成
  link.click(); // クリックイベントを発生させる
  URL.revokeObjectURL(link.href); // オブジェクト URL を解放」
};

export const hex2rgb = (hex: string) => {
  if (hex.slice(0, 1) == "#") hex = hex.slice(1);
  if (hex.length == 3)
    hex =
      hex.slice(0, 1) +
      hex.slice(0, 1) +
      hex.slice(1, 2) +
      hex.slice(1, 2) +
      hex.slice(2, 3) +
      hex.slice(2, 3);

  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(function (
    str
  ) {
    return parseInt(str, 16);
  });
};

export const reorder = (
  list: any[] | undefined,
  startIndex: number,
  endIndex: number
) => {
  if (!list) {
    return [];
  }
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const triggerEvent = (element: HTMLElement, event: string): boolean => {
  // IE以外
  const evt = document.createEvent("HTMLEvents");
  evt.initEvent(event, true, true); // event type, bubbling, cancelable
  return element.dispatchEvent(evt);
};

export const findAddressByZip = async (
  zip: string
): Promise<
  AxiosResponse<{ prefecture?: string; city?: string; street?: string }> & {
    message?: string;
  }
> => {
  let error;
  const url = `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zip.replace(
    "-",
    ""
  )}`;
  const response = await axios
    .get(url)
    .then((res) => JSON.parse(res.request.response));
  let data = {};
  if (response.status === 200 && response.results?.length > 0) {
    const prefecture = response.results[0]["address1"];
    const city = response.results[0]["address2"];
    const street = response.results[0]["address3"];
    data = {
      prefecture,
      city,
      street,
    };
  }
  return { ...response, data };
};

export const speak = (
  text: string,
  onEnd?: () => void,
  pitch?: number,
  rate?: number
) => {
  const speak = new SpeechSynthesisUtterance();
  speak.text = text;
  speak.rate = rate ?? 1; // 読み上げ速度 0.1-10 初期値:1 (倍速なら2, 半分の倍速なら0.5, )
  speak.pitch = pitch ?? 1; // 声の高さ 0-2 初期値:1(0で女性の声)
  speak.lang = "ja-JP"; //(日本語:ja-JP, アメリカ英語:en-US, イギリス英語:en-GB, 中国語:zh-CN, 韓国語:ko-KR)
  speak.volume = 0.5;
  if (onEnd) {
    speak.onend = onEnd;
  }
  speechSynthesis.speak(speak);
};

export const isInt = (n: any) => {
  return Number(n) === n && n % 1 === 0;
};

export const isFloat = (n: any) => {
  return Number(n) === n && n % 1 !== 0;
};

export const setPageSize = (cssPageSize: "landscape" | "portrait") => {
  const style = document.createElement("style");
  style.innerHTML = `@page {size: ${cssPageSize}}`;
  style.id = "page-orientation";
  document.head.appendChild(style);
};

export const zenkaku2Hankaku = (str: string) => {
  let value = str;
  const boinJa = ["あ", "い", "う", "え", "お"];
  const boinEn = ["a", "b", "c", "d", "e"];
  boinJa.map((bj, i) => {
    value = value.replace(bj, boinEn[i]);
  });
  return value.replace(/[！-～]/g, function (input) {
    return String.fromCharCode(input.charCodeAt(0) - 0xfee0);
  });
};

export const truncate = (str: string, len: number) => {
  return str.length <= len ? str : str.substr(0, len) + "...";
};

export const toDateText = (strDate?: string): string => {
  if (strDate) {
    return dayjs(strDate).format("YYYY/MM/DD");
  } else {
    return "-";
  }
};

export const toText = (strDate?: string): string => {
  if (strDate) {
    return dayjs(strDate).format("YYYY/MM/DD HH:mm");
  } else {
    return "-";
  }
};

export const selectYears = (): SelectItem[] => {
  const currentYear = Number(dayjs().format("YYYY"));
  let year = 2022; // 2022からスタート
  const years = [];
  while (year <= currentYear) {
    years.push(year);
    year += 1;
  }
  return years.map((y) => {
    return { label: y + "年", value: y };
  });
};

export const selectMonth = (): SelectItem[] => {
  return new Array(12).fill(0).map((y, idx) => {
    const month = idx + 1;
    return { label: month + "月", value: month };
  });
};

export const getDaysArray = (year: number, month: number) => {
  const monthIndex = month - 1; // 0..11 instead of 1..12
  const names = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const date = new Date(year, monthIndex, 1);
  const result = [];
  while (date.getMonth() == monthIndex) {
    result.push(date.getDate());
    date.setDate(date.getDate() + 1);
  }
  return result;
};

export const swipeBack = {
  disable: () => {
    document.getElementById("html")?.classList.add("disable-swipe-back");
  },
  enable: () => {
    document.getElementById("html")?.classList.remove("disable-swipe-back");
  },
};

export const genTextColor = (text: string) => {
  const i32 = CRC32.str(text);
  const bgColor = [
    (i32 & 0xff000000) >>> 24,
    (i32 & 0x00ff0000) >>> 16,
    (i32 & 0x0000ff00) >>> 8,
    (i32 & 0x000000ff) >>> 0,
  ];
  const fontColor = [
    ~bgColor[0] & 0xff,
    ~bgColor[1] & 0xff,
    ~bgColor[2] & 0xff,
  ];
  const brightness = (r: number, g: number, b: number) => {
    return Math.floor((r * 299 + g * 587 + b * 114) / 1000);
  };

  const bgL = brightness(bgColor[0], bgColor[1], bgColor[2]);
  const fcL = brightness(fontColor[0], fontColor[1], fontColor[2]);
  if (Math.abs(bgL - fcL) < 125) {
    fontColor[0] = fontColor[1] = fontColor[2] = 0xff - bgL > bgL ? 0xff : 0x00;
  }

  const toHex = (b: number) => {
    const str = b.toString(16);
    if (2 <= str.length) {
      return str;
    }
    return "0" + str;
  };

  const toColorCode = (bytes: number[]) => {
    return "#" + toHex(bytes[0]) + toHex(bytes[1]) + toHex(bytes[2]);
  };

  return {
    fontColor: toColorCode(fontColor),
    bgColor: toColorCode(bgColor),
  };
};

export const dayOfWeekStrJP = ["日", "月", "火", "水", "木", "金", "土"];

export type DayOfWeekStrJP = (typeof dayOfWeekStrJP)[number];

export const getDayOfWeekJp = (date: Date): DayOfWeekStrJP => {
  return dayOfWeekStrJP[date.getDay()];
};

export const getFontColorByBackground = (backgroundColor: string) => {
  // RGBをY(輝度)に変換
  const brightness =
    parseInt(backgroundColor.substr(1, 2), 16) * 0.299 + // Red
    parseInt(backgroundColor.substr(3, 2), 16) * 0.587 + // Green
    parseInt(backgroundColor.substr(4, 2), 16) * 0.114; // Blue
  const color = brightness >= 140 ? "#000000" : "#FFFFFF";
  return color;
};

export const Prefectures: Record<string, string> = {
  "1": "北海道",
  "2": "青森県",
  "3": "岩手県",
  "4": "宮城県",
  "5": "秋田県",
  "6": "山形県",
  "7": "福島県",
  "8": "茨城県",
  "9": "栃木県",
  "10": "群馬県",
  "11": "埼玉県",
  "12": "千葉県",
  "13": "東京都",
  "14": "神奈川県",
  "15": "新潟県",
  "16": "富山県",
  "17": "石川県",
  "18": "福井県",
  "19": "山梨県",
  "20": "長野県",
  "21": "岐阜県",
  "22": "静岡県",
  "23": "愛知県",
  "24": "三重県",
  "25": "滋賀県",
  "26": "京都府",
  "27": "大阪府",
  "28": "兵庫県",
  "29": "奈良県",
  "30": "和歌山県",
  "31": "鳥取県",
  "32": "島根県",
  "33": "岡山県",
  "34": "広島県",
  "35": "山口県",
  "36": "徳島県",
  "37": "香川県",
  "38": "愛媛県",
  "39": "高知県",
  "40": "福岡県",
  "41": "佐賀県",
  "42": "長崎県",
  "43": "熊本県",
  "44": "大分県",
  "45": "宮崎県",
  "46": "鹿児島県",
  "47": "沖縄県",
};

export const PrefectureArea: Record<string, string[]> = {
  "北海道・東北": ["1", "2", "3", "4", "5", "6", "7"],
  関東: ["8", "9", "10", "11", "12", "13", "14"],
  中部: ["15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
  近畿: ["25", "26", "27", "28", "29", "30"],
  中国: ["31", "32", "33", "34", "35"],
  四国: ["36", "37", "38", "39"],
  九州: ["40", "41", "42", "43", "44", "45", "46", "47"],
};

export function hankaku2Zenkaku(str: string) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
}

export const getEnvColor = () => {
  if (process.env.REACT_APP_ENV_MODE === "LOCAL") {
    return "#dd9";
  } else if (process.env.REACT_APP_ENV_MODE === "DEVELOPMENT") {
    return "#599";
  } else if (process.env.REACT_APP_ENV_MODE === "DEMO") {
    return "#959";
  } else if (process.env.REACT_APP_ENV_MODE === "PRODUCTION") {
    return "#fff";
  } else return "#000";
};

export function rearrangeArray<T>(
  array: T[],
  sourceIndex: number,
  targetIndex: number
): T[] {
  const result = [...array];
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(targetIndex, 0, removed);

  return result;
}

export function hankakuKatakanaToHiragana(str: string): string {
  const kanaMap: { [key: string]: string } = {
    ｱ: "あ",
    ｲ: "い",
    ｳ: "う",
    ｴ: "え",
    ｵ: "お",
    ｶ: "か",
    ｷ: "き",
    ｸ: "く",
    ｹ: "け",
    ｺ: "こ",
    ｻ: "さ",
    ｼ: "し",
    ｽ: "す",
    ｾ: "せ",
    ｿ: "そ",
    ﾀ: "た",
    ﾁ: "ち",
    ﾂ: "つ",
    ﾃ: "て",
    ﾄ: "と",
    ﾅ: "な",
    ﾆ: "に",
    ﾇ: "ぬ",
    ﾈ: "ね",
    ﾉ: "の",
    ﾊ: "は",
    ﾋ: "ひ",
    ﾌ: "ふ",
    ﾍ: "へ",
    ﾎ: "ほ",
    ﾏ: "ま",
    ﾐ: "み",
    ﾑ: "む",
    ﾒ: "め",
    ﾓ: "も",
    ﾔ: "や",
    ﾕ: "ゆ",
    ﾖ: "よ",
    ﾗ: "ら",
    ﾘ: "り",
    ﾙ: "る",
    ﾚ: "れ",
    ﾛ: "ろ",
    ﾜ: "わ",
    ｦ: "を",
    ﾝ: "ん",
    ｶﾞ: "が",
    ｷﾞ: "ぎ",
    ｸﾞ: "ぐ",
    ｹﾞ: "げ",
    ｺﾞ: "ご",
    ｻﾞ: "ざ",
    ｼﾞ: "じ",
    ｽﾞ: "ず",
    ｾﾞ: "ぜ",
    ｿﾞ: "ぞ",
    ﾀﾞ: "だ",
    ﾁﾞ: "ぢ",
    ﾂﾞ: "づ",
    ﾃﾞ: "で",
    ﾄﾞ: "ど",
    ﾊﾞ: "ば",
    ﾋﾞ: "び",
    ﾌﾞ: "ぶ",
    ﾍﾞ: "べ",
    ﾎﾞ: "ぼ",
    ﾊﾟ: "ぱ",
    ﾋﾟ: "ぴ",
    ﾌﾟ: "ぷ",
    ﾍﾟ: "ぺ",
    ﾎﾟ: "ぽ",
    ｧ: "ぁ",
    ｨ: "ぃ",
    ｩ: "ぅ",
    ｪ: "ぇ",
    ｫ: "ぉ",
    ｯ: "っ",
    ｬ: "ゃ",
    ｭ: "ゅ",
    ｮ: "ょ",
    ｰ: "ー",
    " ": "　",
  };

  let result = "";
  for (let i = 0; i < str.length; i++) {
    const currentChar = str[i];
    const nextChar = str[i + 1];
    // Check for characters that form a pair (like 'ｶﾞ')
    if (nextChar && kanaMap[currentChar + nextChar]) {
      result += kanaMap[currentChar + nextChar];
      i++; // Skip the next character since it's part of a pair
    } else {
      result += kanaMap[currentChar] || currentChar; // Fallback to original if no mapping
    }
  }

  return result;
}
