import { Space, theme } from "antd";
import { useDeleteRouteApi, useFetchRouteApi } from "api/route";
import { GlobalStateContext } from "contexts/global_state_context";

import { Route } from "entities/route";
import { relative } from "path";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { useEffectSkipFirst, useForm } from "utils/hooks";

const RoutePage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const routeApi = useFetchRouteApi();
  const params = useParams<{ id: string }>();
  const routeForm = useForm<Route>({});
  useEffect(() => {
    routeApi.execute(params.id);
  }, []);

  const deleteApi = useDeleteRouteApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      props.history.push("/operations/routes");
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(routeApi.loading);
    if (routeApi.isSuccess()) {
      routeForm.set(routeApi.response.data);
    }
  }, [routeApi.loading]);

  const route = () => {
    return routeApi.response.data;
  };

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="ルート詳細"
      onBack={() => props.history.goBack()}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent>
        <Space
          style={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
            position: "relative"
          }}
        >
          <Space direction="vertical" size={24}>
            <DescriptionBlock label="ルートコード">
              {route()?.code}
            </DescriptionBlock>
            <DescriptionBlock label="運送条件合意書URL">
              {route()?.url}
            </DescriptionBlock>
          </Space>
          <Space size={24} style={{ position: "absolute", right: 0, top: 0 }}>
            <CustomButton
              style={{
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() =>
                props.history.push(
                  `/operations/routes/${route()?.id}/edit`
                )
              }
            >
              編集
            </CustomButton>
            <CustomButton
              danger
              type="text"
              style={{
                fontSize: 14,
                fontWeight: 400,
                padding: 0,
              }}
              confirm
              popconfirmProps={{
                title: "削除します。よろしいですか？",
              }}
              onClick={() => {
                deleteApi.execute(params.id);
              }}
            >
              削除
            </CustomButton>
          </Space>
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(RoutePage);

