import { render } from "@testing-library/react";
import { Descriptions, ModalProps, message } from "antd";
import Column from "antd/es/table/Column";
import { useFetchAllCompaniesApi } from "api/company";
import { usePostUsersApi } from "api/user";
import {
  CsvImportModal,
  EntityColumn,
  RelateCsvEntityColumnsForm,
} from "components/shared/csv_import_modal";
import { GlobalStateContext } from "contexts/global_state_context";
import { User, UserType } from "entities/user";
import { useContext, useEffect } from "react";
import { CustomSelectField } from "specifics/input";
import { useForm } from "utils/hooks";

export const SuperAdminUserCsvImportModal = ({ ...modalProps }: ModalProps) => {
  const bulkCreateUserApi = usePostUsersApi();
  const companiesApi = useFetchAllCompaniesApi();

  const entityColumns: EntityColumn<User>[] = [
    {
      key: "name",
      title: "氏名",
      dataIndex: "name",
      required: true,
      width: 200,
    },
    {
      key: "type",
      title: "役割(運行管理者(1)/ドライバー(2))",
      dataIndex: "type",
      width: 200,
    },
    {
      key: "canAccessBoard",
      title: "ボードアクセス権(あり/なし)",
      dataIndex: "zipcode",
      width: 200,
    },
    { key: "mobile", title: "電話番号", dataIndex: "mobile", width: 200 },
    { key: "password", title: "パスワード", dataIndex: "password", width: 200 },
    {
      key: "email",
      title: "メールアドレス",
      dataIndex: "email",
      width: 200,
    },
  ];

  const companyIdForm = useForm<{ companyId?: string }>({});

  const csvDataToEntities = (
    csvData: Record<string, any>[],
    valueOf: (csvRow: Record<string, any>, csvColumnName: string) => any
  ): User[] => {
    return csvData.map((csvRow) => {
      const entity: User = {};
      entityColumns.forEach((column) => {
        let value = valueOf(csvRow, column.title);
        if (column.dataIndex === "type") {
          if (value === "運行管理者") {
            value = UserType.運行管理者;
          } else if (value === "ドライバー") {
            value = UserType.ドライバー;
          }
        }
        entity[column.dataIndex as keyof User] = value;
      });

      return entity;
    });
  };

  useEffect(() => {
    companiesApi.execute();
  }, []);

  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    globalState.setLoading(bulkCreateUserApi.loading);
  }, [bulkCreateUserApi.loading]);

  return (
    <CsvImportModal
      notReadyToUploadCsv={!companyIdForm.object.companyId}
      notReadyToUploadCsvMessage="アカウントが紐づく客先を選択してください"
      bulkApiResult={{
        data: bulkCreateUserApi.response,
        error: bulkCreateUserApi.apiError as any,
      }}
      extraForm={
        <>
          <CustomSelectField
            label="客先"
            attr="companyId"
            form={companyIdForm}
            selectItems={
              companiesApi.response.data?.map((company) => ({
                label: company.name,
                value: company.id,
              })) || []
            }
            required
          />
        </>
      }
      extraFormView={
        <div style={{ fontWeight: 600 }}>
          客先:
          {
            companiesApi.response.data?.find(
              (company) => company.id === companyIdForm.object.companyId
            )?.name
          }
        </div>
      }
      csvDataToEntities={csvDataToEntities}
      entityColumns={entityColumns}
      entityNameJp="アカウント"
      handleBulkSendValidatedEntities={async (data) => {
        if (companyIdForm.object.companyId) {
          await bulkCreateUserApi.execute(data, companyIdForm.object.companyId);
        } else {
          message.error("客先IDが指定されていません");
          return {
            isError: true,
          };
        }
      }}
      {...modalProps}
    />
  );
};
