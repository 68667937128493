// @ts-nocheck
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Spin, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  CSSProperties,
  Children,
  EffectCallback,
  FC,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { CustomTextAreaField, CustomSelectRadioField } from "specifics/input";
import { CustomButton } from "specifics/button";
import { PointType } from "entities/point";
import { Form } from "./hooks";

const render = (status: Status): ReactElement => {
  if (status === Status.FAILURE) return <div>Error!</div>;
  return <Spin />;
};

type MyMarkerOptions = google.maps.MarkerOptions & {
  onClickMarker?: (
    e: google.maps.MapMouseEvent,
    marker: google.maps.Marker
  ) => void;
  onDbclickMarker?: (e: google.maps.MapMouseEvent) => void;
  onDragstartMarker?: (e: google.maps.MapMouseEvent) => void;
  onDragendMarker?: (e: google.maps.MapMouseEvent) => void;
  onMouseOverMarker?: (
    e: google.maps.MapMouseEvent,
    marker: google.maps.Marker
  ) => void;
};

const Marker: FC<MyMarkerOptions> = ({
  onClickMarker,
  onDbclickMarker,
  onDragstartMarker,
  onDragendMarker,
  onMouseOverMarker,
  ...options
}: MyMarkerOptions) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    if (onClickMarker) {
      marker?.addListener("click", (e: google.maps.MapMouseEvent) => {
        onClickMarker(e, marker);
      });
    }
    if (onDbclickMarker) {
      marker?.addListener("dblclick", onDbclickMarker);
    }
    if (onDragstartMarker) {
      marker?.addListener("dragstart", onDragstartMarker);
    }
    if (onDragendMarker) {
      marker?.addListener("dragend", onDragendMarker);
    }
    if (onMouseOverMarker) {
      marker?.addListener("mouseover", (e: google.maps.MapMouseEvent) => {
        onMouseOverMarker(e, marker);
      });
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options as google.maps.MarkerOptions);
    }
  }, [marker, options]);

  return null;
};

interface MapProps extends google.maps.MapOptions {
  map?: google.maps.Map;
  setMap?: React.Dispatch<React.SetStateAction<google.maps.Map | undefined>>;
  setZoom?: React.Dispatch<React.SetStateAction<number | undefined>>;
  isDriver?: boolean;
  pointTypeForm?: Form<Point>;
  prevPoint?: React.MutableRefObject<Point | undefined>;
  style?: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onDbclick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onDrop?: (e: any) => void;
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  children?: ReactNode;
}

interface DrawerProps {
  isDriver?: boolean;
  pointTypeForm?: Form<Point>;
  prevPoint?: React.MutableRefObject<Point | undefined>;
  setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  drawerOpen?: boolean;
}

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: EffectCallback,
  dependencies: any[]
) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const Map: React.FC<MapProps> = ({
  map,
  setMap,
  setZoom,
  isDriver,
  children,
  style,
  onClick,
  onDbclick,
  ...options
}: MapProps) => {
  // [START maps_react_map_component_add_map_hooks]
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  // [END maps_react_map_component_add_map_hooks]

  // [START maps_react_map_component_options_hook]
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  // [END maps_react_map_component_options_hook]

  // [START maps_react_map_component_event_hooks]
  useEffect(() => {
    if (map) {
      ["click", "dblclick", "idle", "zoom_changed", "center_changed"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      // マップ上がクリックされたときに呼ばれるリスナーを追加
      if (onClick && !isDriver) {
        map.addListener("click", onClick);
      }

      // マップ上がダブルクリックされたときに呼ばれるリスナーを追加
      if (onDbclick) {
        map.addListener("dblclick", onDbclick);
      }

      // ズームレベルが変更されたときに呼ばれるリスナーを追加
      map.addListener("zoom_changed", () => {
        setZoom && setZoom(map.getZoom());
      });
    }
  }, [map, onClick]);
  // [END maps_react_map_component_event_hooks]

  // [START maps_react_map_component_return]
  return (
    <>
      <div ref={ref} style={{ ...style }} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return cloneElement(child, { map });
        }
      })}
    </>
  );
  // [END maps_react_map_component_return]
};

const TaskRegistrationDrawer: React.FC<DraerProps> = ({
  pointTypeForm,
  prevPoint,
  setDrawerOpen,
  drawerOpen,
  isDriver,
}: DrawerProps) => {
  const onClose = () => {
    if (pointTypeForm.object.pointType === undefined) {
      pointTypeForm?.updateObject("pointType", PointType.削除);
    }
    setDrawerOpen(false);
  };

  return (
    <Drawer
      title={
        <div
          style={{
            display: "flex",
            fontSize: 16,
            justifyContent: "space-between",
          }}
        >
          <span></span>
          <span>作業を選択</span>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      footer={
        pointTypeForm.object.pointType > PointType.削除 && (
          <div
            style={{
              display: "flex",
              fontSize: 16,
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              danger
              style={{ background: "#FFEFEE" }}
              dialogconfirmProps={{
                title: "地点を削除しますか？",
                content: "選択した地点と作業が削除されます。",
                okText: "削除する",
                onOk: () => {
                  pointTypeForm?.updateObject("pointType", PointType.削除);
                  onClose();
                },
                cancelText: "戻る",
                okButtonProps: {
                  danger: true,
                  style: { background: "#FFEFEE" },
                },
                icon: null,
                centered: true,
                closable: false,
              }}
            >
              地点を削除
            </CustomButton>
            <CustomButton type="primary" onClick={() => setDrawerOpen(false)}>
              完了
            </CustomButton>
          </div>
        )
      }
      placement={isDriver ? "bottom" : "right"}
      height="100%"
      closable={false}
      open={drawerOpen}
    >
      <CustomSelectRadioField
        form={pointTypeForm}
        attr="pointType"
        selectItems={Object.keys(PointType)
          .filter((key) => isNaN(Number(key)) &&
            PointType[key] !== PointType.削除 &&
            PointType[key] !== PointType.地点
          )
          .map((label, index) => ({
            label: label,
            value: PointType[label],
          }))}
        column={2}
        isManual
        onChange={(e) => {
          prevPoint.current = {
            ...prevPoint.current,
            pointType: e.target.value,
          };
          pointTypeForm?.updateObject("pointType", e.target.value);
        }}
      />
      <CustomTextAreaField
        form={pointTypeForm}
        attr="memo"
        label="補足説明"
        labelStyle={{ marginTop: 15, fontSize: 15 }}
        style={{ marginBottom: 7 }}
        onChange={(e) => {
          prevPoint.current = { ...prevPoint.current, memo: e.target.value };
          pointTypeForm?.updateObject("memo", e.target.value);
        }}
      />
    </Drawer>
  );
};

const LongPressablePolygon = ({ map, pointTypeForm, setDrawerOpen }) => {
  const [polygon, setPolygon] = useState(null);
  const startTimeRef = useRef(null);

  const handleMouseDown = (e) => {
    startTimeRef.current = Date.now();
  };
  const handleMouseUp = (e: google.maps.PolyMouseEvent) => {
    const time = Date.now() - startTimeRef.current;
    if (time > 500) {
      pointTypeForm.set({
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      });
      setDrawerOpen(true);
    }
  };
  useEffect(() => {
    if (map) {
      google.maps.event.addListener(map, "bounds_changed", () => {
        startTimeRef.current = Date.now();
        const bounds = map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const vertices = [
          { lat: ne.lat(), lng: ne.lng() },
          { lat: ne.lat(), lng: sw.lng() },
          { lat: sw.lat(), lng: sw.lng() },
          { lat: sw.lat(), lng: ne.lng() },
        ];
        if (polygon) {
          polygon.setPaths(vertices);
        } else {
          const newPolygon = new google.maps.Polygon({
            paths: vertices,
            map: map,
            fillOpacity: 0,
            strokeOpacity: 0,
          });
          setPolygon(newPolygon);
          // Add listeners for mousedown and mouseup events
          google.maps.event.addListener(
            newPolygon,
            "mousedown",
            handleMouseDown
          );
          google.maps.event.addListener(newPolygon, "mouseup", handleMouseUp);
        }
      });
    }
  }, [map, polygon]);
  // [END maps_react_map_polygon_component_event_hooks]

  return null;
};

export const GoogleMapComponent = ({
  isDriver,
  isDeliveryCompany,
  disableControll,
  pointTypeForm,
  mapCenter,
  mapZoom,
  initialZoom,
  wrapStyle,
  style,
  markerOptions,
  onClick,
  onDbclick,
  onClickMarker,
  onDbclickMarker,
  onDragstartMarker,
  onDragendMarker,
  onMouseOverMarker
}: {
  isDriver?: boolean;
  isDeliveryCompany?: boolean;
  disableControll?: boolean;
  pointTypeForm?: Form<Point>;
  mapCenter?: google.maps.LatLngLiteral;
  mapZoom?: number;
  initialZoom?: number;
  wrapStyle?: CSSProperties;
  style?: CSSProperties;
  markerOptions?: (google.maps.MarkerOptions & { data?: any })[];
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onDbclick?: (e: google.maps.MapMouseEvent) => void;
  onClickMarker?: (
    e: google.maps.MapMouseEvent,
    marker: google.maps.Marker,
    data?: any
  ) => Point | undefined;
  onDbclickMarker?: (e: google.maps.MapMouseEvent, data?: any) => void;
  onDragstartMarker?: (
    e: google.maps.MapMouseEvent,
    data?: any
  ) => Point | undefined;
  onDragendMarker?: (e: google.maps.MapMouseEvent, data?: any) => void;
  onMouseOverMarker?: (
    e: google.maps.MapMouseEvent,
    marker: google.maps.Marker,
    data?: any
  ) => void;
}) => {
  const [zoom, setZoom] = useState(initialZoom ?? 18); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 35.68108367173078,
    lng: 139.76594462580132,
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const prevPoint = useRef<Point>();

  useEffect(() => {
    if (
      mapCenter?.lat &&
      mapCenter.lng &&
      center.lat === 35.68108367173078 &&
      center.lng === 139.76594462580132
    ) {
      setCenter(mapCenter);
    }
  }, [mapCenter]);

  useEffect(() => {
    if (mapZoom) {
      setZoom(mapZoom);
    }
  }, [mapZoom]);

  return (
    <div style={{ display: "flex", height: "100%", ...wrapStyle }}>
      <Wrapper
        apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY ?? ""}
        render={render}
      >
        <Map
          map={map}
          setMap={setMap}
          isDriver={isDriver}
          prevPoint={prevPoint}
          pointTypeForm={pointTypeForm}
          center={center}
          zoom={zoom}
          setZoom={setZoom}
          streetViewControl={!disableControll}
          mapTypeControl={!disableControll}
          zoomControl={false}
          disableDoubleClickZoom={true}
          style={{
            flexGrow: "1",
            height: "344px",
            width: "755px",
            ...(style as { [key: string]: string }),
          }}
          setDrawerOpen={setDrawerOpen}
          onClick={(e) => {
            onClick && onClick(e);
            if (isDeliveryCompany) {
              // 行先編集画面ではアイコン登録をしない
              return;
            };
            setDrawerOpen(true);
          }}
          onDbclick={(e) => {
            onDbclick && onDbclick(e);
          }}
        >
          {isDriver && (
            <>
              <LongPressablePolygon
                map={map}
                // setCenter={setCenter}
                setZoom={setZoom}
                pointTypeForm={pointTypeForm}
                setDrawerOpen={setDrawerOpen}
              />
            </>
          )}
          {pointTypeForm !== undefined && (
            <TaskRegistrationDrawer
              pointTypeForm={pointTypeForm}
              prevPoint={prevPoint}
              setDrawerOpen={setDrawerOpen}
              drawerOpen={drawerOpen}
              isDriver={isDriver}
            />
          )}
          {markerOptions?.map((option, index) => {
            const { data, ...rest } = option;
            return (
              <Marker
                visible={false}
                key={index}
                {...rest}
                onClickMarker={(e, marker) => {
                  if (onClickMarker) {
                    !isDeliveryCompany && setDrawerOpen(true);
                    // prevPointとしてドラッグ前のpointを保持しておかないと、pointの変更が元に戻る。
                    const newPoint = onClickMarker(e, marker, {
                      ...data,
                      point: prevPoint.current,
                    });
                    prevPoint.current = newPoint;
                  }
                }}
                onDbclickMarker={(e) => {
                  if (onDbclickMarker) {
                    onDbclickMarker(e, data);
                  }
                }}
                onDragstartMarker={(e) => {
                  if (onDragstartMarker) {
                    setCenter({
                      lat: map?.getCenter()?.lat(),
                      lng: map?.getCenter().lng(),
                    });
                    // prevPointとしてドラッグ前のpointを保持しておかないと、pointの変更が元に戻る。
                    const newPoint = onDragstartMarker(e);
                    prevPoint.current = newPoint;
                  }
                }}
                onDragendMarker={(e) => {
                  if (onDragendMarker) {
                    setCenter({
                      lat: map?.getCenter()?.lat(),
                      lng: map?.getCenter().lng(),
                    });
                    onDragendMarker(e, { ...prevPoint.current });
                  }
                }}
                onMouseOverMarker={(e, marker) => {
                  if (onMouseOverMarker) {
                    onMouseOverMarker(e, marker);
                  }
                }}
              />
            );
          })}
        </Map>
      </Wrapper>
    </div>
  );
};
