import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useDownloadApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import { Delivery, DeliveryForm, DeliverySearchForm } from "entities/delivery";
import { ID, PagingResponse } from "entities";

export type DeliveriesResponse = PagingResponse<Delivery>;

export function useFetchDeliveriesApi(
  searchForm?: Form<DeliverySearchForm>
): IndexApiSet<DeliveriesResponse> & { execute: (query?: string) => void } {
  const apiPath = "delivery";
  const api = useIndexApi<DeliveriesResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type DeliveryResponse = BaseResponse & {
  data: Delivery;
};

export function useFetchDeliveryApi(): ApiSet<DeliveryResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<DeliveryResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `delivery/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostDeliveryApi(): ApiSet<DeliveryResponse> & {
  execute: (form: Form<DeliveryForm>) => void;
} {
  const apiSet = usePostApi<DeliveryResponse, DeliveryForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (form: Form<DeliveryForm>) => {
    const apiPath = `delivery`;
    apiSet.execute(apiPath, form);
  };

  return { ...apiSet, execute };
}

export function useEditDeliveryApi(): ApiSet<BaseResponse> & {
  execute: (object: DeliveryForm) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, DeliveryForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (object: DeliveryForm) => {
    const apiPath = `delivery/${object.id}`;
    apiSet.execute(apiPath, object);
  };

  return { ...apiSet, execute };
}

export function useDeleteDeliveryApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `delivery/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export function useCloseDeliveryApi(): ApiSet<DeliveryResponse> & {
  execute: (idsForm: Form<{ ids: ID[] }>) => void;
} {
  const apiSet = usePostApi<DeliveryResponse, { ids: ID[] }>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (idsForm: Form<{ ids: ID[] }>) => {
    const apiPath = `delivery_convert`;
    apiSet.execute(apiPath, idsForm);
  };

  return { ...apiSet, execute };
}

type DeliverySwapForm = {
  ids: {
    id: ID;
    no: number;
  }[];
};

export function useSwapDeliveryApi(): ApiSet<BaseResponse> & {
  execute: (object: DeliverySwapForm) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, DeliverySwapForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (object: DeliverySwapForm) => {
    const apiPath = `delivery_swap`;
    apiSet.execute(apiPath, object);
  };

  return { ...apiSet, execute };
}
