import { Select, Space, TableProps, Tag, theme } from "antd";
import { ColumnsType } from "antd/lib/table";

import { BaseSearchForm, ID } from "entities";
import dayjs from "dayjs";
import { HistoryProps } from "routes/app";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { Contract, Status } from "entities/contract";
import { useEffect, useState } from "react";
import { Key } from "antd/es/table/interface";
import { queryString } from "utils/util";
import { statusTag } from "specifics/status_tag";
type ContractsTableProps = TableProps<Contract>;

export const ContractInputRequestTable = (
  props: ContractsTableProps &
    HistoryProps & {
      query: BaseSearchForm;
      selectable: boolean;
      selectedManuals: Contract[];
      setSelectedManuals: React.Dispatch<React.SetStateAction<Contract[]>>
    }
) => {
  const { query, dataSource, selectable, selectedManuals, setSelectedManuals, ...rest } = props;
  const { token } = theme.useToken();
  const table = useTable<Contract>(props);

  const idsForm = useForm<{ ids: ID[] }>({ ids: [] });

  useEffectSkipFirst(() => {
    idsForm.updateObject("ids", table.selectedRowKeys);
  }, [table.selectedRowKeys]);


  const columns: ColumnsType<Contract> = [
    {
      ...table.baseColumn("title"),
      sorter: (a, b) => ((a.title || "") > (b.title || "") ? 1 : -1),
      title: "カルテタイトル",
      width: 430,
      render: (item) =>
        item.title || `${item.deliveryCompany?.name}-${item.consignor?.name}`,
    },
    {
      ...table.baseColumn("deliveryCompany"),
      title: "行先",
      width: 280,
      render: (record) => record.deliveryCompany.name
    },
    {
      ...table.baseColumn("status"),
      sorter: (a, b) => ((a.status || "") > (b.status || "") ? 1 : -1),
      title: "ステータス",
      width: 128,
      render: (item) => item.status < Status.その他 && statusTag(item.status, token)
    },
    {
      ...table.baseColumn("counts"),
      sorter: (a, b) => ((a.counts || "") > (b.counts || "") ? 1 : -1),
      title: "件数",
      width: 128,
      render: (item) => item.counts > 0 ? item.counts : "--"
    },
    {
      ...table.baseColumn("updatedAt"),
      sorter: (a, b) => ((a.manualUpdatedAt || "") > (b.manualUpdatedAt || "") ? 1 : -1),
      title: "最終更新日",
      width: 128,
      render: (item) => item.manualUpdatedAt ? dayjs(item.manualUpdatedAt).format("YYYY/MM/DD") : "--",
    }
  ];

  const pagination = {
    current: props.pagination ? props.pagination.current || 0 : 0,
    pageSize: props.pagination ? props.pagination.pageSize || 0 : 0,
    total: props.pagination ? props.pagination.total || 0 : 0,
    start: 0,
    last: 0,
  };
  pagination.start = (pagination?.current - 1) * pagination?.pageSize + 1;
  pagination.last = pagination?.current * pagination?.pageSize;

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    if (selectable) {
      const newSelectedRows = dataSource?.filter(data => selectedRowKeys.includes(data.id || '') && !selectedManuals.includes(data));
      if (newSelectedRows?.length) {
        setSelectedManuals(prev => [...prev, ...newSelectedRows]);
      } else {
        setSelectedManuals(prev => prev.filter(manual => selectedRowKeys.includes(manual.id || '')));
      }
    }
  }, [selectedRowKeys]);

  // const onSelect = (record: any, selected: boolean) => {
  //   const currentIndex = selectedRowKeys.indexOf(record.key);
  //   const newRows = [...selectedManuals];

  //   if (selected) {
  //     newRows.push(record);
  //   } else {
  //     newRows.splice(currentIndex, 1);
  //   }
  //   setSelectedManuals(newRows);
  // };

  // const onSelectAll = (selected: boolean, selectedManuals: any, changeRows: Contract[]) => {
  //   const keysToChange = changeRows.map((row) => row.id);
  //   if (selected) {
  //     // 全選択の場合、現在のページのすべてのキーを追加
  //     setSelectedManuals(prev => [...prev, ...changeRows]);
  //   } else {
  //     // 選択解除の場合、現在のページのキーを削除
  //     setSelectedManuals(prev => prev.filter(row => !keysToChange.includes(row.id)));
  //   }
  // };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    preserveSelectedRowKeys: true,
    // onSelect,
    // onSelectAll
  };

  return (
    <CustomTable
      tableKey="contracts_table"
      style={{
        height: 620,
        overflowY: "scroll",
        paddingBottom: 32
      }}
      table={table}
      columns={columns}
      hideDisplaySetting={!selectable}
      extraLeft={
        <Space size={25} style={{ fontSize: 13 }}>
          {selectable ?
            <>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <span style={{ fontSize: 15, fontWeight: 500 }}>
                  {pagination.total}
                </span>
                件中&ensp;
                <span style={{ fontSize: 15, fontWeight: 500 }}>
                  {pagination.start}
                </span>
                ~
                <span style={{ fontSize: 15, fontWeight: 500 }}>
                  {pagination.total < pagination.last ? `${pagination.total}` : `${pagination.last}`}
                </span>
                件を表示
              </div>
              <div>
                表示件数
                <Select
                  style={{ width: 85, marginLeft: 6 }}
                  defaultValue={Number(query.pageSize)}
                  options={[
                    { value: 10, label: "10件" },
                    { value: 20, label: "20件" },
                    { value: 50, label: "50件" },
                    { value: 100, label: "100件" },
                  ]}
                  onChange={(value) =>
                    props.history.push(
                      `/operations/contracts/${queryString({
                        ...query,
                        pageSize: value,
                      })}`
                    )
                  }
                />
              </div>
            </> :
            <div>
              全&ensp;
              <span style={{ fontSize: 15, fontWeight: 500 }}>{selectedManuals.length}</span>
              &ensp;件
            </div>
          }
        </Space>
      }
      pagination={table.paginationInfo}
      showSizeChanger={false}
      rowSelection={selectable ? rowSelection : undefined}
      onChange={table.handleChange}
      dataSource={selectable ? dataSource?.map((d) => ({ ...d, key: d?.id })) : selectedManuals}
      {...rest}
    />
  );
};
