import { Space, TableProps, Tag, theme } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RoutesResponse, useEditRouteApi } from "api/route";

import { Route } from "entities/route";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import { IndexApiSet } from "utils/network/api_hooks";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { ID } from "entities";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm } from "utils/hooks";
import { useEffect, useState } from "react";
import { DeliveryCompany } from "entities/delivery_company";

type RoutesTableProps = TableProps<Route>;

export const RoutesTable = (
  props: RoutesTableProps &
    HistoryProps & { handleDelete: (id: ID) => void } & {
      listApi?: IndexApiSet<RoutesResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const { token } = theme.useToken();
  const table = useTable<Route>(props);
  const routeForm = useForm<Route>({});
  const editApi = useEditRouteApi();
  const [isDragging, setIsDragging] = useState(false);
  const [dataSource, setDataSource] = useState(props.dataSource ? [...props.dataSource] : []);

  useEffect(() => {
    if (routeForm.object.deliveryCompanies?.length) {
      editApi.execute(routeForm);
    }
  }, [routeForm.object.deliveryCompanies])

  const reorder = (list: DeliveryCompany[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const columns: ColumnsType<Route> = [
    {
      ...table.baseColumn("code"),
      ...table.getColumnSearchProps("code", "ルート名"),
      title: "ルートコード",
      width: 150,
      render: (item) => item.code,
      fixed: "left",
    },
    {
      ...table.baseColumn("deliveryCompanies"),
      title: "納入先",
      render: (_: any, item: Route, index: number) =>
        <DragDropContext
          onDragStart={() => setIsDragging(true)}
          onDragEnd={(result) => {
            if (!result.destination) return;
            const reorderedCompanies = reorder(
              item.deliveryCompanies || [],
              result.source.index,
              result.destination.index
            );
            routeForm.set({
              ...item,
              deliveryCompanies: reorderedCompanies
            });
            setDataSource(prev => {
              prev[index].deliveryCompanies = reorderedCompanies;
              return prev;
            })
            result.source.droppableId && document.getElementById(result.source.droppableId)?.click();
          }}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: "flex" }}
              >
                <Space style={{ maxHeight: 40, margin: 0 }} size={5}>
                  {item.deliveryCompanies?.map((company, index) => (
                    <Draggable key={company.id} draggableId={company.id || ""} index={index}>
                      {(provided, snapshot) => (
                        <Tag
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          color="default"
                          style={{
                            margin: 0,
                            background: snapshot.isDragging ? '#f4f4f4' : '',
                            boxShadow: snapshot.isDragging ? '0 2px 5px rgba(0,0,0,0.2)' : '',
                            ...provided.draggableProps.style,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            props.history.push(`/operations/delivery-companies/${company.id}`);
                          }}
                        >
                          <Space size={0} direction="vertical" style={{ alignItems: "center" }}>
                            <span style={{ border: "1px solid", padding: 1, borderRadius: "100%", width: 15, height: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>{index + 1}</span>
                            <span>{company.name}{company.point?.memo || ''}</span>
                          </Space>
                        </Tag>
                      )}
                    </Draggable>
                  ))}
                </Space>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
    },
    {
      ...table.baseColumn("url"),
      title: "運送条件合意書",
      width: 127,
      render: (item) => (
        <a href={item.url} target="_blank" rel="noreferrer" style={{ width: "100%", display: "inline-block", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {item.url}
        </a>
      )
    },
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 138,
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/operations/routes/${item.id}/edit`);
            }}
          >
            編集
          </CustomButton>
          <CustomButton
            style={{
              fontWeight: 500,
              padding: 0,
              height: "30px",
              width: "28px",
              textAlign: "left",
            }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              props.handleDelete(item.id);
            }}
            danger
            confirm
            popconfirmProps={{
              title: "削除します。よろしいですか？",
            }}
          >
            削除
          </CustomButton>
        </Space>
      ),
    },
  ];

  return (
    <CustomTable
      tableKey="routes_table"
      onChange={table.handleChange}
      table={table}
      pagination={table.paginationInfo}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () => {
          if (!isDragging) { // ドラッグ中でない場合のみ動作
            props.history.push(`/operations/routes/${data.id}`);
          }
        }
      })}
      {...props}
      dataSource={dataSource}
    />
  );
};