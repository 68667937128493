import { GlobalToken, Space, Tag, theme } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Status } from "entities/contract";

export const statusTag = (status: Status, token: GlobalToken) => {
  let backgroundColor = '';
  let textColor = '';
  let borderColor = ''
  if (status <= Status.一年経過) {
    backgroundColor = token.colorErrorActive;
    textColor = token.colorWhite;
    borderColor = backgroundColor;
  } else if (status <= Status.三ヶ月経過) {
    backgroundColor = token.colorErrorBg;
    textColor = token.colorErrorActive;
    borderColor = token.colorErrorActive;
  } else {
    backgroundColor = token.colorPrimaryBg;
    textColor = token.colorPrimaryText;
    borderColor = backgroundColor;
  }

  return (
    <Tag
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        borderColor: borderColor
      }}
    >
      <Space size={3}>
        {status <= Status.三ヶ月経過 && <ExclamationCircleOutlined />}
        {Status[status]}
      </Space>
    </Tag>
  );
};