import {
  useDeleteManualApi,
  useDeleteManualsApi,
  useFetchManualsApi,
} from "api/manual";
import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useQuery } from "utils/hooks";
import { ManualsTable } from "./manuals_table";
import { CustomButton } from "specifics/button";
import { ID } from "entities";
import { theme } from "antd";
import { queryString } from "utils/util";
import { useFetchAllDeliveryPartnerCompaniesApi } from "api/delivery_partner_company";

const ManualsPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();
  const listApi = useFetchManualsApi();
  const query = useQuery<{
    page: number;
    pageSize: number;
    sorter: string;
    filter: string;
  }>();
  const deleteApi = useDeleteManualApi();
  const deleteManualsApi = useDeleteManualsApi();
  const allDeliveryCompaniesApi = useFetchAllDeliveryPartnerCompaniesApi()

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      listApi.execute(queryString(query));
    } else {
      props.history.push(`/operations/manuals/${queryString()}`);
    }
  }, [JSON.stringify(query)]);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  useEffect(() => {
    allDeliveryCompaniesApi.execute();
  }, []);

  const manuals = () => listApi.response.data;
  const paginationInfo = () => listApi.response.meta;

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading || deleteManualsApi.loading);
    if (deleteApi.isSuccess() || deleteManualsApi.isSuccess()) {
      listApi.execute(queryString(query));
    }
  }, [deleteApi.loading, deleteManualsApi.loading]);
  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      // subTitle={
      //   <CustomInputField
      //     style={{ width: 320, marginLeft: 20 }}
      //     fieldProps={{
      //       prefix: <SearchOutlined color="#9CA3AF" />,
      //     }}
      //     placeholder={"納入カルテを検索"}
      //     attr="id"
      //     form={searchForm}
      //   />
      // }
      title="納入カルテ"
      extra={[
        <CustomButton
          type="primary"
          key={"createManual"}
          onClick={() => props.history.push("/operations/manuals/share")}
        >
          共有
        </CustomButton>,
        <CustomButton
          type="primary"
          key={"createManual"}
          onClick={() => props.history.push("/operations/manuals/create")}
        >
          納入カルテ作成
        </CustomButton>,
      ]}
    >
      <ManualsTable
        handleDelete={(id: ID) => deleteApi.execute(id)}
        handleBulkDelete={(ids: ID[]) => {
          deleteManualsApi.execute({ ids });
        }}
        history={props.history}
        dataSource={manuals()}
        pagination={{
          current: paginationInfo()?.currentPage,
          pageSize: paginationInfo()?.perPage,
          total: paginationInfo()?.total,
        }}
        query={query}
        deliveryPartnerCompanies={allDeliveryCompaniesApi.response.data || []}
      />
    </CustomPageHeader >
  );
};

export default withRouter(ManualsPage);
