import { Spin, message } from "antd";
import { useCheckManualExistApi } from "api/manual";
import { useEffect } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { useQuery } from "utils/hooks";

const FindManualPage = (props: HistoryProps) => {
  const query = useQuery<{ consignorId: string; deliveryCompanyId: string }>();
  const checkExistManualApi = useCheckManualExistApi();
  useEffect(() => {
    if (query.deliveryCompanyId && query.consignorId) {
      checkExistManualApi.execute(query.deliveryCompanyId, query.consignorId);
    } else {
      props.history.goBack();
    }
  }, []);
  useEffect(() => {
    if (checkExistManualApi.isSuccess()) {
      const response = checkExistManualApi.response.data;
      if (response.id) props.history.replace(`/manuals/${response.id}`);
      else {
        message.info("手順書は未作成です");
        props.history.replace(
          `/manuals/create?deliveryCompanyId=${query.deliveryCompanyId}&consignorId=${query.consignorId}`
        );
      }
    }
  }, [checkExistManualApi.loading]);
  return <Spin />;
};

export default withRouter(FindManualPage);
