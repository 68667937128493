import {
  ApiSet,
  BaseResponse,
  IndexApiSet,
  useDeleteApi,
  useDownloadApi,
  useIndexApi,
  usePostApi,
  usePostObjectApi,
  useShowApi,
} from "utils/network/api_hooks";
import { Form, useEffectSkipFirst } from "utils/hooks";
import { HttpClient } from "../utils/network/axios";

import { Order, OrderForm, OrderSearchForm } from "entities/order";
import { ID, PagingResponse } from "entities";

export type OrdersResponse = PagingResponse<Order>;

export function useFetchOrdersApi(
  searchForm?: Form<OrderSearchForm>
): IndexApiSet<OrdersResponse> & { execute: (query?: string) => void } {
  const apiPath = "order";
  const api = useIndexApi<OrdersResponse>(new HttpClient(), {
    initialResponse: {
      data: [],
      links: {
        first: "",
        last: "",
        prev: null,
        next: null,
      },
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        path: "",
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
    initialState: {
      page: searchForm?.object?.page || 1,
      perPage: searchForm?.object?.perPage || 100000000,
    },
  });

  const execute = (query?: string): void => {
    api.execute(apiPath + (query ? query : ""));
  };

  return { ...api, execute };
}

export type OrderResponse = BaseResponse & {
  data: Order;
};

export function useFetchOrderApi(): ApiSet<OrderResponse> & {
  execute: (id: ID) => void;
} {
  const api = useShowApi<OrderResponse>(new HttpClient(), {
    initialResponse: { data: {} },
  });

  const execute = (id: ID): void => {
    const apiPath = `order/${id}`;
    api.execute(apiPath);
  };

  return {
    ...api,
    isSuccess: () => !api.loading && !api.isError,
    execute,
  };
}

export function usePostOrderApi(): ApiSet<OrderResponse> & {
  execute: (order: OrderForm) => void;
} {
  const apiSet = usePostObjectApi<OrderResponse, OrderForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (order: OrderForm) => {
    const apiPath = `order`;
    apiSet.execute(apiPath, order);
  };

  return { ...apiSet, execute };
}

export function usePostOrdersApi(): ApiSet<OrderResponse> & {
  execute: (orders: OrderForm[]) => void;
} {
  const apiSet = usePostObjectApi<OrderResponse, { orders: OrderForm[] }>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (orders: OrderForm[]) => {
    const apiPath = `bulk_create_order`;
    apiSet.execute(apiPath, { orders });
  };

  return { ...apiSet, execute };
}

export function useEditOrderApi(): ApiSet<BaseResponse> & {
  execute: (order: OrderForm, setLoading?: boolean) => Promise<void>;
} {
  const apiSet = usePostObjectApi<BaseResponse, OrderForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = async (order: OrderForm, setLoading?: boolean) => {
    const apiPath = `order/${order.id}`;
    await apiSet.execute(apiPath, order, setLoading);
  };

  return { ...apiSet, execute };
}

export function useDeleteOrderApi(): ApiSet<BaseResponse> & {
  execute: (id: ID) => void;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = (id: ID): void => {
    const apiPath = `order/${id}`;
    api.execute(apiPath);
  };

  return { ...api, execute };
}

export function useDeleteOrdersApi(): ApiSet<BaseResponse> & {
  execute: (idsForm: { ids: ID[] }) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, { ids: ID[] }>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (idsForm: { ids: ID[] }) => {
    const apiPath = `bulk_delete_order`;
    apiSet.execute(apiPath, idsForm);
  };

  return { ...apiSet, execute };
}

export function useCloseOrdersApi(): ApiSet<OrderResponse> & {
  execute: (idsForm: { ids: ID[] }) => void;
} {
  const apiSet = usePostObjectApi<OrderResponse, { ids: ID[] }>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = (idsForm: { ids: ID[] }) => {
    const apiPath = `order_convert`;
    apiSet.execute(apiPath, idsForm);
  };

  return { ...apiSet, execute };
}

export function useCancelCloseOrdersApi(): ApiSet<BaseResponse> & {
  execute: (idsForm: { ids: ID[] }) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, { ids: ID[] }>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (idsForm: { ids: ID[] }) => {
    const apiPath = `order_convert_cancel`;
    apiSet.execute(apiPath, idsForm);
  };

  return { ...apiSet, execute };
}

type OrderSwapForm = {
  ids: {
    id: ID;
    turnInRotation: number;
  }[];
};

export function useSwapOrderApi(): ApiSet<BaseResponse> & {
  execute: (object: OrderSwapForm) => void;
} {
  const apiSet = usePostObjectApi<BaseResponse, OrderSwapForm>(
    new HttpClient(),
    {
      initialResponse: {},
    },
    { formatJson: true }
  );

  const execute = (object: OrderSwapForm) => {
    const apiPath = `order_swap`;
    apiSet.execute(apiPath, object);
  };

  return { ...apiSet, execute };
}

export function useDeleteOrderDividedApi(): ApiSet<BaseResponse> & {
  execute: (id: ID, setLoading?: boolean) => Promise<void>;
} {
  const api = useDeleteApi<BaseResponse>(new HttpClient(), {
    initialResponse: {},
  });

  const execute = async (id: ID, setLoading?: boolean) => {
    const apiPath = `order_divided/${id}`;
    await api.execute(apiPath, setLoading);
  };

  return { ...api, execute };
}
