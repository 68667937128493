import { Button, Modal, ModalProps, Space, UploadFile, message, Image } from "antd";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { IssueForm } from "entities/issue";
import { CustomFileField, CustomSelectField, CustomTextAreaField } from "specifics/input";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { usePostIssueApi } from "api/issue";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import dayjs from "dayjs";
import { DeliveryCompany } from "entities/delivery_company";
import { GlobalStateContext } from "contexts/global_state_context";
import { useContext, useEffect, useState } from "react";
import { useUploadApi } from "api/uplaod";

export const IssueModal = (
  props: ModalProps & HistoryProps & { deliveryCompany: DeliveryCompany; guest?: boolean; delivererId?: string; }
) => {
  const globalState = useContext(GlobalStateContext);
  const postApi = usePostIssueApi();
  const uploadApi = useUploadApi();
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>();

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      props.guest ?
        message.success(
          "報告が完了しました。"
        ) :
        props.history.push("/operations/issues");
    }
  }, [postApi.loading]);
  const initialForm: IssueForm = {
    category: "納入カルテに関すること",
    issueDate: dayjs().format(),
    userId: props.delivererId,
    deliveryCompanyId: props.deliveryCompany?.id
  };
  const issueForm = useForm<IssueForm>(initialForm);

  useEffect(() => {
    issueForm.updateObject("deliveryCompanyId", props.deliveryCompany.id);
  }, [props.deliveryCompany?.id]);

  return (
    <Modal
      footer={null}
      closeIcon={<></>}
      width={720}
      style={{ borderRadius: "16px" }}
      bodyStyle={{
        borderRadius: "16px",
        padding: 16,
      }}
      {...props}
    >
      <Space
        direction="vertical"
        style={{ width: "100%", alignItems: "center" }}
        size={12}
      >
        <Space
          direction="vertical"
          size={12}
          style={{ textAlign: "center", width: 680 }}
        >
          <div style={{ fontSize: 18, fontWeight: 500, color: "#111827" }}>
            この行先について運行管理者に何か報告すべきことがありましたか？
          </div>
          <div style={{ color: "#6B7280" }}>
            運行管理者に{globalState.user.name}さんがこの行先について「報告すべきことがあります」というメッセージを送ります
          </div>
          <Space size={8} direction="vertical" style={{ width: "100%" }}>
            <CustomSelectField
              form={issueForm}
              attr={"category"}
              style={{ width: "100%" }}
              selectItems={[
                {
                  label: "納入カルテに変更/誤りがある",
                  value: "納入カルテに変更/誤りがある",
                },
                {
                  label: "座標(地図の位置)が正確でない",
                  value: "座標(地図の位置)が正確でない",
                },
                {
                  label: "納品開始",
                  value: "納品開始",
                },
                {
                  label: "納品完了",
                  value: "納品完了",
                },
                {
                  label: "出荷準備完了",
                  value: "出荷準備完了",
                },
                {
                  label: "積込み完了",
                  value: "積込み完了",
                },
                {
                  label: "出発前",
                  value: "出発前",
                },
                {
                  label: "降し地到着",
                  value: "降し地到着",
                },
                {
                  label: "その他",
                  value: "その他",
                },
              ]}
            />
            <CustomSelectField
              form={issueForm}
              placeholder="送り先"
              attr={"toUserId"}
              style={{ width: "100%" }}
              selectItems={globalState.users.map(user => ({
                label: user.name,
                value: user.id
              }))}
            />
            <CustomTextAreaField
              fieldProps={{ placeholder: "説明" }}
              form={issueForm}
              style={{ width: "100%" }}
              attr="comment"
            />
            {issueForm.object.img &&
              <div
                style={{ position: "relative", display: "inline-block" }}
              >
                <Image
                  preview={false}
                  src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${issueForm.object.img}`}
                />
                <ArrowsAltOutlined
                  width={29}
                  height={29}
                  style={{
                    color: "white",
                    background: "rgba(0,0,0,0.7)",
                    padding: 5,
                    borderRadius: "100%",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                  onClick={() => setIsPreviewVisible(true)}
                />
                <Modal
                  open={isPreviewVisible}
                  footer={
                    <Button
                      style={{
                        fontWeight: "bold",
                        fontSize: 16,
                        width: "100%",
                      }}
                      onClick={() => setIsPreviewVisible(false)}
                    >
                      閉じる
                    </Button>
                  }
                  closable={false}
                  onCancel={() => setIsPreviewVisible(false)}
                >
                  <img
                    alt="Preview"
                    style={{ width: "100%" }}
                    src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${issueForm.object.img}`}
                  />
                </Modal>
              </div>
            }
            <CustomFileField
              form={issueForm}
              attr="img"
              fieldProps={{ showUploadList: false }}
              onChange={async (e) => {
                if (e.file.status !== "removed") {
                  globalState.setLoading(true);
                  const result = await uploadApi.execute(
                    e.file as UploadFile<any>
                  );
                  issueForm.updateObject(
                    "img",
                    result.data.filename
                  );
                  globalState.setLoading(false);
                }
              }}
            />
            <Button
              danger
              disabled={!issueForm.getValue("img")}
              type="primary"
              onClick={() => {
                issueForm.updateObject(
                  "img",
                  undefined
                );
              }}
            >
              画像を削除
            </Button>
          </Space>
        </Space>
        <CustomButton
          type="primary"
          style={{ width: 680 }}
          onClick={(e: any) => {
            postApi.execute(issueForm);
            if (props.onOk) props.onOk(e); // ToDo: 挙動確認
          }}
        >
          報告する
        </CustomButton>
        <CustomButton
          style={{ width: 680 }}
          onClick={(e: any) => {
            if (props.onCancel) props.onCancel(e); // ToDo: 挙動確認
          }}
        >
          閉じる
        </CustomButton>
      </Space>
    </Modal>
  );
};
