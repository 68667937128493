import { Button, Space, TableProps, theme } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IssuesResponse } from "api/issue";
import { GlobalStateContext } from "contexts/global_state_context";

import { ID } from "entities";
import { Issue } from "entities/issue";
import dayjs from "dayjs";
import { useContext } from "react";
import Highlighter from "react-highlight-words";
import { RiExternalLinkFill } from "react-icons/ri";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomTable } from "specifics/table";
import { CustomTableText } from "specifics/table_text";
import { CustomTag } from "specifics/tag";
import { useTable } from "specifics/use_table";
import { IndexApiSet } from "utils/network/api_hooks";

type IssuesTableProps = TableProps<Issue>;

export const IssuesTable = (
  props: IssuesTableProps &
    HistoryProps & { handleSolve: (id: ID) => void } & {
      listApi?: IndexApiSet<IssuesResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const table = useTable<Issue>(props);
  const columns: ColumnsType<Issue> = [
    {
      ...table.baseColumn("isSolved"),
      title: "ステータス",
      width: 130,
      onFilter: (value: string | number | boolean, record: Issue) =>
        !record.isSolved === !value,
      filters: [
        {
          text: "未承認",
          value: 0,
        },
        {
          text: "承認済",
          value: 1,
        },
      ],
      render: (item) => (
        <CustomTag
          style={{
            cursor: "default",
            color: !item.isSolved ? token.colorWhite : "#000",
            background: !item.isSolved ? "#1d2645" : "#fafafa",
          }}
        >
          {item.isSolved ? "承認済" : "未承認"}
        </CustomTag>
      ),
    },
    {
      ...table.baseColumn("createdAt"),
      title: "報告日時",
      width: "127px",
      render: (item) => (
        <>
          <div>{dayjs(item.createdAt).format("YYYY/MM/DD")}</div>
          <div>{dayjs(item.createdAt).format("HH:mm")}</div>
        </>
      ),
      sorter: (a, b) => ((a.createdAt ?? "") > (b.createdAt ?? "") ? 1 : -1),
    },
    {
      ...table.baseColumn("toUser.name"),
      filters: globalState.users.map((user, index) => ({
        text: user.name ?? "",
        value: user.name ?? "",
      })),
      onFilter: (value: string | number | boolean, record: Issue) =>
        record.toUser?.name === value,
      title: "宛先",
      width: 150,
      render: (item) => (
        <Space size={0}>
          <CustomTableText width={100}>{item.toUser?.name}</CustomTableText>
          {item.user && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.history.push(`/ operations / users / ${item.user.id}`);
              }}
              shape="circle"
              type="text"
              icon={<RiExternalLinkFill />}
            />
          )}
        </Space>
      ),
    },
    {
      ...table.baseColumn("user.name"),
      filters: globalState.users.map((user, index) => ({
        text: user.name ?? "",
        value: user.name ?? "",
      })),
      onFilter: (value: string | number | boolean, record: Issue) =>
        record.user?.name === value,
      title: "報告者名称",
      width: 150,
      render: (item) => (
        <Space size={0}>
          <CustomTableText width={100}>{item.user?.name || item.deliveryPartnerCompany?.name}</CustomTableText>
          {item.user && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.history.push(`/ operations / users / ${item.user.id}`);
              }}
              shape="circle"
              type="text"
              icon={<RiExternalLinkFill />}
            />
          )}
        </Space>
      ),
    },
    {
      ...table.baseColumn("user.mobile"),
      ...table.getColumnSearchProps("user", "報告者電話番号"),
      title: "報告者電話番号",
      width: 180,
      render: (item) => {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[table.searchText]}
            autoEscape
            textToHighlight={item.user?.mobile || item.deliveryPartnerCompany?.tel}
          />
        );
      },
      onFilter: (value, record) =>
        (record.user?.mobile || "")
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
    },
    {
      ...table.baseColumn("deliveryCompany.name"),
      ...table.getColumnSearchProps("deliveryCompany", "行先"),
      title: "行先",
      width: "240px",
      onFilter: (value, record) =>
        (record.deliveryCompany?.name || "")
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      render: (item) => (
        <Space size={0}>
          <CustomTableText width={190}>
            {item.deliveryCompany?.name}
          </CustomTableText>
          {item.deliveryCompany && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.history.push(
                  `/ operations / delivery - companies / ${item.deliveryCompany.id}`
                );
              }}
              shape="circle"
              type="text"
              icon={<RiExternalLinkFill />}
            />
          )}
        </Space>
      ),
    },
    {
      key: "content",
      title: "内容",
      align: "left",
      width: "550px",
      render: (item: Issue) =>
        item.category ? (
          <Space direction="vertical" size={8}>
            <div
              style={{
                background: "#F3F4F6",
                borderRadius: 4,
                width: "max-content",
                padding: "2px 12px",
              }}
            >
              {item.category}
            </div>
            <div style={{ overflow: "revert" }}>{item.comment ?? "--"}</div>
          </Space>
        ) : (
          "--"
        ),
    },
    {
      key: "action",
      title: "アクション",

      width: "120px",
      render: (item: Issue) => (
        <Space direction="vertical" size={12}>
          <CustomButton
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              item.id && props.handleSolve(item.id);
            }}
            disabled={!!item.isSolved}
            style={{
              height: 30,
              fontWeight: 500,
              borderRadius: 4,
              fontSize: 12,
              padding: "7xp 11px",
            }}
            confirm
            popconfirmProps={{
              title: "この報告を承認しますか？",
            }}
          >
            {item.isSolved ? "承認済" : "承認"}
          </CustomButton>
          {/* {item.isSolved && (
            <CustomButton
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{
                padding: 0,
                fontWeight: 500,
                fontSize: 12,
                height: 16,
              }}
              type="text"
            >
              キャンセル
            </CustomButton>
          )} */}
        </Space>
      ),
    },
  ];
  return (
    <CustomTable
      tableKey="issues_table"
      onChange={table.handleChange}
      table={table}
      pagination={table.paginationInfo}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () =>
          props.history.push(`/operations/issues/${data.id}`),
      })}
      {...props}
    />
  );
};
