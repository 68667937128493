import { Col, Divider, Row, Skeleton, Space, theme } from "antd";
import { useEditSettingApi } from "api/setting";
import { GlobalStateContext } from "contexts/global_state_context";
import { DefaultSetting, SettingForm } from "entities/setting";

import { useContext } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { DescriptionBlock } from "specifics/description";

import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import SettingFormItem from "./setting_form_item";

const SettingsPage = (props: HistoryProps) => {
  const editApi = useEditSettingApi();

  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const form = useForm<SettingForm>({ content: { ...globalState.setting.customSetting } });
  const validationResultForm = useForm<SettingForm>({});

  const defaultSettings = globalState.setting.defaultSetting || {};

  useEffectSkipFirst(() => {
    // formが空の時、globalstateから初期設定をローディング
    if (!Object.keys(form.object.content || {}).length) {
      form.set({
        content: { ...globalState.setting.customSetting }
      });
    }
  }, [globalState.setting]);

  useEffectSkipFirst(() => {
    if (editApi.isSuccess()) {
      // 設定更新api実行時にglobalstateの設定も更新
      globalState.setSetting({
        ...editApi.response.data,
        defaultSetting: defaultSettings
      })
    }
  }, [editApi.loading]);

  const onSave = () => { editApi.execute(form.object) };
  const onDefalut = (category: string, key: string) => {
    const defaultValue = defaultSettings[category]?.find(setting => setting.key === key)?.value as string;
    form.updateObject(["content", key], defaultValue); // formの更新
    editApi.execute({                                  // 更新APIへの更新内容送信
      ...form.object,
      content: {
        ...form.object.content,
        [key]: defaultValue
      }
    });
  };

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title={
        <Space align="center">
          <div
            style={{
              fontWeight: 700,
              fontSize: "18px",
            }}
          >
            設定
          </div>
        </Space>
      }
      onBack={() => props.history.goBack()}
      childrenStyle={{
        padding: 32,
        margin: "auto",
        maxWidth: 720,
      }}
    >
      <Skeleton loading={editApi.loading || !Object.keys(form.object.content || {}).length}>
        {defaultSettings && Object.entries(defaultSettings).map(([category, defaultSetting], index) => (
          <>
            {index > 0 && <Divider />}
            <Row key={category}>
              <Col span={4}>
                <DescriptionBlock
                  labelStyle={{ fontSize: 15 }}
                  label={category}
                >
                  <></>
                </DescriptionBlock>
              </Col>
              <Col span={1}>
                <Divider style={{ height: "100%" }} type="vertical" />
              </Col>
              <Col span={19}>
                {defaultSetting?.map((defaultSetting: DefaultSetting, index) =>
                  <DescriptionBlock
                    key={defaultSetting.id}
                    labelStyle={{ fontSize: 15, marginTop: index > 0 ? 32 : 0 }}
                    label={defaultSetting.label}
                  >
                    <span style={{ fontSize: 13, color: "#1C1E37", opacity: 0.8 }} className="description">
                      {defaultSetting.description}
                    </span>
                    <SettingFormItem
                      form={form}
                      validationResultForm={validationResultForm}
                      attr={defaultSetting.key as string}
                      defaultSetting={defaultSetting}
                      onDefault={onDefalut}
                      onSave={onSave}
                    />
                  </DescriptionBlock>
                )}
              </Col>
            </Row>
          </>
        ))}
        {/* <Row>
          <Col span={4}>
            <DescriptionBlock
              labelStyle={{ fontSize: 15 }}
              label={`実態の記入依頼`}
            >
              <></>
            </DescriptionBlock>
          </Col>
          <Col span={1}>
            <Divider style={{ height: "100%" }} type="vertical" />
          </Col>
          <Col span={19}>
            {defaultSetting?.map((defaultSetting: DefaultSetting, index) =>
              <DescriptionBlock
                key={defaultSetting.id}
                labelStyle={{ fontSize: 15, marginTop: index > 0 ? 32 : 0 }}
                label={defaultSetting.label}
              >
                <span style={{ fontSize: 13, color: "#1C1E37", opacity: 0.8 }} className="description">
                  {defaultSetting.description}
                </span>
                <SettingFormItem
                  form={form}
                  validationResultForm={validationResultForm}
                  attr={defaultSetting.key as string}
                  defaultSetting={defaultSetting}
                  onDefault={onDefalut}
                  onSave={onSave}
                />
              </DescriptionBlock>
            )}
          </Col>
        </Row> */}
      </Skeleton>
    </CustomPageHeader>
  );
};

export default withRouter(SettingsPage);
