import {
  Button,
  Col,
  Divider,
  Image,
  Menu,
  Modal,
  Row,
  Skeleton,
  Space,
  Steps,
  Switch,
  Tag,
  message,
  theme,
} from "antd";
import { ArrowsAltOutlined, CarOutlined, MoreOutlined, EditOutlined } from "@ant-design/icons";
import { useDeleteManualApi, useFetchManualApi } from "api/manual";
import { GlobalStateContext } from "contexts/global_state_context";

import { Manual, ManualDetail, ManualKey, ManualMarkers } from "entities/manual";
import { useContext, useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst } from "utils/hooks";
import { DeliveryCompanyCardView } from "components/delivery_companies/delivery_companies_view";
import { GoogleMapComponent } from "utils/google_map_component";
import { useFetchTemplateApi } from "api/template";
import { Step, Template } from "entities/template";
import { DescriptionBlock } from "specifics/description";
import { copyToClipboard, highlightText, sanitize, sortQuestions } from "utils/util";
import { Point, PointType } from "entities/point";
import { ManualContractCardView } from "./manual_contract_card_view";
import { IssueModal } from "components/issues/issue_form";
import { MdLockOutline } from "react-icons/md";
import { QRCodeModalButton } from "components/shared/qrcodeModalButton";
import { ID } from "entities";
import { DeliveryPartnerCompanyCardView } from "components/delivery_partner_companies/delivery_partner_companies_view";
import { DeliveryCompany } from "entities/delivery_company";

export const ManualPage = (props: HistoryProps & { manualId?: ID; selectedManualId?: ID; guest?: boolean; isEdit?: boolean; delivererId?: string; width?: string; }) => {
  const globalState = useContext(GlobalStateContext);
  const manualApi = useFetchManualApi();
  const templateApi = useFetchTemplateApi();
  const deleteManualApi = useDeleteManualApi();
  const { token } = theme.useToken();
  const [selectedPointCompany, setSelectedPointCompany] = useState<DeliveryCompany>();
  const [selectedPointManual, setSelectedPointManual] = useState<Manual>();
  const [key, setKey] = useState<ManualKey>(ManualKey.基本情報);
  const [issueOpen, setIssueOpen] = useState(false);
  const [deliveryCompanyOpen, setDeliveryCompanyOpen] = useState(false);
  const [deliveryPartnerCompanyOpen, setDeliveryPartnerCompanyOpen] = useState(false);
  const [mismatchOpen, setMismatchOpen] = useState(false);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    params.id && manualApi.execute(params.id);
  }, [params.id]);
  useEffect(() => {
    if (!manualApi.loading) {
      props.manualId && manualApi.execute(props.manualId);
    }
  }, [props.manualId, props.selectedManualId]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteManualApi.loading);
    if (deleteManualApi.isSuccess()) {
      props.history.push("/operations/manuals");
    }
  }, [deleteManualApi.loading]);

  useEffectSkipFirst(() => {
    if (manualApi.isSuccess()) {
      const preSelectedPointManual = manualPoints().find(manual => manual.id === props.selectedManualId);
      setSelectedPointCompany(preSelectedPointManual?.deliveryPointCompany || manual().deliveryCompany);
      setSelectedPointManual(preSelectedPointManual || manual());
      if (!props.width && manual().templateId) {
        templateApi.execute(manualApi.response.data.templateId!);
      }
    }
  }, [manualApi.loading]);

  useEffectSkipFirst(() => {
    if (!props.width && selectedPointManual?.templateId) {
      templateApi.execute(selectedPointManual?.templateId);
    } else {
      globalState.setLoading(true);
      setTimeout(() => globalState.setLoading(false), 0);
    }
  }, [selectedPointCompany?.id]);

  const manual = () => manualApi.response.data;
  const manualUrl = () => `${process.env.REACT_APP_DRIVER_HOST}drivers/manuals/view/${selectedPointManual?.id}`;
  const manualPoints = () => [manual(), ...(manual().manuals || [])];
  const deliveryCompanyPoints = () => [manual().deliveryCompany, ...(manual().deliveryCompany?.deliveryCompanies || [])];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`
      }}
      height={props.guest ? { height: "100%", overflowY: "auto" } : {}}
      title={"納入カルテ詳細"}
      onBack={params.id && !props.guest ? () => props.history.goBack() : undefined}
      childrenStyle={{ display: "flex", justifyContent: "center", padding: params.id ? "initial" : 0 }}
      navMenu={props.guest ? undefined :
        <Menu style={{ border: "none", boxShadow: "none" }}>
          <Menu.Item
            style={{
              backgroundColor: "transparent",
              fontSize: 15,
              fontWeight: "normal",
            }}
            onClick={() => {
              if (manual()?.id) {
                copyToClipboard(manualUrl());
                message.success(
                  "クリップボードに納入カルテのリンクをコピーしました。"
                );
              }
            }}
          >
            リンクをコピー
          </Menu.Item>
          <Menu.Item
            style={{
              backgroundColor: "transparent",
              fontSize: 15,
              fontWeight: "normal",
            }}
          >
            <QRCodeModalButton
              pngName="手順書QRコード.png"
              text={manualUrl()}
              key={"QcCode"}
            />
          </Menu.Item>
          <Menu.Item style={{ backgroundColor: "transparent" }}>
            <CustomButton
              dialogconfirmProps={{
                title: "この納入カルテを削除しますか？",
                okText: "削除する",
                onOk: () => {
                  selectedPointManual?.id && deleteManualApi.execute(selectedPointManual.id);
                },
                cancelText: "戻る",
                okButtonProps: {
                  danger: true,
                  style: { background: "#FFEFEE" },
                },
                icon: null,
                centered: true,
                closable: false,
              }}
              danger
              style={{
                color: token.colorError,
                border: "none",
                fontSize: 15,
                padding: 0,
                fontWeight: "normal",
              }}
            >
              カルテを削除する
            </CustomButton>
          </Menu.Item>
        </Menu>
      }
    >
      <Skeleton loading={manualApi.loading || templateApi.loading}>
        <Space size={20} direction="vertical" style={{ width: params.id ? 872 : props.width ? props.width : "50vw", marginBottom: (props.guest && !props.isEdit) ? 0 : 70, }}>
          <Space
            direction="vertical"
            size={0}
            style={{
              width: "100%",
              background: token.colorWhite,
              border: 1,
              borderRadius: 4,
              fontSize: 18
            }}
          >
            <Row gutter={[10, 0]} style={{ padding: 24 }}>
              <Col span={24}>
                <div style={{ fontWeight: 700, fontSize: "22px" }}>
                  {selectedPointManual?.title ??
                    `${selectedPointManual?.deliveryCompany?.name ?? ""}-${selectedPointManual?.deliveryPartnerCompany?.name ?? ""}-${selectedPointManual?.consignor?.name ?? ""}`
                  }
                  {props.guest ? <></> :
                    <EditOutlined
                      style={{ marginLeft: 5, color: "#1C1E37", opacity: 0.5, cursor: "pointer" }}
                      onClick={() => selectedPointManual ?
                        props.history.push(
                          `/operations/manuals/${selectedPointManual.id}/edit?key=${key}`
                        ) :
                        props.history.push(
                          `/operations/manuals/create?manualId=${manual().id}&deliveryCompanyId=${manual().deliveryCompanyId}&deliveryPointCompanyId=${selectedPointCompany?.id}`
                        )
                      }
                    />
                  }
                </div>
                <Space direction="vertical" size={0} style={{ color: "#6B7280" }}>
                  <div>行先：<Button onClick={() => setDeliveryCompanyOpen(true)} type="link" style={{ padding: 0, height: "auto" }}>{selectedPointManual?.deliveryCompany?.name || selectedPointManual?.deliveryPointCompany?.name}</Button></div>
                  <Modal open={deliveryCompanyOpen} onCancel={() => setDeliveryCompanyOpen(false)} footer={null}
                    title={
                      <div style={{ fontSize: 20 }}>
                        行先情報
                        <Divider style={{ margin: "10px 0 0 0", width: "100%", borderWidth: 2 }} />
                      </div>
                    }
                  >

                    <DeliveryCompanyCardView
                      style={{ width: "100%" }}
                      deliveryCompany={selectedPointCompany}
                    />
                    <GoogleMapComponent
                      mapCenter={{
                        lat: selectedPointCompany?.point?.latitude || manual().deliveryCompany?.point?.latitude || 0,
                        lng: selectedPointCompany?.point?.longitude || manual().deliveryCompany?.point?.longitude || 0,
                      }}
                      markerOptions={[
                        {
                          position: {
                            lat: selectedPointCompany?.point?.latitude || manual().deliveryCompany?.point?.latitude || 0,
                            lng: selectedPointCompany?.point?.longitude || manual().deliveryCompany?.point?.longitude || 0,
                          },
                          visible: true,
                        }
                      ]}
                      onClickMarker={(e, marker) => {
                        const targetPointCompany = deliveryCompanyPoints().find(company =>
                          JSON.stringify([company?.point?.latitude, company?.point?.longitude]) ===
                          JSON.stringify([marker.getPosition()?.lat(), marker.getPosition()?.lng()]));
                        const infowindow = new google.maps.InfoWindow({
                          content: targetPointCompany?.point?.memo,
                        });
                        targetPointCompany && infowindow.open(marker?.getMap(), marker);
                      }}
                      style={{ width: "100%", margin: "8px 4px" }}
                    />
                    <CustomButton
                      style={{ width: "100%" }}
                      icon={<CarOutlined />}
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/dir/?api=1&destination=${manual().deliveryCompany?.point?.latitude
                          },${manual().deliveryCompany?.point?.longitude
                          }&travelmode=driving&dir_action=navigate`
                        )
                      }
                    >
                      ナビを起動する
                    </CustomButton>
                  </Modal>
                  <div>運送会社：<Button onClick={() => setDeliveryPartnerCompanyOpen(true)} type="link" style={{ padding: 0, height: "auto" }}>{selectedPointManual?.deliveryPartnerCompany?.name}</Button></div>
                  <Modal open={deliveryPartnerCompanyOpen} onCancel={() => setDeliveryPartnerCompanyOpen(false)} footer={null}
                    title={
                      <div style={{ fontSize: 20 }}>
                        運送会社情報
                        <Divider style={{ margin: "10px 0 0 0", width: "100%", borderWidth: 2 }} />
                      </div>
                    }
                  >
                    <DeliveryPartnerCompanyCardView deliveryPartnerCompany={selectedPointManual?.deliveryPartnerCompany} />
                  </Modal>
                  <div>
                    タグ：
                    {manual().tags?.length
                      ? manual().tags?.map((tag) => (
                        <Tag color="cyan" key={tag.id}>
                          {tag.name}
                        </Tag>
                      ))
                      : "なし"}
                  </div>
                </Space>
              </Col>
              {/* <Col
                span={6}
                style={{
                  fontWeight: 400,
                  fontSize: 12,
                  color: "#6B7280",
                }}
              >
                <Space direction="vertical">
                  <div>
                    <div>
                      作成者：
                      {
                        globalState.users.find((user) => user.id === selectedPointManual?.createdBy)?.name ||
                        globalState.users.find((user) => user.id === selectedPointManual?.updatedBy)?.name
                      }
                    </div>
                    <div>
                      {selectedPointManual?.createdAt
                        ? dayjs(selectedPointManual?.createdAt).format("YYYY/M/D")
                        : ""}
                    </div>
                  </div>
                  <div>
                    <div>
                      最終更新：
                      {
                        globalState.users.find((user) => user.id === selectedPointManual?.updatedBy)
                          ?.name
                      }
                    </div>
                    <div>
                      {selectedPointManual?.updatedAt
                        ? dayjs(selectedPointManual?.updatedAt).format("YYYY/M/D")
                        : ""}
                    </div>
                  </div>
                </Space>
              </Col> */}
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row style={{ cursor: "pointer" }}>
              {deliveryCompanyPoints().map((company, index) => (
                <Col key={index} flex={1} style={{ padding: "0 10px", textAlign: "center" }}>
                  <div
                    style={{
                      fontWeight: (company?.id === manual().deliveryCompany?.id && !selectedPointCompany) || selectedPointCompany?.id === company?.id ? 700 : 500,
                      fontSize: "16px",
                      lineHeight: "20px",
                      padding: "16px 0",
                      color: (company?.id === manual().deliveryCompany?.id && !selectedPointCompany) || selectedPointCompany?.id === company?.id ? token.colorPrimary : "#6B7280",
                      borderBottom:
                        (company?.id === manual().deliveryCompany?.id && !selectedPointCompany) || selectedPointCompany?.id === company?.id ? `3px solid ${token.colorPrimary}` : "none",
                    }}
                    onClick={() => {
                      setSelectedPointCompany(company);
                      const targetManual = manualPoints()?.find(manual =>
                        manual.deliveryPointCompanyId === company?.id ||
                        manual.deliveryCompanyId === company?.id
                      );
                      setSelectedPointManual(targetManual);
                    }}
                  >
                    {company?.point?.memo || company?.name}
                  </div>
                </Col>
              ))}
            </Row>
            <Row style={{ cursor: "pointer", display: props.width ? "none" : "flex" }}>
              <Col span={8} style={{ padding: "0 10px", textAlign: "center" }}>
                <div
                  style={{
                    fontWeight: key === ManualKey.基本情報 ? 700 : 500,
                    fontSize: "16px",
                    lineHeight: "20px",
                    padding: "16px 0",
                    color: key === ManualKey.基本情報 ? token.colorPrimary : "#6B7280",
                    borderBottom:
                      key === ManualKey.基本情報 ? `3px solid ${token.colorPrimary}` : "none",
                  }}
                  onClick={() => setKey(ManualKey.基本情報)}
                >
                  基本情報
                </div>
              </Col>
              <Col span={8} style={{ padding: "0 10px", textAlign: "center" }}>
                <div
                  style={{
                    fontWeight: key === ManualKey.手順 ? 700 : 500,
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: key === ManualKey.手順 ? token.colorPrimary : "#6B7280",
                    borderBottom:
                      key === ManualKey.手順 ? `3px solid ${token.colorPrimary}` : "none",
                    padding: "16px 0",
                  }}
                  onClick={() => setKey(ManualKey.手順)}
                >
                  手順
                </div>
              </Col>
              <Col span={8} style={{ padding: "0 10px", textAlign: "center" }}>
                <div
                  style={{
                    fontWeight: key === ManualKey.構内マップ ? 700 : 500,
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: key === ManualKey.構内マップ ? token.colorPrimary : "#6B7280",
                    borderBottom:
                      key === ManualKey.構内マップ ? `3px solid ${token.colorPrimary}` : "none",
                    padding: "16px 0",
                  }}
                  onClick={() => setKey(ManualKey.構内マップ)}
                >
                  構内マップ
                </div>
              </Col>
            </Row>
          </Space>
          <Space direction="vertical" size={0} style={{ width: "100%", background: token.colorWhite, }}>
            {/* 基本情報 */}
            <Row
              style={{
                display: key === ManualKey.基本情報 ? "flex" : "none",
                padding: "0 20px"
              }}
              gutter={params.id ? 40 : 0}
            >
              {/* 条件の不一致を見る */}
              <Col span={24}>
                <Space
                  direction="vertical"
                  style={{
                    fontSize: 14,
                    width: "100%",
                    padding: "10px 0"
                  }}
                >
                  <Skeleton loading={globalState.loading}>
                    <Row style={{ paddingTop: 8, alignItems: "center" }}>
                      <Col span={22}>
                        <Space
                          style={{ width: "100%" }}
                        >
                          契約との不一致を見る
                          <Switch defaultChecked={mismatchOpen} onChange={val => setMismatchOpen(val)} style={{ width: 34 }} />
                        </Space>
                      </Col>
                      <Col span={2} style={{ textAlign: "center" }}>
                        <MoreOutlined style={{ fontSize: 30, cursor: 'pointer', color: "#9CA3AF", transform: 'rotate(90deg)' }} />
                      </Col>
                    </Row>
                    <ManualContractCardView
                      style={{ width: "100%", marginTop: 4 }}
                      manual={selectedPointManual}
                      deliveryCompany={selectedPointCompany}
                      mismatchOpen={mismatchOpen}
                    />
                    {manual().file &&
                      <div style={{ position: 'relative', marginTop: 10, margin: "10px 4px 0 4px" }}>
                        <DescriptionBlock
                          labelStyle={{ fontSize: 16 }}
                          label="【ファイル】"
                        >
                          <></>
                        </DescriptionBlock>
                        <iframe
                          style={{ width: "100%", height: 500 }}
                          src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${manual().file}#toolbar=0&navpanes=0`}
                        >
                          このブラウザでは iframe がサポートされていません。
                        </iframe>
                        <ArrowsAltOutlined
                          width={29}
                          height={29}
                          style={{
                            color: "white",
                            background: "rgba(0,0,0,0.7)",
                            padding: 5,
                            borderRadius: "100%",
                            position: 'absolute',
                            bottom: '10px',
                            right: '10px',
                          }}
                          onClick={showModal}
                        />
                        <Modal
                          width="100%"
                          open={isModalVisible}
                          footer={
                            <Button style={{ fontWeight: "bold", fontSize: 16, width: "100%" }} onClick={handleCancel}>
                              閉じる
                            </Button>
                          }
                          closable={false}
                          onCancel={handleCancel}
                        >
                          <iframe
                            src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${manual().file}`}
                            style={{ width: '100%', height: '700px' }}
                          >
                            このブラウザでは iframe がサポートされていません。
                          </iframe>
                        </Modal>
                      </div>
                    }
                  </Skeleton>
                </Space>
              </Col>
            </Row>
            {/* 手順 */}
            <Space
              style={{ width: "100%", display: key === ManualKey.手順 ? "flex" : "none" }}
              direction="vertical"
            >
              {ManualPreview(selectedPointManual || {}, templateApi.response.data || {})}
            </Space>
            {/* 構内マップ */}
            <Space
              style={{ width: "100%", display: key === ManualKey.構内マップ ? "flex" : "none" }}
              direction="vertical"
            >
              <div style={{ fontSize: 18, fontWeight: "bold" }}>作業</div>
              <Space size={6} style={{ marginBottom: 10 }} wrap>
                <Row gutter={[8, 8]}>
                  {Object.keys(PointType)
                    .filter(
                      (key): key is keyof typeof PointType =>
                        isNaN(Number(key)) &&
                        PointType[key as keyof typeof PointType] !== PointType.削除 &&
                        PointType[key as keyof typeof PointType] !== PointType.地点
                    )
                    .map((label) => (
                      <Col span={8} key={label}>
                        <CustomButton
                          style={{
                            width: "100%",
                            display: "flex",
                            paddingLeft: 25,
                            justifyContent: "flex-start",
                            cursor: "default",
                            boxShadow: "unset",
                            fontWeight: "normal",
                            pointerEvents: "none",
                          }}
                        >
                          <Space>
                            <div style={{ paddingTop: 5 }}>
                              {
                                ManualMarkers.find(
                                  (manualMaker) =>
                                    manualMaker.pointType === PointType[label]
                                )?.componentIcon
                              }
                            </div>
                            {label}
                          </Space>
                        </CustomButton>
                      </Col>
                    ))}
                </Row>
              </Space>
              <Skeleton loading={globalState.loading}>
                <GoogleMapComponent
                  mapCenter={{
                    lat: selectedPointCompany?.point?.latitude || selectedPointManual?.deliveryCompany?.point?.latitude || 0,
                    lng: selectedPointCompany?.point?.longitude || selectedPointManual?.deliveryCompany?.point?.longitude || 0,
                  }}
                  markerOptions={[
                    {
                      position: {
                        lat: selectedPointCompany?.point?.latitude || selectedPointManual?.deliveryCompany?.point?.latitude || 0,
                        lng: selectedPointCompany?.point?.longitude || selectedPointManual?.deliveryCompany?.point?.longitude || 0,
                      },
                      visible: true
                    },
                    ...(selectedPointManual?.points?.map((point) => ({
                      data: point,
                      position: {
                        lat: point?.latitude ?? 0,
                        lng: point?.longitude ?? 0,
                      },
                      icon: ManualMarkers.find(
                        (manualMaker) => manualMaker.pointType === point.pointType
                      )?.icon,
                      visible: true
                    })) ?? []),
                  ]}
                  onClickMarker={(e, marker, data: Point) => {
                    const targetPointCompany = deliveryCompanyPoints().find(company =>
                      JSON.stringify([company?.point?.latitude, company?.point?.longitude]) ===
                      JSON.stringify([marker.getPosition()?.lat(), marker.getPosition()?.lng()]));
                    const infowindow = new google.maps.InfoWindow({
                      content: targetPointCompany?.name || data.memo,
                    });
                    infowindow.open(marker?.getMap(), marker);
                  }}
                />
              </Skeleton>
            </Space>
          </Space>
          <Space
            style={{
              display: params.id ? "flex" : "none",
              boxShadow:
                "0px -1px 3px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06)",
              flexDirection: "row-reverse",
              width: (props.guest && props.isEdit) ? "100%" : "calc(100vw - 200px)",
              background: "white",
              position: "fixed",
              left: (props.guest && props.isEdit) ? 0 : 200,
              bottom: 0,
              padding: "10px 38px",
              gap: 20,
            }}
          >
            <CustomButton
              type="primary"
              style={{ width: 120, height: 50 }}
              onClick={() => setIssueOpen(true)}
            >
              報告
            </CustomButton>
            {props.guest && !props.isEdit ? <></> :
              <CustomButton
                style={{ width: 120, height: 50 }}
                onClick={() => selectedPointManual ?
                  props.guest ?
                    props.history.push(
                      `/manuals/${selectedPointManual.id}/edit?key=${key}`
                    ) :
                    props.history.push(
                      `/operations/manuals/${selectedPointManual.id}/edit?key=${key}${props.guest ? "&guest=true" : ""}`
                    ) :
                  props.history.push(
                    `/operations/manuals/create?manualId=${manual().id}&deliveryCompanyId=${manual().deliveryCompanyId}&deliveryPointCompanyId=${selectedPointCompany?.id}${props.guest ? "&guest=true" : ""}`
                  )
                }
              >
                {selectedPointManual ? "編集する" : "作成する"}
              </CustomButton>
            }
          </Space>
          <IssueModal
            onCancel={() => setIssueOpen(false)}
            onOk={() => setIssueOpen(false)}
            open={issueOpen}
            history={props.history}
            deliveryCompany={manual().deliveryCompany ?? {}}
            guest={props.guest}
            delivererId={props.delivererId}
          />
        </Space>
      </Skeleton>
    </CustomPageHeader>
  );
};

export default withRouter(ManualPage);

export const ManualPreview = (manual: Manual, template: Template) => {
  const { token } = theme.useToken();
  const processes = (step: Step, detail: ManualDetail) =>
    // step.questions
    //   ?.sort((a, b) => ((a.questionNo ?? 0) > (b.questionNo ?? 0) ? 1 : -1))
    sortQuestions(step.questions || [])
      .map((question) => {
        const targetProcess = detail.manualProcesses?.find(
          (process) => process.questionId === question.id
        );
        const numericOption = question.questionOptions?.find(
          (option) => option.allowsNumericValue
        );
        const numericValue = targetProcess?.numericValue; // 数値
        const numericUnit = targetProcess?.numericUnit; // 単位（円など）
        const freeTextOption = question.questionOptions?.find(
          (option) => option.allowsFreeText
        );
        const freeText = targetProcess?.freeText;

        let title: string;
        if (question?.type === 1) {
          const option = question.questionOptions?.find(
            (qo) =>
              qo.id === targetProcess?.value && qo.id !== numericOption?.id
          );
          title = option ? highlightText(option.value ?? "", token) : "";
        } else if (question?.type === 2) {
          title =
            question.questionOptions
              ?.filter(
                (qo) =>
                  targetProcess?.value?.includes(qo.id as string) &&
                  qo.id !== numericOption?.id
              )
              .map((qo) => highlightText(qo.value ?? "", token))
              .join("") ?? "";
        } else {
          return { title: null };
        }
        // 自由入力と数値入力を手順情報に追加
        if (numericOption?.id)
          title += String(numericValue ?? "") + (numericUnit ?? "");
        if (freeTextOption?.id) title += freeText ?? "";
        return {
          title: title ? (
            <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
          ) : (
            ""
          ),
        };
      })
      .filter((process) => process.title);

  const [isPreviewVisible, setIsPreviewVisible] = useState<
    Record<string, boolean>
  >({});

  const togglePreview = (stepIndex: number, imageIndex: number) => {
    const key = `${stepIndex}-${imageIndex}`;
    setIsPreviewVisible((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const stepDetails = () =>
    template.steps?.map((step, stepIndex, steps) => {
      if (stepIndex === steps?.length) {
        // テンプレートに含まれている以上の手順は表示しない
        return;
      }
      const targetDetail = manual.manualDetails?.find(
        (detail) => detail.stepId === step.id
      );
      if (
        targetDetail === undefined ||
        processes(step, targetDetail)?.length === 0 &&
        !targetDetail.picture1 && !targetDetail.picture2 && !targetDetail.picture3 &&
        !targetDetail.comment
      ) {
        // カルテに含まれていない手順は表示しない
        return;
      }
      return (
        <>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>
            {step.stepName}
          </div>
          <Space
            direction="vertical"
            split={<Divider style={{ margin: "8px 0" }} />}
            style={{
              width: "100%",
              background: "#FFFFFF",
              boxShadow:
                "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
              borderRadius: "8px",
              padding: 20,
              marginTop: -20,
            }}
          >
            <DescriptionBlock
              label="【手順情報】"
              valueStyle={{ marginLeft: 8 }}
            >
              <Steps
                progressDot
                current={processes(step, targetDetail)?.length}
                direction="vertical"
                items={processes(step, targetDetail)}
              />
            </DescriptionBlock>
            <DescriptionBlock
              label="【補足説明】"
              valueStyle={{ marginLeft: 8 }}
            >
              {targetDetail?.comment ?? "--"}
            </DescriptionBlock>
            <DescriptionBlock label="【画像】" valueStyle={{ marginLeft: 8 }}>
              {targetDetail &&
                [1, 2, 3].map((index) => {
                  const pictureKey =
                    `picture${index}` as keyof typeof targetDetail;
                  const modalKey = `${stepIndex}-${index}`;
                  return targetDetail[pictureKey] ? (
                    <div
                      key={modalKey}
                      style={{
                        maxWidth: "90%",
                        position: "relative",
                        display: "inline-block",
                        marginTop: index === 1 ? 0 : 5,
                      }}
                    >
                      {/* 画像とモーダルのトリガー */}
                      <Image
                        preview={false}
                        src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${targetDetail[pictureKey]}`}
                      />
                      <ArrowsAltOutlined
                        width={29}
                        height={29}
                        style={{
                          color: "white",
                          background: "rgba(0,0,0,0.7)",
                          padding: 5,
                          borderRadius: "100%",
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                        }}
                        onClick={() => togglePreview(stepIndex, index)}
                      />
                      <Modal
                        open={isPreviewVisible[modalKey]}
                        closable={false}
                        footer={
                          <Button
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              width: "100%",
                            }}
                            onClick={() => togglePreview(stepIndex, index)}
                          >
                            閉じる
                          </Button>
                        }
                        onCancel={() => togglePreview(stepIndex, index)}
                      >
                        <img
                          alt="Preview"
                          style={{ width: "100%" }}
                          src={`${process.env.REACT_APP_S3_STATIC_STORAGE_URL}${targetDetail[pictureKey]}`}
                        />
                      </Modal>
                    </div>
                  ) : index === 1 ? (
                    "--"
                  ) : (
                    ""
                  );
                })}
            </DescriptionBlock>
          </Space>
        </>
      );
    });

  return (
    <Row
      gutter={[0, 20]}
      style={{ width: "100%" }}
    >
      {(Object.keys(template).length === 0 || stepDetails()?.every((step) => step === undefined)) ? (
        // 手順が全て未作成の時 OR テンプレートが存在しない時
        <Space
          style={{
            marginTop: 40,
            textAlign: "center",
            width: 872,
          }}
          size={24}
          direction="vertical"
        >
          <MdLockOutline style={{ height: 64, width: 64, color: "#9CA3AF" }} />
          <Space direction="vertical" size={13}>
            <div style={{ fontWeight: 700, fontSize: 16, color: "#000" }}>
              まだ手順が作成されていません
            </div>
          </Space>
        </Space>
      ) : (
        stepDetails()
      )}
    </Row>
  );
};
