import {
  useRef,
  useEffect,
  EffectCallback,
  DependencyList,
  useMemo,
} from "react";
import lodash from "lodash";

export function useEffectSkipFirst(
  callback: EffectCallback,
  effect?: DependencyList
): void {
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      callback();
    }
  }, effect);
}

import { useState, Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import { FormRule } from "specifics/input";

export type ParsableValue =
  | string
  | number
  | boolean
  | Record<string, unknown>
  | unknown[]
  | File
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactFragment
  | null
  | undefined;

export type FormAttrType<T> = keyof T | Array<string | number>;

export type Form<T> = {
  object: T;
  modelName?: string;
  set: Dispatch<SetStateAction<T>>;
  update: (setter: (f: T) => void) => void;
  updateObject: (attr: FormAttrType<T>, value: ParsableValue) => void;
  getValue: (attr: FormAttrType<T>) => ParsableValue;
  resetForm: () => void;
  isEmpty: () => boolean;
  validate: boolean;
  setValidate: (validate: boolean) => void;
};

export function isForm<T>(arg: any): arg is Form<T> {
  return (
    arg.object !== undefined &&
    arg.modelName !== undefined &&
    arg.getValue !== undefined &&
    arg.resetForm !== undefined
  );
}

/**
 * Formオブジェクトに値を設定するhooks.
 * ジェネリクスでフォームのtypeを指定する.
 *
 * @param initialForm 初期値.
 */
export function useForm<T>(initialForm: T): Form<T> {
  const [form, setForm] = useState<T>(initialForm);

  const getObjectValue = (attr: FormAttrType<T>): ParsableValue => {
    let returnValue = null;
    if (attr)
      try {
        if (form instanceof Object) {
          if (attr instanceof Array) {
            let selectObj = form as { [key: string]: any };
            attr.map((a, index) => {
              if (index + 1 == attr.length) {
                returnValue = selectObj[a];
              } else {
                selectObj = selectObj[a] as { [key: string]: any };
              }
            });
          } else {
            const selectObj = form as { [key: string]: any };
            returnValue = selectObj[attr as string];
          }
        } else {
          throw "updateArray method require form type object";
        }
      } catch {
        return null;
      }
    return returnValue;
  };
  const [validate, setValidate] = useState(false);

  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [formRules, setFormRules] = useState<FormRule[]>([]);

  const hasError = (): boolean => {
    return Object.keys(errors).some((key) => errors[key]?.length > 0);
  };

  // const check = () => {
  //   formRules.map(rule => {
  //     return rule.
  //   })
  //   setErrors({...errors, [attrToString(attr)]: rules?.filter((rule) => {
  //     return !rule.isValid(getObjectValue(attr))
  //   }).map(rule => rule.message)});
  //   return 
  // };

  const resetForm = () => {
    setForm(() => initialForm);
  };

  const copyForm = (): T => {
    if (form instanceof Array) {
      return Object.assign([], form);
    } else {
      return Object.assign({}, form);
    }
  };

  /**
   * マニュアルでattributeを更新する
   * @param setter 設定するメソッド
   */
  const updateForm = (setter: (f: T) => void): void => {
    const copledForm: T = copyForm();
    setter(copledForm);
    setForm(() => copledForm);
  };

  const updateObject = (attr: FormAttrType<T>, value: ParsableValue): void => {
    const copledForm: T = copyForm();
    if (copledForm instanceof Object) {
      if (attr instanceof Array) {
        let selectObj = copledForm as { [key: string]: any };
        attr.map((a, index) => {
          if (index + 1 == attr.length) {
            selectObj[a] = value === "" ? null : value;
          } else {
            // if (typeof a === 'number' && !selectObj) {
            //     selectObj = []
            // }
            if (
              selectObj instanceof Array &&
              selectObj[a as number] === undefined
            ) {
              selectObj[a as number] = {};
            }
            selectObj = selectObj[a] as { [key: string]: any };
          }
        });
      } else {
        const selectObj = copledForm as { [key: string]: any };
        selectObj[attr as string] = value === "" ? null : value;
      }
    } else {
      throw "updateArray method require form type object";
    }

    setForm(() => copledForm!);
  };

  const getValue = (attr: FormAttrType<T>): ParsableValue => {
    return getObjectValue(attr);
  };

  const isEmpty = () => lodash.isEmpty(form);

  return {
    object: form,
    set: setForm,
    update: updateForm,
    updateObject,
    getValue,
    resetForm,
    isEmpty,
    validate,
    setValidate,
  };
}

export const convertRansackQueryParams = <T>(searchForm: Form<T>): { q: T } => {
  return { q: searchForm.object };
};

export const useQuery = <T>(): T => {
  const obj: any = {};
  const params = new URLSearchParams(useLocation().search);
  for (const [key, value] of params.entries()) {
    obj[key] = value;
  }
  return obj as T;
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
