import { Col, Divider, Row, Space, theme } from "antd";
import { valueType } from "antd/es/statistic/utils";
import { ContractItems, formatContractFieldValue, hasMismatch } from "entities/contract";
import { DeliveryCompany } from "entities/delivery_company";
import { Manual } from "entities/manual";

type ManualContractCardViewProps = {
  manual?: Manual;
  deliveryCompany?: DeliveryCompany;
  mismatchOpen?: boolean;
  style?: React.CSSProperties;
};

export const ManualContractCardView = ({
  manual,
  deliveryCompany,
  mismatchOpen,
  style
}: ManualContractCardViewProps) => {
  const { token } = theme.useToken();
  const ActualVsContract = ({ title, actual, contract, hasDivider }: { title?: string, actual?: valueType | null, contract?: valueType | null, hasDivider?: boolean }) => {
    const isMismatch = hasMismatch(title, actual, contract);
    actual = formatContractFieldValue(manual, title);
    contract = formatContractFieldValue(deliveryCompany, title);

    return (actual || contract) ?
      <>
        <Row justify="space-between" style={{ marginTop: 12, padding: "8px 4px", borderRadius: 4, ...mismatchOpen && isMismatch ? { color: token.colorErrorActive, background: token.colorErrorBg } : {} }}>
          <Col style={{ fontSize: 16, padding: "0px 5px" }}>{title}</Col>
          <Col style={{ fontSize: 16, padding: "0px 5px" }}>{actual}</Col>
        </Row>
        {mismatchOpen &&
          <Row justify="space-between" style={{ marginBottom: 4, opacity: 0.4, padding: "8px 4px" }}>
            <Col style={{ fontSize: 16, padding: "0px 5px" }}>契約条件</Col>
            <Col style={{ fontSize: 16, padding: "0px 5px" }}>{contract}</Col>
          </Row>
        }
        {hasDivider && <Divider style={{ margin: 0, borderWidth: 2 }} />}
      </>
      : <></>
  };

  return (
    <Space size={0} direction="vertical" style={style}>
      {manual?.id && deliveryCompany?.id &&
        Object.entries(ContractItems).map(([key, value], index, items) => (
          <div key={key}>
            <ActualVsContract title={value} actual={manual[key as keyof typeof ContractItems]} contract={deliveryCompany[key as keyof typeof ContractItems]} hasDivider={index < items.length - 1} />
          </div>
        ))
      }
    </Space>
  );
};
