import { render } from "@testing-library/react";
import { ModalProps } from "antd";
import { usePostVehiclesApi } from "api/vehicle";
import {
  CsvImportModal,
  EntityColumn,
  RelateCsvEntityColumnsForm,
} from "components/shared/csv_import_modal";
import { GlobalStateContext } from "contexts/global_state_context";
import { Vehicle, VehicleForm } from "entities/vehicle";
import { useContext, useEffect } from "react";

export const VehicleCsvImportModal = ({ ...modalProps }: ModalProps) => {
  const bulkCreateVehicleApi = usePostVehiclesApi();

  const entityColumns: EntityColumn<Vehicle>[] = [
    {
      key: "division",
      title: "営業所",
      dataIndex: "division",
      width: 200,
      required: true,
    },

    {
      key: "vehicleCode",
      title: "管理番号",
      dataIndex: "vehicleCode",
      width: 200,
    },
    {
      key: "vehicleNo",
      title: "車両No.",
      dataIndex: "vehicleNo",
      width: 200,
      required: true,
    },
    {
      key: "vehicleType",
      title: "車両種類",
      dataIndex: "vehicleType",
      width: 200,
      required: true,
    },
    {
      key: "licenseType",
      title: "免許区分",
      dataIndex: "licenseType",
      width: 200,
    },
    {
      // @ts-ignore
      key: "userName",
      title: "担当ドライバー名",
      dataIndex: "userName",
      width: 200,
      required: true,
    },
    {
      // @ts-ignore
      key: "userMobile",
      title: "担当ドライバー電話番号",
      dataIndex: "userMobile",
      width: 200,
      required: true,
    },
    {
      key: "maxWeight",
      title: "最大積載量(t)",
      dataIndex: "maxWeight",
      width: 200,
      required: true,
    },
    { key: "weight", title: "車格", dataIndex: "weight", width: 200 },
    { key: "memo", title: "備考", dataIndex: "memo", width: 200 },
  ];

  const csvDataToEntities = (
    csvData: Record<string, any>[],
    valueOf: (csvRow: Record<string, any>, csvColumnName: string) => any
  ): Vehicle[] => {
    return csvData.map((csvRow) => {
      const entity: Vehicle = {};
      entityColumns.forEach((column) => {
        entity[column.dataIndex as keyof Vehicle] = valueOf(
          csvRow,
          column.title
        );
      });
      return entity;
    });
  };

  const globalState = useContext(GlobalStateContext);
  useEffect(() => {
    globalState.setLoading(bulkCreateVehicleApi.loading);
  }, [bulkCreateVehicleApi.loading]);

  return (
    <CsvImportModal
      bulkApiResult={{
        data: bulkCreateVehicleApi.response,
        error: bulkCreateVehicleApi.apiError as any,
      }}
      csvDataToEntities={csvDataToEntities}
      entityColumns={entityColumns}
      entityNameJp="車両"
      handleBulkSendValidatedEntities={async (data) => {
        await bulkCreateVehicleApi.execute(data);
      }}
      {...modalProps}
    />
  );
};
