import React from "react";
import { Route, Switch } from "react-router-dom";
import UserAuth from "../containers/user_auth";
import LoginPage from "components/login/login_page";
import ConfirmationPage from "../components/shared/confrimation";
import Error404Page from "../components/shared/error_404";
import OrdersPage from "components/orders/orders_page";
import OrderPage from "components/orders/order_page";
import CharteredVehiclesPage from "components/chartered_vehicles/chartered_vehicles_page";
import CharteredVehiclePage from "components/chartered_vehicles/chartered_vehicle_page";
import {
  CharteredVehiclesCreatePage,
  CharteredVehiclesEditPage,
} from "components/chartered_vehicles/chartered_vehicles_form";
import DeliveryPartnerCompaniesPage from "components/delivery_partner_companies/delivery_partner_companies_page";
import DeliveryPartnerCompanyPage from "components/delivery_partner_companies/delivery_partner_company_page";
import {
  DeliveryPartnerCompanyCreatePage,
  DeliveryPartnerCompanyEditPage,
} from "components/delivery_partner_companies/delivery_partner_company_form";
import ConsignorsPage from "components/consignors/consignors_page";
import ConsignorPage from "components/consignors/consignor_page";
import {
  ConsignorsCreatePage,
  ConsignorsEditPage,
} from "components/consignors/consignors_form";
import DeliveryCompaniesPage from "components/delivery_companies/delivery_companies_page";
import DeliveryCompanyPage from "components/delivery_companies/delivery_company_page";
import AnalysisDeliveriesPage from "components/analysis/deliveries/analysis_deliveries_page";
import {
  DeliveryCompaniesCreatePage,
  DeliveryCompaniesEditPage,
} from "components/delivery_companies/delivery_companies_form";
import DeliveriesRootPage from "components/deliveries/deliveries_root";
import CheckExistManualsPage from "components/manuals/find_manual_page";
import ManualsPage from "components/manuals/manuals_page";
import ManualPage from "components/manuals/manual_page";
import {
  ManualsEditPage,
  ManualsCreatePage,
} from "components/manuals/manuals_form_page";
import ContractCompletionPage from "components/contracts/completion/page";
import ContractsPage from "components/contracts/contracts_page";
import {
  OrdersCreatePage,
  OrdersEditPage,
} from "components/orders/orders_form";
import IssuesPage from "components/issues/issues_page";
import IssuePage from "components/issues/issue_page";
import UsersPage from "components/users/users_page";
import UserPage from "components/users/user_page";
import { UsersCreatePage, UsersEditPage } from "components/users/users_form";

import VehiclesPage from "components/vehicles/vehicles_page";
import VehiclePage from "components/vehicles/vehicle_page";
import {
  VehiclesCreatePage,
  VehiclesEditPage,
} from "components/vehicles/vehicles_form";

import SettingsPage from "components/settings/settings_page";

import {
  DriversManualsCreatePage,
  // DriversManualsEditPage,
} from "components/drivers_manuals/drivers_maunal_form";
import DriversManualsPage from "components/drivers_manuals/drivers_manuals_page";
import DriversManualsSharePage from "components/drivers_manuals/drivers_manuals_share_page";
import DriversManualPage, {
  DriversDeliveryCompanyPage,
  DriversManualReadPage,
} from "components/drivers_manuals/drivers_manual_page";
import DriversDeliversPage from "components/drivers_delivers/drivers_delivers_page";
import DriversIssuesPage from "components/drivers_issues/drivers_issues_page";
import DriversIssuePage from "components/drivers_issues/drivers_issue_page";

import * as H from "history";
import AdminLayout from "components/shared/admin_layout";
import SuperAdminLayout from "components/shared/super_admin_layout";
import ForgetPasswordPage from "components/login/forget_password_page";
import ResetPasswordPage from "components/login/reset_password_page";
import RegisterPage from "components/login/register_page";
import AnalysisDeliveryCompaniesPage from "components/analysis/delivery_companies/analysis_delivery_companies_page";
import AnalysisManualsPage from "components/analysis/manuals/analysis_manuals_page";
import CompanyVisualizationPage from "components/analysis/company/company_visualization_page";
import AnalysisDetailsWithManualsPercentagesPage from "components/analysis/details/analysis_details_with_manuals_percentages_page";
import AnalysisDetailsDriversCountsPage from "components/analysis/details/analysis_details_drivers_counts_page";
import AnalysisDetailsVehiclesCountsPage from "components/analysis/details/analysis_details_vehicles_counts_page";
import AnalysisDetailsIssuesCountsPage from "components/analysis/details/analysis_details_issues_counts_page";
import AnalysisSearchContainer from "containers/analysis_search_container";
import AnalysisMetricsPage from "components/analysis/metrics/metrics_page";
import SuperAdminTemplatesPage from "components/templates/super_admin/super_admin_templates_page";
import SuperAdminTemplatePage from "components/templates/super_admin/super_admin_template_page";
import {
  SuperAdminTemplatesCreatePage,
  SuperAdminTemplatesEditPage,
} from "components/templates/super_admin/super_admin_template_form";
import SuperAdminCompaniesPage from "components/companies/super_admin/super_admin_companies_page";
import SuperAdminCompanyPage from "components/companies/super_admin/super_admin_company_page";
import { SuperAdminCompaniesEditPage } from "components/companies/super_admin/super_admin_companies_form";
import SuperAdminUsersPage from "components/users/super_admin/super_admin_users_page";
import SuperAdminUserPage from "components/users/super_admin/super_admin_user_page";
import {
  SuperAdminUsersCreatePage,
  SuperAdminUsersEditPage,
} from "components/users/super_admin/super_admin_users_form";
import { GuestManualPage, GuestManualsCreatePage, GuestManualsEditPage } from "components/shared/guest_manual_page";
import ManualsSharePage from "components/manuals/manuals_share_page";
import RoutesPage from "components/routes/routes_page";
import RoutePage from "components/routes/route_page";
import {
  RoutesCreatePage,
  RoutesEditPage,
} from "components/routes/routes_form";

export type HistoryProps = {
  history: H.History;
};

const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={AppRouteHelper.root()}>
        <LoginPage />
      </Route>
      {process.env.REACT_APP_TYPE === "ADMIN" && (
        <Route exact path={AppRouteHelper.register()}>
          <RegisterPage />
        </Route>
      )}
      {process.env.REACT_APP_TYPE === "DRIVER" && (
        <Route exact path={AppRouteHelper.driversManualView()}>
          <DriversManualReadPage />
        </Route>
      )}
      {/* /login */}
      <Route exact path={AppRouteHelper.login()}>
        <LoginPage />
      </Route>
      <Route exact path={AppRouteHelper.forgetPassword()}>
        <ForgetPasswordPage />
      </Route>
      <Route exact path={AppRouteHelper.resetPassword()}>
        <ResetPasswordPage />
      </Route>
      <Route path={"/"}>
        <UserAuth>
          {process.env.REACT_APP_TYPE === "DRIVER" && DriverRoutes()}
          {process.env.REACT_APP_TYPE === "ADMIN" && AdminRoutes()}
          {process.env.REACT_APP_TYPE === "SUPER_ADMIN" && SuperAdminRoutes()}
        </UserAuth>
      </Route>
    </Switch>
  );
};

export default AppRoutes;

const AdminRoutes = () => {
  return (
    <AdminLayout>
      <AnalysisSearchContainer>
        <Switch>
          <Route exact path={AppRouteHelper.analysisVisualization()}>
            <CompanyVisualizationPage />
          </Route>
          <Route exact path={AppRouteHelper.analysisMetrics()}>
            <AnalysisMetricsPage />
          </Route>
          <Route exact path={AppRouteHelper.analysisDeliveries()}>
            <AnalysisDeliveriesPage />
          </Route>
          <Route exact path={AppRouteHelper.analysisDeliveryCompanies()}>
            <AnalysisDeliveryCompaniesPage />
          </Route>
          <Route exact path={AppRouteHelper.analysisManuals()}>
            <AnalysisManualsPage />
          </Route>
          <Route
            exact
            path={AppRouteHelper.analysisDetailsWithManualsPercentages()}
          >
            <AnalysisDetailsWithManualsPercentagesPage />
          </Route>
          <Route exact path={AppRouteHelper.analysisDetailsDriversCounts()}>
            <AnalysisDetailsDriversCountsPage />
          </Route>
          <Route exact path={AppRouteHelper.analysisDetailsVehiclesCounts()}>
            <AnalysisDetailsVehiclesCountsPage />
          </Route>
          <Route exact path={AppRouteHelper.analysisDetailsIssuesCounts()}>
            <AnalysisDetailsIssuesCountsPage />
          </Route>

          <Route exact path={AppRouteHelper.users()}>
            <UsersPage />
          </Route>
          <Route exact path={AppRouteHelper.userCreate()}>
            <UsersCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.userEdit()}>
            <UsersEditPage />
          </Route>
          <Route exact path={AppRouteHelper.user()}>
            <UserPage />
          </Route>
          <Route exact path={AppRouteHelper.charteredVehicles()}>
            <CharteredVehiclesPage />
          </Route>
          <Route exact path={AppRouteHelper.charteredVehicleCreate()}>
            <CharteredVehiclesCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.charteredVehicleEdit()}>
            <CharteredVehiclesEditPage />
          </Route>
          <Route exact path={AppRouteHelper.charteredVehicle()}>
            <CharteredVehiclePage />
          </Route>
          <Route exact path={AppRouteHelper.deliveryPartnerCompanies()}>
            <DeliveryPartnerCompaniesPage />
          </Route>
          <Route exact path={AppRouteHelper.deliveryPartnerCompanyCreate()}>
            <DeliveryPartnerCompanyCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.deliveryPartnerCompanyEdit()}>
            <DeliveryPartnerCompanyEditPage />
          </Route>
          <Route exact path={AppRouteHelper.deliveryPartnerCompany()}>
            <DeliveryPartnerCompanyPage />
          </Route>
          <Route exact path={AppRouteHelper.consignors()}>
            <ConsignorsPage />
          </Route>
          <Route exact path={AppRouteHelper.consignorCreate()}>
            <ConsignorsCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.consignorEdit()}>
            <ConsignorsEditPage />
          </Route>
          <Route exact path={AppRouteHelper.consignor()}>
            <ConsignorPage />
          </Route>
          <Route exact path={AppRouteHelper.deliveryCompanies()}>
            <DeliveryCompaniesPage />
          </Route>
          <Route exact path={AppRouteHelper.deliveryCompanyCreate()}>
            <DeliveryCompaniesCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.deliveryCompanyEdit()}>
            <DeliveryCompaniesEditPage />
          </Route>
          <Route exact path={AppRouteHelper.deliveryCompany()}>
            <DeliveryCompanyPage />
          </Route>
          <Route exact path={AppRouteHelper.routes()}>
            <RoutesPage />
          </Route>
          <Route exact path={AppRouteHelper.routeCreate()}>
            <RoutesCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.routeEdit()}>
            <RoutesEditPage />
          </Route>
          <Route exact path={AppRouteHelper.route()}>
            <RoutePage />
          </Route>
          <Route exact path={AppRouteHelper.Deliveries()}>
            <DeliveriesRootPage />
          </Route>
          <Route exact path={AppRouteHelper.checkExistManuals()}>
            <CheckExistManualsPage />
          </Route>
          <Route exact path={AppRouteHelper.manuals()}>
            <ManualsPage />
          </Route>
          <Route exact path={AppRouteHelper.manualsShare()}>
            <ManualsSharePage />
          </Route>
          <Route exact path={AppRouteHelper.manualEdit()}>
            <ManualsEditPage />
          </Route>
          <Route exact path={AppRouteHelper.guestManualEdit()}>
            <GuestManualsEditPage />
          </Route>
          <Route exact path={AppRouteHelper.manualCreate()}>
            <ManualsCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.guestManualCreate()}>
            <GuestManualsCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.manual()}>
            <ManualPage />
          </Route>
          <Route exact path={AppRouteHelper.guestManual()}>
            <GuestManualPage />
          </Route>
          <Route exact path={AppRouteHelper.contracts()}>
            <ContractsPage />
          </Route>
          <Route exact path={AppRouteHelper.ContractCompletion()}>
            <ContractCompletionPage />
          </Route>
          <Route exact path={AppRouteHelper.orders()}>
            <OrdersPage />
          </Route>
          <Route exact path={AppRouteHelper.orderCreate()}>
            <OrdersCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.orderEdit()}>
            <OrdersEditPage />
          </Route>
          <Route exact path={AppRouteHelper.order()}>
            <OrderPage />
          </Route>
          <Route exact path={AppRouteHelper.issues()}>
            <IssuesPage />
          </Route>
          <Route exact path={AppRouteHelper.issue()}>
            <IssuePage />
          </Route>
          <Route exact path={AppRouteHelper.vehicles()}>
            <VehiclesPage />
          </Route>
          <Route exact path={AppRouteHelper.vehicleCreate()}>
            <VehiclesCreatePage />
          </Route>
          <Route exact path={AppRouteHelper.vehicleEdit()}>
            <VehiclesEditPage />
          </Route>
          <Route exact path={AppRouteHelper.vehicle()}>
            <VehiclePage />
          </Route>
          <Route exact path={AppRouteHelper.settings()}>
            <SettingsPage />
          </Route>
          <Route exact path={AppRouteHelper.confirmation()}>
            <ConfirmationPage />
          </Route>
          <Route>
            <Error404Page />
          </Route>
        </Switch>
      </AnalysisSearchContainer>
    </AdminLayout>
  );
};

const SuperAdminRoutes = () => {
  return (
    <SuperAdminLayout>
      <Switch>
        <Route exact path={AppRouteHelper.superAdminCompanies()}>
          <SuperAdminCompaniesPage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminCompany()}>
          <SuperAdminCompanyPage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminCompaniesEdit()}>
          <SuperAdminCompaniesEditPage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminUsers()}>
          <SuperAdminUsersPage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminUserCreate()}>
          <SuperAdminUsersCreatePage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminUserEdit()}>
          <SuperAdminUsersEditPage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminUser()}>
          <SuperAdminUserPage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminTemplates()}>
          <SuperAdminTemplatesPage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminTemplatesCreate()}>
          <SuperAdminTemplatesCreatePage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminTemplatesEdit()}>
          <SuperAdminTemplatesEditPage />
        </Route>
        <Route exact path={AppRouteHelper.superAdminTemplate()}>
          <SuperAdminTemplatePage />
        </Route>
        <Route>
          <Error404Page />
        </Route>
      </Switch>
    </SuperAdminLayout>
  );
};

const DriverRoutes = () => {
  return (
    <Switch>
      <Route exact path={AppRouteHelper.driversDelivers()}>
        <DriversDeliversPage />
      </Route>
      <Route exact path={AppRouteHelper.driversIssues()}>
        <DriversIssuesPage />
      </Route>
      <Route exact path={AppRouteHelper.driversIssue()}>
        <DriversIssuePage />
      </Route>
      <Route exact path={AppRouteHelper.driversManuals()}>
        <DriversManualsPage />
      </Route>
      <Route exact path={AppRouteHelper.driversManualsShare()}>
        <DriversManualsSharePage />
      </Route>
      <Route exact path={AppRouteHelper.driversManualsCreate()}>
        <DriversManualsCreatePage />
      </Route>
      <Route exact path={AppRouteHelper.driversManual()}>
        <DriversManualPage />
      </Route>
      <Route exact path={AppRouteHelper.driversDeliveryCompany()}>
        <DriversDeliveryCompanyPage />
      </Route>
      {/* <Route exact path={AppRouteHelper.driversManualsEdit()}>
        <DriversManualsEditPage />
      </Route> */}
      <Route>
        <Error404Page />
      </Route>
    </Switch>
  );
};

/**
 * ルート定義
 */
export class AppRouteHelper {
  static basePath = (path: string): string => `/${path}`;

  public static root = (): string => "/";
  // ログイン
  public static register = (): string => AppRouteHelper.basePath("register");
  public static login = (): string => AppRouteHelper.basePath("login");
  public static forgetPassword = (): string =>
    AppRouteHelper.basePath("forget-password");
  public static resetPassword = (): string =>
    AppRouteHelper.basePath("reset-password");
  public static confirmation = (): string =>
    AppRouteHelper.basePath("confirmation");

  public static analysisVisualization = (): string =>
    AppRouteHelper.basePath("analysis/visualization");
  public static analysisMetrics = (): string =>
    AppRouteHelper.basePath("analysis/metrics");
  public static analysisDeliveries = (): string =>
    AppRouteHelper.basePath("analysis/deliveries");
  public static analysisOffice = (): string =>
    AppRouteHelper.basePath("analysis/office");
  public static analysisManuals = (): string =>
    AppRouteHelper.basePath("analysis/manuals");
  public static analysisDeliveryCompanies = (): string =>
    AppRouteHelper.basePath("analysis/delivery-companies");
  public static analysisDetailsWithManualsPercentages = (): string =>
    AppRouteHelper.basePath("analysis/details/with-manuals-percentages");
  public static analysisDetailsDriversCounts = (): string =>
    AppRouteHelper.basePath("analysis/details/drivers-counts");
  public static analysisDetailsVehiclesCounts = (): string =>
    AppRouteHelper.basePath("analysis/details/vehicles-counts");
  public static analysisDetailsIssuesCounts = (): string =>
    AppRouteHelper.basePath("analysis/details/issues-counts");

  public static charteredVehicles = (): string =>
    AppRouteHelper.basePath("operations/chartered-vehicles");
  public static charteredVehicle = (): string =>
    AppRouteHelper.basePath("operations/chartered-vehicles/:id");
  public static charteredVehicleCreate = (): string =>
    AppRouteHelper.basePath("operations/chartered-vehicles/create");
  public static charteredVehicleEdit = (): string =>
    AppRouteHelper.basePath("operations/chartered-vehicles/:id/edit");
  public static deliveryPartnerCompanies = (): string =>
    AppRouteHelper.basePath("operations/delivery-partner-companies");
  public static deliveryPartnerCompany = (): string =>
    AppRouteHelper.basePath("operations/delivery-partner-companies/:id");
  public static deliveryPartnerCompanyCreate = (): string =>
    AppRouteHelper.basePath("operations/delivery-partner-companies/create");
  public static deliveryPartnerCompanyEdit = (): string =>
    AppRouteHelper.basePath("operations/delivery-partner-companies/:id/edit");
  public static consignors = (): string =>
    AppRouteHelper.basePath("operations/consignors");
  public static consignor = (): string =>
    AppRouteHelper.basePath("operations/consignors/:id");
  public static consignorCreate = (): string =>
    AppRouteHelper.basePath("operations/consignors/create");
  public static consignorEdit = (): string =>
    AppRouteHelper.basePath("operations/consignors/:id/edit");
  public static contracts = (): string =>
    AppRouteHelper.basePath("operations/contracts/:id?/:filter?");
  public static ContractCompletion = (): string =>
    AppRouteHelper.basePath("contracts/completion/:id");
  public static deliveryCompanies = (): string =>
    AppRouteHelper.basePath("operations/delivery-companies");
  public static deliveryCompany = (): string =>
    AppRouteHelper.basePath("operations/delivery-companies/:id");
  public static deliveryCompanyCreate = (): string =>
    AppRouteHelper.basePath("operations/delivery-companies/create");
  public static deliveryCompanyEdit = (): string =>
    AppRouteHelper.basePath("operations/delivery-companies/:id/edit");
  public static routes = (): string =>
    AppRouteHelper.basePath("operations/routes");
  public static route = (): string =>
    AppRouteHelper.basePath("operations/routes/:id");
  public static routeCreate = (): string =>
    AppRouteHelper.basePath("operations/routes/create");
  public static routeEdit = (): string =>
    AppRouteHelper.basePath("operations/routes/:id/edit");
  public static Deliveries = (): string =>
    AppRouteHelper.basePath("operations/deliveries");
  public static checkExistManuals = (): string =>
    AppRouteHelper.basePath("operations/check-exist-manuals");
  public static manuals = (): string =>
    AppRouteHelper.basePath("operations/manuals");
  public static manual = (): string =>
    AppRouteHelper.basePath("operations/manuals/:id");
  public static manualsShare = (): string =>
    AppRouteHelper.basePath("operations/manuals/share");
  public static manualCreate = (): string =>
    AppRouteHelper.basePath("operations/manuals/create");
  public static manualEdit = (): string =>
    AppRouteHelper.basePath("operations/manuals/:id/edit");
  public static guestManualCreate = (): string =>
    AppRouteHelper.basePath("manuals/create");
  public static guestManualEdit = (): string =>
    AppRouteHelper.basePath("manuals/:id/edit");
  public static guestManual = (): string =>
    AppRouteHelper.basePath("manuals/:id");
  public static orders = (): string =>
    AppRouteHelper.basePath("operations/orders");
  public static order = (): string =>
    AppRouteHelper.basePath("operations/orders/:id");
  public static orderCreate = (): string =>
    AppRouteHelper.basePath("operations/orders/create");
  public static orderEdit = (): string =>
    AppRouteHelper.basePath("operations/orders/:id/edit");
  public static issues = (): string =>
    AppRouteHelper.basePath("operations/issues");
  public static issue = (): string =>
    AppRouteHelper.basePath("operations/issues/:id");
  public static shifts = (): string =>
    AppRouteHelper.basePath("operations/shifts");
  public static shiftTemplates = (): string =>
    AppRouteHelper.basePath("operations/shift-terms");
  public static shiftTemplatesEdit = (): string =>
    AppRouteHelper.basePath("operations/shift-terms/edit");
  public static users = (): string =>
    AppRouteHelper.basePath("operations/users");
  public static user = (): string =>
    AppRouteHelper.basePath("operations/users/:id");
  public static userCreate = (): string =>
    AppRouteHelper.basePath("operations/users/create");
  public static userEdit = (): string =>
    AppRouteHelper.basePath("operations/users/:id/edit");
  public static vehicles = (): string =>
    AppRouteHelper.basePath("operations/vehicles");
  public static vehicle = (): string =>
    AppRouteHelper.basePath("operations/vehicles/:id");
  public static vehicleCreate = (): string =>
    AppRouteHelper.basePath("operations/vehicles/create");
  public static vehicleEdit = (): string =>
    AppRouteHelper.basePath("operations/vehicles/:id/edit");
  public static settings = (): string =>
    AppRouteHelper.basePath("operations/settings");

  public static driversDelivers = (): string =>
    AppRouteHelper.basePath("drivers/delivers");
  public static driversManuals = (): string =>
    AppRouteHelper.basePath("drivers/manuals");
  public static driversManualsShare = (): string =>
    AppRouteHelper.basePath("drivers/manuals/share");
  public static driversManual = (): string =>
    AppRouteHelper.basePath("drivers/manuals/:id");
  public static driversManualView = (): string =>
    AppRouteHelper.basePath("drivers/manuals/view/:id");
  public static driversManualsCreate = (): string =>
    AppRouteHelper.basePath("drivers/manuals/create");
  public static driversManualsEdit = (): string =>
    AppRouteHelper.basePath("drivers/manuals/:id/edit");
  public static driversDeliveryCompany = (): string =>
    AppRouteHelper.basePath("drivers/delivery-companies/detail");
  public static driversIssues = (): string =>
    AppRouteHelper.basePath("drivers/issues");
  public static driversIssue = (): string =>
    AppRouteHelper.basePath("drivers/issues/:id");

  public static superAdminCompanies = (): string =>
    AppRouteHelper.basePath("super-admin/companies");
  public static superAdminCompaniesEdit = (): string =>
    AppRouteHelper.basePath("super-admin/companies/:id/edit");
  public static superAdminCompaniesCreate = (): string =>
    AppRouteHelper.basePath("super-admin/companies/create");
  public static superAdminCompany = (): string =>
    AppRouteHelper.basePath("super-admin/companies/:id");
  public static superAdminTemplates = (): string =>
    AppRouteHelper.basePath("super-admin/templates");
  public static superAdminTemplatesCreate = (): string =>
    AppRouteHelper.basePath("super-admin/templates/create");
  public static superAdminTemplatesEdit = (): string =>
    AppRouteHelper.basePath("super-admin/templates/:id/edit");
  public static superAdminTemplate = (): string =>
    AppRouteHelper.basePath("super-admin/templates/:id");
  public static superAdminUsers = (): string =>
    AppRouteHelper.basePath("super-admin/users");
  public static superAdminUser = (): string =>
    AppRouteHelper.basePath("super-admin/users/:id");
  public static superAdminUserCreate = (): string =>
    AppRouteHelper.basePath("super-admin/users/create");
  public static superAdminUserEdit = (): string =>
    AppRouteHelper.basePath("super-admin/users/:id/edit");
}
