import { Checkbox, Space, Table, TableProps } from "antd";
import { ContractItemMapping, ContractItemValueType, ContractItems, DeliveryTerm, ReceiptHandOverTime, WaitingTime } from "entities/contract";
import { Manual, validateManual } from "entities/manual";
import { DeliveryCompany } from "entities/delivery_company";
import { Form, FormAttrType, useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomInputField, CustomSelectField } from "specifics/input";

export const ContractCompletionTable = ({
  selectedManual,
  manualFormsState,
  setManualFormsState,
  isMobile,
  readonly
}: {
  selectedManual: Manual;
  manualFormsState: { [id: string]: { form: Form<Manual>, validationResultForm: Form<Manual>, completed: boolean } };
  setManualFormsState: React.Dispatch<React.SetStateAction<{
    [id: string]: { form: Form<Manual>, validationResultForm: Form<Manual>, completed: boolean };
  }>>;
  isMobile: boolean;
  readonly?: boolean;
}) => {
  type ContractCompletionItem = {
    name: string;
    contract: ContractItemValueType;
    actual: ContractItemValueType;
    latest: { [id: string]: ContractItemValueType };
  }

  const form = useForm<Manual>({});
  const validationResultForm = useForm<Manual>({});
  const isCompleted = () => (
    // 全項目が回答済みになっていれば
    Object.values(validationResultForm.object).every(
      val => val === undefined
    )
  );
  const updateManualFormsState = (id?: string) => {
    if (!id) { return; }
    const updatedForm = !manualFormsState[id] ?
      // 選択カルテ切り替え時
      { form, validationResultForm, completed: false } :
      // 入力時
      { form, validationResultForm, completed: isCompleted() };

    setManualFormsState(prevForms => ({
      ...prevForms,
      [id]: updatedForm
    }));
  }

  useEffectSkipFirst(() => {
    // 選択中カルテの切り替え
    form.set(
      // 選択中カルテの入力を引き継ぎ
      manualFormsState[selectedManual.id as string]?.form.object ||
      // 初期値
      { id: selectedManual.id, title: selectedManual.title, updatedBy: selectedManual.deliveryPartnerCompanyId }
    );
    validationResultForm.set(manualFormsState[selectedManual.id as string]?.validationResultForm.object || {});
  }, [selectedManual.id])

  useEffectSkipFirst(() => {
    validateManual(form, validationResultForm, true);
    updateManualFormsState(selectedManual.id);
  }, [JSON.stringify(form.object)])

  useEffectSkipFirst(() => {
    updateManualFormsState(selectedManual.id);
  }, [JSON.stringify(validationResultForm.object)])

  const colStyle: any = isMobile ? { whiteSpace: "nowrap", fontSize: 12, width: "auto" } : {};
  const columns: TableProps<ContractCompletionItem>['columns'] = [
    {
      title: '項目',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span style={{ ...colStyle }}>{text}</span>,
      width: 270,
    },
    {
      title: '契約条件',
      dataIndex: 'contract',
      key: 'contract',
      render: (text) => <span style={{ ...colStyle }}>{text}</span>,
      width: 200,
    },
    {
      title: readonly ? '実態(最新)' : '前回の回答',
      dataIndex: 'actual',
      key: 'actual',
      render: (text: string) => <span style={{ ...colStyle }}>{text}</span>,
      width: 200,
    },
    // readonly時に非表示
    ...(readonly ? [] : [
      {
        title: '実態(最新)',
        dataIndex: 'latest',
        key: 'latest',
        render: (obj: { [id: string]: ContractItemValueType }) => Object.entries(obj).map(([attr, ref]) => {
          const targetValue = form.getValue(attr as FormAttrType<Manual>);
          const inputValue = form.getValue(ref as FormAttrType<Manual>);
          const isFreeInput = typeof ref === "string"; // 自由入力flg

          // その他がdisabledになる時に自由入力初期化
          // const initializeOther = (val: string | number) => {
          //   const otherAttr = `${attr}Other`;
          //   const hasOther = Object.keys(ContractItemMapping).includes(otherAttr);
          //   if (hasOther && !(val.toString().includes("その他") || Number(val) == DeliveryTerm.その他)) {
          //     form.updateObject(otherAttr as FormAttrType<Manual>, null);
          //   }
          // }

          return <Space key={attr}>
            <Checkbox
              style={{
                width: 110,
                lineHeight: "43px",
                boxSizing: "border-box",
                padding: "0 10px",
                border: "1px solid #d9d9d9",
                borderRadius: "5px",
                background: "white",
                ...colStyle
              }}
              disabled={isFreeInput && !(inputValue?.toString()?.includes("その他") || inputValue == DeliveryTerm.その他)}
              checked={targetValue === selectedManual[attr as keyof Manual]}
              onChange={(e) => {
                const val = e.target.checked ? selectedManual[attr as keyof Manual] : undefined;
                form.updateObject(attr as FormAttrType<Manual>, val);
                // initializeOther(val);
              }}
            >
              変更なし
            </Checkbox>
            {isFreeInput ?
              // 自由入力（refで自由入力の可否を参照）
              <CustomInputField
                validationResultForm={validationResultForm}
                form={form}
                disabled={!(inputValue?.toString()?.includes("その他") || inputValue == DeliveryTerm.その他)}
                attr={attr as FormAttrType<Manual>}
                style={{ height: 45 }}
              /> :
              // セレクト入力（refで選択肢を参照）
              <CustomSelectField
                validationResultForm={validationResultForm}
                form={form}
                attr={attr as FormAttrType<Manual>}
                style={{ height: 45 }}
                mode={Array.isArray(ref) ? "multiple" : undefined}
                selectItems={Object.entries(ref || [])
                  .filter(([label, value]) =>
                    typeof value === "number" || value === WaitingTime.なし || value === ReceiptHandOverTime.なし || // enumのfilter
                    Array.isArray(ref)       // arrayは全部通す
                  )
                  .map(([label, value]) => ({
                    label: isNaN(value) ? value : label,
                    value: value, // 待ち時間「なし」の時はnullが入る
                  }))}
                onChange={(e) => {
                  form.updateObject(
                    attr as FormAttrType<Manual>,
                    Array.isArray(ref) ?
                      // 複数選択
                      e
                        .sort((a: string, b: string) =>
                          a < b ? 1 : -1
                        ).join(",") :
                      // 単一選択
                      e
                  )
                  // initializeOther(e);
                }
                }
                fieldProps={{
                  value: Array.isArray(ref) ?
                    (form.object[attr as keyof Manual] as string)?.split(",") :
                    form.object[attr as keyof Manual],
                  children: <></>
                }}
              />
            }
          </Space>
        }),
      },
    ]),

  ];

  const formatEnumVal = (ref: ContractItemValueType, val: any) =>
    (Array.isArray(ref) || typeof ref === "string" || ref === null) ? val : ref[val as keyof ContractItemValueType];

  const data: ContractCompletionItem[] =
    Object.entries(ContractItemMapping).map(([key, ref]) => {
      const contractVal = selectedManual.deliveryCompany?.[key as keyof DeliveryCompany];
      const acturalVal = selectedManual[key as keyof Manual];

      return {
        name: ContractItems[key as keyof typeof ContractItems],
        contract: (formatEnumVal(ref as ContractItemValueType, contractVal) || '--') as ContractItemValueType,
        actual: (formatEnumVal(ref as ContractItemValueType, acturalVal) || '--') as ContractItemValueType,
        latest: { [key]: ref as ContractItemValueType }
      }
    });

  const scroll = () => {
    let x = "";
    const y = "calc(100vh - 200px)";
    switch (true) {
      case isMobile:
        x = "calc(100vw - 64px)";
        break;
      case readonly:
        x = "calc(100vw - 392px)";
        break;
      default:
        x = "calc(100vw - 360px)";
    }
    return { x, y };
  }

  return (
    <>
      <span style={{ fontSize: 16 }}>納入先での実態を記入してください。前回から変更がない場合は「変更なし」を選択してください。</span>
      {selectedManual.id &&
        <Table
          className="contract-completion-table"
          scroll={scroll()}
          style={{ marginTop: 10 }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      }
    </>
  );
};
