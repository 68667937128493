import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { useLocation, withRouter } from "react-router-dom";
import * as H from "history";
import {
  Badge,
  Image,
  Layout as AntdLayout,
  Menu,
  MenuProps,
  Space,
  theme,
  Dropdown,
} from "antd";
import {
  HomeOutlined,
  NotificationOutlined,
  UserOutlined,
  ControlOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  LineChartOutlined,
  BarChartOutlined,
  TableOutlined,
  GoldOutlined,
  MailOutlined,
  CarOutlined,
  SettingOutlined
} from "@ant-design/icons";
import Logo from "components/../../public/logo.png";
import { GlobalStateContext } from "contexts/global_state_context";

import { HiOutlineUserCircle } from "react-icons/hi";
import { IoCarOutline } from "react-icons/io5";
import { LoginUserPopover } from "components/login/logout_view";
import { HistoryProps } from "routes/app";
import NotificationModal from "components/notifications/modal";

const { Header, Content, Sider } = AntdLayout;

type MenuItem = Required<MenuProps>["items"][number];

enum SystemMode {
  OPERATIONS = "OPERATIONS", // 通常の運行管理 (Knewit App)
  ANALYSIS = "ANALYSIS", // 分析ツール (Knewit Board)
}

type LayoutProps = {
  children?: ReactNode;
  history: H.History;
};
const AdminLayout = (props: LayoutProps): JSX.Element => {
  const globalState = useContext(GlobalStateContext);
  const canAccessBoard = globalState.user?.canAccessBoard;

  const { token } = theme.useToken();

  const location = useLocation();

  const isFormPage = (): boolean => {
    return (
      location.pathname.includes("create") || location.pathname.includes("edit")
    );
  };

  const getSystemoModeFromUrl = (): SystemMode | undefined => {
    // URLのpathで判別
    const mode = location.pathname.split("/")[1];

    switch (mode) {
      case "operations":
        return SystemMode.OPERATIONS;
      case "analysis":
        return SystemMode.ANALYSIS;
      default:
        return undefined;
    }
  };

  // useEffect(() => {
  //   if (getSystemoModeFromUrl() === SystemMode.ANALYSIS && !canAccessBoard) {
  //     message.error("KnewitBoardへのアクセス権限がありません。");
  //     props.history.push("/login");
  //   }
  // }, [canAccessBoard, getSystemoModeFromUrl()]);

  const hideSider = () => {
    return !getSystemoModeFromUrl();
  };

  const HeaderLogoView = (props: HistoryProps) => {
    return (
      <div style={{ padding: "20px 8px 20px 8px", cursor: "pointer" }}>
        <Dropdown
          disabled={!canAccessBoard}
          key={"app"}
          trigger={["click"]}
          menu={{
            items: [
              {
                label: "運行管理",
                style: {
                  fontWeight:
                    getSystemoModeFromUrl() === SystemMode.OPERATIONS
                      ? 700
                      : 400,
                },
                icon: <TableOutlined />,
                key: SystemMode.OPERATIONS,
                onClick: () => props.history.push("/operations/orders"),
              },
              {
                label: "可視化・分析",
                style: {
                  fontWeight:
                    getSystemoModeFromUrl() === SystemMode.ANALYSIS ? 700 : 400,
                },
                icon: <LineChartOutlined />,
                key: SystemMode.ANALYSIS,
                onClick: () => props.history.push("/analysis/visualization"),
              },
            ],
          }}
        >
          <div
            onClick={() =>
              !canAccessBoard && props.history.push("/operations/orders")
            }
            style={{ width: "100%", cursor: "pointer" }}
          >
            {process.env.REACT_APP_ENV_MODE === "PRODUCTION" ||
              process.env.REACT_APP_ENV_MODE === "LOCAL" ? (
              <Image src={Logo} preview={false} width={124} />
            ) : (
              <div style={{ fontWeight: 700, fontSize: 20 }}>
                {process.env.REACT_APP_ENV_MODE}
              </div>
            )}
          </div>
        </Dropdown>
      </div>
    );
  };

  const [notificaionsOpen, setNotificationsOpen] = useState(false);
  return (
    <AntdLayout style={{ width: "100vw", height: "100%" }}>
      {!hideSider() && (
        <Sider
          theme="light"
          width={200}
          className="site-layout-background"
          collapsed={globalState.collapsed}
          style={{
            border: "1px solid",
            borderColor: token.colorBorder,
            background: token.colorWhite,
            height: "100vh",
          }}
        >
          <Space
            style={{
              justifyContent: "space-between",
              height: "100%",
              width: "100%",
            }}
            direction="vertical"
          >
            <div style={{ paddingLeft: 8 }}>
              <HeaderLogoView history={props.history} />

              {getSystemoModeFromUrl() === SystemMode.OPERATIONS && (
                <OperationsMenu history={props.history} setNotificationsOpen={setNotificationsOpen} />
              )}
              {getSystemoModeFromUrl() === SystemMode.ANALYSIS && (
                <AnalysissMenu history={props.history} />
              )}
            </div>
            <LoginUserPopover history={props.history}>
              <Space
                style={{
                  borderTop: `1px solid ${token.colorBorder}`,
                  padding: 16,
                  height: 70,
                  width: "100%",
                }}
                size={12}
              >
                <HiOutlineUserCircle
                  style={{
                    color: "#6B7280",
                    verticalAlign: "middle",
                  }}
                  size={32}
                />
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: token.colorText,
                    }}
                  >
                    {globalState.user.name}
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      color: token.colorText,
                    }}
                  >
                    {globalState.user.mobile}
                  </div>
                </div>
              </Space>
            </LoginUserPopover>
          </Space>
        </Sider>
      )}
      <AntdLayout style={{ height: "auto", position: "relative" }}>
        <NotificationModal
          open={notificaionsOpen}
          onClose={() => setNotificationsOpen(false)}
          style={{
            position: "fixed",
            top: 70,
            left: 210,
          }}
          history={props.history}
        />
        <Content
          style={{
            height: "100vh",
            overflowY: "scroll",
            width: hideSider()
              ? globalState.dimension.width
              : globalState.dimension.width - 200,
            backgroundColor: token.colorBgBase,
          }}
        >
          {props.children}
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
};

export default withRouter(AdminLayout);

const location2menuKey = (location: H.Location) => [
  location.pathname.split("/").slice(0, 3).join("/"),
];

const OperationsMenu = (props: HistoryProps & { setNotificationsOpen: Dispatch<SetStateAction<boolean>> }) => {
  const globalState = useContext(GlobalStateContext);
  const location = useLocation();

  const transform = "scale(1.15, 1.15)";
  const mainItems: MenuItem[] = [
    {
      key: "/operations/orders",
      label: "受注",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <HomeOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/orders");
      },
    },
    {
      key: "/operations/deliveries",
      label: "配車",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <UserOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/deliveries");
      },
    },
    {
      key: "/operations/notifications",
      label:
        <Space align="center">
          通知
          <Badge count={globalState.notificationsCount} size={"default"} />
        </Space>,
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <ClockCircleOutlined style={{ transform }} />,
      onClick: () => props.setNotificationsOpen(prev => !prev),
    },
  ];

  const masterItems: MenuItem[] = [
    {
      key: "/operations/users",
      label: "メンバー",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <UserOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/users");
      },
    },
    {
      key: "/operations/vehicles",
      label: "車両",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <NotificationOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/vehicles");
      },
    },
    {
      key: "/operations/chartered-vehicles",
      label: "傭車",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <GoldOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/chartered-vehicles");
      },
    },
    {
      key: "/operations/consignors",
      label: "荷主",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <HomeOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/consignors");
      },
    },
    {
      key: "/operations/delivery-partner-companies",
      label: "運送会社",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <CarOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/delivery-partner-companies");
      },
    },
    {
      key: "/operations/delivery-companies",
      label: "行先",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <UserOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/delivery-companies");
      },
    },
    {
      key: "/operations/routes",
      label: "輸送",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <ControlOutlined  style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/routes");
      },
    },
    // {
    //   key: "/operations/shifts",
    //   label: "シフト",
    //   style: { borderRadius: 6, paddingLeft: 8 },
    //   icon: <CalendarOutlined style={{ transform }} />,
    //   onClick: () => {
    //     props.history.push("/operations/shifts");
    //   },
    // },
  ]

  const subItems: MenuItem[] = [
    {
      key: "/operations/manuals",
      label: "マニュアル",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <GoldOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/manuals");
      },
    },
    {
      key: "/operations/contracts",
      label: "実態管理",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <CheckOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/contracts");
      },
    },
    {
      key: "/operations/issues",
      label: "報告",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: (
        <Badge count={globalState.issueCount} size={"small"}>
          <MailOutlined style={{ transform }} />
        </Badge>
      ),
      onClick: () => {
        props.history.push("/operations/issues");
      },
    },
  ];

  const otherItems: MenuItem[] = [
    {
      key: "/operations/settings",
      label: "設定",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <SettingOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/operations/settings");
      },
    }
  ];

  return (
    <>
      <Menu
        selectedKeys={location2menuKey(location)}
        mode="inline"
        theme="light"
        items={mainItems}
        style={{ border: "none" }}
      />
      <div
        style={{
          paddingLeft: 8,
          marginTop: 32,
          fontWeight: 600,
        }}
      >
        マスタ
      </div>
      <Menu
        selectedKeys={location2menuKey(location)}
        mode="inline"
        theme="light"
        style={{ border: "none" }}
        items={masterItems}
      />
      <div
        style={{
          paddingLeft: 8,
          marginTop: 32,
          fontWeight: 600,
        }}
      >
        納入カルテ
      </div>
      <Menu
        selectedKeys={location2menuKey(location)}
        mode="inline"
        theme="light"
        style={{ border: "none" }}
        items={subItems}
      />
      <div
        style={{
          paddingLeft: 8,
          marginTop: 32,
          fontWeight: 600,
        }}
      >
        その他
      </div>
      <Menu
        selectedKeys={location2menuKey(location)}
        mode="inline"
        theme="light"
        style={{ border: "none" }}
        items={otherItems}
      />
    </>
  );
};

const AnalysissMenu = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const location = useLocation();
  const transform = "scale(1.15, 1.15)";
  const mainItems: MenuItem[] = [
    {
      key: "/analysis/visualization",
      label: "可視化",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <LineChartOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/analysis/visualization");
      },
    },
    {
      key: "/analysis/metrics",
      label: "分析",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <BarChartOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/analysis/metrics");
      },
    },
    {
      key: "/analysis/deliveries",
      label: "配車分析",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <IoCarOutline style={{ transform }} />,
      onClick: () => {
        props.history.push("/analysis/deliveries");
      },
    },
  ];

  const subItems: MenuItem[] = [
    {
      key: "/analysis/manuals",
      label: "納入カルテ",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <GoldOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/analysis/manuals");
      },
    },
    {
      key: "/analysis/delivery-companies",
      label: "行先",
      style: { borderRadius: 6, paddingLeft: 8 },
      icon: <UserOutlined style={{ transform }} />,
      onClick: () => {
        props.history.push("/analysis/delivery-companies");
      },
    },
  ];
  return (
    <>
      <div
        style={{
          paddingLeft: 8,
          marginTop: 32,
          fontWeight: 600,
        }}
      >
        経営レポート
      </div>
      <Menu
        selectedKeys={location2menuKey(location)}
        mode="inline"
        theme="light"
        items={mainItems}
        style={{ border: "none" }}
      />
      <div
        style={{
          paddingLeft: 8,
          marginTop: 32,
          fontWeight: 600,
        }}
      >
        管理
      </div>
      <Menu
        selectedKeys={location2menuKey(location)}
        mode="inline"
        theme="light"
        style={{ border: "none" }}
        items={subItems}
      />
    </>
  );
};
