import { Button, Input, InputRef, Select, Space, Table, TableProps, theme } from "antd";
import { Key, useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
  TableRowSelection,
} from "antd/lib/table/interface";
import { CSSProperties } from "styled-components";
import { useQuery } from "utils/hooks";
import { HistoryProps } from "routes/app";
import { IndexApiSet } from "utils/network/api_hooks";
import { queryString } from "utils/util";

export type TableSet<DataType> = {
  filteredInfo: Record<string, FilterValue | null>;
  setFilteredInfo: (filteredInfo: Record<string, FilterValue | null>) => void;
  sortedInfo: SorterResult<DataType>;
  paginationInfo: TablePaginationConfig | undefined;
  setSortedInfo: (sortedInfo: SorterResult<DataType>) => void;
  handleChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType> | SorterResult<DataType>[],
    extra: TableCurrentDataSource<DataType>
  ) => void;

  rowSelection: TableRowSelection<DataType>;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (selectedRowKeys: React.Key[]) => void;

  getColumnSearchProps: (
    dataIndex: keyof DataType,
    dataIndexJp?: string,
    extraDropDown?: string[],
    extraDropDownIndex?: string
  ) => ColumnType<DataType>;
  searchText: string;
  setSearchText: (searchText: string) => void;
  clearFilters: () => void;
  clearSort: () => void;
  baseColumn: (key: string, style?: CSSProperties) => ColumnType<DataType>;
};

export const useTable = <DataType extends any>(
  props?:
    TableProps<DataType> &
    HistoryProps &
    {
      listApi?: IndexApiSet<any> &
      { execute: (query?: string) => void };
    }
): TableSet<DataType> => {
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});

  const query = useQuery<{ current?: number; pageSize?: number; type?: string }>();
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>();

  useEffect(() => {
    if (query.current && query.pageSize) {
      setPaginationInfo({
        current: Number(query.current),
        pageSize: Number(query.pageSize),
      });
    }
  }, [query.current, query.pageSize]);

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    setPaginationInfo(paginationInfo);
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<DataType>);

    // ソートの情報をクエリ用の文字列に変換
    let sorterQuery = "";
    if (Array.isArray(sorter)) {
      sorterQuery = sorter
        .map((s) =>
          s.order
            ? `${s.columnKey},${s.order === "ascend" ? "asc" : "desc"}`
            : ""
        )
        .filter((item) => item !== "")
        .join("|");
    } else if (sorter.columnKey) {
      sorterQuery = sorter.order
        ? `${sorter.columnKey},${sorter.order === "ascend" ? "asc" : "desc"}`
        : "";
    }

    // フィルターの情報をクエリ用の文字列に変換
    const filterQuery = Object.entries(filters)
      .map(([key, value]) => {
        if (value && key === "type") {
          // if the key is 'type'('種別' in '行先'), sum up the value
          return `${key}:${value.join(",")},3`;
        } else {
          return Array.isArray(value) ?
            value.filter(el => !!el)
              .map((el) => Array.isArray(el) ? `${key}:${el.join(",")}` : `${key}:${el}`)
              .filter(v => v !== `${key}:`)
              .join("|")
            : value ? `${key}:${value}` : "";
        }
      })
      .filter((item) => item !== "")
      .join("|");

    const encodedSorterQuery = encodeURIComponent(sorterQuery);
    const encodedFilterQuery = encodeURIComponent(filterQuery);

    const queryObj = {
      ...query,
      page: pagination.current,
      pageSize: pagination.pageSize,
      sorter: encodedSorterQuery,
      filter: encodedFilterQuery,
    };
    const queryStr = queryString(queryObj);

    // listApiの存在チェック
    if (!props?.listApi) {
      // URLのクエリパラメータを更新
      props?.history.push({
        search: queryStr
      });
      return;
    }
    // 作成したクエリをAPIに送信
    props.listApi.execute(queryStr);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const clearSort = () => {
    setSortedInfo({});
  };

  // Row Selection
  // -------------

  const { token } = theme.useToken();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange: TableRowSelection<DataType>["onChange"] = (
    newSelectedRowKeys: React.Key[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  type DataIndex = keyof DataType;

  const [searchText, setSearchText] = useState<string>("");
  const [searchedColumn, setSearchedColumn] = useState<DataIndex | undefined>(
    undefined
  );
  const searchInput = useRef<InputRef>(null);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex,
    dataIndexJp?: string,
    extraDropDown?: string[],
    extraDropDownIndex?: string
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`${dataIndexJp ?? String(dataIndex)}を検索`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value, selectedKeys[1]] : ["", selectedKeys[1]])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        {extraDropDown?.length &&
          <Select
            mode="multiple"
            placeholder={`${extraDropDownIndex ?? String(extraDropDownIndex)}を選択`}
            value={selectedKeys[1] || []}
            onChange={value => setSelectedKeys([selectedKeys[0] || "", value as Key])}
            style={{ width: 188, marginBottom: 8 }}
          >
            {Object.values(extraDropDown).map(item => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        }
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
          >
            検索
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch([], confirm, dataIndex);
            }}
            size="small"
          >
            クリア
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            閉じる
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{ color: filtered ? token.colorPrimary : undefined }}
      />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const baseColumn = (key: string, style?: CSSProperties) => {
    return {
      key,
      title: key,
      filteredValue: filteredInfo[key] || null,
      sortOrder: sortedInfo.columnKey === key ? sortedInfo.order : null,
    } as ColumnType<DataType>;
  };

  return {
    filteredInfo,
    paginationInfo,
    setFilteredInfo,
    sortedInfo,
    setSortedInfo,
    handleChange,

    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,

    getColumnSearchProps,
    searchText,
    setSearchText,
    clearFilters,
    clearSort,
    baseColumn,
  };
};
