import { Alert, Drawer, Menu, Popover, Space, theme, Tag as AntdTag } from "antd";
import { withRouter } from "react-router";
import { SearchOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { DriversHeader } from "specifics/drivers_header";
import { HistoryProps } from "routes/app";
import { GroupedList } from "specifics/grouped_list";
import { MdLockOutline } from "react-icons/md";
import { CustomCheckboxGroupField, CustomInputField } from "specifics/input";
import { useEffectSkipFirst, useForm, useQuery } from "utils/hooks";
import { DeliveryCompanySearchForm } from "entities/delivery_company";

import { CustomButton } from "specifics/button";
import { GlobalStateContext } from "contexts/global_state_context";
import { useFetchManualsApi, useFetchTagsForManualApi } from "api/manual";
import { Manual } from "entities/manual";
import { DriversManualsCreatePageInner } from "./drivers_maunal_form";
import { Status } from "entities/contract";
import { statusTag } from "specifics/status_tag";
import { queryString } from "utils/util";

const DriversManualsPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchManualsApi();
  const listTagsForManualsApi = useFetchTagsForManualApi();
  const searchForm = useForm<DeliveryCompanySearchForm & { status?: number[] }>({});
  const [visible, setVisible] = useState(false);
  const [tagSeachVisible, setTagSeachVisible] = useState(false);
  const [data, setData] = useState<Manual[]>([]); // データの状態を追加
  const tags = () => listTagsForManualsApi.response.data?.filter(tag => !!tag.name);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const filter = useMemo(() =>
    (selectedTags.length > 0 ? `tags.ids:${selectedTags.join(",")},` : "") +
    (searchForm.object.name ? `kana:${searchForm.object.name},` : "") +
    (searchForm.object.status ? `status:${searchForm.object.status},` : ""),
    [searchForm.object.name, searchForm.object.status, selectedTags]
  );
  const defaultQuery = {
    page: 1,
    pageSize: 20,
    sorter: "kana,asc",
    filter
  }
  const query = useQuery<typeof defaultQuery>();

  useEffect(() => {
    listTagsForManualsApi.execute();
  }, []);

  useEffect(() => {
    if (Object.keys(query).length === 0 || data.length === 0 && query.page > 1) {
      props.history.push(`/drivers/manuals${queryString(defaultQuery)}`);
    } else {
      listApi.execute(queryString(query));
    }
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (!tagSeachVisible) {
      props.history.push(`/drivers/manuals${queryString({ ...defaultQuery, page: 1 })}`);
    }
  }, [searchForm.object.status, tagSeachVisible]);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading || listTagsForManualsApi.loading);
    if (query.page > 1) {
      // 画面操作を無効にする
      document.body.style.pointerEvents = 'none';
    }
    if (listApi.isSuccess()) {
      // 画面操作を有効にする
      document.body.style.pointerEvents = 'auto';
      setData(
        prevData => query.page == 1 ?
          listApi.response.data || [] :
          [...prevData, ...listApi.response.data || []]
      );
    }
  }, [listApi.loading, listTagsForManualsApi.loading]);

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const selectTagsContent = () => {
    return (
      <div style={{ overflowX: "auto", width: 300 }}>
        <Space onClick={(e) => e.stopPropagation()} size={3} style={{ paddingBottom: 10 }}>
          {tags()?.length ? (
            tags()?.map((tag) => {
              return (
                <AntdTag.CheckableTag
                  style={{
                    boxShadow: "0 0.5mm 1mm rgba(0, 0, 0, 0.3)",
                    cursor: "pointer",
                  }}
                  key={tag.id}
                  checked={selectedTags.includes(tag.id || '')}
                  onChange={(checked) => handleChange(tag.id || '', checked)}
                >
                  {tag.name}
                </AntdTag.CheckableTag>
              );
            })
          ) : (
            <Alert message="追加できるタグはありません" />
          )}
        </Space>
      </div>
    );
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 10 <= document.documentElement.offsetHeight) return;
    if (listApi.response.meta?.lastPage == query.page || listApi.loading) return; // 最大ページに達したら実行しない
    props.history.push(`/drivers/manuals${queryString({ ...defaultQuery, page: Number(query.page) + 1 })}`)// ページ番号を更新
  }, [query.page, listApi.response.meta, listApi.loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        background: token.colorBgBase,
        minHeight: "100vh",
      }}
    >
      <DriversHeader
        title="納入カルテ一覧"
        onBack={() => {
          props.history.push("/drivers/delivers");
        }}
        navMenu={
          <Menu style={{ border: "none", boxShadow: "none" }}>
            <Menu.Item
              style={{
                backgroundColor: "transparent",
                fontSize: 15,
                fontWeight: "normal",
              }}
              onClick={() => props.history.push(`/drivers/manuals/share`)}
            >
              納入カルテを共有
            </Menu.Item>
          </Menu>
        }
      />
      <Space
        size={16}
        direction="vertical"
        style={{ padding: 16, paddingTop: 48 }}
      >
        <Space style={{ width: "100%" }} size={8}>
          <CustomInputField
            fieldProps={{
              prefix: <SearchOutlined color="#9CA3AF" />,
              suffix:
                <Popover
                  trigger={"click"}
                  content={selectTagsContent}
                  title={"タグで検索"}
                  placement="bottom"
                  open={tagSeachVisible}
                  onOpenChange={(open) => { setTagSeachVisible(open); }}
                >
                  <PlusOutlined style={{ color: "#9CA3AF" }} onClick={(e) => e.stopPropagation()} />
                </Popover>,
              onPressEnter: () => props.history.push(`/drivers/manuals${queryString({ ...defaultQuery, page: 1 })}`) // enterキーが押された時に移動して検索
            }}
            placeholder={"納入カルテ名/行先名/住所で検索"}
            attr="name"
            form={searchForm}
            style={{ width: globalState.dimension.width - 32 - 88 - 8, fontSize: 12 }}
          />
          <CustomButton
            onClick={showDrawer}
            type="primary"
            style={{ width: "100%" }}
          >
            新規作成
          </CustomButton>
        </Space>
        <CustomCheckboxGroupField
          attr="status"
          form={searchForm}
          selectItems={Object.entries(Status)
            .filter(([label, value]) =>
              typeof value === "number" && value === Status.未記入 // 現状未記入だけをフィルタリング
            ).map(([label, value]) => ({
              label,
              value
            }))}
          style={{ width: "100%" }}
          isManual
          column={3}
        />
        {data.length === 0 ? (
          <Space
            size={32}
            direction="vertical"
            style={{ textAlign: "center", marginTop: 40 }}
          >
            <Space
              style={{
                marginTop: 8,
                textAlign: "center",
                width: globalState.dimension.width - 32,
              }}
              size={24}
              direction="vertical"
            >
              <MdLockOutline
                style={{ height: 64, width: 64, color: "#9CA3AF" }}
              />
              <Space direction="vertical" size={13}>
                <div style={{ fontWeight: 700, fontSize: 16, color: "#000" }}>
                  まだ納入カルテが作成されていません
                </div>
                <div
                  style={{
                    fontSize: 16,
                    color: "#6B7280",
                    padding: "0px 8px 0px 8px",
                  }}
                >
                  納入カルテ詳細ページから報告できます。
                </div>
              </Space>
            </Space>
          </Space>
        ) : (
          <GroupedList
            style={{
              background: "#FFFFFF",
              border: "1px solid #E5E7EB",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "6px",
              width: "calc(100vw - 32px)",
            }}
            data={data}
            getGroup={(manual: Manual) =>
              manual?.deliveryCompany?.kana &&
                manual?.deliveryCompany?.kana?.length > 0
                ? manual?.deliveryCompany?.kana[0]
                : "フリガナ無し"
            }
            recordView={(record) => (
              <Space
                size={4}
                style={{
                  justifyContent: "space-between",
                  width: "calc(100vw - 64px)",
                }}
                onClick={() =>
                  props.history.push(`/drivers/manuals/${record.id}`)
                }
              >
                <Space style={{ width: "100%" }} size={0} direction="vertical">
                  <Space>{record.deliveryCompany?.name}</Space>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#6B7280",
                    }}
                  >
                    〒{record?.deliveryCompany?.zipcode}{" "}
                    {record?.deliveryCompany?.address}
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#6B7280",
                    }}
                  >
                    カルテ名称: {record?.title ?? "--"}
                  </div>
                </Space>
                {record.status && record.status < Status.その他 && statusTag(record.status, token)}
              </Space>
            )}
          />
        )}
        <Drawer
          headerStyle={{ background: token.colorWhite }}
          bodyStyle={{ padding: 0 }}
          title={
            <div
              style={{
                display: "flex",
                fontSize: 16,
                justifyContent: "space-between",
              }}
            >
              <span></span>
              <span>新規納入カルテ</span>
              <CustomButton
                type="text"
                style={{ padding: 0, height: "fit-content" }}
                dialogconfirmProps={{
                  title: "編集内容を削除しますか？",
                  content: "納入カルテの作成を終了します。",
                  okText: "削除する",
                  onOk: onClose,
                  cancelText: "戻る",
                  okButtonProps: {
                    danger: true,
                    style: { background: "#FFEFEE" },
                  },
                  centered: true,
                  closable: false,
                }}
              >
                <CloseOutlined style={{ fontSize: 16 }} />
              </CustomButton>
            </div>
          }
          placement="bottom"
          closable={false}
          onClose={onClose}
          open={visible}
          height="100%"
        >
          {visible && <DriversManualsCreatePageInner history={props.history} />}
        </Drawer>
      </Space>
    </Space>
  );
};

export default withRouter(DriversManualsPage);