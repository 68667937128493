import { Divider, Space, theme } from "antd";
import { useDeleteVehicleApi, useFetchVehicleApi } from "api/vehicle";
import { GlobalStateContext } from "contexts/global_state_context";

import { Vehicle } from "entities/vehicle";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { CustomPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { VehicleLicenseTypeView, VehicleTypeView } from "./vehicle_view";

const VehiclePage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);

  const { token } = theme.useToken();
  const vehicleApi = useFetchVehicleApi();
  const params = useParams<{ id: string }>();
  useEffect(() => {
    vehicleApi.execute(params.id);
  }, []);
  const deleteApi = useDeleteVehicleApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      props.history.goBack();
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(vehicleApi.loading);
  }, [vehicleApi.loading]);

  const vehicle = () => {
    return vehicleApi.response.data;
  };

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="車両詳細"
      onBack={() => props.history.push("/operations/vehicles")}
      childrenStyle={{ display: "flex", justifyContent: "center" }}
    >
      <CustomShadowedContent>
        <Space
          style={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <Space direction="vertical" size={24}>
            <DescriptionBlock label="管理番号">
              {vehicle()?.vehicleCode}
            </DescriptionBlock>
            <DescriptionBlock label="営業所">
              {vehicle()?.division}
            </DescriptionBlock>
            <DescriptionBlock label="免許区分">
              {VehicleLicenseTypeView(vehicle())}
            </DescriptionBlock>
            <DescriptionBlock label="車両No.">
              {vehicle()?.vehicleNo}
            </DescriptionBlock>
            <DescriptionBlock label="車格">
              {vehicle()?.weight}t
            </DescriptionBlock>
            <DescriptionBlock label="車両種類">
              {VehicleTypeView(vehicle())}
            </DescriptionBlock>
            <DescriptionBlock label="担当ドライバー">
              {vehicle()?.user?.name}
            </DescriptionBlock>
            <DescriptionBlock label="最大積載量">
              {vehicle()?.maxWeight}t
            </DescriptionBlock>

            <DescriptionBlock label="備考">{vehicle()?.memo}</DescriptionBlock>
          </Space>
          <Space size={24} style={{ verticalAlign: "top", height: "100%" }}>
            <CustomButton
              style={{
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() =>
                props.history.push(`/operations/vehicles/${vehicle()?.id}/edit`)
              }
            >
              編集
            </CustomButton>
            <CustomButton
              danger
              type="text"
              style={{
                fontSize: 14,
                fontWeight: 400,
                padding: 0,
              }}
              confirm
              popconfirmProps={{
                title: "削除します。よろしいですか？",
              }}
              onClick={() => {
                deleteApi.execute(params.id);
              }}
            >
              削除
            </CustomButton>
          </Space>
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(VehiclePage);
