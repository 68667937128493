import { Alert, Skeleton, Space, notification, theme } from "antd";
import { withError } from "antd/lib/modal/confirm";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { DriversHeader } from "specifics/drivers_header";
import { useMedia } from "use-media";
import { MdLockOutline } from "react-icons/md";
import { useEffectSkipFirst, useForm, useQuery } from "utils/hooks";
import {
  ResetPasswordForm,
  useChangePasswordApi,
  useCheckTokenApi,
} from "api/authorization";
import { CustomInputField, StringValidation } from "specifics/input";
import { CustomButton } from "specifics/button";
import { GlobalStateContext } from "contexts/global_state_context";

import { useContext, useEffect } from "react";

const ForgetPasswordPage = (props: HistoryProps) => {
  const isMobile = useMedia("(max-width: 519px)");
  const { token } = theme.useToken();
  const query = useQuery<{ userId?: string; token?: string }>();
  const validationResultForm = useForm<ResetPasswordForm>({
    password: "",
    confirmPassword: "",
  });
  const form = useForm<ResetPasswordForm>({
    password: "",
    confirmPassword: "",
  });

  const checkToeknApi = useCheckTokenApi();

  useEffect(() => {
    if (query.token && query.userId) {
      checkToeknApi.execute({ token: query.token, userId: query.userId });
    }
  }, [query.token, query.userId]);

  useEffect(() => {
    globalState.setLoading(checkToeknApi.loading);
  }, [checkToeknApi.loading]);

  useEffect(() => {
    form.update((f) => {
      f.token = query.token;
      f.userId = query.userId;
    });
  }, [JSON.stringify(query)]);

  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "password", true);
      if ([undefined, null, ""].includes(form.object.confirmPassword)) {
        f.confirmPassword = "必須項目です。";
      } else if (form.object.password !== form.object.confirmPassword) {
        f.confirmPassword = "パスワードが一致しません。";
      } else {
        f.confirmPassword = undefined;
      }
    });
  }, [JSON.stringify(form.object)]);

  const globalState = useContext(GlobalStateContext);

  const changePasswordApi = useChangePasswordApi();

  useEffectSkipFirst(() => {
    globalState.setLoading(changePasswordApi.loading);
    if (changePasswordApi.isSuccess()) {
      notification.success({ message: "パスワード再設定しました" });
      props.history.push("/login");
    }
  }, [changePasswordApi.loading]);

  return (
    <Space
      direction="vertical"
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        alignItems: isMobile ? "unset" : "center",
        justifyContent: isMobile ? "flex-start" : "center",
      }}
    >
      <Space
        style={{
          backgroundColor: token.colorWhite,
          width: isMobile ? globalState.dimension.width : 448,
          padding: isMobile ? "0px 0px" : "32px 40px 32px 40px",
          zIndex: 1,
          boxShadow: isMobile ? "none" : "0 0.5mm 0.5mm rgba(0, 0, 0, 0.3)",
          borderRadius: 6,
          paddingTop: isMobile ? 32 : 0,
        }}
        direction="vertical"
        size={24}
      >
        <Space
          size={32}
          direction="vertical"
          style={{ margin: "0px 16px 0px 16px" }}
        >
          <Skeleton loading={globalState.loading}>
            <Space
              style={{
                marginTop: 8,
                textAlign: "center",
                width: isMobile ? "calc(100vw - 32px)" : 336,
              }}
              size={24}
              direction="vertical"
            >
              <MdLockOutline
                style={{ height: 64, width: 64, color: "#9CA3AF" }}
              />
              <Space direction="vertical" size={13}>
                <div style={{ fontWeight: 700, fontSize: 16, color: "#000" }}>
                  パスワード再設定
                </div>
                <div
                  style={{
                    fontSize: 16,
                    color: token.colorTextSecondary,
                    padding: "0px 8px 0px 8px",
                  }}
                >
                  新しいパスワードを入力してください。
                </div>
              </Space>
            </Space>
            <Space
              direction="vertical"
              size={16}
              style={{ width: "100%", marginTop: 16 }}
            >
              {!form.object.token || !form.object.userId ? (
                <Alert
                  type="error"
                  message="パスワード再設定ページのURLが不正です。"
                />
              ) : !checkToeknApi.response?.data?.result ? (
                <Alert
                  type="error"
                  message="リンクの有効期限を過ぎています。"
                />
              ) : (
                <>
                  <CustomInputField
                    required
                    validationResultForm={validationResultForm}
                    form={form}
                    attr="password"
                    label="パスワード"
                    type="password"
                  />
                  <CustomInputField
                    required
                    validationResultForm={validationResultForm}
                    form={form}
                    attr="confirmPassword"
                    label="パスワード(確認用)"
                    type="password"
                  />
                </>
              )}
              <CustomButton
                disabled={
                  !form.object.token ||
                  !form.object.userId ||
                  !checkToeknApi.response?.data?.result
                }
                style={{
                  width: "100%",
                  height: !isMobile ? 42 : 50,
                  paddingTop: !isMobile ? "auto" : 9,
                  paddingBottom: !isMobile ? "auto" : 9,
                }}
                onClick={() => {
                  form.setValidate(true);
                  if (
                    Object.keys(validationResultForm.object).every(
                      (key) =>
                        validationResultForm.object[
                          key as keyof ResetPasswordForm
                        ] === undefined
                    )
                  ) {
                    changePasswordApi.execute(form.object);
                  }
                }}
              >
                パスワード再設定
              </CustomButton>
            </Space>
          </Skeleton>
        </Space>
      </Space>
    </Space>
  );
};

export default withRouter(ForgetPasswordPage);
