import { Select, Space, TableProps, theme, Tag as AntdTag } from "antd";
import { ColumnsType } from "antd/lib/table";

import { ID } from "entities";
import { Manual } from "entities/manual";
import dayjs from "dayjs";
import { HistoryProps } from "routes/app";
import { CustomTable, CustomTableProps } from "specifics/table";
import { useTable } from "specifics/use_table";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { queryString } from "utils/util";
import { Contract, ContractItems, formatContractFieldValue } from "entities/contract";
import { Key } from "react";
type ContractsTableProps = TableProps<Contract> & CustomTableProps;

export const MismatchedTable = (
  props: ContractsTableProps &
    HistoryProps & {
      query?: {
        page: number;
        pageSize: number;
        sorter: string;
        filter: string;
      };
      showDrawer: (id?: ID, selectedId?: ID) => void
      mode?: string;
      item?: string;
    }
) => {
  const { query, dataSource, ...rest } = props;
  const table = useTable<Manual>(props);
  const { token } = theme.useToken();

  const idsForm = useForm<{ ids: ID[] }>({ ids: [] });

  useEffectSkipFirst(() => {
    idsForm.updateObject("ids", table.selectedRowKeys);
  }, [table.selectedRowKeys]);

  const columnsAll: ColumnsType<Contract> = [
    {
      ...table.baseColumn("title"),
      sorter: (a, b) => ((a.title ?? "") > (b.title ?? "") ? 1 : -1),
      title: "カルテタイトル",
      width: 400,
      render: (item) =>
        item.title ?? `${item.deliveryCompany?.name}-${item.consignor?.name}`,
    },
    {
      ...table.baseColumn("items"),
      title: "契約との不一致",
      width: 356,
      render: (item) => item.items.split(",").filter((i: string) => i.trim() !== "").map((i: string, index: Key) =>
        <AntdTag style={{ border: "none", borderRadius: 100, background: "#F3F4F6", fontSize: 13, paddingTop: 3, paddingBottom: 3, marginInlineEnd: 6, marginBlockEnd: 6 }} key={index}>{i}</AntdTag>
      ),
    },
    {
      ...table.baseColumn("counts"),
      sorter: (a, b) => ((a.counts ?? "") > (b.counts ?? "") ? 1 : -1),
      title: "件数",
      width: 80,
      render: (item) => item.counts,
    },
    {
      ...table.baseColumn("updatedAt"),
      sorter: (a, b) => ((a.manualUpdatedAt ?? "") > (b.manualUpdatedAt ?? "") ? 1 : -1),
      title: "実態更新日",
      width: 128,
      render: (item) => item.manualUpdatedAt ? dayjs(item.manualUpdatedAt).format("YYYY/MM/DD") : "--",
    },
    {
      ...table.baseColumn("dcUpdatedAt"),// todo:バックエンドのクエリのカラム名を持ってしまっている。
      sorter: (a, b) => ((a.contractUpdatedAt ?? "") > (b.contractUpdatedAt ?? "") ? 1 : -1),
      title: "契約更新日",
      width: 128,
      render: (item) => item.contractUpdatedAt ? dayjs(item.contractUpdatedAt).format("YYYY/MM/DD") : "--",
    },
  ];

  const columnsItem: ColumnsType<Contract> = [
    {
      ...table.baseColumn("title"),
      sorter: (a, b) => ((a.title ?? "") > (b.title ?? "") ? 1 : -1),
      title: "カルテタイトル",
      width: 400,
      render: (record) =>
        record.title ?? `${record.deliveryCompany?.name}-${record.consignor?.name}`,
    },
    {
      ...table.baseColumn("manual"),
      title: "実態（カルテ）",
      width: 389,
      render: (record) =>
        <AntdTag style={{ border: "none", borderRadius: 4, color: token.colorError, background: token.colorErrorBg, fontSize: 13, paddingTop: 3, paddingBottom: 3, marginInlineEnd: 4 }}>
          {
            record.manual ?
              formatContractFieldValue(record.manual, ContractItems[props.item as keyof typeof ContractItems]) :
              "--"
          }
        </AntdTag>
      ,
    },
    {
      ...table.baseColumn("deliveryCompany"),
      title: "契約（行先）",
      width: 389,
      render: (record) => record.deliveryCompany ?
        formatContractFieldValue(record.deliveryCompany, ContractItems[props.item as keyof typeof ContractItems]) :
        "--"
    },
    {
      ...table.baseColumn("updatedAt"),
      sorter: (a, b) => ((a.manualUpdatedAt ?? "") > (b.manualUpdatedAt ?? "") ? 1 : -1),
      title: "実態更新日",
      width: 128,
      render: (item) => item.manualUpdatedAt ? dayjs(item.manualUpdatedAt).format("YYYY/MM/DD") : "--",
    },
    {
      ...table.baseColumn("dcUpdatedAt"),// todo:バックエンドのクエリのカラム名を持ってしまっている。
      sorter: (a, b) => ((a.contractUpdatedAt ?? "") > (b.contractUpdatedAt ?? "") ? 1 : -1),
      title: "契約更新日",
      width: 128,
      render: (item) => item.contractUpdatedAt ? dayjs(item.contractUpdatedAt).format("YYYY/MM/DD") : "--",
    },
  ];

  const pagination = {
    current: props.pagination ? props.pagination.current ?? 0 : 0,
    pageSize: props.pagination ? props.pagination.pageSize ?? 0 : 0,
    total: props.pagination ? props.pagination.total ?? 0 : 0,
    start: 0,
    last: 0,
  };
  pagination.start = (pagination?.current - 1) * pagination?.pageSize + 1;
  pagination.last = pagination?.current * pagination?.pageSize;

  let singleClickTimer: NodeJS.Timeout;
  let firstClickEvent = false;
  const oneClickHandler = (onClick: () => void) => {
    if (!firstClickEvent) {
      firstClickEvent = true;
      singleClickTimer = setTimeout(() => {
        onClick();
        firstClickEvent = false;
      }, 300);
    }
  };
  const doubleClickHandler = (onDoubleClick: () => void) => {
    clearTimeout(singleClickTimer);
    onDoubleClick()
    firstClickEvent = false;
  };
  return (
    <CustomTable
      tableKey="contracts_table"
      table={table}
      extraLeft={
        <Space size={25} style={{ fontSize: 13 }}>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              {pagination.total}
            </span>
            件中&ensp;
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              {pagination.start}
            </span>
            ~
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              {pagination.total < pagination.last ? `${pagination.total}` : `${pagination.last}`}
            </span>
            件を表示
          </div>
          {!props.hideDisplaySetting && <div>
            表示件数
            <Select
              style={{ width: 85, marginLeft: 6 }}
              defaultValue={Number(query?.pageSize)}
              options={[
                { value: 10, label: "10件" },
                { value: 20, label: "20件" },
                { value: 50, label: "50件" },
                { value: 100, label: "100件" },
              ]}
              onChange={(value) =>
                props.history.push(
                  `/operations/contracts/${queryString({
                    ...query,
                    pageSize: value,
                  })}`
                )
              }
            />
          </div>}
        </Space>
      }
      pagination={table.paginationInfo}
      showSizeChanger={false}
      onChange={table.handleChange}
      columns={props.mode === "すべての不一致" ? columnsAll : columnsItem}
      onRow={(data, index) => ({
        onClick: () => {
          oneClickHandler(() => props.showDrawer(data.manual?.manualId || data.id, data.manual?.id));
        },
        onDoubleClick: () => {
          doubleClickHandler(() => props.history.push(`/operations/manuals/${data.manual?.manualId || data.id}`));
        },
      })}
      dataSource={dataSource?.map((d) => ({ ...d, key: d?.id }))}
      {...rest}
    />
  );
};
