import {
  Alert,
  Button,
  ButtonProps,
  Divider,
  Drawer,
  DrawerProps,
  Space,
  theme,
} from "antd";
import { HistoryOutlined, DeleteOutlined } from "@ant-design/icons";
import { MetricsHistoryManager } from "./metrics_history_manager";
import { MetricsForm } from "../metrics_config_form";
import { MetricsFormViewModel } from "../metrics_form_view";
import { useEffect, useState } from "react";
import { MetricsGroupBy } from "../metrics_config";

export const MetricsHistoryDrawer = ({
  onClickHistory,
  ...drawerProps
}: {
  onClickHistory?: (metricsFormHistory: MetricsForm) => void;
} & DrawerProps) => {
  const [metricsFormHistories, setMetricsFormHistories] = useState<
    MetricsForm[]
  >([]);
  useEffect(() => {
    if (drawerProps.open) {
      setMetricsFormHistories(MetricsHistoryManager.getHistories());
    }
  }, [drawerProps.open]);
  return (
    <Drawer {...drawerProps} title="分析履歴">
      {!metricsFormHistories.length ? (
        <Alert message="履歴はありません" />
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          {metricsFormHistories.map((metricsFormHistory, index) => {
            return (
              <MetricsHistoryItemView
                metricsFormHistory={metricsFormHistory}
                key={`metricsFormHistory${index}`}
                onClickHistory={onClickHistory}
                onRemoveHistory={() => {
                  setMetricsFormHistories(
                    MetricsHistoryManager.removeHistory(metricsFormHistory)
                  );
                }}
              />
            );
          })}
        </Space>
      )}
    </Drawer>
  );
};

const MetricsHistoryItemView = ({
  metricsFormHistory,
  onClickHistory,
  onRemoveHistory,
}: {
  metricsFormHistory: MetricsForm;
  onClickHistory?: (metricsFormHistory: MetricsForm) => void;
  onRemoveHistory?: () => void;
}) => {
  const [hover, setHover] = useState(false);
  const { token } = theme.useToken();
  return (
    <Space
      direction="vertical"
      onClick={() => onClickHistory && onClickHistory(metricsFormHistory)}
      style={{
        width: "100%",
        padding: 12,
        borderRadius: 16,
        background: hover ? token.colorBgLayout : token.colorWhite,
        cursor: "pointer",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Space style={{ justifyContent: "space-between", width: "100%" }}>
        <div style={{ fontWeight: 700 }}>{metricsFormHistory.nameJp}</div>
        <Button
          shape="circle"
          size="small"
          danger
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            onRemoveHistory && onRemoveHistory();
          }}
        />
      </Space>
      <Space>
        <div>{MetricsFormViewModel.fromToDateView(metricsFormHistory)}</div>
        <div>{MetricsFormViewModel.rangeView(metricsFormHistory)}</div>
      </Space>
      <Divider style={{ marginTop: 2, marginBottom: 2 }} />
      <div>{MetricsFormViewModel.groupingView(metricsFormHistory)}</div>
      {(metricsFormHistory.groupBy === MetricsGroupBy.DELIVERY_COMPANY ||
        metricsFormHistory.groupBy === MetricsGroupBy.VEHICLE) && (
        <div>{MetricsFormViewModel.groupsView(metricsFormHistory)}</div>
      )}
    </Space>
  );
};

export const OpenMetricsHistoryDraerButton = ({
  ...buttonProps
}: ButtonProps) => {
  return <Button shape="circle" icon={<HistoryOutlined />} {...buttonProps} />;
};
