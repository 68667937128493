import { Space, Table, Upload, theme } from "antd";
import {
  useDeleteCharteredCompanyApi,
  useFetchCharteredCompaniesApi,
} from "api/chartered_company";
import { GlobalStateContext } from "contexts/global_state_context";

import {
  CharteredCompany,
  CharteredCompanySearchForm,
} from "entities/chartered_company";
import { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomInputField } from "specifics/input";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { CharteredVehiclesTable } from "./chartered_vehicles_table";
import { ID } from "entities";

const CharteredCompaniesPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const listApi = useFetchCharteredCompaniesApi();
  const { token } = theme.useToken();
  const deleteApi = useDeleteCharteredCompanyApi();

  useEffect(() => {
    listApi.execute();
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(listApi.loading);
  }, [listApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);
  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      // subTitle={
      //   <CustomInputField
      //     style={{ width: 320, marginLeft: 20 }}
      //     fieldProps={{
      //       prefix: <SearchOutlined color="#9CA3AF" />,
      //     }}
      //     placeholder={"傭車を検索"}
      //     attr="name"
      //     form={searchForm}
      //   />
      // }
      title="傭車"
      extra={[
        // <Upload  key="UploadCSVToCharteredCompanies">
        //   <div
        //     style={{
        //       display: "flex",
        //       flexDirection: "row",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       padding: "0px",
        //       gap: "4px",
        //       width: 241,
        //       height: 42,
        //       border: "2px dashed #D1D5DB",
        //       borderRadius: "6px",
        //       flex: "none",
        //       order: 0,
        //       flexGrow: 0,
        //     }}
        //   >
        //     <UploadOutlined style={{ color: token.colorPrimary }} />
        //     <div
        //       style={{
        //         padding: 0,
        //         width: 184,
        //         height: 20,
        //         fontSize: 14,
        //         fontWeight: 500,
        //         textAlign: "justify",
        //         color: token.colorPrimary,
        //       }}
        //     >
        //       CSVファイルをアップロード
        //     </div>
        //   </div>
        // </Upload>,
        <CustomButton
          key="CreateCharteredCompanies"
          onClick={() =>
            props.history.push("/operations/chartered-vehicles/create")
          }
          type="primary"
        >
          傭車登録
        </CustomButton>,
      ]}
    >
      <CharteredVehiclesTable
        handleDelete={(id: ID) => deleteApi.execute(id)}
        history={props.history}
        dataSource={listApi.response.data}
        listApi={listApi}
        pagination={{
          current: listApi.response.meta?.currentPage,
          pageSize: listApi.response.meta?.perPage,
          total: listApi.response.meta?.total,
        }}
        // loading={globalState.loading}
      />
    </CustomPageHeader>
  );
};

export default withRouter(CharteredCompaniesPage);
