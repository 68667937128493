import { TemporaryToken } from "entities/temporary_token";
import { ApiSet, BaseResponse, usePostObjectApi } from "utils/network/api_hooks";
import { HttpClient } from "utils/network/axios";

interface TemporaryTokenForm {
  expirationOption?: '1day' | '1week' | '1month' | '1year' | string | number;
}

export type TemporaryTokenResponse = BaseResponse & {
  data: TemporaryToken;
};

export function useGenerateTemporaryTokenApi(): ApiSet<TemporaryTokenResponse> & {
  execute: (form: TemporaryTokenForm) => Promise<any>;
} {
  const apiSet = usePostObjectApi<TemporaryTokenResponse, TemporaryTokenForm>(
    new HttpClient(),
    {
      initialResponse: { data: {} },
    },
    { formatJson: true }
  );

  const execute = async (form: TemporaryTokenForm) => {
    const apiPath = `temporary_token`;
    const setLoading = true;
    return apiSet.execute(apiPath, form, setLoading);
  };

  return { ...apiSet, execute };
}