import { Divider, Space } from "antd";
import { OrderDetailsView } from "components/orders/orders_view";
import { Order, OrderCharterTypes } from "entities/order";
import { useEffect, useState } from "react";

export const DeliveryDetailView = ({
    order,
}: { order: Order }) => {
    const cellStyle = {
        paddingLeft: "20px",
        fontSize: 16,
    }

    return (
        <Space
            direction="vertical"
            style={{
                height: "780px",
                overflowY: "scroll",
            }}
        >
            <table style={{ textAlign: "left" }}>
                <thead>
                    <tr>
                        <th style={{ fontSize: 16 }}>受注番号</th>
                        <th style={cellStyle}>車両</th>
                        <th style={cellStyle}>荷主</th>
                        {order.orderType === 0 && <th style={cellStyle}>車格</th>}
                        <th style={cellStyle}>重量</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ fontSize: 16 }}>{order.orderNo}</td>
                        <td style={cellStyle}>{OrderCharterTypes[order.charterType!]}</td>
                        <td style={cellStyle}>{order.consignor?.name}</td>
                        {order.orderType === 0 && <td style={cellStyle}>{order.weight}</td>}
                        <td style={cellStyle}>{order.loadWeight}</td>
                    </tr>
                </tbody>
            </table>
            {([2, 1] as const).map((pointType) => (
                <OrderDetailsView
                    key={pointType}
                    order={order}
                    pointType={pointType}
                    deliveryModal={true}
                    style={{ width: 592 }}
                />
            ))}
            <Divider style={{ margin: '0 0 20px 0' }} />
            <div style={{ fontSize: 16, fontWeight: "bolder" }}>備考</div>
            <div style={{ display: "flex", fontSize: 16, fontWeight: "bolder" }}>
                {order.memo}
            </div>
            <div style={{ fontSize: 16, fontWeight: "bolder" }}>重量</div>
            <div style={{ display: "flex", fontSize: 16, fontWeight: "bolder" }}>
                {order.assignedWeight}kg/{order.loadWeight}kg
            </div>
        </Space>
    )
}