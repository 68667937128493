import { Popover, Typography, theme } from "antd";
import { Children, ReactNode } from "react";

const { Text } = Typography;

export const CustomTableText = (props: {
  width?: number | string;
  children?: ReactNode;
}) => {
  const { token } = theme.useToken();
  return (
    <Popover content={props.children}>
      <Text
        style={{ width: props.width, color: token.colorTextSecondary }}
        ellipsis
      >
        {props.children ?? "--"}
      </Text>
    </Popover>
  );
};
