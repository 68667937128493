export function convertToCSV<T>(objArray: T[]): string {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  // ヘッダを追加
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (const index in array[i]) {
      if (line !== "") line += ",";

      line += index;
    }

    if (i === 0) {
      str += line + "\r\n";
    }
  }

  // データを追加
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (const index in array[i]) {
      if (line !== "") line += ",";

      // オブジェクト内の特定のプロパティを取得
      let value = array[i][index];
      // 値が存在して、カンマやダブルクオートを含む場合は、ダブルクオートで囲む
      if (
        typeof value === "string" &&
        (value.includes(",") || value.includes('"'))
      ) {
        value = `"${value.replace(/"/g, '""')}"`;
      }
      if (typeof value === "string" && value.includes("\n")) {
        value = `"${value.replace(/\n/g, "\\n")}"`;
      }
      line += value;
    }

    str += line + "\r\n";
  }

  return str;
}

export function downloadCSV(csv: string, filename = "data.csv"): void {
  const bom = "\uFEFF";  // BOMを追加
  const blob = new Blob([bom + csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
