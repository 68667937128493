import { Space, theme } from "antd";
import {
  useDeleteUserApi,
  useFetchAllUsersApi,
  useFetchUsersApi,
} from "api/user";
import { GlobalStateContext } from "contexts/global_state_context";

import { UserSearchForm } from "entities/user";
import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton, UploadCSVFileButton } from "specifics/button";
import { CustomPageHeader } from "specifics/page_header";
import { useEffectSkipFirst, useForm } from "utils/hooks";

import { UsersTable } from "../users_table";
import { ID } from "entities";
import { SuperAdminUserCsvImportModal } from "./super_admin_user_csv_import_modal";

const SuperAdminUsersPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const searchForm = useForm<UserSearchForm>({});
  const listApi = useFetchUsersApi();
  const allApi = useFetchAllUsersApi();
  const deleteApi = useDeleteUserApi();

  const [importCSVModalOpen, setImportCsvModalopen] = useState(false);

  useEffect(() => {
    if (!importCSVModalOpen) {
      listApi.execute();
      allApi.execute();
    }
  }, [importCSVModalOpen]);

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      listApi.execute();
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(allApi.loading);
    if (allApi.isSuccess()) {
      globalState.setUsers(allApi.response.data || []);
    }
  }, [allApi.loading]);

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      childrenStyle={{ paddingBottom: 0 }}
      title="メンバー"
      extra={[
        <UploadCSVFileButton
          key={"UploadCsvFileButton"}
          onClick={() => setImportCsvModalopen(true)}
        />,
        <CustomButton
          key="CreateUsers"
          onClick={() => props.history.push("/super-admin/users/create")}
          type="primary"
        >
          メンバー登録
        </CustomButton>,
      ]}
    >
      <UsersTable
        history={props.history}
        dataSource={listApi.response.data}
        listApi={listApi}
        pagination={{
          current: listApi.response.meta?.currentPage,
          pageSize: listApi.response.meta?.perPage,
          total: listApi.response.meta?.total,
        }}
        handleDelete={(id: ID) => deleteApi.execute(id)}
      />
      <SuperAdminUserCsvImportModal
        open={importCSVModalOpen}
        onCancel={(e) => setImportCsvModalopen(false)}
      />
    </CustomPageHeader>
  );
};

export default withRouter(SuperAdminUsersPage);
