import { Space, TableProps, theme } from "antd";
import { ColumnsType } from "antd/lib/table";

import { ID } from "entities";
import { Template } from "entities/template";
import { useContext } from "react";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import Highlighter from "react-highlight-words";
import { GlobalStateContext } from "contexts/global_state_context";
import dayjs from "dayjs";

type TemplatesTableProps = TableProps<Template>;

export const TemplatesTable = (
  props: TemplatesTableProps &
    HistoryProps & {
      handleDelete: (id: ID) => void,
      handleCopy: (item: Template) => void
    }
) => {
  const globalState = useContext(GlobalStateContext);
  const { token } = theme.useToken();

  const table = useTable<Template>(props);

  const columns: ColumnsType<Template> = [
    {
      ...table.baseColumn("templateNo"),
      ...table.getColumnSearchProps("templateNo", "テンプレート番号"),
      width: 150,
      title: "名称",
      render: (item) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[table.searchText]}
          autoEscape
          textToHighlight={item.templateName}
        />
      ),
    },

    {
      ...table.baseColumn("createdAt"),
      width: 70,
      title: "作成日",
      render: (item) => item.createdAt ? dayjs(item.createdAt).format("YYYY/MM/DD") : '',
    },
    {
      ...table.baseColumn("updatedAt"),
      width: 70,
      title: "最終更新日",
      render: (item) => item.updatedAt ? dayjs(item.updatedAt).format("YYYY/MM/DD") : '',
    },
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 138,
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/super-admin/templates/${item.id}/edit`);
            }}
          >
            編集
          </CustomButton>
          <CustomButton
            style={{
              fontWeight: 500,
              padding: 0,
              height: "30px",
              width: "28px",
              textAlign: "left",
            }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              props.handleDelete(item.id);
            }}
            danger
            confirm
            popconfirmProps={{
              title: "削除します。よろしいですか？",
            }}
          >
            削除
          </CustomButton>
          <CustomButton
            style={{
              color: token.colorInfo,
              fontWeight: 500,
              padding: 0,
              height: "30px",
              textAlign: "left",
            }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              const { templateName, steps } = item; // itemから複製用にtemplateNameとstepsを抽出
              props.handleCopy({ templateName: templateName + "のコピー", steps });
            }}
            confirm
            popconfirmProps={{
              title: "コピーを作成します。よろしいですか？",
            }}
          >
            コピー
          </CustomButton>
        </Space>
      ),
    },
  ];
  return (
    <CustomTable
      tableKey="templates_table"
      table={table}
      pagination={table.paginationInfo}
      onChange={table.handleChange}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () => props.history.push(`/super-admin/templates/${data.id}`),
      })}
      {...props}
    />
  );
};
