import { Divider, Modal, Skeleton, Space, Table, Tag, theme } from "antd";

import { ID } from "entities";
import {
  Order,
  OrderTypes,
  OrderType,
  OrderCharterType,
  OrderPointType,
  OrderPointTypes,
  OrderCharterTypes,
} from "entities/order";
import dayjs from "dayjs";
import { CSSProperties, useEffect, useState } from "react";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";
import { useFetchManualsApi } from "api/manual";
import { Manual } from "entities/manual";
import { ManualPage } from "components/manuals/manual_page";
import { useHistory } from "react-router";
import { queryString } from "utils/util";

export const getOrderTypeLabel = (value?: OrderType) => {
  return value !== undefined ? OrderTypes[value] : "--";
};

export const OrderTypeView = (item?: Order) => {
  return getOrderTypeLabel(item?.orderType);
};

export const getOrderCharterTypeLabel = (value?: OrderCharterType) => {
  if (value === undefined) return "";
  return OrderCharterTypes[value];
};

export const getIndexFilteredByPointType = (
  order: Order,
  detailIndex: number,
  pointType: OrderPointType
): number => {
  if (order.orderDetails === undefined) return -1;
  else if (order.orderDetails.length <= detailIndex) return -1;
  else {
    return order.orderDetails
      .slice(0, detailIndex)
      .filter((od) => od.pointType === pointType && od.flag !== "d").length;
  }
};

export const OrderDetailsView = ({
  pointType,
  order,
  deliveryModal,
  style
}: {
  order: Order;
  pointType: OrderPointType;
  deliveryModal?: boolean
  style?: CSSProperties
}) => {
  const { token } = theme.useToken();
  const [selectedDeliveryCompanyId, setSelectedDeliveryCompanyId] = useState<string | null>(null);

  const handleShowManualModal = (deliveryCompanyId: string) => {
    setSelectedDeliveryCompanyId(deliveryCompanyId);
  };

  return (
    <>
      <DescriptionBlock
        label={`${OrderPointTypes[pointType]}`}
        valueStyle={{
          height: "auto",
        }}
        style={style}
      >
        {order.orderDetails
          ?.filter((orderDetail) => orderDetail.pointType === pointType)
          .map((orderDetail, index) => (
            <Space
              key={orderDetail.id}
              style={{
                width: deliveryModal ? 560 : 755,
                background: token.colorBgBase,
                alignItems: "flex-start",
                padding: 16,
                borderRadius: 8,
              }}
              direction="vertical"
            >
              <Space size={16} style={{ alignItems: "flex-start" }}>
                {!deliveryModal &&
                  <div>
                    {getIndexFilteredByPointType(order, index, pointType) + 1}.
                  </div>
                }
                <DescriptionBlock label={`${OrderPointTypes[pointType]}日`} style={{ minWidth: 90 }}>
                  {orderDetail.deliveryDate ? dayjs(orderDetail.deliveryDate).format("YYYY/MM/DD") : ''}
                </DescriptionBlock>
                <DescriptionBlock
                  style={{ width: deliveryModal ? "fit-content" : 520 }}
                  label={`${OrderPointTypes[pointType]}先`}
                >
                  <Space align="center">
                    {orderDetail.deliveryCompany?.name}
                    <Tag
                      color="cyan"
                      onClick={() => handleShowManualModal(orderDetail.deliveryCompany?.id || '')}
                      style={{ cursor: 'pointer' }}
                    >
                      カルテを参照
                    </Tag>
                  </Space>
                  <Space
                    split={<Divider style={{ height: 100 }} type="vertical" />}
                    style={{
                      marginTop: 10,
                      height: "100%",
                      alignItems: "flex-start",
                    }}
                  >
                    <DescriptionBlock
                      style={{ width: deliveryModal ? 128 : "auto" }}
                      labelStyle={{
                        width: deliveryModal ? 128 : 151.33,
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      valueStyle={{
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      label="住所"
                    >
                      <div>〒{orderDetail.deliveryCompany?.zipcode}</div>
                      <div>{orderDetail.deliveryCompany?.address}</div>
                    </DescriptionBlock>
                    <DescriptionBlock
                      style={{ width: deliveryModal ? 128 : "auto" }}
                      labelStyle={{
                        width: deliveryModal ? 128 : 151.33,
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      valueStyle={{
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      label="受入可能車両"
                    >
                      {orderDetail.deliveryCompany?.maxWeight ?? "--"}t
                    </DescriptionBlock>
                    <DescriptionBlock
                      style={{ width: deliveryModal ? 128 : "auto" }}
                      labelStyle={{
                        width: deliveryModal ? 128 : 151.33,
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      valueStyle={{
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      label="受入可能時間"
                    >
                      {orderDetail.deliveryCompany?.availableHours}
                    </DescriptionBlock>
                  </Space>
                  <Space
                    split={<Divider style={{ height: 100 }} type="vertical" />}
                    style={{
                      marginTop: 10,
                      height: "100%",
                      alignItems: "flex-start",
                    }}
                  >
                    <DescriptionBlock
                      style={{ width: deliveryModal ? 128 : "auto" }}
                      labelStyle={{
                        width: deliveryModal ? 128 : 151.33,
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      valueStyle={{
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      label="品名"
                    >
                      {orderDetail.productName ?? "--"}
                    </DescriptionBlock>
                    <DescriptionBlock
                      style={{ width: deliveryModal ? 128 : "auto" }}
                      labelStyle={{
                        width: deliveryModal ? 128 : 151.33,
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      valueStyle={{
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      label="数量・長さ"
                    >
                      <div>{orderDetail.quantity ?? "--"} {orderDetail.unit ?? ""}</div>
                      <div>{orderDetail.length ? `${orderDetail.length}m` : "--"}</div>
                    </DescriptionBlock>
                    <DescriptionBlock
                      style={{ width: deliveryModal ? 128 : "auto" }}
                      labelStyle={{
                        width: deliveryModal ? 128 : 151.33,
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      valueStyle={{
                        overflow: "revert",
                        fontSize: 14,
                      }}
                      label="着時間指定"
                    >
                      {orderDetail.arrivalTimeSpecified ?? "--"}
                    </DescriptionBlock>
                  </Space>
                </DescriptionBlock>
                {!deliveryModal &&
                  <DescriptionBlock label="重量">
                    {orderDetail.loadWeight}kg
                  </DescriptionBlock>
                }
              </Space>
              <DescriptionBlock
                label="備考"
              >
                {orderDetail.memo ?? "--"}
              </DescriptionBlock>
            </Space>
          ))}
      </DescriptionBlock>
      <OrderDetailManualModals
        selectedDeliveryCompanyId={selectedDeliveryCompanyId}
        onClose={() => setSelectedDeliveryCompanyId(null)}
      />
    </>
  );
};

export const OrderDetailManualModals = ({
  selectedDeliveryCompanyId,
  onClose,
  onSelectManual
}: {
  selectedDeliveryCompanyId: string | null;
  onClose: () => void;
  onSelectManual?: (manual: Manual) => void;
}) => {
  const history = useHistory();
  const [selectedManual, setSelectedManual] = useState<Manual | null>(null);
  const [isManualDetailModalVisible, setIsManualDetailModalVisible] = useState(false);
  const listManualsApi = useFetchManualsApi();

  useEffect(() => {
    if (selectedDeliveryCompanyId) {
      listManualsApi.execute(queryString({ filter: `deliveryCompany.id:${selectedDeliveryCompanyId}` }));
    }
  }, [selectedDeliveryCompanyId]);

  const handleManualModalClose = () => {
    onClose();
  };

  const showManualDetailModal = (manual: Manual) => {
    setSelectedManual(manual);
    setIsManualDetailModalVisible(true);
  };

  const handleManualDetailModalClose = () => {
    setIsManualDetailModalVisible(false);
    setSelectedManual(null);
  };

  return (
    <>
      <Modal
        title="納入カルテ一覧"
        open={!!selectedDeliveryCompanyId}
        onCancel={handleManualModalClose}
        width={800}
        bodyStyle={{ height: 700 }}
        footer={null}
      >
        <Skeleton loading={listManualsApi.loading}>
          <Table
            columns={[
              {
                title: 'カルテ名',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => text || `${record.deliveryCompany?.name}-${record.deliveryPartnerCompany?.name}-${record.consignor?.name}`,
              }
            ]}
            dataSource={listManualsApi.response?.data}
            pagination={false}
            onRow={(record) => {
              let clickTimeout: NodeJS.Timeout;
              let firstClickEvent = false;

              return {
                onDoubleClick: () => {
                  clearTimeout(clickTimeout);
                  onSelectManual && onSelectManual(record);
                  firstClickEvent = false;
                  onClose();
                },
                onClick: () => {
                  if (!firstClickEvent) {
                    firstClickEvent = true;
                    clickTimeout = setTimeout(() => {
                      showManualDetailModal(record);
                      firstClickEvent = false;
                    }, 300);
                  }
                },
                style: { cursor: 'pointer' }
              };
            }}
          />
        </Skeleton>
      </Modal>
      <Modal
        title="納入カルテ詳細"
        open={isManualDetailModalVisible}
        onCancel={handleManualDetailModalClose}
        width={800}
        bodyStyle={{ height: 700 }}
        footer={null}
      >
        {selectedManual && (
          <ManualPage
            history={history}
            manualId={selectedManual.id}
            selectedManualId={selectedManual.id}
            guest={true}
          />
        )}
      </Modal>
    </>
  );
};

export const DeleteOrCancelCloseOrderButton = ({
  item,
  handleCancelClose,
  handleDelete,
  inTable,
}: {
  item: Order;
  handleCancelClose: (id: ID) => void;
  handleDelete: (id: ID) => void;
  inTable?: boolean;
}) => {
  const style: CSSProperties = inTable
    ? {
      fontWeight: 500,
      padding: "0px 5px",
      height: "30px",
      textAlign: "left",
    }
    : ({
      fontWeight: 500,
    } as CSSProperties);
  return item.isConvert ? (
    <CustomButton
      style={style}
      type="primary"
      onClick={(e) => {
        e.stopPropagation();
        handleCancelClose(item.id!);
      }}
      danger
      confirm
      popconfirmProps={{
        title: "締めを解除し、配車データを削除します。よろしいですか？",
      }}
    >
      解除
    </CustomButton>
  ) : (
    <CustomButton
      style={{
        fontWeight: 500,
        padding: "0px 5px",
        height: "30px",
        textAlign: "left",
      }}
      type="text"
      onClick={(e) => {
        e.stopPropagation();
        handleDelete(item.id!);
      }}
      danger
      confirm
      popconfirmProps={{
        title: "削除します。よろしいですか？",
      }}
      disabled={!!item.isConvert}
    >
      削除
    </CustomButton>
  );
};