import { Select, Space, TableProps, Button, message } from "antd";
import { LinkOutlined, FormOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

import { ID } from "entities";
import { Manual, ManualInfo, ManualKey } from "entities/manual";
import { HistoryProps } from "routes/app";
import { CustomTable } from "specifics/table";
import { useTable } from "specifics/use_table";
import { useEffectSkipFirst, useForm } from "utils/hooks";
import { bulkEditManualUrlText, copyToClipboard, queryString } from "utils/util";
import { Contract } from "entities/contract";
import dayjs from "dayjs";
import { useGenerateTemporaryTokenApi } from "api/temporary_token";
import { GlobalStateContext } from "contexts/global_state_context";
import { useContext } from "react";
type ContractsTableProps = TableProps<Contract>;

export const UnfilledTable = (
  props: ContractsTableProps &
    HistoryProps & {
      query: {
        page: number;
        pageSize: number;
        sorter: string;
        filter: string;
      };
      showDrawer: (id?: ID) => void
    }
) => {
  const globalState = useContext(GlobalStateContext);
  const { query, dataSource, ...rest } = props;
  const table = useTable<Manual>(props);
  const generateTokenApi = useGenerateTemporaryTokenApi();

  const idsForm = useForm<{ ids: ID[] }>({ ids: [] });

  useEffectSkipFirst(() => {
    idsForm.updateObject("ids", table.selectedRowKeys);
  }, [table.selectedRowKeys]);

  const columns: ColumnsType<Contract> = [
    {
      ...table.baseColumn("title"),
      ...table.getColumnSearchProps("title", "タイトル"),
      sorter: (a, b) => ((a.title ?? "") > (b.title ?? "") ? 1 : -1),
      title: "タイトル",
      render: (item) => item.title || "--"
    },
    {
      ...table.baseColumn("updatedAt"),
      sorter: (a, b) => ((a.deliveryCompany?.updatedAt ?? "") > (b.deliveryCompany?.updatedAt ?? "") ? 1 : -1),
      width: 128,
      title: "契約更新日",
      render: (item) => item.deliveryCompany.updatedAt ? dayjs(item.deliveryCompany.updatedAt).format("YYYY/MM/DD") : "--",
    },
    {
      key: "action",
      title: "アクション",
      align: "right",
      width: 128,
      render: (item) => (
        <Space size={2}>
          <Button
            type="link"
            icon={
              <FormOutlined style={{ fontSize: 15 }} />
            }
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/operations/manuals/${item.manual.id}/edit?key=${ManualKey.基本情報}`)
            }}
          />
          <Button
            type="link"
            icon={
              <LinkOutlined style={{ fontSize: 20, transform: 'rotate(45deg)' }} />
            }
            onClick={async (e) => {
              e.stopPropagation();
              const res = await generateTokenApi.execute({ expirationOption: globalState.setting.customSetting?.urlExpiration });
              const token = encodeURIComponent(res.data.token);
              copyToClipboard(`${item.deliveryCompany.name}\n${process.env.REACT_APP_ADMIN_HOST}manuals/${item.manual.id}/edit?key=${ManualKey.基本情報}&token=${token}`);
              message.success(
                "クリップボードに編集用リンクをコピーしました。"
              );
            }}
          />
        </Space>
      ),
    },
  ];

  const handleBulkCopy = async (ids: ID[]) => {
    const finalText = await bulkEditManualUrlText(ids, dataSource || []);
    copyToClipboard(finalText);

    // 成功メッセージを表示
    message.success("クリップボードに編集用リンクを一括コピーしました。");
  };

  const pagination = {
    current: props.pagination ? props.pagination.current ?? 0 : 0,
    pageSize: props.pagination ? props.pagination.pageSize ?? 0 : 0,
    total: props.pagination ? props.pagination.total ?? 0 : 0,
    start: 0,
    last: 0,
  };
  pagination.start = (pagination?.current - 1) * pagination?.pageSize + 1;
  pagination.last = pagination?.current * pagination?.pageSize;

  const clearRowSelection = () => {
    idsForm.resetForm();
    table.setSelectedRowKeys([]);
  };

  return (
    <CustomTable
      tableKey="contracts_table"
      table={table}
      extraLeft={
        <Space size={25} style={{ fontSize: 13 }}>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              {pagination.total}
            </span>
            件中&ensp;
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              {pagination.start}
            </span>
            ~
            <span style={{ fontSize: 15, fontWeight: 500 }}>
              {pagination.total < pagination.last ? `${pagination.total}` : `${pagination.last}`}
            </span>
            件を表示
          </div>
          <div>
            表示件数
            <Select
              style={{ width: 85, marginLeft: 6 }}
              defaultValue={Number(query.pageSize)}
              options={[
                { value: 10, label: "10件" },
                { value: 20, label: "20件" },
                { value: 50, label: "50件" },
                { value: 100, label: "100件" },
              ]}
              onChange={(value) =>
                props.history.push(
                  `/operations/contracts/${queryString({
                    ...query,
                    pageSize: value,
                  })}`
                )
              }
            />
          </div>
        </Space>
      }
      rowSelection={table.rowSelection}
      pagination={table.paginationInfo}
      showSizeChanger={false}
      onChange={table.handleChange}
      columns={columns}
      onRow={(data) => ({
        onClick: () => props.history.push(`/operations/manuals/${data.manual?.manualId || data.id}`),
      })}
      dataSource={dataSource?.map((d) => ({ ...d, key: d?.id }))}
      onBulkURLCopy={() => {
        handleBulkCopy(idsForm.object.ids);
        clearRowSelection();
      }}
      {...rest}
    />
  );
};
